const listePages = [
    { page: 1, link: "https://i.ibb.co/1f6HWw9/0061-001.webp" },
    { page: 2, link: "https://i.ibb.co/Vmf7yx0/0061-002.webp" },
    { page: 3, link: "https://i.ibb.co/LtH572t/0061-003.webp" },
    { page: 4, link: "https://i.ibb.co/k9Mwr6s/0061-004.webp" },
    { page: 5, link: "https://i.ibb.co/KqbBpCx/0061-005.webp" },
    { page: 6, link: "https://i.ibb.co/ngRXw9G/0061-006.webp" },
    { page: 7, link: "https://i.ibb.co/GF2qcZg/0061-007.webp" },
    { page: 8, link: "https://i.ibb.co/5r3JbtX/0061-008.webp" },
    { page: 9, link: "https://i.ibb.co/X3F8S8s/0061-009.webp" },
    { page: 10, link: "https://i.ibb.co/px091h4/0061-010.webp" },
    { page: 11, link: "https://i.ibb.co/FVWXqY9/0061-011.webp" },
    { page: 12, link: "https://i.ibb.co/G5L2hQh/0061-012.webp" },
    { page: 13, link: "https://i.ibb.co/DpgBjz2/0061-013.webp" },
    { page: 14, link: "https://i.ibb.co/r0dtR5Y/0061-014.webp" },
    { page: 15, link: "https://i.ibb.co/m9JLXXq/0061-015.webp" },
    { page: 16, link: "https://i.ibb.co/DWWjYF7/0061-016.webp" },
    { page: 17, link: "https://i.ibb.co/qN5GXbk/0061-017.webp" },
    { page: 18, link: "https://i.ibb.co/27JpqmX/0061-018.webp" },
    { page: 19, link: "https://i.ibb.co/NnmYmmm/0061-019.webp" },
    { page: 20, link: "https://i.ibb.co/fYQ1x7b/0061-020.webp" },
    { page: 21, link: "https://i.ibb.co/nzbpPNQ/0061-021.webp" },
    { page: 22, link: "https://i.ibb.co/PYJdc03/0061-022.webp" },
    { page: 23, link: "https://i.ibb.co/6RDVJVt/0061-023.webp" },
    { page: 24, link: "https://i.ibb.co/VHz6sP8/0061-024.webp" },
    { page: 25, link: "https://i.ibb.co/xSLhD7N/0061-025.webp" },
    { page: 26, link: "https://i.ibb.co/PxwVfRs/0061-026.webp" },
    { page: 27, link: "https://i.ibb.co/QQ878p9/0061-027.webp" },
    { page: 28, link: "https://i.ibb.co/KjSxDBR/0061-028.webp" },
    { page: 29, link: "https://i.ibb.co/Hh45m6N/0061-029.webp" },
    { page: 30, link: "https://i.ibb.co/MZgttPf/0061-030.webp" },
    { page: 31, link: "https://i.ibb.co/FxLZ1Pf/0061-031.webp" },
    { page: 32, link: "https://i.ibb.co/GxDf0vk/0061-032.webp" }
  ]
  

export default listePages;