const listePages = [
    {
        page: 1,
        link: "https://i.ibb.co/fxxMg0d/0133.webp"
      },
      {
        page: 2,
        link: "https://i.ibb.co/ChZd45v/0134.webp"
      },
      {
        page: 3,
        link: "https://i.ibb.co/ggBzfzK/0135.webp"
      },
      {
        page: 4,
        link: "https://i.ibb.co/yRWTwvk/0136.webp"
      },
      {
        page: 5,
        link: "https://i.ibb.co/Jk20brz/0137.webp"
      },
      {
        page: 6,
        link: "https://i.ibb.co/tXHRmdC/0138.webp"
      },
      {
        page: 7,
        link: "https://i.ibb.co/FVjtqJG/0139.webp"
      },
      {
        page: 8,
        link: "https://i.ibb.co/wgmtj4P/0140.webp"
      },
      {
        page: 9,
        link: "https://i.ibb.co/sqD7jb7/0141.webp"
      },
      {
        page: 10,
        link: "https://i.ibb.co/7CLP1DQ/0142.webp"
      },
      {
        page: 11,
        link: "https://i.ibb.co/W6yp5kj/0143.webp"
      },
      {
        page: 12,
        link: "https://i.ibb.co/R0WVqjP/0144.webp"
      },
      {
        page: 13,
        link: "https://i.ibb.co/g7w1Y9p/0145.webp"
      },
      {
        page: 14,
        link: "https://i.ibb.co/kDbk8J7/0146.webp"
      },
      {
        page: 15,
        link: "https://i.ibb.co/zRJZf1k/0147.webp"
      },
      {
        page: 16,
        link: "https://i.ibb.co/TqDLjY5/0148.webp"
      },
      {
        page: 17,
        link: "https://i.ibb.co/g9xMLcm/0149.webp"
      },
      {
        page: 18,
        link: "https://i.ibb.co/vXqtKnV/0150.webp"
      },
      {
        page: 19,
        link: "https://i.ibb.co/dMQRRmK/0151.webp"
      },
      {
        page: 20,
        link: "https://i.ibb.co/ZxdqKZb/0152.webp"
      },
      {
        page: 21,
        link: "https://i.ibb.co/Nxjqcdd/0153.webp"
      },
      {
        page: 22,
        link: "https://i.ibb.co/qk19fpb/0154.webp"
      },
      {
        page: 23,
        link: "https://i.ibb.co/SJwPWbf/0155.webp"
      },
      {
        page: 24,
        link: "https://i.ibb.co/chn96Ld/0156.webp"
      },
      {
        page: 25,
        link: "https://i.ibb.co/j5CvRnj/0157.webp"
      },
      {
        page: 26,
        link: "https://i.ibb.co/8YGn3HS/0158.webp"
      },
      {
        page: 27,
        link: "https://i.ibb.co/47z88s4/0159.webp"
      },
      {
        page: 28,
        link: "https://i.ibb.co/XD2dCgB/0160.webp"
      },
      {
        page: 29,
        link: "https://i.ibb.co/Wp7TR7y/0161.webp"
      },
      {
        page: 30,
        link: "https://i.ibb.co/3R8YpzD/0162.webp"
      },
      {
        page: 31,
        link: "https://i.ibb.co/KwHwkrV/0163.webp"
      },
      {
        page: 32,
        link: "https://i.ibb.co/qyQfqzZ/0164.webp"
      },
      {
        page: 33,
        link: "https://i.ibb.co/VtTzwSZ/0165.webp"
      },
      {
        page: 34,
        link: "https://i.ibb.co/3dRcwGJ/0166.webp"
      },
      {
        page: 35,
        link: "https://i.ibb.co/RN2rqhN/0167.webp"
      },
      {
        page: 36,
        link: "https://i.ibb.co/7pKHnMD/0168.webp"
      }
]


export default listePages;