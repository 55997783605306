const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/KX1MHXZ/43-7-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/3fsPj2N/43-7-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/Pm0cQkB/43-7-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/Ntq0DQM/43-7-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/pzpv0Kx/43-7-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/dJ9XqFT/43-7-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/ZNfjnnm/43-7-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/2h13jnC/43-7-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/zhPVmcR/43-7-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/n3V5Ktd/43-7-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/x5SHTBs/43-7-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/8XsdkCm/43-7-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/qkh9vpv/43-7-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/Y77jt10/43-7-014.webp"
  }
];


export default listePages;