const listePages = [
    {
        page: 1,
        link: "https://i.ibb.co/DgBGB0z/088.webp"
      },
      {
        page: 2,
        link: "https://i.ibb.co/THFT3yX/089.webp"
      },
      {
        page: 3,
        link: "https://i.ibb.co/XDHGwc9/090.webp"
      },
      {
        page: 4,
        link: "https://i.ibb.co/mX9v0YY/091.webp"
      },
      {
        page: 5,
        link: "https://i.ibb.co/hFR1jG7/092.webp"
      },
      {
        page: 6,
        link: "https://i.ibb.co/wgnD3dF/093.webp"
      },
      {
        page: 7,
        link: "https://i.ibb.co/4YW53W4/094.webp"
      },
      {
        page: 8,
        link: "https://i.ibb.co/LJmvYsh/095.webp"
      },
      {
        page: 9,
        link: "https://i.ibb.co/K652RkF/096.webp"
      },
      {
        page: 10,
        link: "https://i.ibb.co/zfpDw1M/097.webp"
      },
      {
        page: 11,
        link: "https://i.ibb.co/X3rXGcj/098.webp"
      },
      {
        page: 12,
        link: "https://i.ibb.co/WVF53pF/099.webp"
      },
      {
        page: 13,
        link: "https://i.ibb.co/D4wfdFx/100.webp"
      },
      {
        page: 14,
        link: "https://i.ibb.co/Ngh5fBR/101.webp"
      },
      {
        page: 15,
        link: "https://i.ibb.co/Y3jmHpw/102.webp"
      },
      {
        page: 16,
        link: "https://i.ibb.co/88N5kTj/103.webp"
      },
      {
        page: 17,
        link: "https://i.ibb.co/T81RBC4/104.webp"
      },
      {
        page: 18,
        link: "https://i.ibb.co/6DWm0Wp/105.webp"
      },
      {
        page: 19,
        link: "https://i.ibb.co/ynYw95b/106.webp"
      },
      {
        page: 20,
        link: "https://i.ibb.co/pQ0YcRY/107.webp"
      },
      {
        page: 21,
        link: "https://i.ibb.co/vQM6XzH/108.webp"
      },
      {
        page: 22,
        link: "https://i.ibb.co/zxbmzmq/109.webp"
      },
      {
        page: 23,
        link: "https://i.ibb.co/Yt1gm50/110.webp"
      },
      {
        page: 24,
        link: "https://i.ibb.co/DRD94Fm/111.webp"
      },
      {
        page: 25,
        link: "https://i.ibb.co/djx8QkX/112.webp"
      },
      {
        page: 26,
        link: "https://i.ibb.co/Z1Gxw32/113.webp"
      },
      {
        page: 27,
        link: "https://i.ibb.co/DMvVX5T/114.webp"
      },
      {
        page: 28,
        link: "https://i.ibb.co/khB659c/115.webp"
      },
      {
        page: 29,
        link: "https://i.ibb.co/xhZSvk1/116.webp"
      },
      {
        page: 30,
        link: "https://i.ibb.co/dgx12DY/117.webp"
      },
      {
        page: 31,
        link: "https://i.ibb.co/QvTXj1c/118.webp"
      },
      {
        page: 32,
        link: "https://i.ibb.co/dfVnFGf/119.webp"
      },
      {
        page: 33,
        link: "https://i.ibb.co/FzmgFFQ/120.webp"
      },
      {
        page: 34,
        link: "https://i.ibb.co/BtKzk2r/121.webp"
      },
      {
        page: 35,
        link: "https://i.ibb.co/XJk2M6y/122.webp"
      }
]


export default listePages;