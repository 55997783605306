import React from 'react';

export default function LegalContainer() {
  return (
    <>
      <section className="legal-section">
        <div>
          <h1>AVERTISSEMENT LÉGAL</h1>
          <p>Le site streaming-espace.fr n'héberge aucun fichier vidéo.</p>
          <p>Le site streaming-espace.fr ne fait que répertorier du contenu indirect que l'on peut trouver sur diverses plateformes d'hébergement vidéo publiques et légalement reconnues telles que YouTube, Dailymotion ou Sibnet.ru. Nous ne pouvons donc en aucun cas être tenu responsable pour le contenu disponible sur ces plates-formes d'hébergement.</p>
          <p>La loi vous oblige à posséder l'oeuvre originale pour pouvoir la regarder sur internet.</p>
          <p>En cas de non respect de cette règle, le site streaming-espace.fr et son hébergeur ne pourront être tenus responsables d’éventuels téléchargements ou visualisations en streaming d'oeuvres sous copyright (qui constitueraient alors une infraction de la loi n° 78-17 du 6 janvier 1978, relative à l’informatique, aux fichiers et aux libertés).</p>
          <p>En visualisant les vidéos de ce site, vous acceptez toutes ces conditions.</p>
        </div>
		  </section>
    </>
  );
}