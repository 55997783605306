const listePages = [
    {
      "page": 1,
      "link": "https://i.ibb.co/PTZSZLM/0095-001.webp"
    },
    {
      "page": 2,
      "link": "https://i.ibb.co/JcrrHnM/0095-002.webp"
    },
    {
      "page": 3,
      "link": "https://i.ibb.co/Qf7kxVW/0095-003.webp"
    },
    {
      "page": 4,
      "link": "https://i.ibb.co/qmd0rYw/0095-004.webp"
    },
    {
      "page": 5,
      "link": "https://i.ibb.co/GQ09793/0095-005.webp"
    },
    {
      "page": 6,
      "link": "https://i.ibb.co/rF20WYr/0095-006.webp"
    },
    {
      "page": 7,
      "link": "https://i.ibb.co/PhwfpFH/0095-007.webp"
    },
    {
      "page": 8,
      "link": "https://i.ibb.co/G5m8k4T/0095-008.webp"
    },
    {
      "page": 9,
      "link": "https://i.ibb.co/RTbwG0S/0095-009.webp"
    },
    {
      "page": 10,
      "link": "https://i.ibb.co/cYpycxq/0095-010.webp"
    },
    {
      "page": 11,
      "link": "https://i.ibb.co/kKGVvvb/0095-011.webp"
    },
    {
      "page": 12,
      "link": "https://i.ibb.co/XZkzSC2/0095-012.webp"
    },
    {
      "page": 13,
      "link": "https://i.ibb.co/Cw2djnW/0095-013.webp"
    },
    {
      "page": 14,
      "link": "https://i.ibb.co/j3hZndK/0095-014.webp"
    },
    {
      "page": 15,
      "link": "https://i.ibb.co/pZpsZmM/0095-015.webp"
    },
    {
      "page": 16,
      "link": "https://i.ibb.co/SDTv91y/0095-016.webp"
    },
    {
      "page": 17,
      "link": "https://i.ibb.co/vvcSJM6/0095-017.webp"
    },
    {
      "page": 18,
      "link": "https://i.ibb.co/jGSkC3R/0095-018.webp"
    },
    {
      "page": 19,
      "link": "https://i.ibb.co/FKfpTT0/0095-019.webp"
    },
    {
      "page": 20,
      "link": "https://i.ibb.co/82ht96p/0095-020.webp"
    },
    {
      "page": 21,
      "link": "https://i.ibb.co/ZTqMB22/0095-021.webp"
    },
    {
      "page": 22,
      "link": "https://i.ibb.co/2NLnwRP/0095-022.webp"
    },
    {
      "page": 23,
      "link": "https://i.ibb.co/Vmk73Ty/0095-023.webp"
    },
    {
      "page": 24,
      "link": "https://i.ibb.co/Y3HLqKZ/0095-024.webp"
    },
    {
      "page": 25,
      "link": "https://i.ibb.co/NC8ZpY4/0095-025.webp"
    },
    {
      "page": 26,
      "link": "https://i.ibb.co/WW7w2z4/0095-026.webp"
    },
    {
      "page": 27,
      "link": "https://i.ibb.co/7GRXxNq/0095-027.webp"
    },
    {
      "page": 28,
      "link": "https://i.ibb.co/pKhR99J/0095-028.webp"
    },
    {
      "page": 29,
      "link": "https://i.ibb.co/cgGKR5p/0095-029.webp"
    },
    {
      "page": 30,
      "link": "https://i.ibb.co/BnZ91DK/0095-030.webp"
    }
  ]
  

export default listePages;