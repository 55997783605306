const listePages = [
  { page: 1, link: "https://i.ibb.co/hdVCPNS/0054-001.webp" },
  { page: 2, link: "https://i.ibb.co/g6tBh7y/0054-002.webp" },
  { page: 3, link: "https://i.ibb.co/vqSSPC8/0054-003.webp" },
  { page: 4, link: "https://i.ibb.co/CBfZPbs/0054-004.webp" },
  { page: 5, link: "https://i.ibb.co/Swt68Nb/0054-005.webp" },
  { page: 6, link: "https://i.ibb.co/jrMhXqX/0054-006.webp" },
  { page: 7, link: "https://i.ibb.co/n1Hvz1x/0054-007.webp" },
  { page: 8, link: "https://i.ibb.co/3yCcFD7/0054-008.webp" },
  { page: 9, link: "https://i.ibb.co/4VL5FSQ/0054-009.webp" },
  { page: 10, link: "https://i.ibb.co/QPGYxD3/0054-010.webp" },
  { page: 11, link: "https://i.ibb.co/2s1y3n7/0054-011.webp" },
  { page: 12, link: "https://i.ibb.co/2Nv34rB/0054-012.webp" },
  { page: 13, link: "https://i.ibb.co/wrGCPhX/0054-013.webp" },
  { page: 14, link: "https://i.ibb.co/5B6Y3Qz/0054-014.webp" },
  { page: 15, link: "https://i.ibb.co/fXzFkrb/0054-015.webp" },
  { page: 16, link: "https://i.ibb.co/1MtQwNP/0054-016.webp" },
  { page: 17, link: "https://i.ibb.co/gF4PVXg/0054-017.webp" },
  { page: 18, link: "https://i.ibb.co/HxNfYTD/0054-018.webp" },
  { page: 19, link: "https://i.ibb.co/x2wmC48/0054-019.webp" },
  { page: 20, link: "https://i.ibb.co/FbPfBcc/0054-020.webp" },
  { page: 21, link: "https://i.ibb.co/YRxv4gk/0054-021.webp" }
]


export default listePages;