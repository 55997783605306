const listePages = [
    { page: 1, link: "https://i.ibb.co/8bg3rkJ/73-1-001.webp" },
    { page: 2, link: "https://i.ibb.co/q136vKC/73-1-002.webp" },
    { page: 3, link: "https://i.ibb.co/NyHcqQR/73-1-003.webp" },
    { page: 4, link: "https://i.ibb.co/G9K0yz9/73-1-004.webp" },
    { page: 5, link: "https://i.ibb.co/K2L0C1H/73-1-005.webp" },
    { page: 6, link: "https://i.ibb.co/2k3vJhZ/73-1-006.webp" },
    { page: 7, link: "https://i.ibb.co/LtnvwX2/73-1-007.webp" },
    { page: 8, link: "https://i.ibb.co/zJPgXpf/73-1-008.webp" },
    { page: 9, link: "https://i.ibb.co/G2FBMy4/73-1-009.webp" },
    { page: 10, link: "https://i.ibb.co/47yxLT6/73-1-010.webp" },
    { page: 11, link: "https://i.ibb.co/K5kvDJN/73-1-011.webp" },
    { page: 12, link: "https://i.ibb.co/xqt7zPQ/73-1-012.webp" }
  ]
  

export default listePages;