const listePages = [
    {
        "page": 1,
        "link": "https://i.ibb.co/GMJ7M9z/0045.webp"
      },
      {
        "page": 2,
        "link": "https://i.ibb.co/Ctfv6QL/0046.webp"
      },
      {
        "page": 3,
        "link": "https://i.ibb.co/xJbLqG8/0047.webp"
      },
      {
        "page": 4,
        "link": "https://i.ibb.co/K2BB0rN/0048.webp"
      },
      {
        "page": 5,
        "link": "https://i.ibb.co/5rj8DHF/0049.webp"
      },
      {
        "page": 6,
        "link": "https://i.ibb.co/88pyZq7/0050.webp"
      },
      {
        "page": 7,
        "link": "https://i.ibb.co/tx9CtG7/0051.webp"
      },
      {
        "page": 8,
        "link": "https://i.ibb.co/fQ4HQ3t/0052.webp"
      },
      {
        "page": 9,
        "link": "https://i.ibb.co/L8k53Yz/0053.webp"
      },
      {
        "page": 10,
        "link": "https://i.ibb.co/cKBwGC9/0054.webp"
      },
      {
        "page": 11,
        "link": "https://i.ibb.co/7VMkb2V/0055.webp"
      },
      {
        "page": 12,
        "link": "https://i.ibb.co/BnrkK19/0056.webp"
      },
      {
        "page": 13,
        "link": "https://i.ibb.co/h9dGgDx/0057.webp"
      },
      {
        "page": 14,
        "link": "https://i.ibb.co/qN94xk4/0058.webp"
      },
      {
        "page": 15,
        "link": "https://i.ibb.co/59ZGg0m/0059.webp"
      },
      {
        "page": 16,
        "link": "https://i.ibb.co/mcHXyDT/0060.webp"
      },
      {
        "page": 17,
        "link": "https://i.ibb.co/MBkcPrj/0061.webp"
      },
      {
        "page": 18,
        "link": "https://i.ibb.co/Kzdp2YV/0062.webp"
      },
      {
        "page": 19,
        "link": "https://i.ibb.co/dDVrC0F/0063.webp"
      },
      {
        "page": 20,
        "link": "https://i.ibb.co/sQb2rxR/0064.webp"
      },
      {
        "page": 21,
        "link": "https://i.ibb.co/gR1LrTm/0065.webp"
      },
      {
        "page": 22,
        "link": "https://i.ibb.co/ZNPWGgV/0066.webp"
      },
      {
        "page": 23,
        "link": "https://i.ibb.co/drTSMVx/0067.webp"
      },
      {
        "page": 24,
        "link": "https://i.ibb.co/PmHfyD5/0068.webp"
      },
      {
        "page": 25,
        "link": "https://i.ibb.co/nwPhTPp/0069.webp"
      },
      {
        "page": 26,
        "link": "https://i.ibb.co/yFJfXXf/0070.webp"
      },
      {
        "page": 27,
        "link": "https://i.ibb.co/Jq5TdmH/0071.webp"
      },
      {
        "page": 28,
        "link": "https://i.ibb.co/S59LH4g/0072.webp"
      },
      {
        "page": 29,
        "link": "https://i.ibb.co/WGGFPds/0073.webp"
      },
      {
        "page": 30,
        "link": "https://i.ibb.co/DYcXhgK/0074.webp"
      },
      {
        "page": 31,
        "link": "https://i.ibb.co/9sm1PQr/0075.webp"
      },
      {
        "page": 32,
        "link": "https://i.ibb.co/1fMHFfv/0076.webp"
      },
      {
        "page": 33,
        "link": "https://i.ibb.co/7XQH0r8/0077.webp"
      },
      {
        "page": 34,
        "link": "https://i.ibb.co/9hHxCDy/0078.webp"
      },
      {
        "page": 35,
        "link": "https://i.ibb.co/K54Zr45/0079.webp"
      },
      {
        "page": 36,
        "link": "https://i.ibb.co/r7g8pmC/0080.webp"
      },
      {
        "page": 37,
        "link": "https://i.ibb.co/C7Tf8fP/0081.webp"
      },
      {
        "page": 38,
        "link": "https://i.ibb.co/xHtSTjD/0082.webp"
      },
      {
        "page": 39,
        "link": "https://i.ibb.co/MhGy9JK/0083.webp"
      }
]
  

export default listePages;