const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/S3Cg3DL/0021-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/dK38snL/0021-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/ZXXt82p/0021-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/C0tdL2L/0021-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/Jz89MwP/0021-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/NN70tzM/0021-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/qdtt66n/0021-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/L1b11qX/0021-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/fvLKgSN/0021-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/qBnmZRM/0021-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/Lk8PNyd/0021-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/3SxXYRQ/0021-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/j8mLPnt/0021-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/MPwKJnw/0021-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/2Y4MnJD/0021-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/cyGBC3F/0021-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/thm9PLW/0021-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/M9qn3FY/0021-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/JKRJSTc/0021-019.webp"
  },
  {
    page: 20,
    link: "https://i.ibb.co/KyLQv30/0021-020.webp"
  },
  {
    page: 21,
    link: "https://i.ibb.co/SxC69Xw/0021-021.webp"
  },
  {
    page: 22,
    link: "https://i.ibb.co/g9hCNf5/0021-022.webp"
  },
  {
    page: 23,
    link: "https://i.ibb.co/9yLYxwc/0021-023.webp"
  },
  {
    page: 24,
    link: "https://i.ibb.co/FbSp2yP/0021-024.webp"
  },
  {
    page: 25,
    link: "https://i.ibb.co/qgbyyNs/0021-025.webp"
  },
  {
    page: 26,
    link: "https://i.ibb.co/fMnGhPH/0021-026.webp"
  },
  {
    page: 27,
    link: "https://i.ibb.co/6PMGTj4/0021-027.webp"
  },
  {
    page: 28,
    link: "https://i.ibb.co/jbz0bG6/0021-028.webp"
  },
  {
    page: 29,
    link: "https://i.ibb.co/jfJ0y0t/0021-029.webp"
  },
  {
    page: 30,
    link: "https://i.ibb.co/HPTJhRN/0021-030.webp"
  },
  {
    page: 31,
    link: "https://i.ibb.co/bPXXqrM/0021-031.webp"
  },
  {
    page: 32,
    link: "https://i.ibb.co/hdsLtVn/0021-032.webp"
  },
  {
    page: 33,
    link: "https://i.ibb.co/MVPtqxp/0021-033.webp"
  },
  {
    page: 34,
    link: "https://i.ibb.co/LhtPxKk/0021-034.webp"
  },
  {
    page: 35,
    link: "https://i.ibb.co/K7p1y0F/0021-035.webp"
  },
  {
    page: 36,
    link: "https://i.ibb.co/2hkfwtV/0021-036.webp"
  }  
]

export default listePages;