const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/Fs28xrt/0034-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/3F1B12t/0034-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/v4qnX4z/0034-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/vcVVjmR/0034-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/prcxMgf/0034-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/SdShryt/0034-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/tmZzF9z/0034-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/sp4CjrK/0034-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/WDJ6pcD/0034-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/Kw8kCXZ/0034-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/syMnXjJ/0034-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/TBYPm29/0034-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/mqsYwVq/0034-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/Tcsg94x/0034-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/ZW4W0W8/0034-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/zQTYFcY/0034-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/dMnmR2K/0034-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/QKBxqsR/0034-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/B6T5fcx/0034-019.webp"
  },
  {
    page: 20,
    link: "https://i.ibb.co/BN1RwjF/0034-020.webp"
  },
  {
    page: 21,
    link: "https://i.ibb.co/1zGFSsz/0034-021.webp"
  },
  {
    page: 22,
    link: "https://i.ibb.co/6bMqLTf/0034-022.webp"
  },
  {
    page: 23,
    link: "https://i.ibb.co/hd95RTP/0034-023.webp"
  },
  {
    page: 24,
    link: "https://i.ibb.co/W265W3K/0034-024.webp"
  },
  {
    page: 25,
    link: "https://i.ibb.co/YtbfxYG/0034-025.webp"
  },
  {
    page: 26,
    link: "https://i.ibb.co/gvp6djB/0034-026.webp"
  },
  {
    page: 27,
    link: "https://i.ibb.co/86bM8PJ/0034-027.webp"
  },
  {
    page: 28,
    link: "https://i.ibb.co/jrznfRN/0034-028.webp"
  },
  {
    page: 29,
    link: "https://i.ibb.co/Fzt5sMr/0034-029.webp"
  },
  {
    page: 30,
    link: "https://i.ibb.co/c3b8NQN/0034-030.webp"
  },
  {
    page: 31,
    link: "https://i.ibb.co/jvsM4cK/0034-031.webp"
  },
  {
    page: 32,
    link: "https://i.ibb.co/J5dWjxK/0034-032.webp"
  },
  {
    page: 33,
    link: "https://i.ibb.co/wR4R71n/0034-033.webp"
  },
  {
    page: 34,
    link: "https://i.ibb.co/SBcwZn9/0034-034.webp"
  },
  {
    page: 35,
    link: "https://i.ibb.co/XyV0Kf2/0034-035.webp"
  },
  {
    page: 36,
    link: "https://i.ibb.co/frmNfqK/0034-036.webp"
  },
  {
    page: 37,
    link: "https://i.ibb.co/FV7qGmq/0034-037.webp"
  },
  {
    page: 38,
    link: "https://i.ibb.co/PC1nSKn/0034-038.webp"
  },
  {
    page: 39,
    link: "https://i.ibb.co/bzN4y9s/0034-039.webp"
  },
  {
    page: 40,
    link: "https://i.ibb.co/rFmW0CZ/0034-040.webp"
  },
  {
    page: 41,
    link: "https://i.ibb.co/PT0jLkx/0034-041.webp"
  },
  {
    page: 42,
    link: "https://i.ibb.co/J3xgW5x/0034-042.webp"
  },
  {
    page: 43,
    link: "https://i.ibb.co/KjDbTJx/0034-043.webp"
  },
  {
    page: 44,
    link: "https://i.ibb.co/WN99zZV/0034-044.webp"
  },
  {
    page: 45,
    link: "https://i.ibb.co/YPNtH1Z/0034-045.webp"
  },
  {
    page: 46,
    link: "https://i.ibb.co/ynMc8jp/0034-046.webp"
  },
  {
    page: 47,
    link: "https://i.ibb.co/8X87nTS/0034-047.webp"
  },
  {
    page: 48,
    link: "https://i.ibb.co/NLvTqZQ/0034-048.webp"
  },
  {
    page: 49,
    link: "https://i.ibb.co/M6bkPMV/0034-049.webp"
  },
  {
    page: 50,
    link: "https://i.ibb.co/ZJMHKZ6/0034-050.webp"
  },
  {
    page: 51,
    link: "https://i.ibb.co/ZhmzxvM/0034-051.webp"
  },
  {
    page: 52,
    link: "https://i.ibb.co/7J4b2sB/0034-052.webp"
  },
  {
    page: 53,
    link: "https://i.ibb.co/hRvZWcZ/0034-053.webp"
  }
]

export default listePages;