const listePages = [
    { page: 1, link: "https://i.ibb.co/P6Jmr9W/0072-001.webp" },
    { page: 2, link: "https://i.ibb.co/zGHJctB/0072-002.webp" },
    { page: 3, link: "https://i.ibb.co/wzWk54p/0072-003.webp" },
    { page: 4, link: "https://i.ibb.co/yprCB7G/0072-004.webp" },
    { page: 5, link: "https://i.ibb.co/nn1dFFg/0072-005.webp" },
    { page: 6, link: "https://i.ibb.co/FWz5F7k/0072-006.webp" },
    { page: 7, link: "https://i.ibb.co/zbgTH4R/0072-007.webp" },
    { page: 8, link: "https://i.ibb.co/QQqbqBK/0072-008.webp" },
    { page: 9, link: "https://i.ibb.co/K7WPYXK/0072-009.webp" },
    { page: 10, link: "https://i.ibb.co/hfpF2B7/0072-010.webp" },
    { page: 11, link: "https://i.ibb.co/4TBRB04/0072-011.webp" },
    { page: 12, link: "https://i.ibb.co/CV3CmGc/0072-012.webp" },
    { page: 13, link: "https://i.ibb.co/r2wyBW1/0072-013.webp" },
    { page: 14, link: "https://i.ibb.co/xhwfpZs/0072-014.webp" },
    { page: 15, link: "https://i.ibb.co/PY9SNvT/0072-015.webp" },
    { page: 16, link: "https://i.ibb.co/0f2CbC6/0072-016.webp" },
    { page: 17, link: "https://i.ibb.co/kXF26fM/0072-017.webp" },
    { page: 18, link: "https://i.ibb.co/L19Ms3Y/0072-018.webp" },
    { page: 19, link: "https://i.ibb.co/HhFFhbd/0072-019.webp" },
    { page: 20, link: "https://i.ibb.co/rx4G4jW/0072-020.webp" },
    { page: 21, link: "https://i.ibb.co/Kz8KCRC/0072-021.webp" },
    { page: 22, link: "https://i.ibb.co/X3WRpP7/0072-022.webp" },
    { page: 23, link: "https://i.ibb.co/T0XNj36/0072-023.webp" },
    { page: 24, link: "https://i.ibb.co/wQsCLNg/0072-024.webp" },
    { page: 25, link: "https://i.ibb.co/Zm25Xqk/0072-025.webp" },
    { page: 26, link: "https://i.ibb.co/cLmdsZM/0072-026.webp" },
    { page: 27, link: "https://i.ibb.co/b70TcGM/0072-027.webp" },
    { page: 28, link: "https://i.ibb.co/9gdL2nC/0072-028.webp" },
    { page: 29, link: "https://i.ibb.co/QCr3bsx/0072-029.webp" },
    { page: 30, link: "https://i.ibb.co/PhfG5NF/0072-030.webp" },
    { page: 31, link: "https://i.ibb.co/Lg6qCkd/0072-031.webp" },
    { page: 32, link: "https://i.ibb.co/qyvRhQy/0072-032.webp" },
    { page: 33, link: "https://i.ibb.co/yF2kPbD/72-5-001.webp" },
    { page: 34, link: "https://i.ibb.co/JsMFvsP/72-5-002.webp" }
  ]
  

export default listePages;