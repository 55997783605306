const listePages = [
    {
        "page": 1,
        "link": "https://i.ibb.co/yFQY4Q2/0001.webp"
      },
      {
        "page": 2,
        "link": "https://i.ibb.co/xMfLHCC/0005.webp"
      },
      {
        "page": 3,
        "link": "https://i.ibb.co/Wt58BHw/0006.webp"
      },
      {
        "page": 4,
        "link": "https://i.ibb.co/THJCGgc/0007.webp"
      },
      {
        "page": 5,
        "link": "https://i.ibb.co/ynBQT2L/0008.webp"
      },
      {
        "page": 6,
        "link": "https://i.ibb.co/XkH6ywp/0009.webp"
      },
      {
        "page": 7,
        "link": "https://i.ibb.co/84T5yBh/0010.webp"
      },
      {
        "page": 8,
        "link": "https://i.ibb.co/tBK5K15/0011.webp"
      },
      {
        "page": 9,
        "link": "https://i.ibb.co/D8SC4hD/0012.webp"
      },
      {
        "page": 10,
        "link": "https://i.ibb.co/Q6YV5Bx/0013.webp"
      },
      {
        "page": 11,
        "link": "https://i.ibb.co/R3VmZh1/0014.webp"
      },
      {
        "page": 12,
        "link": "https://i.ibb.co/swLC593/0015.webp"
      },
      {
        "page": 13,
        "link": "https://i.ibb.co/NFKjP4b/0016.webp"
      },
      {
        "page": 14,
        "link": "https://i.ibb.co/SXR7KGS/0017.webp"
      },
      {
        "page": 15,
        "link": "https://i.ibb.co/ZcFJBvK/0018.webp"
      },
      {
        "page": 16,
        "link": "https://i.ibb.co/qx8CNSZ/0019.webp"
      },
      {
        "page": 17,
        "link": "https://i.ibb.co/vYSc6jY/0020.webp"
      },
      {
        "page": 18,
        "link": "https://i.ibb.co/vPLCDcF/0021.webp"
      },
      {
        "page": 19,
        "link": "https://i.ibb.co/fkCH8Hb/0022.webp"
      },
      {
        "page": 20,
        "link": "https://i.ibb.co/NVdBBwj/0023.webp"
      },
      {
        "page": 21,
        "link": "https://i.ibb.co/r6kQydJ/0024.webp"
      },
      {
        "page": 22,
        "link": "https://i.ibb.co/88x1k3S/0025.webp"
      },
      {
        "page": 23,
        "link": "https://i.ibb.co/9wKGzGq/0026.webp"
      },
      {
        "page": 24,
        "link": "https://i.ibb.co/Fz8F09K/0027.webp"
      },
      {
        "page": 25,
        "link": "https://i.ibb.co/Rzwbxgf/0028.webp"
      },
      {
        "page": 26,
        "link": "https://i.ibb.co/2kPy35V/0029.webp"
      },
      {
        "page": 27,
        "link": "https://i.ibb.co/p2DVgPd/0030.webp"
      },
      {
        "page": 28,
        "link": "https://i.ibb.co/G3zNRJQ/0031.webp"
      },
      {
        "page": 29,
        "link": "https://i.ibb.co/6WsT9m8/0032.webp"
      },
      {
        "page": 30,
        "link": "https://i.ibb.co/56Yb6H8/0033.webp"
      },
      {
        "page": 31,
        "link": "https://i.ibb.co/nzmR3sB/0034.webp"
      },
      {
        "page": 32,
        "link": "https://i.ibb.co/L8CSKKY/0035.webp"
      },
      {
        "page": 33,
        "link": "https://i.ibb.co/TWJY4pY/0036.webp"
      },
      {
        "page": 34,
        "link": "https://i.ibb.co/Zf7zYCH/0037.webp"
      },
      {
        "page": 35,
        "link": "https://i.ibb.co/F7S1BhY/0038.webp"
      },
      {
        "page": 36,
        "link": "https://i.ibb.co/vwdTKtc/0039.webp"
      },
      {
        "page": 37,
        "link": "https://i.ibb.co/rxZbXSj/0040.webp"
      },
      {
        "page": 38,
        "link": "https://i.ibb.co/8NC2CZv/0041.webp"
      },
      {
        "page": 39,
        "link": "https://i.ibb.co/9qWbMB7/0042.webp"
      },
      {
        "page": 40,
        "link": "https://i.ibb.co/h2X14cg/0043.webp"
      },
      {
        "page": 41,
        "link": "https://i.ibb.co/8209PFt/0044.webp"
      },
      {
        "page": 42,
        "link": "https://i.ibb.co/jb2xH4n/0045.webp"
      },
      {
        "page": 43,
        "link": "https://i.ibb.co/SrSMzgQ/0046.webp"
      },
      {
        "page": 44,
        "link": "https://i.ibb.co/0BrmYdt/0047.webp"
      },
      {
        "page": 45,
        "link": "https://i.ibb.co/dMG3W7H/0048.webp"
      },
      {
        "page": 46,
        "link": "https://i.ibb.co/fxtH06m/0049.webp"
      },
      {
        "page": 47,
        "link": "https://i.ibb.co/60GytCh/0050.webp"
      },
      {
        "page": 48,
        "link": "https://i.ibb.co/hYDrLy9/0051.webp"
      },
      {
        "page": 49,
        "link": "https://i.ibb.co/NFN88d7/0052.webp"
      },
      {
        "page": 50,
        "link": "https://i.ibb.co/0yb5LHd/0053.webp"
      }
]


export default listePages;