const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/7RNhNcP/0011-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/1Mj4S9K/0011-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/wd4v42X/0011-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/dgYB1bw/0011-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/y6Md0cn/0011-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/P6mRBsG/0011-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/8bkfxqf/0011-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/phnfbSG/0011-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/R6Pw7dW/0011-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/Np85bp7/0011-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/7C43Nnt/0011-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/wpYYbmP/0011-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/NC9xJ4G/0011-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/XLcVkB0/0011-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/s3x2xmX/0011-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/S5b5qzx/0011-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/jWCn8sy/0011-017.webp"
  }
]

export default listePages;