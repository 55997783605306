const listePages = [
  { page: 1, link: 'https://i.ibb.co/NFP1Jnw/0003-001.webp' },
  { page: 2, link: 'https://i.ibb.co/qYPxSdL/0003-002.webp' },
  { page: 3, link: 'https://i.ibb.co/2PMSW3w/0003-003.webp' },
  { page: 4, link: 'https://i.ibb.co/1bnS9gK/0003-004.webp' },
  { page: 5, link: 'https://i.ibb.co/9bfkQWc/0003-005.webp' },
  { page: 6, link: 'https://i.ibb.co/Gd2GMXq/0003-006.webp' },
  { page: 7, link: 'https://i.ibb.co/WxLRqMC/0003-007.webp' },
  { page: 8, link: 'https://i.ibb.co/0JTtTnd/0003-008.webp' },
  { page: 9, link: 'https://i.ibb.co/68wgGmH/0003-009.webp' },
  { page: 10, link: 'https://i.ibb.co/FYKwLf0/0003-010.webp' },
  { page: 11, link: 'https://i.ibb.co/9rwsGC0/0003-011.webp' },
  { page: 12, link: 'https://i.ibb.co/3m9ndDk/0003-012.webp' },
  { page: 13, link: 'https://i.ibb.co/qY8kYGF/0003-013.webp' },
  { page: 14, link: 'https://i.ibb.co/BnN7CZB/0003-014.webp' },
  { page: 15, link: 'https://i.ibb.co/jRrBTJJ/0003-015.webp' },
  { page: 16, link: 'https://i.ibb.co/jDgvRDK/0003-016.webp' },
  { page: 17, link: 'https://i.ibb.co/zNwY3n4/0003-017.webp' },
  { page: 18, link: 'https://i.ibb.co/K24mgLv/0003-018.webp' },
  { page: 19, link: 'https://i.ibb.co/JkWMdBt/0003-019.webp' },
  { page: 20, link: 'https://i.ibb.co/JRcLysv/0003-020.webp' },
  { page: 21, link: 'https://i.ibb.co/M8w8Zqn/0003-021.webp' },
  { page: 22, link: 'https://i.ibb.co/QbVkj4T/0003-022.webp' },
  { page: 23, link: 'https://i.ibb.co/3s4zXTb/0003-023.webp' },
  { page: 24, link: 'https://i.ibb.co/fSz4F1z/0003-024.webp' },
  { page: 25, link: 'https://i.ibb.co/wJcZ9Qf/0003-025.webp' },
  { page: 26, link: 'https://i.ibb.co/nD8fynh/0003-026.webp' },
  { page: 27, link: 'https://i.ibb.co/28b3GKX/0003-027.webp' },
  { page: 28, link: 'https://i.ibb.co/RcZtS9W/0003-028.webp' },
  { page: 29, link: 'https://i.ibb.co/LNJSFmy/0003-029.webp' },
  { page: 30, link: 'https://i.ibb.co/ypxkN0x/0003-030.webp' },
  { page: 31, link: 'https://i.ibb.co/VVV9NxG/0003-031.webp' },
  { page: 32, link: 'https://i.ibb.co/Pg8MZpH/0003-032.webp' },
  { page: 33, link: 'https://i.ibb.co/ngWpb7f/0003-033.webp' },
  { page: 34, link: 'https://i.ibb.co/VY3z2C5/0003-034.webp' },
  { page: 35, link: 'https://i.ibb.co/c1np20X/0003-035.webp' },
  { page: 36, link: 'https://i.ibb.co/tqXvRdy/0003-036.webp' },
  { page: 37, link: 'https://i.ibb.co/mJDXKpP/0003-037.webp' },
  { page: 38, link: 'https://i.ibb.co/37sjhWc/0003-038.webp' },
  { page: 39, link: 'https://i.ibb.co/nMgCw0v/0003-039.webp' },
  { page: 40, link: 'https://i.ibb.co/fF55PBb/0003-040.webp' }
]


export default listePages;