import React from 'react';

export default function FaqContainer() {
  return (
    <>
      <section className="faq-section">
        <div>
          <h1>FOIRE AUX QUESTIONS</h1>
          <h2>EST-CE QUE STREAMING-ESPACE EST UN SERVICE ENTIÈREMENT GRATUIT ?</h2>
          <p>Oui, le site est et sera toujours gratuit.</p>
          <h2>AI-JE BESOIN DE M’INSCRIRE POUR REGARDER SUR LE SITE ?</h2>
          <p>Non, vous n'en avez pas besoin.</p>
          <h2>QUELS RISQUES J'ENCOURS À REGARDER DES VIDÉOS EN STREAMING ?</h2>
          <p>Le streaming consiste en la consultation de contenu vidéo en continu, sans que le film, la série ou l'animé ne soit téléchargé sur le disque dur de l'internaute spectateur. La situation reste floue autour du streaming, puisque, si ce n'est pas légal, ce n'est pas non plus illégal. Le streaming ne rentre pas dans une des dispositions qui permet d'appréhender les pirates, les contrefacteurs. En droit pénal, l'interprétation de la loi étant restrictive, les lois qui comportent une sanction pénale doivent être interprétées de façon stricte, et non pas large, par le juge pénal. Le streaming échappe donc, de ce fait, au délit de contrefaçon tel qu'il est défini par le Code de la Propriété intellectuelle. Tant que vous ne stockez pas les vidéos que vous regardez sur votre disque dur, vous pouvez vous rassurer et continuer sans risque de regarder votre animé favori sur streaming-espace.fr.</p>
        </div>
      </section>
    </>
  );
}