const listePages = [
    {
      "page": 1,
      "link": "https://i.ibb.co/31NRV7M/0096-001.webp"
    },
    {
      "page": 2,
      "link": "https://i.ibb.co/r2pCq7x/0096-002.webp"
    },
    {
      "page": 3,
      "link": "https://i.ibb.co/wNsBfYj/0096-003.webp"
    },
    {
      "page": 4,
      "link": "https://i.ibb.co/8snKvJn/0096-004.webp"
    },
    {
      "page": 5,
      "link": "https://i.ibb.co/tbbj2nk/0096-005.webp"
    },
    {
      "page": 6,
      "link": "https://i.ibb.co/HXpC8sf/0096-006.webp"
    },
    {
      "page": 7,
      "link": "https://i.ibb.co/0rXmrLj/0096-007.webp"
    },
    {
      "page": 8,
      "link": "https://i.ibb.co/WfVP4SB/0096-008.webp"
    },
    {
      "page": 9,
      "link": "https://i.ibb.co/XWY33D1/0096-009.webp"
    },
    {
      "page": 10,
      "link": "https://i.ibb.co/JRJ1YZr/0096-010.webp"
    },
    {
      "page": 11,
      "link": "https://i.ibb.co/vPDsBpL/0096-011.webp"
    },
    {
      "page": 12,
      "link": "https://i.ibb.co/DGLdL0X/0096-012.webp"
    },
    {
      "page": 13,
      "link": "https://i.ibb.co/3WZY73C/0096-013.webp"
    },
    {
      "page": 14,
      "link": "https://i.ibb.co/t2Zn8d8/0096-014.webp"
    },
    {
      "page": 15,
      "link": "https://i.ibb.co/Xb8YvMB/0096-015.webp"
    },
    {
      "page": 16,
      "link": "https://i.ibb.co/9VDNq4d/0096-016.webp"
    },
    {
      "page": 17,
      "link": "https://i.ibb.co/Pjf10ZG/0096-017.webp"
    },
    {
      "page": 18,
      "link": "https://i.ibb.co/9Z46gXg/0096-018.webp"
    },
    {
      "page": 19,
      "link": "https://i.ibb.co/vhYwn4P/0096-019.webp"
    },
    {
      "page": 20,
      "link": "https://i.ibb.co/7NDtvdt/0096-020.webp"
    },
    {
      "page": 21,
      "link": "https://i.ibb.co/5h29Z4K/0096-021.webp"
    },
    {
      "page": 22,
      "link": "https://i.ibb.co/17NkGVv/0096-022.webp"
    },
    {
      "page": 23,
      "link": "https://i.ibb.co/yNr0Kfh/0096-023.webp"
    },
    {
      "page": 24,
      "link": "https://i.ibb.co/qrXmydB/0096-024.webp"
    },
    {
      "page": 25,
      "link": "https://i.ibb.co/hXPYxDJ/0096-025.webp"
    },
    {
      "page": 26,
      "link": "https://i.ibb.co/ky5nX7y/0096-026.webp"
    },
    {
      "page": 27,
      "link": "https://i.ibb.co/mJgCst2/0096-027.webp"
    },
    {
      "page": 28,
      "link": "https://i.ibb.co/nCyStLw/0096-028.webp"
    },
    {
      "page": 29,
      "link": "https://i.ibb.co/LdBwybK/0096-029.webp"
    },
    {
      "page": 30,
      "link": "https://i.ibb.co/pJsb8CX/0096-030.webp"
    }
  ]
  

export default listePages;