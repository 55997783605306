const films = [
    {
        id : 1,
        titre : "Sol Blaze",
        link : "https://video.sibnet.ru/shell.php?videoid=5227392",
        language : "vostfr"
    },
    {
        id : 2,
        titre : "Sol Blaze",
        link : "https://video.sibnet.ru/shell.php?videoid=5226602",
        language : "vf"
    }
]

export default films