const listePages = [
    { page: 1, link: "https://i.ibb.co/RbKSB1g/0059-001.webp" },
    { page: 2, link: "https://i.ibb.co/n3WBWCF/0059-002.webp" },
    { page: 3, link: "https://i.ibb.co/2Ww1w4P/0059-003.webp" },
    { page: 4, link: "https://i.ibb.co/VQ2grQv/0059-004.webp" },
    { page: 5, link: "https://i.ibb.co/TrT9G0p/0059-005.webp" },
    { page: 6, link: "https://i.ibb.co/s6tLzQ0/0059-006.webp" },
    { page: 7, link: "https://i.ibb.co/dtHKjwx/0059-007.webp" },
    { page: 8, link: "https://i.ibb.co/VJBCgFF/0059-008.webp" },
    { page: 9, link: "https://i.ibb.co/WzH53b4/0059-009.webp" },
    { page: 10, link: "https://i.ibb.co/cTHyf3v/0059-010.webp" },
    { page: 11, link: "https://i.ibb.co/B67mYqm/0059-011.webp" },
    { page: 12, link: "https://i.ibb.co/2cx8p9M/0059-012.webp" },
    { page: 13, link: "https://i.ibb.co/CWJSFsV/0059-013.webp" },
    { page: 14, link: "https://i.ibb.co/VHNjDs3/0059-014.webp" },
    { page: 15, link: "https://i.ibb.co/ryj3ZS7/0059-015.webp" },
    { page: 16, link: "https://i.ibb.co/dJTD1g8/0059-016.webp" },
    { page: 17, link: "https://i.ibb.co/vjghPwY/0059-017.webp" },
    { page: 18, link: "https://i.ibb.co/qNkWY6m/0059-018.webp" },
    { page: 19, link: "https://i.ibb.co/z6WnJ4V/0059-019.webp" },
    { page: 20, link: "https://i.ibb.co/55hnXTK/0059-020.webp" },
    { page: 21, link: "https://i.ibb.co/64zMwQ9/0059-021.webp" },
    { page: 22, link: "https://i.ibb.co/bN9j4t4/0059-022.webp" },
    { page: 23, link: "https://i.ibb.co/j4fsvWJ/0059-023.webp" },
    { page: 24, link: "https://i.ibb.co/d438f0L/0059-024.webp" },
    { page: 25, link: "https://i.ibb.co/BLQpBpk/0059-025.webp" },
    { page: 26, link: "https://i.ibb.co/N7xcC9L/0059-026.webp" },
    { page: 27, link: "https://i.ibb.co/wrj1CvR/0059-027.webp" },
    { page: 28, link: "https://i.ibb.co/PrNFNKv/0059-028.webp" },
    { page: 29, link: "https://i.ibb.co/m4t9WP1/0059-029.webp" },
    { page: 30, link: "https://i.ibb.co/JxzDqmc/0059-030.webp" },
    { page: 31, link: "https://i.ibb.co/w08qhSJ/0059-031.webp" },
    { page: 32, link: "https://i.ibb.co/RNs7DJF/0059-032.webp" }
  ]
  

export default listePages;