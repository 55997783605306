/* eslint-disable default-case */
import { Helmet } from "react-helmet"
import FilmDisplay from "../components/FilmDisplay"
import { useParams } from "react-router-dom"

export default function Film() {
    const param = useParams()
    const generationURL = param.generation
    const langageURL = param.langue

    let filmName;

    switch (generationURL) {
        case "bakuten":
            filmName = "Bakuten Shoot Beyblade - Takao VS Daichi"
            break
        case "metal":
            filmName = "Metal Fight Beyblade - Sol Blaze"
            break
    }

    return (
        <>
            <Helmet>
                <title>{filmName} {langageURL.toUpperCase()} | Streaming Espace</title>
                <link rel="canonical" href={`https://www.streaming-espace.fr/${generationURL}/film/${langageURL}`}/>
                <meta name="description" content={"Découvre le film " + filmName + " en " + langageURL.toUpperCase() + "."} />
            </Helmet>
            <main className="content container Film">
                <FilmDisplay />
            </main>                
        </>
    )       
}