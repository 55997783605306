const listePages = [
    {
        "page": 1,
        "link": "https://i.ibb.co/T1kYGpj/0088.webp"
      },
      {
        "page": 2,
        "link": "https://i.ibb.co/xjpxgH5/0089.webp"
      },
      {
        "page": 3,
        "link": "https://i.ibb.co/MRtnR58/0090.webp"
      },
      {
        "page": 4,
        "link": "https://i.ibb.co/VY74SdQ/0091.webp"
      },
      {
        "page": 5,
        "link": "https://i.ibb.co/pfPPpPh/0092.webp"
      },
      {
        "page": 6,
        "link": "https://i.ibb.co/C9p9VNM/0093.webp"
      },
      {
        "page": 7,
        "link": "https://i.ibb.co/61XDsnB/0094.webp"
      },
      {
        "page": 8,
        "link": "https://i.ibb.co/MVt4rpL/0095.webp"
      },
      {
        "page": 9,
        "link": "https://i.ibb.co/3Fhc7Gb/0096.webp"
      },
      {
        "page": 10,
        "link": "https://i.ibb.co/GFmksLk/0097.webp"
      },
      {
        "page": 11,
        "link": "https://i.ibb.co/By3Yqc3/0098.webp"
      },
      {
        "page": 12,
        "link": "https://i.ibb.co/mF6807Y/0099.webp"
      },
      {
        "page": 13,
        "link": "https://i.ibb.co/2j3s7mp/0100.webp"
      },
      {
        "page": 14,
        "link": "https://i.ibb.co/LYhQ9gy/0101.webp"
      },
      {
        "page": 15,
        "link": "https://i.ibb.co/1ffPL8K/0102.webp"
      },
      {
        "page": 16,
        "link": "https://i.ibb.co/VTgf2Jt/0103.webp"
      },
      {
        "page": 17,
        "link": "https://i.ibb.co/F4k25Kt/0104.webp"
      },
      {
        "page": 18,
        "link": "https://i.ibb.co/Q6Gsn1v/0105.webp"
      },
      {
        "page": 19,
        "link": "https://i.ibb.co/h9TXvZS/0106.webp"
      },
      {
        "page": 20,
        "link": "https://i.ibb.co/9n5SkBk/0107.webp"
      },
      {
        "page": 21,
        "link": "https://i.ibb.co/TmmSkVG/0108.webp"
      },
      {
        "page": 22,
        "link": "https://i.ibb.co/N7BpQVM/0109.webp"
      },
      {
        "page": 23,
        "link": "https://i.ibb.co/QmcqZBT/0110.webp"
      },
      {
        "page": 24,
        "link": "https://i.ibb.co/L9fhBpf/0111.webp"
      },
      {
        "page": 25,
        "link": "https://i.ibb.co/cLFvCzm/0112.webp"
      },
      {
        "page": 26,
        "link": "https://i.ibb.co/mcwrLKX/0113.webp"
      },
      {
        "page": 27,
        "link": "https://i.ibb.co/Z6T1Gxs/0114.webp"
      },
      {
        "page": 28,
        "link": "https://i.ibb.co/T4GYZHp/0115.webp"
      },
      {
        "page": 29,
        "link": "https://i.ibb.co/R6HJdVV/0116.webp"
      },
      {
        "page": 30,
        "link": "https://i.ibb.co/k15SXbH/0117.webp"
      },
      {
        "page": 31,
        "link": "https://i.ibb.co/hf3Wr9f/0118.webp"
      },
      {
        "page": 32,
        "link": "https://i.ibb.co/QXJsNpK/0119.webp"
      },
      {
        "page": 33,
        "link": "https://i.ibb.co/mFd4bQC/0120.webp"
      },
      {
        "page": 34,
        "link": "https://i.ibb.co/JpyfwJ4/0121.webp"
      },
      {
        "page": 35,
        "link": "https://i.ibb.co/JmSjKzp/0122.webp"
      },
      {
        "page": 36,
        "link": "https://i.ibb.co/6tKWhcq/0123.webp"
      },
      {
        "page": 37,
        "link": "https://i.ibb.co/5cgNz8D/0124.webp"
      },
      {
        "page": 38,
        "link": "https://i.ibb.co/3M7N30L/0125.webp"
      },
      {
        "page": 39,
        "link": "https://i.ibb.co/Lhkv8m2/0126.webp"
      },
      {
        "page": 40,
        "link": "https://i.ibb.co/tHhKCvF/0127.webp"
      },
      {
        "page": 41,
        "link": "https://i.ibb.co/YZ85Ygm/0128.webp"
      },
      {
        "page": 42,
        "link": "https://i.ibb.co/VVh6Mrv/0129.webp"
      },
      {
        "page": 43,
        "link": "https://i.ibb.co/z5L84Hp/0130.webp"
      }
]


export default listePages;