const listePages = [
  {
    "page": 1,
    "link": "https://i.ibb.co/tJkmG92/0099-001.webp"
  },
  {
    "page": 2,
    "link": "https://i.ibb.co/y89pDfx/0099-002.webp"
  },
  {
    "page": 3,
    "link": "https://i.ibb.co/wQwwKt8/0099-003.webp"
  },
  {
    "page": 4,
    "link": "https://i.ibb.co/MDbzWxF/0099-004.webp"
  },
  {
    "page": 5,
    "link": "https://i.ibb.co/QYjrvJP/0099-005.webp"
  },
  {
    "page": 6,
    "link": "https://i.ibb.co/qk7Zrt3/0099-006.webp"
  },
  {
    "page": 7,
    "link": "https://i.ibb.co/s9nmSXX/0099-007.webp"
  },
  {
    "page": 8,
    "link": "https://i.ibb.co/hYSr4ZB/0099-008.webp"
  },
  {
    "page": 9,
    "link": "https://i.ibb.co/Hg2XR3Z/0099-009.webp"
  },
  {
    "page": 10,
    "link": "https://i.ibb.co/HhyWT3j/0099-010.webp"
  },
  {
    "page": 11,
    "link": "https://i.ibb.co/9TQhkyr/0099-011.webp"
  },
  {
    "page": 12,
    "link": "https://i.ibb.co/RCnTs2Z/0099-012.webp"
  },
  {
    "page": 13,
    "link": "https://i.ibb.co/cc5DmyZ/0099-013.webp"
  },
  {
    "page": 14,
    "link": "https://i.ibb.co/ftNq9Ys/0099-014.webp"
  },
  {
    "page": 15,
    "link": "https://i.ibb.co/ypdxGZg/0099-015.webp"
  },
  {
    "page": 16,
    "link": "https://i.ibb.co/BctxP0N/0099-016.webp"
  },
  {
    "page": 17,
    "link": "https://i.ibb.co/0X3Z3c9/0099-017.webp"
  },
  {
    "page": 18,
    "link": "https://i.ibb.co/jrXyGBB/0099-018.webp"
  },
  {
    "page": 19,
    "link": "https://i.ibb.co/7bq9fSS/0099-019.webp"
  },
  {
    "page": 20,
    "link": "https://i.ibb.co/RvswWvh/0099-020.webp"
  },
  {
    "page": 21,
    "link": "https://i.ibb.co/yggXxsK/0099-021.webp"
  },
  {
    "page": 22,
    "link": "https://i.ibb.co/80G4jrx/0099-022.webp"
  },
  {
    "page": 23,
    "link": "https://i.ibb.co/xJFs39p/0099-023.webp"
  },
  {
    "page": 24,
    "link": "https://i.ibb.co/9pmx6zd/0099-024.webp"
  },
  {
    "page": 25,
    "link": "https://i.ibb.co/6HTTzgn/0099-025.webp"
  },
  {
    "page": 26,
    "link": "https://i.ibb.co/G3hNsjL/0099-026.webp"
  },
  {
    "page": 27,
    "link": "https://i.ibb.co/Qmn8mjN/0099-027.webp"
  },
  {
    "page": 28,
    "link": "https://i.ibb.co/wRvRLxg/0099-028.webp"
  },
  {
    "page": 29,
    "link": "https://i.ibb.co/Mh8txTY/99-5-001.webp"
  },
  {
    "page": 30,
    "link": "https://i.ibb.co/cLPDQgv/99-5-002.webp"
  },
  {
    "page": 31,
    "link": "https://i.ibb.co/zSWM8c9/99-5-003.webp"
  },
  {
    "page": 32,
    "link": "https://i.ibb.co/qdFfVSr/99-5-004.webp"
  },
  {
    "page": 33,
    "link": "https://i.ibb.co/w7vWy2h/99-5-005.webp"
  },
  {
    "page": 34,
    "link": "https://i.ibb.co/q9Lx9FQ/99-5-006.webp"
  },
  {
    "page": 35,
    "link": "https://i.ibb.co/pyXL0Y7/99-5-007.webp"
  },
  {
    "page": 36,
    "link": "https://i.ibb.co/94b9J8z/99-5-008.webp"
  }
]

export default listePages;