const listePages = [
  { page: 1, link: 'https://i.ibb.co/vLc1f6Z/001.webp' },
  { page: 2, link: 'https://i.ibb.co/w0yd2Kt/002.webp' },
  { page: 3, link: 'https://i.ibb.co/8KVkTHm/003.webp' },
  { page: 4, link: 'https://i.ibb.co/t4qFcp9/004.webp' },
  { page: 5, link: 'https://i.ibb.co/YZYFc1k/005.webp' },
  { page: 6, link: 'https://i.ibb.co/5j07bfY/006.webp' },
  { page: 7, link: 'https://i.ibb.co/VVDrpLy/007.webp' },
  { page: 8, link: 'https://i.ibb.co/6n0Qk8Z/008.webp' },
  { page: 9, link: 'https://i.ibb.co/9thMZb7/009.webp' },
  { page: 10, link: 'https://i.ibb.co/fCbcscY/010.webp' },
  { page: 11, link: 'https://i.ibb.co/C2WpJdy/011-012.webp' },
  { page: 12, link: 'https://i.ibb.co/GtbZLFy/013.webp' },
  { page: 13, link: 'https://i.ibb.co/SfWt4SW/014.webp' },
  { page: 14, link: 'https://i.ibb.co/pyQHtVk/015.webp' },
  { page: 15, link: 'https://i.ibb.co/5LDLYMJ/016.webp' },
  { page: 16, link: 'https://i.ibb.co/jMw4pxQ/017.webp' },
  { page: 17, link: 'https://i.ibb.co/LRQbg0m/018.webp' },
  { page: 18, link: 'https://i.ibb.co/pznhr8D/019.webp' },
  { page: 19, link: 'https://i.ibb.co/jVSPQVG/020.webp' },
  { page: 20, link: 'https://i.ibb.co/BNBnFD4/021.webp' },
  { page: 21, link: 'https://i.ibb.co/Cm1fjXR/022.webp' },
  { page: 22, link: 'https://i.ibb.co/1KrMNtx/023.webp' },
  { page: 23, link: 'https://i.ibb.co/ChCkqpQ/024.webp' },
  { page: 24, link: 'https://i.ibb.co/NZ89Z5X/025.webp' },
  { page: 25, link: 'https://i.ibb.co/NYMnnqt/026.webp' },
  { page: 26, link: 'https://i.ibb.co/zx9hcpy/027.webp' },
  { page: 27, link: 'https://i.ibb.co/Th7tvmP/028.webp' },
  { page: 28, link: 'https://i.ibb.co/JQ7cJ9J/029.webp' },
  { page: 29, link: 'https://i.ibb.co/HpMr4y7/030.webp' },
  { page: 30, link: 'https://i.ibb.co/5k645c5/031.webp' },
  { page: 31, link: 'https://i.ibb.co/gymDTVb/032.webp' },
  { page: 32, link: 'https://i.ibb.co/tm5pxfm/033.webp' },
  { page: 33, link: 'https://i.ibb.co/SyTMxjC/034.webp' },
  { page: 34, link: 'https://i.ibb.co/G9JjVdN/035.webp' },
  { page: 35, link: 'https://i.ibb.co/Wxjzqy6/036.webp' },
  { page: 36, link: 'https://i.ibb.co/XxFHrfS/037.webp' },
  { page: 37, link: 'https://i.ibb.co/2MxDNhz/038.webp' },
  { page: 38, link: 'https://i.ibb.co/Vgw49bg/039.webp' },
  { page: 39, link: 'https://i.ibb.co/V3TDqf9/040.webp' },
  { page: 40, link: 'https://i.ibb.co/2ZgZnVS/041.webp' },
  { page: 41, link: 'https://i.ibb.co/JHL1HnG/042.webp' },
  { page: 42, link: 'https://i.ibb.co/82qFDwR/043.webp' },
  { page: 43, link: 'https://i.ibb.co/rp19sQG/044.webp' },
  { page: 44, link: 'https://i.ibb.co/BZhWvDQ/045.webp' },
  { page: 45, link: 'https://i.ibb.co/5nvNP2W/046.webp' },
  { page: 46, link: 'https://i.ibb.co/VVhzRsM/047-048.webp' },
  { page: 47, link: 'https://i.ibb.co/z4HKFXd/049.webp' }
]

export default listePages;