const listePages = [
    {
        "page": 1,
        "link": "https://i.ibb.co/Jc35fzr/0156.webp"
      },
      {
        "page": 2,
        "link": "https://i.ibb.co/LR8sQfm/0157.webp"
      },
      {
        "page": 3,
        "link": "https://i.ibb.co/7RQtpV0/0158.webp"
      },
      {
        "page": 4,
        "link": "https://i.ibb.co/M2fqbfH/0159.webp"
      },
      {
        "page": 5,
        "link": "https://i.ibb.co/0YqFZMr/0160.webp"
      },
      {
        "page": 6,
        "link": "https://i.ibb.co/fNWNG56/0161.webp"
      },
      {
        "page": 7,
        "link": "https://i.ibb.co/Vm1K7Rr/0162.webp"
      },
      {
        "page": 8,
        "link": "https://i.ibb.co/cwW7DCB/0163.webp"
      },
      {
        "page": 9,
        "link": "https://i.ibb.co/1qPQ32G/0164.webp"
      },
      {
        "page": 10,
        "link": "https://i.ibb.co/HV42DJL/0165.webp"
      },
      {
        "page": 11,
        "link": "https://i.ibb.co/fQdmZL3/0166.webp"
      },
      {
        "page": 12,
        "link": "https://i.ibb.co/nCv9nJX/0167.webp"
      },
      {
        "page": 13,
        "link": "https://i.ibb.co/VDLQrYL/0168.webp"
      },
      {
        "page": 14,
        "link": "https://i.ibb.co/D1YYPzG/0169.webp"
      },
      {
        "page": 15,
        "link": "https://i.ibb.co/5WXQJXw/0170.webp"
      },
      {
        "page": 16,
        "link": "https://i.ibb.co/Vp0FjkT/0171.webp"
      },
      {
        "page": 17,
        "link": "https://i.ibb.co/99LWVxz/0172.webp"
      },
      {
        "page": 18,
        "link": "https://i.ibb.co/949dqJk/0173.webp"
      },
      {
        "page": 19,
        "link": "https://i.ibb.co/GC7NQLs/0174.webp"
      },
      {
        "page": 20,
        "link": "https://i.ibb.co/KNWdW7N/0175.webp"
      },
      {
        "page": 21,
        "link": "https://i.ibb.co/L9bQDcT/0176.webp"
      },
      {
        "page": 22,
        "link": "https://i.ibb.co/HKT2Wtk/0177.webp"
      },
      {
        "page": 23,
        "link": "https://i.ibb.co/zFYGcRt/0178.webp"
      },
      {
        "page": 24,
        "link": "https://i.ibb.co/h7bJHWM/0179.webp"
      },
      {
        "page": 25,
        "link": "https://i.ibb.co/qFkNf0v/0180.webp"
      },
      {
        "page": 26,
        "link": "https://i.ibb.co/gSn4Btf/0181.webp"
      },
      {
        "page": 27,
        "link": "https://i.ibb.co/3M3mrmW/0182.webp"
      },
      {
        "page": 28,
        "link": "https://i.ibb.co/FnXM7qW/0183.webp"
      },
      {
        "page": 29,
        "link": "https://i.ibb.co/vhLF9M6/0184.webp"
      },
      {
        "page": 30,
        "link": "https://i.ibb.co/VSVmqgK/0185.webp"
      },
      {
        "page": 31,
        "link": "https://i.ibb.co/jb9P8Ly/0186.webp"
      }
]


export default listePages;