const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/zGW1Vmd/0032-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/5G11ch7/0032-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/vwTzC87/0032-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/vLL1Nx3/0032-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/s567ncq/0032-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/fxShX65/0032-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/LPWh2ms/0032-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/PjFkt3K/0032-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/5rsJgCG/0032-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/P6QhPxs/0032-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/DrJKc3f/0032-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/KyYWCxd/0032-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/FzN8H8n/0032-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/nrMxWfJ/0032-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/XFZWKSt/0032-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/VQd99Kx/0032-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/xDxM57P/0032-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/XCMQzHf/0032-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/ft772Fk/0032-019.webp"
  },
  {
    page: 20,
    link: "https://i.ibb.co/2WrZ3b1/0032-020.webp"
  },
  {
    page: 21,
    link: "https://i.ibb.co/KqLcqjZ/0032-021.webp"
  },
  {
    page: 22,
    link: "https://i.ibb.co/7n2d3d3/0032-022.webp"
  },
  {
    page: 23,
    link: "https://i.ibb.co/5TMrNQX/0032-023.webp"
  },
  {
    page: 24,
    link: "https://i.ibb.co/BLGbL7T/0032-024.webp"
  },
  {
    page: 25,
    link: "https://i.ibb.co/d5CnV1S/0032-025.webp"
  },
  {
    page: 26,
    link: "https://i.ibb.co/DfktMZH/0032-026.webp"
  },
  {
    page: 27,
    link: "https://i.ibb.co/7zDDs68/0032-027.webp"
  },
  {
    page: 28,
    link: "https://i.ibb.co/bsmW4fL/0032-028.webp"
  },
  {
    page: 29,
    link: "https://i.ibb.co/f8F9Hks/0032-029.webp"
  },
  {
    page: 30,
    link: "https://i.ibb.co/GFM7DWP/0032-030.webp"
  },
  {
    page: 31,
    link: "https://i.ibb.co/yRyNjSj/0032-031.webp"
  },
  {
    page: 32,
    link: "https://i.ibb.co/TYPxwJR/0032-032.webp"
  },
  {
    page: 33,
    link: "https://i.ibb.co/Yb2tdPC/0032-033.webp"
  },
  {
    page: 34,
    link: "https://i.ibb.co/0CzGRNq/0032-034.webp"
  },
  {
    page: 35,
    link: "https://i.ibb.co/P9c07Qy/0032-035.webp"
  },
  {
    page: 36,
    link: "https://i.ibb.co/PGx1xxh/0032-036.webp"
  },
  {
    page: 37,
    link: "https://i.ibb.co/KNx0xSm/0032-037.webp"
  }
]

export default listePages;