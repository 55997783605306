const listeEpisodes = [
    {
        episode : 1,
        link : "https://video.sibnet.ru/shell.php?videoid=4932027"
    },
    {
        episode : 2,
        link : "https://video.sibnet.ru/shell.php?videoid=4928616"
    },
    {
        episode : 3,
        link : "https://video.sibnet.ru/shell.php?videoid=4932065"
    },
    {
        episode : 4,
        link : "https://video.sibnet.ru/shell.php?videoid=4932074"
    },
    {
        episode : 5,
        link : "https://video.sibnet.ru/shell.php?videoid=4932090"
    },
    {
        episode : 6,
        link : "https://video.sibnet.ru/shell.php?videoid=4932093"
    },
    {
        episode : 7,
        link : "https://video.sibnet.ru/shell.php?videoid=4932098"
    },
    {
        episode : 8,
        link : "https://video.sibnet.ru/shell.php?videoid=4932110"
    },
    {
        episode : 9,
        link : "https://video.sibnet.ru/shell.php?videoid=4932119"
    },
    {
        episode : 10,
        link : "https://video.sibnet.ru/shell.php?videoid=4932123"
    },
    {
        episode : 11,
        link : "https://video.sibnet.ru/shell.php?videoid=4932124"
    },
    {
        episode : 12,
        link : "https://video.sibnet.ru/shell.php?videoid=4932133"
    },
    {
        episode : 13,
        link : "https://video.sibnet.ru/shell.php?videoid=4928617"
    },
    {
        episode : 14,
        link : "https://video.sibnet.ru/shell.php?videoid=4928618"
    },
    {
        episode : 15,
        link : "https://video.sibnet.ru/shell.php?videoid=4928620"
    },
    {
        episode : 16,
        link : "https://video.sibnet.ru/shell.php?videoid=4928621"
    },
    {
        episode : 17,
        link : "https://video.sibnet.ru/shell.php?videoid=4928622"
    },
    {
        episode : 18,
        link : "https://video.sibnet.ru/shell.php?videoid=4931195"
    },
    {
        episode : 19,
        link : "https://video.sibnet.ru/shell.php?videoid=4928624"
    },
    {
        episode : 20,
        link : "https://video.sibnet.ru/shell.php?videoid=4928625"
    },
    {
        episode : 21,
        link : "https://video.sibnet.ru/shell.php?videoid=4928626"
    },
    {
        episode : 22,
        link : "https://video.sibnet.ru/shell.php?videoid=4928628"
    },
    {
        episode : 23,
        link : "https://video.sibnet.ru/shell.php?videoid=4928630"
    },
    {
        episode : 24,
        link : "https://video.sibnet.ru/shell.php?videoid=4928631"
    },
    {
        episode : 25,
        link : "https://video.sibnet.ru/shell.php?videoid=4931200"
    },
    {
        episode : 26,
        link : "https://video.sibnet.ru/shell.php?videoid=4928645"
    },
    {
        episode : 27,
        link : "https://video.sibnet.ru/shell.php?videoid=4928646"
    },
    {
        episode : 28,
        link : "https://video.sibnet.ru/shell.php?videoid=4928647"
    },
    {
        episode : 29,
        link : "https://video.sibnet.ru/shell.php?videoid=4928649"
    },
    {
        episode : 30,
        link : "https://video.sibnet.ru/shell.php?videoid=4928650"
    },
    {
        episode : 31,
        link : "https://video.sibnet.ru/shell.php?videoid=4928651"
    },
    {
        episode : 32,
        link : "https://video.sibnet.ru/shell.php?videoid=4931204"
    },
    {
        episode : 33,
        link : "https://video.sibnet.ru/shell.php?videoid=4932134"
    },
    {
        episode : 34,
        link : "https://video.sibnet.ru/shell.php?videoid=4932136"
    },
    {
        episode : 35,
        link : "https://video.sibnet.ru/shell.php?videoid=4932147"
    },
    {
        episode : 36,
        link : "https://video.sibnet.ru/shell.php?videoid=4932158"
    },
    {
        episode : 37,
        link : "https://video.sibnet.ru/shell.php?videoid=4932167"
    },
    {
        episode : 38,
        link : "https://video.sibnet.ru/shell.php?videoid=4932177"
    },
    {
        episode : 39,
        link : "https://video.sibnet.ru/shell.php?videoid=4932194"
    },
    {
        episode : 40,
        link : "https://video.sibnet.ru/shell.php?videoid=4932210"
    },
    {
        episode : 41,
        link : "https://video.sibnet.ru/shell.php?videoid=4932233"
    },
    {
        episode : 42,
        link : "https://video.sibnet.ru/shell.php?videoid=4932235"
    },
    {
        episode : 43,
        link : "https://video.sibnet.ru/shell.php?videoid=4932238"
    },
    {
        episode : 44,
        link : "https://video.sibnet.ru/shell.php?videoid=4932243"
    },
    {
        episode : 45,
        link : "https://video.sibnet.ru/shell.php?videoid=4932248"
    },
    {
        episode : 46,
        link : "https://video.sibnet.ru/shell.php?videoid=4932255"
    },
    {
        episode : 47,
        link : "https://video.sibnet.ru/shell.php?videoid=4932257"
    },
    {
        episode : 48,
        link : "https://video.sibnet.ru/shell.php?videoid=4932262"
    },
    {
        episode : 49,
        link : "https://video.sibnet.ru/shell.php?videoid=4932264"
    },
    {
        episode : 50,
        link : "https://video.sibnet.ru/shell.php?videoid=4932268"
    },
    {
        episode : 51,
        link : "https://video.sibnet.ru/shell.php?videoid=4932272"
    }
]

export default listeEpisodes;