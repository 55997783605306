const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/7CD4ndk/0040-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/mtYPpQ1/0040-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/R2Fn0Ln/0040-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/0yG04VQ/0040-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/mzb3m01/0040-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/vXsyZNZ/0040-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/fqtwpwq/0040-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/NYk6JDj/0040-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/HgqQgM4/0040-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/5BJzKcY/0040-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/qm02d3d/0040-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/BrFSmGv/0040-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/RpTHJHC/0040-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/9n20D2b/0040-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/RCr9FP7/0040-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/BfF7J3P/0040-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/jGxSfrT/0040-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/fFKswhh/0040-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/nbSDLQF/0040-019.webp"
  },
  {
    page: 20,
    link: "https://i.ibb.co/tDnwjBX/0040-020.webp"
  },
  {
    page: 21,
    link: "https://i.ibb.co/yqT8gcc/0040-021.webp"
  },
  {
    page: 22,
    link: "https://i.ibb.co/RHPv15S/0040-022.webp"
  },
  {
    page: 23,
    link: "https://i.ibb.co/MGm5tRN/0040-023.webp"
  },
  {
    page: 24,
    link: "https://i.ibb.co/yyGS20t/0040-024.webp"
  },
  {
    page: 25,
    link: "https://i.ibb.co/WnBRH9Q/0040-025.webp"
  },
  {
    page: 26,
    link: "https://i.ibb.co/b2FPg6x/0040-026.webp"
  },
  {
    page: 27,
    link: "https://i.ibb.co/pzrKk1w/0040-027.webp"
  },
  {
    page: 28,
    link: "https://i.ibb.co/58nzyNk/0040-028.webp"
  },
  {
    page: 29,
    link: "https://i.ibb.co/NmbzF8C/0040-029.webp"
  },
  {
    page: 30,
    link: "https://i.ibb.co/6sGNWwJ/0040-030.webp"
  },
  {
    page: 31,
    link: "https://i.ibb.co/VMgxQ3d/0040-031.webp"
  },
  {
    page: 32,
    link: "https://i.ibb.co/xfbBG3Y/0040-032.webp"
  },
  {
    page: 33,
    link: "https://i.ibb.co/8DBPLVR/0040-033.webp"
  }
]


export default listePages;