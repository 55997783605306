const listePages = [
  { page: 1, link: "https://i.ibb.co/tLHnyPZ/0078-001.webp" },
  { page: 2, link: "https://i.ibb.co/s6Dtgw6/0078-002.webp" },
  { page: 3, link: "https://i.ibb.co/9H8jb1k/0078-003.webp" },
  { page: 4, link: "https://i.ibb.co/qFBYvy6/0078-004.webp" },
  { page: 5, link: "https://i.ibb.co/LJd6gkb/0078-005.webp" },
  { page: 6, link: "https://i.ibb.co/PYL9Lmh/0078-006.webp" },
  { page: 7, link: "https://i.ibb.co/L5YvvF1/0078-007.webp" },
  { page: 8, link: "https://i.ibb.co/565VzYP/0078-008.webp" },
  { page: 9, link: "https://i.ibb.co/GJ7f7R5/0078-009.webp" },
  { page: 10, link: "https://i.ibb.co/9H0jHgx/0078-010.webp" },
  { page: 11, link: "https://i.ibb.co/HnKpCPX/0078-011.webp" },
  { page: 12, link: "https://i.ibb.co/NsrpSvk/0078-012.webp" },
  { page: 13, link: "https://i.ibb.co/Gc4qqnn/0078-013.webp" },
  { page: 14, link: "https://i.ibb.co/68YXdcx/0078-014.webp" },
  { page: 15, link: "https://i.ibb.co/PxTLfXR/0078-015.webp" },
  { page: 16, link: "https://i.ibb.co/Y7wsMC5/0078-016.webp" },
  { page: 17, link: "https://i.ibb.co/f2xFkqr/0078-017.webp" },
  { page: 18, link: "https://i.ibb.co/4fQ9qXt/0078-018.webp" },
  { page: 19, link: "https://i.ibb.co/JpZ9fQG/0078-019.webp" },
  { page: 20, link: "https://i.ibb.co/2W0hgmR/0078-020.webp" },
  { page: 21, link: "https://i.ibb.co/Br7f0xG/0078-021.webp" },
  { page: 22, link: "https://i.ibb.co/FDqB7jh/0078-022.webp" },
  { page: 23, link: "https://i.ibb.co/QQtF5K3/0078-023.webp" },
  { page: 24, link: "https://i.ibb.co/nkzKmWZ/0078-024.webp" },
  { page: 25, link: "https://i.ibb.co/FKv34Pw/0078-025.webp" },
  { page: 26, link: "https://i.ibb.co/jf1GVKL/0078-026.webp" },
  { page: 27, link: "https://i.ibb.co/GRrqwJF/0078-027.webp" }
]


export default listePages;