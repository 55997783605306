const listePages = [
    {
        "page": 1,
        "link": "https://i.ibb.co/KxY0TdR/0130.webp"
      },
      {
        "page": 2,
        "link": "https://i.ibb.co/vxnWhJp/0131.webp"
      },
      {
        "page": 3,
        "link": "https://i.ibb.co/SX58J5W/0132.webp"
      },
      {
        "page": 4,
        "link": "https://i.ibb.co/1qnc3pb/0133.webp"
      },
      {
        "page": 5,
        "link": "https://i.ibb.co/VJBNXjc/0134.webp"
      },
      {
        "page": 6,
        "link": "https://i.ibb.co/7t8KSZf/0135.webp"
      },
      {
        "page": 7,
        "link": "https://i.ibb.co/pXfNb42/0136.webp"
      },
      {
        "page": 8,
        "link": "https://i.ibb.co/4mJnCTQ/0137.webp"
      },
      {
        "page": 9,
        "link": "https://i.ibb.co/zHpvTfW/0138.webp"
      },
      {
        "page": 10,
        "link": "https://i.ibb.co/X4tdtcq/0139.webp"
      },
      {
        "page": 11,
        "link": "https://i.ibb.co/rxd7BkN/0140.webp"
      },
      {
        "page": 12,
        "link": "https://i.ibb.co/7nXdq5m/0141.webp"
      },
      {
        "page": 13,
        "link": "https://i.ibb.co/44kQQ8X/0142.webp"
      },
      {
        "page": 14,
        "link": "https://i.ibb.co/ZmbzKT6/0143.webp"
      },
      {
        "page": 15,
        "link": "https://i.ibb.co/mqRfZTt/0144.webp"
      },
      {
        "page": 16,
        "link": "https://i.ibb.co/pdHHTKg/0145.webp"
      },
      {
        "page": 17,
        "link": "https://i.ibb.co/DYvGJJv/0146.webp"
      },
      {
        "page": 18,
        "link": "https://i.ibb.co/3mQ5V0S/0147.webp"
      },
      {
        "page": 19,
        "link": "https://i.ibb.co/sw5hDqS/0148.webp"
      },
      {
        "page": 20,
        "link": "https://i.ibb.co/dtWww5Q/0149.webp"
      },
      {
        "page": 21,
        "link": "https://i.ibb.co/YZD6cCG/0150.webp"
      },
      {
        "page": 22,
        "link": "https://i.ibb.co/6vBZDxK/0151.webp"
      },
      {
        "page": 23,
        "link": "https://i.ibb.co/mvdNgbq/0152.webp"
      },
      {
        "page": 24,
        "link": "https://i.ibb.co/12WcrgD/0153.webp"
      },
      {
        "page": 25,
        "link": "https://i.ibb.co/pyvCV0B/0154.webp"
      },
      {
        "page": 26,
        "link": "https://i.ibb.co/86N3CCL/0155.webp"
      },
      {
        "page": 27,
        "link": "https://i.ibb.co/FgJhwv1/0156.webp"
      },
      {
        "page": 28,
        "link": "https://i.ibb.co/xhZp9DX/0157.webp"
      },
      {
        "page": 29,
        "link": "https://i.ibb.co/fSpfBB2/0158.webp"
      },
      {
        "page": 30,
        "link": "https://i.ibb.co/87rMr5n/0159.webp"
      },
      {
        "page": 31,
        "link": "https://i.ibb.co/CznNmFN/0160.webp"
      },
      {
        "page": 32,
        "link": "https://i.ibb.co/YdxJ8fZ/0161.webp"
      },
      {
        "page": 33,
        "link": "https://i.ibb.co/xDJjK7b/0162.webp"
      },
      {
        "page": 34,
        "link": "https://i.ibb.co/DRLL7PG/0163.webp"
      },
      {
        "page": 35,
        "link": "https://i.ibb.co/jZMdrsg/0164.webp"
      },
      {
        "page": 36,
        "link": "https://i.ibb.co/cwmLY8g/0165.webp"
      },
      {
        "page": 37,
        "link": "https://i.ibb.co/McRwzKg/0166.webp"
      },
      {
        "page": 38,
        "link": "https://i.ibb.co/d04MQ6H/0167.webp"
      },
      {
        "page": 39,
        "link": "https://i.ibb.co/NNxff0C/0168.webp"
      },
      {
        "page": 40,
        "link": "https://i.ibb.co/ggF8mjj/0169.webp"
      }
]


export default listePages;