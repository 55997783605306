const listePages = [
	{ page: 1, link: 'https://i.ibb.co/ZSr27Sj/001.png' },
	{ page: 2, link: 'https://i.ibb.co/NKDhQ1t/002.png' },
	{ page: 3, link: 'https://i.ibb.co/YZVPc5K/003.png' },
	{ page: 4, link: 'https://i.ibb.co/vJwjk1f/004.png' },
	{ page: 5, link: 'https://i.ibb.co/ZfdmMzD/005-006.png' },
	{ page: 7, link: 'https://i.ibb.co/vzTLLn8/007.png' },
	{ page: 8, link: 'https://i.ibb.co/M17SMxb/008.png' },
	{ page: 9, link: 'https://i.ibb.co/tp8qPqs/009.png' },
	{ page: 10, link: 'https://i.ibb.co/7tDhhck/010.png' }
  ]
  


export default listePages;