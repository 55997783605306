const listePages = [
    {
        page: 1,
        link: "https://i.ibb.co/RckHtmL/0158.webp"
      },
      {
        page: 2,
        link: "https://i.ibb.co/vsSTwvT/0159.webp"
      },
      {
        page: 3,
        link: "https://i.ibb.co/5x8PDBy/0160.webp"
      },
      {
        page: 4,
        link: "https://i.ibb.co/y8RpVwQ/0161.webp"
      },
      {
        page: 5,
        link: "https://i.ibb.co/2hG537m/0162.webp"
      },
      {
        page: 6,
        link: "https://i.ibb.co/LdMY80P/0163.webp"
      },
      {
        page: 7,
        link: "https://i.ibb.co/D5bR9Dr/0164.webp"
      },
      {
        page: 8,
        link: "https://i.ibb.co/k2Rd6rL/0165.webp"
      },
      {
        page: 9,
        link: "https://i.ibb.co/CJ4KC7m/0166.webp"
      },
      {
        page: 10,
        link: "https://i.ibb.co/ZMfkRF6/0167.webp"
      },
      {
        page: 11,
        link: "https://i.ibb.co/9hXWGX2/0168.webp"
      },
      {
        page: 12,
        link: "https://i.ibb.co/KK95N7Q/0169.webp"
      },
      {
        page: 13,
        link: "https://i.ibb.co/BTdSy6D/0170.webp"
      },
      {
        page: 14,
        link: "https://i.ibb.co/3SFJF8V/0171.webp"
      },
      {
        page: 15,
        link: "https://i.ibb.co/kBgfvn5/0172.webp"
      },
      {
        page: 16,
        link: "https://i.ibb.co/5RPyTQM/0173.webp"
      },
      {
        page: 17,
        link: "https://i.ibb.co/qgTpkJ5/0174.webp"
      },
      {
        page: 18,
        link: "https://i.ibb.co/KqSpbdr/0175.webp"
      },
      {
        page: 19,
        link: "https://i.ibb.co/0Qj06gM/0176.webp"
      },
      {
        page: 20,
        link: "https://i.ibb.co/M8SqgPd/0177.webp"
      },
      {
        page: 21,
        link: "https://i.ibb.co/MGzdXnx/0178.webp"
      },
      {
        page: 22,
        link: "https://i.ibb.co/sgzhfMj/0179.webp"
      },
      {
        page: 23,
        link: "https://i.ibb.co/TR317Pc/0180.webp"
      },
      {
        page: 24,
        link: "https://i.ibb.co/tcHjQR4/0181.webp"
      },
      {
        page: 25,
        link: "https://i.ibb.co/xLdBJMc/0182.webp"
      },
      {
        page: 26,
        link: "https://i.ibb.co/kHX4jZd/0183.webp"
      },
      {
        page: 27,
        link: "https://i.ibb.co/7YSFKSz/0184.webp"
      },
      {
        page: 28,
        link: "https://i.ibb.co/ZWjKzgL/0185.webp"
      },
      {
        page: 29,
        link: "https://i.ibb.co/YPPXw0m/0186.webp"
      },
      {
        page: 30,
        link: "https://i.ibb.co/LrKcZMK/0187.webp"
      },
      {
        page: 31,
        link: "https://i.ibb.co/0GmKBxY/0188.webp"
      },
      {
        page: 32,
        link: "https://i.ibb.co/Bsch9BZ/0189.webp"
      },
      {
        page: 33,
        link: "https://i.ibb.co/6tTKFyt/0190.webp"
      },
      {
        page: 34,
        link: "https://i.ibb.co/BPqSPY4/0191.webp"
      },
      {
        page: 35,
        link: "https://i.ibb.co/0CLp7Pr/0192.webp"
      },
      {
        page: 36,
        link: "https://i.ibb.co/0JCPY24/0193.webp"
      },
      {
        page: 37,
        link: "https://i.ibb.co/3Ty9zKd/0194.webp"
      },
      {
        page: 38,
        link: "https://i.ibb.co/J2pKtXt/0195.webp"
      },
      {
        page: 39,
        link: "https://i.ibb.co/XWrpZWx/0196.webp"
      }
]


export default listePages;