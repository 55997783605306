const listeChapitres = [
    {
        id : 1,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 1",
        url : "chapitre-1",
        img : "https://i.ibb.co/dfBksF1/01.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-1"
    },
    {
        id : 2,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 2",
        url : "chapitre-2",
        img : "https://i.ibb.co/dfBksF1/01.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-2"
    },
    {
        id : 3,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 3",
        url : "chapitre-3",
        img : "https://i.ibb.co/dfBksF1/01.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-3"
    },
    {
        id : 4,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 4",
        url : "chapitre-4",
        img : "https://i.ibb.co/dfBksF1/01.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-4"
    },
    {
        id : 5,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 5",
        url : "chapitre-5",
        img : "https://i.ibb.co/y06HT49/tome-2.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-5"
    },
    {
        id : 6,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 6",
        url : "chapitre-6",
        img : "https://i.ibb.co/y06HT49/tome-2.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-6"
    },
    {
        id : 7,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 7",
        url : "chapitre-7",
        img : "https://i.ibb.co/y06HT49/tome-2.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-7"
    },
    {
        id : 8,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 8",
        url : "chapitre-8",
        img : "https://i.ibb.co/y06HT49/tome-2.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-8"
    },
    {
        id : 9,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 9",
        url : "chapitre-9",
        img : "https://i.ibb.co/y06HT49/tome-2.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-9"
    },
    {
        id : 10,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 10",
        url : "chapitre-10",
        img : "https://i.ibb.co/zVny0JX/tome-3.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-10"
    },
    {
        id : 11,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 11",
        url : "chapitre-11",
        img : "https://i.ibb.co/zVny0JX/tome-3.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-11"
    },
    {
        id : 12,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 12",
        url : "chapitre-12",
        img : "https://i.ibb.co/zVny0JX/tome-3.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-12"
    },
    {
        id : 13,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 13",
        url : "chapitre-13",
        img : "https://i.ibb.co/zVny0JX/tome-3.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-13"
    },
    {
        id : 14,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 14",
        url : "chapitre-14",
        img : "https://i.ibb.co/zVny0JX/tome-3.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-14"
    },
    {
        id : 15,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 15",
        url : "chapitre-15",
        img : "https://i.ibb.co/z4wH3Zq/tome-4.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-15"
    },
    {
        id : 16,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 16",
        url : "chapitre-16",
        img : "https://i.ibb.co/z4wH3Zq/tome-4.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-16"
    },
    {
        id : 17,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 17",
        url : "chapitre-17",
        img : "https://i.ibb.co/z4wH3Zq/tome-4.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-17"
    },
    {
        id : 18,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 18",
        url : "chapitre-18",
        img : "https://i.ibb.co/z4wH3Zq/tome-4.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-18"
    },
    {
        id : 19,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 19",
        url : "chapitre-19",
        img : "https://i.ibb.co/z4wH3Zq/tome-4.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-19"
    },
    {
        id : 19.5,
        titre : "Metal Fight Beyblade",
        chapitre : "Épisode Spécial",
        url : "chapitre-19.5",
        img : "https://i.ibb.co/z4wH3Zq/tome-4.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-19.5"
    },
    {
        id : 20,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 20",
        url : "chapitre-20",
        img : "https://i.ibb.co/K9b9Kpv/tome-5.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-20"
    },
    {
        id : 21,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 21",
        url : "chapitre-21",
        img : "https://i.ibb.co/K9b9Kpv/tome-5.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-21"
    },
    {
        id : 22,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 22",
        url : "chapitre-22",
        img : "https://i.ibb.co/K9b9Kpv/tome-5.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-22"
    },
    {
        id : 23,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 23",
        url : "chapitre-23",
        img : "https://i.ibb.co/K9b9Kpv/tome-5.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-23"
    },
    {
        id : 24,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 24",
        url : "chapitre-24",
        img : "https://i.ibb.co/f84t0JW/tome-6.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-24"
    },
    {
        id : 25,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 25",
        url : "chapitre-25",
        img : "https://i.ibb.co/f84t0JW/tome-6.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-25"
    },
    {
        id : 26,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 26",
        url : "chapitre-26",
        img : "https://i.ibb.co/f84t0JW/tome-6.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-26"
    },
    {
        id : 26.5,
        titre : "Metal Fight Beyblade",
        chapitre : "Bonus - Le Film",
        url : "chapitre-26.5",
        img : "https://i.ibb.co/f84t0JW/tome-6.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-26.5"
    },
    {
        id : 27,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 27",
        url : "chapitre-27",
        img : "https://i.ibb.co/ZhfHDJ1/tome-7.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-27"
    },
    {
        id : 28,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 28",
        url : "chapitre-28",
        img : "https://i.ibb.co/ZhfHDJ1/tome-7.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-28"
    },
    {
        id : 29,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 29",
        url : "chapitre-29",
        img : "https://i.ibb.co/ZhfHDJ1/tome-7.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-29"
    },
    {
        id : 30,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 30",
        url : "chapitre-30",
        img : "https://i.ibb.co/ZhfHDJ1/tome-7.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-30"
    },
    {
        id : 30.5,
        titre : "Metal Fight Beyblade",
        chapitre : "Épisode Spécial",
        url : "chapitre-30.5",
        img : "https://i.ibb.co/ZhfHDJ1/tome-7.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-30.5"
    },
    {
        id : 31,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 31",
        url : "chapitre-31",
        img : "https://i.ibb.co/PDDYgff/tome-8.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-31"
    },
    {
        id : 32,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 32",
        url : "chapitre-32",
        img : "https://i.ibb.co/PDDYgff/tome-8.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-32"
    },
    {
        id : 33,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 33",
        url : "chapitre-33",
        img : "https://i.ibb.co/PDDYgff/tome-8.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-33"
    },
    {
        id : 33.5,
        titre : "Metal Fight Beyblade",
        chapitre : "Épisode Spécial",
        url : "chapitre-33.5",
        img : "https://i.ibb.co/PDDYgff/tome-8.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-33.5"
    },
    {
        id : 34,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 34",
        url : "chapitre-34",
        img : "https://i.ibb.co/PDDYgff/tome-8.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-34"
    },
    {
        id : 35,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 35",
        url : "chapitre-35",
        img : "https://i.ibb.co/HFynKHz/tome-9.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-35"
    },
    {
        id : 36,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 36",
        url : "chapitre-36",
        img : "https://i.ibb.co/HFynKHz/tome-9.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-36"
    },
    {
        id : 37,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 37",
        url : "chapitre-37",
        img : "https://i.ibb.co/HFynKHz/tome-9.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-37"
    },
    {
        id : 38,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 38",
        url : "chapitre-38",
        img : "https://i.ibb.co/HFynKHz/tome-9.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-38"
    },
    {
        id : 38.5,
        titre : "Metal Fight Beyblade",
        chapitre : "Épisode Spécial",
        url : "chapitre-38.5",
        img : "https://i.ibb.co/HFynKHz/tome-9.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-38.5"
    },
    {
        id : 39,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 39",
        url : "chapitre-39",
        img : "https://i.ibb.co/YQfYjK3/tome-10.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-39"
    },
    {
        id : 40,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 40",
        url : "chapitre-40",
        img : "https://i.ibb.co/YQfYjK3/tome-10.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-40"
    },
    {
        id : 41,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 41",
        url : "chapitre-41",
        img : "https://i.ibb.co/YQfYjK3/tome-10.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-41"
    },
    {
        id : 42,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 42",
        url : "chapitre-42",
        img : "https://i.ibb.co/YQfYjK3/tome-10.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-42"
    },
    {
        id : 42.5,
        titre : "Metal Fight Beyblade",
        chapitre : "Épisode Spécial",
        url : "chapitre-42.5",
        img : "https://i.ibb.co/YQfYjK3/tome-10.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-42.5"
    },
    {
        id : 42.6,
        titre : "Metal Fight Beyblade",
        chapitre : "Épisode Spécial 1",
        url : "chapitre-42.5-1",
        img : "https://i.ibb.co/kBWSJjC/tome-11.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-42.5-1"
    },
    {
        id : 42.7,
        titre : "Metal Fight Beyblade",
        chapitre : "Épisode Spécial 2",
        url : "chapitre-42.5-2",
        img : "https://i.ibb.co/kBWSJjC/tome-11.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-42.5-2"
    },
    {
        id : 43,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 43",
        url : "chapitre-43",
        img : "https://i.ibb.co/kBWSJjC/tome-11.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-43"
    },
    {
        id : 44,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 44",
        url : "chapitre-44",
        img : "https://i.ibb.co/kBWSJjC/tome-11.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-44"
    },
    {
        id : 45,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 45",
        url : "chapitre-45",
        img : "https://i.ibb.co/kBWSJjC/tome-11.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-45"
    },
    {
        id : 46,
        titre : "Metal Fight Beyblade",
        chapitre : "Chapitre 46",
        url : "chapitre-46",
        img : "https://i.ibb.co/kBWSJjC/tome-11.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-46"
    },
    {
        id : 47,
        titre : "Metal Fight Beyblade Zero-G",
        chapitre : "Chapitre 47",
        url : "chapitre-47",
        img : "https://i.ibb.co/yf3nGfN/volume-1-001.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-47"
    },
    {
        id : 48,
        titre : "Metal Fight Beyblade Zero-G",
        chapitre : "Chapitre 48",
        url : "chapitre-48",
        img : "https://i.ibb.co/yf3nGfN/volume-1-001.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-48"
    },
    {
        id : 49,
        titre : "Metal Fight Beyblade Zero-G",
        chapitre : "Chapitre 49",
        url : "chapitre-49",
        img : "https://i.ibb.co/yf3nGfN/volume-1-001.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-49"
    },
    {
        id : 50,
        titre : "Metal Fight Beyblade Zero-G",
        chapitre : "Chapitre 50",
        url : "chapitre-50",
        img : "https://i.ibb.co/yf3nGfN/volume-1-001.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-50"
    },
    {
        id : 51,
        titre : "Metal Fight Beyblade Zero-G",
        chapitre : "Chapitre 51",
        url : "chapitre-51",
        img : "https://i.ibb.co/yf3nGfN/volume-1-001.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-51"
    },
    {
        id : 52,
        titre : "Metal Fight Beyblade Zero-G",
        chapitre : "Chapitre 52",
        url : "chapitre-52",
        img : "https://i.ibb.co/JcGvBgZ/9782820315540-1-7.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-52"
    },
    {
        id : 53,
        titre : "Metal Fight Beyblade Zero-G",
        chapitre : "Chapitre 53",
        url : "chapitre-53",
        img : "https://i.ibb.co/JcGvBgZ/9782820315540-1-7.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-53"
    },
    {
        id : 54,
        titre : "Metal Fight Beyblade Zero-G",
        chapitre : "Chapitre 54",
        url : "chapitre-54",
        img : "https://i.ibb.co/JcGvBgZ/9782820315540-1-7.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-54"
    },
    {
        id : 55,
        titre : "Metal Fight Beyblade Zero-G",
        chapitre : "Chapitre 55",
        url : "chapitre-55",
        img : "https://i.ibb.co/JcGvBgZ/9782820315540-1-7.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-55"
    },
    {
        id : 55.5,
        titre : "Metal Fight Beyblade Zero-G",
        chapitre : "Épisode Spécial 1",
        url : "chapitre-55.5-1",
        img : "https://i.ibb.co/JcGvBgZ/9782820315540-1-7.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-55.5-1"
    },
    {
        id : 55.6,
        titre : "Metal Fight Beyblade Zero-G",
        chapitre : "Épisode Spécial 2",
        url : "chapitre-55.5-2",
        img : "https://i.ibb.co/JcGvBgZ/9782820315540-1-7.webp",
        vf : true,
        vflink : "/metal/scans/chapitre-55.5-2"
    }
]

export default listeChapitres