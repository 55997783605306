const listePages = [
    { page: 1, link: "https://i.ibb.co/jv5pqMt/66-5-001.webp" },
    { page: 2, link: "https://i.ibb.co/C54T0Wm/66-5-002.webp" },
    { page: 3, link: "https://i.ibb.co/f2RbPyv/66-5-003.webp" },
    { page: 4, link: "https://i.ibb.co/RhqQFMg/66-5-004.webp" },
    { page: 5, link: "https://i.ibb.co/GPHgqMk/66-5-005.webp" },
    { page: 6, link: "https://i.ibb.co/fdxMzyb/66-5-006.webp" },
    { page: 7, link: "https://i.ibb.co/NNcfFVB/66-5-007.webp" },
    { page: 8, link: "https://i.ibb.co/YfCBLFf/66-5-008.webp" },
    { page: 9, link: "https://i.ibb.co/9WvJ1Nr/66-5-009.webp" },
    { page: 10, link: "https://i.ibb.co/jL3DrV4/66-5-010.webp" }
  ]
  

export default listePages;