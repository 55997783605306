const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/xjK3rp6/14-5-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/X558DMC/14-5-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/tz82Vwn/14-5-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/m9kjKpz/14-5-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/cJjMQYY/14-5-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/nrgtY0Y/14-5-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/4NvT4k8/14-5-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/qWpqcFw/14-5-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/Ssk4N3W/14-5-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/NsjGJGZ/14-5-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/JCct7cS/14-5-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/sytGq7B/14-5-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/jzn7kzP/14-5-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/F4jDyT0/14-5-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/1bXRbhL/14-5-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/tKPmdHD/14-5-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/dtHHv9n/14-5-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/pbZHQ9G/14-5-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/GpTwT2p/14-5-019.webp"
  },
  {
    page: 20,
    link: "https://i.ibb.co/LYYvt41/14-5-020.webp"
  },
  {
    page: 21,
    link: "https://i.ibb.co/WGYPqGv/14-5-021.webp"
  },
  {
    page: 22,
    link: "https://i.ibb.co/Bg7LYzz/14-5-022.webp"
  },
  {
    page: 23,
    link: "https://i.ibb.co/qJbtQ05/14-5-023.webp"
  },
  {
    page: 24,
    link: "https://i.ibb.co/bv7WRKz/14-5-024.webp"
  },
  {
    page: 25,
    link: "https://i.ibb.co/Mcsfhxv/14-5-025.webp"
  },
  {
    page: 26,
    link: "https://i.ibb.co/Nj91sVX/14-5-026.webp"
  },
  {
    page: 27,
    link: "https://i.ibb.co/CJ8FhBT/14-5-027.webp"
  }  
]

export default listePages;