const listePages = [
  {
    "page": 1,
    "link": "https://i.ibb.co/r3xjJcy/01.webp"
  },
  {
    "page": 2,
    "link": "https://i.ibb.co/fnRrsbB/02.webp"
  },
  {
    "page": 3,
    "link": "https://i.ibb.co/MCS508n/03.webp"
  },
  {
    "page": 4,
    "link": "https://i.ibb.co/8xJqnJj/04-05.webp"
  },
  {
    "page": 5,
    "link": "https://i.ibb.co/0hTHdzs/06.webp"
  },
  {
    "page": 6,
    "link": "https://i.ibb.co/FBJ9y4X/07.webp"
  },
  {
    "page": 7,
    "link": "https://i.ibb.co/d6cGsGq/08.webp"
  },
  {
    "page": 8,
    "link": "https://i.ibb.co/wyct6gT/09.webp"
  },
  {
    "page": 9,
    "link": "https://i.ibb.co/1RNrFQb/10.webp"
  },
  {
    "page": 10,
    "link": "https://i.ibb.co/chtVZVQ/11.webp"
  },
  {
    "page": 11,
    "link": "https://i.ibb.co/3hZLPhb/12-13.webp"
  },
  {
    "page": 12,
    "link": "https://i.ibb.co/cJJ193W/14.webp"
  },
  {
    "page": 13,
    "link": "https://i.ibb.co/SvJZTHN/15.webp"
  },
  {
    "page": 14,
    "link": "https://i.ibb.co/8NPrfkz/16.webp"
  },
  {
    "page": 15,
    "link": "https://i.ibb.co/ZJksqjJ/17.webp"
  },
  {
    "page": 16,
    "link": "https://i.ibb.co/LN9R4L1/18.webp"
  },
  {
    "page": 17,
    "link": "https://i.ibb.co/GpGR0TG/19-1-1.webp"
  },
  {
    "page": 18,
    "link": "https://i.ibb.co/p2x9Cm0/20-21.webp"
  },
  {
    "page": 19,
    "link": "https://i.ibb.co/sb1dGgg/22.webp"
  },
  {
    "page": 20,
    "link": "https://i.ibb.co/SQwMWdz/23.webp"
  },
  {
    "page": 21,
    "link": "https://i.ibb.co/8DCwN2B/24.webp"
  },
  {
    "page": 22,
    "link": "https://i.ibb.co/YNd6dCk/25.webp"
  },
  {
    "page": 23,
    "link": "https://i.ibb.co/SdQGNJH/26.webp"
  },
  {
    "page": 24,
    "link": "https://i.ibb.co/sgJgXLf/27.webp"
  },
  {
    "page": 25,
    "link": "https://i.ibb.co/bPYcrQ3/28.webp"
  },
  {
    "page": 26,
    "link": "https://i.ibb.co/pyZhxFX/29.webp"
  },
  {
    "page": 27,
    "link": "https://i.ibb.co/37GFn16/30.webp"
  },
  {
    "page": 28,
    "link": "https://i.ibb.co/CMqwKNG/31.webp"
  },
  {
    "page": 29,
    "link": "https://i.ibb.co/g6QBX7q/32-33.webp"
  },
  {
    "page": 30,
    "link": "https://i.ibb.co/rsSrTdG/34.webp"
  },
  {
    "page": 31,
    "link": "https://i.ibb.co/NnQFgcT/35.webp"
  },
  {
    "page": 32,
    "link": "https://i.ibb.co/6tzvqBk/36.webp"
  },
  {
    "page": 33,
    "link": "https://i.ibb.co/pQCsfFb/37.webp"
  },
  {
    "page": 34,
    "link": "https://i.ibb.co/nnWbn7S/38.webp"
  },
  {
    "page": 35,
    "link": "https://i.ibb.co/R9mVszk/39.webp"
  },
  {
    "page": 36,
    "link": "https://i.ibb.co/rmMbbw6/40.webp"
  },
  {
    "page": 37,
    "link": "https://i.ibb.co/Cv8NFvK/41.webp"
  },
  {
    "page": 38,
    "link": "https://i.ibb.co/db163kH/42.webp"
  },
  {
    "page": 39,
    "link": "https://i.ibb.co/V2094RK/43.webp"
  },
  {
    "page": 40,
    "link": "https://i.ibb.co/qnP90PZ/44.webp"
  },
  {
    "page": 41,
    "link": "https://i.ibb.co/Xzr1svQ/45.webp"
  },
  {
    "page": 42,
    "link": "https://i.ibb.co/RYXg1vd/46-47.webp"
  },
  {
    "page": 43,
    "link": "https://i.ibb.co/X3TLVP5/48.webp"
  }
]


  
export default listePages;