const listePages = [
    {
      page: 1,
      link: "https://i.ibb.co/HdGbMwT/0035-001.webp"
    },
    {
      page: 2,
      link: "https://i.ibb.co/VNft9jf/0035-002.webp"
    },
    {
      page: 3,
      link: "https://i.ibb.co/XsSTT6M/0035-003.webp"
    },
    {
      page: 4,
      link: "https://i.ibb.co/n7QFcW5/0035-004.webp"
    },
    {
      page: 5,
      link: "https://i.ibb.co/StLDWsB/0035-005.webp"
    },
    {
      page: 6,
      link: "https://i.ibb.co/wNK46vr/0035-006.webp"
    },
    {
      page: 7,
      link: "https://i.ibb.co/Bs3nRwq/0035-007.webp"
    },
    {
      page: 8,
      link: "https://i.ibb.co/DRK5Dnt/0035-008.webp"
    },
    {
      page: 9,
      link: "https://i.ibb.co/fkzqbr0/0035-009.webp"
    },
    {
      page: 10,
      link: "https://i.ibb.co/9YC7tzc/0035-010.webp"
    },
    {
      page: 11,
      link: "https://i.ibb.co/3R7VgCw/0035-011.webp"
    },
    {
      page: 12,
      link: "https://i.ibb.co/N39H0Yk/0035-012.webp"
    },
    {
      page: 13,
      link: "https://i.ibb.co/T48ks1m/0035-013.webp"
    },
    {
      page: 14,
      link: "https://i.ibb.co/xSKNw4b/0035-014.webp"
    },
    {
      page: 15,
      link: "https://i.ibb.co/P5LZQyN/0035-015.webp"
    },
    {
      page: 16,
      link: "https://i.ibb.co/JjkxpBT/0035-016.webp"
    },
    {
      page: 17,
      link: "https://i.ibb.co/yXBKpbh/0035-017.webp"
    },
    {
      page: 18,
      link: "https://i.ibb.co/0tDrj3Y/0035-018.webp"
    },
    {
      page: 19,
      link: "https://i.ibb.co/Gk5jQPv/0035-019.webp"
    },
    {
      page: 20,
      link: "https://i.ibb.co/7W7zVKH/0035-020.webp"
    },
    {
      page: 21,
      link: "https://i.ibb.co/BGM7kCz/0035-021.webp"
    },
    {
      page: 22,
      link: "https://i.ibb.co/mqW2hGr/0035-022.webp"
    },
    {
      page: 23,
      link: "https://i.ibb.co/7tJBPD7/0035-023.webp"
    },
    {
      page: 24,
      link: "https://i.ibb.co/wyHtGrw/0035-024.webp"
    },
    {
      page: 25,
      link: "https://i.ibb.co/9ZyWXnw/0035-025.webp"
    },
    {
      page: 26,
      link: "https://i.ibb.co/vPF9w0W/0035-026.webp"
    },
    {
      page: 27,
      link: "https://i.ibb.co/YjFWXK3/0035-027.webp"
    },
    {
      page: 28,
      link: "https://i.ibb.co/ZKcGKJ6/0035-028.webp"
    },
    {
      page: 29,
      link: "https://i.ibb.co/jV5VrLS/0035-029.webp"
    },
    {
      page: 30,
      link: "https://i.ibb.co/P9FXn2G/0035-030.webp"
    },
    {
      page: 31,
      link: "https://i.ibb.co/F894nQG/0035-031.webp"
    },
    {
      page: 32,
      link: "https://i.ibb.co/6vBg0dV/0035-032.webp"
    },
    {
      page: 33,
      link: "https://i.ibb.co/SwPqyD3/0035-033.webp"
    },
    {
      page: 34,
      link: "https://i.ibb.co/n0vBphX/0035-034.webp"
    },
    {
      page: 35,
      link: "https://i.ibb.co/4TcRqHV/0035-035.webp"
    },
    {
      page: 36,
      link: "https://i.ibb.co/ryJwhmF/0035-036.webp"
    },
    {
      page: 37,
      link: "https://i.ibb.co/4Jws3Tb/0035-037.webp"
    },
    {
      page: 38,
      link: "https://i.ibb.co/DVhLcgC/0035-038.webp"
    }  
]

export default listePages;