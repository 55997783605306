const listePages = [
    {
        page: 1,
        link: "https://i.ibb.co/tMkyDX0/064.webp"
      },
      {
        page: 2,
        link: "https://i.ibb.co/MS2C41T/065.webp"
      },
      {
        page: 3,
        link: "https://i.ibb.co/W6hWdrx/066.webp"
      },
      {
        page: 4,
        link: "https://i.ibb.co/yFh8dbt/067.webp"
      },
      {
        page: 5,
        link: "https://i.ibb.co/rHWb1hZ/068.webp"
      },
      {
        page: 6,
        link: "https://i.ibb.co/jMqfSFH/069.webp"
      },
      {
        page: 7,
        link: "https://i.ibb.co/bmH3RM1/070.webp"
      },
      {
        page: 8,
        link: "https://i.ibb.co/PQY740b/071.webp"
      },
      {
        page: 9,
        link: "https://i.ibb.co/N3TZxy7/072.webp"
      },
      {
        page: 10,
        link: "https://i.ibb.co/DKzKnmW/073.webp"
      },
      {
        page: 11,
        link: "https://i.ibb.co/qBWCGVS/074.webp"
      },
      {
        page: 12,
        link: "https://i.ibb.co/WG67drG/075.webp"
      },
      {
        page: 13,
        link: "https://i.ibb.co/Y8g2qdn/076.webp"
      },
      {
        page: 14,
        link: "https://i.ibb.co/v1MNzqz/077.webp"
      },
      {
        page: 15,
        link: "https://i.ibb.co/bmMcGH1/078.webp"
      },
      {
        page: 16,
        link: "https://i.ibb.co/xGb3xBx/079.webp"
      },
      {
        page: 17,
        link: "https://i.ibb.co/9cnr9KQ/080.webp"
      },
      {
        page: 18,
        link: "https://i.ibb.co/RDBTPkK/081.webp"
      },
      {
        page: 19,
        link: "https://i.ibb.co/xFtTwLB/082.webp"
      },
      {
        page: 20,
        link: "https://i.ibb.co/NZR0tb3/083.webp"
      },
      {
        page: 21,
        link: "https://i.ibb.co/P6YbtW1/084.webp"
      },
      {
        page: 22,
        link: "https://i.ibb.co/phCb4Rj/085.webp"
      },
      {
        page: 23,
        link: "https://i.ibb.co/SVQtjSy/086.webp"
      },
      {
        page: 24,
        link: "https://i.ibb.co/hfFqCdK/087.webp"
      },
      {
        page: 25,
        link: "https://i.ibb.co/GVz0dLR/051.webp"
      },
      {
        page: 26,
        link: "https://i.ibb.co/zfK3Mpm/052.webp"
      },
      {
        page: 27,
        link: "https://i.ibb.co/tHjB63B/053.webp"
      },
      {
        page: 28,
        link: "https://i.ibb.co/BLPdVNd/054.webp"
      },
      {
        page: 29,
        link: "https://i.ibb.co/xhq0F96/055.webp"
      },
      {
        page: 30,
        link: "https://i.ibb.co/jGDNCmS/056.webp"
      },
      {
        page: 31,
        link: "https://i.ibb.co/Chqnpt8/057.webp"
      },
      {
        page: 32,
        link: "https://i.ibb.co/Xjsn4BW/058.webp"
      },
      {
        page: 33,
        link: "https://i.ibb.co/rw4Xymb/059.webp"
      },
      {
        page: 34,
        link: "https://i.ibb.co/3mdZgwR/060.webp"
      },
      {
        page: 35,
        link: "https://i.ibb.co/1ztx8Yb/061.webp"
      },
      {
        page: 36,
        link: "https://i.ibb.co/SdC6jJ9/063.webp"
      },
      {
        page: 37,
        link: "https://i.ibb.co/7Kn19r2/062.webp"
      }
]


export default listePages;