const listePages = [
    { page: 1, link: "https://i.ibb.co/f9KB3NC/0081-001.webp" },
    { page: 2, link: "https://i.ibb.co/xgxpsT8/0081-002.webp" },
    { page: 3, link: "https://i.ibb.co/BzRTv5q/0081-003.webp" },
    { page: 4, link: "https://i.ibb.co/w664w4M/0081-004.webp" },
    { page: 5, link: "https://i.ibb.co/2WZ6zwx/0081-005.webp" },
    { page: 6, link: "https://i.ibb.co/Sn35mth/0081-006.webp" },
    { page: 7, link: "https://i.ibb.co/TPQsMv5/0081-007.webp" },
    { page: 8, link: "https://i.ibb.co/zNGS4Cb/0081-008.webp" },
    { page: 9, link: "https://i.ibb.co/Dz0Fpjr/0081-009.webp" },
    { page: 10, link: "https://i.ibb.co/r4zWFHC/0081-010.webp" },
    { page: 11, link: "https://i.ibb.co/zGZhx1P/0081-011.webp" },
    { page: 12, link: "https://i.ibb.co/hmzp3H3/0081-012.webp" },
    { page: 13, link: "https://i.ibb.co/zXsph6c/0081-013.webp" },
    { page: 14, link: "https://i.ibb.co/M7NhCGC/0081-014.webp" },
    { page: 15, link: "https://i.ibb.co/ccMQTSf/0081-015.webp" },
    { page: 16, link: "https://i.ibb.co/3M8n28B/0081-016.webp" },
    { page: 17, link: "https://i.ibb.co/MVB1XNt/0081-017.webp" },
    { page: 18, link: "https://i.ibb.co/kh91PhC/0081-018.webp" },
    { page: 19, link: "https://i.ibb.co/ssDpFyz/0081-019.webp" },
    { page: 20, link: "https://i.ibb.co/wpm5dmm/0081-020.webp" },
    { page: 21, link: "https://i.ibb.co/KhBFG7c/0081-021.webp" },
    { page: 22, link: "https://i.ibb.co/smrXWsS/0081-022.webp" },
    { page: 23, link: "https://i.ibb.co/SXt35xX/0081-023.webp" },
    { page: 24, link: "https://i.ibb.co/zGQFQvS/0081-024.webp" },
    { page: 25, link: "https://i.ibb.co/xfN4dhd/0081-025.webp" },
    { page: 26, link: "https://i.ibb.co/tCrqF63/0081-026.webp" },
    { page: 27, link: "https://i.ibb.co/YXgmkvT/0081-027.webp" },
    { page: 28, link: "https://i.ibb.co/N1ND6sC/0081-028.webp" },
    { page: 29, link: "https://i.ibb.co/C6WHTj3/0081-029.webp" },
    { page: 30, link: "https://i.ibb.co/MMWPgKD/0081-030.webp" }
  ]
  

export default listePages;