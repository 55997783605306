const listePages = [
  { page: 1, link: "https://i.ibb.co/v3DhV9Q/0089-001.webp" },
  { page: 2, link: "https://i.ibb.co/K7DsgbP/0089-002.webp" },
  { page: 3, link: "https://i.ibb.co/LPcVVFZ/0089-003.webp" },
  { page: 4, link: "https://i.ibb.co/FHfnpg5/0089-004.webp" },
  { page: 5, link: "https://i.ibb.co/LPDZx2f/0089-005.webp" },
  { page: 6, link: "https://i.ibb.co/fYMcn3g/0089-006.webp" },
  { page: 7, link: "https://i.ibb.co/kxMmPVp/0089-007.webp" },
  { page: 8, link: "https://i.ibb.co/hCVjj2w/0089-008.webp" },
  { page: 9, link: "https://i.ibb.co/2FL4V9X/0089-009.webp" },
  { page: 10, link: "https://i.ibb.co/0t6yvf3/0089-010.webp" },
  { page: 11, link: "https://i.ibb.co/8xFMD9t/0089-011.webp" },
  { page: 12, link: "https://i.ibb.co/KKM0c5J/0089-012.webp" },
  { page: 13, link: "https://i.ibb.co/B4Htvqm/0089-013.webp" },
  { page: 14, link: "https://i.ibb.co/1RZ87rG/0089-014.webp" },
  { page: 15, link: "https://i.ibb.co/y0Jwh3v/0089-015.webp" },
  { page: 16, link: "https://i.ibb.co/87Rmxxx/0089-016.webp" },
  { page: 17, link: "https://i.ibb.co/K9fnQ74/0089-017.webp" },
  { page: 18, link: "https://i.ibb.co/MRHmQsD/0089-018.webp" },
  { page: 19, link: "https://i.ibb.co/7CSZk74/0089-019.webp" },
  { page: 20, link: "https://i.ibb.co/ftxrkGT/0089-020.webp" },
  { page: 21, link: "https://i.ibb.co/5GBtvZp/0089-021.webp" },
  { page: 22, link: "https://i.ibb.co/d2W0xyj/0089-022.webp" },
  { page: 23, link: "https://i.ibb.co/1GmKkJ9/0089-023.webp" },
  { page: 24, link: "https://i.ibb.co/Qm4RkWR/0089-024.webp" },
  { page: 25, link: "https://i.ibb.co/yP0dSpv/0089-025.webp" },
  { page: 26, link: "https://i.ibb.co/3rnCK2v/0089-026.webp" },
  { page: 27, link: "https://i.ibb.co/ZYXSzj1/0089-027.webp" }
]


export default listePages;