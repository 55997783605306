const listePages = [
  {"page": 1, "link": "https://i.ibb.co/Yy5pLYH/001.webp"},
  {"page": 2, "link": "https://i.ibb.co/S7NLGwZ/002.webp"},
  {"page": 3, "link": "https://i.ibb.co/C1JhxXw/003.webp"},
  {"page": 4, "link": "https://i.ibb.co/8X4fJwB/004.webp"},
  {"page": 5, "link": "https://i.ibb.co/K7xPnHp/005.webp"},
  {"page": 6, "link": "https://i.ibb.co/yPhsmGy/006.webp"},
  {"page": 7, "link": "https://i.ibb.co/LrmFv1y/007.webp"},
  {"page": 8, "link": "https://i.ibb.co/3kL4qtW/008.webp"},
  {"page": 9, "link": "https://i.ibb.co/gWw2ywx/009.webp"},
  {"page": 10, "link": "https://i.ibb.co/9g8d4y3/010.webp"},
  {"page": 11, "link": "https://i.ibb.co/qjPgGV7/011.webp"},
  {"page": 12, "link": "https://i.ibb.co/6vZmZTC/012.webp"},
  {"page": 13, "link": "https://i.ibb.co/nLndrs9/013.webp"},
  {"page": 14, "link": "https://i.ibb.co/gVmmbVs/014.webp"},
  {"page": 15, "link": "https://i.ibb.co/K61xSqW/015.webp"},
  {"page": 16, "link": "https://i.ibb.co/GCpxnSD/016.webp"},
  {"page": 17, "link": "https://i.ibb.co/hgw0K0x/017.webp"},
  {"page": 18, "link": "https://i.ibb.co/sRSdDr6/018.webp"},
  {"page": 19, "link": "https://i.ibb.co/5s5FWSH/019.webp"},
  {"page": 20, "link": "https://i.ibb.co/FDvb3Rj/020.webp"},
  {"page": 21, "link": "https://i.ibb.co/51tWddV/021.webp"},
  {"page": 22, "link": "https://i.ibb.co/8zfWWgS/022.webp"},
  {"page": 23, "link": "https://i.ibb.co/47JZDWG/023.webp"},
  {"page": 24, "link": "https://i.ibb.co/rp240mC/024.webp"},
  {"page": 25, "link": "https://i.ibb.co/PGt9LnB/025.webp"},
  {"page": 26, "link": "https://i.ibb.co/yVFhY7C/026.webp"},
  {"page": 27, "link": "https://i.ibb.co/jhZpcgW/027.webp"},
  {"page": 28, "link": "https://i.ibb.co/Kqt801b/028.webp"},
  {"page": 29, "link": "https://i.ibb.co/SQPp8NC/029.webp"},
  {"page": 30, "link": "https://i.ibb.co/z8bYMR5/030.webp"},
  {"page": 31, "link": "https://i.ibb.co/2g7BBdW/031.webp"},
  {"page": 32, "link": "https://i.ibb.co/M81KbMB/032-033.webp"},
  {"page": 33, "link": "https://i.ibb.co/wJbH0Sh/034.webp"},
  {"page": 34, "link": "https://i.ibb.co/txWbFwm/035.webp"},
  {"page": 35, "link": "https://i.ibb.co/nRGb2YH/036.webp"},
  {"page": 36, "link": "https://i.ibb.co/CnBrYRs/037.webp"},
  {"page": 37, "link": "https://i.ibb.co/8PdbFP1/038.webp"},
  {"page": 38, "link": "https://i.ibb.co/8sWsq09/039.webp"},
  {"page": 39, "link": "https://i.ibb.co/SxF8r5y/040.webp"},
  {"page": 40, "link": "https://i.ibb.co/jzKR95y/041.webp"},
  {"page": 41, "link": "https://i.ibb.co/xmFrS6b/042.webp"},
  {"page": 42, "link": "https://i.ibb.co/N7nN8yQ/043.webp"},
  {"page": 43, "link": "https://i.ibb.co/RBmJbQ0/044.webp"},
  {"page": 44, "link": "https://i.ibb.co/3zw0SNw/045.webp"},
  {"page": 45, "link": "https://i.ibb.co/ZH2vjmD/046.webp"},
  {"page": 46, "link": "https://i.ibb.co/N2KNxdm/047.webp"},
  {"page": 47, "link": "https://i.ibb.co/b7zzH0x/048.webp"}
]

export default listePages;