const listePages = [
    {
        "page": 1,
        "link": "https://i.ibb.co/5YFYSsX/0054.webp"
      },
      {
        "page": 2,
        "link": "https://i.ibb.co/fCwbFNX/0055.webp"
      },
      {
        "page": 3,
        "link": "https://i.ibb.co/2gL0V9Q/0056.webp"
      },
      {
        "page": 4,
        "link": "https://i.ibb.co/kSwjvjZ/0057.webp"
      },
      {
        "page": 5,
        "link": "https://i.ibb.co/9gtRqJh/0058.webp"
      },
      {
        "page": 6,
        "link": "https://i.ibb.co/xf3xCTQ/0059.webp"
      },
      {
        "page": 7,
        "link": "https://i.ibb.co/n3BRzXV/0060.webp"
      },
      {
        "page": 8,
        "link": "https://i.ibb.co/Zfpfjfh/0061.webp"
      },
      {
        "page": 9,
        "link": "https://i.ibb.co/sbS83Xd/0062.webp"
      },
      {
        "page": 10,
        "link": "https://i.ibb.co/M2hmX4B/0063.webp"
      },
      {
        "page": 11,
        "link": "https://i.ibb.co/bXKdMjg/0064.webp"
      },
      {
        "page": 12,
        "link": "https://i.ibb.co/vPq1Jcq/0065.webp"
      },
      {
        "page": 13,
        "link": "https://i.ibb.co/PQcgcxk/0066.webp"
      },
      {
        "page": 14,
        "link": "https://i.ibb.co/BLzxdqc/0067.webp"
      },
      {
        "page": 15,
        "link": "https://i.ibb.co/yy8VKzG/0068.webp"
      },
      {
        "page": 16,
        "link": "https://i.ibb.co/mSfXs9d/0069.webp"
      },
      {
        "page": 17,
        "link": "https://i.ibb.co/qDRSrTR/0070.webp"
      },
      {
        "page": 18,
        "link": "https://i.ibb.co/WtpP7tx/0071.webp"
      },
      {
        "page": 19,
        "link": "https://i.ibb.co/0y5H1tn/0072.webp"
      },
      {
        "page": 20,
        "link": "https://i.ibb.co/d2fSyxr/0073.webp"
      },
      {
        "page": 21,
        "link": "https://i.ibb.co/CbYht7J/0074.webp"
      },
      {
        "page": 22,
        "link": "https://i.ibb.co/ZmGWhFs/0075.webp"
      },
      {
        "page": 23,
        "link": "https://i.ibb.co/VYPMVHX/0076.webp"
      },
      {
        "page": 24,
        "link": "https://i.ibb.co/Hpw90mF/0077.webp"
      },
      {
        "page": 25,
        "link": "https://i.ibb.co/2649bmL/0078.webp"
      },
      {
        "page": 26,
        "link": "https://i.ibb.co/j8fcGm3/0079.webp"
      },
      {
        "page": 27,
        "link": "https://i.ibb.co/rQxB89v/0080.webp"
      },
      {
        "page": 28,
        "link": "https://i.ibb.co/M15Z0m4/0081.webp"
      },
      {
        "page": 29,
        "link": "https://i.ibb.co/3y9G4DW/0082.webp"
      },
      {
        "page": 30,
        "link": "https://i.ibb.co/RHFStt4/0083.webp"
      },
      {
        "page": 31,
        "link": "https://i.ibb.co/7pp6p5F/0084.webp"
      },
      {
        "page": 32,
        "link": "https://i.ibb.co/fSDCtC9/0085.webp"
      },
      {
        "page": 33,
        "link": "https://i.ibb.co/Rb73zQq/0086.webp"
      },
      {
        "page": 34,
        "link": "https://i.ibb.co/sRngsgS/0087.webp"
      },
      {
        "page": 35,
        "link": "https://i.ibb.co/NmzYsP9/0088.webp"
      },
      {
        "page": 36,
        "link": "https://i.ibb.co/Xkz6SHn/0089.webp"
      },
      {
        "page": 37,
        "link": "https://i.ibb.co/vwpXK8S/0090.webp"
      },
      {
        "page": 38,
        "link": "https://i.ibb.co/wSgxfhr/0091.webp"
      },
      {
        "page": 39,
        "link": "https://i.ibb.co/8Mm892j/0092.webp"
      },
      {
        "page": 40,
        "link": "https://i.ibb.co/zhM4p6V/0093.webp"
      }
]


export default listePages;