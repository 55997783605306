const listePages = [
    { page: 1, link: "https://i.ibb.co/82Zvs06/0086-001.webp" },
    { page: 2, link: "https://i.ibb.co/ZgCxV2N/0086-002.webp" },
    { page: 3, link: "https://i.ibb.co/mqzsXrK/0086-003.webp" },
    { page: 4, link: "https://i.ibb.co/7VztLKz/0086-004.webp" },
    { page: 5, link: "https://i.ibb.co/x3vgyRf/0086-005.webp" },
    { page: 6, link: "https://i.ibb.co/9vn675T/0086-006.webp" },
    { page: 7, link: "https://i.ibb.co/g3k68sb/0086-007.webp" },
    { page: 8, link: "https://i.ibb.co/mHDYg71/0086-008.webp" },
    { page: 9, link: "https://i.ibb.co/bJRtd2c/0086-009.webp" },
    { page: 10, link: "https://i.ibb.co/Cb7pfSb/0086-010.webp" },
    { page: 11, link: "https://i.ibb.co/3rZbtDG/0086-011.webp" },
    { page: 12, link: "https://i.ibb.co/ys258r2/0086-012.webp" },
    { page: 13, link: "https://i.ibb.co/K7kGLMW/0086-013.webp" },
    { page: 14, link: "https://i.ibb.co/2Mjx9Mc/0086-014.webp" },
    { page: 15, link: "https://i.ibb.co/phjXy0m/0086-015.webp" },
    { page: 16, link: "https://i.ibb.co/HTsrvWX/0086-016.webp" },
    { page: 17, link: "https://i.ibb.co/RDbRMVB/0086-017.webp" },
    { page: 18, link: "https://i.ibb.co/W6LSWRc/0086-018.webp" },
    { page: 19, link: "https://i.ibb.co/ZfdD1H5/0086-019.webp" },
    { page: 20, link: "https://i.ibb.co/9WG43t0/0086-020.webp" },
    { page: 21, link: "https://i.ibb.co/kxw7k8d/0086-021.webp" },
    { page: 22, link: "https://i.ibb.co/s6bmYVt/0086-022.webp" },
    { page: 23, link: "https://i.ibb.co/sFqWbmZ/0086-023.webp" },
    { page: 24, link: "https://i.ibb.co/RBsXBvY/0086-024.webp" },
    { page: 25, link: "https://i.ibb.co/y5ZhRzf/0086-025.webp" },
    { page: 26, link: "https://i.ibb.co/bsk9SLR/0086-026.webp" },
    { page: 27, link: "https://i.ibb.co/FHzvVF7/0086-027.webp" },
    { page: 28, link: "https://i.ibb.co/bzS4SMT/0086-028.webp" },
    { page: 29, link: "https://i.ibb.co/p19XQDs/0086-029.webp" },
    { page: 30, link: "https://i.ibb.co/yFNm3vR/0086-030.webp" },
    { page: 31, link: "https://i.ibb.co/j4f9nLY/0086-031.webp" },
    { page: 32, link: "https://i.ibb.co/MhKzK86/0086-032.webp" },
    { page: 33, link: "https://i.ibb.co/GQMF7pQ/0086-033.webp" },
    { page: 34, link: "https://i.ibb.co/FVfwnRG/0086-034.webp" },
    { page: 35, link: "https://i.ibb.co/72jGtM8/0086-035.webp" },
    { page: 36, link: "https://i.ibb.co/SfVDyS7/0086-036.webp" }
  ]
  

export default listePages;