const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/YyzdKYQ/0048-018.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/TLDZ8Rq/0048-019.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/SNRc9g5/0048-020.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/CPbCYss/0048-021.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/2Nth1mW/0048-022.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/SQNyBrb/0048-023.webp"
  }
];


export default listePages;