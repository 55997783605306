const listePages = [
    {
        "page": 1,
        "link": "https://i.ibb.co/DfyCJjb/0049.webp"
      },
      {
        "page": 2,
        "link": "https://i.ibb.co/THXXR4M/0050.webp"
      },
      {
        "page": 3,
        "link": "https://i.ibb.co/PMqtwmJ/0051.webp"
      },
      {
        "page": 4,
        "link": "https://i.ibb.co/4FB0P7G/0052.webp"
      },
      {
        "page": 5,
        "link": "https://i.ibb.co/SrfBZmB/0053.webp"
      },
      {
        "page": 6,
        "link": "https://i.ibb.co/RTbtgHj/0054.webp"
      },
      {
        "page": 7,
        "link": "https://i.ibb.co/brvNgv8/0055.webp"
      },
      {
        "page": 8,
        "link": "https://i.ibb.co/ZVtLT4v/0056.webp"
      },
      {
        "page": 9,
        "link": "https://i.ibb.co/hBsQcKH/0057.webp"
      },
      {
        "page": 10,
        "link": "https://i.ibb.co/CsjsPXQ/0058.webp"
      },
      {
        "page": 11,
        "link": "https://i.ibb.co/6m77Y2x/0059.webp"
      },
      {
        "page": 12,
        "link": "https://i.ibb.co/Bt8MC6P/0060.webp"
      },
      {
        "page": 13,
        "link": "https://i.ibb.co/S0KjDNf/0061.webp"
      },
      {
        "page": 14,
        "link": "https://i.ibb.co/9w00smn/0062.webp"
      },
      {
        "page": 15,
        "link": "https://i.ibb.co/F86HnGK/0063.webp"
      },
      {
        "page": 16,
        "link": "https://i.ibb.co/tK8nZPj/0064.webp"
      },
      {
        "page": 17,
        "link": "https://i.ibb.co/848vZg3/0065.webp"
      },
      {
        "page": 18,
        "link": "https://i.ibb.co/C58bZRf/0066.webp"
      },
      {
        "page": 19,
        "link": "https://i.ibb.co/fkTGw5L/0067.webp"
      },
      {
        "page": 20,
        "link": "https://i.ibb.co/z2KN3dc/0068.webp"
      },
      {
        "page": 21,
        "link": "https://i.ibb.co/gvs0Wqd/0069.webp"
      },
      {
        "page": 22,
        "link": "https://i.ibb.co/bvfLMcT/0070.webp"
      },
      {
        "page": 23,
        "link": "https://i.ibb.co/c83PbhF/0071.webp"
      },
      {
        "page": 24,
        "link": "https://i.ibb.co/KLBv2BL/0072.webp"
      },
      {
        "page": 25,
        "link": "https://i.ibb.co/hZ1m9CS/0073.webp"
      },
      {
        "page": 26,
        "link": "https://i.ibb.co/dGdY1TT/0074.webp"
      },
      {
        "page": 27,
        "link": "https://i.ibb.co/HDRc1xg/0075.webp"
      },
      {
        "page": 28,
        "link": "https://i.ibb.co/YLkM1xS/0076.webp"
      },
      {
        "page": 29,
        "link": "https://i.ibb.co/cNMFNVd/0077.webp"
      },
      {
        "page": 30,
        "link": "https://i.ibb.co/pKzCkPN/0078.webp"
      },
      {
        "page": 31,
        "link": "https://i.ibb.co/1XZf34X/0079.webp"
      },
      {
        "page": 32,
        "link": "https://i.ibb.co/TcWR4rB/0080.webp"
      },
      {
        "page": 33,
        "link": "https://i.ibb.co/GvSdMck/0081.webp"
      },
      {
        "page": 34,
        "link": "https://i.ibb.co/8dKqc8y/0082.webp"
      },
      {
        "page": 35,
        "link": "https://i.ibb.co/Z8JgPfF/0083.webp"
      },
      {
        "page": 36,
        "link": "https://i.ibb.co/x1PHQq3/0084.webp"
      },
      {
        "page": 37,
        "link": "https://i.ibb.co/jTnzMyZ/0085.webp"
      },
      {
        "page": 38,
        "link": "https://i.ibb.co/WWQ3Ttm/0086.webp"
      },
      {
        "page": 39,
        "link": "https://i.ibb.co/BgmCQcT/0087.webp"
      },
      {
        "page": 40,
        "link": "https://i.ibb.co/2ssNvqV/0088.webp"
      },
      {
        "page": 41,
        "link": "https://i.ibb.co/r0jF06T/0089.webp"
      }
]


export default listePages;