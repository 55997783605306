const listePages = [
	{ page: 1, link: 'https://i.ibb.co/P4Z4F3b/0002.webp' },
	{ page: 2, link: 'https://i.ibb.co/zPzXLJ8/0003.webp' },
	{ page: 3, link: 'https://i.ibb.co/pJrZbs6/0004.webp' },
	{ page: 4, link: 'https://i.ibb.co/B29frSs/0005.webp' },
	{ page: 5, link: 'https://i.ibb.co/TR1FGLQ/0006.webp' },
	{ page: 6, link: 'https://i.ibb.co/rphB9X2/0007.webp' },
	{ page: 7, link: 'https://i.ibb.co/g42Q4N1/0008.webp' },
	{ page: 8, link: 'https://i.ibb.co/Tm5BZHh/0009.webp' },
	{ page: 9, link: 'https://i.ibb.co/B2bdp1b/0010.webp' },
	{ page: 10, link: 'https://i.ibb.co/RgVZDBy/0011.webp' },
	{ page: 11, link: 'https://i.ibb.co/df1yHWG/0012.webp' },
	{ page: 12, link: 'https://i.ibb.co/B3PvP5R/0013.webp' },
	{ page: 13, link: 'https://i.ibb.co/wzDQsq5/0014.webp' },
	{ page: 14, link: 'https://i.ibb.co/bmhTNbf/0015.webp' },
	{ page: 15, link: 'https://i.ibb.co/J5MDd2v/0016.webp' },
	{ page: 16, link: 'https://i.ibb.co/YTwdWNF/0017.webp' },
	{ page: 17, link: 'https://i.ibb.co/n1GF7mJ/0018.webp' },
	{ page: 18, link: 'https://i.ibb.co/rQ5rwbM/0019.webp' },
	{ page: 19, link: 'https://i.ibb.co/fCT1fwb/0020.webp' },
	{ page: 20, link: 'https://i.ibb.co/f4690LF/0021.webp' },
	{ page: 21, link: 'https://i.ibb.co/Y7WF49R/0022.webp' },
	{ page: 22, link: 'https://i.ibb.co/0cMFKyy/0023.webp' },
	{ page: 23, link: 'https://i.ibb.co/d0Cjf29/0024.webp' },
	{ page: 24, link: 'https://i.ibb.co/2cSwZBS/0025.webp' },
	{ page: 25, link: 'https://i.ibb.co/YcQWtnt/0026.webp' },
	{ page: 26, link: 'https://i.ibb.co/vs3K5dB/0027.webp' },
	{ page: 27, link: 'https://i.ibb.co/hsdcS9r/0028.webp' },
	{ page: 28, link: 'https://i.ibb.co/tYN4VyX/0029.webp' },
	{ page: 29, link: 'https://i.ibb.co/YbmpwJt/0030.webp' },
	{ page: 30, link: 'https://i.ibb.co/0CztJSR/0031.webp' },
	{ page: 31, link: 'https://i.ibb.co/mR1KSZs/0032.webp' },
	{ page: 32, link: 'https://i.ibb.co/bJKZ9hb/0033.webp' },
	{ page: 33, link: 'https://i.ibb.co/n0RShc3/0034.webp' },
	{ page: 34, link: 'https://i.ibb.co/gms9Wx8/0035.webp' },
	{ page: 35, link: 'https://i.ibb.co/8gMSQfm/0036.webp' },
	{ page: 36, link: 'https://i.ibb.co/jRtM5n0/0037.webp' },
	{ page: 37, link: 'https://i.ibb.co/8bqp7Pg/0038.webp' },
	{ page: 38, link: 'https://i.ibb.co/YtsnzZ3/0039.webp' },
	{ page: 39, link: 'https://i.ibb.co/T0ybmJr/0040.webp' },
	{ page: 40, link: 'https://i.ibb.co/1K2z99Q/0041.webp' },
	{ page: 41, link: 'https://i.ibb.co/L1xLcvY/0042.webp' },
	{ page: 42, link: 'https://i.ibb.co/DgXrRrF/0043.webp' },
	{ page: 43, link: 'https://i.ibb.co/yR78fL6/0044.webp' },
	{ page: 44, link: 'https://i.ibb.co/Ntn2KVD/0045.webp' },
	{ page: 45, link: 'https://i.ibb.co/xJWTMWN/0046.webp' },
	{ page: 46, link: 'https://i.ibb.co/R33Wz0n/0047.webp' },
	{ page: 47, link: 'https://i.ibb.co/D7gZRr3/0048.webp' },
	{ page: 48, link: 'https://i.ibb.co/tLRXnDP/0049.webp' }          
]

export default listePages;