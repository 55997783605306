const listePages = [
    {
        "page": 1,
        "link": "https://i.ibb.co/WnQL09z/0048.webp"
      },
      {
        "page": 2,
        "link": "https://i.ibb.co/wgTVK5P/0049.webp"
      },
      {
        "page": 3,
        "link": "https://i.ibb.co/NZkQLpP/0050.webp"
      },
      {
        "page": 4,
        "link": "https://i.ibb.co/zP7cXGh/0051.webp"
      },
      {
        "page": 5,
        "link": "https://i.ibb.co/G0kr9VS/0052.webp"
      },
      {
        "page": 6,
        "link": "https://i.ibb.co/hdzh8Xr/0053.webp"
      },
      {
        "page": 7,
        "link": "https://i.ibb.co/Scm3qs5/0054.webp"
      },
      {
        "page": 8,
        "link": "https://i.ibb.co/nM08gXf/0055.webp"
      },
      {
        "page": 9,
        "link": "https://i.ibb.co/kS4BxrQ/0056.webp"
      },
      {
        "page": 10,
        "link": "https://i.ibb.co/XxtFCwj/0057.webp"
      },
      {
        "page": 11,
        "link": "https://i.ibb.co/YRyMgbV/0058.webp"
      },
      {
        "page": 12,
        "link": "https://i.ibb.co/hs6Jhzr/0059.webp"
      },
      {
        "page": 13,
        "link": "https://i.ibb.co/Hrb6kZY/0060.webp"
      },
      {
        "page": 14,
        "link": "https://i.ibb.co/Dw762x4/0061.webp"
      },
      {
        "page": 15,
        "link": "https://i.ibb.co/g3r3Zrc/0062.webp"
      },
      {
        "page": 16,
        "link": "https://i.ibb.co/3mBz00D/0063.webp"
      },
      {
        "page": 17,
        "link": "https://i.ibb.co/pngHW4p/0064.webp"
      },
      {
        "page": 18,
        "link": "https://i.ibb.co/42fkthp/0065.webp"
      },
      {
        "page": 19,
        "link": "https://i.ibb.co/c3095vP/0066.webp"
      },
      {
        "page": 20,
        "link": "https://i.ibb.co/zHf5dYJ/0067.webp"
      },
      {
        "page": 21,
        "link": "https://i.ibb.co/1XjKjBK/0068.webp"
      },
      {
        "page": 22,
        "link": "https://i.ibb.co/H2vvGQw/0069.webp"
      },
      {
        "page": 23,
        "link": "https://i.ibb.co/ssqn1dw/0070.webp"
      },
      {
        "page": 24,
        "link": "https://i.ibb.co/Rv7wDQ3/0071.webp"
      },
      {
        "page": 25,
        "link": "https://i.ibb.co/0stRbpT/0072.webp"
      },
      {
        "page": 26,
        "link": "https://i.ibb.co/SmcRDBQ/0073.webp"
      },
      {
        "page": 27,
        "link": "https://i.ibb.co/h2tp8Xh/0074.webp"
      },
      {
        "page": 28,
        "link": "https://i.ibb.co/CMM6KcP/0075.webp"
      },
      {
        "page": 29,
        "link": "https://i.ibb.co/BnhY0nT/0076.webp"
      },
      {
        "page": 30,
        "link": "https://i.ibb.co/Z2Jz14y/0077.webp"
      },
      {
        "page": 31,
        "link": "https://i.ibb.co/kg9SBzS/0078.webp"
      },
      {
        "page": 32,
        "link": "https://i.ibb.co/G5T8Kh8/0079.webp"
      },
      {
        "page": 33,
        "link": "https://i.ibb.co/VgHvGP1/0080.webp"
      },
      {
        "page": 34,
        "link": "https://i.ibb.co/ZHnKwyg/0081.webp"
      },
      {
        "page": 35,
        "link": "https://i.ibb.co/4sPyLp6/0082.webp"
      },
      {
        "page": 36,
        "link": "https://i.ibb.co/tcVky51/0083.webp"
      },
      {
        "page": 37,
        "link": "https://i.ibb.co/MkstmWZ/0084.webp"
      },
      {
        "page": 38,
        "link": "https://i.ibb.co/bvw6crq/0085.webp"
      },
      {
        "page": 39,
        "link": "https://i.ibb.co/3WJfSmR/0086.webp"
      },
      {
        "page": 40,
        "link": "https://i.ibb.co/1mg807T/0087.webp"
      }
]


export default listePages;