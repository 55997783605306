const listeEpisodes = [
    {
        episode : 1,
        link : "https://www.youtube.com/embed/7hap8imirag"
    },
    {
        episode : 2,
        link : "https://www.youtube.com/embed/wEOQ1s6JySo"
    },
    {
        episode : 3,
        link : "https://www.youtube.com/embed/6SJ2fWP_fV8"
    },
    {
        episode : 4,
        link : "https://www.youtube.com/embed/QuRR3J6VHmI"
    },
    {
        episode : 5,
        link : "https://www.youtube.com/embed/7rGpWxTwbBk"
    },
    {
        episode : 6,
        link : "https://www.youtube.com/embed/y69JiF1Uo0U"
    },
    {
        episode : 7,
        link : "https://www.youtube.com/embed/eimzQp7pfWI"
    },
    {
        episode : 8,
        link : "https://www.youtube.com/embed/gFrj18eum6s"
    },
    {
        episode : 9,
        link : "https://www.youtube.com/embed/ED8ffvkCifE"
    },
    {
        episode : 10,
        link : "https://www.youtube.com/embed/taqyidwsSEg"
    },
    {
        episode : 11,
        link : "https://www.youtube.com/embed/iX4vUn7-n38"
    },
    {
        episode : 12,
        link : "https://www.youtube.com/embed/8-VjrZCMrW0"
    },
    {
        episode : 13,
        link : "https://www.youtube.com/embed/G2ZWbuRKUsE"
    },
    {
        episode : 14,
        link : "https://www.youtube.com/embed/pXjL5xU6YZ8"
    },
    {
        episode : 15,
        link : "https://www.youtube.com/embed/4JuBOx7wXbs"
    },
    {
        episode : 16,
        link : "https://www.youtube.com/embed/-SQ54ucjmcU"
    },
    {
        episode : 17,
        link : "https://www.youtube.com/embed/4FNHzctt0Mc"
    },
    {
        episode : 18,
        link : "https://www.youtube.com/embed/MohtohiUgVs"
    },
    {
        episode : 19,
        link : "https://www.youtube.com/embed/0xNSsMFcD-8"
    },
    {
        episode : 20,
        link : "https://www.youtube.com/embed/OSAyENQbSrU"
    },
    {
        episode : 21,
        link : "https://www.youtube.com/embed/Nx0VwNt8WJ8"
    },
    {
        episode : 22,
        link : "https://www.youtube.com/embed/ctK-rmzlmpo"
    },
    {
        episode : 23,
        link : "https://www.youtube.com/embed/2jxos2OZask"
    },
    {
        episode : 24,
        link : "https://www.youtube.com/embed/ztUlGYlbJYU"
    },
    {
        episode : 25,
        link : "https://www.youtube.com/embed/_99x48p5ba8"
    },
    {
        episode : 26,
        link : "https://www.youtube.com/embed/O-gQjdmCDJs"
    }
]

export default listeEpisodes;