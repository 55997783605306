const listePages = [
      {
        "page": 1,
        "link": "https://i.ibb.co/Hdqf2Bm/0187.webp"
      },
      {
        "page": 2,
        "link": "https://i.ibb.co/3s6FDsH/0188.webp"
      },
      {
        "page": 3,
        "link": "https://i.ibb.co/2c03fmj/0189.webp"
      },
      {
        "page": 4,
        "link": "https://i.ibb.co/c10mqm2/0190.webp"
      },
      {
        "page": 5,
        "link": "https://i.ibb.co/jR6RqSm/0191.webp"
      },
      {
        "page": 6,
        "link": "https://i.ibb.co/1JVzMY1/0192.webp"
      },
      {
        "page": 7,
        "link": "https://i.ibb.co/1GLSjp1/0193.webp"
      },
      {
        "page": 8,
        "link": "https://i.ibb.co/DKbDd1D/0194.webp"
      },
      {
        "page": 9,
        "link": "https://i.ibb.co/34bSyXQ/0195.webp"
      },
      {
        "page": 10,
        "link": "https://i.ibb.co/7RCXdDf/0196.webp"
      }
]


export default listePages;