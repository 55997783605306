import React from 'react';
import { Helmet } from "react-helmet"

export default function ErrorPage() {
  return (
    <>
      <Helmet>
          <title>Erreur 404 | Streaming Espace</title>
          <link rel="canonical" href={`https://www.streaming-espace.fr/`}/>
      </Helmet>
      <div className="Errorpage">
        <h1>PAGE INTROUVABLE</h1>
        <p>Désolé, la page n'a pas été trouvée</p>
        <p>Peut-être y a-t-il une erreur dans l'URL ?</p>
      </div>
    </>
  );
}