const listePages = [
    {
      "page": 1,
      "link": "https://i.ibb.co/nzdDny4/0001.webp"
    },
    {
      "page": 2,
      "link": "https://i.ibb.co/f437wRt/0005.webp"
    },
    {
      "page": 3,
      "link": "https://i.ibb.co/gd6kJ94/0006.webp"
    },
    {
      "page": 4,
      "link": "https://i.ibb.co/vqSmgtN/0007.webp"
    },
    {
      "page": 5,
      "link": "https://i.ibb.co/xzmLnSh/0008.webp"
    },
    {
      "page": 6,
      "link": "https://i.ibb.co/SKkTj9m/0009.webp"
    },
    {
      "page": 7,
      "link": "https://i.ibb.co/tbhXqp7/0010.webp"
    },
    {
      "page": 8,
      "link": "https://i.ibb.co/fdFQYLW/0011.webp"
    },
    {
      "page": 9,
      "link": "https://i.ibb.co/pXzFY7V/0012.webp"
    },
    {
      "page": 10,
      "link": "https://i.ibb.co/q911QCj/0013.webp"
    },
    {
      "page": 11,
      "link": "https://i.ibb.co/xqyM5PN/0014.webp"
    },
    {
      "page": 12,
      "link": "https://i.ibb.co/2jkcNDQ/0015.webp"
    },
    {
      "page": 13,
      "link": "https://i.ibb.co/Bj986BZ/0016.webp"
    },
    {
      "page": 14,
      "link": "https://i.ibb.co/TmNsVkk/0017.webp"
    },
    {
      "page": 15,
      "link": "https://i.ibb.co/3BFjqHf/0018.webp"
    },
    {
      "page": 16,
      "link": "https://i.ibb.co/0sxLxy2/0019.webp"
    },
    {
      "page": 17,
      "link": "https://i.ibb.co/GCytmcN/0020.webp"
    },
    {
      "page": 18,
      "link": "https://i.ibb.co/WHtP5pK/0021.webp"
    },
    {
      "page": 19,
      "link": "https://i.ibb.co/z82Br43/0022.webp"
    },
    {
      "page": 20,
      "link": "https://i.ibb.co/Dtk15xf/0023.webp"
    },
    {
      "page": 21,
      "link": "https://i.ibb.co/yF23fR3/0024.webp"
    },
    {
      "page": 22,
      "link": "https://i.ibb.co/GCmKTWV/0025.webp"
    },
    {
      "page": 23,
      "link": "https://i.ibb.co/1fSBSnK/0026.webp"
    },
    {
      "page": 24,
      "link": "https://i.ibb.co/ZJnHwpJ/0027.webp"
    },
    {
      "page": 25,
      "link": "https://i.ibb.co/xFMmJv6/0028.webp"
    },
    {
      "page": 26,
      "link": "https://i.ibb.co/hVW6v4j/0029.webp"
    },
    {
      "page": 27,
      "link": "https://i.ibb.co/rmg98T8/0030.webp"
    },
    {
      "page": 28,
      "link": "https://i.ibb.co/hsGC63g/0031.webp"
    },
    {
      "page": 29,
      "link": "https://i.ibb.co/qgyXsB1/0032.webp"
    },
    {
      "page": 30,
      "link": "https://i.ibb.co/9YNwqNs/0033.webp"
    },
    {
      "page": 31,
      "link": "https://i.ibb.co/XXftHRQ/0034.webp"
    },
    {
      "page": 32,
      "link": "https://i.ibb.co/xCjSMQj/0035.webp"
    },
    {
      "page": 33,
      "link": "https://i.ibb.co/jf8sjpL/0036.webp"
    },
    {
      "page": 34,
      "link": "https://i.ibb.co/r5ycfX4/0037.webp"
    },
    {
      "page": 35,
      "link": "https://i.ibb.co/fFtwGwH/0038.webp"
    },
    {
      "page": 36,
      "link": "https://i.ibb.co/vjPCyWF/0039.webp"
    },
    {
      "page": 37,
      "link": "https://i.ibb.co/YyK2pZw/0040.webp"
    },
    {
      "page": 38,
      "link": "https://i.ibb.co/2hnLQjf/0041.webp"
    },
    {
      "page": 39,
      "link": "https://i.ibb.co/NYfgXY8/0042.webp"
    },
    {
      "page": 40,
      "link": "https://i.ibb.co/MDxjqwD/0043.webp"
    },
    {
      "page": 41,
      "link": "https://i.ibb.co/XJGM4gW/0044.webp"
    },
    {
      "page": 42,
      "link": "https://i.ibb.co/xGzHdH4/0045.webp"
    },
    {
      "page": 43,
      "link": "https://i.ibb.co/SdyHTd5/0046.webp"
    },
    {
      "page": 44,
      "link": "https://i.ibb.co/bmJtDHy/0047.webp"
    },
    {
      "page": 45,
      "link": "https://i.ibb.co/cXzZkx9/0048.webp"
    },
    {
      "page": 46,
      "link": "https://i.ibb.co/bgPQMNt/0049.webp"
    },
    {
      "page": 47,
      "link": "https://i.ibb.co/LNmQ6Sp/0050.webp"
    },
    {
      "page": 48,
      "link": "https://i.ibb.co/zVL1fZ3/0051.webp"
    },
    {
      "page": 49,
      "link": "https://i.ibb.co/n3B6B1p/0052.webp"
    },
    {
      "page": 50,
      "link": "https://i.ibb.co/QPRJB94/0053.webp"
    },
    {
      "page": 51,
      "link": "https://i.ibb.co/FXmSTHr/0054.webp"
    },
    {
      "page": 52,
      "link": "https://i.ibb.co/Y2L9NDk/0055.webp"
    },
    {
      "page": 53,
      "link": "https://i.ibb.co/QKdsg4W/0056.webp"
    },
    {
      "page": 54,
      "link": "https://i.ibb.co/pWhLxBr/0057.webp"
    },
    {
      "page": 55,
      "link": "https://i.ibb.co/jLMxfn3/0058.webp"
    },
    {
      "page": 56,
      "link": "https://i.ibb.co/Y7W05Lk/0059.webp"
    }
  ]


export default listePages;