const listePages = [
  { page: 1, link: "https://i.ibb.co/fdW8kyW/0053-001.webp" },
  { page: 2, link: "https://i.ibb.co/r5LwMf7/0053-002.webp" },
  { page: 3, link: "https://i.ibb.co/mRnGLkF/0053-003.webp" },
  { page: 4, link: "https://i.ibb.co/LtZjwbk/0053-004.webp" },
  { page: 5, link: "https://i.ibb.co/ncNGrJG/0053-005.webp" },
  { page: 6, link: "https://i.ibb.co/DMHJWRL/0053-006.webp" },
  { page: 7, link: "https://i.ibb.co/2WCj4cZ/0053-007.webp" },
  { page: 8, link: "https://i.ibb.co/vPQZZfz/0053-008.webp" },
  { page: 9, link: "https://i.ibb.co/wL6fjwx/0053-009.webp" },
  { page: 10, link: "https://i.ibb.co/wWsb64t/0053-010.webp" },
  { page: 11, link: "https://i.ibb.co/stCZ2ZZ/0053-011.webp" },
  { page: 12, link: "https://i.ibb.co/Z8CP335/0053-012.webp" },
  { page: 13, link: "https://i.ibb.co/8smYjW4/0053-013.webp" },
  { page: 14, link: "https://i.ibb.co/dQJ3wQ1/0053-014.webp" },
  { page: 15, link: "https://i.ibb.co/HGKhmnq/0053-015.webp" },
  { page: 16, link: "https://i.ibb.co/wgt4XVX/0053-016.webp" },
  { page: 17, link: "https://i.ibb.co/Rjyq8wn/0053-017.webp" },
  { page: 18, link: "https://i.ibb.co/6DzckRH/0053-018.webp" },
  { page: 19, link: "https://i.ibb.co/wShzK1p/0053-019.webp" },
  { page: 20, link: "https://i.ibb.co/6P1r9gZ/0053-020.webp" },
  { page: 21, link: "https://i.ibb.co/rxsG2nT/0053-021.webp" },
  { page: 22, link: "https://i.ibb.co/BqK17GP/0053-022.webp" },
  { page: 23, link: "https://i.ibb.co/yV5tCZz/0053-023.webp" },
  { page: 24, link: "https://i.ibb.co/d5F4fd7/0053-024.webp" },
  { page: 25, link: "https://i.ibb.co/wyzqcF9/0053-025.webp" },
  { page: 26, link: "https://i.ibb.co/1qH9RMH/0053-026.webp" },
  { page: 27, link: "https://i.ibb.co/wYV7QDn/0053-027.webp" },
  { page: 28, link: "https://i.ibb.co/BgmMkpG/0053-028.webp" },
  { page: 29, link: "https://i.ibb.co/cFZG4xb/0053-029.webp" },
  { page: 30, link: "https://i.ibb.co/YjPGYKt/0053-030.webp" },
  { page: 31, link: "https://i.ibb.co/kDrtGFq/0053-031.webp" },
  { page: 32, link: "https://i.ibb.co/jf3w7m5/0053-032.webp" },
  { page: 33, link: "https://i.ibb.co/TK8VNBw/0053-033.webp" }
]


export default listePages;