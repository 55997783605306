const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/7kLQPv4/0009-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/zRwHsVK/0009-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/j3nffbq/0009-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/n0y7Gmj/0009-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/PQqjHXy/0009-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/YtzhvVP/0009-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/ZmDcvR3/0009-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/N6Lfby9/0009-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/rZZ1g4S/0009-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/wrSH2tL/0009-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/vDQK430/0009-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/LxTFn2C/0009-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/ySBdVjS/0009-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/TY7ShhM/0009-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/vVWyHJJ/0009-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/8s8cYzc/0009-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/3BNtQLD/0009-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/FggJq3G/0009-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/qnMMhck/0009-019.webp"
  },
  {
    page: 20,
    link: "https://i.ibb.co/nPntdW5/0009-020.webp"
  },
  {
    page: 21,
    link: "https://i.ibb.co/K0Y9VnG/0009-021.webp"
  },
  {
    page: 22,
    link: "https://i.ibb.co/5sCDXFC/0009-022.webp"
  },
  {
    page: 23,
    link: "https://i.ibb.co/yhkc2Ng/0009-023.webp"
  },
  {
    page: 24,
    link: "https://i.ibb.co/QDPcmnC/0009-024.webp"
  },
  {
    page: 25,
    link: "https://i.ibb.co/TwHf05k/0009-025.webp"
  },
  {
    page: 26,
    link: "https://i.ibb.co/fXDKwZK/0009-026.webp"
  },
  {
    page: 27,
    link: "https://i.ibb.co/vxBzH3r/0009-027.webp"
  },
  {
    page: 28,
    link: "https://i.ibb.co/QJJF7SV/0009-028.webp"
  },
  {
    page: 29,
    link: "https://i.ibb.co/Y7pmkHb/0009-029.webp"
  },
  {
    page: 30,
    link: "https://i.ibb.co/jk8nnnW/0009-030.webp"
  },
  {
    page: 31,
    link: "https://i.ibb.co/x6S1dT1/0009-031.webp"
  },
  {
    page: 32,
    link: "https://i.ibb.co/wh4zSRD/0009-032.webp"
  },
  {
    page: 33,
    link: "https://i.ibb.co/qxqLYz9/0009-033.webp"
  },
  {
    page: 34,
    link: "https://i.ibb.co/ZJngwhC/0009-034.webp"
  },
  {
    page: 35,
    link: "https://i.ibb.co/F60T7Ct/0009-035.webp"
  },
  {
    page: 36,
    link: "https://i.ibb.co/sH99Gyx/0009-036.webp"
  },
  {
    page: 37,
    link: "https://i.ibb.co/kcr9062/0009-037.webp"
  },
  {
    page: 38,
    link: "https://i.ibb.co/QftWmyw/0009-038.webp"
  },
  {
    page: 39,
    link: "https://i.ibb.co/f8JkP3S/0009-039.webp"
  }
]


export default listePages;