const listePages = [
    {
      "page": 1,
      "link": "https://i.ibb.co/g7MX9Xy/0165.webp"
    },
    {
      "page": 2,
      "link": "https://i.ibb.co/T2dHLHz/0166.webp"
    },
    {
      "page": 3,
      "link": "https://i.ibb.co/JmjdCr1/0167.webp"
    },
    {
      "page": 4,
      "link": "https://i.ibb.co/YbMNmgP/0168.webp"
    },
    {
      "page": 5,
      "link": "https://i.ibb.co/0QZpYJJ/0169.webp"
    },
    {
      "page": 6,
      "link": "https://i.ibb.co/jW8Ry1D/0170.webp"
    },
    {
      "page": 7,
      "link": "https://i.ibb.co/DVRrj16/0171.webp"
    },
    {
      "page": 8,
      "link": "https://i.ibb.co/qgHq68s/0172.webp"
    },
    {
      "page": 9,
      "link": "https://i.ibb.co/4VpC2P3/0173.webp"
    },
    {
      "page": 10,
      "link": "https://i.ibb.co/hMxD0BH/0174.webp"
    },
    {
      "page": 11,
      "link": "https://i.ibb.co/wYB151g/0175.webp"
    },
    {
      "page": 12,
      "link": "https://i.ibb.co/0VVQMcj/0176.webp"
    },
    {
      "page": 13,
      "link": "https://i.ibb.co/9pYpcY4/0177.webp"
    },
    {
      "page": 14,
      "link": "https://i.ibb.co/Yh86Px8/0178.webp"
    },
    {
      "page": 15,
      "link": "https://i.ibb.co/PFLMTWr/0179.webp"
    },
    {
      "page": 16,
      "link": "https://i.ibb.co/5xQgfWw/0180.webp"
    },
    {
      "page": 17,
      "link": "https://i.ibb.co/02kw5Yg/0181.webp"
    },
    {
      "page": 18,
      "link": "https://i.ibb.co/1qGXH2G/0182.webp"
    },
    {
      "page": 19,
      "link": "https://i.ibb.co/JKXtb41/0183.webp"
    },
    {
      "page": 20,
      "link": "https://i.ibb.co/PDNztZr/0184.webp"
    },
    {
      "page": 21,
      "link": "https://i.ibb.co/7VP79P0/0185.webp"
    },
    {
      "page": 22,
      "link": "https://i.ibb.co/G3xk5LW/0186.webp"
    },
    {
      "page": 23,
      "link": "https://i.ibb.co/1GC3RJN/0187.webp"
    },
    {
      "page": 24,
      "link": "https://i.ibb.co/c8V1jCM/0188.webp"
    },
    {
      "page": 25,
      "link": "https://i.ibb.co/BssGQ5h/0189.webp"
    },
    {
      "page": 26,
      "link": "https://i.ibb.co/dW78KP5/0190.webp"
    },
    {
      "page": 27,
      "link": "https://i.ibb.co/yWsN2CS/0191.webp"
    },
    {
      "page": 28,
      "link": "https://i.ibb.co/nPzjd4B/0192.webp"
    },
    {
      "page": 29,
      "link": "https://i.ibb.co/PmtSXL5/0193.webp"
    },
    {
      "page": 30,
      "link": "https://i.ibb.co/gwHKSM8/0194.webp"
    },
    {
      "page": 31,
      "link": "https://i.ibb.co/LJS6ypf/0195.webp"
    },
    {
      "page": 32,
      "link": "https://i.ibb.co/VL024d3/0196.webp"
    },
    {
      "page": 33,
      "link": "https://i.ibb.co/hc7y0NZ/0197.webp"
    },
    {
      "page": 34,
      "link": "https://i.ibb.co/SsTfPFv/0198.webp"
    }
  ]


export default listePages;