const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/fSPCzWg/0026-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/9q3p6fn/0026-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/HzJgFBL/0026-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/S5ztv3J/0026-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/nR435cw/0026-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/cw9Hqc6/0026-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/FKFr6M4/0026-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/54kx07C/0026-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/kMcZ4S6/0026-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/6PN9Mc0/0026-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/BgvjSXc/0026-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/8Dch70z/0026-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/nMN0N2W/0026-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/p1GrtcS/0026-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/z4knNyW/0026-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/fGk44PM/0026-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/b1RtRmP/0026-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/cYYv10r/0026-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/qd73Fz2/0026-019.webp"
  },
  {
    page: 20,
    link: "https://i.ibb.co/80ZDYyv/0026-020.webp"
  },
  {
    page: 21,
    link: "https://i.ibb.co/zZzjD0Z/0026-021.webp"
  },
  {
    page: 22,
    link: "https://i.ibb.co/18qPGrR/0026-022.webp"
  },
  {
    page: 23,
    link: "https://i.ibb.co/4PkMH6g/0026-023.webp"
  },
  {
    page: 24,
    link: "https://i.ibb.co/zhdDbbD/0026-024.webp"
  },
  {
    page: 25,
    link: "https://i.ibb.co/nkCJqc2/0026-025.webp"
  },
  {
    page: 26,
    link: "https://i.ibb.co/tBNpkfV/0026-026.webp"
  },
  {
    page: 27,
    link: "https://i.ibb.co/LNPkGfy/0026-027.webp"
  },
  {
    page: 28,
    link: "https://i.ibb.co/PtYMDTw/0026-028.webp"
  },
  {
    page: 29,
    link: "https://i.ibb.co/wghCKMC/0026-029.webp"
  },
  {
    page: 30,
    link: "https://i.ibb.co/0XT9vW8/0026-030.webp"
  },
  {
    page: 31,
    link: "https://i.ibb.co/DMv5qZr/0026-031.webp"
  },
  {
    page: 32,
    link: "https://i.ibb.co/3yrw0BP/0026-032.webp"
  },
  {
    page: 33,
    link: "https://i.ibb.co/6PMB2Q2/0026-033.webp"
  },
  {
    page: 34,
    link: "https://i.ibb.co/m4hzwP8/0026-034.webp"
  },
  {
    page: 35,
    link: "https://i.ibb.co/QpRQrRq/0026-035.webp"
  },
  {
    page: 36,
    link: "https://i.ibb.co/pz26DvV/0026-036.webp"
  },
  {
    page: 37,
    link: "https://i.ibb.co/c33GgkS/0026-037.webp"
  },
  {
    page: 38,
    link: "https://i.ibb.co/R94gchw/0026-038.webp"
  }  
]

export default listePages;