const listePages = [
    { page: 1, link: "https://i.ibb.co/S5yVMRC/59-7-001.webp" },
    { page: 2, link: "https://i.ibb.co/zJZDNJ2/59-7-002.webp" },
    { page: 3, link: "https://i.ibb.co/FsfNPkv/59-7-003.webp" },
    { page: 4, link: "https://i.ibb.co/frfbssh/59-7-004.webp" },
    { page: 5, link: "https://i.ibb.co/mBGkyb5/59-7-005.webp" },
    { page: 6, link: "https://i.ibb.co/BKJgrYm/59-7-006.webp" },
    { page: 7, link: "https://i.ibb.co/f8ZKLpm/59-7-007.webp" },
    { page: 8, link: "https://i.ibb.co/8bPb4Fx/59-7-008.webp" },
    { page: 9, link: "https://i.ibb.co/mzsxq5h/59-7-009.webp" },
    { page: 10, link: "https://i.ibb.co/K53mb6C/59-7-010.webp" },
    { page: 11, link: "https://i.ibb.co/h7b3FgD/59-7-011.webp" },
    { page: 12, link: "https://i.ibb.co/sC46Y78/59-7-012.webp" },
    { page: 13, link: "https://i.ibb.co/p2jxB9D/59-7-013.webp" },
    { page: 14, link: "https://i.ibb.co/1bDP8Yv/59-7-014.webp" },
    { page: 15, link: "https://i.ibb.co/hg1Y4wD/59-7-015.webp" },
    { page: 16, link: "https://i.ibb.co/3WzBS1Q/59-7-016.webp" },
    { page: 17, link: "https://i.ibb.co/6vc8Z4h/59-7-017.webp" }
  ]
  

export default listePages;