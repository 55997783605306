const listePages = [
    {
      "page": 1,
      "link": "https://i.ibb.co/pWF73zy/0138.webp"
    },
    {
      "page": 2,
      "link": "https://i.ibb.co/PhgZgQ7/0139.webp"
    },
    {
      "page": 3,
      "link": "https://i.ibb.co/WPcZ3QF/0140.webp"
    },
    {
      "page": 4,
      "link": "https://i.ibb.co/Q8pwHPc/0141.webp"
    },
    {
      "page": 5,
      "link": "https://i.ibb.co/82BFnTh/0142.webp"
    },
    {
      "page": 6,
      "link": "https://i.ibb.co/VwQNZYC/0143.webp"
    },
    {
      "page": 7,
      "link": "https://i.ibb.co/Vj3YzPj/0144.webp"
    },
    {
      "page": 8,
      "link": "https://i.ibb.co/MBhHPVv/0145.webp"
    },
    {
      "page": 9,
      "link": "https://i.ibb.co/nQ5Pm7w/0146.webp"
    },
    {
      "page": 10,
      "link": "https://i.ibb.co/WVh9Txn/0147.webp"
    },
    {
      "page": 11,
      "link": "https://i.ibb.co/qkwsg6h/0148.webp"
    },
    {
      "page": 12,
      "link": "https://i.ibb.co/tHQgn4V/0149.webp"
    },
    {
      "page": 13,
      "link": "https://i.ibb.co/PFYHK05/0150.webp"
    },
    {
      "page": 14,
      "link": "https://i.ibb.co/MhxV63P/0151.webp"
    },
    {
      "page": 15,
      "link": "https://i.ibb.co/b2jZJpY/0152.webp"
    },
    {
      "page": 16,
      "link": "https://i.ibb.co/b3DNx2Y/0153.webp"
    },
    {
      "page": 17,
      "link": "https://i.ibb.co/w6rvNn5/0154.webp"
    },
    {
      "page": 18,
      "link": "https://i.ibb.co/bJS8cCw/0155.webp"
    },
    {
      "page": 19,
      "link": "https://i.ibb.co/x2yV1v3/0156.webp"
    },
    {
      "page": 20,
      "link": "https://i.ibb.co/kgrsm9n/0157.webp"
    },
    {
      "page": 21,
      "link": "https://i.ibb.co/XWzMBp3/0158.webp"
    },
    {
      "page": 22,
      "link": "https://i.ibb.co/54sGfxL/0159.webp"
    },
    {
      "page": 23,
      "link": "https://i.ibb.co/bJ3ZHVm/0160.webp"
    },
    {
      "page": 24,
      "link": "https://i.ibb.co/ZYBnCrS/0161.webp"
    },
    {
      "page": 25,
      "link": "https://i.ibb.co/648X2N4/0162.webp"
    },
    {
      "page": 26,
      "link": "https://i.ibb.co/4V2kHj7/0163.webp"
    },
    {
      "page": 27,
      "link": "https://i.ibb.co/FYyNrpW/0164.webp"
    }
  ]


export default listePages;