const listeEpisodes = [
    {
        episode : 1,
        link : "https://video.sibnet.ru/shell.php?videoid=4932276"
    },
    {
        episode : 2,
        link : "https://video.sibnet.ru/shell.php?videoid=4932278"
    },
    {
        episode : 3,
        link : "https://video.sibnet.ru/shell.php?videoid=4930689"
    },
    {
        episode : 4,
        link : "https://video.sibnet.ru/shell.php?videoid=4930690"
    },
    {
        episode : 5,
        link : "https://video.sibnet.ru/shell.php?videoid=4932279"
    },
    {
        episode : 6,
        link : "https://video.sibnet.ru/shell.php?videoid=4932283"
    },
    {
        episode : 7,
        link : "https://video.sibnet.ru/shell.php?videoid=4932290"
    },
    {
        episode : 8,
        link : "https://video.sibnet.ru/shell.php?videoid=4932295"
    },
    {
        episode : 9,
        link : "https://video.sibnet.ru/shell.php?videoid=4930692"
    },
    {
        episode : 10,
        link : "https://video.sibnet.ru/shell.php?videoid=5255950"
    },
    {
        episode : 11,
        link : "https://video.sibnet.ru/shell.php?videoid=4932297"
    },
    {
        episode : 12,
        link : "https://video.sibnet.ru/shell.php?videoid=4932302"
    },
    {
        episode : 13,
        link : "https://video.sibnet.ru/shell.php?videoid=4930695"
    },
    {
        episode : 14,
        link : "https://video.sibnet.ru/shell.php?videoid=4930696"
    },
    {
        episode : 15,
        link : "https://video.sibnet.ru/shell.php?videoid=4932307"
    },
    {
        episode : 16,
        link : "https://video.sibnet.ru/shell.php?videoid=5255929"
    },
    {
        episode : 17,
        link : "https://video.sibnet.ru/shell.php?videoid=4932310"
    },
    {
        episode : 18,
        link : "https://video.sibnet.ru/shell.php?videoid=5255931"
    },
    {
        episode : 19,
        link : "https://video.sibnet.ru/shell.php?videoid=5255936"
    },
    {
        episode : 20,
        link : "https://video.sibnet.ru/shell.php?videoid=5255939"
    },
    {
        episode : 21,
        link : "https://video.sibnet.ru/shell.php?videoid=5255942"
    },
    {
        episode : 22,
        link : "https://video.sibnet.ru/shell.php?videoid=4930705"
    },
    {
        episode : 23,
        link : "https://video.sibnet.ru/shell.php?videoid=4930707"
    },
    {
        episode : 24,
        link : "https://video.sibnet.ru/shell.php?videoid=4932313"
    },
    {
        episode : 25,
        link : "https://video.sibnet.ru/shell.php?videoid=4930710"
    },
    {
        episode : 26,
        link : "https://video.sibnet.ru/shell.php?videoid=4930711"
    },
    {
        episode : 27,
        link : "https://video.sibnet.ru/shell.php?videoid=4930712"
    },
    {
        episode : 28,
        link : "https://video.sibnet.ru/shell.php?videoid=4930714"
    },
    {
        episode : 29,
        link : "https://video.sibnet.ru/shell.php?videoid=4930716"
    },
    {
        episode : 30,
        link : "https://video.sibnet.ru/shell.php?videoid=4932315"
    },
    {
        episode : 31,
        link : "https://video.sibnet.ru/shell.php?videoid=4930723"
    },
    {
        episode : 32,
        link : "https://video.sibnet.ru/shell.php?videoid=4930726"
    },
    {
        episode : 33,
        link : "https://video.sibnet.ru/shell.php?videoid=4930729"
    },
    {
        episode : 34,
        link : "https://video.sibnet.ru/shell.php?videoid=4932318"
    },
    {
        episode : 35,
        link : "https://video.sibnet.ru/shell.php?videoid=4930732"
    },
    {
        episode : 36,
        link : "https://video.sibnet.ru/shell.php?videoid=4932319"
    },
    {
        episode : 37,
        link : "https://video.sibnet.ru/shell.php?videoid=4932325"
    },
    {
        episode : 38,
        link : "https://video.sibnet.ru/shell.php?videoid=4930733"
    },
    {
        episode : 39,
        link : "https://video.sibnet.ru/shell.php?videoid=4930735"
    },
    {
        episode : 40,
        link : "https://video.sibnet.ru/shell.php?videoid=4932327"
    },
    {
        episode : 41,
        link : "https://video.sibnet.ru/shell.php?videoid=4932329"
    },
    {
        episode : 42,
        link : "https://video.sibnet.ru/shell.php?videoid=4932332"
    },
    {
        episode : 43,
        link : "https://video.sibnet.ru/shell.php?videoid=4932334"
    },
    {
        episode : 44,
        link : "https://video.sibnet.ru/shell.php?videoid=4932337"
    },
    {
        episode : 45,
        link : "https://video.sibnet.ru/shell.php?videoid=4930739"
    },
    {
        episode : 46,
        link : "https://video.sibnet.ru/shell.php?videoid=4930740"
    },
    {
        episode : 47,
        link : "https://video.sibnet.ru/shell.php?videoid=4930741"
    },
    {
        episode : 48,
        link : "https://video.sibnet.ru/shell.php?videoid=4930743"
    },
    {
        episode : 49,
        link : "https://video.sibnet.ru/shell.php?videoid=4932340"
    },
    {
        episode : 50,
        link : "https://video.sibnet.ru/shell.php?videoid=4932341"
    },
    {
        episode : 51,
        link : "https://video.sibnet.ru/shell.php?videoid=4930744"
    }
]

export default listeEpisodes;