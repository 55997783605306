const listePages = [
      {
        "page": 1,
        "link": "https://i.ibb.co/G72tLTv/0141.webp"
      },
      {
        "page": 2,
        "link": "https://i.ibb.co/f0cTnwy/0142.webp"
      },
      {
        "page": 3,
        "link": "https://i.ibb.co/HKSkCTn/0143.webp"
      },
      {
        "page": 4,
        "link": "https://i.ibb.co/fryRwv2/0144.webp"
      },
      {
        "page": 5,
        "link": "https://i.ibb.co/mT4D7H2/0145.webp"
      },
      {
        "page": 6,
        "link": "https://i.ibb.co/qrBSHZx/0146.webp"
      },
      {
        "page": 7,
        "link": "https://i.ibb.co/R01ytRh/0147.webp"
      },
      {
        "page": 8,
        "link": "https://i.ibb.co/BNXhJX3/0148.webp"
      },
      {
        "page": 9,
        "link": "https://i.ibb.co/Z27dDHx/0149.webp"
      },
      {
        "page": 10,
        "link": "https://i.ibb.co/0yfDYgG/0150.webp"
      },
      {
        "page": 11,
        "link": "https://i.ibb.co/28RF9nJ/0151.webp"
      },
      {
        "page": 12,
        "link": "https://i.ibb.co/kg09BtL/0152.webp"
      },
      {
        "page": 13,
        "link": "https://i.ibb.co/Cb4w0Rk/0153.webp"
      },
      {
        "page": 14,
        "link": "https://i.ibb.co/QYjd764/0154.webp"
      },
      {
        "page": 15,
        "link": "https://i.ibb.co/KK92WVQ/0155.webp"
      },
      {
        "page": 16,
        "link": "https://i.ibb.co/Sc3nTqV/0156.webp"
      },
      {
        "page": 17,
        "link": "https://i.ibb.co/HrnV066/0157.webp"
      },
      {
        "page": 18,
        "link": "https://i.ibb.co/zFQML8W/0158.webp"
      },
      {
        "page": 19,
        "link": "https://i.ibb.co/zZtjPJw/0159.webp"
      },
      {
        "page": 20,
        "link": "https://i.ibb.co/MDT6bRQ/0160.webp"
      },
      {
        "page": 21,
        "link": "https://i.ibb.co/7zTbPpt/0161.webp"
      },
      {
        "page": 22,
        "link": "https://i.ibb.co/sC2bg4v/0162.webp"
      },
      {
        "page": 23,
        "link": "https://i.ibb.co/HFqns8J/0163.webp"
      },
      {
        "page": 24,
        "link": "https://i.ibb.co/FWJHQy5/0164.webp"
      },
      {
        "page": 25,
        "link": "https://i.ibb.co/bW8rXtw/0165.webp"
      },
      {
        "page": 26,
        "link": "https://i.ibb.co/txvBq59/0166.webp"
      },
      {
        "page": 27,
        "link": "https://i.ibb.co/zr42p2h/0167.webp"
      },
      {
        "page": 28,
        "link": "https://i.ibb.co/VxmqBDw/0168.webp"
      },
      {
        "page": 29,
        "link": "https://i.ibb.co/jWGJGsK/0169.webp"
      },
      {
        "page": 30,
        "link": "https://i.ibb.co/qR7QKYf/0170.webp"
      },
      {
        "page": 31,
        "link": "https://i.ibb.co/d4F2LDp/0171.webp"
      },
      {
        "page": 32,
        "link": "https://i.ibb.co/T4Jr703/0172.webp"
      },
      {
        "page": 33,
        "link": "https://i.ibb.co/MBb7y6S/0173.webp"
      },
      {
        "page": 34,
        "link": "https://i.ibb.co/k6HwDxK/0174.webp"
      },
      {
        "page": 35,
        "link": "https://i.ibb.co/nr8jGSs/0175.webp"
      },
      {
        "page": 36,
        "link": "https://i.ibb.co/cgGfGnZ/0176.webp"
      },
      {
        "page": 37,
        "link": "https://i.ibb.co/pZvkYBh/0177.webp"
      },
      {
        "page": 38,
        "link": "https://i.ibb.co/488hCc3/0178.webp"
      },
      {
        "page": 39,
        "link": "https://i.ibb.co/DYkfKZf/0179.webp"
      },
      {
        "page": 40,
        "link": "https://i.ibb.co/s6X25yH/0180.webp"
      },
      {
        "page": 41,
        "link": "https://i.ibb.co/QjBpstf/0181.webp"
      },
      {
        "page": 42,
        "link": "https://i.ibb.co/B4qZJw3/0182.webp"
      },
      {
        "page": 43,
        "link": "https://i.ibb.co/809v22T/0183.webp"
      },
      {
        "page": 44,
        "link": "https://i.ibb.co/pWzcP0z/0184.webp"
      },
      {
        "page": 45,
        "link": "https://i.ibb.co/hmxYbpX/0185.webp"
      },
      {
        "page": 46,
        "link": "https://i.ibb.co/ZxNy3HF/0186.webp"
      },
      {
        "page": 47,
        "link": "https://i.ibb.co/phxFBFS/0187.webp"
      },
      {
        "page": 48,
        "link": "https://i.ibb.co/3pcHpST/0188.webp"
      },
      {
        "page": 49,
        "link": "https://i.ibb.co/yWd34zC/0189.webp"
      },
      {
        "page": 50,
        "link": "https://i.ibb.co/xCNPL10/0190.webp"
      },
      {
        "page": 51,
        "link": "https://i.ibb.co/4PhrNHg/0191.webp"
      },
      {
        "page": 52,
        "link": "https://i.ibb.co/VJBTr78/0192.webp"
      }
]


export default listePages;