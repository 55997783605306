const listePages = [
    {
        page: 1,
        link: "https://i.ibb.co/98GTj3z/123.webp"
      },
      {
        page: 2,
        link: "https://i.ibb.co/QjG2YVK/124.webp"
      },
      {
        page: 3,
        link: "https://i.ibb.co/BzLp6Ny/125.webp"
      },
      {
        page: 4,
        link: "https://i.ibb.co/GV7cNdy/126.webp"
      },
      {
        page: 5,
        link: "https://i.ibb.co/qx8b1dp/127.webp"
      },
      {
        page: 6,
        link: "https://i.ibb.co/ZBV8P7n/128.webp"
      },
      {
        page: 7,
        link: "https://i.ibb.co/mSgFKqS/129.webp"
      },
      {
        page: 8,
        link: "https://i.ibb.co/CvDpsbr/130.webp"
      },
      {
        page: 9,
        link: "https://i.ibb.co/XztHN1V/131.webp"
      },
      {
        page: 10,
        link: "https://i.ibb.co/NKV2rrZ/132.webp"
      },
      {
        page: 11,
        link: "https://i.ibb.co/mR7s17f/133.webp"
      },
      {
        page: 12,
        link: "https://i.ibb.co/SwRsS4q/134.webp"
      },
      {
        page: 13,
        link: "https://i.ibb.co/jHHms2p/135.webp"
      },
      {
        page: 14,
        link: "https://i.ibb.co/bRdbNJL/136.webp"
      },
      {
        page: 15,
        link: "https://i.ibb.co/7rzZYQ3/137.webp"
      },
      {
        page: 16,
        link: "https://i.ibb.co/W25GgSV/138.webp"
      },
      {
        page: 17,
        link: "https://i.ibb.co/vXZ4Vjc/139.webp"
      },
      {
        page: 18,
        link: "https://i.ibb.co/frzMY6g/140.webp"
      },
      {
        page: 19,
        link: "https://i.ibb.co/fXzwrnD/141.webp"
      },
      {
        page: 20,
        link: "https://i.ibb.co/PhDPw0p/142.webp"
      },
      {
        page: 21,
        link: "https://i.ibb.co/JcqV2h0/143.webp"
      },
      {
        page: 22,
        link: "https://i.ibb.co/rtN9K6j/144.webp"
      },
      {
        page: 23,
        link: "https://i.ibb.co/LdGh0dF/145.webp"
      },
      {
        page: 24,
        link: "https://i.ibb.co/m65wm82/146.webp"
      },
      {
        page: 25,
        link: "https://i.ibb.co/M5vL7GL/147.webp"
      },
      {
        page: 26,
        link: "https://i.ibb.co/P9MX942/148.webp"
      },
      {
        page: 27,
        link: "https://i.ibb.co/kMd2cJh/149.webp"
      },
      {
        page: 28,
        link: "https://i.ibb.co/9pF4Ff2/150.webp"
      },
      {
        page: 29,
        link: "https://i.ibb.co/6PVVWZC/151.webp"
      },
      {
        page: 30,
        link: "https://i.ibb.co/j4sNfcK/152.webp"
      },
      {
        page: 31,
        link: "https://i.ibb.co/4jtyt85/153.webp"
      },
      {
        page: 32,
        link: "https://i.ibb.co/X2FwJK2/154.webp"
      },
      {
        page: 33,
        link: "https://i.ibb.co/pfT0mxs/155.webp"
      },
      {
        page: 34,
        link: "https://i.ibb.co/wsd1TW2/156.webp"
      },
      {
        page: 35,
        link: "https://i.ibb.co/8gQgct0/157.webp"
      }
]


export default listePages;