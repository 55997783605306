const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/CsRDYnQ/0044-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/1r0ggY5/0044-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/ChTk0SN/0044-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/5FZ2S5G/0044-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/gjpCC96/0044-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/Nr7YkrW/0044-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/6sQx5nS/0044-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/fHLk1wV/0044-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/Y81d2Ch/0044-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/PxrcTMm/0044-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/qYbDQdh/0044-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/2ysTTGz/0044-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/bFZCZrc/0044-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/645R1GM/0044-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/7CJ1jM4/0044-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/8sG0C99/0044-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/rHc9n9C/0044-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/qs8gkGd/0044-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/XY9KX0L/0044-019.webp"
  },
  {
    page: 20,
    link: "https://i.ibb.co/ZKnhMS9/0044-020.webp"
  },
  {
    page: 21,
    link: "https://i.ibb.co/xqvVg6w/0044-021.webp"
  },
  {
    page: 22,
    link: "https://i.ibb.co/4Vwnp3n/0044-022.webp"
  },
  {
    page: 23,
    link: "https://i.ibb.co/BfnwqD3/0044-023.webp"
  },
  {
    page: 24,
    link: "https://i.ibb.co/qM2nN4s/0044-024.webp"
  },
  {
    page: 25,
    link: "https://i.ibb.co/1bM5C4d/0044-025.webp"
  },
  {
    page: 26,
    link: "https://i.ibb.co/4F2gqpf/0044-026.webp"
  },
  {
    page: 27,
    link: "https://i.ibb.co/JncpVfj/0044-027.webp"
  },
  {
    page: 28,
    link: "https://i.ibb.co/C1tqkz4/0044-028.webp"
  },
  {
    page: 29,
    link: "https://i.ibb.co/fCn3fhz/0044-029.webp"
  },
  {
    page: 30,
    link: "https://i.ibb.co/RQsby3Z/0044-030.webp"
  },
  {
    page: 31,
    link: "https://i.ibb.co/8MT491b/0044-031.webp"
  },
  {
    page: 32,
    link: "https://i.ibb.co/kK7Cmvv/0044-032.webp"
  }
];


export default listePages;