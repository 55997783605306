const listePages = [
	{ page: 1, link: 'https://i.ibb.co/b2sVzdV/001.webp' },
	{ page: 2, link: 'https://i.ibb.co/BrB1WzY/002.webp' },
	{ page: 3, link: 'https://i.ibb.co/LNq7TCX/003.webp' },
	{ page: 4, link: 'https://i.ibb.co/qN88cLM/004.webp' },
	{ page: 5, link: 'https://i.ibb.co/9t76X1Z/005.webp' },
	{ page: 6, link: 'https://i.ibb.co/dr95Lt1/006.webp' },
	{ page: 7, link: 'https://i.ibb.co/D9qtN4h/007.webp' },
	{ page: 8, link: 'https://i.ibb.co/7SZNkWb/008.webp' },
	{ page: 9, link: 'https://i.ibb.co/M87qdLy/009.webp' },
	{ page: 10, link: 'https://i.ibb.co/dcrPVsw/010.webp' },
	{ page: 11, link: 'https://i.ibb.co/s5ZdrGR/011.webp' },
	{ page: 12, link: 'https://i.ibb.co/9GwFYF7/012.webp' },
	{ page: 13, link: 'https://i.ibb.co/phvmssY/013.webp' },
	{ page: 14, link: 'https://i.ibb.co/7VTsCMD/014.webp' },
	{ page: 15, link: 'https://i.ibb.co/4jdmjBv/015.webp' },
	{ page: 16, link: 'https://i.ibb.co/d0xFm2m/016.webp' },
	{ page: 17, link: 'https://i.ibb.co/ZgBHpxR/017.webp' },
	{ page: 18, link: 'https://i.ibb.co/QmDNqqZ/018.webp' },
	{ page: 19, link: 'https://i.ibb.co/SXpGyrz/019.webp' },
	{ page: 20, link: 'https://i.ibb.co/myytnkN/020.webp' },
	{ page: 21, link: 'https://i.ibb.co/PrVbXm3/021.webp' },
	{ page: 22, link: 'https://i.ibb.co/L6KHnGd/022.webp' },
	{ page: 23, link: 'https://i.ibb.co/7nDtGT7/023.webp' },
	{ page: 24, link: 'https://i.ibb.co/C5fP2T5/024.webp' },
	{ page: 25, link: 'https://i.ibb.co/RTxRJ0C/025.webp' },
	{ page: 26, link: 'https://i.ibb.co/HHMsJhH/026.webp' },
	{ page: 27, link: 'https://i.ibb.co/3myXTcs/027.webp' },
	{ page: 28, link: 'https://i.ibb.co/X7XrbQ5/028.webp' },
	{ page: 29, link: 'https://i.ibb.co/jHTKSH4/029.webp' },
	{ page: 30, link: 'https://i.ibb.co/RQZQjtZ/030.webp' },
	{ page: 31, link: 'https://i.ibb.co/yqPvD0v/031.webp' },
	{ page: 32, link: 'https://i.ibb.co/HrMp0yB/032.webp' },
	{ page: 33, link: 'https://i.ibb.co/9yxh9VP/033.webp' },
	{ page: 34, link: 'https://i.ibb.co/r0knrfT/034.webp' },
	{ page: 35, link: 'https://i.ibb.co/dpX8mBN/035.webp' },
	{ page: 36, link: 'https://i.ibb.co/4tY06Bk/036.webp' },
	{ page: 37, link: 'https://i.ibb.co/qDqtcMg/037.webp' },
	{ page: 38, link: 'https://i.ibb.co/2Z8QVTt/038.webp' },
	{ page: 39, link: 'https://i.ibb.co/w7YtmDH/039.webp' },
	{ page: 40, link: 'https://i.ibb.co/Cz535Nx/040.webp' },
	{ page: 41, link: 'https://i.ibb.co/3RtD9tS/041.webp' },
	{ page: 42, link: 'https://i.ibb.co/qpCsDLZ/042-043.webp' },
	{ page: 44, link: 'https://i.ibb.co/k5HdjGt/044.webp' },
	{ page: 45, link: 'https://i.ibb.co/gDwrwLd/045.webp' },
	{ page: 46, link: 'https://i.ibb.co/vkGYP3G/046-047.webp' },
	{ page: 48, link: 'https://i.ibb.co/6w6Vhwf/048.webp' }
  ]


export default listePages;