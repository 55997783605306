const listePages = [
  { page: 1, link: "https://i.ibb.co/VtrFnP4/0069-001.webp" },
  { page: 2, link: "https://i.ibb.co/DKXrk6C/0069-002.webp" },
  { page: 3, link: "https://i.ibb.co/HhcL69Q/0069-003.webp" },
  { page: 4, link: "https://i.ibb.co/Zh1PCHj/0069-004.webp" },
  { page: 5, link: "https://i.ibb.co/mybSQKL/0069-005.webp" },
  { page: 6, link: "https://i.ibb.co/W0R7nJZ/0069-006.webp" },
  { page: 7, link: "https://i.ibb.co/QPWgG2S/0069-007.webp" },
  { page: 8, link: "https://i.ibb.co/WGGDd1V/0069-008.webp" },
  { page: 9, link: "https://i.ibb.co/Hpf1Y2k/0069-009.webp" },
  { page: 10, link: "https://i.ibb.co/gm8NFBq/0069-010.webp" },
  { page: 11, link: "https://i.ibb.co/VgmqK51/0069-011.webp" },
  { page: 12, link: "https://i.ibb.co/qJcQ8dv/0069-012.webp" },
  { page: 13, link: "https://i.ibb.co/wr8JZ0h/0069-013.webp" },
  { page: 14, link: "https://i.ibb.co/Z6vHYtM/0069-014.webp" },
  { page: 15, link: "https://i.ibb.co/pbcKz8N/0069-015.webp" },
  { page: 16, link: "https://i.ibb.co/XWjQX4W/0069-016.webp" },
  { page: 17, link: "https://i.ibb.co/Dtpz5mz/0069-017.webp" },
  { page: 18, link: "https://i.ibb.co/pR2BNjM/0069-018.webp" },
  { page: 19, link: "https://i.ibb.co/CHqwfrw/0069-019.webp" },
  { page: 20, link: "https://i.ibb.co/fFYsTFb/0069-020.webp" },
  { page: 21, link: "https://i.ibb.co/PghkqxV/0069-021.webp" },
  { page: 22, link: "https://i.ibb.co/3k1KsmL/0069-022.webp" },
  { page: 23, link: "https://i.ibb.co/9NMdK0F/0069-023.webp" },
  { page: 24, link: "https://i.ibb.co/9w4mSt6/0069-024.webp" },
  { page: 25, link: "https://i.ibb.co/JxSDQ3w/0069-025.webp" },
  { page: 26, link: "https://i.ibb.co/G01d8sy/0069-026.webp" },
  { page: 27, link: "https://i.ibb.co/2YLMbM7/0069-027.webp" },
  { page: 28, link: "https://i.ibb.co/hMpDB3j/0069-028.webp" },
  { page: 29, link: "https://i.ibb.co/fphJ8b1/0069-029.webp" },
  { page: 30, link: "https://i.ibb.co/9NHK9kx/0069-030.webp" },
  { page: 31, link: "https://i.ibb.co/ctVc8K0/0069-031.webp" },
  { page: 32, link: "https://i.ibb.co/cDL1Xng/0069-032.webp" }
]


export default listePages;