const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/zJyZCrL/0046-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/1683CVV/0046-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/bLCv931/0046-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/37619F8/0046-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/r7kH56C/0046-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/DbfgDpY/0046-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/Jzr1Bt8/0046-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/nBGrGHL/0046-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/zHFNDYZ/0046-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/0K4nPGS/0046-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/C1ZcpMf/0046-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/jbBksxZ/0046-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/pR7rRvP/0046-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/JzjvXRS/0046-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/Y8F9rQk/0046-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/nCkzmgC/0046-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/4Kd6GrR/0046-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/bHd0YkH/0046-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/JCVvw8t/0046-019.webp"
  },
  {
    page: 20,
    link: "https://i.ibb.co/RC9p1nb/0046-020.webp"
  },
  {
    page: 21,
    link: "https://i.ibb.co/80h7TfL/0046-021.webp"
  },
  {
    page: 22,
    link: "https://i.ibb.co/4TVb6T7/0046-022.webp"
  },
  {
    page: 23,
    link: "https://i.ibb.co/QrRMsjT/0046-023.webp"
  },
  {
    page: 24,
    link: "https://i.ibb.co/dr6w7TG/0046-024.webp"
  },
  {
    page: 25,
    link: "https://i.ibb.co/rtgS6NQ/0046-025.webp"
  },
  {
    page: 26,
    link: "https://i.ibb.co/hR4JJr8/0046-026.webp"
  },
  {
    page: 27,
    link: "https://i.ibb.co/LrmFM9n/0046-027.webp"
  },
  {
    page: 28,
    link: "https://i.ibb.co/7bsvLzY/0046-028.webp"
  },
  {
    page: 29,
    link: "https://i.ibb.co/6ncXPWY/0046-029.webp"
  },
  {
    page: 30,
    link: "https://i.ibb.co/RD4z0Rb/0046-030.webp"
  },
  {
    page: 31,
    link: "https://i.ibb.co/v3bnFww/0046-031.webp"
  },
  {
    page: 32,
    link: "https://i.ibb.co/Vw0wd7C/0046-032.webp"
  },
  {
    page: 33,
    link: "https://i.ibb.co/NL233YW/0046-033.webp"
  },
  {
    page: 34,
    link: "https://i.ibb.co/PjfmZcJ/0046-034.webp"
  },
  {
    page: 35,
    link: "https://i.ibb.co/qJXyf8W/0046-035.webp"
  },
  {
    page: 36,
    link: "https://i.ibb.co/zPBCyGQ/0046-036.webp"
  },
  {
    page: 37,
    link: "https://i.ibb.co/pJgTVvc/0046-037.webp"
  },
  {
    page: 38,
    link: "https://i.ibb.co/vHJKTn2/0046-038.webp"
  },
  {
    page: 39,
    link: "https://i.ibb.co/Sw1zZVY/0046-039.webp"
  },
  {
    page: 40,
    link: "https://i.ibb.co/x6qjLNm/0046-040.webp"
  },
  {
    page: 41,
    link: "https://i.ibb.co/GTRQSV3/0046-041.webp"
  },
  {
    page: 42,
    link: "https://i.ibb.co/5sxknJn/0046-042.webp"
  },
  {
    page: 43,
    link: "https://i.ibb.co/1XNBXkz/0046-043.webp"
  },
  {
    page: 44,
    link: "https://i.ibb.co/48vDFCH/0046-044.webp"
  },
  {
    page: 45,
    link: "https://i.ibb.co/9gNxtBb/0046-045.webp"
  },
  {
    page: 46,
    link: "https://i.ibb.co/9yJhpGW/0046-046.webp"
  },
  {
    page: 47,
    link: "https://i.ibb.co/Wc7vR6r/0046-047.webp"
  },
  {
    page: 48,
    link: "https://i.ibb.co/9VWQJNt/0046-048.webp"
  },
  {
    page: 49,
    link: "https://i.ibb.co/ZSqsyb5/0046-049.webp"
  },
  {
    page: 50,
    link: "https://i.ibb.co/fHxS4Br/0046-050.webp"
  },
  {
    page: 51,
    link: "https://i.ibb.co/Nm8gMtg/0046-051.webp"
  },
  {
    page: 52,
    link: "https://i.ibb.co/26tT9SK/0046-052.webp"
  },
  {
    page: 53,
    link: "https://i.ibb.co/PNBzzbw/0046-053.webp"
  },
  {
    page: 54,
    link: "https://i.ibb.co/brFdxmn/0046-054.webp"
  }
];


export default listePages;