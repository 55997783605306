const listePages = [
    { page: 1, link: "https://i.ibb.co/q188PhX/0087-001.webp" },
    { page: 2, link: "https://i.ibb.co/ZTxxDHp/0087-002.webp" },
    { page: 3, link: "https://i.ibb.co/Kwwy4x6/0087-003.webp" },
    { page: 4, link: "https://i.ibb.co/Rc3QvPQ/0087-004.webp" },
    { page: 5, link: "https://i.ibb.co/2gJz88Q/0087-005.webp" },
    { page: 6, link: "https://i.ibb.co/FnM9g6x/0087-006.webp" },
    { page: 7, link: "https://i.ibb.co/Qjrvc31/0087-007.webp" },
    { page: 8, link: "https://i.ibb.co/VmK8nX7/0087-008.webp" },
    { page: 9, link: "https://i.ibb.co/2P7FK0b/0087-009.webp" },
    { page: 10, link: "https://i.ibb.co/8r8VQrC/0087-010.webp" },
    { page: 11, link: "https://i.ibb.co/hYfP9xM/0087-011.webp" },
    { page: 12, link: "https://i.ibb.co/P4xhqnj/0087-012.webp" },
    { page: 13, link: "https://i.ibb.co/Drwr2hq/0087-013.webp" },
    { page: 14, link: "https://i.ibb.co/Mcybjc6/0087-014.webp" },
    { page: 15, link: "https://i.ibb.co/9Nk82d4/0087-015.webp" },
    { page: 16, link: "https://i.ibb.co/c1rSCvR/0087-016.webp" },
    { page: 17, link: "https://i.ibb.co/LNrWQw3/0087-017.webp" },
    { page: 18, link: "https://i.ibb.co/6WMjMrT/0087-018.webp" },
    { page: 19, link: "https://i.ibb.co/pQhnnWK/0087-019.webp" },
    { page: 20, link: "https://i.ibb.co/gDb5R5L/0087-020.webp" },
    { page: 21, link: "https://i.ibb.co/5nJ1nJH/0087-021.webp" },
    { page: 22, link: "https://i.ibb.co/jrmQn2G/0087-022.webp" },
    { page: 23, link: "https://i.ibb.co/KsHn21N/0087-023.webp" },
    { page: 24, link: "https://i.ibb.co/drG7JD4/0087-024.webp" },
    { page: 25, link: "https://i.ibb.co/txjzZf5/0087-025.webp" },
    { page: 26, link: "https://i.ibb.co/VwbDPmf/0087-026.webp" },
    { page: 27, link: "https://i.ibb.co/wpq4Pk2/0087-027.webp" },
    { page: 28, link: "https://i.ibb.co/x8zZ5Rc/0087-028.webp" },
    { page: 29, link: "https://i.ibb.co/7tJMbc1/0087-029.webp" },
    { page: 30, link: "https://i.ibb.co/W3ZJ6F0/0087-030.webp" },
    { page: 31, link: "https://i.ibb.co/bRFQhYC/0087-031.webp" },
    { page: 32, link: "https://i.ibb.co/nsxvMtv/0087-032.webp" },
    { page: 33, link: "https://i.ibb.co/PYRBMyx/0087-033.webp" },
    { page: 34, link: "https://i.ibb.co/DQN3sRM/0087-034.webp" },
    { page: 35, link: "https://i.ibb.co/8cdxB9B/0087-035.webp" },
    { page: 36, link: "https://i.ibb.co/3y85jfM/0087-036.webp" },
    { page: 37, link: "https://i.ibb.co/2t57WRD/0087-037.webp" }
  ]
  

export default listePages;