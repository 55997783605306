import React from 'react';
import '../styles/App.scss'
import { Routes, Route } from "react-router-dom";
import Header from "./Header/Header.js"
import Footer from "./Footer"
import Home from "../pages/Home.js"
import Generation from '../pages/Generation.js';
import Faq from '../pages/Faq.js';
import Legal from '../pages/Legal';
import Saison from '../pages/Saison';
import EpisodesList from '../pages/EpisodesList';
import Episode from '../pages/Episode';
import FilmMenu from '../pages/FilmMenu';
import ScansMenu from '../pages/ScansMenu';
import Scan from '../pages/Scan';
import Film from '../pages/Film';
import HelpSection from './HelpSection.js';

export default function App() {
  return (
    <>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/:generation" element={<Generation />} />
          <Route path="/:generation/scans" element={<ScansMenu />} />
          <Route path="/:generation/scans/:chapitre" element={<Scan />} />
          <Route path="/:generation/film" element={<FilmMenu />} />
          <Route path="/:generation/film/:langue" element={<Film />} />
          <Route path="/:generation/:saison" element={<Saison />} />
          <Route path="/:generation/:saison/:langue" element={<EpisodesList />} />
          <Route path="/:generation/:saison/:langue/:episode" element={<Episode />} />
        </Routes>
        <Footer />
        {/* <MobileAppAd /> */}
        <HelpSection />
    </>
  );
}