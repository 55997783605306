const listePages = [
    {
        "page": 1,
        "link": "https://i.ibb.co/XWqFMtw/0114.webp"
      },
      {
        "page": 2,
        "link": "https://i.ibb.co/1z2gx8D/0115.webp"
      },
      {
        "page": 3,
        "link": "https://i.ibb.co/PmnTbtm/0116.webp"
      },
      {
        "page": 4,
        "link": "https://i.ibb.co/s2gVJZg/0117.webp"
      },
      {
        "page": 5,
        "link": "https://i.ibb.co/ss67331/0118.webp"
      },
      {
        "page": 6,
        "link": "https://i.ibb.co/n1qT4YD/0119.webp"
      },
      {
        "page": 7,
        "link": "https://i.ibb.co/z8hbhpy/0120.webp"
      },
      {
        "page": 8,
        "link": "https://i.ibb.co/5jHJqwZ/0121.webp"
      },
      {
        "page": 9,
        "link": "https://i.ibb.co/chXjXwq/0122.webp"
      },
      {
        "page": 10,
        "link": "https://i.ibb.co/dWmbdH5/0123.webp"
      },
      {
        "page": 11,
        "link": "https://i.ibb.co/DMyL7FK/0124.webp"
      },
      {
        "page": 12,
        "link": "https://i.ibb.co/j8QcLPS/0125.webp"
      },
      {
        "page": 13,
        "link": "https://i.ibb.co/VpRjhqq/0126.webp"
      },
      {
        "page": 14,
        "link": "https://i.ibb.co/1Z5nYmj/0127.webp"
      },
      {
        "page": 15,
        "link": "https://i.ibb.co/1sspNTG/0128.webp"
      },
      {
        "page": 16,
        "link": "https://i.ibb.co/hCwwqCJ/0129.webp"
      },
      {
        "page": 17,
        "link": "https://i.ibb.co/tPhf2rF/0130.webp"
      },
      {
        "page": 18,
        "link": "https://i.ibb.co/C1cw4Mk/0131.webp"
      },
      {
        "page": 19,
        "link": "https://i.ibb.co/QJmMN8n/0132.webp"
      },
      {
        "page": 20,
        "link": "https://i.ibb.co/9HNGTPZ/0133.webp"
      },
      {
        "page": 21,
        "link": "https://i.ibb.co/dbBXB9P/0134.webp"
      },
      {
        "page": 22,
        "link": "https://i.ibb.co/9bVtfrp/0135.webp"
      },
      {
        "page": 23,
        "link": "https://i.ibb.co/SvmdzZ0/0136.webp"
      },
      {
        "page": 24,
        "link": "https://i.ibb.co/BgMHt79/0137.webp"
      },
      {
        "page": 25,
        "link": "https://i.ibb.co/g64Wx7P/0138.webp"
      },
      {
        "page": 26,
        "link": "https://i.ibb.co/HVmYWg7/0139.webp"
      },
      {
        "page": 27,
        "link": "https://i.ibb.co/SmCLRP9/0140.webp"
      },
      {
        "page": 28,
        "link": "https://i.ibb.co/tphmtzY/0141.webp"
      },
      {
        "page": 29,
        "link": "https://i.ibb.co/BGHyyLg/0142.webp"
      },
      {
        "page": 30,
        "link": "https://i.ibb.co/7VcxNyt/0143.webp"
      },
      {
        "page": 31,
        "link": "https://i.ibb.co/FD3V55j/0144.webp"
      },
      {
        "page": 32,
        "link": "https://i.ibb.co/TcTMKfn/0145.webp"
      },
      {
        "page": 33,
        "link": "https://i.ibb.co/0X1ZKwW/0146.webp"
      },
      {
        "page": 34,
        "link": "https://i.ibb.co/v4j1S55/0147.webp"
      },
      {
        "page": 35,
        "link": "https://i.ibb.co/x1VM85J/0148.webp"
      },
      {
        "page": 36,
        "link": "https://i.ibb.co/MBhD65M/0149.webp"
      },
      {
        "page": 37,
        "link": "https://i.ibb.co/rMR3t7n/0150.webp"
      },
      {
        "page": 38,
        "link": "https://i.ibb.co/VHMjM3d/0151.webp"
      },
      {
        "page": 39,
        "link": "https://i.ibb.co/1MkRC3Y/0152.webp"
      },
      {
        "page": 40,
        "link": "https://i.ibb.co/Twv8v2n/0153.webp"
      },
      {
        "page": 41,
        "link": "https://i.ibb.co/CMZGTB7/0154.webp"
      }
]


export default listePages;