import React from 'react'
import AnimeList from '../../datas/GenerationsList'

export default function HomeAnimesList() {
  return (
    <section className='animes-section'>
      <div className='title-container'>
        <div className='title-decoration'></div>
        <h1>BEYBLADE ANIMES</h1>
      </div>
      <div className='articles-container'>
        {
          AnimeList.map((anime) =>
          <article key={anime.id}>
            <a href={anime.link}>
              <div className='card'>
                <div className='wrapper'>
                  <img width="220px" height="310px" src={anime.img} alt={"Affiche de " + anime.titre + " Beyblade Streaming"}/>
                </div>
                <img src={anime.logo} className='logotitle' alt={"Logo de " + anime.titre + " Beyblade Streaming"} />
                <img src={anime.character} className='character' alt={"Personnage principal de " + anime.titre + " Beyblade Streaming"} />
              </div>
              
              <h2>{anime.titre}</h2>
            </a>
          </article>
          )
        }
      </div>
    </section>
  )
}