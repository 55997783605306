const listePages = [
    {
        "page": 1,
        "link": "https://i.ibb.co/yPytHbf/0001.webp"
      },
      {
        "page": 2,
        "link": "https://i.ibb.co/hd36DMh/0003.webp"
      },
      {
        "page": 3,
        "link": "https://i.ibb.co/Mgvzfz0/0004.webp"
      },
      {
        "page": 4,
        "link": "https://i.ibb.co/KjY60qd/0005.webp"
      },
      {
        "page": 5,
        "link": "https://i.ibb.co/sqktdZq/0006.webp"
      },
      {
        "page": 6,
        "link": "https://i.ibb.co/DgxjwRP/0007.webp"
      },
      {
        "page": 7,
        "link": "https://i.ibb.co/HrxKJnN/0008.webp"
      },
      {
        "page": 8,
        "link": "https://i.ibb.co/LPyCS5w/0009.webp"
      },
      {
        "page": 9,
        "link": "https://i.ibb.co/d6xLkfZ/0010.webp"
      },
      {
        "page": 10,
        "link": "https://i.ibb.co/Scz0bTS/0011.webp"
      },
      {
        "page": 11,
        "link": "https://i.ibb.co/njgDKjw/0012.webp"
      },
      {
        "page": 12,
        "link": "https://i.ibb.co/bBnKKMP/0013.webp"
      },
      {
        "page": 13,
        "link": "https://i.ibb.co/vjGmYND/0014.webp"
      },
      {
        "page": 14,
        "link": "https://i.ibb.co/4gBBBTm/0015.webp"
      },
      {
        "page": 15,
        "link": "https://i.ibb.co/GFcr5K5/0016.webp"
      },
      {
        "page": 16,
        "link": "https://i.ibb.co/MVj2cxX/0017.webp"
      },
      {
        "page": 17,
        "link": "https://i.ibb.co/XDt74MJ/0018.webp"
      },
      {
        "page": 18,
        "link": "https://i.ibb.co/bQk1YXR/0019.webp"
      },
      {
        "page": 19,
        "link": "https://i.ibb.co/Y0k6MC2/0020.webp"
      },
      {
        "page": 20,
        "link": "https://i.ibb.co/BKwzHXH/0021.webp"
      },
      {
        "page": 21,
        "link": "https://i.ibb.co/hRZ8NBC/0022.webp"
      },
      {
        "page": 22,
        "link": "https://i.ibb.co/xgMWhcK/0023.webp"
      },
      {
        "page": 23,
        "link": "https://i.ibb.co/C6zjWWm/0024.webp"
      },
      {
        "page": 24,
        "link": "https://i.ibb.co/VgdxzrS/0025.webp"
      },
      {
        "page": 25,
        "link": "https://i.ibb.co/X5BCZTw/0026.webp"
      },
      {
        "page": 26,
        "link": "https://i.ibb.co/6PJQY0q/0027.webp"
      },
      {
        "page": 27,
        "link": "https://i.ibb.co/dKN0jcJ/0028.webp"
      },
      {
        "page": 28,
        "link": "https://i.ibb.co/F8BcvYz/0029.webp"
      },
      {
        "page": 29,
        "link": "https://i.ibb.co/0M42HdK/0030.webp"
      },
      {
        "page": 30,
        "link": "https://i.ibb.co/Gc1Lg3y/0031.webp"
      },
      {
        "page": 31,
        "link": "https://i.ibb.co/0fPzbBm/0032.webp"
      },
      {
        "page": 32,
        "link": "https://i.ibb.co/5jHy3N4/0033.webp"
      },
      {
        "page": 33,
        "link": "https://i.ibb.co/F3VH5Xc/0034.webp"
      }
]


export default listePages;