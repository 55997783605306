const listePages = [
    {
        "page": 1,
        "link": "https://i.ibb.co/6rdrPFX/0001.webp"
      },
      {
        "page": 2,
        "link": "https://i.ibb.co/ZzbfmDQ/0003.webp"
      },
      {
        "page": 3,
        "link": "https://i.ibb.co/Nn13X8Z/0004.webp"
      },
      {
        "page": 4,
        "link": "https://i.ibb.co/RNVGZbv/0005.webp"
      },
      {
        "page": 5,
        "link": "https://i.ibb.co/yBwgB5q/0006.webp"
      },
      {
        "page": 6,
        "link": "https://i.ibb.co/t850PdC/0007.webp"
      },
      {
        "page": 7,
        "link": "https://i.ibb.co/n1QhhVz/0008.webp"
      },
      {
        "page": 8,
        "link": "https://i.ibb.co/wSvmyzk/0009.webp"
      },
      {
        "page": 9,
        "link": "https://i.ibb.co/0MkzG0C/0010.webp"
      },
      {
        "page": 10,
        "link": "https://i.ibb.co/8spn9qh/0011.webp"
      },
      {
        "page": 11,
        "link": "https://i.ibb.co/wdBhXZ9/0012.webp"
      },
      {
        "page": 12,
        "link": "https://i.ibb.co/m9vdHQK/0013.webp"
      },
      {
        "page": 13,
        "link": "https://i.ibb.co/nf6FM3n/0014.webp"
      },
      {
        "page": 14,
        "link": "https://i.ibb.co/vkmGkBt/0015.webp"
      },
      {
        "page": 15,
        "link": "https://i.ibb.co/PwkfVLv/0016.webp"
      },
      {
        "page": 16,
        "link": "https://i.ibb.co/djwKjwb/0017.webp"
      },
      {
        "page": 17,
        "link": "https://i.ibb.co/P5P8nry/0018.webp"
      },
      {
        "page": 18,
        "link": "https://i.ibb.co/HV8pDnM/0019.webp"
      },
      {
        "page": 19,
        "link": "https://i.ibb.co/vYyHrnD/0020.webp"
      },
      {
        "page": 20,
        "link": "https://i.ibb.co/Drr8QFV/0021.webp"
      },
      {
        "page": 21,
        "link": "https://i.ibb.co/r7WZGqG/0022.webp"
      },
      {
        "page": 22,
        "link": "https://i.ibb.co/tBfDKR2/0023.webp"
      },
      {
        "page": 23,
        "link": "https://i.ibb.co/Cw7Y7Fn/0024.webp"
      },
      {
        "page": 24,
        "link": "https://i.ibb.co/881MwBs/0025.webp"
      },
      {
        "page": 25,
        "link": "https://i.ibb.co/0YzJYqj/0026.webp"
      },
      {
        "page": 26,
        "link": "https://i.ibb.co/zS8SvJY/0027.webp"
      },
      {
        "page": 27,
        "link": "https://i.ibb.co/kJTh18N/0028.webp"
      },
      {
        "page": 28,
        "link": "https://i.ibb.co/x1Yn7Zd/0029.webp"
      },
      {
        "page": 29,
        "link": "https://i.ibb.co/CWKQGgB/0030.webp"
      },
      {
        "page": 30,
        "link": "https://i.ibb.co/7S5hB1b/0031.webp"
      },
      {
        "page": 31,
        "link": "https://i.ibb.co/9ycd23h/0032.webp"
      },
      {
        "page": 32,
        "link": "https://i.ibb.co/YydhwSn/0033.webp"
      },
      {
        "page": 33,
        "link": "https://i.ibb.co/p1Q75Jr/0034.webp"
      },
      {
        "page": 34,
        "link": "https://i.ibb.co/XCBSCHp/0035.webp"
      },
      {
        "page": 35,
        "link": "https://i.ibb.co/DgggLLc/0036.webp"
      },
      {
        "page": 36,
        "link": "https://i.ibb.co/GMc9jmh/0037.webp"
      },
      {
        "page": 37,
        "link": "https://i.ibb.co/mqHTYsc/0038.webp"
      },
      {
        "page": 38,
        "link": "https://i.ibb.co/h8140t1/0039.webp"
      },
      {
        "page": 39,
        "link": "https://i.ibb.co/BnGn0BT/0040.webp"
      },
      {
        "page": 40,
        "link": "https://i.ibb.co/gjd4V2j/0041.webp"
      },
      {
        "page": 41,
        "link": "https://i.ibb.co/YdLpBFk/0042.webp"
      },
      {
        "page": 42,
        "link": "https://i.ibb.co/wW4bhBx/0043.webp"
      },
      {
        "page": 43,
        "link": "https://i.ibb.co/LRjZHnz/0044.webp"
      }
]


export default listePages;