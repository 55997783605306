const listePages = [
    {
      "page": 1,
      "link": "https://i.ibb.co/nPqB12D/0042.webp"
    },
    {
      "page": 2,
      "link": "https://i.ibb.co/GPwxFSC/0043.webp"
    },
    {
      "page": 3,
      "link": "https://i.ibb.co/w0qxC5Y/0044.webp"
    },
    {
      "page": 4,
      "link": "https://i.ibb.co/N3rygfG/0045.webp"
    },
    {
      "page": 5,
      "link": "https://i.ibb.co/qrMK4W0/0046.webp"
    },
    {
      "page": 6,
      "link": "https://i.ibb.co/bdGPdk6/0047.webp"
    },
    {
      "page": 7,
      "link": "https://i.ibb.co/YtMhkFc/0048.webp"
    },
    {
      "page": 8,
      "link": "https://i.ibb.co/zV7rfB1/0049.webp"
    },
    {
      "page": 9,
      "link": "https://i.ibb.co/x57C2gG/0050.webp"
    },
    {
      "page": 10,
      "link": "https://i.ibb.co/rcghLcv/0051.webp"
    },
    {
      "page": 11,
      "link": "https://i.ibb.co/2558FYs/0052.webp"
    },
    {
      "page": 12,
      "link": "https://i.ibb.co/R02XrmN/0053.webp"
    },
    {
      "page": 13,
      "link": "https://i.ibb.co/f9CJJPV/0054.webp"
    },
    {
      "page": 14,
      "link": "https://i.ibb.co/0BFR5j8/0055.webp"
    },
    {
      "page": 15,
      "link": "https://i.ibb.co/FV23LQN/0056.webp"
    },
    {
      "page": 16,
      "link": "https://i.ibb.co/ByDSfwH/0057.webp"
    },
    {
      "page": 17,
      "link": "https://i.ibb.co/x1sGmZv/0058.webp"
    },
    {
      "page": 18,
      "link": "https://i.ibb.co/MDfK9zB/0059.webp"
    },
    {
      "page": 19,
      "link": "https://i.ibb.co/BqFJdsT/0060.webp"
    },
    {
      "page": 20,
      "link": "https://i.ibb.co/cyvTLD3/0061.webp"
    },
    {
      "page": 21,
      "link": "https://i.ibb.co/0CWXmXt/0062.webp"
    },
    {
      "page": 22,
      "link": "https://i.ibb.co/xfJ5wtL/0063.webp"
    },
    {
      "page": 23,
      "link": "https://i.ibb.co/Sym170J/0064.webp"
    },
    {
      "page": 24,
      "link": "https://i.ibb.co/wRsd1nQ/0065.webp"
    },
    {
      "page": 25,
      "link": "https://i.ibb.co/4F365Tv/0066.webp"
    },
    {
      "page": 26,
      "link": "https://i.ibb.co/KVyV1fZ/0067.webp"
    },
    {
      "page": 27,
      "link": "https://i.ibb.co/THT0T7V/0068.webp"
    },
    {
      "page": 28,
      "link": "https://i.ibb.co/D95TTgc/0069.webp"
    },
    {
      "page": 29,
      "link": "https://i.ibb.co/9yJp5Vt/0070.webp"
    },
    {
      "page": 30,
      "link": "https://i.ibb.co/18TCtBs/0071.webp"
    },
    {
      "page": 31,
      "link": "https://i.ibb.co/r7FxLs0/0072.webp"
    },
    {
      "page": 32,
      "link": "https://i.ibb.co/TKN6271/0073.webp"
    },
    {
      "page": 33,
      "link": "https://i.ibb.co/fY9FTrN/0074.webp"
    },
    {
      "page": 34,
      "link": "https://i.ibb.co/wprzPmg/0075.webp"
    },
    {
      "page": 35,
      "link": "https://i.ibb.co/TrbNwpm/0076.webp"
    },
    {
      "page": 36,
      "link": "https://i.ibb.co/LRzBm1c/0077.webp"
    },
    {
      "page": 37,
      "link": "https://i.ibb.co/KXXzZTG/0078.webp"
    },
    {
      "page": 38,
      "link": "https://i.ibb.co/pxpR3TQ/0079.webp"
    },
    {
      "page": 39,
      "link": "https://i.ibb.co/d70yG4B/0080.webp"
    },
    {
      "page": 40,
      "link": "https://i.ibb.co/hc3zNZ0/0081.webp"
    },
    {
      "page": 41,
      "link": "https://i.ibb.co/0KZqp18/0082.webp"
    },
    {
      "page": 42,
      "link": "https://i.ibb.co/5n01cPR/0083.webp"
    }
  ]


export default listePages;