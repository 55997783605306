/* eslint-disable default-case */
import { Helmet } from 'react-helmet';
import MenuSaisons from '../components/MenuSaisons';
import { useParams } from 'react-router-dom';

export default function Saison() {
    const param = useParams()
    const generationURL = param.generation
    const saisonURL = param.saison

    let actualGeneration;
    let actualSaison;

    switch (generationURL) {
        case "bakuten":
            actualGeneration = "Bakuten Shoot Beyblade"
            switch (saisonURL) {
                case "saison1":
                    actualSaison = false;
                    break
                case "saison2":
                    actualSaison = "2002";
                    break
                case "saison3":
                    actualSaison = "G Revolution"
                    break
            }
            break
        case "metal":
            actualGeneration = "Metal Fight Beyblade"
            switch (saisonURL) {
                case "saison1":
                    actualSaison = false;
                    break
                case "saison2":
                    actualSaison = ": Baku";
                    break
                case "saison3":
                    actualSaison = "4D"
                    break
                case "saison4":
                    actualSaison = "Zero G"
                    break
            }
            break
        case "burst":
            actualGeneration = "Beyblade Burst"
            switch (saisonURL) {
                case "saison1":
                    actualSaison = false;
                    break
                case "saison2":
                    actualSaison = "God";
                    break
                case "saison3":
                    actualSaison = "Cho-Z"
                    break
                case "saison4":
                    actualSaison = "GT"
                    break
                case "saison5":
                    actualSaison = "SuperKing"
                    break
                case "saison6":
                    actualSaison = "Dynamite Battle"
                    break
                case "saison7":
                    actualSaison = "QuadStrike"
                    break
            }
            break
        case "x":
            actualGeneration = "Beyblade X"
            actualSaison = false
            break
        case "spinoff":
            actualGeneration = "BeyWheels"
            break
    }

    return (
        <>
            <Helmet>
                <title>{actualGeneration}{actualSaison ? ` ${actualSaison} ` : ""} | Streaming Espace</title>
                <link rel="canonical" href={`https://www.streaming-espace.fr/${generationURL}/${saisonURL}`}/>
                <meta name="description" content={"Découvre la saison " + actualSaison + " de " + actualGeneration + "."} />
            </Helmet>
            <main className="content container Saisonpage">
                <MenuSaisons />
            </main>                
        </>
    )       
}