const listePages = [
    {
        "page": 1,
        "link": "https://i.ibb.co/BGkgshL/0116.webp"
      },
      {
        "page": 2,
        "link": "https://i.ibb.co/MNgGtsp/0117.webp"
      },
      {
        "page": 3,
        "link": "https://i.ibb.co/GP8rcjx/0118.webp"
      },
      {
        "page": 4,
        "link": "https://i.ibb.co/1K1FNjy/0119.webp"
      },
      {
        "page": 5,
        "link": "https://i.ibb.co/187PdVR/0120.webp"
      },
      {
        "page": 6,
        "link": "https://i.ibb.co/R7fKfWG/0121.webp"
      },
      {
        "page": 7,
        "link": "https://i.ibb.co/CKSQfKt/0122.webp"
      },
      {
        "page": 8,
        "link": "https://i.ibb.co/NyWqbT4/0123.webp"
      },
      {
        "page": 9,
        "link": "https://i.ibb.co/Dw2TpXF/0124.webp"
      },
      {
        "page": 10,
        "link": "https://i.ibb.co/vQCZH9w/0125.webp"
      },
      {
        "page": 11,
        "link": "https://i.ibb.co/GRkXHZC/0126.webp"
      },
      {
        "page": 12,
        "link": "https://i.ibb.co/vBm80zw/0127.webp"
      },
      {
        "page": 13,
        "link": "https://i.ibb.co/D5mhsxb/0128.webp"
      },
      {
        "page": 14,
        "link": "https://i.ibb.co/k3nZBRC/0129.webp"
      },
      {
        "page": 15,
        "link": "https://i.ibb.co/qDdZTcb/0130.webp"
      },
      {
        "page": 16,
        "link": "https://i.ibb.co/K71bvvC/0131.webp"
      },
      {
        "page": 17,
        "link": "https://i.ibb.co/vHC181b/0132.webp"
      },
      {
        "page": 18,
        "link": "https://i.ibb.co/mvF6txT/0133.webp"
      },
      {
        "page": 19,
        "link": "https://i.ibb.co/BPy5nNV/0134.webp"
      },
      {
        "page": 20,
        "link": "https://i.ibb.co/MnLGCnQ/0135.webp"
      },
      {
        "page": 21,
        "link": "https://i.ibb.co/Z8tjVHb/0136.webp"
      },
      {
        "page": 22,
        "link": "https://i.ibb.co/ggfc1bF/0137.webp"
      },
      {
        "page": 23,
        "link": "https://i.ibb.co/KzC3sKK/0138.webp"
      },
      {
        "page": 24,
        "link": "https://i.ibb.co/xmRXhXV/0139.webp"
      },
      {
        "page": 25,
        "link": "https://i.ibb.co/jJmNHxq/0140.webp"
      },
      {
        "page": 26,
        "link": "https://i.ibb.co/3TFMZP6/0141.webp"
      },
      {
        "page": 27,
        "link": "https://i.ibb.co/vDyYkxr/0142.webp"
      },
      {
        "page": 28,
        "link": "https://i.ibb.co/dKWQWGC/0143.webp"
      },
      {
        "page": 29,
        "link": "https://i.ibb.co/HY18nt5/0144.webp"
      },
      {
        "page": 30,
        "link": "https://i.ibb.co/G769qSd/0145.webp"
      },
      {
        "page": 31,
        "link": "https://i.ibb.co/Z6N1tdB/0146.webp"
      },
      {
        "page": 32,
        "link": "https://i.ibb.co/qjPSwDJ/0147.webp"
      },
      {
        "page": 33,
        "link": "https://i.ibb.co/mhfm9FW/0148.webp"
      },
      {
        "page": 34,
        "link": "https://i.ibb.co/HYst5cm/0149.webp"
      },
      {
        "page": 35,
        "link": "https://i.ibb.co/bW0WqG8/0150.webp"
      },
      {
        "page": 36,
        "link": "https://i.ibb.co/169KjxQ/0151.webp"
      },
      {
        "page": 37,
        "link": "https://i.ibb.co/JycQ5Pq/0152.webp"
      },
      {
        "page": 38,
        "link": "https://i.ibb.co/Yk9bSLQ/0153.webp"
      },
      {
        "page": 39,
        "link": "https://i.ibb.co/DpXLWFz/0154.webp"
      },
      {
        "page": 40,
        "link": "https://i.ibb.co/8NpN0p0/0155.webp"
      }
]


export default listePages;