const listeEpisodes = [
    {
        episode : 1,
        multi: true,
        links : [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239071&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/kwgjuqkj"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5479772"
            }
        ]
    },
    {
        episode : 2,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239072&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/kcx9miab"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5479775"
            }
        ]
    },
    {
        episode : 3,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239073&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/cz9382sw"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5479778"
            }
        ]
    },
    {
        episode : 4,
        multi: true,
        links : [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239074&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/oiy2dsle"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5479779"
            }
        ]
    },
    {
        episode : 5,
        multi: true,
        links : [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239075&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/ubl9ylws"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5479781"
            }
        ]
    },
    {
        episode : 6,
        multi: true,
        links : [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239076&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/56h8r1ct"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5479784"
            }
        ]
    },
    {
        episode : 7,
        multi: true,
        links : [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239077&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/tdqcf88g"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5479785"
            }
        ]
    },
    {
        episode : 8,
        multi: true,
        links : [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239078&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/izlfutkw"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5479787"
            }
        ]
    },
    {
        episode : 9,
        multi: true,
        links : [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239079&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/n0g2klnx"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5479790"
            }
        ]
    },
    {
        episode : 10,
        multi: true,
        links : [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239080&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/59bt86vi"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5479796"
            }
        ]
    },
    {
        episode : 11,
        multi: true,
        links : [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239081&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/r260tsxe"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5479800"
            }
        ]
    },
    {
        episode : 12,
        multi: true,
        links : [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239082&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/d2j1ragy"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5479802"
            }
        ]
    },
    {
        episode : 13,
        multi: true,
        links : [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239083&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/llqkrsnh"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5479806"
            }
        ]
    },
    {
        episode : 14,
        multi: true,
        links : [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239084&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/hnna23uq"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5479808"
            }
        ]
    },
    {
        episode : 15,
        multi: true,
        links : [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239085&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/yx72xr2j"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5479814"
            }
        ]
    },
    {
        episode : 16,
        multi: true,
        links : [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239086&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/a1177uy1"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5479815"
            }
        ]
    },
    {
        episode : 17,
        multi: true,
        links : [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239087&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/p0vde6k3"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5479820"
            }
        ]
    },
    {
        episode : 18,
        multi: true,
        links : [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239088&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/rqs4a5dr"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5479823"
            }
        ]
    },
    {
        episode : 19,
        multi: true,
        links : [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239089&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/tkyuc8se"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5479827"
            }
        ]
    },
    {
        episode : 20,
        multi: true,
        links : [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239090&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/w8czbbhp"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5479829"
            }
        ]
    },
    {
        episode : 21,
        multi: true,
        links : [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239091&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/9z5t1c2b"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5479832"
            }
        ]
    },
    {
        episode : 22,
        multi: true,
        links : [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239092&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/76ut4mxm"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5479835"
            }
        ]
    },
    {
        episode : 23,
        multi: true,
        links : [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239093&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/5qfch8z3"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5479840"
            }
        ]
    },
    {
        episode : 24,
        multi: true,
        links : [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239094&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/8fnn8r90"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5479857"
            }
        ]
    },
    {
        episode : 25,
        multi: true,
        links : [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239095&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/i0um2exp"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5479847"
            }
        ],
    },
    {
        episode : 26,
        multi: true,
        links : [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239096&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/vbpiepfv"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5479849"
            }
        ],
    }
]

export default listeEpisodes;