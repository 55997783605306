const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/sPHvRhy/18-7-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/sm2ZV6p/18-7-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/b3nKxH9/18-7-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/qYBPTDn/18-7-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/YByWn4n/18-7-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/yVgQv7F/18-7-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/PgNvJCm/18-7-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/MNJhwFc/18-7-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/1qJfq0f/18-7-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/tZs1w3r/18-7-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/TPP1pvW/18-7-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/s6MfFw2/18-7-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/BwGkD8V/18-7-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/j3nQbD5/18-7-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/MgQPBdb/18-7-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/qFK8L5F/18-7-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/c2yFQbp/18-7-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/fktyPMc/18-7-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/C0r6YZr/18-7-019.webp"
  }
]

export default listePages;