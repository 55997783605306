const listePages = [
  { page: 1, link: "https://i.ibb.co/CmQ3PRS/53-5-001.webp" },
  { page: 2, link: "https://i.ibb.co/FJGCKbt/53-5-002.webp" },
  { page: 3, link: "https://i.ibb.co/YjmCZzC/53-5-003.webp" },
  { page: 4, link: "https://i.ibb.co/L0Wgbq8/53-5-004.webp" },
  { page: 5, link: "https://i.ibb.co/RyvPHz0/53-5-005.webp" },
  { page: 6, link: "https://i.ibb.co/gDz73MG/53-5-006.webp" },
  { page: 7, link: "https://i.ibb.co/XWfHm13/53-5-007.webp" },
  { page: 8, link: "https://i.ibb.co/4gtQ4Zp/53-5-008.webp" },
  { page: 9, link: "https://i.ibb.co/vLP4rRD/53-5-009.webp" }
]


export default listePages;