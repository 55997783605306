const listePages = [
    {
      "page": 1,
      "link": "https://i.ibb.co/YBCT0x0/0001.webp"
    },
    {
      "page": 2,
      "link": "https://i.ibb.co/7RwRhpW/0005.webp"
    },
    {
      "page": 3,
      "link": "https://i.ibb.co/YLfspbL/0006.webp"
    },
    {
      "page": 4,
      "link": "https://i.ibb.co/44rMXbY/0007.webp"
    },
    {
      "page": 5,
      "link": "https://i.ibb.co/9Z4XF6p/0008.webp"
    },
    {
      "page": 6,
      "link": "https://i.ibb.co/MGhw7fc/0009.webp"
    },
    {
      "page": 7,
      "link": "https://i.ibb.co/SPNx7B8/0010.webp"
    },
    {
      "page": 8,
      "link": "https://i.ibb.co/LrkZF6c/0011.webp"
    },
    {
      "page": 9,
      "link": "https://i.ibb.co/jHXxvb8/0012.webp"
    },
    {
      "page": 10,
      "link": "https://i.ibb.co/1rRbqXy/0013.webp"
    },
    {
      "page": 11,
      "link": "https://i.ibb.co/HKgpz8T/0014.webp"
    },
    {
      "page": 12,
      "link": "https://i.ibb.co/tbT0J7d/0015.webp"
    },
    {
      "page": 13,
      "link": "https://i.ibb.co/n3MXTYL/0016.webp"
    },
    {
      "page": 14,
      "link": "https://i.ibb.co/QvXh8PB/0017.webp"
    },
    {
      "page": 15,
      "link": "https://i.ibb.co/v3zYTv6/0018.webp"
    },
    {
      "page": 16,
      "link": "https://i.ibb.co/0ySfKww/0019.webp"
    },
    {
      "page": 17,
      "link": "https://i.ibb.co/TwwFmSq/0020.webp"
    },
    {
      "page": 18,
      "link": "https://i.ibb.co/v30X22k/0021.webp"
    },
    {
      "page": 19,
      "link": "https://i.ibb.co/km7yCTZ/0022.webp"
    },
    {
      "page": 20,
      "link": "https://i.ibb.co/RSGNKXW/0023.webp"
    },
    {
      "page": 21,
      "link": "https://i.ibb.co/ChBF9Vn/0024.webp"
    },
    {
      "page": 22,
      "link": "https://i.ibb.co/0qH5cgh/0025.webp"
    },
    {
      "page": 23,
      "link": "https://i.ibb.co/0MhtvWh/0026.webp"
    },
    {
      "page": 24,
      "link": "https://i.ibb.co/0mjRZHZ/0027.webp"
    },
    {
      "page": 25,
      "link": "https://i.ibb.co/f1xHnxZ/0028.webp"
    },
    {
      "page": 26,
      "link": "https://i.ibb.co/Rpc4RkX/0029.webp"
    },
    {
      "page": 27,
      "link": "https://i.ibb.co/zJpcg9f/0030.webp"
    },
    {
      "page": 28,
      "link": "https://i.ibb.co/hcCYtNX/0031.webp"
    },
    {
      "page": 29,
      "link": "https://i.ibb.co/LZ77gwV/0032.webp"
    },
    {
      "page": 30,
      "link": "https://i.ibb.co/7NDBbvK/0032-1.webp"
    },
    {
      "page": 31,
      "link": "https://i.ibb.co/tshznvW/0033.webp"
    },
    {
      "page": 32,
      "link": "https://i.ibb.co/5RGqk7d/0034.webp"
    },
    {
      "page": 33,
      "link": "https://i.ibb.co/NYFYPND/0035.webp"
    },
    {
      "page": 34,
      "link": "https://i.ibb.co/gvJZQxj/0036.webp"
    },
    {
      "page": 35,
      "link": "https://i.ibb.co/1qt2tNd/0037.webp"
    },
    {
      "page": 36,
      "link": "https://i.ibb.co/ZzjYRNv/0038.webp"
    },
    {
      "page": 37,
      "link": "https://i.ibb.co/bdW8RCc/0039.webp"
    },
    {
      "page": 38,
      "link": "https://i.ibb.co/DMYrrqw/0040.webp"
    },
    {
      "page": 39,
      "link": "https://i.ibb.co/VwyHNCw/0041.webp"
    },
    {
      "page": 40,
      "link": "https://i.ibb.co/GR5SLHG/0042.webp"
    },
    {
      "page": 41,
      "link": "https://i.ibb.co/1nh8Drs/0043.webp"
    },
    {
      "page": 42,
      "link": "https://i.ibb.co/C2zY0tn/0044.webp"
    },
    {
      "page": 43,
      "link": "https://i.ibb.co/4NYFWPH/0045.webp"
    },
    {
      "page": 44,
      "link": "https://i.ibb.co/KXrkWyM/0046.webp"
    },
    {
      "page": 45,
      "link": "https://i.ibb.co/7vXJLPK/0047.webp"
    },
    {
      "page": 46,
      "link": "https://i.ibb.co/34qvygj/0048.webp"
    },
    {
      "page": 47,
      "link": "https://i.ibb.co/dckjL7z/0049.webp"
    },
    {
      "page": 48,
      "link": "https://i.ibb.co/F09kTKc/0050.webp"
    },
    {
      "page": 49,
      "link": "https://i.ibb.co/JyrmwPp/0051.webp"
    }
  ]


export default listePages;