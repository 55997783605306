const listePages = [
    {
        "page": 1,
        "link": "https://i.ibb.co/9ccbGwZ/0090.webp"
      },
      {
        "page": 2,
        "link": "https://i.ibb.co/wRPymtv/0091.webp"
      },
      {
        "page": 3,
        "link": "https://i.ibb.co/b1rRh9S/0092.webp"
      },
      {
        "page": 4,
        "link": "https://i.ibb.co/GngXLGz/0093.webp"
      },
      {
        "page": 5,
        "link": "https://i.ibb.co/Mfkj7sS/0094.webp"
      },
      {
        "page": 6,
        "link": "https://i.ibb.co/f0qWSXw/0095.webp"
      },
      {
        "page": 7,
        "link": "https://i.ibb.co/PMgyS0h/0096.webp"
      },
      {
        "page": 8,
        "link": "https://i.ibb.co/31K2x57/0097.webp"
      },
      {
        "page": 9,
        "link": "https://i.ibb.co/87r4jyb/0098.webp"
      },
      {
        "page": 10,
        "link": "https://i.ibb.co/9n9jtzc/0099.webp"
      },
      {
        "page": 11,
        "link": "https://i.ibb.co/vjYFmkp/0100.webp"
      },
      {
        "page": 12,
        "link": "https://i.ibb.co/kx1MQP1/0101.webp"
      },
      {
        "page": 13,
        "link": "https://i.ibb.co/g9hFdK0/0102.webp"
      },
      {
        "page": 14,
        "link": "https://i.ibb.co/jyVJWMX/0103.webp"
      },
      {
        "page": 15,
        "link": "https://i.ibb.co/Fg6XwTK/0104.webp"
      },
      {
        "page": 16,
        "link": "https://i.ibb.co/nnvKTks/0105.webp"
      },
      {
        "page": 17,
        "link": "https://i.ibb.co/597p9fF/0106.webp"
      },
      {
        "page": 18,
        "link": "https://i.ibb.co/ZYSkvgF/0107.webp"
      },
      {
        "page": 19,
        "link": "https://i.ibb.co/6bDV341/0108.webp"
      },
      {
        "page": 20,
        "link": "https://i.ibb.co/sm8FwYs/0109.webp"
      },
      {
        "page": 21,
        "link": "https://i.ibb.co/HCZprtK/0110.webp"
      },
      {
        "page": 22,
        "link": "https://i.ibb.co/FHsQhB5/0111.webp"
      },
      {
        "page": 23,
        "link": "https://i.ibb.co/pZc4mC8/0112.webp"
      },
      {
        "page": 24,
        "link": "https://i.ibb.co/5cvj9qz/0113.webp"
      },
      {
        "page": 25,
        "link": "https://i.ibb.co/rM9XJZh/0114.webp"
      },
      {
        "page": 26,
        "link": "https://i.ibb.co/wzgFXYw/0115.webp"
      },
      {
        "page": 27,
        "link": "https://i.ibb.co/gFDsp0M/0116.webp"
      },
      {
        "page": 28,
        "link": "https://i.ibb.co/Hx7Dh4b/0117.webp"
      },
      {
        "page": 29,
        "link": "https://i.ibb.co/m6vCPmT/0118.webp"
      },
      {
        "page": 30,
        "link": "https://i.ibb.co/7prVNJF/0119.webp"
      },
      {
        "page": 31,
        "link": "https://i.ibb.co/xzxYdk1/0120.webp"
      },
      {
        "page": 32,
        "link": "https://i.ibb.co/TM9t8Md/0121.webp"
      },
      {
        "page": 33,
        "link": "https://i.ibb.co/Yk6LwkH/0122.webp"
      },
      {
        "page": 34,
        "link": "https://i.ibb.co/vDnVPK9/0123.webp"
      },
      {
        "page": 35,
        "link": "https://i.ibb.co/bmcFbPh/0124.webp"
      },
      {
        "page": 36,
        "link": "https://i.ibb.co/wrnHswW/0125.webp"
      },
      {
        "page": 37,
        "link": "https://i.ibb.co/PWkJ34G/0126.webp"
      },
      {
        "page": 38,
        "link": "https://i.ibb.co/txQGKcW/0127.webp"
      },
      {
        "page": 39,
        "link": "https://i.ibb.co/XbCsSsp/0128.webp"
      },
      {
        "page": 40,
        "link": "https://i.ibb.co/YfzHysC/0129.webp"
      }
]


export default listePages;