const listeEpisodes = [
    {
        episode: 1,
        link: "https://www.youtube.com/embed/lj3fyNv8qXw"
    },
    {
        episode: 2,
        link: "https://www.youtube.com/embed/AKQeoFjmbW8"
    },
    {
        episode: 3,
        link: "https://www.youtube.com/embed/czdusEXRSyo"
    },
    {
        episode: 4,
        link: "https://www.youtube.com/embed/eiYyOqvHWEg"
    },
    {
        episode: 5,
        link: "https://www.youtube.com/embed/9UeDCXJnO8U"
    },
    {
        episode: 6,
        link: "https://www.youtube.com/embed/hSgGfK2_5BY"
    },
    {
        episode: 7,
        link: "https://www.youtube.com/embed/1hs1eG_UBIY"
    },
    {
        episode: 8,
        link: "https://www.youtube.com/embed/zfFJdGQF8fI"
    },
    {
        episode: 9,
        link: "https://www.youtube.com/embed/67n5xRpJrMc"
    },
    {
        episode: 10,
        link: "https://www.youtube.com/embed/nc477GwhxTQ"
    },
    {
        episode: 11,
        link: "https://www.youtube.com/embed/dEbxBDgL7xw"
    },
    {
        episode: 12,
        link: "https://www.youtube.com/embed/IB_XFzx-FHM"
    },
    {
        episode: 13,
        link: "https://www.youtube.com/embed/xsZmv_mhQQs"
    },
    {
        episode: 14,
        link: "https://www.youtube.com/embed/o5lc3srzyGM"
    },
    {
        episode: 15,
        link: "https://www.youtube.com/embed/zybDzvlkYLE"
    },
    {
        episode: 16,
        link: "https://www.youtube.com/embed/q0BRPADL3p0"
    },
    {
        episode: 17,
        link: "https://www.youtube.com/embed/mgn9EQhhUwM"
    },
    {
        episode: 18,
        link: "https://www.youtube.com/embed/MpmhcfZcl5Q"
    },
    {
        episode: 19,
        link: "https://www.youtube.com/embed/1lxRlu24K2U"
    },
    {
        episode: 20,
        link: "https://www.youtube.com/embed/r5FgQxDhK6k"
    },
    {
        episode: 21,
        link: "https://www.youtube.com/embed/JBbBtvvdFys"
    },
    {
        episode: 22,
        link: "https://www.youtube.com/embed/yzPCAZrltK0"
    },
    {
        episode: 23,
        link: "https://www.youtube.com/embed/0kkQyUakuNA"
    },
    {
        episode: 24,
        link: "https://www.youtube.com/embed/DdVCekDxVU0"
    },
    {
        episode: 25,
        link: "https://www.youtube.com/embed/zDMapnXuJb8"
    },
    {
        episode: 26,
        link: "https://www.youtube.com/embed/KDcvHZv1LB8"
    },
    {
        episode: 27,
        link: "https://www.youtube.com/embed/gWgzuhEorV0"
    },
    {
        episode: 28,
        link: "https://www.youtube.com/embed/EUqmly5Mr5Q"
    },
    {
        episode: 29,
        link: "https://www.youtube.com/embed/dRv1Z68p01Q"
    },
    {
        episode: 30,
        link: "https://www.youtube.com/embed/mTCucRDA6n4"
    },
    {
        episode: 31,
        link: "https://www.youtube.com/embed/mbnIAu2a0ZQ"
    },
    {
        episode: 32,
        link: "https://www.youtube.com/embed/A9Je3Qe28DY"
    },
    {
        episode: 33,
        link: "https://www.youtube.com/embed/RtS8uIG2n6w"
    },
    {
        episode: 34,
        link: "https://www.youtube.com/embed/5c7veXIYHao"
    },
    {
        episode: 35,
        link: "https://www.youtube.com/embed/cubLMRW5NgI"
    },
    {
        episode: 36,
        link: "https://www.youtube.com/embed/zFgY1JhaKhA"
    },
    {
        episode: 37,
        link: "https://www.youtube.com/embed/HZTTQFRL9Hk"
    },
    {
        episode: 38,
        link: "https://www.youtube.com/embed/M2PVIuqrD2w"
    },
    {
        episode: 39,
        link: "https://www.youtube.com/embed/CyzlQGBZv3M"
    },
    {
        episode: 40,
        link: "https://www.youtube.com/embed/zWkcB_GqROo"
    },
    {
        episode: 41,
        link: "https://www.youtube.com/embed/ChPlgexH3mg"
    },
    {
        episode: 42,
        link: "https://www.youtube.com/embed/cJucQ5NhHOU"
    },
    {
        episode: 43,
        link: "https://www.youtube.com/embed/Cr-96CsdGmk"
    },
    {
        episode: 44,
        link: "https://www.youtube.com/embed/AEPopmkGOBE"
    },
    {
        episode: 45,
        link: "https://www.youtube.com/embed/knMSjROk11E"
    },
    {
        episode: 46,
        link: "https://www.youtube.com/embed/mdmE6IPGFuc"
    },
    {
        episode: 47,
        link: "https://www.youtube.com/embed/3fByhgJ9ZyE"
    },
    {
        episode: 48,
        link: "https://www.youtube.com/embed/qzjkuS2FzLI"
    },
    {
        episode: 49,
        link: "https://www.youtube.com/embed/sENqNdHhEUk"
    },
    {
        episode: 50,
        link: "https://www.youtube.com/embed/bT4FxFFBhzU"
    },
    {
        episode: 51,
        link: "https://www.youtube.com/embed/tBOdwMJLSwo"
    }
]

export default listeEpisodes;