const listePages = [
    {
      "page": 1,
      "link": "https://i.ibb.co/zJjmSBS/0052.webp"
    },
    {
      "page": 2,
      "link": "https://i.ibb.co/LzxFKB5/0053.webp"
    },
    {
      "page": 3,
      "link": "https://i.ibb.co/6NY5N2F/0054.webp"
    },
    {
      "page": 4,
      "link": "https://i.ibb.co/whZJ0r8/0055.webp"
    },
    {
      "page": 5,
      "link": "https://i.ibb.co/Zdt2QPV/0056.webp"
    },
    {
      "page": 6,
      "link": "https://i.ibb.co/tZqSQ0r/0057.webp"
    },
    {
      "page": 7,
      "link": "https://i.ibb.co/pfYmMcc/0058.webp"
    },
    {
      "page": 8,
      "link": "https://i.ibb.co/fF5J1GP/0059.webp"
    },
    {
      "page": 9,
      "link": "https://i.ibb.co/dj9T0fd/0060.webp"
    },
    {
      "page": 10,
      "link": "https://i.ibb.co/ZdK30y2/0061.webp"
    },
    {
      "page": 11,
      "link": "https://i.ibb.co/G2XvzBN/0062.webp"
    },
    {
      "page": 12,
      "link": "https://i.ibb.co/VJ7Gb3B/0063.webp"
    },
    {
      "page": 13,
      "link": "https://i.ibb.co/GvwpPY0/0064.webp"
    },
    {
      "page": 14,
      "link": "https://i.ibb.co/MZ5V8Cy/0065.webp"
    },
    {
      "page": 15,
      "link": "https://i.ibb.co/Ns00RSW/0066.webp"
    },
    {
      "page": 16,
      "link": "https://i.ibb.co/QKNXzz9/0067.webp"
    },
    {
      "page": 17,
      "link": "https://i.ibb.co/5kVtvKy/0068.webp"
    },
    {
      "page": 18,
      "link": "https://i.ibb.co/vxrcqWn/0069.webp"
    },
    {
      "page": 19,
      "link": "https://i.ibb.co/WxSmYG6/0070.webp"
    },
    {
      "page": 20,
      "link": "https://i.ibb.co/mJmXgGK/0071.webp"
    },
    {
      "page": 21,
      "link": "https://i.ibb.co/FKM3P0s/0072.webp"
    },
    {
      "page": 22,
      "link": "https://i.ibb.co/xYvzrc7/0073.webp"
    },
    {
      "page": 23,
      "link": "https://i.ibb.co/C0XPjc5/0074.webp"
    },
    {
      "page": 24,
      "link": "https://i.ibb.co/txmTps3/0075.webp"
    },
    {
      "page": 25,
      "link": "https://i.ibb.co/Q7047FN/0076.webp"
    },
    {
      "page": 26,
      "link": "https://i.ibb.co/KqcLDdV/0077.webp"
    },
    {
      "page": 27,
      "link": "https://i.ibb.co/4RgdRT8/0078.webp"
    },
    {
      "page": 28,
      "link": "https://i.ibb.co/sR6DymP/0079.webp"
    },
    {
      "page": 29,
      "link": "https://i.ibb.co/VghJzjF/0080.webp"
    },
    {
      "page": 30,
      "link": "https://i.ibb.co/PgYFmx2/0081.webp"
    },
    {
      "page": 31,
      "link": "https://i.ibb.co/y65VvSf/0082.webp"
    },
    {
      "page": 32,
      "link": "https://i.ibb.co/ZJQq7Bx/0083.webp"
    },
    {
      "page": 33,
      "link": "https://i.ibb.co/TKCPhf0/0084.webp"
    },
    {
      "page": 34,
      "link": "https://i.ibb.co/vkFdm9Z/0085.webp"
    },
    {
      "page": 35,
      "link": "https://i.ibb.co/gJLgXLG/0086.webp"
    },
    {
      "page": 36,
      "link": "https://i.ibb.co/b5L0TSQ/0087.webp"
    },
    {
      "page": 37,
      "link": "https://i.ibb.co/FD2mYfL/0088.webp"
    },
    {
      "page": 38,
      "link": "https://i.ibb.co/6NrgJgm/0089.webp"
    },
    {
      "page": 39,
      "link": "https://i.ibb.co/HzdZ7wR/0090.webp"
    },
    {
      "page": 40,
      "link": "https://i.ibb.co/0jRdK0F/0091.webp"
    },
    {
      "page": 41,
      "link": "https://i.ibb.co/F7d7P6N/0092.webp"
    },
    {
      "page": 42,
      "link": "https://i.ibb.co/CsGcnfB/0093.webp"
    },
    {
      "page": 43,
      "link": "https://i.ibb.co/6gCM0tC/0094.webp"
    },
    {
      "page": 44,
      "link": "https://i.ibb.co/nB82mJk/0095.webp"
    }
  ]


export default listePages;