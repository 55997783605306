import React from 'react';

export default function Footer() {
	return (
        <footer className="Footer">
            <div className='footer-content'>
                <div className='footer-links'>
                    <a href='/legal'>Avertissement Légal</a>
                    <a href='/faq'>Foire aux Questions</a>
                    <a href='https://linktr.ee/beyblade.espace' target="_blank" rel="noreferrer">Contact</a>
                </div>
                <p>© S T R E A M I N G - E S P A C E . F R <br/> 2 0 2 0 - 2 0 2 4</p>
            </div>
        </footer>
    )
}