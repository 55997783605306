const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/JxN7QkZ/0018-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/Fs8fCFN/0018-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/wYFVdZh/0018-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/XyLxHFx/0018-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/52LZc5b/0018-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/4WZ1ZWx/0018-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/KLdqG44/0018-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/wsj9nn2/0018-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/5cct5yM/0018-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/0XTZnvk/0018-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/4fJjkx3/0018-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/92tyV7f/0018-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/M1jhJvh/0018-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/DpbKZc7/0018-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/mtN14qS/0018-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/c6FrVmq/0018-016.webp"
  }
]

export default listePages;