const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/z6c3KWF/34-5-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/TwM2Zjf/34-5-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/mvY6wFq/34-5-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/mNhG8y4/34-5-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/yQZH0Nw/34-5-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/JmM7vvM/34-5-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/JKJ48tD/34-5-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/R9cqcrJ/34-5-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/R9TQmMW/34-5-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/jVLFNc6/34-5-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/3cZbRfw/34-5-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/frt5gCR/34-5-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/RSSYmM1/34-5-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/P5w5vYB/34-5-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/wSBm07w/34-5-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/CV94qPr/34-5-016.webp"
  }
]

export default listePages;