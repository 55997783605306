const listePages = [
    {
      "page": 1,
      "link": "https://i.ibb.co/x2H4t0V/0169.webp"
    },
    {
      "page": 2,
      "link": "https://i.ibb.co/bLrPFCd/0170.webp"
    },
    {
      "page": 3,
      "link": "https://i.ibb.co/LpWFp8y/0171.webp"
    },
    {
      "page": 4,
      "link": "https://i.ibb.co/nM8h3y3/0172.webp"
    },
    {
      "page": 5,
      "link": "https://i.ibb.co/TtVd2Wf/0173.webp"
    },
    {
      "page": 6,
      "link": "https://i.ibb.co/tXG3FM8/0174.webp"
    },
    {
      "page": 7,
      "link": "https://i.ibb.co/YjvtpHf/0175.webp"
    },
    {
      "page": 8,
      "link": "https://i.ibb.co/kBn6vvc/0176.webp"
    },
    {
      "page": 9,
      "link": "https://i.ibb.co/QMLj9mf/0177.webp"
    },
    {
      "page": 10,
      "link": "https://i.ibb.co/rdYYLp8/0178.webp"
    },
    {
      "page": 11,
      "link": "https://i.ibb.co/bmqwkY4/0179.webp"
    },
    {
      "page": 12,
      "link": "https://i.ibb.co/DbdVmx1/0180.webp"
    },
    {
      "page": 13,
      "link": "https://i.ibb.co/JRQ6NTy/0181.webp"
    },
    {
      "page": 14,
      "link": "https://i.ibb.co/0Q0ZyBX/0182.webp"
    },
    {
      "page": 15,
      "link": "https://i.ibb.co/vZLDP8B/0183.webp"
    },
    {
      "page": 16,
      "link": "https://i.ibb.co/1R7Lbmj/0184.webp"
    },
    {
      "page": 17,
      "link": "https://i.ibb.co/8ch4PZt/0185.webp"
    },
    {
      "page": 18,
      "link": "https://i.ibb.co/HhSDHT6/0186.webp"
    },
    {
      "page": 19,
      "link": "https://i.ibb.co/bzhxXVz/0187.webp"
    },
    {
      "page": 20,
      "link": "https://i.ibb.co/dgm0cFM/0188.webp"
    },
    {
      "page": 21,
      "link": "https://i.ibb.co/SB00WYP/0189.webp"
    },
    {
      "page": 22,
      "link": "https://i.ibb.co/G3Bf3WH/0190.webp"
    },
    {
      "page": 23,
      "link": "https://i.ibb.co/G5QFw90/0191.webp"
    },
    {
      "page": 24,
      "link": "https://i.ibb.co/G2yGynp/0192.webp"
    }
  ]


export default listePages;