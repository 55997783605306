const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/FnqJgKG/104-5-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/VHPYCSn/104-5-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/Pc6RNy0/104-5-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/Z8jR2Jg/104-5-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/8rDPL1v/104-5-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/mBQNBjt/104-5-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/tY9R2hJ/104-5-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/p0j6DDH/104-5-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/nr5dMcn/104-5-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/5cY2DNp/104-5-010.webp"
  }
]


export default listePages;