const listePages = [
    {
        "page": 1,
        "link": "https://i.ibb.co/H4HKdbW/0035.webp"
      },
      {
        "page": 2,
        "link": "https://i.ibb.co/52gfSSc/0036.webp"
      },
      {
        "page": 3,
        "link": "https://i.ibb.co/NxJnhzK/0037.webp"
      },
      {
        "page": 4,
        "link": "https://i.ibb.co/DtCvfCJ/0038.webp"
      },
      {
        "page": 5,
        "link": "https://i.ibb.co/SXm9C3w/0039.webp"
      },
      {
        "page": 6,
        "link": "https://i.ibb.co/j45K0K0/0040.webp"
      },
      {
        "page": 7,
        "link": "https://i.ibb.co/M5gJYNT/0041.webp"
      },
      {
        "page": 8,
        "link": "https://i.ibb.co/C167HD8/0042.webp"
      },
      {
        "page": 9,
        "link": "https://i.ibb.co/CmvWfyT/0043.webp"
      },
      {
        "page": 10,
        "link": "https://i.ibb.co/g764zdv/0044.webp"
      },
      {
        "page": 11,
        "link": "https://i.ibb.co/0cCqk9r/0045.webp"
      },
      {
        "page": 12,
        "link": "https://i.ibb.co/JvM7zWM/0046.webp"
      },
      {
        "page": 13,
        "link": "https://i.ibb.co/3yfQ4cq/0047.webp"
      },
      {
        "page": 14,
        "link": "https://i.ibb.co/W33Sh1M/0048.webp"
      },
      {
        "page": 15,
        "link": "https://i.ibb.co/DLBxPch/0049.webp"
      },
      {
        "page": 16,
        "link": "https://i.ibb.co/fGv5SyF/0050.webp"
      },
      {
        "page": 17,
        "link": "https://i.ibb.co/M9xGrkH/0051.webp"
      },
      {
        "page": 18,
        "link": "https://i.ibb.co/j6Cmb26/0052.webp"
      },
      {
        "page": 19,
        "link": "https://i.ibb.co/vJFFH77/0053.webp"
      },
      {
        "page": 20,
        "link": "https://i.ibb.co/n81CP27/0054.webp"
      },
      {
        "page": 21,
        "link": "https://i.ibb.co/KhhVTT6/0055.webp"
      },
      {
        "page": 22,
        "link": "https://i.ibb.co/D7R2Ckm/0056.webp"
      },
      {
        "page": 23,
        "link": "https://i.ibb.co/WKzz4yR/0057.webp"
      },
      {
        "page": 24,
        "link": "https://i.ibb.co/PDS67y6/0058.webp"
      },
      {
        "page": 25,
        "link": "https://i.ibb.co/FmC8Tcw/0059.webp"
      },
      {
        "page": 26,
        "link": "https://i.ibb.co/sg619m4/0060.webp"
      },
      {
        "page": 27,
        "link": "https://i.ibb.co/X48w1xg/0061.webp"
      },
      {
        "page": 28,
        "link": "https://i.ibb.co/tZvnVwF/0062.webp"
      },
      {
        "page": 29,
        "link": "https://i.ibb.co/wdkvpk5/0063.webp"
      },
      {
        "page": 30,
        "link": "https://i.ibb.co/St01tTY/0064.webp"
      },
      {
        "page": 31,
        "link": "https://i.ibb.co/H79H1tP/0065.webp"
      },
      {
        "page": 32,
        "link": "https://i.ibb.co/LYJTJVt/0066.webp"
      },
      {
        "page": 33,
        "link": "https://i.ibb.co/N7vnxpS/0067.webp"
      },
      {
        "page": 34,
        "link": "https://i.ibb.co/Tg66GrP/0068.webp"
      },
      {
        "page": 35,
        "link": "https://i.ibb.co/RTbBQFm/0069.webp"
      },
      {
        "page": 36,
        "link": "https://i.ibb.co/mDPRGp1/0070.webp"
      },
      {
        "page": 37,
        "link": "https://i.ibb.co/RgqBPPt/0071.webp"
      },
      {
        "page": 38,
        "link": "https://i.ibb.co/C0KyYxy/0072.webp"
      },
      {
        "page": 39,
        "link": "https://i.ibb.co/8PXRnG9/0073.webp"
      },
      {
        "page": 40,
        "link": "https://i.ibb.co/QQYsVhs/0074.webp"
      },
      {
        "page": 41,
        "link": "https://i.ibb.co/THztCvF/0075.webp"
      },
      {
        "page": 42,
        "link": "https://i.ibb.co/y6kpspK/0076.webp"
      },
      {
        "page": 43,
        "link": "https://i.ibb.co/QPK4r1b/0077.webp"
      },
      {
        "page": 44,
        "link": "https://i.ibb.co/8xp4jM5/0078.webp"
      },
      {
        "page": 45,
        "link": "https://i.ibb.co/gSf7WqH/0079.webp"
      },
      {
        "page": 46,
        "link": "https://i.ibb.co/42nBSbB/0080.webp"
      },
      {
        "page": 47,
        "link": "https://i.ibb.co/2ZVqqSr/0081.webp"
      },
      {
        "page": 48,
        "link": "https://i.ibb.co/dm2DtMz/0082.webp"
      },
      {
        "page": 49,
        "link": "https://i.ibb.co/r33qZpQ/0083.webp"
      },
      {
        "page": 50,
        "link": "https://i.ibb.co/18sM6j1/0084.webp"
      },
      {
        "page": 51,
        "link": "https://i.ibb.co/8DgFnBZ/0085.webp"
      },
      {
        "page": 52,
        "link": "https://i.ibb.co/khg5Y06/0086.webp"
      },
      {
        "page": 53,
        "link": "https://i.ibb.co/bvtZrvQ/0087.webp"
      },
      {
        "page": 54,
        "link": "https://i.ibb.co/zn0CDq5/0088.webp"
      },
      {
        "page": 55,
        "link": "https://i.ibb.co/ZzYq0mn/0089.webp"
      },
      {
        "page": 56,
        "link": "https://i.ibb.co/Gpj8gnS/0090.webp"
      },
      {
        "page": 57,
        "link": "https://i.ibb.co/JnVbqNh/0091.webp"
      },
      {
        "page": 58,
        "link": "https://i.ibb.co/xX5WHSX/0092.webp"
      },
      {
        "page": 59,
        "link": "https://i.ibb.co/Mk7fkz3/0093.webp"
      },
      {
        "page": 60,
        "link": "https://i.ibb.co/D9kRLDv/0094.webp"
      },
      {
        "page": 61,
        "link": "https://i.ibb.co/LSyG5Xw/0095.webp"
      },
      {
        "page": 62,
        "link": "https://i.ibb.co/7Gw2gTg/0096.webp"
      },
      {
        "page": 63,
        "link": "https://i.ibb.co/6D6kHKT/0097.webp"
      },
      {
        "page": 64,
        "link": "https://i.ibb.co/WP7DL7F/0098.webp"
      },
      {
        "page": 65,
        "link": "https://i.ibb.co/9tbcnNj/0099.webp"
      },
      {
        "page": 66,
        "link": "https://i.ibb.co/CK0Tf4X/0100.webp"
      },
      {
        "page": 67,
        "link": "https://i.ibb.co/19f8mGM/0101.webp"
      },
      {
        "page": 68,
        "link": "https://i.ibb.co/dLvVY4V/0102.webp"
      },
      {
        "page": 69,
        "link": "https://i.ibb.co/hV7JkMH/0103.webp"
      },
      {
        "page": 70,
        "link": "https://i.ibb.co/MBQDy57/0104.webp"
      },
      {
        "page": 71,
        "link": "https://i.ibb.co/BVC3SCt/0105.webp"
      },
      {
        "page": 72,
        "link": "https://i.ibb.co/Pm0s3Xz/0106.webp"
      },
      {
        "page": 73,
        "link": "https://i.ibb.co/9cRQskw/0107.webp"
      },
      {
        "page": 74,
        "link": "https://i.ibb.co/p4qVgkt/0108.webp"
      },
      {
        "page": 75,
        "link": "https://i.ibb.co/gvrLMTW/0109.webp"
      },
      {
        "page": 76,
        "link": "https://i.ibb.co/gVKyDwk/0110.webp"
      },
      {
        "page": 77,
        "link": "https://i.ibb.co/2MTx76C/0111.webp"
      },
      {
        "page": 78,
        "link": "https://i.ibb.co/yXS4y2r/0112.webp"
      },
      {
        "page": 79,
        "link": "https://i.ibb.co/qDfWF05/0113.webp"
      },
      {
        "page": 80,
        "link": "https://i.ibb.co/2j3c5WX/0114.webp"
      },
      {
        "page": 81,
        "link": "https://i.ibb.co/hL17w4s/0115.webp"
      }
]


export default listePages;