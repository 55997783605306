const listeEpisodes = [
    {
        episode: 1,
        link: "https://www.youtube.com/embed/f5reBsbea4E?si=ZjSWoDXiN4BF5MRF"
    },
    {
        episode: 2,
        link: "https://www.youtube.com/embed/tA_bcIPAU0U?si=G3IE94qiOpbzq2jO"
    },
    {
        episode: 3,
        link: "https://www.youtube.com/embed/8fQtK8fEr0k?si=5gxptWA2B15gRCRr"
    },
    {
        episode: 4,
        link: "https://www.youtube.com/embed/lsm_geJCaGw?si=6PGLaDqOGUUXumjQ"
    },
    {
        episode: 5,
        link: "https://www.youtube.com/embed/C0Bm-c6RGVw?si=LkOZl-xvVwoE_w5d"
    },
    {
        episode: 6,
        link: "https://www.youtube.com/embed/v1Rvjf6llHQ?si=PKjcHqc2NzoH5FY9"
    },
    {
        episode: 7,
        link: "https://www.youtube.com/embed/RT4VajjzKhk?si=y8tF67qdszhekLsS"
    },
    {
        episode: 8,
        link: "https://www.youtube.com/embed/72yq_U_6v7s?si=FxCAy4NQit-v4T8m"
    },
    {
        episode: 9,
        link: "https://www.youtube.com/embed/EOB3lyIEcBM?si=--rNTW-lDFjZ_i4O"
    },
    {
        episode: 10,
        link: "https://www.youtube.com/embed/TUCO-ULFB3w?si=clFN2cMS5Tb1iytD"
    },
    {
        episode: 11,
        link: "https://www.youtube.com/embed/OehCnTxk7uo?si=9MlVCKA38GuovMUh"
    },
    {
        episode: 12,
        link: "https://www.youtube.com/embed/j8S3di8sgz0?si=fRTccXMmwXog9Edj"
    },
    {
        episode: 13,
        link: "https://www.youtube.com/embed/AjWdprswAX0?si=y_H0yA9UUX5pUNQN"
    },
    {
        episode: 14,
        link: "https://www.youtube.com/embed/1p7u4VuwFJw?si=nmdym5sPASURrp_s"
    },
    {
        episode: 15,
        link: "https://www.youtube.com/embed/_nmrUW28qEQ?si=gZ0nLPP-F9yfwGkE"
    },
    {
        episode: 16,
        link: "https://www.youtube.com/embed/cQXFrfhKEhs?si=20oeLOvjPRf8NtYV"
    },
    {
        episode: 17,
        link: "https://www.youtube.com/embed/XJaR0gUIgG4?si=FbBvoSHHbJd5nrcH"
    },
    {
        episode: 18,
        link: "https://www.youtube.com/embed/qimsgA1ChZw?si=PZFuh5k4gVrX9E16"
    },
    {
        episode: 19,
        link: "https://www.youtube.com/embed/IgA5diy9ym8?si=Gmykv5H70wM9WYgS"
    },
    {
        episode: 20,
        link: "https://www.youtube.com/embed/4Xkb5Ey_ouk?si=XgbGmE2deGWHZrZw"
    },
    {
        episode: 21,
        link: "https://www.youtube.com/embed/IDp5jjvnI2Y?si=bqRAMfGouKDwiofX"
    },
    {
        episode: 22,
        link: "https://www.youtube.com/embed/kXLrqaLNzXA?si=ac3Q6YUPMoUSgwum"
    },
    {
        episode: 23,
        link: "https://www.youtube.com/embed/9jE-6t-tKQo?si=1-2a7tW-K7fWH2Ri"
    },
    {
        episode: 24,
        link: "https://www.youtube.com/embed/v2f7OBXZkZk?si=m5lZNBoYG-HUVivL"
    },
    {
        episode: 25,
        link: "https://www.youtube.com/embed/zDWytX3jAKo?si=_jDhpt211L-DxO2y"
    },
    {
        episode: 26,
        link: "https://www.youtube.com/embed/z4fHqlPn5GQ?si=9HWuNZdrSB8dX2-t"
    },
    {
        episode: 27,
        link: "https://www.youtube.com/embed/4oN4_PLs0fE?si=puYJERDP4yBmsKXD"
    },
    {
        episode: 28,
        link: "https://www.youtube.com/embed/kzzQc4vBwI0?si=UEdcJEXPHSefJBn3"
    },
    {
        episode: 29,
        link: "https://www.youtube.com/embed/KCJWMyBXZmQ?si=jgVPR4gyaaPwWpIV"
    },
    {
        episode: 30,
        link: "https://www.youtube.com/embed/8XhwiSpYv1A?si=WHrJEtiyV6lhZYGy"
    },
    {
        episode: 31,
        link: "https://www.youtube.com/embed/Glsei0KFpxE?si=aPvInoos4n_YGg0t"
    },
    {
        episode: 32,
        link: "https://www.youtube.com/embed/Il_Hyy017IQ?si=fPYOiudh8Ib1KWrE"
    },
    {
        episode: 33,
        link: "https://www.youtube.com/embed/v4qlI9a6tHU?si=44K_SQ5Yl0zLwZF_"
    },
    {
        episode: 34,
        link: "https://www.youtube.com/embed/JKoXInjRxN4?si=DoxfndSJ9T0qkXkZ"
    },
    {
        episode: 35,
        link: "https://www.youtube.com/embed/VmBJiVpe8mI?si=rEQLL4K-vIVFf1xA"
    },
    {
        episode: 36,
        link: "https://www.youtube.com/embed/5IrQIU4DYw8?si=N8sbQ1Fb9kz3wUgj"
    },
    {
        episode: 37,
        link: "https://www.youtube.com/embed/aru837iXjjc?si=XlyXb5kjkArgFvo6"
    },
    {
        episode: 38,
        link: "https://www.youtube.com/embed/TFlwihmNCOA?si=y8YWcLyvyhUQEQRC"
    },
    {
        episode: 39,
        link: "https://www.youtube.com/embed/mbLa0W8Mvw0?si=TRULEjfXk9SdLEAk"
    },
    {
        episode: 40,
        link: "https://www.youtube.com/embed/KnNFACaFHM4?si=ZDtOKSGHQFq3mgbq"
    },
    {
        episode: 41,
        link: "https://www.youtube.com/embed/kivUWtu8C88?si=gxDIANMWcioQQqa5"
    },
    {
        episode: 42,
        link: "https://www.youtube.com/embed/lgXvmLVWQwU?si=ksWqADRi5aKbr0S8"
    },
    {
        episode: 43,
        link: "https://www.youtube.com/embed/Q0LO2gMlxfU?si=X94-a7jIBkJ-X_Uj"
    },
    {
        episode: 44,
        link: "https://www.youtube.com/embed/Q4FWYe8aC2E?si=dt0OMNjhyF8jMTAg"
    },
    {
        episode: 45,
        link: "https://www.youtube.com/embed/p0Er7Sjx3uY?si=ceJ9GegkizhsNjcZ"
    },
    {
        episode: 46,
        link: "https://www.youtube.com/embed/uE75Lpowe8k?si=JAxpFZLPIRXZYRRO"
    },
    {
        episode: 47,
        link: "https://www.youtube.com/embed/pGWOedgcuao?si=Cb_vnvvMlyM8OONj"
    },
    {
        episode: 48,
        link: "https://www.youtube.com/embed/jugNczDf7dY?si=41SFr9kQtfAEP1C0"
    },
    {
        episode: 49,
        link: "https://www.youtube.com/embed/jLVa0DfxsY4?si=0Cbt_977S9wIR4Si"
    },
    {
        episode: 50,
        link: "https://www.youtube.com/embed/gnn1e68D9cs?si=fg5yXVPPbDNb0T-3"
    },
    {
        episode: 51,
        link: "https://www.youtube.com/embed/sDQjDdpvja8?si=yjjLzQjW95CQDxv3"
    }
]

export default listeEpisodes;