const listePages = [
    { page: 1, link: "https://i.ibb.co/D1CndcG/0085-001.webp" },
    { page: 2, link: "https://i.ibb.co/3rpkhMD/0085-002.webp" },
    { page: 3, link: "https://i.ibb.co/jhHyYrs/0085-003.webp" },
    { page: 4, link: "https://i.ibb.co/j8gLTx8/0085-004.webp" },
    { page: 5, link: "https://i.ibb.co/4WszFXX/0085-005.webp" },
    { page: 6, link: "https://i.ibb.co/Tht5G9G/0085-006.webp" },
    { page: 7, link: "https://i.ibb.co/zFN3F9d/0085-007.webp" },
    { page: 8, link: "https://i.ibb.co/tXX0g5v/0085-008.webp" },
    { page: 9, link: "https://i.ibb.co/dbGkHqV/0085-009.webp" },
    { page: 10, link: "https://i.ibb.co/vQnGBWt/0085-010.webp" },
    { page: 11, link: "https://i.ibb.co/qMHQPcv/0085-011.webp" },
    { page: 12, link: "https://i.ibb.co/JjDBCqf/0085-012.webp" },
    { page: 13, link: "https://i.ibb.co/hV6d8Zb/0085-013.webp" },
    { page: 14, link: "https://i.ibb.co/KsJdzBR/0085-014.webp" },
    { page: 15, link: "https://i.ibb.co/nCfvBCH/0085-015.webp" },
    { page: 16, link: "https://i.ibb.co/DkBD4dj/0085-016.webp" },
    { page: 17, link: "https://i.ibb.co/0XV59VM/0085-017.webp" },
    { page: 18, link: "https://i.ibb.co/tQYb1ph/0085-018.webp" },
    { page: 19, link: "https://i.ibb.co/jV2snJj/0085-019.webp" },
    { page: 20, link: "https://i.ibb.co/Vwv2BRx/0085-020.webp" },
    { page: 21, link: "https://i.ibb.co/WHyX5s4/0085-021.webp" },
    { page: 22, link: "https://i.ibb.co/cTkWRWn/0085-022.webp" },
    { page: 23, link: "https://i.ibb.co/Ntwc1yc/0085-023.webp" },
    { page: 24, link: "https://i.ibb.co/Z6Kxnpk/0085-024.webp" },
    { page: 25, link: "https://i.ibb.co/PZPMhDh/0085-025.webp" },
    { page: 26, link: "https://i.ibb.co/dQqK7j9/0085-026.webp" }
  ]
  

export default listePages;