const listePages = [
  { page: 1, link: "https://i.ibb.co/7b6jkTG/0063-001.webp" },
  { page: 2, link: "https://i.ibb.co/pP9qmnP/0063-002.webp" },
  { page: 3, link: "https://i.ibb.co/jR4zdDC/0063-003.webp" },
  { page: 4, link: "https://i.ibb.co/6HwLNd7/0063-004.webp" },
  { page: 5, link: "https://i.ibb.co/bdmm1Nz/0063-005.webp" },
  { page: 6, link: "https://i.ibb.co/Hdb15hN/0063-006.webp" },
  { page: 7, link: "https://i.ibb.co/JFtfXtg/0063-007.webp" },
  { page: 8, link: "https://i.ibb.co/K919bhY/0063-008.webp" },
  { page: 9, link: "https://i.ibb.co/V9m6N6c/0063-009.webp" },
  { page: 10, link: "https://i.ibb.co/q1rD6J6/0063-010.webp" },
  { page: 11, link: "https://i.ibb.co/GJhCfMx/0063-011.webp" },
  { page: 12, link: "https://i.ibb.co/tZBTjmM/0063-012.webp" },
  { page: 13, link: "https://i.ibb.co/Cztmsd5/0063-013.webp" },
  { page: 14, link: "https://i.ibb.co/myLmmBb/0063-014.webp" },
  { page: 15, link: "https://i.ibb.co/FwRH7vz/0063-015.webp" },
  { page: 16, link: "https://i.ibb.co/7yHh2Z5/0063-016.webp" }
]


export default listePages;