const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/mc44ttN/0004-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/smdJvTf/0004-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/HnxDWMq/0004-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/6vD7YsQ/0004-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/PzQ4NKP/0004-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/bHmt99c/0004-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/M95NTXw/0004-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/T2qNqhb/0004-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/6DsHYX5/0004-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/B6N1KNw/0004-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/FW4DjmN/0004-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/pbgjrVP/0004-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/BzTgw0q/0004-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/0y896J1/0004-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/fCtG0fg/0004-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/HH87Jd6/0004-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/qJTJ0p9/0004-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/JygMchM/0004-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/KLQj4Xp/0004-019.webp"
  },
  {
    page: 20,
    link: "https://i.ibb.co/T0VVkpg/0004-020.webp"
  },
  {
    page: 21,
    link: "https://i.ibb.co/PYsp8GZ/0004-021.webp"
  },
  {
    page: 22,
    link: "https://i.ibb.co/drQXVj2/0004-022.webp"
  },
  {
    page: 23,
    link: "https://i.ibb.co/TcQcPS5/0004-023.webp"
  },
  {
    page: 24,
    link: "https://i.ibb.co/vxXKhwN/0004-024.webp"
  },
  {
    page: 25,
    link: "https://i.ibb.co/dG65BM1/0004-025.webp"
  },
  {
    page: 26,
    link: "https://i.ibb.co/vjVww7H/0004-026.webp"
  },
  {
    page: 27,
    link: "https://i.ibb.co/rG3MR1W/0004-027.webp"
  },
  {
    page: 28,
    link: "https://i.ibb.co/N9hWZ4Q/0004-028.webp"
  },
  {
    page: 29,
    link: "https://i.ibb.co/7kyV5Jt/0004-029.webp"
  },
  {
    page: 30,
    link: "https://i.ibb.co/F83LP8y/0004-030.webp"
  },
  {
    page: 31,
    link: "https://i.ibb.co/R3q2CTJ/0004-031.webp"
  },
  {
    page: 32,
    link: "https://i.ibb.co/5L3Sqct/0004-032.webp"
  },
  {
    page: 33,
    link: "https://i.ibb.co/jGxN1Dz/0004-033.webp"
  },
  {
    page: 34,
    link: "https://i.ibb.co/nkb6fYC/0004-034.webp"
  },
  {
    page: 35,
    link: "https://i.ibb.co/0yrDnvk/0004-035.webp"
  },
  {
    page: 36,
    link: "https://i.ibb.co/8Nhf4J9/0004-036.webp"
  },
  {
    page: 37,
    link: "https://i.ibb.co/0K19jt2/0004-037.webp"
  },
  {
    page: 38,
    link: "https://i.ibb.co/1vFR55P/0004-038.webp"
  }
]


export default listePages;