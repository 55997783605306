const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/8mTfjSm/0030-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/cy86745/0030-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/XjwjwFG/0030-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/CJTb9jd/0030-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/brfhkJk/0030-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/N2jGynX/0030-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/qWy5Rgs/0030-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/vXCQFdd/0030-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/5Tqb6h3/0030-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/g7J8GMk/0030-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/4JwdFc5/0030-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/pZKspBD/0030-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/nR1dfPj/0030-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/WK51FcB/0030-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/TT8Shbj/0030-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/ZNY0PpR/0030-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/kBjBGsH/0030-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/tDZL26J/0030-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/gvYzrx8/0030-019.webp"
  },
  {
    page: 20,
    link: "https://i.ibb.co/NtQH47M/0030-020.webp"
  },
  {
    page: 21,
    link: "https://i.ibb.co/9gYdPPz/0030-021.webp"
  },
  {
    page: 22,
    link: "https://i.ibb.co/9pMF8wm/0030-022.webp"
  },
  {
    page: 23,
    link: "https://i.ibb.co/b2VzW4G/0030-023.webp"
  },
  {
    page: 24,
    link: "https://i.ibb.co/xMqQSr7/0030-024.webp"
  },
  {
    page: 25,
    link: "https://i.ibb.co/PrPgQMk/0030-025.webp"
  },
  {
    page: 26,
    link: "https://i.ibb.co/0JszPcv/0030-026.webp"
  },
  {
    page: 27,
    link: "https://i.ibb.co/2yBDPBC/0030-027.webp"
  },
  {
    page: 28,
    link: "https://i.ibb.co/vYc0gPf/0030-028.webp"
  },
  {
    page: 29,
    link: "https://i.ibb.co/8KPZdfN/0030-029.webp"
  },
  {
    page: 30,
    link: "https://i.ibb.co/ct0MNcz/0030-030.webp"
  },
  {
    page: 31,
    link: "https://i.ibb.co/qN67Rbj/0030-031.webp"
  },
  {
    page: 32,
    link: "https://i.ibb.co/rvZcVvw/0030-032.webp"
  },
  {
    page: 33,
    link: "https://i.ibb.co/9HGzK8y/0030-033.webp"
  },
  {
    page: 34,
    link: "https://i.ibb.co/qCkQ71J/0030-034.webp"
  },
  {
    page: 35,
    link: "https://i.ibb.co/zQV8tGB/0030-035.webp"
  },
  {
    page: 36,
    link: "https://i.ibb.co/Lv72HY3/0030-036.webp"
  },
  {
    page: 37,
    link: "https://i.ibb.co/zxZJkpP/0030-037.webp"
  },
  {
    page: 38,
    link: "https://i.ibb.co/xqtYQ07/0030-038.webp"
  }  
]

export default listePages;