const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/TTSkQZC/0028-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/Js8Tttp/0028-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/vH1Cb9n/0028-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/PtP2t3Y/0028-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/HYywHxz/0028-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/6r3xGr4/0028-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/7t0Zd8D/0028-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/3R2M5z7/0028-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/qmgz7Qj/0028-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/5stvBJb/0028-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/W2jg5GB/0028-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/6817494/0028-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/YtMVzyD/0028-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/hXscdkn/0028-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/303L7w5/0028-015.webp"
  }
]

export default listePages;