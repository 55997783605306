const listePages = [
    { page: 1, link: "https://i.ibb.co/TkW352L/0079-001.webp" },
    { page: 2, link: "https://i.ibb.co/wQ5GXH6/0079-002.webp" },
    { page: 3, link: "https://i.ibb.co/KKFLwsH/0079-003.webp" },
    { page: 4, link: "https://i.ibb.co/FD5RBgz/0079-004.webp" },
    { page: 5, link: "https://i.ibb.co/8BXMd7w/0079-005.webp" },
    { page: 6, link: "https://i.ibb.co/NpZNJkG/0079-006.webp" },
    { page: 7, link: "https://i.ibb.co/dGVSSQ7/0079-007.webp" },
    { page: 8, link: "https://i.ibb.co/HnKY9hG/0079-008.webp" },
    { page: 9, link: "https://i.ibb.co/NF7Z173/0079-009.webp" },
    { page: 10, link: "https://i.ibb.co/PCsTgkm/0079-010.webp" },
    { page: 11, link: "https://i.ibb.co/wNPf6B2/0079-011.webp" },
    { page: 12, link: "https://i.ibb.co/cy2FC7J/0079-012.webp" },
    { page: 13, link: "https://i.ibb.co/s6Mb8py/0079-013.webp" },
    { page: 14, link: "https://i.ibb.co/JmY9rQp/0079-014.webp" },
    { page: 15, link: "https://i.ibb.co/0FWksTv/0079-015.webp" },
    { page: 16, link: "https://i.ibb.co/f2np7Qd/0079-016.webp" },
    { page: 17, link: "https://i.ibb.co/LPy657S/0079-017.webp" },
    { page: 18, link: "https://i.ibb.co/WkxYp4g/0079-018.webp" },
    { page: 19, link: "https://i.ibb.co/jLhjznQ/0079-019.webp" },
    { page: 20, link: "https://i.ibb.co/jZVBLYK/0079-020.webp" },
    { page: 21, link: "https://i.ibb.co/6wcMnTL/0079-021.webp" }
  ]
  

export default listePages;