const listePages = [
    {
        page: 1,
        link: "https://i.ibb.co/m5cj1xG/0001.webp"
      },
      {
        page: 2,
        link: "https://i.ibb.co/LRbhmbb/0005.webp"
      },
      {
        page: 3,
        link: "https://i.ibb.co/S3V56Ys/0006.webp"
      },
      {
        page: 4,
        link: "https://i.ibb.co/TPwTHy6/0007.webp"
      },
      {
        page: 5,
        link: "https://i.ibb.co/yFN8dWn/0008.webp"
      },
      {
        page: 6,
        link: "https://i.ibb.co/z26XwKg/0009.webp"
      },
      {
        page: 7,
        link: "https://i.ibb.co/qFWg2Bh/0010.webp"
      },
      {
        page: 8,
        link: "https://i.ibb.co/n33mTJS/0011.webp"
      },
      {
        page: 9,
        link: "https://i.ibb.co/MpQV4p7/0012.webp"
      },
      {
        page: 10,
        link: "https://i.ibb.co/4sJwT8V/0013.webp"
      },
      {
        page: 11,
        link: "https://i.ibb.co/sKh9JSy/0014.webp"
      },
      {
        page: 12,
        link: "https://i.ibb.co/B2ncrmj/0015.webp"
      },
      {
        page: 13,
        link: "https://i.ibb.co/7v912yz/0016.webp"
      },
      {
        page: 14,
        link: "https://i.ibb.co/RDk6R3P/0017.webp"
      },
      {
        page: 15,
        link: "https://i.ibb.co/D1pqghJ/0018.webp"
      },
      {
        page: 16,
        link: "https://i.ibb.co/YTR3LBB/0019.webp"
      },
      {
        page: 17,
        link: "https://i.ibb.co/6ZNTmgg/0020.webp"
      },
      {
        page: 18,
        link: "https://i.ibb.co/SxSspGx/0021.webp"
      },
      {
        page: 19,
        link: "https://i.ibb.co/yW41ZP7/0022.webp"
      },
      {
        page: 20,
        link: "https://i.ibb.co/mbF1fND/0023.webp"
      },
      {
        page: 21,
        link: "https://i.ibb.co/FWLQwpC/0024.webp"
      },
      {
        page: 22,
        link: "https://i.ibb.co/YdmnbjH/0025.webp"
      },
      {
        page: 23,
        link: "https://i.ibb.co/7SYS5yJ/0026.webp"
      },
      {
        page: 24,
        link: "https://i.ibb.co/5rTnht7/0027.webp"
      },
      {
        page: 25,
        link: "https://i.ibb.co/nLjQk3D/0028.webp"
      },
      {
        page: 26,
        link: "https://i.ibb.co/wQkFX7D/0029.webp"
      },
      {
        page: 27,
        link: "https://i.ibb.co/dbpW6kr/0030.webp"
      },
      {
        page: 28,
        link: "https://i.ibb.co/qk9P24w/0031.webp"
      },
      {
        page: 29,
        link: "https://i.ibb.co/B6Ffzwv/0032.webp"
      },
      {
        page: 30,
        link: "https://i.ibb.co/smrptNj/0033.webp"
      },
      {
        page: 31,
        link: "https://i.ibb.co/d0yh6F2/0034.webp"
      }
]


export default listePages;