const listeSaisons = [
    {
        id : 1,
        titre : "Bakuten Shoot Beyblade",
        saison : "Saison 1",
        url : "saison1",
        img : "https://i.ibb.co/KskZBb6/3-UOE3xx-U-1.webp",
        synopsis : "Takao Kinomiya est un jeune garçon qui est passionné par le Beyblade. Comme tous les jeunes ados de son âge, il détient une Beyblade avec laquelle il s'entraine tous les jours. Rapidement, Takao améliore son niveau et affronte les meilleurs Bladers.",
        vostfr : true,
        vostfrlink : "/bakuten/saison1/vostfr",
        vf : true,
        vflink : "/bakuten/saison1/vf",
        needvf : true
    },
    {
        id : 2,
        titre : "Bakuten Shoot Beyblade V-Force",
        saison : "Saison 2",
        url : "saison2",
        img : "https://i.ibb.co/hdtYq9q/4-adzovce.webp",
        synopsis : "Les Bladebreakers doivent unir leurs forces une fois de plus, car un groupe mystérieux sous la direction de Oozuma les a vaincu, mais le véritable ennemi n'est pas Oozuma. La véritable menace provient d'un groupe de personnes qui utilisent les Cyber-BitBeasts pour capturer les originaux des Bladebreakers.",
        vostfr : true,
        vostfrlink : "/bakuten/saison2/vostfr",
        vf : false,
        vflink : "/bakuten/saison2/vf",
        needvf : true
    },
    {
        id : 3,
        titre : "Bakuten Shoot Beyblade G-Revolution",
        saison : "Saison 3",
        url : "saison3",
        img : "https://i.ibb.co/jRv1Z4s/5-6aa4-OSI.webp",
        synopsis : "Un nouveau championnat mondial est annoncé au cours duquel les joueurs s'affrontent au moyen de Beyblades. Takao sera accompagné de Daichi son coéquipier, d'Hiromi, et de Kyôju l'intello qui l'aide pour ses matchs.",
        vostfr : true,
        vostfrlink : "/bakuten/saison3/vostfr",
        vf : false,
        vflink : "/bakuten/saison3/vf",
        needvf : true
    },
    {
        id : 4,
        titre : "Bakuten Shoot Beyblade Takao VS Daichi",
        saison : "Film",
        url : "film",
        img : "https://i.ibb.co/Cbc1K1T/1-NO6uxrp.webp",
        synopsis : "Alors que Takao vient de remporter le championnat du monde, un jeune garçon du nom de Daichi vient le défier. Pendant ce temps sur une île, un groupe d'enfants trouve d'étranges inscriptions dans une grotte et réveillent les esprits qui y étaient endormis, ces derniers prennent possession des enfants.",
        vostfr : true,
        vostfrlink : "/bakuten/film/vostfr"
    }
]

export default listeSaisons