const listePages = [
    {
      "page": 1,
      "link": "https://i.ibb.co/2YsmpvW/0084.webp"
    },
    {
      "page": 2,
      "link": "https://i.ibb.co/fFLf9Y8/0085.webp"
    },
    {
      "page": 3,
      "link": "https://i.ibb.co/LScx1my/0086.webp"
    },
    {
      "page": 4,
      "link": "https://i.ibb.co/1mBsmbh/0087.webp"
    },
    {
      "page": 5,
      "link": "https://i.ibb.co/kcZkphC/0088.webp"
    },
    {
      "page": 6,
      "link": "https://i.ibb.co/0ttry2c/0089.webp"
    },
    {
      "page": 7,
      "link": "https://i.ibb.co/qrpSMt8/0090.webp"
    },
    {
      "page": 8,
      "link": "https://i.ibb.co/qMDwRSv/0091.webp"
    },
    {
      "page": 9,
      "link": "https://i.ibb.co/SNzNpk0/0092.webp"
    },
    {
      "page": 10,
      "link": "https://i.ibb.co/QbF67GG/0093.webp"
    },
    {
      "page": 11,
      "link": "https://i.ibb.co/t3Ly7Rh/0094.webp"
    },
    {
      "page": 12,
      "link": "https://i.ibb.co/ByDQMXg/0095.webp"
    },
    {
      "page": 13,
      "link": "https://i.ibb.co/HYn5XrX/0096.webp"
    },
    {
      "page": 14,
      "link": "https://i.ibb.co/b2xGQ0F/0097.webp"
    },
    {
      "page": 15,
      "link": "https://i.ibb.co/ccPf0Rv/0098.webp"
    },
    {
      "page": 16,
      "link": "https://i.ibb.co/6Z9cQ6t/0099.webp"
    },
    {
      "page": 17,
      "link": "https://i.ibb.co/mvx2XP0/0100.webp"
    },
    {
      "page": 18,
      "link": "https://i.ibb.co/wybWHQv/0101.webp"
    },
    {
      "page": 19,
      "link": "https://i.ibb.co/nP1MSDD/0102.webp"
    },
    {
      "page": 20,
      "link": "https://i.ibb.co/55pXrSH/0103.webp"
    },
    {
      "page": 21,
      "link": "https://i.ibb.co/D4k79vB/0104.webp"
    },
    {
      "page": 22,
      "link": "https://i.ibb.co/TMsYJ9N/0105.webp"
    },
    {
      "page": 23,
      "link": "https://i.ibb.co/31rfKLR/0106.webp"
    },
    {
      "page": 24,
      "link": "https://i.ibb.co/Tt4bKHj/0107.webp"
    },
    {
      "page": 25,
      "link": "https://i.ibb.co/bzqyZYX/0108.webp"
    },
    {
      "page": 26,
      "link": "https://i.ibb.co/tYZ3S4q/0109.webp"
    },
    {
      "page": 27,
      "link": "https://i.ibb.co/2KrXKHQ/0110.webp"
    },
    {
      "page": 28,
      "link": "https://i.ibb.co/DMMD9ZW/0111.webp"
    },
    {
      "page": 29,
      "link": "https://i.ibb.co/RDg4RLw/0112.webp"
    },
    {
      "page": 30,
      "link": "https://i.ibb.co/zsy4fhf/0113.webp"
    },
    {
      "page": 31,
      "link": "https://i.ibb.co/4fj8rdL/0114.webp"
    },
    {
      "page": 32,
      "link": "https://i.ibb.co/6nk8NN9/0115.webp"
    },
    {
      "page": 33,
      "link": "https://i.ibb.co/pzTJWh4/0116.webp"
    },
    {
      "page": 34,
      "link": "https://i.ibb.co/ygkmFs3/0117.webp"
    },
    {
      "page": 35,
      "link": "https://i.ibb.co/wgLghvP/0118.webp"
    },
    {
      "page": 36,
      "link": "https://i.ibb.co/0X1LtX2/0119.webp"
    },
    {
      "page": 37,
      "link": "https://i.ibb.co/kK7BbSj/0120.webp"
    },
    {
      "page": 38,
      "link": "https://i.ibb.co/0CDkQLN/0121.webp"
    },
    {
      "page": 39,
      "link": "https://i.ibb.co/y4K48Yk/0122.webp"
    },
    {
      "page": 40,
      "link": "https://i.ibb.co/1XkGR98/0123.webp"
    },
    {
      "page": 41,
      "link": "https://i.ibb.co/Sd7BBKW/0124.webp"
    },
    {
      "page": 42,
      "link": "https://i.ibb.co/Yf8RRx9/0125.webp"
    }
  ]


export default listePages;