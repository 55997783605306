const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/mX56QFk/0000-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/PNt0mrC/0000-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/CW5nXd3/0000-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/cgPcMmd/0000-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/Tgh4Cd9/0000-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/L5z68px/0000-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/CbQSFkX/0000-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/r6VpPp6/0000-008.webp"
  }
]

export default listePages;