const listeSaisons = [
    {
        id : 1,
        titre : "Beyblade Burst",
        saison : "Saison 1",
        url : "saison1",
        img : "https://i.ibb.co/QjkkM0c/6-2grrkvl.webp",
        synopsis : "Valt Aoi est un grand fan de Beyblade depuis son jeune âge, aux côtés de son meilleur ami Shu Kurenai, qui est un prodige, faisant même partie des quatre meilleurs bladers du Japon. Valt, avec sa toupie,Victory Valkyrie, devra faire face à de nombreuses épreuves.",
        vostfr : true,
        vostfrlink : "/burst/saison1/vostfr",
        vf : true,
        vflink : "/burst/saison1/vf"
    },
    {
        id : 2,
        titre : "Beyblade Burst God/Evolution",
        saison : "Saison 2",
        url : "saison2",
        img : "https://i.ibb.co/3rr7CVc/7-40ozbd7.webp",
        synopsis : "Après sa participation au tournoi du Japon, Valt est recruté par la prestigieuse BC Sol, une équipe espagnole qui ambitionne de gagner le tournoi mondial ! Mais pour pouvoir entrer dans cette compétition, Valt et ses nouveaux coéquipiers devront gagner le tournoi européen.",
        vostfr : true,
        vostfrlink : "/burst/saison2/vostfr",
        vf : true,
        vflink : "/burst/saison2/vf"
    },
    {
        id : 3,
        titre : "Beyblade Burst ChoZ/Turbo",
        saison : "Saison 3",
        url : "saison3",
        img : "https://i.ibb.co/N1GLHqC/8-x1bzcns.webp",
        synopsis : "Valt est devenu champion du monde. Dans les bois, il rencontre Aiga Akabane, un Blader pas comme les autres. Il construit lui même sa Bey, Z Achilles. Aiga perd son premier combat face à Valt, qui a construit sa nouvelle Bey, Wining Valkyrie avec l'aide du père d'Aiga.",
        vostfr : true,
        vostfrlink : "/burst/saison3/vostfr",
        vf : true,
        vflink : "/burst/saison3/vf"
    },
    {
        id : 4,
        titre : "Beyblade Burst Gachi/Rise",
        saison : "Saison 4",
        url : "saison4",
        img : "https://i.ibb.co/qmKFJ6T/9-spy6lii.webp",
        synopsis : "Valt cherche de nouveaux membres pour BC Sol. Il montre à Drum sa nouvelle toupie Slash Valkyrie, une Bey Gatchi. Drum part au Japon pour s'approprier ce nouveau système. Il rentre dans le club Beyblade tenu par son oncle.",
        vostfr : true,
        vostfrlink : "/burst/saison4/vostfr",
        vf : true,
        vflink : "/burst/saison4/vf"
    },
    {
        id : 5,
        titre : "Beyblade Burst SuperKing/Surge",
        saison : "Saison 5",
        url : "saison5",
        img : "https://i.ibb.co/Bn9S5Jm/10-l77eeqb.webp",
        synopsis : "Lors d'un match entre Valt, Rantaro et Sisco, nos héros Hyuga et Hikaru décident de faire leurs propres Beys SuperKing. Ils se préparent alors pour un tournoi réunissant des légendes vivantes du Beyblade afin de les affronter.",
        vostfr : true,
        vostfrlink : "/burst/saison5/vostfr",
        vf : true,
        vflink : "/burst/saison5/vf"
    },
    {
        id : 6,
        titre : "Beyblade Burst DynamiteBattle/QuadDrive",
        saison : "Saison 6",
        url : "saison6",
        img : "https://i.ibb.co/x1sJQfj/11-2yec7gu.webp",
        synopsis : "L'histoire est centrée sur Bell Daikokuten, le chef du cimetière de Bey Makai no Mon. Bell, qui joue avec Dynamite Belial, déclare la guerre aux Bladers du monde entiers.",
        vostfr : true,
        vostfrlink : "/burst/saison6/vostfr",
        vf : true,
        vflink : "/burst/saison6/vf"
    },
    {
        id : 7,
        titre : "Beyblade Burst QuadStrike",
        saison : "Saison 7",
        url : "saison7",
        img : "https://i.ibb.co/BC6ZKsS/Any-Conv-com-k-Pog-Qh-M-Imgur.webp",
        synopsis : "Brandissant sa Lightning Pandora, Quadra vainc tous les ennemis qui se présentent à elle. Pendant ce temps, le frère de Quadra, Pax, est occupé à étudier les pouvoirs élémentaires.",
        va : true,
        valink : "/burst/saison7/va",
        vf : true,
        vflink : "/burst/saison7/vf"
    },
    {
        id : 8,
        titre : "Beyblade Burst",
        saison : "Scans",
        url : "scans",
        img : "https://i.ibb.co/f181kPV/Beyblade-Burst-T01.webp",
        synopsis : "Valt Aoi est un grand fan de Beyblade depuis son jeune âge, aux côtés de son meilleur ami Shu Kurenai, qui est un prodige, faisant même partie des quatre meilleurs bladers du Japon.",
        vf : true,
        vflink : "/burst/scans/",
        scan : true
    }
]

export default listeSaisons