const listePages = [
      {
        page: 1,
        link: "https://i.ibb.co/F4w9Np0/0169.webp"
      },
      {
        page: 2,
        link: "https://i.ibb.co/XXhrLHQ/0170.webp"
      },
      {
        page: 3,
        link: "https://i.ibb.co/r5BVbX3/0171.webp"
      },
      {
        page: 4,
        link: "https://i.ibb.co/F4Whmmv/0172.webp"
      },
      {
        page: 5,
        link: "https://i.ibb.co/DMkgJ8J/0173.webp"
      },
      {
        page: 6,
        link: "https://i.ibb.co/549rhm8/0174.webp"
      },
      {
        page: 7,
        link: "https://i.ibb.co/9tr37cv/0175.webp"
      },
      {
        page: 8,
        link: "https://i.ibb.co/Vx0pTjT/0176.webp"
      },
      {
        page: 9,
        link: "https://i.ibb.co/hcj09qp/0177.webp"
      },
      {
        page: 10,
        link: "https://i.ibb.co/1MwsBf2/0178.webp"
      },
      {
        page: 11,
        link: "https://i.ibb.co/M7WnKQv/0179.webp"
      },
      {
        page: 12,
        link: "https://i.ibb.co/N2PrYwD/0180.webp"
      },
      {
        page: 13,
        link: "https://i.ibb.co/stfWQP9/0181.webp"
      },
      {
        page: 14,
        link: "https://i.ibb.co/ckFVNqp/0182.webp"
      },
      {
        page: 15,
        link: "https://i.ibb.co/5cFVWdN/0183.webp"
      },
      {
        page: 16,
        link: "https://i.ibb.co/rGtSSc8/0184.webp"
      },
      {
        page: 17,
        link: "https://i.ibb.co/t8f6LSQ/0185.webp"
      },
      {
        page: 18,
        link: "https://i.ibb.co/dkPXW8n/0186.webp"
      },
      {
        page: 19,
        link: "https://i.ibb.co/NLg6S77/0187.webp"
      },
      {
        page: 20,
        link: "https://i.ibb.co/2ZQntf2/0188.webp"
      },
      {
        page: 21,
        link: "https://i.ibb.co/RDTgL2J/0189.webp"
      },
      {
        page: 22,
        link: "https://i.ibb.co/gPr7mHm/0190.webp"
      },
      {
        page: 23,
        link: "https://i.ibb.co/9GQJTW7/0191.webp"
      },
      {
        page: 24,
        link: "https://i.ibb.co/9rWfrZg/0192.webp"
      },
      {
        page: 25,
        link: "https://i.ibb.co/YDJVwCN/0193.webp"
      },
      {
        page: 26,
        link: "https://i.ibb.co/867w9Kx/0194.webp"
      }
]


export default listePages;