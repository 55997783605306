const listeEpisodes = [
	{
        episode: 1,
        link: "https://www.dailymotion.com/embed/video/x7ub8mw"
    },
    {
        episode: 2,
        link: "https://www.dailymotion.com/embed/video/x7uc43i"
    },
    {
        episode: 3,
        link: "https://www.dailymotion.com/embed/video/x7ud0m1"
    },
    {
        episode: 4,
        link: "https://www.dailymotion.com/embed/video/x7uek3n"
    },
    {
        episode: 5,
        link: "https://www.dailymotion.com/embed/video/x7ugkbf"
    },
    {
        episode: 6,
        link: "https://www.dailymotion.com/embed/video/x7uh3lr"
    },
    {
        episode: 7,
        link: "https://www.dailymotion.com/embed/video/x7umbj9"
    },
    {
        episode: 8,
        link: "https://www.dailymotion.com/embed/video/x7umfi1"
    },
    {
        episode: 9,
        link: "https://www.dailymotion.com/embed/video/x7urgt8"
    },
    {
        episode: 10,
        link: "https://www.dailymotion.com/embed/video/x7uq556"
    },
    {
        episode: 11,
        link: "https://www.dailymotion.com/embed/video/x7urdem"
    },
    {
        episode: 12,
        link: "https://www.dailymotion.com/embed/video/x7usb1k"
    },
    {
        episode: 13,
        link: "https://www.dailymotion.com/embed/video/x7utlxi"
    }
]

export default listeEpisodes;