const listeEpisodes = [
    {
        episode : 1,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/OYHa5Aj1yw0?si=quYU3TmyziysGaoR"
            },
            {
                name: "Sibnet",
                link: "https://video.sibnet.ru/shell.php?videoid=5255057"
            }
        ]
    },
    {
        episode : 2,
        link : "https://video.sibnet.ru/shell.php?videoid=5258071"
    },
    {
        episode : 3,
        link : "https://video.sibnet.ru/shell.php?videoid=5260931"
    },
    {
        episode : 4,
        link : "https://video.sibnet.ru/shell.php?videoid=5265793"
    },
    {
        episode : 5,
        link : "https://video.sibnet.ru/shell.php?videoid=5273658"
    },
    {
        episode : 6,
        link : "https://video.sibnet.ru/shell.php?videoid=5287571"
    },
    {
        episode : 7,
        link : "https://video.sibnet.ru/shell.php?videoid=5305191"
    },
    {
        episode : 8,
        link : "https://video.sibnet.ru/shell.php?videoid=5305202"
    },
    {
        episode : 9,
        link : "https://video.sibnet.ru/shell.php?videoid=5310591"
    },
    {
        episode : 10,
        link : "https://video.sibnet.ru/shell.php?videoid=5310605"
    },
    {
        episode : 11,
        link : "https://video.sibnet.ru/shell.php?videoid=5310622"
    },
    {
        episode : 12,
        link : "https://video.sibnet.ru/shell.php?videoid=5310633"
    },
    {
        episode : 13,
        link : "https://video.sibnet.ru/shell.php?videoid=5310657"
    },
    {
        episode : 14,
        link : "https://video.sibnet.ru/shell.php?videoid=5310671"
    },
    {
        episode : 15,
        link : "https://video.sibnet.ru/shell.php?videoid=5310680"
    },
    {
        episode : 16,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/PVpkAWkpqto?si=BNc8YsrOkBKOhhmG"
            },
            {
                name: "Sibnet",
                link: "https://video.sibnet.ru/shell.php?videoid=5310684"
            }
        ]
    },
    {
        episode : 17,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/4MdUp2cAUEI?si=_9NxPoJiwgF3CeMU"
            },
            {
                name: "Sibnet",
                link: "https://video.sibnet.ru/shell.php?videoid=5310690"
            }
        ]
    },
    {
        episode : 18,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/nB8HBy_iumk?si=g1VNwX1RY--7xcJO"
            },
            {
                name: "Sibnet",
                link: "https://video.sibnet.ru/shell.php?videoid=5310706"
            }
        ]
    },
    {
        episode : 19,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/ByOTVVCZaUw?si=fA25qk6KUVGLl0_d"
            },
            {
                name: "Sibnet",
                link: "https://video.sibnet.ru/shell.php?videoid=5310723"
            }
        ]
    },
    {
        episode : 20,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/LuWVDNPIJ1U?si=5hLngDntrFPTzeur"
            },
            {
                name: "Sibnet",
                link: "https://video.sibnet.ru/shell.php?videoid=5310736"
            }
        ]
    },
    {
        episode : 21,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/zWSjW1nLz3w?si=2PlEX5aq8RNlQ_3D"
            },
            {
                name: "Sibnet",
                link: "https://video.sibnet.ru/shell.php?videoid=5310746"
            }
        ]
    },
    {
        episode : 22,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/VUv5llH6Nbg?si=RJFj42SHwOYJYpKO"
            },
            {
                name: "Sibnet",
                link: "https://video.sibnet.ru/shell.php?videoid=5310761"
            }
        ]
    },
    {
        episode : 23,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/pnhLeWSlvXA?si=Gnn6Rjc1Cx2GGxqV"
            },
            {
                name: "Sibnet",
                link: "https://video.sibnet.ru/shell.php?videoid=5310766"
            }
        ]
    },
    {
        episode : 24,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/VPen0aRrkTU?si=EK1I9_hVj-0DUSuq"
            },
            {
                name: "Sibnet",
                link: "https://video.sibnet.ru/shell.php?videoid=5310775"
            }
        ]
    },
    {
        episode : 25,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/lDUOn1x653w?si=SdliEeYCII5VW7Oq"
            },
            {
                name: "Sibnet",
                link: "https://video.sibnet.ru/shell.php?videoid=5310787"
            }
        ]
    },
    {
        episode : 26,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/XM3UZ196c-w?si=BuewhMxkTD0W2LG-"
            },
            {
                name: "Sibnet",
                link: "https://video.sibnet.ru/shell.php?videoid=5310812"
            }
        ]
    }
]

export default listeEpisodes;