const listePages = [
  { page: 1, link: "https://i.ibb.co/Dz83ZNG/0057-001.webp" },
  { page: 2, link: "https://i.ibb.co/7bK4Hc6/0057-002.webp" },
  { page: 3, link: "https://i.ibb.co/k8CPDt4/0057-003.webp" },
  { page: 4, link: "https://i.ibb.co/TbPWMYz/0057-004.webp" },
  { page: 5, link: "https://i.ibb.co/9h31Cww/0057-005.webp" },
  { page: 6, link: "https://i.ibb.co/ChZjhN0/0057-006.webp" },
  { page: 7, link: "https://i.ibb.co/1zrBqTR/0057-007.webp" },
  { page: 8, link: "https://i.ibb.co/xjjD42T/0057-008.webp" },
  { page: 9, link: "https://i.ibb.co/QjKRDbt/0057-009.webp" },
  { page: 10, link: "https://i.ibb.co/F4H5g1t/0057-010.webp" },
  { page: 11, link: "https://i.ibb.co/v4qLPkH/0057-011.webp" },
  { page: 12, link: "https://i.ibb.co/8crQ4FD/0057-012.webp" },
  { page: 13, link: "https://i.ibb.co/YLGX0Rj/0057-013.webp" },
  { page: 14, link: "https://i.ibb.co/9gDHfZx/0057-014.webp" },
  { page: 15, link: "https://i.ibb.co/xqKx15R/0057-015.webp" }
]


export default listePages;