const listePages = [
    { page: 1, link: "https://i.ibb.co/9g8dC4x/0091-001.webp" },
    { page: 2, link: "https://i.ibb.co/VwLrQ0n/0091-002.webp" },
    { page: 3, link: "https://i.ibb.co/wdKThSp/0091-003.webp" },
    { page: 4, link: "https://i.ibb.co/cyx562x/0091-004.webp" },
    { page: 5, link: "https://i.ibb.co/6HPjRz0/0091-005.webp" },
    { page: 6, link: "https://i.ibb.co/k9786pz/0091-006.webp" },
    { page: 7, link: "https://i.ibb.co/LtJpDBk/0091-007.webp" },
    { page: 8, link: "https://i.ibb.co/YThsZqC/0091-008.webp" },
    { page: 9, link: "https://i.ibb.co/Y2scsWx/0091-009.webp" },
    { page: 10, link: "https://i.ibb.co/5FbrWmX/0091-010.webp" },
    { page: 11, link: "https://i.ibb.co/6X3jXvN/0091-011.webp" },
    { page: 12, link: "https://i.ibb.co/mhnJ9sz/0091-012.webp" },
    { page: 13, link: "https://i.ibb.co/W2SZYRD/0091-013.webp" },
    { page: 14, link: "https://i.ibb.co/sm3sjd4/0091-014.webp" },
    { page: 15, link: "https://i.ibb.co/SrQYGq0/0091-015.webp" },
    { page: 16, link: "https://i.ibb.co/rGBjjdx/0091-016.webp" }
  ]
  

export default listePages;