const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/PtJtq2j/0025-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/mtBcvVQ/0025-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/B22mrRn/0025-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/zV5bQrx/0025-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/CJSssCT/0025-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/MGSMZsR/0025-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/sbzG9y1/0025-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/KNtss6W/0025-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/WV0dYFv/0025-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/Qcmx719/0025-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/G79rMJk/0025-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/G7ktW7L/0025-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/HYKfYGz/0025-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/Y7kNbjv/0025-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/KqKQP6c/0025-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/KhrhrSj/0025-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/R2M6nQx/0025-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/GxYq895/0025-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/30DxXSV/0025-019.webp"
  },
  {
    page: 20,
    link: "https://i.ibb.co/qBS2N8m/0025-020.webp"
  },
  {
    page: 21,
    link: "https://i.ibb.co/ZNJTXvR/0025-021.webp"
  },
  {
    page: 22,
    link: "https://i.ibb.co/BKmt9Jy/0025-022.webp"
  },
  {
    page: 23,
    link: "https://i.ibb.co/99yLLkX/0025-023.webp"
  },
  {
    page: 24,
    link: "https://i.ibb.co/gWd5XJ1/0025-024.webp"
  },
  {
    page: 25,
    link: "https://i.ibb.co/JmhDVG0/0025-025.webp"
  },
  {
    page: 26,
    link: "https://i.ibb.co/c2vsZmZ/0025-026.webp"
  },
  {
    page: 27,
    link: "https://i.ibb.co/P1pJzpZ/0025-027.webp"
  },
  {
    page: 28,
    link: "https://i.ibb.co/gv03KBv/0025-028.webp"
  },
  {
    page: 29,
    link: "https://i.ibb.co/vwK6T6Y/0025-029.webp"
  },
  {
    page: 30,
    link: "https://i.ibb.co/WkgpZ0w/0025-030.webp"
  },
  {
    page: 31,
    link: "https://i.ibb.co/wN4pk35/0025-031.webp"
  },
  {
    page: 32,
    link: "https://i.ibb.co/GVMSpFD/0025-032.webp"
  }
]

export default listePages;