const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/NyxQ4vL/0017-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/2s6ZmqK/0017-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/XY3zwQF/0017-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/gvmYRM3/0017-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/cQdVqYN/0017-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/kJn9d1y/0017-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/8MKVhzY/0017-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/T0CjMgD/0017-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/61YMKWb/0017-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/3RBqvbb/0017-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/nmn8dZy/0017-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/bXrvPjy/0017-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/gzRfDCc/0017-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/wYc5wGP/0017-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/Qv8H4s7/0017-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/Fx1mrkT/0017-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/QMR7wfz/0017-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/Sf4khtQ/0017-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/1bwY69p/0017-019.webp"
  },
  {
    page: 20,
    link: "https://i.ibb.co/bdnRJTn/0017-020.webp"
  },
  {
    page: 21,
    link: "https://i.ibb.co/Tm5Kv3q/0017-021.webp"
  },
  {
    page: 22,
    link: "https://i.ibb.co/vkCf4KR/0017-022.webp"
  },
  {
    page: 23,
    link: "https://i.ibb.co/Byx25R6/0017-023.webp"
  },
  {
    page: 24,
    link: "https://i.ibb.co/djRMK2S/0017-024.webp"
  },
  {
    page: 25,
    link: "https://i.ibb.co/cv0mJbX/0017-025.webp"
  },
  {
    page: 26,
    link: "https://i.ibb.co/qmk6kmF/0017-026.webp"
  },
  {
    page: 27,
    link: "https://i.ibb.co/9ywtYsY/0017-027.webp"
  },
  {
    page: 28,
    link: "https://i.ibb.co/s3yWYN3/0017-028.webp"
  },
  {
    page: 29,
    link: "https://i.ibb.co/3dCgmY0/0017-029.webp"
  },
  {
    page: 30,
    link: "https://i.ibb.co/C8ppb9h/0017-030.webp"
  },
  {
    page: 31,
    link: "https://i.ibb.co/V96tpQs/0017-031.webp"
  },
  {
    page: 32,
    link: "https://i.ibb.co/VSGDMqk/0017-032.webp"
  },
  {
    page: 33,
    link: "https://i.ibb.co/ZHbK84q/0017-033.webp"
  },
  {
    page: 34,
    link: "https://i.ibb.co/Y3MLZ6k/0017-034.webp"
  },
  {
    page: 35,
    link: "https://i.ibb.co/tqgfZHr/0017-035.webp"
  },
  {
    page: 36,
    link: "https://i.ibb.co/1L4LXXC/0017-036.webp"
  }
  
]

export default listePages;