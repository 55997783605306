const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/sCkqqSn/0019-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/Ybp34mG/0019-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/s9SPNj0/0019-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/9tBmrxL/0019-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/fdvqZX0/0019-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/zJHdBsC/0019-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/4j5pCMC/0019-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/NWLDV4k/0019-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/SPDsS3s/0019-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/bWMLnHL/0019-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/Jc9VJYS/0019-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/c1WcBgp/0019-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/MRzkgWq/0019-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/h715NYp/0019-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/R6j0Kny/0019-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/wpmXFnh/0019-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/d7c5GBm/0019-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/NsMcYKF/0019-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/209Hgmz/0019-019.webp"
  },
  {
    page: 20,
    link: "https://i.ibb.co/hR9sjCw/0019-020.webp"
  },
  {
    page: 21,
    link: "https://i.ibb.co/9gXt41X/0019-021.webp"
  },
  {
    page: 22,
    link: "https://i.ibb.co/vZRxGf9/0019-022.webp"
  },
  {
    page: 23,
    link: "https://i.ibb.co/ZKndVXP/0019-023.webp"
  },
  {
    page: 24,
    link: "https://i.ibb.co/RQ0wsFV/0019-024.webp"
  },
  {
    page: 25,
    link: "https://i.ibb.co/Jdxhg7Y/0019-025.webp"
  },
  {
    page: 26,
    link: "https://i.ibb.co/VSbwjfF/0019-026.webp"
  },
  {
    page: 27,
    link: "https://i.ibb.co/LgzvpdS/0019-027.webp"
  },
  {
    page: 28,
    link: "https://i.ibb.co/rFhK5mf/0019-028.webp"
  },
  {
    page: 29,
    link: "https://i.ibb.co/fN3R2y2/0019-029.webp"
  },
  {
    page: 30,
    link: "https://i.ibb.co/r07zwZg/0019-030.webp"
  },
  {
    page: 31,
    link: "https://i.ibb.co/hD1ZQzR/0019-031.webp"
  },
  {
    page: 32,
    link: "https://i.ibb.co/mq7PqwD/0019-032.webp"
  },
  {
    page: 33,
    link: "https://i.ibb.co/QbxGcy6/0019-033.webp"
  },
  {
    page: 34,
    link: "https://i.ibb.co/tbRgqdB/0019-034.webp"
  },
  {
    page: 35,
    link: "https://i.ibb.co/k9khDtr/0019-035.webp"
  },
  {
    page: 36,
    link: "https://i.ibb.co/PGrsFhc/0019-036.webp"
  },
  {
    page: 37,
    link: "https://i.ibb.co/wz13YGV/0019-037.webp"
  },
  {
    page: 38,
    link: "https://i.ibb.co/zxt9pgk/0019-038.webp"
  }  
]

export default listePages;