import HeaderNavList from "./NavList"

export default function Header() {
	return (
        <header className="Header">
            <div className='header-content'>
                <a href="https://www.streaming-espace.fr" className="header-logo">
                    <img width="114px" height="37px" src="https://i.ibb.co/Hx7KC0C/logo.webp" alt='Beyblade Espace Streaming'/>
                </a>
                <HeaderNavList/>
            </div>
        </header>
    )
}