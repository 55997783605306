const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/Ky8nPyB/0031-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/nPkvL7R/0031-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/qkY6sfT/0031-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/BGRhqwH/0031-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/DDbNH76/0031-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/QkbMDTp/0031-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/Tqbvx6C/0031-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/gFYLmXR/0031-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/CsQdJ26/0031-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/yd2wp7g/0031-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/9pR9QQV/0031-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/Gv7FCVf/0031-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/dfVm1Wv/0031-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/kKVh1Pr/0031-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/3kF2TG1/0031-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/q70ZbpT/0031-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/4j1CSch/0031-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/Qf5Lvmz/0031-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/GTQ45V8/0031-019.webp"
  },
  {
    page: 20,
    link: "https://i.ibb.co/PWyyMBp/0031-020.webp"
  },
  {
    page: 21,
    link: "https://i.ibb.co/FJ98VwN/0031-021.webp"
  },
  {
    page: 22,
    link: "https://i.ibb.co/7nvjXY3/0031-022.webp"
  },
  {
    page: 23,
    link: "https://i.ibb.co/HrbHRLJ/0031-023.webp"
  },
  {
    page: 24,
    link: "https://i.ibb.co/3dhGDMz/0031-024.webp"
  },
  {
    page: 25,
    link: "https://i.ibb.co/cygbkJY/0031-025.webp"
  },
  {
    page: 26,
    link: "https://i.ibb.co/Qkff9qK/0031-026.webp"
  },
  {
    page: 27,
    link: "https://i.ibb.co/swtFLY3/0031-027.webp"
  },
  {
    page: 28,
    link: "https://i.ibb.co/CwY9JgF/0031-028.webp"
  },
  {
    page: 29,
    link: "https://i.ibb.co/PZXn79j/0031-029.webp"
  },
  {
    page: 30,
    link: "https://i.ibb.co/hyPc69d/0031-030.webp"
  },
  {
    page: 31,
    link: "https://i.ibb.co/8Xyd2RS/0031-031.webp"
  },
  {
    page: 32,
    link: "https://i.ibb.co/HqRqR5d/0031-032.webp"
  },
  {
    page: 33,
    link: "https://i.ibb.co/qsPv0mb/0031-033.webp"
  },
  {
    page: 34,
    link: "https://i.ibb.co/vsGGYyw/0031-034.webp"
  },
  {
    page: 35,
    link: "https://i.ibb.co/zPHtY8W/0031-035.webp"
  },
  {
    page: 36,
    link: "https://i.ibb.co/3MnNjkh/0031-036.webp"
  },
  {
    page: 37,
    link: "https://i.ibb.co/d2NMhzw/0031-037.webp"
  },
  {
    page: 38,
    link: "https://i.ibb.co/k16kHW5/0031-038.webp"
  },
  {
    page: 39,
    link: "https://i.ibb.co/JmMhsf2/0031-039.webp"
  }  
]

export default listePages;