import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ErrorPage from '../components/ErrorPage';
import MetalData from '../datas/scans/metal/chaptersList'
import BurstData from '../datas/scans/burst/chaptersList'
import XData from '../datas/scans/x/chaptersList'
import SpinoffData from '../datas/scans/spinoff/chaptersList'

export default function MenuScans() {
    const [largeurEcran, setLargeurEcran] = useState(window.innerWidth);

    useEffect(() => {
        function handleResize() {
            setLargeurEcran(window.innerWidth);
        }
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);

    const param = useParams()
    const generationURL = param.generation
    let actualGeneration
    let generationData

    if (generationURL === "metal") {
        actualGeneration = "Metal Fight Beyblade"
        generationData = MetalData
    } else if (generationURL === "burst") {
        actualGeneration = "Beyblade Burst"
        generationData = BurstData
    } else if (generationURL === "x") {
        actualGeneration = "Beyblade X"
        generationData = XData
    } else if (generationURL === "spinoff") {
        actualGeneration = "Spin-Off"
        generationData = SpinoffData
    } else {
        return <ErrorPage />
    }

    return (
        <>
            <section className='scans-section'>
                <div className='title-container'>
                    <div className='title-decoration'></div>
                    <h1>SCANS {actualGeneration.toUpperCase()}</h1>
                </div>
                <div className={generationURL + " articles-container"}>
                    {
                        generationData.map((chapitre) =>
                            <article key={chapitre.url}>
                                <a href={chapitre.vflink}>
                                    <img width="220px" height="310px" src={chapitre.img} alt={"Affiche de " + chapitre.titre + " " + chapitre.chapitre + " Beyblade Streaming"}/>
                                </a>
                                <div className='scan-description'>
                                    <h2>{chapitre.chapitre}</h2>
                                </div>
                                <div className='btnlinks-div'>
                                    {largeurEcran <= 850 ? (
                                        <>
                                            <a href={chapitre.vflink}>
                                                <svg width="148" height="31" viewBox="0 0 148 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0.5 8C0.5 3.85786 3.85786 0.5 8 0.5H140C144.142 0.5 147.5 3.85786 147.5 8V23C147.5 27.1421 144.142 30.5 140 30.5H8C3.85787 30.5 0.5 27.1421 0.5 23V8Z" fill="black" stroke="url(#paint0_linear_1104_25)"/>
                                                    <path d="M68.9326 21L66 10.9785H67.7979L69.876 18.3955L71.8857 10.9785H73.6494L70.71 21H68.9326ZM74.5039 21V10.9785H80.1367V12.6738H76.1582V15.0459H79.5967V16.7412H76.1582V21H74.5039Z" fill="white"/>
                                                    <defs>
                                                        <linearGradient id="paint0_linear_1104_25" x1="8.68364e-06" y1="-124" x2="150.26" y2="-121.198" gradientUnits="userSpaceOnUse">
                                                            <stop stopColor="#0C7DF1"/>
                                                            <stop offset="0.229167" stopColor="#371CF1"/>
                                                            <stop offset="0.484375" stopColor="#A01DE5"/>
                                                            <stop offset="0.744792" stopColor="#EF0F64"/>
                                                            <stop offset="1" stopColor="#DD6F0F"/>
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                            </a>
                                        </>
                                    ) : (
                                        <>
                                            <a href={chapitre.vflink}>
                                                <svg width="109" height="46" viewBox="0 0 109 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0.5 8C0.5 3.85787 3.85786 0.5 8 0.5H101C105.142 0.5 108.5 3.85786 108.5 8V38C108.5 42.1421 105.142 45.5 101 45.5H8C3.85786 45.5 0.5 42.1421 0.5 38V8Z" fill="black" stroke="url(#paint0_linear_922_29)"/>
                                                    <path d="M42.8203 22V11.9785H43.9072V20.8174H47.9541V22H42.8203ZM49.4785 22V11.9785H50.5654V22H49.4785ZM52.4658 22V11.9785H56.1094C56.8704 11.9785 57.431 12.0719 57.791 12.2588C58.1556 12.4456 58.4541 12.7578 58.6865 13.1953C58.9189 13.6283 59.0352 14.1318 59.0352 14.7061C59.0352 15.4626 58.8369 16.0755 58.4404 16.5449C58.0485 17.0143 57.4766 17.3128 56.7246 17.4404C57.0299 17.6227 57.2624 17.8073 57.4219 17.9941C57.7454 18.377 58.0303 18.8031 58.2764 19.2725L59.7119 22H58.3447L57.251 19.915C56.8271 19.0993 56.5059 18.5501 56.2871 18.2676C56.0729 17.985 55.8633 17.7959 55.6582 17.7002C55.4577 17.5999 55.1751 17.5498 54.8105 17.5498H53.5527V22H52.4658ZM53.5527 16.4014H55.8906C56.401 16.4014 56.7884 16.3398 57.0527 16.2168C57.3171 16.0938 57.5267 15.9001 57.6816 15.6357C57.8366 15.3714 57.9141 15.0638 57.9141 14.7129C57.9141 14.207 57.766 13.8105 57.4697 13.5234C57.1735 13.2318 56.7337 13.0859 56.1504 13.0859H53.5527V16.4014ZM60.7783 22V11.9785H66.7188V13.1611H61.8652V16.2305H66.4111V17.4062H61.8652V20.8174H66.9102V22H60.7783Z" fill="white"/>
                                                    <path d="M35.9092 36V25.9785H41.8496V27.1611H36.9961V30.2305H41.542V31.4062H36.9961V34.8174H42.041V36H35.9092ZM43.5107 36V25.9785H44.625L48.9453 33.8467V25.9785H49.9844V36H48.8701L44.5566 28.125V36H43.5107ZM59.1732 36L56.2406 25.9785H58.0385L60.1166 33.3955L62.1264 25.9785H63.89L60.9506 36H59.1732ZM64.7445 36V25.9785H70.3773V27.6738H66.3988V30.0459H69.8373V31.7412H66.3988V36H64.7445Z" fill="white"/>
                                                    <defs>
                                                        <linearGradient id="paint0_linear_922_29" x1="6.39538e-06" y1="-184.001" x2="110.694" y2="-182.976" gradientUnits="userSpaceOnUse">
                                                            <stop stopColor="#0C7DF1"/>
                                                            <stop offset="0.229167" stopColor="#371CF1"/>
                                                            <stop offset="0.484375" stopColor="#A01DE5"/>
                                                            <stop offset="0.744792" stopColor="#EF0F64"/>
                                                            <stop offset="1" stopColor="#DD6F0F"/>
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                            </a>
                                        </>
                                    )}
                                </div>
                            </article>
                        )
                    }
                </div>
            </section> 
        </>
    )       
}