const listePages = [
  { page: 1, link: "https://i.ibb.co/znr69qq/0084-001.webp" },
  { page: 2, link: "https://i.ibb.co/tKsgtrr/0084-002.webp" },
  { page: 3, link: "https://i.ibb.co/tX1c7Bd/0084-003.webp" },
  { page: 4, link: "https://i.ibb.co/YkhxRxz/0084-004.webp" },
  { page: 5, link: "https://i.ibb.co/jL7jN3N/0084-005.webp" },
  { page: 6, link: "https://i.ibb.co/wzmm4KW/0084-006.webp" },
  { page: 7, link: "https://i.ibb.co/XFz0rNB/0084-007.webp" },
  { page: 8, link: "https://i.ibb.co/N6gyvzT/0084-008.webp" },
  { page: 9, link: "https://i.ibb.co/RH4jz4v/0084-009.webp" },
  { page: 10, link: "https://i.ibb.co/Qmc0Bsn/0084-010.webp" },
  { page: 11, link: "https://i.ibb.co/hYnsnGS/0084-011.webp" },
  { page: 12, link: "https://i.ibb.co/5xxGRCb/0084-012.webp" },
  { page: 13, link: "https://i.ibb.co/YRJB1sy/0084-013.webp" },
  { page: 14, link: "https://i.ibb.co/zn3KYty/0084-014.webp" },
  { page: 15, link: "https://i.ibb.co/yy3SYbx/0084-015.webp" },
  { page: 16, link: "https://i.ibb.co/6RzWdrY/0084-016.webp" },
  { page: 17, link: "https://i.ibb.co/ws74VQc/0084-017.webp" },
  { page: 18, link: "https://i.ibb.co/G5j0tQx/0084-018.webp" },
  { page: 19, link: "https://i.ibb.co/VHBmT6f/0084-019.webp" },
  { page: 20, link: "https://i.ibb.co/BcgbpRw/0084-020.webp" },
  { page: 21, link: "https://i.ibb.co/k3YYKf9/0084-021.webp" },
  { page: 22, link: "https://i.ibb.co/37ngCfV/0084-022.webp" },
  { page: 23, link: "https://i.ibb.co/2c6tsYK/0084-023.webp" },
  { page: 24, link: "https://i.ibb.co/whtFkTg/0084-024.webp" },
  { page: 25, link: "https://i.ibb.co/rxpYb9n/0084-025.webp" },
  { page: 26, link: "https://i.ibb.co/BfwBXr6/0084-026.webp" },
  { page: 27, link: "https://i.ibb.co/BKQDbLP/0084-027.webp" },
  { page: 28, link: "https://i.ibb.co/DkZfPxg/0084-028.webp" },
  { page: 29, link: "https://i.ibb.co/ZgQsSyP/0084-029.webp" },
  { page: 30, link: "https://i.ibb.co/wMnB8dn/0084-030.webp" },
  { page: 31, link: "https://i.ibb.co/fGY3rr1/0084-031.webp" },
  { page: 32, link: "https://i.ibb.co/hMpqBS7/0084-032.webp" }
]


export default listePages;