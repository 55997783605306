const listeEpisodes = [
    {
        episode: 1,
        link: "https://www.youtube.com/embed/cnJXHbVnNNk"
    },
    {
        episode: 2,
        link: "https://www.youtube.com/embed/U0kXEClNf98"
    },
    {
        episode: 3,
        link: "https://www.youtube.com/embed/kGpva8q_7fo"
    },
    {
        episode: 4,
        link: "https://www.youtube.com/embed/jt-DpR7bFi8"
    },
    {
        episode: 5,
        link: "https://www.youtube.com/embed/tMKZMqP8EOo"
    },
    {
        episode: 6,
        link: "https://www.youtube.com/embed/99GBN4wjq-M"
    },
    {
        episode: 7,
        link: "https://www.youtube.com/embed/V3KSiHbEj88"
    },
    {
        episode: 8,
        link: "https://www.youtube.com/embed/nrW5C-HoLaQ"
    },
    {
        episode: 9,
        link: "https://www.youtube.com/embed/kXl4XYo-Vpo"
    },
    {
        episode: 10,
        link: "https://www.youtube.com/embed/0MsEj4-n89k"
    },
    {
        episode: 11,
        link: "https://www.youtube.com/embed/AAd3OjQatA4"
    },
    {
        episode: 12,
        link: "https://www.youtube.com/embed/gfVRoHRu_Xk"
    },
    {
        episode: 13,
        link: "https://www.youtube.com/embed/sRTcZIBvnn4"
    },
    {
        episode: 14,
        link: "https://www.youtube.com/embed/itZ7aEcdoAw"
    },
    {
        episode: 15,
        link: "https://www.youtube.com/embed/5PR5ZAexHH4"
    },
    {
        episode: 16,
        link: "https://www.youtube.com/embed/NcAN4Dy4Vvs"
    },
    {
        episode: 17,
        link: "https://www.youtube.com/embed/Iot79n54f5o"
    },
    {
        episode: 18,
        link: "https://www.youtube.com/embed/1Go3aghnSMs"
    },
    {
        episode: 19,
        link: "https://www.youtube.com/embed/ILnHMq5rMpo"
    },
    {
        episode: 20,
        link: "https://www.youtube.com/embed/q54d0C2ESB4"
    },
    {
        episode: 21,
        link: "https://www.youtube.com/embed/CjXYe8BWxjw"
    },
    {
        episode: 22,
        link: "https://www.youtube.com/embed/XDuA0DZaMsk"
    },
    {
        episode: 23,
        link: "https://www.youtube.com/embed/hXTvbap-fyc"
    },
    {
        episode: 24,
        link: "https://www.youtube.com/embed/NS_rWyi3uYA"
    },
    {
        episode: 25,
        link: "https://www.youtube.com/embed/wS3EupNc9Uw"
    },
    {
        episode: 26,
        link: "https://www.youtube.com/embed/7J_Jq0KExGw"
    },
    {
        episode: 27,
        link: "https://www.youtube.com/embed/u6zsSpDqj5E"
    },
    {
        episode: 28,
        link: "https://www.youtube.com/embed/0VWgbOt2-QQ"
    },
    {
        episode: 29,
        link: "https://www.youtube.com/embed/ckCu2mAGShw"
    },
    {
        episode: 30,
        link: "https://www.youtube.com/embed/XDUpfTUYVQs"
    },
    {
        episode: 31,
        link: "https://www.youtube.com/embed/I_PJgkabteo"
    },
    {
        episode: 32,
        link: "https://www.youtube.com/embed/A1R0hLSySsQ"
    },
    {
        episode: 33,
        link: "https://www.youtube.com/embed/xkHeXnwZjJw"
    },
    {
        episode: 34,
        link: "https://www.youtube.com/embed/botoEJxie2s"
    },
    {
        episode: 35,
        link: "https://www.youtube.com/embed/IxImQRyI8nE"
    },
    {
        episode: 36,
        link: "https://www.youtube.com/embed/i1xFJjRHjbU"
    },
    {
        episode: 37,
        link: "https://www.youtube.com/embed/oYB3jDwP_sI"
    },
    {
        episode: 38,
        link: "https://www.youtube.com/embed/a2EpfLbcJj8"
    },
    {
        episode: 39,
        link: "https://www.youtube.com/embed/buBjIM_rOuc"
    },
    {
        episode: 40,
        link: "https://www.youtube.com/embed/IpeAmPG_ZiM"
    },
    {
        episode: 41,
        link: "https://www.youtube.com/embed/Eoks7qs-8s0"
    },
    {
        episode: 42,
        link: "https://www.youtube.com/embed/pglDustFCjI"
    },
    {
        episode: 43,
        link: "https://www.youtube.com/embed/4dYcyoEvO_Q"
    },
    {
        episode: 44,
        link: "https://www.youtube.com/embed/rxzrsFKu-P4"
    },
    {
        episode: 45,
        link: "https://www.youtube.com/embed/Tdl-GQbvp-Q"
    },
    {
        episode: 46,
        link: "https://www.youtube.com/embed/rnGKcqRCdp0"
    },
    {
        episode: 47,
        link: "https://www.youtube.com/embed/UpZeioesYGQ"
    },
    {
        episode: 48,
        link: "https://www.youtube.com/embed/9dY7QUCvbwk"
    },
    {
        episode: 49,
        link: "https://www.youtube.com/embed/dGlg847-Mmg"
    },
    {
        episode: 50,
        link: "https://www.youtube.com/embed/-Z7xm9D9BjQ"
    },
    {
        episode: 51,
        link: "https://www.youtube.com/embed/xlV2UIAzb4c"
    }
]

export default listeEpisodes;