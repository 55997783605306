const listePages = [
    {
        page: 1,
        link: "https://i.ibb.co/MMX5PG1/158.webp"
      },
      {
        page: 2,
        link: "https://i.ibb.co/b2wzFkh/159.webp"
      },
      {
        page: 3,
        link: "https://i.ibb.co/yy3rv5m/160.webp"
      },
      {
        page: 4,
        link: "https://i.ibb.co/1GKKMSY/161.webp"
      },
      {
        page: 5,
        link: "https://i.ibb.co/XZ0Y0pk/162.webp"
      },
      {
        page: 6,
        link: "https://i.ibb.co/qNRgYmK/163.webp"
      },
      {
        page: 7,
        link: "https://i.ibb.co/9rvdBhm/164.webp"
      },
      {
        page: 8,
        link: "https://i.ibb.co/wQhDshR/165.webp"
      },
      {
        page: 9,
        link: "https://i.ibb.co/RHTTkS3/166.webp"
      },
      {
        page: 10,
        link: "https://i.ibb.co/f26nLWD/167.webp"
      },
      {
        page: 11,
        link: "https://i.ibb.co/4mDc5Y9/168.webp"
      },
      {
        page: 12,
        link: "https://i.ibb.co/rb46Q6r/169.webp"
      },
      {
        page: 13,
        link: "https://i.ibb.co/PchXhZC/170.webp"
      },
      {
        page: 14,
        link: "https://i.ibb.co/bQCSRcv/171.webp"
      },
      {
        page: 15,
        link: "https://i.ibb.co/ZS1rWDs/172.webp"
      },
      {
        page: 16,
        link: "https://i.ibb.co/pzGf3yJ/173.webp"
      },
      {
        page: 17,
        link: "https://i.ibb.co/82q6qMD/174.webp"
      },
      {
        page: 18,
        link: "https://i.ibb.co/hyBkZ1g/175.webp"
      },
      {
        page: 19,
        link: "https://i.ibb.co/YPZGdf9/176.webp"
      },
      {
        page: 20,
        link: "https://i.ibb.co/VDWMbs7/177.webp"
      },
      {
        page: 21,
        link: "https://i.ibb.co/myNKT0C/178.webp"
      },
      {
        page: 22,
        link: "https://i.ibb.co/SsNPPVV/179.webp"
      },
      {
        page: 23,
        link: "https://i.ibb.co/Pc1pPbp/180.webp"
      },
      {
        page: 24,
        link: "https://i.ibb.co/mS2MkY9/181.webp"
      },
      {
        page: 25,
        link: "https://i.ibb.co/fGRMfNz/182.webp"
      },
      {
        page: 26,
        link: "https://i.ibb.co/wYCGZsw/183.webp"
      },
      {
        page: 27,
        link: "https://i.ibb.co/dL50RrP/184.webp"
      },
      {
        page: 28,
        link: "https://i.ibb.co/zSMHxFW/185.webp"
      },
      {
        page: 29,
        link: "https://i.ibb.co/fNSbjXB/186.webp"
      },
      {
        page: 30,
        link: "https://i.ibb.co/CBPjfFV/187.webp"
      },
      {
        page: 31,
        link: "https://i.ibb.co/qWCB3kg/188.webp"
      },
      {
        page: 32,
        link: "https://i.ibb.co/tBYzzV2/189.webp"
      },
      {
        page: 33,
        link: "https://i.ibb.co/sWZ2r6X/190.webp"
      },
      {
        page: 34,
        link: "https://i.ibb.co/ThF9kX9/191.webp"
      },
      {
        page: 35,
        link: "https://i.ibb.co/NWRyjpb/192.webp"
      },
      {
        page: 36,
        link: "https://i.ibb.co/P1JCCSM/193.webp"
      },
      {
        page: 37,
        link: "https://i.ibb.co/Y8WqWGV/194.webp"
      }
]


export default listePages;