const listePages = [
    { page: 1, link: "https://i.ibb.co/9tM2CBb/0055-001.webp" },
    { page: 2, link: "https://i.ibb.co/1L9f1JR/0055-002.webp" },
    { page: 3, link: "https://i.ibb.co/zFB36P7/0055-003.webp" },
    { page: 4, link: "https://i.ibb.co/p0WfZKp/0055-004.webp" },
    { page: 5, link: "https://i.ibb.co/f2s35Z2/0055-005.webp" },
    { page: 6, link: "https://i.ibb.co/r2t1gJY/0055-006.webp" },
    { page: 7, link: "https://i.ibb.co/kMFdsbY/0055-007.webp" },
    { page: 8, link: "https://i.ibb.co/Q6Z3fnB/0055-008.webp" },
    { page: 9, link: "https://i.ibb.co/SXQv9tb/0055-009.webp" },
    { page: 10, link: "https://i.ibb.co/rx1pJjs/0055-010.webp" },
    { page: 11, link: "https://i.ibb.co/7vzS1Wq/0055-011.webp" },
    { page: 12, link: "https://i.ibb.co/0VJtqHm/0055-012.webp" },
    { page: 13, link: "https://i.ibb.co/B653Z1n/0055-013.webp" },
    { page: 14, link: "https://i.ibb.co/H4XsJ4x/0055-014.webp" },
    { page: 15, link: "https://i.ibb.co/MMyg8vr/0055-015.webp" },
    { page: 16, link: "https://i.ibb.co/ZT1HNdB/0055-016.webp" },
    { page: 17, link: "https://i.ibb.co/mXWFkMg/0055-017.webp" },
    { page: 18, link: "https://i.ibb.co/5RxQQMN/0055-018.webp" },
    { page: 19, link: "https://i.ibb.co/VDJd6sG/0055-019.webp" },
    { page: 20, link: "https://i.ibb.co/pwMMx3Y/0055-020.webp" },
    { page: 21, link: "https://i.ibb.co/QFCQ0m4/0055-021.webp" },
    { page: 22, link: "https://i.ibb.co/9v4gsLm/0055-022.webp" },
    { page: 23, link: "https://i.ibb.co/8mwRKsq/0055-023.webp" },
    { page: 24, link: "https://i.ibb.co/CbvGt6Y/0055-024.webp" },
    { page: 25, link: "https://i.ibb.co/GMk9Sth/0055-025.webp" },
    { page: 26, link: "https://i.ibb.co/bmh2z9X/0055-026.webp" },
    { page: 27, link: "https://i.ibb.co/HNqthW9/0055-027.webp" },
    { page: 28, link: "https://i.ibb.co/wdYz1s4/0055-028.webp" },
    { page: 29, link: "https://i.ibb.co/k48MWzJ/0055-029.webp" },
    { page: 30, link: "https://i.ibb.co/9Z2pVHj/0055-030.webp" },
    { page: 31, link: "https://i.ibb.co/HgR8xTw/0055-031.webp" },
    { page: 32, link: "https://i.ibb.co/LNXjCFw/0055-032.webp" }
  ]
  

export default listePages;