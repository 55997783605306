import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ErrorPage from '../components/ErrorPage';

export default function EpisodeList() {
    const [data, setData] = useState([]);
    const [status, doesItWorked] = useState(false)
    const param = useParams()
    const generationURL = param.generation
    const saisonURL = param.saison
    const langageURL = param.langue

    useEffect(() => {
        try {
            setData(require(`../datas/episodes/${generationURL}/${saisonURL}/${langageURL}.js`).default)
            doesItWorked(true)
        } catch (error) {}
    }, [doesItWorked, generationURL, saisonURL, langageURL])
        
    if (status === false) {
        return <ErrorPage />
    }

    return (
            <>
                <div className='content'>
                    <section className='episodes-section'>
                        {data.map((episode) => {
                            if (episode.episode === "trailer") {
                                return (
                                    <div className='episode-div' key={episode.episode}>
                                        <a href={langageURL + "/" + episode.episode}>Trailer</a>
                                    </div>
                                )
                            } else if (episode.special) {
                                return (
                                    <div className='episode-div' key={episode.episode}>
                                        <a href={langageURL + "/" + episode.episode}>Spécial {episode.special}</a>
                                    </div>
                                )
                            } else {
                                if (episode.part) {
                                    return (
                                        <div className='episode-div' key={episode.episode}>
                                            <a href={langageURL + "/" + episode.episode}>Épisode {episode.realepisode} Partie {episode.part}</a>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className='episode-div' key={episode.episode}>
                                            <a href={langageURL + "/" + episode.episode}>Épisode {episode.episode}</a>
                                        </div>
                                    )
                                }
                            }
                        })}

                        
                    </section>
                </div>           
            </>
    )       
}