const listePages = [
  { page: 1, link: 'https://i.ibb.co/sjKDMNh/0001.webp' },
  { page: 2, link: 'https://i.ibb.co/RQ54m1Y/0002.webp' },
  { page: 3, link: 'https://i.ibb.co/MhHgKKh/0003.webp' },
  { page: 4, link: 'https://i.ibb.co/PZLkHX8/0004.webp' },
  { page: 5, link: 'https://i.ibb.co/HBLmgHK/0005.webp' },
  { page: 6, link: 'https://i.ibb.co/CsXZvq7/0006.webp' },
  { page: 7, link: 'https://i.ibb.co/mTBBcPF/0007.webp' },
  { page: 8, link: 'https://i.ibb.co/KFzqYB0/0008.webp' },
  { page: 9, link: 'https://i.ibb.co/6NyTfFM/0009.webp' },
  { page: 10, link: 'https://i.ibb.co/r5bW1vf/0010.webp' },
  { page: 11, link: 'https://i.ibb.co/frKDsvs/0011.webp' },
  { page: 12, link: 'https://i.ibb.co/KxZRFC3/0012.webp' },
  { page: 13, link: 'https://i.ibb.co/7SLbhjx/0013.webp' },
  { page: 14, link: 'https://i.ibb.co/16cv5WW/0014.webp' },
  { page: 15, link: 'https://i.ibb.co/HghDhrs/0015.webp' },
  { page: 16, link: 'https://i.ibb.co/dKt7Mjx/0016.webp' },
  { page: 17, link: 'https://i.ibb.co/jMvsn71/0017.webp' },
  { page: 18, link: 'https://i.ibb.co/XWLLDmg/0018.webp' },
  { page: 19, link: 'https://i.ibb.co/6NSmyMw/0019.webp' },
  { page: 20, link: 'https://i.ibb.co/DQfQgRL/0020.webp' },
  { page: 21, link: 'https://i.ibb.co/PTbxzfK/0021.webp' },
  { page: 22, link: 'https://i.ibb.co/r0bLggZ/0022.webp' },
  { page: 23, link: 'https://i.ibb.co/p4SNQKp/0023.webp' },
  { page: 24, link: 'https://i.ibb.co/fMK5SYq/0024.webp' },
  { page: 25, link: 'https://i.ibb.co/N6rp792/0025.webp' },
  { page: 26, link: 'https://i.ibb.co/HBzXKrW/0026.webp' },
  { page: 27, link: 'https://i.ibb.co/LryXLwt/0027.webp' },
  { page: 28, link: 'https://i.ibb.co/6yY8Ddt/0028.webp' },
  { page: 29, link: 'https://i.ibb.co/d24wQtB/0029.webp' },
  { page: 30, link: 'https://i.ibb.co/8d7SnNG/0030.webp' },
  { page: 31, link: 'https://i.ibb.co/54gXctv/0031.webp' },
  { page: 32, link: 'https://i.ibb.co/MMXbfKW/0032.webp' }
]

export default listePages;