const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/SrWVMxm/34-6-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/WWFzQBv/34-6-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/985rXRX/34-6-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/k1hKGkX/34-6-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/MDhsRGs/34-6-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/TkVm3QM/34-6-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/JvtX4nV/34-6-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/Wxs3f9V/34-6-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/7Yw3Jrs/34-6-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/mvgpty1/34-6-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/0sQJKSm/34-6-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/2gCsvWS/34-6-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/p4VjZrz/34-6-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/Bfqm7DT/34-6-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/hDJztGQ/34-6-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/7nM5P82/34-6-016.webp"
  }  
]

export default listePages;