const animeList = [
    {
        id : 1,
        titre : "Bakuten Shoot Beyblade",
        link : "/bakuten",
        img : "https://i.ibb.co/KskZBb6/3-UOE3xx-U-1.webp",
        logo : "https://i.ibb.co/n6ckHZm/beyblade-bakuten.webp",
        character: "https://i.ibb.co/9rsb5GT/tyson.webp"
    },
    {
        id : 2,
        titre : "Metal Fight Beyblade",
        link : "/metal",
        img : "https://i.ibb.co/THdHrp7/12-quvnwfn.webp",
        logo : "https://i.ibb.co/FxkP8pC/beyblade-metal.webp",
        character: "https://i.ibb.co/9ncz6fF/gingka.webp"
    },
    {
        id : 3,
        titre : "Beyblade Burst",
        link : "/burst",
        img : "https://i.ibb.co/kKkJMcn/bx21236-8-B4f-ORbu-Up6v.webp",
        logo : "https://i.ibb.co/DLy9Ph4/beyblade-burst.webp",
        character: "https://i.ibb.co/Y3yRh0Z/valt.webp"
    },
    {
        id : 4,
        titre : "Beyblade X",
        link : "/x",
        img : "https://i.ibb.co/n6TL20p/295343.webp",
        logo : "https://i.ibb.co/KjRFQNV/beyblade-x.webp",
        character: "https://i.ibb.co/qsxqjcg/bird.webp"
    },
    {
        id : 5,
        titre : "Spin-Off",
        link : "/spinoff",
        img : "https://i.ibb.co/2FZSRKd/Any-Conv-com-16-QFh-D9-Et.webp",
        logo : "https://i.ibb.co/w6Hf8B5/beywheels.webp",
        character: "https://i.ibb.co/7YzJwW1/sho.webp"
    }
]

export default animeList