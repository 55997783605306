const listePages = [
    { page: 1, link: "https://i.ibb.co/VYgBq9k/0066-001.webp" },
    { page: 2, link: "https://i.ibb.co/LSVHDYC/0066-002.webp" },
    { page: 3, link: "https://i.ibb.co/C6rrYdM/0066-003.webp" },
    { page: 4, link: "https://i.ibb.co/x8fJJF7/0066-004.webp" },
    { page: 5, link: "https://i.ibb.co/qDfwsSL/0066-005.webp" },
    { page: 6, link: "https://i.ibb.co/7Q7jW87/0066-006.webp" },
    { page: 7, link: "https://i.ibb.co/DCKxW6t/0066-007.webp" },
    { page: 8, link: "https://i.ibb.co/1fDq3Y0/0066-008.webp" },
    { page: 9, link: "https://i.ibb.co/gmGhCdH/0066-009.webp" },
    { page: 10, link: "https://i.ibb.co/Jn06fv4/0066-010.webp" },
    { page: 11, link: "https://i.ibb.co/NK5dVv6/0066-011.webp" },
    { page: 12, link: "https://i.ibb.co/yNFTsVf/0066-012.webp" },
    { page: 13, link: "https://i.ibb.co/pZR7DcN/0066-013.webp" },
    { page: 14, link: "https://i.ibb.co/9bDLySb/0066-014.webp" },
    { page: 15, link: "https://i.ibb.co/2hjsBbd/0066-015.webp" },
    { page: 16, link: "https://i.ibb.co/1XgJ97s/0066-016.webp" },
    { page: 17, link: "https://i.ibb.co/LxJrpJC/0066-017.webp" },
    { page: 18, link: "https://i.ibb.co/HqgnSsG/0066-018.webp" },
    { page: 19, link: "https://i.ibb.co/1nMHGnX/0066-019.webp" },
    { page: 20, link: "https://i.ibb.co/XbNrnPD/0066-020.webp" }
  ]
  

export default listePages;