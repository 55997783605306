/* eslint-disable default-case */
import { useParams } from 'react-router-dom';
import EpisodeAndList from '../components/EpisodeAndList';
import { Helmet } from 'react-helmet';

export default function Episode() {
    const param = useParams()
    const generationURL = param.generation
    const saisonURL = param.saison
    const langageURL = param.langue
    const episodeURL = param.episode

    let actualGeneration;
    let actualSaison;

    switch (generationURL) {
        case "bakuten":
            actualGeneration = "Bakuten Shoot Beyblade"
            switch (saisonURL) {
                case "saison1":
                    actualSaison = false;
                    break
                case "saison2":
                    actualSaison = "V-Force";
                    break
                case "saison3":
                    actualSaison = "G Revolution"
                    break
            }
            break
        case "metal":
            if (langageURL === "vf") {
                actualGeneration = "Beyblade Metal"
            } else if (langageURL === "vostfr") {
                actualGeneration = "Metal Fight Beyblade"
            }
            switch (saisonURL) {
                case "saison1":
                    if (langageURL === "vf") {
                        actualSaison = "Fusion"
                    } else if (langageURL === "vostfr") {
                        actualSaison = false;
                    }
                    break
                case "saison2":
                    if (langageURL === "vf") {
                        actualSaison = "Masters";
                    } else if (langageURL === "vostfr") {
                        actualSaison = ": Baku";
                    }
                    break
                case "saison3":
                    if (langageURL === "vf") {
                        actualSaison = "Fury"
                    } else if (langageURL === "vostfr") {
                        actualSaison = "4D"
                    }
                    break
                case "saison4":
                    if (langageURL === "vf") {
                        actualSaison = "Shogun Steel"
                    } else if (langageURL === "vostfr") {
                        actualSaison = "Zero G"
                    }
                    break
            }
            break
        case "burst":
            actualGeneration = "Beyblade Burst"
            switch (saisonURL) {
                case "saison1":
                    actualSaison = false;
                    break
                case "saison2":
                    if (langageURL === "vf") {
                        actualSaison = "Evolution";
                    } else if (langageURL === "vostfr") {
                        actualSaison = "God";
                    }
                    break
                case "saison3":
                    if (langageURL === "vf") {
                        actualSaison = "Turbo"
                    } else if (langageURL === "vostfr") {
                        actualSaison = "Cho-Z"
                    }
                    break
                case "saison4":
                    if (langageURL === "vf") {
                        actualSaison = "Rise"
                    } else if (langageURL === "vostfr") {
                        actualSaison = "GT"
                    }
                    break
                case "saison5":
                    if (langageURL === "vf") {
                        actualSaison = "Surge"
                    } else if (langageURL === "vostfr") {
                        actualSaison = "SuperKing"
                    }
                    break
                case "saison6":
                    if (langageURL === "vf") {
                        actualSaison = "QuadDrive"
                    } else if (langageURL === "vostfr") {
                        actualSaison = "Dynamite Battle"
                    }
                    break
                case "saison7":
                    actualSaison = "QuadStrike"
                    break
            }
            break
        case "x":
            actualGeneration = "Beyblade X"
            actualSaison = false
            break
        case "spinoff":
            actualGeneration = "BeyWheels"
            switch (saisonURL) {
                case "beywheels":
                    actualGeneration = "BeyWheels"
                    break
                case "beywarriors-cyborg":
                    actualGeneration = "BeyWarriors : Cyborg"
                    break
            }
            break
    }
 
    return (
        <>
            <Helmet>
                <title>{actualGeneration}{actualSaison ? ` ${actualSaison} ` : " "}{episodeURL} {langageURL.toUpperCase()} | Streaming Espace</title>
                <link rel="canonical" href={`https://www.streaming-espace.fr/${generationURL}/${saisonURL}/${langageURL}/${episodeURL}`}/>
                <meta name="description" content={"Découvre l'épisode " + episodeURL + " de " + actualGeneration + " " + actualSaison + " en " + langageURL.toUpperCase() + "."} />
            </Helmet>
            <main className="content container Episode">
                <EpisodeAndList />
            </main>                
        </>
    )       
}