const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/Hxxft2m/104-6-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/VM4S9Sh/104-6-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/1spdzp7/104-6-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/X5ttCcX/104-6-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/sWBddb7/104-6-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/DGmMdd5/104-6-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/1LpXb7q/104-6-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/H7tGDMJ/104-6-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/0yYk7V7/104-6-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/2nsm3pW/104-6-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/GtTMvbT/104-6-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/3r01ST2/104-6-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/St1nFfD/104-6-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/yh7939L/104-6-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/PgxMx1P/104-6-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/LdHzpBP/104-6-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/qdk9RcX/104-6-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/FnQXFf3/104-6-018.webp"
  }
]


export default listePages;