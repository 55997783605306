const listePages = [
    {
        "page": 1,
        "link": "https://i.ibb.co/ssPG29S/0168.webp"
      },
      {
        "page": 2,
        "link": "https://i.ibb.co/MRSSHqy/0169.webp"
      },
      {
        "page": 3,
        "link": "https://i.ibb.co/JQb6YT8/0170.webp"
      },
      {
        "page": 4,
        "link": "https://i.ibb.co/19b4x7t/0171.webp"
      },
      {
        "page": 5,
        "link": "https://i.ibb.co/phDmccC/0172.webp"
      },
      {
        "page": 6,
        "link": "https://i.ibb.co/Wc1VcQq/0173.webp"
      },
      {
        "page": 7,
        "link": "https://i.ibb.co/DgLhGH5/0174.webp"
      },
      {
        "page": 8,
        "link": "https://i.ibb.co/Ld45F2t/0175.webp"
      },
      {
        "page": 9,
        "link": "https://i.ibb.co/B2jvV82/0176.webp"
      },
      {
        "page": 10,
        "link": "https://i.ibb.co/1rSzQpg/0177.webp"
      },
      {
        "page": 11,
        "link": "https://i.ibb.co/4jR15m6/0178.webp"
      },
      {
        "page": 12,
        "link": "https://i.ibb.co/cLdWQm5/0179.webp"
      },
      {
        "page": 13,
        "link": "https://i.ibb.co/g7hJ8wg/0180.webp"
      },
      {
        "page": 14,
        "link": "https://i.ibb.co/JnkxcPy/0181.webp"
      },
      {
        "page": 15,
        "link": "https://i.ibb.co/4JNJqps/0182.webp"
      },
      {
        "page": 16,
        "link": "https://i.ibb.co/Wg3N62h/0183.webp"
      },
      {
        "page": 17,
        "link": "https://i.ibb.co/HVL65XD/0184.webp"
      },
      {
        "page": 18,
        "link": "https://i.ibb.co/YdyWFF2/0185.webp"
      },
      {
        "page": 19,
        "link": "https://i.ibb.co/0QDJWpT/0186.webp"
      },
      {
        "page": 20,
        "link": "https://i.ibb.co/BtBdyPy/0187.webp"
      },
      {
        "page": 21,
        "link": "https://i.ibb.co/84CNVnY/0188.webp"
      },
      {
        "page": 22,
        "link": "https://i.ibb.co/s3X6xng/0189.webp"
      },
      {
        "page": 23,
        "link": "https://i.ibb.co/gv2d145/0190.webp"
      },
      {
        "page": 24,
        "link": "https://i.ibb.co/wyYr5s3/0191.webp"
      },
      {
        "page": 25,
        "link": "https://i.ibb.co/BsFgp6N/0192.webp"
      }
]


export default listePages;