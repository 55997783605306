const listePages = [
  {
    "page": 1,
    "link": "https://i.ibb.co/2yVZQwm/Beyblade-X-Chapter-4-JPN-page-0001.webp"
  },
  {
    "page": 2,
    "link": "https://i.ibb.co/BnJ6Lgd/Beyblade-X-Chapter-4-JPN-page-0002.webp"
  },
  {
    "page": 3,
    "link": "https://i.ibb.co/8KBWHZT/Beyblade-X-Chapter-4-JPN-page-0003.webp"
  },
  {
    "page": 4,
    "link": "https://i.ibb.co/wdXyhqw/Beyblade-X-Chapter-4-JPN-page-0004.webp"
  },
  {
    "page": 5,
    "link": "https://i.ibb.co/Jk3R1Jj/Beyblade-X-Chapter-4-JPN-page-0005.webp"
  },
  {
    "page": 6,
    "link": "https://i.ibb.co/KNhqQct/Beyblade-X-Chapter-4-JPN-page-0007.webp"
  },
  {
    "page": 7,
    "link": "https://i.ibb.co/qBg79RS/Beyblade-X-Chapter-4-JPN-page-0008.webp"
  },
  {
    "page": 8,
    "link": "https://i.ibb.co/mhcL7rB/Beyblade-X-Chapter-4-JPN-page-0009.webp"
  },
  {
    "page": 9,
    "link": "https://i.ibb.co/2Px3KjM/Beyblade-X-Chapter-4-JPN-page-0010.webp"
  },
  {
    "page": 10,
    "link": "https://i.ibb.co/p3wx4sJ/Beyblade-X-Chapter-4-JPN-page-0011.webp"
  },
  {
    "page": 11,
    "link": "https://i.ibb.co/2YQFKjN/Beyblade-X-Chapter-4-JPN-page-0012.webp"
  },
  {
    "page": 12,
    "link": "https://i.ibb.co/sjHGm7v/Beyblade-X-Chapter-4-JPN-page-0013.webp"
  },
  {
    "page": 13,
    "link": "https://i.ibb.co/t26sVYg/Beyblade-X-Chapter-4-JPN-page-0014.webp"
  },
  {
    "page": 14,
    "link": "https://i.ibb.co/p3jQPVY/Beyblade-X-Chapter-4-JPN-page-0015.webp"
  },
  {
    "page": 15,
    "link": "https://i.ibb.co/t2MW0jw/Beyblade-X-Chapter-4-JPN-page-0016.webp"
  },
  {
    "page": 16,
    "link": "https://i.ibb.co/BB33788/Beyblade-X-Chapter-4-JPN-page-0017.webp"
  },
  {
    "page": 17,
    "link": "https://i.ibb.co/vVWLWv6/Beyblade-X-Chapter-4-JPN-page-0018.webp"
  },
  {
    "page": 18,
    "link": "https://i.ibb.co/1s8TZxs/Beyblade-X-Chapter-4-JPN-page-0019.webp"
  },
  {
    "page": 19,
    "link": "https://i.ibb.co/SvYm4Fc/Beyblade-X-Chapter-4-JPN-page-0021.webp"
  },
  {
    "page": 20,
    "link": "https://i.ibb.co/4dHNh6S/Beyblade-X-Chapter-4-JPN-page-0022.webp"
  },
  {
    "page": 21,
    "link": "https://i.ibb.co/PNpwMpS/Beyblade-X-Chapter-4-JPN-page-0023.webp"
  },
  {
    "page": 22,
    "link": "https://i.ibb.co/pXgSWCZ/Beyblade-X-Chapter-4-JPN-page-0024.webp"
  },
  {
    "page": 23,
    "link": "https://i.ibb.co/gRtr5wR/Beyblade-X-Chapter-4-JPN-page-0025.webp"
  },
  {
    "page": 24,
    "link": "https://i.ibb.co/B26SQ18/Beyblade-X-Chapter-4-JPN-page-0026.webp"
  },
  {
    "page": 25,
    "link": "https://i.ibb.co/ZddCKFN/Beyblade-X-Chapter-4-JPN-page-0027.webp"
  },
  {
    "page": 26,
    "link": "https://i.ibb.co/thFN4FM/Beyblade-X-Chapter-4-JPN-page-0029.webp"
  },
  {
    "page": 27,
    "link": "https://i.ibb.co/J5SbbFM/Beyblade-X-Chapter-4-JPN-page-0030.webp"
  },
  {
    "page": 28,
    "link": "https://i.ibb.co/6mN9NyC/Beyblade-X-Chapter-4-JPN-page-0031.webp"
  },
  {
    "page": 29,
    "link": "https://i.ibb.co/kJkfw5P/Beyblade-X-Chapter-4-JPN-page-0032.webp"
  },
  {
    "page": 30,
    "link": "https://i.ibb.co/3B0HTyj/Beyblade-X-Chapter-4-JPN-page-0033.webp"
  }
]

  
export default listePages;