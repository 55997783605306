const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/gdQ5YVS/0015-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/N965Vfj/0015-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/DRHw94L/0015-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/xD2zw3F/0015-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/0G7nBpJ/0015-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/yVPxNpS/0015-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/5GLNbZB/0015-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/f0dc7Tr/0015-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/BTT95xd/0015-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/LJX7SpZ/0015-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/FHT7qxz/0015-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/Bj5LKzc/0015-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/MgtG053/0015-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/txBJQcN/0015-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/GTnTykL/0015-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/c1jyFwc/0015-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/mTFkv61/0015-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/y6QLYHG/0015-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/xFzvCYJ/0015-019.webp"
  },
  {
    page: 20,
    link: "https://i.ibb.co/s1phfF0/0015-020.webp"
  },
  {
    page: 21,
    link: "https://i.ibb.co/C0Hftzq/0015-021.webp"
  },
  {
    page: 22,
    link: "https://i.ibb.co/qsGMQXD/0015-022.webp"
  },
  {
    page: 23,
    link: "https://i.ibb.co/jzgtcwn/0015-023.webp"
  },
  {
    page: 24,
    link: "https://i.ibb.co/3hsHBtP/0015-024.webp"
  },
  {
    page: 25,
    link: "https://i.ibb.co/4djj3RF/0015-025.webp"
  },
  {
    page: 26,
    link: "https://i.ibb.co/7zSKCXj/0015-026.webp"
  },
  {
    page: 27,
    link: "https://i.ibb.co/kM5PTLD/0015-027.webp"
  },
  {
    page: 28,
    link: "https://i.ibb.co/xFrs3r2/0015-028.webp"
  },
  {
    page: 29,
    link: "https://i.ibb.co/sHR6qHt/0015-029.webp"
  },
  {
    page: 30,
    link: "https://i.ibb.co/CHWCxDC/0015-030.webp"
  },
  {
    page: 31,
    link: "https://i.ibb.co/kXSN5vL/0015-031.webp"
  },
  {
    page: 32,
    link: "https://i.ibb.co/z6cHzyP/0015-032.webp"
  },
  {
    page: 33,
    link: "https://i.ibb.co/rkt2Ztr/0015-033.webp"
  },
  {
    page: 34,
    link: "https://i.ibb.co/g7NL80R/0015-034.webp"
  },
  {
    page: 35,
    link: "https://i.ibb.co/RgprQRF/0015-035.webp"
  },
  {
    page: 36,
    link: "https://i.ibb.co/dkNys0w/0015-036.webp"
  },
  {
    page: 37,
    link: "https://i.ibb.co/9vFBCYv/0015-037.webp"
  }
  
]

export default listePages;