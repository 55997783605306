const listeEpisodes = [
    {
        episode : 1,
        link : "https://www.youtube.com/embed/Qw1R5L1pqao"
    },
    {
        episode : 2,
        link : "https://www.youtube.com/embed/GfwZVqFnURg"
    },
    {
        episode : 3,
        link : "https://www.youtube.com/embed/er1xRbd94KI"
    },
    {
        episode : 4,
        link : "https://www.youtube.com/embed/Ch89kwvAIYE"
    },
    {
        episode : 5,
        link : "https://www.youtube.com/embed/ch87AI7AYuM"
    },
    {
        episode : 6,
        link : "https://www.youtube.com/embed/kciDGXMPmeU"
    },
    {
        episode : 7,
        link : "https://www.youtube.com/embed/EYcsJ-Zsm1Y"
    },
    {
        episode : 8,
        link : "https://www.youtube.com/embed/_sFXp5vncc0"
    },
    {
        episode : 9,
        link : "https://www.youtube.com/embed/cC7BPw1F-10"
    },
    {
        episode : 10,
        link : "https://www.youtube.com/embed/hdSDmcHcRXY"
    },
    {
        episode : 11,
        link : "https://www.youtube.com/embed/WTajD6yYjr4"
    },
    {
        episode : 12,
        link : "https://www.youtube.com/embed/eYe1Q4ENLEY"
    },
    {
        episode : 13,
        link : "https://www.youtube.com/embed/6rbzoaVC5Pg"
    }
]

export default listeEpisodes;