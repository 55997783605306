const listeEpisodes = [
    {
        episode : 1,
        link : "https://video.sibnet.ru/shell.php?videoid=5075780"
    },
    {
        episode : 2,
        link : "https://video.sibnet.ru/shell.php?videoid=5075784"
    },
    {
        episode : 3,
        link : "https://video.sibnet.ru/shell.php?videoid=5075785"
    },
    {
        episode : 4,
        link : "https://video.sibnet.ru/shell.php?videoid=5075793"
    },
    {
        episode : 5,
        link : "https://video.sibnet.ru/shell.php?videoid=5075795"
    },
    {
        episode : 6,
        link : "https://video.sibnet.ru/shell.php?videoid=5075798"
    },
    {
        episode : 7,
        link : "https://video.sibnet.ru/shell.php?videoid=5077414"
    },
    {
        episode : 8,
        link : "https://video.sibnet.ru/shell.php?videoid=5077416"
    },
    {
        episode : 9,
        link : "https://video.sibnet.ru/shell.php?videoid=5077418"
    },
    {
        episode : 10,
        link : "https://video.sibnet.ru/shell.php?videoid=5077420"
    },
    {
        episode : 11,
        link : "https://video.sibnet.ru/shell.php?videoid=5077440"
    },
    {
        episode : 12,
        link : "https://video.sibnet.ru/shell.php?videoid=5077441"
    },
    {
        episode : 13,
        link : "https://video.sibnet.ru/shell.php?videoid=5077442"
    },
    {
        episode : 14,
        link : "https://video.sibnet.ru/shell.php?videoid=5077449"
    },
    {
        episode : 15,
        link : "https://video.sibnet.ru/shell.php?videoid=5077450"
    },
    {
        episode : 16,
        link : "https://video.sibnet.ru/shell.php?videoid=5077459"
    },
    {
        episode : 17,
        link : "https://video.sibnet.ru/shell.php?videoid=5077462"
    },
    {
        episode : 18,
        link : "https://video.sibnet.ru/shell.php?videoid=5077471"
    },
    {
        episode : 19,
        link : "https://video.sibnet.ru/shell.php?videoid=5077472"
    },
    {
        episode : 20,
        link : "https://video.sibnet.ru/shell.php?videoid=5077474"
    },
    {
        episode : 21,
        link : "https://video.sibnet.ru/shell.php?videoid=5077485"
    },
    {
        episode : 22,
        link : "https://video.sibnet.ru/shell.php?videoid=5077486"
    },
    {
        episode : 23,
        link : "https://video.sibnet.ru/shell.php?videoid=5077492"
    },
    {
        episode : 24,
        link : "https://video.sibnet.ru/shell.php?videoid=5077494"
    },
    {
        episode : 25,
        link : "https://video.sibnet.ru/shell.php?videoid=5077496"
    },
    {
        episode : 26,
        link : "https://video.sibnet.ru/shell.php?videoid=5077498"
    },
    {
        episode : 27,
        link : "https://video.sibnet.ru/shell.php?videoid=5077500"
    },
    {
        episode : 28,
        link : "https://video.sibnet.ru/shell.php?videoid=5077502"
    },
    {
        episode : 29,
        link : "https://video.sibnet.ru/shell.php?videoid=5077504"
    },
    {
        episode : 30,
        link : "https://video.sibnet.ru/shell.php?videoid=5077505"
    },
    {
        episode : 31,
        link : "https://video.sibnet.ru/shell.php?videoid=5077508"
    },
    {
        episode : 32,
        link : "https://video.sibnet.ru/shell.php?videoid=5076123"
    },
    {
        episode : 33,
        link : "https://video.sibnet.ru/shell.php?videoid=5077510"
    },
    {
        episode : 34,
        link : "https://video.sibnet.ru/shell.php?videoid=5077511"
    },
    {
        episode : 35,
        link : "https://video.sibnet.ru/shell.php?videoid=5077512"
    },
    {
        episode : 36,
        link : "https://video.sibnet.ru/shell.php?videoid=5077515"
    },
    {
        episode : 37,
        link : "https://video.sibnet.ru/shell.php?videoid=5077516"
    },
    {
        episode : 38,
        link : "https://video.sibnet.ru/shell.php?videoid=5077519"
    },
    {
        episode : 39,
        link : "https://video.sibnet.ru/shell.php?videoid=5077520"
    },
    {
        episode : 40,
        link : "https://video.sibnet.ru/shell.php?videoid=5077522"
    },
    {
        episode : 41,
        link : "https://video.sibnet.ru/shell.php?videoid=5077523"
    },
    {
        episode : 42,
        link : "https://video.sibnet.ru/shell.php?videoid=5077528"
    },
    {
        episode : 43,
        link : "https://video.sibnet.ru/shell.php?videoid=5077530"
    },
    {
        episode : 44,
        link : "https://video.sibnet.ru/shell.php?videoid=5077531"
    },
    {
        episode : 45,
        link : "https://video.sibnet.ru/shell.php?videoid=5077538"
    },
    {
        episode : 46,
        link : "https://video.sibnet.ru/shell.php?videoid=5077541"
    },
    {
        episode : 47,
        link : "https://video.sibnet.ru/shell.php?videoid=5077543"
    },
    {
        episode : 48,
        link : "https://video.sibnet.ru/shell.php?videoid=5077547"
    },
    {
        episode : 49,
        link : "https://video.sibnet.ru/shell.php?videoid=5077549"
    },
    {
        episode : 50,
        link : "https://video.sibnet.ru/shell.php?videoid=5077550"
    },
    {
        episode : 51,
        link : "https://video.sibnet.ru/shell.php?videoid=5077555"
    }
]

export default listeEpisodes;