const listePages = [
    { page: 1, link: "https://i.ibb.co/7KrckxQ/0080-001.webp" },
    { page: 2, link: "https://i.ibb.co/RPhBP7y/0080-002.webp" },
    { page: 3, link: "https://i.ibb.co/G0Rkj8F/0080-003.webp" },
    { page: 4, link: "https://i.ibb.co/WGNvG8h/0080-004.webp" },
    { page: 5, link: "https://i.ibb.co/JCc7Twt/0080-005.webp" },
    { page: 6, link: "https://i.ibb.co/gTvDMk7/0080-006.webp" },
    { page: 7, link: "https://i.ibb.co/F3kkyH3/0080-007.webp" },
    { page: 8, link: "https://i.ibb.co/k0ypHCh/0080-008.webp" },
    { page: 9, link: "https://i.ibb.co/TRcngYb/0080-009.webp" },
    { page: 10, link: "https://i.ibb.co/4JYjy1R/0080-010.webp" },
    { page: 11, link: "https://i.ibb.co/CHbs81y/0080-011.webp" },
    { page: 12, link: "https://i.ibb.co/w4WDXWs/0080-012.webp" },
    { page: 13, link: "https://i.ibb.co/fFMbRKq/0080-013.webp" },
    { page: 14, link: "https://i.ibb.co/wdyhWNQ/0080-014.webp" },
    { page: 15, link: "https://i.ibb.co/cTS7WTM/0080-015.webp" },
    { page: 16, link: "https://i.ibb.co/DkwWV19/0080-016.webp" },
    { page: 17, link: "https://i.ibb.co/ss08sWz/0080-017.webp" },
    { page: 18, link: "https://i.ibb.co/gMXdjbF/0080-018.webp" },
    { page: 19, link: "https://i.ibb.co/PFgtkqK/0080-019.webp" },
    { page: 20, link: "https://i.ibb.co/1dmqC2B/0080-020.webp" },
    { page: 21, link: "https://i.ibb.co/b7QWXSL/0080-021.webp" },
    { page: 22, link: "https://i.ibb.co/nDXGqpG/0080-022.webp" },
    { page: 23, link: "https://i.ibb.co/XzschSh/0080-023.webp" },
    { page: 24, link: "https://i.ibb.co/chzm4cM/0080-024.webp" },
    { page: 25, link: "https://i.ibb.co/CP4c9q9/0080-025.webp" },
    { page: 26, link: "https://i.ibb.co/pw6dY5D/0080-026.webp" },
    { page: 27, link: "https://i.ibb.co/BPLVYyM/0080-027.webp" },
    { page: 28, link: "https://i.ibb.co/tBPK0Fw/0080-028.webp" },
    { page: 29, link: "https://i.ibb.co/0c2bFLj/0080-029.webp" }
  ]
  

export default listePages;