const listeEpisodes = [
    {
        episode : "trailer",
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239172&hash=83056ec755e754b2"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929958"
            }
        ]
    },
    {
        episode : 1,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239120&hash=8f7a877dae6ac502"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929839"
            }
        ]
    },
    {
        episode : 2,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239121&hash=30e3ea424f1c9d76"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929841"
            }
        ]
    },
    {
        episode : 3,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239122&hash=bb8dda4d87414685"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929843"
            }
        ]
    },
    {
        episode : 4,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239123&hash=0591c39f7e99b4d0"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929844"
            }
        ]
    },
    {
        episode : 5,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239124&hash=2eef28b40ca37755"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929845"
            }
        ]
    },
    {
        episode : 6,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239125&hash=149f22e5ec7c6b81"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929846"
            }
        ]
    },
    {
        episode : 7,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239126&hash=bbd1bebf7d484216"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929847"
            }
        ]
    },
    {
        episode : 8,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239127&hash=1fb4d35ac2e3c7dd"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929848"
            }
        ]
    },
    {
        episode : 9,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239128&hash=e5f6bbd5a89dbeb5"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929850"
            }
        ]
    },
    {
        episode : 10,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239129&hash=7a63f42f2e9f7d87"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929856"
            }
        ]
    },
    {
        episode : 11,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239130&hash=dc884c798e41384a"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929858"
            }
        ]
    },
    {
        episode : 12,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239131&hash=725cd6cc36b80146"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929860"
            }
        ]
    },
    {
        episode : 13,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239132&hash=b0f54803829186ca"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929862"
            }
        ]
    },
    {
        episode : 14,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239133&hash=7caea54fc7cf827c"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929864"
            }
        ]
    },
    {
        episode : 15,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239134&hash=0d50f24dcf7922aa"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929866"
            }
        ]
    },
    {
        episode : 16,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239135&hash=423b9695594f953f"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929869"
            }
        ]
    },
    {
        episode : 17,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239136&hash=4a51c749dc70aee6"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929870"
            }
        ]
    },
    {
        episode : 18,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239137&hash=6fa57a6695d60682"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929872"
            }
        ]
    },
    {
        episode : 19,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239138&hash=f3d01ee43fa51406"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929877"
            }
        ]
    },
    {
        episode : 20,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239139&hash=70f44cc70314790d"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929879"
            }
        ]
    },
    {
        episode : 21,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239140&hash=513898d1a06ee520"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929887"
            }
        ]
    },
    {
        episode : 22,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239141&hash=a514fe6111b1cc8c"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929889"
            }
        ]
    },
    {
        episode : 23,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239142&hash=ddff9dbade3c5d19"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929890"
            }
        ]
    },
    {
        episode : 24,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239143&hash=f58ef9e1d7172bba"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929893"
            }
        ]
    },
    {
        episode : 25,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239144&hash=0f8d198782c1d6c0"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929894"
            }
        ]
    },
    {
        episode : 26,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239145&hash=48b30d505a034a65"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929895"
            }
        ]
    },
    {
        episode : 27,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239146&hash=9fc0194707613882"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929902"
            }
        ]
    },
    {
        episode : 28,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239147&hash=b3bf153dc5451c8b"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929906"
            }
        ]
    },
    {
        episode : 29,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239148&hash=1e772f2e4f9121df"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929908"
            }
        ]
    },
    {
        episode : 30,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239149&hash=671ac308505e5485"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929909"
            }
        ]
    },
    {
        episode : 31,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239150&hash=dc48121254ef9704"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929913"
            }
        ]
    },
    {
        episode : 32,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239151&hash=84892a86e23f184a"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929916"
            }
        ]
    },
    {
        episode : 33,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239152&hash=0e1b932f112ab222"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929917"
            }
        ]
    },
    {
        episode : 34,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239153&hash=834c3d2d43be6b64"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929922"
            }
        ]
    },
    {
        episode : 35,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239154&hash=8844975acf0ec39c"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929925"
            }
        ]
    },
    {
        episode : 36,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239155&hash=b7225f85ff09261f"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929927"
            }
        ]
    },
    {
        episode : 37,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239156&hash=87f3aba5d2bad19f"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929929"
            }
        ]
    },
    {
        episode : 38,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239157&hash=3fca9b6d49060a3b"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929930"
            }
        ]
    },
    {
        episode : 39,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239158&hash=280e2b77a265170d"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929938"
            }
        ]
    },
    {
        episode : 40,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239159&hash=91c6aee5bbcce382"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929939"
            }
        ]
    },
    {
        episode : 41,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239160&hash=14d1aa36361d959b"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929941"
            }
        ]
    },
    {
        episode : 42,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239161&hash=b20b16584e7d3ccf"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929942"
            }
        ]
    },
    {
        episode : 43,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239162&hash=771378678f6ba130"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929948"
            }
        ]
    },
    {
        episode : 44,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239163&hash=520fe1051cbfb7d2"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929949"
            }
        ]
    },
    {
        episode : 45,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239164&hash=2583e9dcf76b8d98"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929950"
            }
        ]
    },
    {
        episode : 46,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239165&hash=1b8afdfc9afd8c3d"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929952"
            }
        ]
    },
    {
        episode : 47,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239166&hash=71a81ade10621ca1"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929953"
            }
        ]
    },
    {
        episode : 48,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239167&hash=43dd813dd30ca6ef"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929954"
            }
        ]
    },
    {
        episode : 49,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239168&hash=9370f7c76078c437"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929955"
            }
        ]
    },
    {
        episode : 50,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239169&hash=1cba20360be04c5b"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929956"
            }
        ]
    },
    {
        episode : 51,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239170&hash=1ef6b418f7e84230"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929957"
            }
        ]
    },
    {
        episode : 52,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239171&hash=9c030ea28c1f669b"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5395309"
            }
        ]
    }
]

export default listeEpisodes;