const listeEpisodes = [
    {
        episode : 1,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=646754736&id=456239019&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/52vi80ra"
            }
        ]
    },
    {
        episode : 2,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=646754736&id=456239021&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/vcfp180o"
            },
        ]
    },
    // {
    //     episode : 3,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink: ""
    // },
    // {
    //     episode : 4,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink: ""
    // },
    // {
    //     episode : 5,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink: ""
    // },
    // {
    //     episode : 6,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink: ""
    // },
    // {
    //     episode : 7,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink: ""
    // },
    // {
    //     episode : 8,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink: ""
    // },
    // {
    //     episode : 9,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink: ""
    // },
    // {
    //     episode : 10,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink: ""
    // },
    // {
    //     episode : 11,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink: ""
    // },
]

export default listeEpisodes;