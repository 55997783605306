const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/WG54YJ9/0010-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/fqrpZ7f/0010-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/vJtqYrJ/0010-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/NZJ1Dgr/0010-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/nP7Bb2z/0010-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/ChXbfbH/0010-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/SmkQZQT/0010-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/VpcbvV1/0010-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/xMkfv7m/0010-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/9YX4XQS/0010-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/L5kjqvv/0010-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/nbHsyRG/0010-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/qC32SRG/0010-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/QpQ8rHy/0010-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/zGFt9Dc/0010-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/XtXYgXN/0010-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/zN1rw97/0010-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/ykqNngD/0010-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/Wc2KPYC/0010-019.webp"
  },
  {
    page: 20,
    link: "https://i.ibb.co/ScQQn6T/0010-020.webp"
  },
  {
    page: 21,
    link: "https://i.ibb.co/pQw7BRn/0010-021.webp"
  },
  {
    page: 22,
    link: "https://i.ibb.co/NSMvWLT/0010-022.webp"
  },
  {
    page: 23,
    link: "https://i.ibb.co/2hp0tHg/0010-023.webp"
  },
  {
    page: 24,
    link: "https://i.ibb.co/yQhCPPL/0010-024.webp"
  },
  {
    page: 25,
    link: "https://i.ibb.co/MpdbWfk/0010-025.webp"
  },
  {
    page: 26,
    link: "https://i.ibb.co/5WP8QXW/0010-026.webp"
  },
  {
    page: 27,
    link: "https://i.ibb.co/6PShKcT/0010-027.webp"
  },
  {
    page: 28,
    link: "https://i.ibb.co/GTBYQPS/0010-028.webp"
  },
  {
    page: 29,
    link: "https://i.ibb.co/bQQP8Js/0010-029.webp"
  },
  {
    page: 30,
    link: "https://i.ibb.co/H701R3V/0010-030.webp"
  },
  {
    page: 31,
    link: "https://i.ibb.co/XJ4dHvY/0010-031.webp"
  },
  {
    page: 32,
    link: "https://i.ibb.co/84xrJ0x/0010-032.webp"
  },
  {
    page: 33,
    link: "https://i.ibb.co/YcxWyW2/0010-033.webp"
  },
  {
    page: 34,
    link: "https://i.ibb.co/MkfD5Fd/0010-034.webp"
  },
  {
    page: 35,
    link: "https://i.ibb.co/pJ427gC/0010-035.webp"
  },
  {
    page: 36,
    link: "https://i.ibb.co/5GBxCWW/0010-036.webp"
  },
  {
    page: 37,
    link: "https://i.ibb.co/xXyrJbG/0010-037.webp"
  },
  {
    page: 38,
    link: "https://i.ibb.co/v4MV1rR/0010-038.webp"
  },
  {
    page: 39,
    link: "https://i.ibb.co/ZhTH2Sv/0010-039.webp"
  },
  {
    page: 40,
    link: "https://i.ibb.co/MRXdDtT/0010-040.webp"
  },
  {
    page: 41,
    link: "https://i.ibb.co/DQwVTLW/0010-041.webp"
  },
  {
    page: 42,
    link: "https://i.ibb.co/HgLfscs/0010-042.webp"
  },
  {
    page: 43,
    link: "https://i.ibb.co/C9Km4QD/0010-043.webp"
  },
  {
    page: 44,
    link: "https://i.ibb.co/d4yDmRG/0010-044.webp"
  },
  {
    page: 45,
    link: "https://i.ibb.co/QmcZQF7/0010-045.webp"
  },
  {
    page: 46,
    link: "https://i.ibb.co/VtpzcR6/0010-046.webp"
  },
  {
    page: 47,
    link: "https://i.ibb.co/T2SrRGY/0010-047.webp"
  },
  {
    page: 48,
    link: "https://i.ibb.co/93VWHr9/0010-048.webp"
  },
  {
    page: 49,
    link: "https://i.ibb.co/Nm3MQFz/0010-049.webp"
  },
  {
    page: 50,
    link: "https://i.ibb.co/ynwbtVm/0010-050.webp"
  },
  {
    page: 51,
    link: "https://i.ibb.co/nR2zk1r/0010-051.webp"
  },
  {
    page: 52,
    link: "https://i.ibb.co/CtgbD59/0010-052.webp"
  },
  {
    page: 53,
    link: "https://i.ibb.co/CvzsnS5/0010-053.webp"
  },
  {
    page: 54,
    link: "https://i.ibb.co/09V7r7z/0010-054.webp"
  },
  {
    page: 55,
    link: "https://i.ibb.co/ZTs9rWn/0010-055.webp"
  },
  {
    page: 56,
    link: "https://i.ibb.co/fHX0tzt/0010-056.webp"
  },
  {
    page: 57,
    link: "https://i.ibb.co/h2m7NWG/0010-057.webp"
  },
  {
    page: 58,
    link: "https://i.ibb.co/LgrCGMs/0010-058.webp"
  },
  {
    page: 59,
    link: "https://i.ibb.co/61v52QX/0010-059.webp"
  },
  {
    page: 60,
    link: "https://i.ibb.co/SxhqPfZ/0010-060.webp"
  },
  {
    page: 61,
    link: "https://i.ibb.co/n1srrqV/0010-061.webp"
  },
  {
    page: 62,
    link: "https://i.ibb.co/YhxrVgz/0010-062.webp"
  },
  {
    page: 63,
    link: "https://i.ibb.co/dKXqmb8/0010-063.webp"
  },
  {
    page: 64,
    link: "https://i.ibb.co/3stw9bF/0010-064.webp"
  },
  {
    page: 65,
    link: "https://i.ibb.co/sg8g6vw/0010-065.webp"
  },
  {
    page: 66,
    link: "https://i.ibb.co/cNc4zzg/0010-066.webp"
  },
  {
    page: 67,
    link: "https://i.ibb.co/JjXj3xT/0010-067.webp"
  },
  {
    page: 68,
    link: "https://i.ibb.co/6vHr4gc/0010-068.webp"
  },
  {
    page: 69,
    link: "https://i.ibb.co/WDm2F2p/0010-069.webp"
  },
  {
    page: 70,
    link: "https://i.ibb.co/xF9whdK/0010-070.webp"
  },
  {
    page: 71,
    link: "https://i.ibb.co/9YYfMcg/0010-071.webp"
  },
  {
    page: 72,
    link: "https://i.ibb.co/9rM69y0/0010-072.webp"
  }
]


export default listePages;