const listePages = [
    {
        page: 1,
        link: "https://i.ibb.co/tZrJNG2/0123.webp"
      },
      {
        page: 2,
        link: "https://i.ibb.co/ZXsmLLP/0124.webp"
      },
      {
        page: 3,
        link: "https://i.ibb.co/nPH3Wbv/0125.webp"
      },
      {
        page: 4,
        link: "https://i.ibb.co/K7qkFWf/0126.webp"
      },
      {
        page: 5,
        link: "https://i.ibb.co/C2Ztyyq/0127.webp"
      },
      {
        page: 6,
        link: "https://i.ibb.co/Mf3hR4Y/0128.webp"
      },
      {
        page: 7,
        link: "https://i.ibb.co/WnWWsfj/0129.webp"
      },
      {
        page: 8,
        link: "https://i.ibb.co/tqmkqZ9/0130.webp"
      },
      {
        page: 9,
        link: "https://i.ibb.co/NVDps0N/0131.webp"
      },
      {
        page: 10,
        link: "https://i.ibb.co/BffSyb4/0132.webp"
      },
      {
        page: 11,
        link: "https://i.ibb.co/0GhfFm4/0133.webp"
      },
      {
        page: 12,
        link: "https://i.ibb.co/3ppkgdF/0134.webp"
      },
      {
        page: 13,
        link: "https://i.ibb.co/vkDL52L/0135.webp"
      },
      {
        page: 14,
        link: "https://i.ibb.co/Rp5pzMM/0136.webp"
      },
      {
        page: 15,
        link: "https://i.ibb.co/NncYZP4/0137.webp"
      },
      {
        page: 16,
        link: "https://i.ibb.co/ccPVdWb/0138.webp"
      },
      {
        page: 17,
        link: "https://i.ibb.co/1q0Zh3f/0139.webp"
      },
      {
        page: 18,
        link: "https://i.ibb.co/KxQ5LcC/0140.webp"
      },
      {
        page: 19,
        link: "https://i.ibb.co/DfRWk1K/0141.webp"
      },
      {
        page: 20,
        link: "https://i.ibb.co/rGY5Zk5/0142.webp"
      },
      {
        page: 21,
        link: "https://i.ibb.co/rkRCwkP/0143.webp"
      },
      {
        page: 22,
        link: "https://i.ibb.co/8xzDkfW/0144.webp"
      },
      {
        page: 23,
        link: "https://i.ibb.co/GVG69nY/0145.webp"
      },
      {
        page: 24,
        link: "https://i.ibb.co/dM6tQB2/0146.webp"
      },
      {
        page: 25,
        link: "https://i.ibb.co/ynnz2pT/0147.webp"
      },
      {
        page: 26,
        link: "https://i.ibb.co/jhf72xr/0148.webp"
      },
      {
        page: 27,
        link: "https://i.ibb.co/pv6459Z/0149.webp"
      },
      {
        page: 28,
        link: "https://i.ibb.co/Z6Dx3H6/0150.webp"
      },
      {
        page: 29,
        link: "https://i.ibb.co/tK4HnSC/0151.webp"
      },
      {
        page: 30,
        link: "https://i.ibb.co/hfQrDVJ/0152.webp"
      },
      {
        page: 31,
        link: "https://i.ibb.co/TkwjSMz/0153.webp"
      },
      {
        page: 32,
        link: "https://i.ibb.co/d49ML8Q/0154.webp"
      },
      {
        page: 33,
        link: "https://i.ibb.co/7kkL5pN/0155.webp"
      },
      {
        page: 34,
        link: "https://i.ibb.co/ZdWbyq5/0156.webp"
      },
      {
        page: 35,
        link: "https://i.ibb.co/7WKfm8t/0157.webp"
      }
]


export default listePages;