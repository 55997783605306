const listePages = [
    { page: 1, link: "https://i.ibb.co/tLgRJFK/0068-001.webp" },
    { page: 2, link: "https://i.ibb.co/mBJPNyY/0068-002.webp" },
    { page: 3, link: "https://i.ibb.co/7C4vRX1/0068-003.webp" },
    { page: 4, link: "https://i.ibb.co/qB5JNXh/0068-004.webp" },
    { page: 5, link: "https://i.ibb.co/CMmYFH0/0068-005.webp" },
    { page: 6, link: "https://i.ibb.co/KyRKQCw/0068-006.webp" },
    { page: 7, link: "https://i.ibb.co/5LbxbDK/0068-007.webp" },
    { page: 8, link: "https://i.ibb.co/kKBPyzZ/0068-008.webp" },
    { page: 9, link: "https://i.ibb.co/vjNbh7h/0068-009.webp" },
    { page: 10, link: "https://i.ibb.co/pnZrPHn/0068-010.webp" },
    { page: 11, link: "https://i.ibb.co/NpbsRLV/0068-011.webp" },
    { page: 12, link: "https://i.ibb.co/17yRsfp/0068-012.webp" },
    { page: 13, link: "https://i.ibb.co/DrjKLk9/0068-013.webp" },
    { page: 14, link: "https://i.ibb.co/ZV5N3wT/0068-014.webp" },
    { page: 15, link: "https://i.ibb.co/wg2pyS4/0068-015.webp" },
    { page: 16, link: "https://i.ibb.co/BrL71hP/0068-016.webp" },
    { page: 17, link: "https://i.ibb.co/k6tN55s/0068-017.webp" },
    { page: 18, link: "https://i.ibb.co/SJTdbvV/0068-018.webp" },
    { page: 19, link: "https://i.ibb.co/QvMVBQ6/0068-019.webp" },
    { page: 20, link: "https://i.ibb.co/4V8kJVV/0068-020.webp" },
    { page: 21, link: "https://i.ibb.co/FVzGJ9z/0068-021.webp" },
    { page: 22, link: "https://i.ibb.co/gSb7FtF/0068-022.webp" },
    { page: 23, link: "https://i.ibb.co/LC6FMSC/0068-023.webp" },
    { page: 24, link: "https://i.ibb.co/CH3XnTw/0068-024.webp" },
    { page: 25, link: "https://i.ibb.co/j62TWQb/0068-025.webp" },
    { page: 26, link: "https://i.ibb.co/rdnR6J2/0068-026.webp" },
    { page: 27, link: "https://i.ibb.co/0Kxx3Wy/0068-027.webp" },
    { page: 28, link: "https://i.ibb.co/m9vmVQ0/0068-028.webp" },
    { page: 29, link: "https://i.ibb.co/SyM7JFY/0068-029.webp" },
    { page: 30, link: "https://i.ibb.co/WcPKm1H/0068-030.webp" },
    { page: 31, link: "https://i.ibb.co/d6H3jtN/0068-031.webp" },
    { page: 32, link: "https://i.ibb.co/R0Rn9ZK/0068-032.webp" }
  ]
  

export default listePages;