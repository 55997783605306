const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/hZBnNGX/0014-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/9qhHDZv/0014-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/Y7M0Hjx/0014-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/SdvCJmj/0014-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/s5w3R25/0014-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/2gmXM2F/0014-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/gtxVW1j/0014-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/PrCyrbV/0014-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/hc9fgdf/0014-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/XLWW8x6/0014-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/qnp1bMk/0014-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/3pg63RM/0014-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/b587N2D/0014-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/6B6Pw5F/0014-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/6vjzypV/0014-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/VwR0ZZP/0014-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/v4dLFL9/0014-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/GT5Z7vt/0014-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/GMb9RmX/0014-019.webp"
  }
]

export default listePages;