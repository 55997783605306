const listeEpisodes = [
    {
        episode: 1,
        link: "https://www.dailymotion.com/embed/video/x565rz0"
    },
    {
        episode: 2,
        link: "https://www.dailymotion.com/embed/video/x5goiuq"
    },
    {
        episode: 3,
        link: "https://www.dailymotion.com/embed/video/x5m8eul"
    },
    {
        episode: 4,
        link: "https://www.dailymotion.com/embed/video/x5o2pi8"
    },
    {
        episode: 5,
        link: "https://www.dailymotion.com/embed/video/x5o4yxe"
    },
    {
        episode: 6,
        link: "https://www.dailymotion.com/embed/video/x5o692i"
    },
    {
        episode: 7,
        link: "https://www.dailymotion.com/embed/video/x5zqhof"
    },
    {
        episode: 8,
        link: "https://www.dailymotion.com/embed/video/x6hzhsg"
    },
    {
        episode: 9,
        link: "https://www.dailymotion.com/embed/video/x6k523v"
    },
    {
        episode: 10,
        link: "https://www.dailymotion.com/embed/video/x6sxvmt"
    },
    {
        episode: 11,
        link: "https://www.dailymotion.com/embed/video/x6toxgo"
    },
    {
        episode: 12,
        link: "https://www.dailymotion.com/embed/video/x6to00o"
    },
    {
        episode: 13,
        link: "https://www.dailymotion.com/embed/video/x6wdvpv"
    },
    {
        episode: 14,
        link: "https://www.dailymotion.com/embed/video/x6wfg56"
    },
    {
        episode: 15,
        link: "https://www.dailymotion.com/embed/video/x6whf1f"
    },
    {
        episode: 16,
        link: "https://www.dailymotion.com/embed/video/x6woktd"
    },
    {
        episode: 17,
        link: "https://www.dailymotion.com/embed/video/x6x6npu"
    },
    {
        episode: 18,
        link: "https://www.dailymotion.com/embed/video/x6zt0xy"
    },
    {
        episode: 19,
        link: "https://www.dailymotion.com/embed/video/x6z73ot"
    },
    {
        episode: 20,
        link: "https://www.dailymotion.com/embed/video/x6zdlis"
    },
    {
        episode: 21,
        link: "https://www.dailymotion.com/embed/video/x6zzy11"
    },
    {
        episode: 22,
        link: "https://www.dailymotion.com/embed/video/x705zhq"
    },
    {
        episode: 23,
        link: "https://www.dailymotion.com/embed/video/x709uhh"
    },
    {
        episode: 24,
        link: "https://www.dailymotion.com/embed/video/x70obo3"
    },
    {
        episode: 25,
        link: "https://www.dailymotion.com/embed/video/x70tl6m"
    },
    {
        episode: 26,
        link: "https://www.dailymotion.com/embed/video/x70v2x6"
    },
    {
        episode: 27,
        link: "https://www.dailymotion.com/embed/video/x7184t1"
    },
    {
        episode: 28,
        link: "https://www.dailymotion.com/embed/video/x71bwl0"
    },
    {
        episode: 29,
        link: "https://www.dailymotion.com/embed/video/x71cayu"
    },
    {
        episode: 30,
        link: "https://www.dailymotion.com/embed/video/x71mhl8"
    },
    {
        episode: 31,
        link: "https://www.dailymotion.com/embed/video/x6lbbee"
    },
    {
        episode: 32,
        link: "https://www.dailymotion.com/embed/video/x6n2bk4"
    },
    {
        episode: 33,
        link: "https://www.dailymotion.com/embed/video/x6otazy"
    },
    {
        episode: 34,
        link: "https://www.dailymotion.com/embed/video/x6ptj8c"
    },
    {
        episode: 35,
        link: "https://www.dailymotion.com/embed/video/x6t7k3y"
    },
    {
        episode: 36,
        link: "https://www.dailymotion.com/embed/video/x6ue8ay"
    },
    {
        episode: 37,
        link: "https://www.dailymotion.com/embed/video/x6ujxdp"
    },
    {
        episode: 38,
        link: "https://www.dailymotion.com/embed/video/x6v1s1u"
    },
    {
        episode: 39,
        link: "https://www.dailymotion.com/embed/video/x6va8m4"
    },
    {
        episode: 40,
        link: "https://www.dailymotion.com/embed/video/x6vu0da"
    },
    {
        episode: 41,
        link: "https://www.dailymotion.com/embed/video/x6vvoji"
    },
    {
        episode: 42,
        link: "https://www.dailymotion.com/embed/video/x6w83al"
    },
    {
        episode: 43,
        link: "https://www.dailymotion.com/embed/video/x6wbdl3"
    },
    {
        episode: 44,
        link: "https://www.dailymotion.com/embed/video/x7qt2fk"
    },
    {
        episode: 45,
        link: "https://www.dailymotion.com/embed/video/x6zfbhx"
    },
    {
        episode: 46,
        link: "https://www.dailymotion.com/embed/video/x70zk3c"
    },
    {
        episode: 47,
        link: "https://www.dailymotion.com/embed/video/x71o801"
    },
    {
        episode: 48,
        link: "https://www.dailymotion.com/embed/video/x71pwr3"
    },
    {
        episode: 49,
        link: "https://www.dailymotion.com/embed/video/x71qoed"
    },
    {
        episode: 50,
        link: "https://www.dailymotion.com/embed/video/x71u93t"
    },
    {
        episode: 51,
        link: "https://www.dailymotion.com/embed/video/x71w301"
    }
]

export default listeEpisodes;