const listePages = [
    { page: 1, link: "https://i.ibb.co/091kjy9/0082-001.webp" },
    { page: 2, link: "https://i.ibb.co/TmTYFsc/0082-002.webp" },
    { page: 3, link: "https://i.ibb.co/4PvNB1G/0082-003.webp" },
    { page: 4, link: "https://i.ibb.co/pJDLVqW/0082-004.webp" },
    { page: 5, link: "https://i.ibb.co/6XSpGg5/0082-005.webp" },
    { page: 6, link: "https://i.ibb.co/hfSh3Gn/0082-006.webp" },
    { page: 7, link: "https://i.ibb.co/Pmk0ZGY/0082-007.webp" },
    { page: 8, link: "https://i.ibb.co/0KKrLH4/0082-008.webp" },
    { page: 9, link: "https://i.ibb.co/5hf9vZm/0082-009.webp" },
    { page: 10, link: "https://i.ibb.co/1Lb5R0V/0082-010.webp" },
    { page: 11, link: "https://i.ibb.co/D4gCLKX/0082-011.webp" },
    { page: 12, link: "https://i.ibb.co/6s6TmCw/0082-012.webp" },
    { page: 13, link: "https://i.ibb.co/YBTkjgc/0082-013.webp" },
    { page: 14, link: "https://i.ibb.co/Pwn4X7r/0082-014.webp" },
    { page: 15, link: "https://i.ibb.co/bvy4cbX/0082-015.webp" },
    { page: 16, link: "https://i.ibb.co/z4m9d8q/0082-016.webp" }
  ]
  

export default listePages;