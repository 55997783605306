const listeSaisons = [
    {
        id : 1,
        titre : "Beyblade X",
        saison : "Saison 1",
        url : "saison1",
        img : "https://i.ibb.co/mbC48fn/beyximg.webp",
        synopsis : "L'intrigue de l'anime se concentre sur Kasami Bird, qui aspire à devenir un joueur professionnel de Beyblade. Il veut aller à la X Tower, où se réunissent les joueurs professionnels de Beyblade.",
        vostfr : true,
        vostfrlink : "/x/saison1/vostfr",
        vf : true,
        vflink : "/x/saison1/vf",
    },
    {
        id : 2,
        titre : "Beyblade X",
        saison : "Saison 2",
        url : "saison2",
        img : "https://i.ibb.co/DbF9Jqt/Cover-X-s2.webp",
        synopsis : "La saison 2 commence immédiatement après le dernier épisode de la saison 1, et suit encore les Persona qui poursuivent leur ascension de la X Tower. De nouveaux ennemis se mettront en travers de leur chemin, la suite de l'histoire dépend d'eux.",
        vostfr : true,
        vostfrlink : "/x/saison2/vostfr",
        vf : false,
        vflink : "/x/saison2/vf",
    },
    {
        id : 3,
        titre : "Beyblade X",
        saison : "Scans",
        url : "x",
        img : "https://i.ibb.co/fNk71Hp/Beyblade-X-p1.webp",
        synopsis : "L'intrigue du manga se concentre sur Kasami Bird, qui aspire à devenir un joueur professionnel de Beyblade. Il veut aller à la X Tower, où se réunissent les joueurs professionnels de Beyblade. Le manga présente de nouveaux systèmes et capacités pour jouer à Beyblade.",
        vf : true,
        vflink : "/x/scans/",
        scan : true
    }
]

export default listeSaisons