const listePages = [
    {
        "page": 1,
        "link": "https://i.ibb.co/2cKCK1D/0001.webp"
      },
      {
        "page": 3,
        "link": "https://i.ibb.co/tKzjnCN/0003.webp"
      },
      {
        "page": 4,
        "link": "https://i.ibb.co/mS0mFbL/0004.webp"
      },
      {
        "page": 5,
        "link": "https://i.ibb.co/CBrxr0W/0005.webp"
      },
      {
        "page": 6,
        "link": "https://i.ibb.co/gSCzTr3/0006.webp"
      },
      {
        "page": 7,
        "link": "https://i.ibb.co/FVgFTcY/0007.webp"
      },
      {
        "page": 8,
        "link": "https://i.ibb.co/ZWgQWgg/0008.webp"
      },
      {
        "page": 9,
        "link": "https://i.ibb.co/yWFVdMm/0009.webp"
      },
      {
        "page": 10,
        "link": "https://i.ibb.co/tmWvFrv/0010.webp"
      },
      {
        "page": 11,
        "link": "https://i.ibb.co/FD6Ks28/0011.webp"
      },
      {
        "page": 12,
        "link": "https://i.ibb.co/dkK70rx/0012.webp"
      },
      {
        "page": 13,
        "link": "https://i.ibb.co/zFPcVQX/0013.webp"
      },
      {
        "page": 14,
        "link": "https://i.ibb.co/VBStxTJ/0014.webp"
      },
      {
        "page": 15,
        "link": "https://i.ibb.co/2sk3nPV/0015.webp"
      },
      {
        "page": 16,
        "link": "https://i.ibb.co/WchpC25/0016.webp"
      },
      {
        "page": 17,
        "link": "https://i.ibb.co/qkHXsrT/0017.webp"
      },
      {
        "page": 18,
        "link": "https://i.ibb.co/J326nj0/0018.webp"
      },
      {
        "page": 19,
        "link": "https://i.ibb.co/p3Lv8J3/0019.webp"
      },
      {
        "page": 20,
        "link": "https://i.ibb.co/QpT13CB/0020.webp"
      },
      {
        "page": 21,
        "link": "https://i.ibb.co/r2sVYXX/0021.webp"
      },
      {
        "page": 22,
        "link": "https://i.ibb.co/GdFyVTx/0022.webp"
      },
      {
        "page": 23,
        "link": "https://i.ibb.co/GWw74Fq/0023.webp"
      },
      {
        "page": 24,
        "link": "https://i.ibb.co/PgtKGvP/0024.webp"
      },
      {
        "page": 25,
        "link": "https://i.ibb.co/nCM2ZFj/0025.webp"
      },
      {
        "page": 26,
        "link": "https://i.ibb.co/nMrkQPJ/0026.webp"
      },
      {
        "page": 27,
        "link": "https://i.ibb.co/j64JDKK/0027.webp"
      },
      {
        "page": 28,
        "link": "https://i.ibb.co/5FtWDzc/0028.webp"
      },
      {
        "page": 29,
        "link": "https://i.ibb.co/gPt0wXm/0029.webp"
      },
      {
        "page": 30,
        "link": "https://i.ibb.co/FsD67bq/0030.webp"
      },
      {
        "page": 31,
        "link": "https://i.ibb.co/gwNfXNq/0031.webp"
      },
      {
        "page": 32,
        "link": "https://i.ibb.co/T01SVJ8/0032.webp"
      },
      {
        "page": 33,
        "link": "https://i.ibb.co/Y70R2KB/0033.webp"
      },
      {
        "page": 34,
        "link": "https://i.ibb.co/GJm6mf8/0034.webp"
      },
      {
        "page": 35,
        "link": "https://i.ibb.co/GHY60wm/0035.webp"
      },
      {
        "page": 36,
        "link": "https://i.ibb.co/kc4q8dY/0036.webp"
      },
      {
        "page": 37,
        "link": "https://i.ibb.co/mCSYNHx/0037.webp"
      },
      {
        "page": 38,
        "link": "https://i.ibb.co/1nC9jXj/0038.webp"
      },
      {
        "page": 39,
        "link": "https://i.ibb.co/ZzkR1TQ/0039.webp"
      },
      {
        "page": 40,
        "link": "https://i.ibb.co/C5gC0wY/0040.webp"
      },
      {
        "page": 41,
        "link": "https://i.ibb.co/HqKcrSH/0041.webp"
      },
      {
        "page": 42,
        "link": "https://i.ibb.co/5K0Vf8n/0042.webp"
      },
      {
        "page": 43,
        "link": "https://i.ibb.co/QJtkKCd/0043.webp"
      },
      {
        "page": 44,
        "link": "https://i.ibb.co/sFxyqFK/0044.webp"
      },
      {
        "page": 45,
        "link": "https://i.ibb.co/GFdxCmF/0045.webp"
      },
      {
        "page": 46,
        "link": "https://i.ibb.co/fQyCTDm/0046.webp"
      },
      {
        "page": 47,
        "link": "https://i.ibb.co/KGpm2HY/0047.webp"
      }
]


export default listePages;