const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/Vgn4k3n/0045-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/vVVY9h4/0045-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/hX5rjV7/0045-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/bdYvb73/0045-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/7VLgbtF/0045-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/NnfS38R/0045-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/wgdmNsr/0045-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/d0CTG8H/0045-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/QpXmCnH/0045-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/X5Jh6bb/0045-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/M76rpgz/0045-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/QDYW0pC/0045-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/v3pCDMz/0045-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/KVHKYwR/0045-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/JqPL2gC/0045-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/4K9Qt7N/0045-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/PTTQfyP/0045-017.webp"
  }
];

export default listePages;