const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/z23ZVkw/0006-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/Cw8yDhx/0006-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/GkNQFZm/0006-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/nBBVQbP/0006-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/37BGGTy/0006-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/VxhCpPN/0006-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/GRtcs0K/0006-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/q1hG8gZ/0006-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/vZyxpzD/0006-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/kh9jmK8/0006-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/LzJ3cYf/0006-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/XDN1hZg/0006-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/HtcVZFx/0006-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/0sPrndR/0006-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/Ns0LFyX/0006-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/ZxRtP6x/0006-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/34fCVpP/0006-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/PhW6YNW/0006-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/5j0Hsnr/0006-019.webp"
  },
  {
    page: 20,
    link: "https://i.ibb.co/dQTB8KL/0006-020.webp"
  },
  {
    page: 21,
    link: "https://i.ibb.co/K2YJNRB/0006-021.webp"
  },
  {
    page: 22,
    link: "https://i.ibb.co/f1JpyHN/0006-022.webp"
  },
  {
    page: 23,
    link: "https://i.ibb.co/JtTdgRX/0006-023.webp"
  },
  {
    page: 24,
    link: "https://i.ibb.co/JmK4LMR/0006-024.webp"
  },
  {
    page: 25,
    link: "https://i.ibb.co/dML260p/0006-025.webp"
  },
  {
    page: 26,
    link: "https://i.ibb.co/pyP77fz/0006-026.webp"
  },
  {
    page: 27,
    link: "https://i.ibb.co/2cN4k51/0006-027.webp"
  },
  {
    page: 28,
    link: "https://i.ibb.co/PM5JzFC/0006-028.webp"
  },
  {
    page: 29,
    link: "https://i.ibb.co/GFS7c1J/0006-029.webp"
  },
  {
    page: 30,
    link: "https://i.ibb.co/VmgWh3K/0006-030.webp"
  },
  {
    page: 31,
    link: "https://i.ibb.co/TctDWWb/0006-031.webp"
  },
  {
    page: 32,
    link: "https://i.ibb.co/GQS7DTg/0006-032.webp"
  },
  {
    page: 33,
    link: "https://i.ibb.co/kBZP9D8/0006-033.webp"
  },
  {
    page: 34,
    link: "https://i.ibb.co/xLfGq5F/0006-034.webp"
  },
  {
    page: 35,
    link: "https://i.ibb.co/YZTNqCC/0006-035.webp"
  },
  {
    page: 36,
    link: "https://i.ibb.co/LxwJKZP/0006-036.webp"
  },
  {
    page: 37,
    link: "https://i.ibb.co/FDN04yR/0006-037.webp"
  },
  {
    page: 38,
    link: "https://i.ibb.co/yRd9h8F/0006-038.webp"
  },
  {
    page: 39,
    link: "https://i.ibb.co/qgzryDr/0006-039.webp"
  },
  {
    page: 40,
    link: "https://i.ibb.co/hW82k2T/0006-040.webp"
  },
  {
    page: 41,
    link: "https://i.ibb.co/VvtSrKM/0006-041.webp"
  },
  {
    page: 42,
    link: "https://i.ibb.co/Y7Q5vYX/0006-042.webp"
  },
  {
    page: 43,
    link: "https://i.ibb.co/bmNqBXV/0006-043.webp"
  }
]


export default listePages;