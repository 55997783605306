const listeEpisodes = [
    {
        episode : 1,
        link : "https://video.sibnet.ru/shell.php?videoid=5228970"
    },
    {
        episode : 2,
        link : "https://video.sibnet.ru/shell.php?videoid=5229013"
    },
    {
        episode : 3,
        link : "https://video.sibnet.ru/shell.php?videoid=5228971"
    },
    {
        episode : 4,
        link : "https://video.sibnet.ru/shell.php?videoid=5228972"
    },
    {
        episode : 5,
        link : "https://video.sibnet.ru/shell.php?videoid=5228973"
    },
    {
        episode : 6,
        link : "https://video.sibnet.ru/shell.php?videoid=5228975"
    },
    {
        episode : 7,
        link : "https://video.sibnet.ru/shell.php?videoid=5228976"
    },
    {
        episode : 8,
        link : "https://video.sibnet.ru/shell.php?videoid=5229015"
    },
    {
        episode : 9,
        link : "https://video.sibnet.ru/shell.php?videoid=5228977"
    },
    {
        episode : 10,
        link : "https://video.sibnet.ru/shell.php?videoid=5228980"
    },
    {
        episode : 11,
        link : "https://video.sibnet.ru/shell.php?videoid=5228981"
    },
    {
        episode : 12,
        link : "https://video.sibnet.ru/shell.php?videoid=5228982"
    },
    {
        episode : 13,
        link : "https://video.sibnet.ru/shell.php?videoid=5228984"
    },
    {
        episode : 14,
        link : "https://video.sibnet.ru/shell.php?videoid=5228986"
    },
    {
        episode : 15,
        link : "https://video.sibnet.ru/shell.php?videoid=5228988"
    },
    {
        episode : 16,
        link : "https://video.sibnet.ru/shell.php?videoid=5228990"
    },
    {
        episode : 17,
        link : "https://video.sibnet.ru/shell.php?videoid=5228994"
    },
    {
        episode : 18,
        link : "https://video.sibnet.ru/shell.php?videoid=5228996"
    },
    {
        episode : 19,
        link : "https://video.sibnet.ru/shell.php?videoid=5228998"
    },
    {
        episode : 20,
        link : "https://video.sibnet.ru/shell.php?videoid=5229000"
    },
    {
        episode : 21,
        link : "https://video.sibnet.ru/shell.php?videoid=5229002"
    },
    {
        episode : 22,
        link : "https://video.sibnet.ru/shell.php?videoid=5229005"
    },
    {
        episode : 23,
        link : "https://video.sibnet.ru/shell.php?videoid=5229007"
    },
    {
        episode : 24,
        link : "https://video.sibnet.ru/shell.php?videoid=5229009"
    },
    {
        episode : 25,
        link : "https://video.sibnet.ru/shell.php?videoid=5229011"
    },
    {
        episode : 26,
        link : "https://video.sibnet.ru/shell.php?videoid=5229012"
    }
]

export default listeEpisodes;