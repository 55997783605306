const listePages = [
    {
        "page": 1,
        "link": "https://i.ibb.co/q7PGvys/0126.webp"
      },
      {
        "page": 2,
        "link": "https://i.ibb.co/94WB2Wm/0127.webp"
      },
      {
        "page": 3,
        "link": "https://i.ibb.co/WWG4r0b/0128.webp"
      },
      {
        "page": 4,
        "link": "https://i.ibb.co/TKGhYS2/0129.webp"
      },
      {
        "page": 5,
        "link": "https://i.ibb.co/qRymwWH/0130.webp"
      },
      {
        "page": 6,
        "link": "https://i.ibb.co/zHXhzrt/0131.webp"
      },
      {
        "page": 7,
        "link": "https://i.ibb.co/hcnPrLP/0132.webp"
      },
      {
        "page": 8,
        "link": "https://i.ibb.co/zxV4ym2/0133.webp"
      },
      {
        "page": 9,
        "link": "https://i.ibb.co/3WNQLTq/0134.webp"
      },
      {
        "page": 10,
        "link": "https://i.ibb.co/R3NnVgF/0135.webp"
      },
      {
        "page": 11,
        "link": "https://i.ibb.co/W2QmJjJ/0136.webp"
      },
      {
        "page": 12,
        "link": "https://i.ibb.co/8zHSwpC/0137.webp"
      },
      {
        "page": 13,
        "link": "https://i.ibb.co/z7HV58N/0138.webp"
      },
      {
        "page": 14,
        "link": "https://i.ibb.co/r0S74yz/0139.webp"
      },
      {
        "page": 15,
        "link": "https://i.ibb.co/tLrSFCJ/0140.webp"
      },
      {
        "page": 16,
        "link": "https://i.ibb.co/5KGNsjk/0141.webp"
      },
      {
        "page": 17,
        "link": "https://i.ibb.co/XC4SbXm/0142.webp"
      },
      {
        "page": 18,
        "link": "https://i.ibb.co/HppKjCQ/0143.webp"
      },
      {
        "page": 19,
        "link": "https://i.ibb.co/QYQhk1Z/0144.webp"
      },
      {
        "page": 20,
        "link": "https://i.ibb.co/Zmh5PvW/0145.webp"
      },
      {
        "page": 21,
        "link": "https://i.ibb.co/DgBLd3Y/0146.webp"
      },
      {
        "page": 22,
        "link": "https://i.ibb.co/Vm7cT8c/0147.webp"
      },
      {
        "page": 23,
        "link": "https://i.ibb.co/w0j8zKZ/0148.webp"
      },
      {
        "page": 24,
        "link": "https://i.ibb.co/1KzqMTK/0149.webp"
      },
      {
        "page": 25,
        "link": "https://i.ibb.co/sQFx06H/0150.webp"
      },
      {
        "page": 26,
        "link": "https://i.ibb.co/mbtvtyB/0151.webp"
      },
      {
        "page": 27,
        "link": "https://i.ibb.co/wWFCKnq/0152.webp"
      },
      {
        "page": 28,
        "link": "https://i.ibb.co/0qMHh8Q/0153.webp"
      },
      {
        "page": 29,
        "link": "https://i.ibb.co/0B6PSMN/0154.webp"
      },
      {
        "page": 30,
        "link": "https://i.ibb.co/mXLm93y/0155.webp"
      },
      {
        "page": 31,
        "link": "https://i.ibb.co/hRkmxYN/0156.webp"
      },
      {
        "page": 32,
        "link": "https://i.ibb.co/qmQtt81/0157.webp"
      },
      {
        "page": 33,
        "link": "https://i.ibb.co/Q9gpnMv/0158.webp"
      },
      {
        "page": 34,
        "link": "https://i.ibb.co/pdLH6VH/0159.webp"
      },
      {
        "page": 35,
        "link": "https://i.ibb.co/2d058zp/0160.webp"
      },
      {
        "page": 36,
        "link": "https://i.ibb.co/4VdWRc3/0161.webp"
      },
      {
        "page": 37,
        "link": "https://i.ibb.co/hc79h9s/0162.webp"
      },
      {
        "page": 38,
        "link": "https://i.ibb.co/804gcQ2/0163.webp"
      },
      {
        "page": 39,
        "link": "https://i.ibb.co/qnchWb6/0164.webp"
      },
      {
        "page": 40,
        "link": "https://i.ibb.co/gZ4Jmpg/0165.webp"
      },
      {
        "page": 41,
        "link": "https://i.ibb.co/Xy1yvSb/0166.webp"
      },
      {
        "page": 42,
        "link": "https://i.ibb.co/1Q3SSkj/0167.webp"
      }
]
  

export default listePages;