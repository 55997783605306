const listeEpisodes = [
    {
        episode : 1,
        link : "https://www.youtube.com/embed/HXd1xVL2JX0"
    },
    {
        episode : 2,
        link : "https://www.youtube.com/embed/WOmz6O84SkA"
    },
    {
        episode : 3,
        link : "https://www.youtube.com/embed/ftpqbPdXpyE"
    },
    {
        episode : 4,
        link : "https://www.youtube.com/embed/mYbMLACA7PE"
    },
    {
        episode : 5,
        link : "https://www.youtube.com/embed/io1AGHxduxg"
    },
    {
        episode : 6,
        link : "https://www.youtube.com/embed/U9d8jky27Lk"
    },
    {
        episode : 7,
        link : "https://www.youtube.com/embed/_khT-TjT5ig"
    },
    {
        episode : 8,
        link : "https://www.youtube.com/embed/ImBalVrf_wU"
    },
    {
        episode : 9,
        link : "https://www.youtube.com/embed/t2rjyHdWuK4"
    },
    {
        episode : 10,
        link : "https://www.youtube.com/embed/pXvIZSsKzp0"
    },
    {
        episode : 11,
        link : "https://www.youtube.com/embed/bzDqN7emi98"
    },
    {
        episode : 12,
        link : "https://www.youtube.com/embed/PkGozFmJIJc"
    },
    {
        episode : 13,
        link : "https://www.youtube.com/embed/fr4jihtDU9A"
    },
    {
        episode : 14,
        link : "https://www.youtube.com/embed/tT-T9o3CpAs"
    },
    {
        episode : 15,
        link : "https://www.youtube.com/embed/6cguA8WRGl4"
    },
    {
        episode : 16,
        link : "https://www.youtube.com/embed/zs-YsRnZpzk"
    },
    {
        episode : 17,
        link : "https://www.youtube.com/embed/p6TSPyqSKAo"
    },
    {
        episode : 18,
        link : "https://www.youtube.com/embed/B-FVW_dM3C0"
    },
    {
        episode : 19,
        link : "https://www.youtube.com/embed/t7aliNsmCpo"
    },
    {
        episode : 20,
        link : "https://www.youtube.com/embed/Jmh67x9ON4k"
    },
    {
        episode : 21,
        link : "https://www.youtube.com/embed/p0ZVgKDnMJ0"
    },
    {
        episode : 22,
        link : "https://www.youtube.com/embed/-Jhz2Xn4LBo"
    },
    {
        episode : 23,
        link : "https://www.youtube.com/embed/ilxBOJZKSMI"
    },
    {
        episode : 24,
        link : "https://www.youtube.com/embed/vwy7L3fP6yU"
    },
    {
        episode : 25,
        link : "https://www.youtube.com/embed/psuayT1xkbU"
    },
    {
        episode : 26,
        link : "https://www.youtube.com/embed/UOVsHIckNY4"
    },
    {
        episode : 27,
        link : "https://www.youtube.com/embed/vQIzCRpR3SM"
    },
    {
        episode : 28,
        link : "https://www.youtube.com/embed/yHnpgfrG4G4"
    },
    {
        episode : 29,
        link : "https://www.youtube.com/embed/NSEWTss-MY8"
    },
    {
        episode : 30,
        link : "https://www.youtube.com/embed/tuXxz7SgO8o"
    },
    {
        episode : 31,
        link : "https://www.youtube.com/embed/spA18lflTbQ"
    },
    {
        episode : 32,
        link : "https://www.youtube.com/embed/lBayn8H8weQ"
    },
    {
        episode : 33,
        link : "https://www.youtube.com/embed/BBZieS8f_v4"
    },
    {
        episode : 34,
        link : "https://www.youtube.com/embed/92tdrt8KC0g"
    },
    {
        episode : 35,
        link : "https://www.youtube.com/embed/b6-V_NPs2gY"
    },
    {
        episode : 36,
        link : "https://www.youtube.com/embed/TE7Ftkc-xus"
    },
    {
        episode : 37,
        link : "https://www.youtube.com/embed/7fcSSqyvtF8"
    },
    {
        episode : 38,
        link : "https://www.youtube.com/embed/pu_suGZOk8A"
    },
    {
        episode : 39,
        link : "https://www.youtube.com/embed/ctiDRAe2HLE"
    }
]

export default listeEpisodes;