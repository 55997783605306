const listePages = [
      {
        page: 47,
        link: "https://i.ibb.co/QfXpfhD/171.webp"
      },
      {
        page: 48,
        link: "https://i.ibb.co/YDQBDTX/172.webp"
      },
      {
        page: 49,
        link: "https://i.ibb.co/8Y9yxGJ/173.webp"
      },
      {
        page: 50,
        link: "https://i.ibb.co/1ZjkzNy/174.webp"
      },
      {
        page: 51,
        link: "https://i.ibb.co/KzzqyV4/175.webp"
      },
      {
        page: 52,
        link: "https://i.ibb.co/B4gLZRZ/176.webp"
      },
      {
        page: 53,
        link: "https://i.ibb.co/yBktZ57/177.webp"
      },
      {
        page: 54,
        link: "https://i.ibb.co/WtD4QnQ/178.webp"
      },
      {
        page: 55,
        link: "https://i.ibb.co/6X8wXVD/179.webp"
      },
      {
        page: 56,
        link: "https://i.ibb.co/HK0PVSd/180.webp"
      },
      {
        page: 57,
        link: "https://i.ibb.co/2N2sH1X/181.webp"
      },
      {
        page: 58,
        link: "https://i.ibb.co/C7ttxm3/182.webp"
      },
      {
        page: 59,
        link: "https://i.ibb.co/8cQGCCG/183.webp"
      },
      {
        page: 60,
        link: "https://i.ibb.co/DzWZF22/184.webp"
      },
      {
        page: 61,
        link: "https://i.ibb.co/xX3bQ70/185.webp"
      },
      {
        page: 62,
        link: "https://i.ibb.co/1MwXtFv/186.webp"
      },
      {
        page: 63,
        link: "https://i.ibb.co/XFg5kzf/187.webp"
      },
      {
        page: 64,
        link: "https://i.ibb.co/phNvvkN/188.webp"
      },
      {
        page: 65,
        link: "https://i.ibb.co/ChWPtjV/189.webp"
      },
      {
        page: 66,
        link: "https://i.ibb.co/mTw4LVx/190.webp"
      },
      {
        page: 67,
        link: "https://i.ibb.co/Gc24CR6/191.webp"
      },
      {
        page: 68,
        link: "https://i.ibb.co/HdX8t1S/192.webp"
      }
]


export default listePages;