const listeEpisodes = [
    {
        episode : 1,
        link : "https://video.sibnet.ru/shell.php?videoid=5229566"
    },
    {
        episode : 2,
        link : "https://video.sibnet.ru/shell.php?videoid=5229567"
    },
    {
        episode : 3,
        link : "https://video.sibnet.ru/shell.php?videoid=5229568"
    },
    {
        episode : 4,
        link : "https://video.sibnet.ru/shell.php?videoid=5229570"
    },
    {
        episode : 5,
        link : "https://video.sibnet.ru/shell.php?videoid=5229572"
    },
    {
        episode : 6,
        link : "https://video.sibnet.ru/shell.php?videoid=5229573"
    },
    {
        episode : 7,
        link : "https://video.sibnet.ru/shell.php?videoid=5229574"
    },
    {
        episode : 8,
        link : "https://video.sibnet.ru/shell.php?videoid=5229576"
    },
    {
        episode : 9,
        link : "https://video.sibnet.ru/shell.php?videoid=5229577"
    },
    {
        episode : 10,
        link : "https://video.sibnet.ru/shell.php?videoid=5229578"
    },
    {
        episode : 11,
        link : "https://video.sibnet.ru/shell.php?videoid=5229580"
    },
    {
        episode : 12,
        link : "https://video.sibnet.ru/shell.php?videoid=5229582"
    },
    {
        episode : 13,
        link : "https://video.sibnet.ru/shell.php?videoid=5229584"
    },
    {
        episode : 14,
        link : "https://video.sibnet.ru/shell.php?videoid=5229586"
    },
    {
        episode : 15,
        link : "https://video.sibnet.ru/shell.php?videoid=5229587"
    },
    {
        episode : 16,
        link : "https://video.sibnet.ru/shell.php?videoid=5229588"
    },
    {
        episode : 17,
        link : "https://video.sibnet.ru/shell.php?videoid=5229589"
    },
    {
        episode : 18,
        link : "https://video.sibnet.ru/shell.php?videoid=5229590"
    },
    {
        episode : 19,
        link : "https://video.sibnet.ru/shell.php?videoid=5229591"
    },
    {
        episode : 20,
        link : "https://video.sibnet.ru/shell.php?videoid=5229593"
    },
    {
        episode : 21,
        link : "https://video.sibnet.ru/shell.php?videoid=5229596"
    },
    {
        episode : 22,
        link : "https://video.sibnet.ru/shell.php?videoid=5229598"
    },
    {
        episode : 23,
        link : "https://video.sibnet.ru/shell.php?videoid=5229599"
    },
    {
        episode : 24,
        link : "https://video.sibnet.ru/shell.php?videoid=5229600"
    },
    {
        episode : 25,
        link : "https://video.sibnet.ru/shell.php?videoid=5229602"
    },
    {
        episode : 26,
        link : "https://video.sibnet.ru/shell.php?videoid=5229604"
    },
    {
        episode : 27,
        link : "https://video.sibnet.ru/shell.php?videoid=5229605"
    },
    {
        episode : 28,
        link : "https://video.sibnet.ru/shell.php?videoid=5229606"
    },
    {
        episode : 29,
        link : "https://video.sibnet.ru/shell.php?videoid=5229608"
    },
    {
        episode : 30,
        link : "https://video.sibnet.ru/shell.php?videoid=5229613"
    },
    {
        episode : 31,
        link : "https://video.sibnet.ru/shell.php?videoid=5229616"
    },
    {
        episode : 32,
        link : "https://video.sibnet.ru/shell.php?videoid=5229619"
    },
    {
        episode : 33,
        link : "https://video.sibnet.ru/shell.php?videoid=5229620"
    },
    {
        episode : 34,
        link : "https://video.sibnet.ru/shell.php?videoid=5229622"
    },
    {
        episode : 35,
        link : "https://video.sibnet.ru/shell.php?videoid=5229542"
    },
    {
        episode : 36,
        link : "https://video.sibnet.ru/shell.php?videoid=5229544"
    },
    {
        episode : 37,
        link : "https://video.sibnet.ru/shell.php?videoid=5229546"
    },
    {
        episode : 38,
        link : "https://video.sibnet.ru/shell.php?videoid=5229547"
    },
    {
        episode : 39,
        link : "https://video.sibnet.ru/shell.php?videoid=5229548"
    },
    {
        episode : 40,
        link : "https://video.sibnet.ru/shell.php?videoid=5229549"
    },
    {
        episode : 41,
        link : "https://video.sibnet.ru/shell.php?videoid=5229551"
    },
    {
        episode : 42,
        link : "https://video.sibnet.ru/shell.php?videoid=5229552"
    },
    {
        episode : 43,
        link : "https://video.sibnet.ru/shell.php?videoid=5229553"
    },
    {
        episode : 44,
        link : "https://video.sibnet.ru/shell.php?videoid=5229554"
    },
    {
        episode : 45,
        link : "https://video.sibnet.ru/shell.php?videoid=5229555"
    },
    {
        episode : 46,
        link : "https://video.sibnet.ru/shell.php?videoid=5229557"
    },
    {
        episode : 47,
        link : "https://video.sibnet.ru/shell.php?videoid=5229558"
    },
    {
        episode : 48,
        link : "https://video.sibnet.ru/shell.php?videoid=5229560"
    },
    {
        episode : 49,
        link : "https://video.sibnet.ru/shell.php?videoid=5229561"
    },
    {
        episode : 50,
        link : "https://video.sibnet.ru/shell.php?videoid=5229563"
    },
    {
        episode : 51,
        link : "https://video.sibnet.ru/shell.php?videoid=5229564"
    },
    {
        episode : 52,
        link : "https://video.sibnet.ru/shell.php?videoid=5229565"
    }
]

export default listeEpisodes;