const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/bszwFxs/0048-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/G5BPNfs/0048-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/HXK5rkc/0048-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/x1DfT0j/0048-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/9vBhKhm/0048-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/BPkHjQ6/0048-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/6YQh8TK/0048-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/R7z4rkj/0048-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/sFCnvHP/0048-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/9922F4m/0048-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/H2JJXpL/0048-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/TBGw0H4/0048-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/wwqSkVr/0048-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/k1pvxZV/0048-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/jh8K5TP/0048-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/SRQ3khS/0048-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/sRTHv5t/0048-017.webp"
  }
];


export default listePages;