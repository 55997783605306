const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/23hkkHy/0008-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/D4TYhW4/0008-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/9gp5th5/0008-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/ZMNT89r/0008-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/7jR7Qrd/0008-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/Ptk27CL/0008-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/VYTvz9G/0008-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/g6R1cm8/0008-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/RjXk6Gx/0008-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/sVKpQfm/0008-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/YBSwTV1/0008-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/QPxzrLg/0008-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/S6Y9Js3/0008-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/t26kfZL/0008-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/q9mR769/0008-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/zH47Wfv/0008-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/943cwcn/0008-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/6t1s19x/0008-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/fGdLJ4v/0008-019.webp"
  },
  {
    page: 20,
    link: "https://i.ibb.co/HrT4gzH/0008-020.webp"
  },
  {
    page: 21,
    link: "https://i.ibb.co/T0PkWDV/0008-021.webp"
  },
  {
    page: 22,
    link: "https://i.ibb.co/4dML5mM/0008-022.webp"
  },
  {
    page: 23,
    link: "https://i.ibb.co/P42z5R5/0008-023.webp"
  },
  {
    page: 24,
    link: "https://i.ibb.co/hK7R47m/0008-024.webp"
  },
  {
    page: 25,
    link: "https://i.ibb.co/F80NPry/0008-025.webp"
  },
  {
    page: 26,
    link: "https://i.ibb.co/pn4Nk9p/0008-026.webp"
  },
  {
    page: 27,
    link: "https://i.ibb.co/HnPfkCS/0008-027.webp"
  },
  {
    page: 28,
    link: "https://i.ibb.co/s6GzGRp/0008-028.webp"
  },
  {
    page: 29,
    link: "https://i.ibb.co/0KsW28j/0008-029.webp"
  },
  {
    page: 30,
    link: "https://i.ibb.co/1TgKqyn/0008-030.webp"
  },
  {
    page: 31,
    link: "https://i.ibb.co/JCRLB5V/0008-031.webp"
  },
  {
    page: 32,
    link: "https://i.ibb.co/tDhYMtZ/0008-032.webp"
  },
  {
    page: 33,
    link: "https://i.ibb.co/xHKRptz/0008-033.webp"
  },
  {
    page: 34,
    link: "https://i.ibb.co/ypKY6C3/0008-034.webp"
  },
  {
    page: 35,
    link: "https://i.ibb.co/sFCcfPM/0008-035.webp"
  },
  {
    page: 36,
    link: "https://i.ibb.co/MpbNcWp/0008-036.webp"
  },
  {
    page: 37,
    link: "https://i.ibb.co/L13Zc3z/0008-037.webp"
  },
  {
    page: 38,
    link: "https://i.ibb.co/2tYPQTH/0008-038.webp"
  },
  {
    page: 39,
    link: "https://i.ibb.co/zSg67nV/0008-039.webp"
  },
  {
    page: 40,
    link: "https://i.ibb.co/48PQCsK/0008-040.webp"
  }
]


export default listePages;