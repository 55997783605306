const listeEpisodes = [
    {
        episode : "trailer",
        link : "https://video.sibnet.ru/shell.php?videoid=5246774"
    },
    {
        episode : 1,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239045&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/sozj2rdj"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5452584"
            }
        ],
        dllink : "https://drive.google.com/uc?export=download&id=1CF9k_Q3iXmSzTJP4bbhdSP8uFFKudhGa "
    },
    {
        episode : 2,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239046&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/q4x7o5wx"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5452591"
            }
        ],
        dllink : "https://drive.google.com/uc?export=download&id=1WoUAGGvzjiUsGyMHcDJJaGWRFtVZhnpR"
    },
    {
        episode : 3,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239065&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/ujiu5pa7"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5452765"
            }
        ],
        dllink : "https://drive.google.com/uc?export=download&id=11Tr2twoCGvbmP3DB4YHi_0MmEv4AOD7P "
    },
    {
        episode : 4,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239048&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/vv9djzbk"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5452599"
            }
        ],
        dllink : "https://drive.google.com/uc?export=download&id=1DIoQnn9_Ufg0k6hMbijalAVB0tDqbhXA"
    },
    {
        episode : 5,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239049&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/xoowiejb"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5452604"
            }
        ],
        dllink : "https://drive.google.com/uc?export=download&id=1f9V1ZEyJAgsWAheJ210Pd32zqsP2z5Za"
    },
    {
        episode : 6,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239050&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/jn1u347o"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5452607"
            }
        ],
        dllink : "https://drive.google.com/uc?export=download&id=1-pUjVZqGGAk0SvkI-vzkhcf_L6zxUPQk"
    },
    {
        episode : 7,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239051&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/fdhwymqb"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5452612"
            }
        ],
        dllink : "https://drive.google.com/uc?export=download&id=1RTznHQdFnkN6I2gdFiiTYFgHoz4YKrI7"
    },
    {
        episode : 8,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239066&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/9rwy2wjl"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5452767"
            }
        ],
        dllink : "https://drive.google.com/uc?export=download&id=1UftPwFW47x6N9gzMjl-cmaQ-48bfIwoV"
    },
    {
        episode : 9,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239053&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/6vw2cxcj"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5452620"
            }
        ],
        dllink : "https://drive.google.com/uc?export=download&id=13ham8jp-EONUzl2qckE73eGOyx2nCKJY"
    },
    {
        episode : 10,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239054&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/enrzfrst"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5452622"
            }
        ],
        dllink : "https://drive.google.com/uc?export=download&id=1FanxjLC0bJcA64GDkDyBb0bo-aekMV1c"
    },
    {
        episode : 11,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239055&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/otynf1oz"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5452625"
            }
        ],
        dllink : "https://drive.google.com/uc?export=download&id=1wv_vdwg2s9onINdER9NEkFUCSougiMoj"
    },
    {
        episode : 12,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239056&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/t60n1b7e"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5452631"
            }
        ],
        dllink : "https://drive.google.com/uc?export=download&id=1EhQWngwk4EAu8zHemz277c5wS8z0qjzh"
    },
    {
        episode : 13,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239057&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/9dtazn54"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5452634"
            }
        ],
        dllink : "https://drive.google.com/uc?export=download&id=13iS6WQRwRC-CzEQsYsAlOM0WMoW2ByVL"
    },
    {
        episode : 14,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239058&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/5m8nj0ch"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5452636"
            }
        ],
        dllink : "https://drive.google.com/uc?export=download&id=1qzfdCas8Y4xLMjAQ8dQJyQ-AZn53P__Y"
    },
    {
        episode : 15,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239059&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/r3qv2jm1"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5452639"
            }
        ],
        dllink : "https://drive.google.com/uc?export=download&id=1O6C4toTrWDZUTVsLTRz1oGBdBKtwM1UU"
    },
    {
        episode : 16,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239060&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/549i33xk"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5452644"
            }
        ],
        dllink : "https://drive.google.com/uc?export=download&id=1bIByt6UqNf5PZCEzF2QlnU5MI84nPgFh"
    },
    {
        episode : 17,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239061&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/puqg2qxb"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5452648"
            }
        ],
        dllink : "https://drive.google.com/uc?export=download&id=1IbUxHzVBeeFn2GWW8ci5u1WU6BsFFQcQ"
    },
    {
        episode : 18,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239062&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/ghdjploi"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5452649"
            }
        ],
        dllink : "https://drive.google.com/uc?export=download&id=1JllGBJBfl0ikPe1IOBXhC_URRzTGcDsV"
    },
    {
        episode : 19,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239063&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/60wd0rmf"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5452651"
            }
        ],
        dllink : "https://drive.google.com/uc?export=download&id=1UentcMbez73fDUDK1iOlso6FSKIvve6G"
    },
    {
        episode : 20,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239064&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/fqgp0sis"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5452653"
            }
        ],
        dllink : "https://drive.google.com/uc?export=download&id=1Nygz99QMlLkugPjoG6gCiYHFCcB7CMN8"
    },
    {
        episode : 21,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239067&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/l4u8y17i"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5453967"
            }
        ],
        dllink: "https://drive.google.com/uc?export=download&id=1i_V-RsxPT_Sm9dcf7OGb9QW-tR9vA3Wi"
    },
	{
	   episode : 22,
	    multi: true,
	    links: [
	        {
	            name: "VK Video",
	            link: "https://vk.com/video_ext.php?oid=852616431&id=456239068&hd=2"
	        },
	        {
	            name: "SendVid",
	            link : "https://sendvid.com/embed/579dslbw"
	        },
	        {
	            name: "Sibnet",
	            link : "https://video.sibnet.ru/shell.php?videoid=5465979"
	       }
	    ],
	    dllink: "https://drive.google.com/uc?export=download&id=1ar8SR_fNLdj_bYInMepPCRBi5nxDVVZW"
	},
    {
        episode : 23,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239069&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/90zcqkw5"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5473276"
            }
        ],
        dllink: "https://drive.google.com/uc?export=download&id=1D5Fo3e3x43Puk4d1XG7A7ru0sRVk-96P"
    },
    {
        episode : 24,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239070&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/lbklhpzp"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5478071"
            }
        ],
        dllink: "https://drive.google.com/uc?export=download&id=1XPydPOdr1Pawjm2UIcw5eVsv7_DwBvtW"
    },
	{
	    episode : 25,
	    multi: true,
	    links: [
	        {
	            name: "VK Video",
	            link: "https://vk.com/video_ext.php?oid=852616431&id=456239097&hd=2"
	        },
	        {
	            name: "SendVid",
	            link : "https://sendvid.com/embed/hfnupxrs"
	        },
	        {
	            name: "Sibnet",
	            link : "https://video.sibnet.ru/shell.php?videoid=5486662"
	        }
	    ],
	    dllink: "https://drive.google.com/uc?export=download&id=1sFhwOIaGDHNzMGwT1KyiGFMhSSnodG0Y"
	},
    {
        episode : 26,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239100&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/kexyp9vn"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5498375"
            }
        ],
        dllink: "https://drive.google.com/uc?export=download&id=19oohSgUd4n3zTma28fGa15neoLAgXnN3"
    },
    {
        episode : 27,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239101&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/882952vc"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5503698"
            }
        ],
        dllink: "https://drive.google.com/u/0/uc?id=1F-GYgei3lMrwFo9il7xd9xAsBAD_bcew&export=download"
    },
    {
        episode : 28,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239103&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/vqrrbmg4"
            },
        ],
        dllink: "https://drive.google.com/uc?export=download&id=18ACWpETBPmxO1v1ITBPhbMURSW1HQmQN"
    },
    {
        episode : 29,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239107&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/nric8q61"
            }
        ],
        dllink: "https://drive.google.com/uc?export=download&id=1rvtXF3fi4dgT2qZmSv7DkNHgMv30mdFn"
    },
    {
        episode : 30,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239108&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/4pw07v6d"
            }
        ],
        dllink: "https://drive.google.com/uc?export=download&id=1VbV7-4qdCiyDu5-5mgNwPc_VmVRQaWND"
    },
    {
        episode : 31,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239110&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/7pbp97w4"
            }
        ],
        dllink: "https://drive.google.com/uc?export=download&id=1n8HQWnK14I1acdPy6lk5lRZFZReE_5wb"
    },
    {
        episode : 32,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239111&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/ihwm03pq"
            }
        ],
        dllink: "https://drive.google.com/uc?export=download&id=11hF8OYn1rvVJG2v0WiepzKKeKicl0zS-"
    },
    {
        episode : 33,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239112&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/r17i3mhr"
            }
        ],
        dllink: "https://drive.google.com/uc?export=download&id=1ey-R-o3eUY8DxWE_2iT3EQmd0Qin-6IP"
    },
    {
        episode : 34,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239113&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/p0568m55"
            },
        ],
        dllink: "https://drive.google.com/uc?export=download&id=1L5uPLk_Dv4aI8IpME0LzzVrEfKBa2_KQ"
    },
    {
        episode : 35,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239114&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/dmpf30c8"
            },
        ],
        dllink: "https://drive.google.com/uc?export=download&id=1DJdmzVhtgjkC2G7EPwkGALQGFngil5gl"
    },
    {
        episode : 36,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239115&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/li6232op"
            },
        ],
        dllink: "https://drive.google.com/uc?export=download&id=1Cx6R0U7OExkIJFNYYKuWaWfhxwFT3548"
    },
    {
        episode : 37,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239116&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/lgqu5nxu"
            },
        ],
        dllink: "https://drive.google.com/uc?export=download&id=1dSSsH0sHF6Ay_cL8VrHzz4H4eks9-jj2"
    },
    {
        episode : 38,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239118&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/artrnjuo"
            },
        ],
        dllink: "https://drive.google.com/uc?export=download&id=1zfhGH8e0vNcYxY_1bkrlpiiwp3X4OCJw"
    },
    {
        episode : 39,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239120&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/0hvuugrg"
            },
        ],
        dllink: "https://drive.google.com/uc?export=download&id=1eYyVstCNQn2cuov3p9QsjFu8wOlZHgLA"
    },
    {
        episode : 40,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239121&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/8pbuvjax"
            },
        ],
        dllink: "https://drive.google.com/uc?export=download&id=1FGK3Ho5Xsten7dsJq0B3B95woItVLVp2"
    },
    {
        episode : 41,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239122&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/d2ro66hf"
            },
        ],
        dllink: "https://drive.google.com/uc?export=download&id=10UmR9bTC2Ze50ee3bRFZ8s0QrT3qS2Wc"
    },
    {
        episode : 42,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239123&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/6s77un5y"
            },
        ],
        dllink: "https://drive.google.com/uc?export=download&id=1M7LIls51nLpaHi2j-vp5xTa_8rlothGM"
    },
    {
        episode : 43,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=646754736&id=456239017&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/psispk2x"
            },
        ],
        dllink: "https://drive.google.com/uc?export=download&id=1T0n4Zg1ebRD_x8mMrorY_5hd_ktplzSh"
    },
    {
        episode : 44,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=646754736&id=456239018&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/qty97o6q"
            },
        ],
        dllink: "https://drive.google.com/uc?export=download&id=11Y_EluIn_SK-8YNPLUkvuRjwBQXABg4T"
    },
    {
        episode : 45,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239124&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/p4f2vd3j"
            },
        ],
        dllink: "https://drive.google.com/uc?export=download&id=1Ww0M4omXXanqpW9GWxbCR2Me1N73GmIb"
    },
    {
        episode : 46,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239127&hdp=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/h882wep0"
            },
        ],
        dllink: "https://drive.google.com/uc?export=download&id=1zKosBxG73M4jnlKvqFbeHhATAndbHTfJ"
    },
    {
        episode : 47,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239128&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/yl2347tr"
            },
        ],
        dllink: "https://drive.google.com/uc?export=download&id=1xF7TDBBgSGkBvLxdY8AEXGtE9Z4ovc6s"
    },
    {
        episode : 48,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239129&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/6plyvuy9"
            },
        ],
        dllink: "https://drive.google.com/uc?export=download&id=1dwXwd9FbVSmVf7zl_p2j-WmQMxE29wGr"
    },
    {
        episode : 49,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239130&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/psfapoe2"
            },
        ],
        dllink: "https://drive.google.com/uc?export=download&id=1_r2Awvoh62Xjd5X_zKAkqvGNXK1CiGvf"
    },
    {
        episode : 50,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239131&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/o341r61h"
            },
        ],
        dllink: "https://drive.google.com/uc?export=download&id=1NHv6ugi4MTdUuac9GglwZTGsLqtkKYkw"
    },
    {
        episode : 51,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239132&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/oz7qjzw5"
            },
        ],
        dllink: "https://drive.google.com/uc?export=download&id=1YaIh7j4KGjWsZKPt505oK-m625NTvH2O"
    },
]

export default listeEpisodes;