const listePages = [
  { page: 1, link: "https://i.ibb.co/SXc8YWp/0093-001.webp" },
  { page: 2, link: "https://i.ibb.co/bmnHHm7/0093-002.webp" },
  { page: 3, link: "https://i.ibb.co/mS9PdFT/0093-003.webp" },
  { page: 4, link: "https://i.ibb.co/4K0FqFB/0093-004.webp" },
  { page: 5, link: "https://i.ibb.co/5FcpmBd/0093-005.webp" },
  { page: 6, link: "https://i.ibb.co/9y5VxQ6/0093-006.webp" },
  { page: 7, link: "https://i.ibb.co/NjVv94D/0093-007.webp" },
  { page: 8, link: "https://i.ibb.co/L50WqfZ/0093-008.webp" },
  { page: 9, link: "https://i.ibb.co/Xp0Zbj7/0093-009.webp" },
  { page: 10, link: "https://i.ibb.co/vCNrJwT/0093-010.webp" },
  { page: 11, link: "https://i.ibb.co/Hz9BjDn/0093-011.webp" },
  { page: 12, link: "https://i.ibb.co/KGSPsbY/0093-012.webp" },
  { page: 13, link: "https://i.ibb.co/cJHZ59F/0093-013.webp" },
  { page: 14, link: "https://i.ibb.co/vLzyj7z/0093-014.webp" },
  { page: 15, link: "https://i.ibb.co/W69Yd6p/0093-015.webp" },
  { page: 16, link: "https://i.ibb.co/D78PzCh/0093-016.webp" },
  { page: 17, link: "https://i.ibb.co/vhmLvGC/0093-017.webp" },
  { page: 18, link: "https://i.ibb.co/SVwBt83/0093-018.webp" },
  { page: 19, link: "https://i.ibb.co/f4qyrXM/0093-019.webp" },
  { page: 20, link: "https://i.ibb.co/4ZNVz14/0093-020.webp" },
  { page: 21, link: "https://i.ibb.co/JjFJyVF/0093-021.webp" },
  { page: 22, link: "https://i.ibb.co/HXX5dtV/0093-022.webp" },
  { page: 23, link: "https://i.ibb.co/0myyj6w/0093-023.webp" },
  { page: 24, link: "https://i.ibb.co/n8CHKw8/0093-024.webp" },
  { page: 25, link: "https://i.ibb.co/WkSyJ6t/0093-025.webp" },
  { page: 26, link: "https://i.ibb.co/wCQnfzz/0093-026.webp" },
  { page: 27, link: "https://i.ibb.co/fk9mQRc/0093-027.webp" },
  { page: 28, link: "https://i.ibb.co/km9Zn9G/0093-028.webp" },
  { page: 29, link: "https://i.ibb.co/ckYDDbv/0093-029.webp" },
  { page: 30, link: "https://i.ibb.co/C1pFTbn/0093-030.webp" },
  { page: 31, link: "https://i.ibb.co/XYRGV7Y/0093-031.webp" },
  { page: 32, link: "https://i.ibb.co/yfGrvyP/0093-032.webp" },
  { page: 33, link: "https://i.ibb.co/syH0mGG/0093-033.webp" }
]


export default listePages;