const listePages = [
	{ page: 1, link: 'https://i.ibb.co/cQkLxkz/001.webp' },
	{ page: 2, link: 'https://i.ibb.co/NyhQFYY/002.webp' },
	{ page: 3, link: 'https://i.ibb.co/JRFbt5T/003.webp' },
	{ page: 4, link: 'https://i.ibb.co/hXYJ96y/004.webp' },
	{ page: 5, link: 'https://i.ibb.co/DVdGmcT/005.webp' },
	{ page: 6, link: 'https://i.ibb.co/68nQsBC/006.webp' },
	{ page: 7, link: 'https://i.ibb.co/0cTvF3X/007.webp' },
	{ page: 8, link: 'https://i.ibb.co/FnfBP0M/008.webp' },
	{ page: 9, link: 'https://i.ibb.co/y0MghLB/009.webp' },
	{ page: 10, link: 'https://i.ibb.co/1XLQX4c/010.webp' },
	{ page: 11, link: 'https://i.ibb.co/J3dSHbx/011.webp' },
	{ page: 12, link: 'https://i.ibb.co/wzpZQLT/012.webp' },
	{ page: 13, link: 'https://i.ibb.co/L1bbQzC/013.webp' },
	{ page: 14, link: 'https://i.ibb.co/GRZT4hX/014.webp' },
	{ page: 15, link: 'https://i.ibb.co/dmCYzwT/015.webp' },
	{ page: 16, link: 'https://i.ibb.co/8BvMz7T/016.webp' },
	{ page: 17, link: 'https://i.ibb.co/zQPWykB/017.webp' },
	{ page: 18, link: 'https://i.ibb.co/gVpNXBF/018.webp' },
	{ page: 19, link: 'https://i.ibb.co/NVzhCSj/019-020.webp' },
	{ page: 20, link: 'https://i.ibb.co/QHky5LH/021.webp' },
	{ page: 21, link: 'https://i.ibb.co/rpn6ydP/022.webp' },
	{ page: 22, link: 'https://i.ibb.co/nzkGsyS/023.webp' },
	{ page: 23, link: 'https://i.ibb.co/XsKLH72/024.webp' },
	{ page: 24, link: 'https://i.ibb.co/7n0KrNL/025.webp' },
	{ page: 25, link: 'https://i.ibb.co/FsTNDjZ/026.webp' },
	{ page: 26, link: 'https://i.ibb.co/pK88TFR/027.webp' },
	{ page: 27, link: 'https://i.ibb.co/CvhG9cn/028.webp' },
	{ page: 28, link: 'https://i.ibb.co/1b8csGS/029-030.webp' },
	{ page: 29, link: 'https://i.ibb.co/rbKV4Rb/031.webp' },
	{ page: 30, link: 'https://i.ibb.co/WPkdsjz/032.webp' },
	{ page: 31, link: 'https://i.ibb.co/5BRhD41/033.webp' },
	{ page: 32, link: 'https://i.ibb.co/dcmG757/034.webp' },
	{ page: 33, link: 'https://i.ibb.co/YDW4WZC/035.webp' },
	{ page: 34, link: 'https://i.ibb.co/WDv058d/036.webp' },
	{ page: 35, link: 'https://i.ibb.co/d6mnGj5/037.webp' },
	{ page: 36, link: 'https://i.ibb.co/j5yx4K1/038.webp' },
	{ page: 37, link: 'https://i.ibb.co/51KB0BJ/039.webp' },
	{ page: 38, link: 'https://i.ibb.co/Q94f7Hm/040.webp' },
	{ page: 39, link: 'https://i.ibb.co/5R0qWt4/041.webp' },
	{ page: 40, link: 'https://i.ibb.co/VTgh1Sf/042.webp' },
	{ page: 41, link: 'https://i.ibb.co/WP2N2Yq/043.webp' },
	{ page: 42, link: 'https://i.ibb.co/r2djYF4/044.webp' },
	{ page: 43, link: 'https://i.ibb.co/wzL195z/045.webp' },
	{ page: 44, link: 'https://i.ibb.co/ZJPWpyz/046.webp' },
	{ page: 45, link: 'https://i.ibb.co/GCswtjp/047-048.webp' },	
	{ page: 46, link: 'https://i.ibb.co/48c6T46/049.webp' }
  ]


export default listePages;