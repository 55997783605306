const listePages = [
  {
    page: 45,
    link: "https://i.ibb.co/Ybn6X0c/23-5-001.webp"
  },
  {
    page: 46,
    link: "https://i.ibb.co/JdnT5Bt/23-5-002.webp"
  },
  {
    page: 47,
    link: "https://i.ibb.co/fkzzMDh/23-5-003.webp"
  },
  {
    page: 48,
    link: "https://i.ibb.co/pbhsZ4W/23-5-004.webp"
  },
  {
    page: 49,
    link: "https://i.ibb.co/V9nnfvp/23-5-005.webp"
  },
  {
    page: 50,
    link: "https://i.ibb.co/KVnfBt2/23-5-006.webp"
  },
  {
    page: 51,
    link: "https://i.ibb.co/bLzy1bY/23-5-007.webp"
  },
  {
    page: 52,
    link: "https://i.ibb.co/cLhR1X3/23-5-008.webp"
  },
  {
    page: 53,
    link: "https://i.ibb.co/k5vNvHt/23-5-009.webp"
  },
  {
    page: 54,
    link: "https://i.ibb.co/Z1fqd2C/23-5-010.webp"
  },
  {
    page: 55,
    link: "https://i.ibb.co/mXbbZN8/23-5-011.webp"
  },
  {
    page: 56,
    link: "https://i.ibb.co/yWBgmnX/23-5-012.webp"
  },
  {
    page: 57,
    link: "https://i.ibb.co/tJnzDyy/23-5-013.webp"
  },
  {
    page: 58,
    link: "https://i.ibb.co/hC4L55j/23-5-014.webp"
  },
  {
    page: 59,
    link: "https://i.ibb.co/BgVp7V4/23-5-015.webp"
  },
  {
    page: 60,
    link: "https://i.ibb.co/L8m5m85/23-5-016.webp"
  },
  {
    page: 61,
    link: "https://i.ibb.co/BsmZKNV/23-5-017.webp"
  },
  {
    page: 62,
    link: "https://i.ibb.co/Mc3Yh8B/23-5-018.webp"
  },
  {
    page: 63,
    link: "https://i.ibb.co/MPvKFYJ/23-5-019.webp"
  },
  {
    page: 64,
    link: "https://i.ibb.co/qYYQRrm/23-5-020.webp"
  },
  {
    page: 65,
    link: "https://i.ibb.co/p1JvyXY/23-5-021.webp"
  },
  {
    page: 66,
    link: "https://i.ibb.co/DLZQBcM/23-5-022.webp"
  },
  {
    page: 67,
    link: "https://i.ibb.co/d6RjGKV/23-5-023.webp"
  }  
]

export default listePages;