const listePages = [
    {
        page: 1,
        link: "https://i.ibb.co/rGxCNfL/049.webp"
      },
      {
        page: 2,
        link: "https://i.ibb.co/fXSdDRN/050.webp"
      },
      {
        page: 3,
        link: "https://i.ibb.co/DDtPPGt/051.webp"
      },
      {
        page: 4,
        link: "https://i.ibb.co/R65RdLL/052.webp"
      },
      {
        page: 5,
        link: "https://i.ibb.co/XZTyHqJ/053.webp"
      },
      {
        page: 6,
        link: "https://i.ibb.co/fdXqXKh/054.webp"
      },
      {
        page: 7,
        link: "https://i.ibb.co/v1Wj6Bm/055.webp"
      },
      {
        page: 8,
        link: "https://i.ibb.co/5skNxdQ/056.webp"
      },
      {
        page: 9,
        link: "https://i.ibb.co/chwj9Wy/057.webp"
      },
      {
        page: 10,
        link: "https://i.ibb.co/Bf2NMWM/058.webp"
      },
      {
        page: 11,
        link: "https://i.ibb.co/Np3XSxg/059.webp"
      },
      {
        page: 12,
        link: "https://i.ibb.co/grJBkr2/060.webp"
      },
      {
        page: 13,
        link: "https://i.ibb.co/2yjcj9c/061.webp"
      },
      {
        page: 14,
        link: "https://i.ibb.co/pRVLLnz/062.webp"
      },
      {
        page: 15,
        link: "https://i.ibb.co/0jhnFyG/063.webp"
      },
      {
        page: 16,
        link: "https://i.ibb.co/2YgF1R3/064.webp"
      },
      {
        page: 17,
        link: "https://i.ibb.co/GtZ1qxn/065.webp"
      },
      {
        page: 18,
        link: "https://i.ibb.co/f1Sjrvg/066.webp"
      },
      {
        page: 19,
        link: "https://i.ibb.co/tJfyd9K/067.webp"
      },
      {
        page: 20,
        link: "https://i.ibb.co/x5fv9x4/068.webp"
      },
      {
        page: 21,
        link: "https://i.ibb.co/KbsWdsD/069.webp"
      },
      {
        page: 22,
        link: "https://i.ibb.co/KmDP4SB/070.webp"
      },
      {
        page: 23,
        link: "https://i.ibb.co/dtmPjpV/071.webp"
      },
      {
        page: 24,
        link: "https://i.ibb.co/j8f3gwf/072.webp"
      },
      {
        page: 25,
        link: "https://i.ibb.co/M7cKH8d/073.webp"
      },
      {
        page: 26,
        link: "https://i.ibb.co/8gPmX6X/074.webp"
      },
      {
        page: 27,
        link: "https://i.ibb.co/GdCwjYQ/075.webp"
      },
      {
        page: 28,
        link: "https://i.ibb.co/nc2hqpq/076.webp"
      },
      {
        page: 29,
        link: "https://i.ibb.co/hLgMG4W/077.webp"
      },
      {
        page: 30,
        link: "https://i.ibb.co/vzF1hjZ/078.webp"
      },
      {
        page: 31,
        link: "https://i.ibb.co/xzHpZ2s/079.webp"
      },
      {
        page: 32,
        link: "https://i.ibb.co/vznMGMp/080.webp"
      },
      {
        page: 33,
        link: "https://i.ibb.co/ynsT3sk/081.webp"
      },
      {
        page: 34,
        link: "https://i.ibb.co/8NKcXT8/082.webp"
      },
      {
        page: 35,
        link: "https://i.ibb.co/RS7fWBm/083.webp"
      },
      {
        page: 36,
        link: "https://i.ibb.co/x8xyX0H/084.webp"
      }
]


export default listePages;