const listePages = [
    { page: 1, link: "https://i.ibb.co/2n2Gg70/0074-001.webp" },
    { page: 2, link: "https://i.ibb.co/jVfR79b/0074-002.webp" },
    { page: 3, link: "https://i.ibb.co/kKyLjWc/0074-003.webp" },
    { page: 4, link: "https://i.ibb.co/XDGbV44/0074-004.webp" },
    { page: 5, link: "https://i.ibb.co/MBPh5m1/0074-005.webp" },
    { page: 6, link: "https://i.ibb.co/4TwJY3z/0074-006.webp" },
    { page: 7, link: "https://i.ibb.co/Jdt2ryY/0074-007.webp" },
    { page: 8, link: "https://i.ibb.co/3zX3wz2/0074-008.webp" },
    { page: 9, link: "https://i.ibb.co/GWjtQ0R/0074-009.webp" },
    { page: 10, link: "https://i.ibb.co/NNxHRdV/0074-010.webp" },
    { page: 11, link: "https://i.ibb.co/N3dmq0k/0074-011.webp" },
    { page: 12, link: "https://i.ibb.co/N9TyzfZ/0074-012.webp" },
    { page: 13, link: "https://i.ibb.co/SK7q3Rr/0074-013.webp" },
    { page: 14, link: "https://i.ibb.co/FHWrQ9b/0074-014.webp" },
    { page: 15, link: "https://i.ibb.co/vQYvF5Q/0074-015.webp" },
    { page: 16, link: "https://i.ibb.co/gwFZQdc/0074-016.webp" },
    { page: 17, link: "https://i.ibb.co/b3SjTyf/0074-017.webp" },
    { page: 18, link: "https://i.ibb.co/rGWyh9P/0074-018.webp" },
    { page: 19, link: "https://i.ibb.co/BfqtRhd/0074-019.webp" },
    { page: 20, link: "https://i.ibb.co/f2yYg2x/0074-020.webp" },
    { page: 21, link: "https://i.ibb.co/zfhT473/0074-021.webp" },
    { page: 22, link: "https://i.ibb.co/FqDwv40/0074-022.webp" },
    { page: 23, link: "https://i.ibb.co/mb4Fprg/0074-023.webp" },
    { page: 24, link: "https://i.ibb.co/THNFsZD/0074-024.webp" },
    { page: 25, link: "https://i.ibb.co/vQk0jQ9/0074-025.webp" },
    { page: 26, link: "https://i.ibb.co/2SvzFt8/0074-026.webp" },
    { page: 27, link: "https://i.ibb.co/QYp9pqm/0074-027.webp" },
    { page: 28, link: "https://i.ibb.co/YbhpTNP/0074-028.webp" },
    { page: 29, link: "https://i.ibb.co/y5nJHnm/0074-029.webp" },
    { page: 30, link: "https://i.ibb.co/1rM6fRH/0074-030.webp" },
    { page: 31, link: "https://i.ibb.co/9HfD659/0074-031.webp" },
    { page: 32, link: "https://i.ibb.co/jRXHdJn/0074-032.webp" }
  ]
  

export default listePages;