const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/58tHkdR/0104-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/8Xh7PQZ/0104-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/TvdPN8y/0104-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/FV9zPNm/0104-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/gzqN4hY/0104-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/hCt8C9V/0104-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/TtnvmPk/0104-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/TY22PNS/0104-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/dLbrmT4/0104-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/prXbFKJ/0104-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/c2XjbXM/0104-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/RN8yz5t/0104-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/98VxG2P/0104-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/8d3WPx7/0104-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/5vhB6T3/0104-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/54j0N15/0104-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/Jn097vn/0104-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/bX50QF2/0104-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/n75sp2D/0104-019.webp"
  },
  {
    page: 20,
    link: "https://i.ibb.co/8zPXhvk/0104-020.webp"
  },
  {
    page: 21,
    link: "https://i.ibb.co/3s9K17C/0104-021.webp"
  },
  {
    page: 22,
    link: "https://i.ibb.co/XFjbn0s/0104-022.webp"
  },
  {
    page: 23,
    link: "https://i.ibb.co/bbqxG3q/0104-023.webp"
  },
  {
    page: 24,
    link: "https://i.ibb.co/3Ng85Fy/0104-024.webp"
  },
  {
    page: 25,
    link: "https://i.ibb.co/DPXDCwQ/0104-025.webp"
  },
  {
    page: 26,
    link: "https://i.ibb.co/PZBhk7F/0104-026.webp"
  },
  {
    page: 27,
    link: "https://i.ibb.co/64f79mc/0104-027.webp"
  },
  {
    page: 28,
    link: "https://i.ibb.co/16dNMRk/0104-028.webp"
  },
  {
    page: 29,
    link: "https://i.ibb.co/ZGQTnfV/0104-029.webp"
  },
  {
    page: 30,
    link: "https://i.ibb.co/GTCL4zB/0104-030.webp"
  },
  {
    page: 31,
    link: "https://i.ibb.co/Bs3J70x/0104-031.webp"
  }
]


export default listePages;