const listePages = [
    { page: 1, link: "https://i.ibb.co/f0szY3g/59-5-001.webp" },
    { page: 2, link: "https://i.ibb.co/r0GWwk5/59-5-002.webp" },
    { page: 3, link: "https://i.ibb.co/kK6BV16/59-5-003.webp" },
    { page: 4, link: "https://i.ibb.co/MN43bRD/59-5-004.webp" },
    { page: 5, link: "https://i.ibb.co/vHmkWYm/59-5-005.webp" },
    { page: 6, link: "https://i.ibb.co/X4w6L9S/59-5-006.webp" },
    { page: 7, link: "https://i.ibb.co/5YDwNH3/59-5-007.webp" },
    { page: 8, link: "https://i.ibb.co/XXj45F0/59-5-008.webp" },
    { page: 9, link: "https://i.ibb.co/rQnvqFK/59-5-009.webp" },
    { page: 10, link: "https://i.ibb.co/8MgLTVG/59-5-010.webp" },
    { page: 11, link: "https://i.ibb.co/xfHMhd2/59-5-011.webp" },
    { page: 12, link: "https://i.ibb.co/JFq97sQ/59-5-012.webp" }
  ]
  

export default listePages;