const listePages = [
    {
      "page": 1,
      "link": "https://i.ibb.co/MpSgP0p/0126.webp"
    },
    {
      "page": 2,
      "link": "https://i.ibb.co/qrLqPPM/0127.webp"
    },
    {
      "page": 3,
      "link": "https://i.ibb.co/6JTwptz/0128.webp"
    },
    {
      "page": 4,
      "link": "https://i.ibb.co/WK4XTcF/0129.webp"
    },
    {
      "page": 5,
      "link": "https://i.ibb.co/R2fxSj7/0130.webp"
    },
    {
      "page": 6,
      "link": "https://i.ibb.co/WpKnF5B/0131.webp"
    },
    {
      "page": 7,
      "link": "https://i.ibb.co/GPkpj15/0132.webp"
    },
    {
      "page": 8,
      "link": "https://i.ibb.co/KN3jSpk/0133.webp"
    },
    {
      "page": 9,
      "link": "https://i.ibb.co/bFK8kqY/0134.webp"
    },
    {
      "page": 10,
      "link": "https://i.ibb.co/DG39tjF/0135.webp"
    },
    {
      "page": 11,
      "link": "https://i.ibb.co/ryVdQyj/0136.webp"
    },
    {
      "page": 12,
      "link": "https://i.ibb.co/5BKm8Kt/0137.webp"
    },
    {
      "page": 13,
      "link": "https://i.ibb.co/0KPsgwt/0138.webp"
    },
    {
      "page": 14,
      "link": "https://i.ibb.co/rwvZ1WB/0139.webp"
    },
    {
      "page": 15,
      "link": "https://i.ibb.co/XpR5HYC/0140.webp"
    },
    {
      "page": 16,
      "link": "https://i.ibb.co/k5j0Sgy/0141.webp"
    },
    {
      "page": 17,
      "link": "https://i.ibb.co/nsVvGjQ/0142.webp"
    },
    {
      "page": 18,
      "link": "https://i.ibb.co/VVgGrpL/0143.webp"
    },
    {
      "page": 19,
      "link": "https://i.ibb.co/F0GdCvP/0144.webp"
    },
    {
      "page": 20,
      "link": "https://i.ibb.co/02chG6H/0145.webp"
    },
    {
      "page": 21,
      "link": "https://i.ibb.co/k3rYt2p/0146.webp"
    },
    {
      "page": 22,
      "link": "https://i.ibb.co/h9b0WZL/0147.webp"
    },
    {
      "page": 23,
      "link": "https://i.ibb.co/8YXy4wk/0148.webp"
    },
    {
      "page": 24,
      "link": "https://i.ibb.co/Wn9f7q5/0149.webp"
    },
    {
      "page": 25,
      "link": "https://i.ibb.co/tY1CNdq/0150.webp"
    },
    {
      "page": 26,
      "link": "https://i.ibb.co/mzDg4gr/0151.webp"
    },
    {
      "page": 27,
      "link": "https://i.ibb.co/LvtqYVX/0152.webp"
    },
    {
      "page": 28,
      "link": "https://i.ibb.co/FW24t6X/0153.webp"
    },
    {
      "page": 29,
      "link": "https://i.ibb.co/cvqF6YD/0154.webp"
    },
    {
      "page": 30,
      "link": "https://i.ibb.co/fSGVT3D/0155.webp"
    },
    {
      "page": 31,
      "link": "https://i.ibb.co/8063Nnx/0156.webp"
    },
    {
      "page": 32,
      "link": "https://i.ibb.co/mym3qTH/0157.webp"
    },
    {
      "page": 33,
      "link": "https://i.ibb.co/n89znNy/0158.webp"
    },
    {
      "page": 34,
      "link": "https://i.ibb.co/s9QNSdk/0159.webp"
    },
    {
      "page": 35,
      "link": "https://i.ibb.co/g37nWVn/0160.webp"
    },
    {
      "page": 36,
      "link": "https://i.ibb.co/gWzd8HY/0161.webp"
    },
    {
      "page": 37,
      "link": "https://i.ibb.co/StrLzb3/0162.webp"
    },
    {
      "page": 38,
      "link": "https://i.ibb.co/RSNKXnq/0163.webp"
    },
    {
      "page": 39,
      "link": "https://i.ibb.co/5vrC2zh/0164.webp"
    },
    {
      "page": 40,
      "link": "https://i.ibb.co/ZWtHSSZ/0165.webp"
    },
    {
      "page": 41,
      "link": "https://i.ibb.co/BzcvDc0/0166.webp"
    },
    {
      "page": 42,
      "link": "https://i.ibb.co/0VLcV37/0167.webp"
    },
    {
      "page": 43,
      "link": "https://i.ibb.co/XSfSVxT/0168.webp"
    }
  ]


export default listePages;