const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/R0M95n8/0109-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/JdPCBPw/0109-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/H7dwXsf/0109-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/pwW1FsS/0109-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/LQgwnD2/0109-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/pvBQGh2/0109-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/LYy0Gww/0109-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/dkkJBRD/0109-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/rssXky4/0109-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/NYD7089/0109-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/brjYsTb/0109-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/PMSwtgf/0109-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/fqS65mk/0109-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/QKrRC3m/0109-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/0s8RV3v/0109-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/wRYjLHG/0109-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/PcCBZJ2/0109-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/cNYYftY/0109-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/wNcBRVC/0109-019.webp"
  },
  {
    page: 20,
    link: "https://i.ibb.co/vcPRWND/0109-020.webp"
  },
  {
    page: 21,
    link: "https://i.ibb.co/PwFXHyY/0109-021.webp"
  },
  {
    page: 22,
    link: "https://i.ibb.co/GsfLTQY/0109-022.webp"
  },
  {
    page: 23,
    link: "https://i.ibb.co/gtPmK8R/0109-023.webp"
  },
  {
    page: 24,
    link: "https://i.ibb.co/1974HjL/0109-024.webp"
  },
  {
    page: 25,
    link: "https://i.ibb.co/JsWF790/0109-025.webp"
  },
  {
    page: 26,
    link: "https://i.ibb.co/KhXdBhG/0109-026.webp"
  },
  {
    page: 27,
    link: "https://i.ibb.co/gg7MjGR/0109-027.webp"
  },
  {
    page: 28,
    link: "https://i.ibb.co/rFKfVdr/0109-028.webp"
  },
  {
    page: 29,
    link: "https://i.ibb.co/FbzcV42/0109-029.webp"
  },
  {
    page: 30,
    link: "https://i.ibb.co/yP32Cz0/0109-030.webp"
  }
]


export default listePages;