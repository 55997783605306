const listePages = [
    {
        "page": 1,
        "link": "https://i.ibb.co/2vBvr2Z/0084.webp"
      },
      {
        "page": 2,
        "link": "https://i.ibb.co/FWXbRRr/0085.webp"
      },
      {
        "page": 3,
        "link": "https://i.ibb.co/RS3VC79/0086.webp"
      },
      {
        "page": 4,
        "link": "https://i.ibb.co/y5JCRdY/0087.webp"
      },
      {
        "page": 5,
        "link": "https://i.ibb.co/p1JW6qN/0088.webp"
      },
      {
        "page": 6,
        "link": "https://i.ibb.co/N7Z02r5/0089.webp"
      },
      {
        "page": 7,
        "link": "https://i.ibb.co/YPj25xK/0090.webp"
      },
      {
        "page": 8,
        "link": "https://i.ibb.co/rMtyDR9/0091.webp"
      },
      {
        "page": 9,
        "link": "https://i.ibb.co/wcydTVc/0092.webp"
      },
      {
        "page": 10,
        "link": "https://i.ibb.co/rZJKfzB/0093.webp"
      },
      {
        "page": 11,
        "link": "https://i.ibb.co/QrZtm3k/0094.webp"
      },
      {
        "page": 12,
        "link": "https://i.ibb.co/pxqCmMn/0095.webp"
      },
      {
        "page": 13,
        "link": "https://i.ibb.co/DtC1ftK/0096.webp"
      },
      {
        "page": 14,
        "link": "https://i.ibb.co/Swd0n7p/0097.webp"
      },
      {
        "page": 15,
        "link": "https://i.ibb.co/7SWH6cY/0098.webp"
      },
      {
        "page": 16,
        "link": "https://i.ibb.co/F308H7n/0099.webp"
      },
      {
        "page": 17,
        "link": "https://i.ibb.co/rpFfVWB/0100.webp"
      },
      {
        "page": 18,
        "link": "https://i.ibb.co/pvxQ6xH/0101.webp"
      },
      {
        "page": 19,
        "link": "https://i.ibb.co/8KWQt5q/0102.webp"
      },
      {
        "page": 20,
        "link": "https://i.ibb.co/C91VZ44/0103.webp"
      },
      {
        "page": 21,
        "link": "https://i.ibb.co/26mrwBb/0104.webp"
      },
      {
        "page": 22,
        "link": "https://i.ibb.co/J2Rg0kT/0105.webp"
      },
      {
        "page": 23,
        "link": "https://i.ibb.co/L62Q2jB/0106.webp"
      },
      {
        "page": 24,
        "link": "https://i.ibb.co/PhTjKy0/0107.webp"
      },
      {
        "page": 25,
        "link": "https://i.ibb.co/mSb2S8b/0108.webp"
      },
      {
        "page": 26,
        "link": "https://i.ibb.co/qYv4fWj/0109.webp"
      },
      {
        "page": 27,
        "link": "https://i.ibb.co/JFHV3BV/0110.webp"
      },
      {
        "page": 28,
        "link": "https://i.ibb.co/1Gsj3v9/0111.webp"
      },
      {
        "page": 29,
        "link": "https://i.ibb.co/NKXcDPX/0112.webp"
      },
      {
        "page": 30,
        "link": "https://i.ibb.co/4g3NK9J/0113.webp"
      },
      {
        "page": 31,
        "link": "https://i.ibb.co/twR4crD/0114.webp"
      },
      {
        "page": 32,
        "link": "https://i.ibb.co/dPsvmWC/0115.webp"
      },
      {
        "page": 33,
        "link": "https://i.ibb.co/xGW8Jjj/0116.webp"
      },
      {
        "page": 34,
        "link": "https://i.ibb.co/9hm5MQq/0117.webp"
      },
      {
        "page": 35,
        "link": "https://i.ibb.co/NZ9G8Qq/0118.webp"
      },
      {
        "page": 36,
        "link": "https://i.ibb.co/bNKnXh2/0119.webp"
      },
      {
        "page": 37,
        "link": "https://i.ibb.co/CtKP8fQ/0120.webp"
      },
      {
        "page": 38,
        "link": "https://i.ibb.co/2q01r2v/0121.webp"
      },
      {
        "page": 39,
        "link": "https://i.ibb.co/9gphS5j/0122.webp"
      },
      {
        "page": 40,
        "link": "https://i.ibb.co/Lhj0Y7D/0123.webp"
      },
      {
        "page": 41,
        "link": "https://i.ibb.co/wztdQRD/0124.webp"
      },
      {
        "page": 42,
        "link": "https://i.ibb.co/8bPCD0g/0125.webp"
      }
]


export default listePages;