const listePages = [
    {
        page: 1,
        link: "https://i.ibb.co/nMKfbNm/0139.webp"
      },
      {
        page: 2,
        link: "https://i.ibb.co/n7JMGW1/0140.webp"
      },
      {
        page: 3,
        link: "https://i.ibb.co/k08qcjr/0141.webp"
      },
      {
        page: 4,
        link: "https://i.ibb.co/B3HRf5v/0142.webp"
      },
      {
        page: 5,
        link: "https://i.ibb.co/BnDKvk6/0143.webp"
      },
      {
        page: 6,
        link: "https://i.ibb.co/88z59x0/0144.webp"
      },
      {
        page: 7,
        link: "https://i.ibb.co/QfrgMjJ/0145.webp"
      },
      {
        page: 8,
        link: "https://i.ibb.co/SdLXP7d/0146.webp"
      },
      {
        page: 9,
        link: "https://i.ibb.co/VLRrMqf/0147.webp"
      },
      {
        page: 10,
        link: "https://i.ibb.co/VSGjBH0/0148.webp"
      },
      {
        page: 11,
        link: "https://i.ibb.co/8gCJkzx/0149.webp"
      },
      {
        page: 12,
        link: "https://i.ibb.co/9qJSF1j/0150.webp"
      },
      {
        page: 13,
        link: "https://i.ibb.co/tDMfMWz/0151.webp"
      },
      {
        page: 14,
        link: "https://i.ibb.co/vVYJ9DT/0152.webp"
      },
      {
        page: 15,
        link: "https://i.ibb.co/8rKpsZ2/0153.webp"
      },
      {
        page: 16,
        link: "https://i.ibb.co/2WDnbrk/0154.webp"
      },
      {
        page: 17,
        link: "https://i.ibb.co/8B9NXKw/0155.webp"
      },
      {
        page: 18,
        link: "https://i.ibb.co/w0LgjSL/0156.webp"
      },
      {
        page: 19,
        link: "https://i.ibb.co/BCWmH1d/0157.webp"
      },
      {
        page: 20,
        link: "https://i.ibb.co/pfg446L/0158.webp"
      },
      {
        page: 21,
        link: "https://i.ibb.co/LgtKGbh/0159.webp"
      },
      {
        page: 22,
        link: "https://i.ibb.co/Jzc9GPG/0160.webp"
      },
      {
        page: 23,
        link: "https://i.ibb.co/nLLfqqq/0161.webp"
      },
      {
        page: 24,
        link: "https://i.ibb.co/Tk9TdPh/0162.webp"
      },
      {
        page: 25,
        link: "https://i.ibb.co/z7Pjp6q/0163.webp"
      },
      {
        page: 26,
        link: "https://i.ibb.co/tbSXZC6/0164.webp"
      },
      {
        page: 27,
        link: "https://i.ibb.co/KqDLnGk/0165.webp"
      },
      {
        page: 28,
        link: "https://i.ibb.co/4Vx3KvC/0166.webp"
      },
      {
        page: 29,
        link: "https://i.ibb.co/SV5C9tF/0167.webp"
      },
      {
        page: 30,
        link: "https://i.ibb.co/s5hqgSn/0168.webp"
      },
      {
        page: 31,
        link: "https://i.ibb.co/5LfFsGY/0169.webp"
      },
      {
        page: 32,
        link: "https://i.ibb.co/CWZ895Q/0170.webp"
      },
      {
        page: 33,
        link: "https://i.ibb.co/W3SbFhm/0171.webp"
      },
      {
        page: 34,
        link: "https://i.ibb.co/f9hmQrc/0172.webp"
      },
      {
        page: 35,
        link: "https://i.ibb.co/F7NYz7M/0173.webp"
      },
      {
        page: 36,
        link: "https://i.ibb.co/2yFp3X9/0174.webp"
      },
      {
        page: 37,
        link: "https://i.ibb.co/7gp0DyB/0175.webp"
      },
      {
        page: 38,
        link: "https://i.ibb.co/zxJQG8w/0176.webp"
      },
      {
        page: 39,
        link: "https://i.ibb.co/wR08zgN/0177.webp"
      },
      {
        page: 40,
        link: "https://i.ibb.co/SrCkXFN/0178.webp"
      }
]


export default listePages;