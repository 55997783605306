const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/kmVH5r7/0049-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/jbw3dyJ/0049-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/YRJGk7D/0049-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/61JCVwz/0049-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/nL7MDnW/0049-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/J5qr9mp/0049-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/SxQMBpW/0049-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/qrQNfWV/0049-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/wCSJVW0/0049-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/nsszh0W/0049-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/7GBv2WC/0049-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/TL4WzNQ/0049-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/dbMSqNV/0049-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/F0vW9xK/0049-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/k2LyhRb/0049-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/6Wj1qsb/0049-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/HG6G3X8/0049-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/b6H7qjt/0049-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/0BgmqqS/0049-019.webp"
  },
  {
    page: 20,
    link: "https://i.ibb.co/LrNbJns/0049-020.webp"
  },
  {
    page: 21,
    link: "https://i.ibb.co/f48DQfW/0049-021.webp"
  },
  {
    page: 22,
    link: "https://i.ibb.co/x3K5pZV/0049-022.webp"
  },
  {
    page: 23,
    link: "https://i.ibb.co/p0nywCr/0049-023.webp"
  },
  {
    page: 24,
    link: "https://i.ibb.co/bKk4WZ1/0049-024.webp"
  },
  {
    page: 25,
    link: "https://i.ibb.co/wYwqdSd/0049-025.webp"
  },
  {
    page: 26,
    link: "https://i.ibb.co/7jyqMB1/0049-026.webp"
  },
  {
    page: 27,
    link: "https://i.ibb.co/Wsc37Jb/0049-027.webp"
  },
  {
    page: 28,
    link: "https://i.ibb.co/XLVJssW/0049-028.webp"
  },
  {
    page: 29,
    link: "https://i.ibb.co/gZ6XhYK/0049-029.webp"
  },
  {
    page: 30,
    link: "https://i.ibb.co/MMvbYYd/0049-030.webp"
  },
  {
    page: 31,
    link: "https://i.ibb.co/MDHC5Ht/0049-031.webp"
  },
  {
    page: 32,
    link: "https://i.ibb.co/bFNR2wv/0049-032.webp"
  },
  {
    page: 33,
    link: "https://i.ibb.co/nsMGw2G/0049-033.webp"
  },
  {
    page: 34,
    link: "https://i.ibb.co/SNG61MY/0049-034.webp"
  },
  {
    page: 35,
    link: "https://i.ibb.co/ZfFmPcB/0049-035.webp"
  },
  {
    page: 36,
    link: "https://i.ibb.co/gyBG1qy/0049-036.webp"
  },
  {
    page: 37,
    link: "https://i.ibb.co/nnC5mj2/0049-037.webp"
  },
  {
    page: 38,
    link: "https://i.ibb.co/NKR99K9/0049-038.webp"
  }
];


export default listePages;