const listePages = [
    {
      "page": 1,
      "link": "https://i.ibb.co/tMpDWdx/0098-001.webp"
    },
    {
      "page": 2,
      "link": "https://i.ibb.co/bBztcrP/0098-002.webp"
    },
    {
      "page": 3,
      "link": "https://i.ibb.co/S3d6Xmq/0098-003.webp"
    },
    {
      "page": 4,
      "link": "https://i.ibb.co/1Ly0QqW/0098-004.webp"
    },
    {
      "page": 5,
      "link": "https://i.ibb.co/QmXS8yw/0098-005.webp"
    },
    {
      "page": 6,
      "link": "https://i.ibb.co/NVRj7dC/0098-006.webp"
    },
    {
      "page": 7,
      "link": "https://i.ibb.co/5c9YFgV/0098-007.webp"
    },
    {
      "page": 8,
      "link": "https://i.ibb.co/sR3VmFm/0098-008.webp"
    },
    {
      "page": 9,
      "link": "https://i.ibb.co/7YKdk05/0098-009.webp"
    },
    {
      "page": 10,
      "link": "https://i.ibb.co/fSqbsZp/0098-010.webp"
    },
    {
      "page": 11,
      "link": "https://i.ibb.co/vm2RvJ8/0098-011.webp"
    },
    {
      "page": 12,
      "link": "https://i.ibb.co/LvcMLD2/0098-012.webp"
    },
    {
      "page": 13,
      "link": "https://i.ibb.co/CzRRd1G/0098-013.webp"
    },
    {
      "page": 14,
      "link": "https://i.ibb.co/nLYSP0Z/0098-014.webp"
    },
    {
      "page": 15,
      "link": "https://i.ibb.co/g7G8Wz5/0098-015.webp"
    },
    {
      "page": 16,
      "link": "https://i.ibb.co/Jsb9c2V/0098-016.webp"
    },
    {
      "page": 17,
      "link": "https://i.ibb.co/KXQfvCw/0098-017.webp"
    },
    {
      "page": 18,
      "link": "https://i.ibb.co/xzmdvy2/0098-018.webp"
    },
    {
      "page": 19,
      "link": "https://i.ibb.co/mGSZg0J/0098-019.webp"
    },
    {
      "page": 20,
      "link": "https://i.ibb.co/w7zyfyG/0098-020.webp"
    },
    {
      "page": 21,
      "link": "https://i.ibb.co/gVp2Z2b/0098-021.webp"
    },
    {
      "page": 22,
      "link": "https://i.ibb.co/LdTdV9W/0098-022.webp"
    },
    {
      "page": 23,
      "link": "https://i.ibb.co/1JQh2VL/0098-023.webp"
    },
    {
      "page": 24,
      "link": "https://i.ibb.co/fDbDS8L/0098-024.webp"
    },
    {
      "page": 25,
      "link": "https://i.ibb.co/Qdm865R/0098-025.webp"
    },
    {
      "page": 26,
      "link": "https://i.ibb.co/fQxwvy2/0098-026.webp"
    },
    {
      "page": 27,
      "link": "https://i.ibb.co/RB0HB9G/0098-027.webp"
    }
  ]

export default listePages;