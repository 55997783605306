const listePages = [
    {
        page: 1,
        link: "https://i.ibb.co/dD817fw/001.webp"
      },
      {
        page: 2,
        link: "https://i.ibb.co/F5gHkzW/003.webp"
      },
      {
        page: 3,
        link: "https://i.ibb.co/3vBfG3B/004.webp"
      },
      {
        page: 4,
        link: "https://i.ibb.co/fNN9Gvs/005.webp"
      },
      {
        page: 5,
        link: "https://i.ibb.co/z6P6NkS/006.webp"
      },
      {
        page: 6,
        link: "https://i.ibb.co/rkPWSV8/007.webp"
      },
      {
        page: 7,
        link: "https://i.ibb.co/y8VgMm5/008.webp"
      },
      {
        page: 8,
        link: "https://i.ibb.co/hLyhShZ/009.webp"
      },
      {
        page: 9,
        link: "https://i.ibb.co/RDt553C/010.webp"
      },
      {
        page: 10,
        link: "https://i.ibb.co/ZXqNqXm/011.webp"
      },
      {
        page: 11,
        link: "https://i.ibb.co/0QJfM2D/012.webp"
      },
      {
        page: 12,
        link: "https://i.ibb.co/S6PfTv2/013.webp"
      },
      {
        page: 13,
        link: "https://i.ibb.co/CJ7fLqJ/014.webp"
      },
      {
        page: 14,
        link: "https://i.ibb.co/zrQq5yD/015.webp"
      },
      {
        page: 15,
        link: "https://i.ibb.co/kHN4x12/016.webp"
      },
      {
        page: 16,
        link: "https://i.ibb.co/3f8BV7R/017.webp"
      },
      {
        page: 17,
        link: "https://i.ibb.co/JHfw6tL/018.webp"
      },
      {
        page: 18,
        link: "https://i.ibb.co/ZfJfmyR/019.webp"
      },
      {
        page: 19,
        link: "https://i.ibb.co/dB20n65/020.webp"
      },
      {
        page: 20,
        link: "https://i.ibb.co/M95MDHv/021.webp"
      },
      {
        page: 21,
        link: "https://i.ibb.co/dmB34bB/022.webp"
      },
      {
        page: 22,
        link: "https://i.ibb.co/tXyKXc4/023.webp"
      },
      {
        page: 23,
        link: "https://i.ibb.co/9hSNXgq/024.webp"
      },
      {
        page: 24,
        link: "https://i.ibb.co/kcmHGNm/025.webp"
      },
      {
        page: 25,
        link: "https://i.ibb.co/xh7Z5pc/026.webp"
      },
      {
        page: 26,
        link: "https://i.ibb.co/ZXFbVWX/027.webp"
      },
      {
        page: 27,
        link: "https://i.ibb.co/fQV8XLY/028.webp"
      },
      {
        page: 28,
        link: "https://i.ibb.co/jWC03jh/029.webp"
      },
      {
        page: 29,
        link: "https://i.ibb.co/7pgy15x/030.webp"
      },
      {
        page: 30,
        link: "https://i.ibb.co/SPn9xSK/031.webp"
      },
      {
        page: 31,
        link: "https://i.ibb.co/b2GJWb3/032.webp"
      },
      {
        page: 32,
        link: "https://i.ibb.co/PChfP4c/033.webp"
      },
      {
        page: 33,
        link: "https://i.ibb.co/yd9T0fw/034.webp"
      },
      {
        page: 34,
        link: "https://i.ibb.co/9GNQy4P/035.webp"
      },
      {
        page: 35,
        link: "https://i.ibb.co/ySt0Phq/036.webp"
      },
      {
        page: 36,
        link: "https://i.ibb.co/GFsNWZW/037.webp"
      },
      {
        page: 37,
        link: "https://i.ibb.co/88SCqNK/038.webp"
      },
      {
        page: 38,
        link: "https://i.ibb.co/sHkt08s/039.webp"
      },
      {
        page: 39,
        link: "https://i.ibb.co/sgSWNQC/040.webp"
      },
      {
        page: 40,
        link: "https://i.ibb.co/mRB6KBN/041.webp"
      },
      {
        page: 41,
        link: "https://i.ibb.co/7zcdPY3/042.webp"
      },
      {
        page: 42,
        link: "https://i.ibb.co/WGghsFx/043.webp"
      },
      {
        page: 43,
        link: "https://i.ibb.co/4jYqD5K/044.webp"
      },
      {
        page: 44,
        link: "https://i.ibb.co/ZYQTQNG/045.webp"
      },
      {
        page: 45,
        link: "https://i.ibb.co/ccfyn43/046.webp"
      },
      {
        page: 46,
        link: "https://i.ibb.co/ncbKZc9/047.webp"
      },
      {
        page: 47,
        link: "https://i.ibb.co/JkV6Vtn/048.webp"
      },
      {
        page: 48,
        link: "https://i.ibb.co/Bz6czWt/049.webp"
      },
      {
        page: 49,
        link: "https://i.ibb.co/G9C6d45/050.webp"
      }
]


export default listePages;