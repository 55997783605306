const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/B6W4WLG/0001-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/S3ndfzQ/0001-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/1JBL9Vj/0001-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/fSg7q4c/0001-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/643ZdfW/0001-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/S079XvK/0001-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/3zLDwv7/0001-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/JCPsTw5/0001-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/d4CcYry/0001-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/BZDKWp4/0001-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/9yqyXDx/0001-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/wwZNpBZ/0001-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/CVrbSpB/0001-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/G74Jrkb/0001-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/dbryTxp/0001-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/sCgwsNG/0001-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/mFvT8s5/0001-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/9TL4qsH/0001-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/q09fGTb/0001-019.webp"
  },
  {
    page: 20,
    link: "https://i.ibb.co/TbP8Rgm/0001-020.webp"
  },
  {
    page: 21,
    link: "https://i.ibb.co/pJZFPQj/0001-021.webp"
  },
  {
    page: 22,
    link: "https://i.ibb.co/3BLFH3d/0001-022.webp"
  },
  {
    page: 23,
    link: "https://i.ibb.co/7npCDM6/0001-023.webp"
  },
  {
    page: 24,
    link: "https://i.ibb.co/9ZMtSLT/0001-024.webp"
  },
  {
    page: 25,
    link: "https://i.ibb.co/b7bBZRD/0001-025.webp"
  },
  {
    page: 26,
    link: "https://i.ibb.co/ypVVH5q/0001-026.webp"
  },
  {
    page: 27,
    link: "https://i.ibb.co/jRgMW7v/0001-027.webp"
  },
  {
    page: 28,
    link: "https://i.ibb.co/PCJ7zd5/0001-028.webp"
  },
  {
    page: 29,
    link: "https://i.ibb.co/V3xCkvm/0001-029.webp"
  },
  {
    page: 30,
    link: "https://i.ibb.co/cbLqNDJ/0001-030.webp"
  },
  {
    page: 31,
    link: "https://i.ibb.co/fDz0QT7/0001-031.webp"
  },
  {
    page: 32,
    link: "https://i.ibb.co/3cZY2LG/0001-032.webp"
  },
  {
    page: 33,
    link: "https://i.ibb.co/F4XWwm1/0001-033.webp"
  },
  {
    page: 34,
    link: "https://i.ibb.co/pX4PRvy/0001-034.webp"
  },
  {
    page: 35,
    link: "https://i.ibb.co/dkqDCf3/0001-035.webp"
  },
  {
    page: 36,
    link: "https://i.ibb.co/sbrspxv/0001-036.webp"
  },
  {
    page: 37,
    link: "https://i.ibb.co/2F0nrN6/0001-037.webp"
  },
  {
    page: 38,
    link: "https://i.ibb.co/T43Y8gW/0001-038.webp"
  },
  {
    page: 39,
    link: "https://i.ibb.co/0DBjKj2/0001-039.webp"
  },
  {
    page: 40,
    link: "https://i.ibb.co/SKtss58/0001-040.webp"
  },
  {
    page: 41,
    link: "https://i.ibb.co/F6w182c/0001-041.webp"
  },
  {
    page: 42,
    link: "https://i.ibb.co/DtKtg1b/0001-042.webp"
  },
  {
    page: 43,
    link: "https://i.ibb.co/xfm20mg/0001-043.webp"
  },
  {
    page: 44,
    link: "https://i.ibb.co/CKKP1xx/0001-044.webp"
  },
  {
    page: 45,
    link: "https://i.ibb.co/WHPsvm5/0001-045.webp"
  },
  {
    page: 46,
    link: "https://i.ibb.co/NSQDYkN/0001-046.webp"
  },
  {
    page: 47,
    link: "https://i.ibb.co/jv75HLM/0001-047.webp"
  },
  {
    page: 48,
    link: "https://i.ibb.co/YLF001w/0001-048.webp"
  },
  {
    page: 49,
    link: "https://i.ibb.co/gJ3hpVh/0001-049.webp"
  },
  {
    page: 50,
    link: "https://i.ibb.co/vLDmKpS/0001-050.webp"
  },
  {
    page: 51,
    link: "https://i.ibb.co/mX28tJm/0001-051.webp"
  }
]

export default listePages;