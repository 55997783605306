const listePages = [
    
        {
          "page": 1,
          "link": "https://i.ibb.co/9Nqyr8r/0001.webp"
        },
        {
          "page": 2,
          "link": "https://i.ibb.co/prJb8q7/0005.webp"
        },
        {
          "page": 3,
          "link": "https://i.ibb.co/9c6HwJc/0006.webp"
        },
        {
          "page": 4,
          "link": "https://i.ibb.co/PtrhL42/0007.webp"
        },
        {
          "page": 5,
          "link": "https://i.ibb.co/N97zxjQ/0008.webp"
        },
        {
          "page": 6,
          "link": "https://i.ibb.co/LrMM4v9/0009.webp"
        },
        {
          "page": 7,
          "link": "https://i.ibb.co/PM8r5Ns/0010.webp"
        },
        {
          "page": 8,
          "link": "https://i.ibb.co/N7VL792/0011.webp"
        },
        {
          "page": 9,
          "link": "https://i.ibb.co/MGMSHQY/0012.webp"
        },
        {
          "page": 10,
          "link": "https://i.ibb.co/jrVFCht/0013.webp"
        },
        {
          "page": 11,
          "link": "https://i.ibb.co/jM43pMq/0014.webp"
        },
        {
          "page": 12,
          "link": "https://i.ibb.co/CwYWBnm/0015.webp"
        },
        {
          "page": 13,
          "link": "https://i.ibb.co/gVxG8MR/0016.webp"
        },
        {
          "page": 14,
          "link": "https://i.ibb.co/YR7MbXm/0017.webp"
        },
        {
          "page": 15,
          "link": "https://i.ibb.co/b5sQy6f/0018.webp"
        },
        {
          "page": 16,
          "link": "https://i.ibb.co/y4LDzCb/0019.webp"
        },
        {
          "page": 17,
          "link": "https://i.ibb.co/Hr8cY66/0020.webp"
        },
        {
          "page": 18,
          "link": "https://i.ibb.co/gv7TmtB/0021.webp"
        },
        {
          "page": 19,
          "link": "https://i.ibb.co/RPQ9bSm/0022.webp"
        },
        {
          "page": 20,
          "link": "https://i.ibb.co/QQ25HRB/0023.webp"
        },
        {
          "page": 21,
          "link": "https://i.ibb.co/k9xJczT/0024.webp"
        },
        {
          "page": 22,
          "link": "https://i.ibb.co/Y2gmXZx/0025.webp"
        },
        {
          "page": 23,
          "link": "https://i.ibb.co/GnqhtK9/0026.webp"
        },
        {
          "page": 24,
          "link": "https://i.ibb.co/TtDQyfB/0027.webp"
        },
        {
          "page": 25,
          "link": "https://i.ibb.co/tYvgc0W/0028.webp"
        },
        {
          "page": 26,
          "link": "https://i.ibb.co/JdHfzq5/0029.webp"
        },
        {
          "page": 27,
          "link": "https://i.ibb.co/XCXNGCD/0030.webp"
        },
        {
          "page": 28,
          "link": "https://i.ibb.co/k5gjHyP/0031.webp"
        },
        {
          "page": 29,
          "link": "https://i.ibb.co/tZgfhPG/0032.webp"
        },
        {
          "page": 30,
          "link": "https://i.ibb.co/9NmDqhn/0033.webp"
        },
        {
          "page": 31,
          "link": "https://i.ibb.co/xMct3DG/0034.webp"
        },
        {
          "page": 32,
          "link": "https://i.ibb.co/85GWYm3/0035.webp"
        },
        {
          "page": 33,
          "link": "https://i.ibb.co/rFFKBfT/0036.webp"
        },
        {
          "page": 34,
          "link": "https://i.ibb.co/NC8QcmZ/0037.webp"
        },
        {
          "page": 35,
          "link": "https://i.ibb.co/6FgPYKt/0038.webp"
        },
        {
          "page": 36,
          "link": "https://i.ibb.co/DQgFnYJ/0039.webp"
        },
        {
          "page": 37,
          "link": "https://i.ibb.co/S6HMKym/0040.webp"
        },
        {
          "page": 38,
          "link": "https://i.ibb.co/ZHT9XkV/0041.webp"
        }
      
]


export default listePages;