const listeEpisodes = [
    {
        episode : 1,
        link : "https://video.sibnet.ru/shell.php?videoid=5229461"
    },
    {
        episode : 2,
        link : "https://video.sibnet.ru/shell.php?videoid=5229465"
    },
    {
        episode : 3,
        link : "https://video.sibnet.ru/shell.php?videoid=5229466"
    },
    {
        episode : 4,
        link : "https://video.sibnet.ru/shell.php?videoid=5229467"
    },
    {
        episode : 5,
        link : "https://video.sibnet.ru/shell.php?videoid=5229468"
    },
    {
        episode : 6,
        link : "https://video.sibnet.ru/shell.php?videoid=5229469"
    },
    {
        episode : 7,
        link : "https://video.sibnet.ru/shell.php?videoid=5229470"
    },
    {
        episode : 8,
        link : "https://video.sibnet.ru/shell.php?videoid=5229471"
    },
    {
        episode : 9,
        link : "https://video.sibnet.ru/shell.php?videoid=5229472"
    },
    {
        episode : 10,
        link : "https://video.sibnet.ru/shell.php?videoid=5229474"
    },
    {
        episode : 11,
        link : "https://video.sibnet.ru/shell.php?videoid=5229475"
    },
    {
        episode : 12,
        link : "https://video.sibnet.ru/shell.php?videoid=5229477"
    },
    {
        episode : 13,
        link : "https://video.sibnet.ru/shell.php?videoid=5229478"
    },
    {
        episode : 14,
        link : "https://video.sibnet.ru/shell.php?videoid=5229479"
    },
    {
        episode : 15,
        link : "https://video.sibnet.ru/shell.php?videoid=5229481"
    },
    {
        episode : 16,
        link : "https://video.sibnet.ru/shell.php?videoid=5229482"
    },
    {
        episode : 17,
        link : "https://video.sibnet.ru/shell.php?videoid=5229532"
    },
    {
        episode : 18,
        link : "https://video.sibnet.ru/shell.php?videoid=5229495"
    },
    {
        episode : 19,
        link : "https://video.sibnet.ru/shell.php?videoid=5229497"
    },
    {
        episode : 20,
        link : "https://video.sibnet.ru/shell.php?videoid=5229498"
    },
    {
        episode : 21,
        link : "https://video.sibnet.ru/shell.php?videoid=5229499"
    },
    {
        episode : 22,
        link : "https://video.sibnet.ru/shell.php?videoid=5229500"
    },
    {
        episode : 23,
        link : "https://video.sibnet.ru/shell.php?videoid=5229502"
    },
    {
        episode : 24,
        link : "https://video.sibnet.ru/shell.php?videoid=5229503"
    },
    {
        episode : 25,
        link : "https://video.sibnet.ru/shell.php?videoid=5229504"
    },
    {
        episode : 26,
        link : "https://video.sibnet.ru/shell.php?videoid=5229505"
    },
    {
        episode : 27,
        link : "https://video.sibnet.ru/shell.php?videoid=5229507"
    },
    {
        episode : 28,
        link : "https://video.sibnet.ru/shell.php?videoid=5229508"
    },
    {
        episode : 29,
        link : "https://video.sibnet.ru/shell.php?videoid=5229509"
    },
    {
        episode : 30,
        link : "https://video.sibnet.ru/shell.php?videoid=5229510"
    },
    {
        episode : 31,
        link : "https://video.sibnet.ru/shell.php?videoid=5229511"
    },
    {
        episode : 32,
        link : "https://video.sibnet.ru/shell.php?videoid=5229514"
    },
    {
        episode : 33,
        link : "https://video.sibnet.ru/shell.php?videoid=5229515"
    },
    {
        episode : 34,
        link : "https://video.sibnet.ru/shell.php?videoid=5229517"
    },
    {
        episode : 35,
        link : "https://video.sibnet.ru/shell.php?videoid=5229518"
    },
    {
        episode : 36,
        link : "https://video.sibnet.ru/shell.php?videoid=5229520"
    },
    {
        episode : 37,
        link : "https://video.sibnet.ru/shell.php?videoid=5229521"
    },
    {
        episode : 38,
        link : "https://video.sibnet.ru/shell.php?videoid=5229522"
    },
    {
        episode : 39,
        link : "https://video.sibnet.ru/shell.php?videoid=5229524"
    },
    {
        episode : 40,
        link : "https://video.sibnet.ru/shell.php?videoid=5229526"
    },
    {
        episode : 41,
        link : "https://video.sibnet.ru/shell.php?videoid=5229527"
    },
    {
        episode : 42,
        link : "https://video.sibnet.ru/shell.php?videoid=5229528"
    },
    {
        episode : 43,
        link : "https://video.sibnet.ru/shell.php?videoid=5229529"
    },
    {
        episode : 44,
        link : "https://video.sibnet.ru/shell.php?videoid=5229530"
    },
    {
        episode : 45,
        link : "https://video.sibnet.ru/shell.php?videoid=5229531"
    },
    {
        episode : 46,
        link : "https://video.sibnet.ru/shell.php?videoid=5229533"
    },
    {
        episode : 47,
        link : "https://video.sibnet.ru/shell.php?videoid=5229535"
    },
    {
        episode : 48,
        link : "https://video.sibnet.ru/shell.php?videoid=5229536"
    },
    {
        episode : 49,
        link : "https://video.sibnet.ru/shell.php?videoid=5229537"
    },
    {
        episode : 50,
        link : "https://video.sibnet.ru/shell.php?videoid=5229538"
    },
    {
        episode : 51,
        link : "https://video.sibnet.ru/shell.php?videoid=5229539"
    }
]

export default listeEpisodes;