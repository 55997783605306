import { Helmet } from 'react-helmet';
import LegalContainer from '../components/LegalContainer';

export default function Legal() {
    return (
            <>
                <Helmet>
                    <title>Avertissement Légal | Streaming Espace</title>
                    <link rel="canonical" href={`https://www.streaming-espace.fr/legal`}/>
                    <meta name="description" content={"Avertissement Légal de Streaming Espace."} />
                </Helmet>
                <main className="content container">
                    <LegalContainer />
                </main>                
            </>
    )       
}