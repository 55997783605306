const listePages = [
    {
      page: 1,
      link: "https://i.ibb.co/pJjsjsg/0107-001.webp"
    },
    {
      page: 2,
      link: "https://i.ibb.co/Hp2zHjH/0107-002.webp"
    },
    {
      page: 3,
      link: "https://i.ibb.co/bRhJ2t3/0107-003.webp"
    },
    {
      page: 4,
      link: "https://i.ibb.co/6t2yb9p/0107-004.webp"
    },
    {
      page: 5,
      link: "https://i.ibb.co/z4SW99H/0107-005.webp"
    },
    {
      page: 6,
      link: "https://i.ibb.co/NWFGVQJ/0107-006.webp"
    },
    {
      page: 7,
      link: "https://i.ibb.co/vhRpH2P/0107-007.webp"
    },
    {
      page: 8,
      link: "https://i.ibb.co/BBZkkN4/0107-008.webp"
    },
    {
      page: 9,
      link: "https://i.ibb.co/qsQgC5H/0107-009.webp"
    },
    {
      page: 10,
      link: "https://i.ibb.co/zGmH4kt/0107-010.webp"
    },
    {
      page: 11,
      link: "https://i.ibb.co/zrbZ5GR/0107-011.webp"
    },
    {
      page: 12,
      link: "https://i.ibb.co/Km6Bw99/0107-012.webp"
    },
    {
      page: 13,
      link: "https://i.ibb.co/6PWQ69R/0107-013.webp"
    },
    {
      page: 14,
      link: "https://i.ibb.co/RNnN6RV/0107-014.webp"
    },
    {
      page: 15,
      link: "https://i.ibb.co/y4rG97W/0107-015.webp"
    },
    {
      page: 16,
      link: "https://i.ibb.co/Z1jxdWv/0107-016.webp"
    },
    {
      page: 17,
      link: "https://i.ibb.co/vwT81Fh/0107-017.webp"
    },
    {
      page: 18,
      link: "https://i.ibb.co/YDtJDQN/0107-018.webp"
    },
    {
      page: 19,
      link: "https://i.ibb.co/8DBbXWq/0107-019.webp"
    },
    {
      page: 20,
      link: "https://i.ibb.co/1vJs7CK/0107-020.webp"
    },
    {
      page: 21,
      link: "https://i.ibb.co/HPNkKWn/0107-021.webp"
    },
    {
      page: 22,
      link: "https://i.ibb.co/mXFW0Kk/0107-022.webp"
    },
    {
      page: 23,
      link: "https://i.ibb.co/9n0C83W/0107-023.webp"
    },
    {
      page: 24,
      link: "https://i.ibb.co/PQfM2gK/0107-024.webp"
    },
    {
      page: 25,
      link: "https://i.ibb.co/tw65Csk/0107-025.webp"
    },
    {
      page: 26,
      link: "https://i.ibb.co/yFyW4HG/0107-026.webp"
    },
    {
      page: 27,
      link: "https://i.ibb.co/d4Jpp4D/0107-027.webp"
    },
    {
      page: 28,
      link: "https://i.ibb.co/7WWtbXH/0107-028.webp"
    },
    {
      page: 29,
      link: "https://i.ibb.co/mJv3JZq/0107-029.webp"
    },
    {
      page: 30,
      link: "https://i.ibb.co/rb8XWt1/0107-030.webp"
    }
  ]

export default listePages;