const listeEpisodes = [
	{
        episode: 1,
        link: "https://www.dailymotion.com/embed/video/x74o6ym"
    },
    {
        episode: 2,
        link: "https://www.dailymotion.com/embed/video/x76x4lk"
    },
    {
        episode: 3,
        link: "https://www.dailymotion.com/embed/video/x76x61r"
    },
    {
        episode: 4,
        link: "https://www.dailymotion.com/embed/video/x772bb8"
    },
    {
        episode: 5,
        link: "https://www.dailymotion.com/embed/video/x777g1d"
    },
    {
        episode: 6,
        link: "https://www.dailymotion.com/embed/video/x787bnk"
    },
    {
        episode: 7,
        link: "https://www.dailymotion.com/embed/video/x78dova"
    },
    {
        episode: 8,
        link: "https://www.dailymotion.com/embed/video/x78k4ph"
    },
    {
        episode: 9,
        link: "https://www.dailymotion.com/embed/video/x7aeozd"
    },
    {
        episode: 10,
        link: "https://www.dailymotion.com/embed/video/x7asjj1"
    },
    {
        episode: 11,
        link: "https://www.dailymotion.com/embed/video/x7asqu5"
    },
    {
        episode: 12,
        link: "https://www.dailymotion.com/embed/video/x7fo48w"
    },
    {
        episode: 13,
        link: "https://www.dailymotion.com/embed/video/x7bf69f"
    },
    {
        episode: 14,
        link: "https://www.dailymotion.com/embed/video/x7c7kjm"
    },
    {
        episode: 15,
        link: "https://www.dailymotion.com/embed/video/x7e9ku5"
    },
    {
        episode: 16,
        link: "https://www.dailymotion.com/embed/video/x7fi248"
    },
    {
        episode: 17,
        link: "https://www.dailymotion.com/embed/video/x7l3roq"
    },
    {
        episode: 18,
        link: "https://www.dailymotion.com/embed/video/x7t68vg"
    },
    {
        episode: 19,
        link: "https://www.dailymotion.com/embed/video/x7lww5o"
    },
    {
        episode: 20,
        link: "https://www.dailymotion.com/embed/video/x7m3nqm"
    },
    {
        episode: 21,
        link: "https://www.dailymotion.com/embed/video/x7mb3sa"
    },
    {
        episode: 22,
        link: "https://www.dailymotion.com/embed/video/x7mwdc9"
    },
    {
        episode: 23,
        link: "https://www.dailymotion.com/embed/video/x7n013c"
    },
    {
        episode: 24,
        link: "https://www.dailymotion.com/embed/video/x7n59zz"
    },
    {
        episode: 25,
        link: "https://www.dailymotion.com/embed/video/x7nkpkd"
    },
    {
        episode: 26,
        link: "https://www.dailymotion.com/embed/video/x7nkpo8"
    },
    {
        episode: 27,
        link: "https://www.dailymotion.com/embed/video/x7nlu7p"
    },
    {
        episode: 28,
        link: "https://www.dailymotion.com/embed/video/x7nr61m"
    },
    {
        episode: 29,
        link: "https://www.dailymotion.com/embed/video/x7o42y0"
    },
    {
        episode: 30,
        link: "https://www.dailymotion.com/embed/video/x7o8gmt"
    },
    {
        episode: 31,
        link: "https://www.dailymotion.com/embed/video/x7og9tu"
    },
    {
        episode: 32,
        link: "https://www.dailymotion.com/embed/video/x7ow8xc"
    },
    {
        episode: 33,
        link: "https://www.dailymotion.com/embed/video/x7ozkki"
    },
    {
        episode: 34,
        link: "https://www.dailymotion.com/embed/video/x7p8dti"
    },
    {
        episode: 35,
        link: "https://www.dailymotion.com/embed/video/x7p8qwx"
    },
    {
        episode: 36,
        link: "https://www.dailymotion.com/embed/video/x7po4h4"
    },
    {
        episode: 37,
        link: "https://www.dailymotion.com/embed/video/x7pr8qq"
    },
    {
        episode: 38,
        link: "https://www.dailymotion.com/embed/video/x7pribr"
    },
    {
        episode: 39,
        link: "https://www.dailymotion.com/embed/video/x7pttrs"
    },
    {
        episode: 40,
        link: "https://www.dailymotion.com/embed/video/x7q0pha"
    },
    {
        episode: 41,
        link: "https://www.dailymotion.com/embed/video/x7q9462"
    },
    {
        episode: 42,
        link: "https://www.dailymotion.com/embed/video/x7qagmw"
    },
    {
        episode: 43,
        link: "https://www.dailymotion.com/embed/video/x7qjepp"
    },
    {
        episode: 44,
        link: "https://www.dailymotion.com/embed/video/x7qp6bf"
    },
    {
        episode: 45,
        link: "https://www.dailymotion.com/embed/video/x7qqnm2"
    },
    {
        episode: 46,
        link: "https://www.dailymotion.com/embed/video/x7qsn25"
    },
    {
        episode: 47,
        link: "https://www.dailymotion.com/embed/video/x7qsoww"
    },
    {
        episode: 48,
        link: "https://www.dailymotion.com/embed/video/x7qtvha"
    },
    {
        episode: 49,
        link: "https://www.dailymotion.com/embed/video/x7qx0sm"
    },
    {
        episode: 50,
        link: "https://www.dailymotion.com/embed/video/x7qz6lb"
    },
    {
        episode: 51,
        link: "https://www.dailymotion.com/embed/video/x7r128v"
    }
]

export default listeEpisodes;