const listePages = [
	{ page: 1, link: 'https://i.ibb.co/QbHB48c/001.webp' },
	{ page: 2, link: 'https://i.ibb.co/CKR4VNb/002-003.webp' },
	{ page: 4, link: 'https://i.ibb.co/XxJRdRP/004.webp' },
	{ page: 5, link: 'https://i.ibb.co/T8CmDNG/005.webp' },
	{ page: 6, link: 'https://i.ibb.co/xhRVBx5/006.webp' },
	{ page: 7, link: 'https://i.ibb.co/sPfZsP7/007.webp' },
	{ page: 8, link: 'https://i.ibb.co/k6Rx1vW/008.webp' },
	{ page: 9, link: 'https://i.ibb.co/jV6whzS/009.webp' },
	{ page: 10, link: 'https://i.ibb.co/c2y5GQX/010.webp' },
	{ page: 11, link: 'https://i.ibb.co/BK4rKRZ/011.webp' },
	{ page: 12, link: 'https://i.ibb.co/ckNCtjL/012.webp' },
	{ page: 13, link: 'https://i.ibb.co/1GY3fdj/013.webp' },
	{ page: 14, link: 'https://i.ibb.co/gggMwd1/014.webp' },
	{ page: 15, link: 'https://i.ibb.co/PNDSXM1/015.webp' },
	{ page: 16, link: 'https://i.ibb.co/fkXXFDn/016.webp' },
	{ page: 17, link: 'https://i.ibb.co/Y4CX88q/017.webp' },
	{ page: 18, link: 'https://i.ibb.co/HrjgH1N/018.webp' },
	{ page: 19, link: 'https://i.ibb.co/xYScdVd/019.webp' },
	{ page: 20, link: 'https://i.ibb.co/MNqP79Q/020.webp' },
	{ page: 21, link: 'https://i.ibb.co/BK8j3Rw/021.webp' },
	{ page: 22, link: 'https://i.ibb.co/TcLcvvs/022.webp' },
	{ page: 23, link: 'https://i.ibb.co/pXMsJ9v/023.webp' },
	{ page: 24, link: 'https://i.ibb.co/wwf6VNw/024.webp' },
	{ page: 25, link: 'https://i.ibb.co/HpY01vm/025.webp' },
	{ page: 26, link: 'https://i.ibb.co/9y6QB8X/026.webp' },
	{ page: 27, link: 'https://i.ibb.co/gz838Qx/027.webp' },
	{ page: 28, link: 'https://i.ibb.co/dcR64k1/028.webp' },
	{ page: 29, link: 'https://i.ibb.co/ZGk9pbm/029.webp' },
	{ page: 30, link: 'https://i.ibb.co/QJ8QGrF/030.webp' },
	{ page: 31, link: 'https://i.ibb.co/6tVScgj/031.webp' },
	{ page: 32, link: 'https://i.ibb.co/5k2XNyy/032.webp' },
	{ page: 33, link: 'https://i.ibb.co/nCCs3yy/033.webp' },
	{ page: 34, link: 'https://i.ibb.co/SKM1gdv/034.webp' },
	{ page: 35, link: 'https://i.ibb.co/CJBTB3y/035.webp' },
	{ page: 36, link: 'https://i.ibb.co/smM76pc/036-037.webp' },
	{ page: 38, link: 'https://i.ibb.co/3WgD01r/038-039.webp' },
	{ page: 40, link: 'https://i.ibb.co/9qzsTck/040.webp' },
	{ page: 41, link: 'https://i.ibb.co/2MgFYC7/041.webp' },
	{ page: 42, link: 'https://i.ibb.co/rbCXxd9/042.webp' },
	{ page: 43, link: 'https://i.ibb.co/jkydYCQ/043.webp' },
	{ page: 44, link: 'https://i.ibb.co/sy6P1WF/044.webp' },
	{ page: 45, link: 'https://i.ibb.co/17nZCrV/045.webp' },
	{ page: 46, link: 'https://i.ibb.co/zsYmT5F/046.webp' },
	{ page: 47, link: 'https://i.ibb.co/F87pYHw/047.webp' },
	{ page: 48, link: 'https://i.ibb.co/3TjGzmS/048.webp' },
	{ page: 49, link: 'https://i.ibb.co/PWDmngL/049.webp' },
	{ page: 50, link: 'https://i.ibb.co/pzYpqSW/050.webp' },
	{ page: 51, link: 'https://i.ibb.co/GMWK94M/051.webp' },
	{ page: 52, link: 'https://i.ibb.co/WkJMQsJ/052.webp' },
	{ page: 53, link: 'https://i.ibb.co/HqZx0cG/053.webp' },
	{ page: 54, link: 'https://i.ibb.co/2N1MTjQ/054.webp' },
	{ page: 55, link: 'https://i.ibb.co/rMfgW7Z/055.webp' },
	{ page: 56, link: 'https://i.ibb.co/Zh3BBDJ/056.webp' },
	{ page: 57, link: 'https://i.ibb.co/SNkCV3Q/057.webp' },
	{ page: 58, link: 'https://i.ibb.co/8x1CTMn/058.webp' },
	{ page: 59, link: 'https://i.ibb.co/PNcxjV2/059.webp' },
	{ page: 60, link: 'https://i.ibb.co/f8yj51c/060.webp' },
	{ page: 61, link: 'https://i.ibb.co/NjBBggf/061.webp' },
	{ page: 62, link: 'https://i.ibb.co/tCQM6FF/062-063.webp' },
	{ page: 64, link: 'https://i.ibb.co/5FWSsX0/064.webp' },
	{ page: 65, link: 'https://i.ibb.co/4VhbnNK/065.webp' },
	{ page: 66, link: 'https://i.ibb.co/7jVbVkk/066.webp' },
	{ page: 67, link: 'https://i.ibb.co/3TMd10B/067.webp' },
	{ page: 68, link: 'https://i.ibb.co/sC7M8xz/068.webp' },
	{ page: 69, link: 'https://i.ibb.co/nmRygG3/069.webp' },
	{ page: 70, link: 'https://i.ibb.co/vPfCNg3/070.webp' },
	{ page: 71, link: 'https://i.ibb.co/MN7xKv6/071.webp' },
	{ page: 72, link: 'https://i.ibb.co/7S4cCvL/072.webp' },
	{ page: 73, link: 'https://i.ibb.co/YdCdrph/073.webp' },
	{ page: 74, link: 'https://i.ibb.co/bF2mPZ3/074.webp' },
	{ page: 75, link: 'https://i.ibb.co/sQ2tJfx/075.webp' },
	{ page: 76, link: 'https://i.ibb.co/vP4HwZK/076.webp' },
	{ page: 77, link: 'https://i.ibb.co/f8Zjhbh/077.webp' },
	{ page: 78, link: 'https://i.ibb.co/mbsVGnN/078.webp' }
]



export default listePages;