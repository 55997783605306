const listeEpisodes = [
    {
        episode : 1,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/E-XOtl2Aask?si=VOBqsN-81gd9SDNq"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239018&hd=2"
            },
            {
                name: "Sibnet",
                link: "https://video.sibnet.ru/shell.php?videoid=5313604"
            }
        ]
    },
    {
        episode : 2,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239019&hd=2"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5313613"
            }
        ]
    },
    {
        episode : 3,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239020&hd=2"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5313616"
            }
        ]
    },
    {
        episode : 4,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239021&hd=2"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5313618"
            }
        ]
    },
    {
        episode : 5,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239022&hd=2"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5313619"
            }
        ]
    },
    {
        episode : 6,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/dTD3LyBASig?si=Lu8bg_RLqY9ZounW"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239023&hd=2"
            },
            {
                name: "Sibnet",
                link: "https://video.sibnet.ru/shell.php?videoid=5313623"
            }
        ]
    },
    {
        episode : 7,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239024&hd=2"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5313630"
            }
        ]
    },
    {
        episode : 8,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239025&hd=2"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5313637"
            }
        ]
    },
    {
        episode : 9,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239026&hd=2"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5313641"
            }
        ]
    },
    {
        episode : 10,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239027&hd=2"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5313649"
            }
        ]
    },
    {
        episode : 11,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239028&hd=2"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5313673"
            }
        ]
    },
    {
        episode : 12,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239029&hd=2"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5313678"
            }
        ]
    },
    {
        episode : 13,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239030&hd=2"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5313686"
            }
        ]
    },
    {
        episode : 14,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239031&hd=2"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5313695"
            }
        ]
    },
    {
        episode : 15,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239032&hd=2"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5313710"
            }
        ]
    },
    {
        episode : 16,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239033&hd=2"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5313726"
            }
        ]
    },
    {
        episode : 17,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239034&hd=2"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5313728"
            }
        ]
    },
    {
        episode : 18,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239035&hd=2"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5313744"
            }
        ]
    },
    {
        episode : 19,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239036&hd=2"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5313746"
            }
        ]
    },
    {
        episode : 20,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239037&hd=2"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5313749"
            }
        ]
    },
    {
        episode : 21,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239038&hd=2"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5313755"
            }
        ]
    },
    {
        episode : 22,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239039&hd=2"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5313758"
            }
        ]
    },
    {
        episode : 23,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239040&hd=2"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5313762"
            }
        ]
    },
    {
        episode : 24,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239041&hd=2"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5313768"
            }
        ]
    },
    {
        episode : 25,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239042&hd=2"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5313775"
            }
        ]
    },
    {
        episode : 26,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239043&hd=2"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5313782"
            }
        ]
    }
]

export default listeEpisodes;