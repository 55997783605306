const listePages = [
  { page: 1, link: 'https://i.ibb.co/Htj98QJ/0001.webp' },
  { page: 2, link: 'https://i.ibb.co/B2x6WZb/0002.webp' },
  { page: 3, link: 'https://i.ibb.co/5K4V54C/0003.webp' },
  { page: 4, link: 'https://i.ibb.co/yqHfKGR/0004.webp' },
  { page: 5, link: 'https://i.ibb.co/PFXbP7d/0005.webp' },
  { page: 6, link: 'https://i.ibb.co/qydWNQb/0006.webp' },
  { page: 7, link: 'https://i.ibb.co/VT3STPL/0007.webp' },
  { page: 8, link: 'https://i.ibb.co/V9qtj1h/0008.webp' },
  { page: 9, link: 'https://i.ibb.co/0FF4pHT/0009.webp' },
  { page: 10, link: 'https://i.ibb.co/pKKJVtY/0010.webp' },
  { page: 11, link: 'https://i.ibb.co/sm4CtB0/0011.webp' },
  { page: 12, link: 'https://i.ibb.co/r0NB35H/0012.webp' }
]


export default listePages;