const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/k5FpRFn/0038-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/xGkRsTk/0038-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/Z1c3G5Q/0038-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/TDG0kq8/0038-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/gdcmS8f/0038-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/5R7yC25/0038-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/jRjKJ2H/0038-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/WyzVbKv/0038-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/sJYDKX8/0038-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/2SggRsR/0038-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/VLMZ1ph/0038-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/sssJRwq/0038-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/4MNwtL6/0038-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/hWfTzHM/0038-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/Ph8yxpx/0038-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/B4RnsNn/0038-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/N2wjKKF/0038-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/NTv4NnB/0038-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/RvnWWGb/0038-019.webp"
  },
  {
    page: 20,
    link: "https://i.ibb.co/kS3D3GZ/0038-020.webp"
  },
  {
    page: 21,
    link: "https://i.ibb.co/YLxkP3F/0038-021.webp"
  },
  {
    page: 22,
    link: "https://i.ibb.co/S72YHk7/0038-022.webp"
  },
  {
    page: 23,
    link: "https://i.ibb.co/dfcJvqd/0038-023.webp"
  },
  {
    page: 24,
    link: "https://i.ibb.co/n3vBV5T/0038-024.webp"
  },
  {
    page: 25,
    link: "https://i.ibb.co/nQ45S5P/0038-025.webp"
  },
  {
    page: 26,
    link: "https://i.ibb.co/s2Cts98/0038-026.webp"
  },
  {
    page: 27,
    link: "https://i.ibb.co/4Y8vBmG/0038-027.webp"
  },
  {
    page: 28,
    link: "https://i.ibb.co/XSCy6yw/0038-028.webp"
  },
  {
    page: 29,
    link: "https://i.ibb.co/C95DfNx/0038-029.webp"
  },
  {
    page: 30,
    link: "https://i.ibb.co/nzj4qvg/0038-030.webp"
  },
  {
    page: 31,
    link: "https://i.ibb.co/y6SF8nn/0038-031.webp"
  },
  {
    page: 32,
    link: "https://i.ibb.co/qp16bjY/0038-032.webp"
  },
  {
    page: 33,
    link: "https://i.ibb.co/q7gnWy2/0038-033.webp"
  },
  {
    page: 34,
    link: "https://i.ibb.co/hRhGRsK/0038-034.webp"
  },
  {
    page: 35,
    link: "https://i.ibb.co/JdM1BQV/0038-035.webp"
  },
  {
    page: 36,
    link: "https://i.ibb.co/c6Yw8Mp/0038-036.webp"
  }
]


export default listePages;