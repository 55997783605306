const listeEpisodes = [
    {
        episode : "trailer",
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239068&hd=2"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929260"
            }
        ]
    },
    {
        episode : 1,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239017&hd=2"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929223"
            }
        ]
    },
    {
        episode : 2,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239017&hd=2"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929223"
            }
        ]
    },
    {
        episode : 3,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239018&hd=2"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929226"
            }
        ]
    },
    {
        episode : 4,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239019&hd=2"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929227"
            }
        ]
    },
    {
        episode : 5,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239020&hd=2"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929230"
            }
        ]
    },
    {
        episode : 6,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239021&hd=2"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929232"
            }
        ]
    },
    {
        episode : 7,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239022&hd=2"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929233"
            }
        ]
    },
    {
        episode : 8,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239023&hd=2"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929235"
            }
        ]
    },
    {
        episode : 9,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239024&hd=2"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929237"
            }
        ]
    },
    {
        episode : 10,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239025&hash=ccc597d79e438c14"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929239"
            }
        ]
    },
    {
        episode : 11,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239026&hash=425cc8f1b6828b92"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929240"
            }
        ]
    },
    {
        episode : 12,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239027&hash=cfcdeb7628593024"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929242"
            }
        ]
    },
    {
        episode : 13,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239028&hash=8fb52534d25849b0"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929243"
            }
        ]
    },
    {
        episode : 14,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239029&hash=687425cf2c278e57"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929245"
            }
        ]
    },
    {
        episode : 15,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239030&hash=110ab6ceb60741e5"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929247"
            }
        ]
    },
    {
        episode : 16,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239031&hash=4a4a2ac0963f74eb"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929249"
            }
        ]
    },
    {
        episode : 17,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239032&hash=0754198aece0c8d0"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929250"
            }
        ]
    },
    {
        episode : 18,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239033&hash=7076a6db7c34d2c8"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929252"
            }
        ]
    },
    {
        episode : 19,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239034&hash=b5ffe06913a880a7"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929253"
            }
        ]
    },
    {
        episode : 20,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239035&hash=b074eca750e5d922"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929254"
            }
        ]
    },
    {
        episode : 21,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239036&hash=0173bf566edfc3b5"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929255"
            }
        ]
    },
    {
        episode : 22,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239037&hash=addb3ac75e32ac12"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929257"
            }
        ]
    },
    {
        episode : 23,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239038&hash=356f348585429a7b"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929258"
            }
        ]
    },
    {
        episode : 24,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239039&hash=2c2e9dfae17a6f3c"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929259"
            }
        ]
    },
    {
        episode : 25,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239040&hash=073b656881b0fd07"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5235309"
            }
        ]
    },
    {
        episode : 26,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239041&hash=b81a6d50fac3a361"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5234750"
            }
        ]
    },
    {
        episode : 27,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239042&hash=ec63df51d22ebbd7"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5234754"
            }
        ]
    },
    {
        episode : 28,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239043&hash=fe343d63ace6ef98"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5234759"
            }
        ]
    },
    {
        episode : 29,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239044&hash=53b5a8f8664048b8"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5234766"
            }
        ]
    },
    {
        episode : 30,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239045&hash=e8e3f832b0ed1105"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5234770"
            }
        ]
    },
    {
        episode : 31,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239046&hash=c6c98ae35db2e30c"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5234773"
            }
        ]
    },
    {
        episode : 32,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239047&hash=afe3b26047dc63de"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5234778"
            }
        ]
    },
    {
        episode : 33,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239048&hash=7f4513c99beef2ed"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5234781"
            }
        ]
    },
    {
        episode : 34,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239049&hash=3c0867c41cad208b"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5234786"
            }
        ]
    },
    {
        episode : 35,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239050&hash=1e01c6f2a2e95c8e"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5234792"
            }
        ]
    },
    {
        episode : 36,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239051&hash=af91ff4abd307ebf"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5234796"
            }
        ]
    },
    {
        episode : 37,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239052&hash=891069825caa2734"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5234797"
            }
        ]
    },
    {
        episode : 38,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239053&hash=d755cdb8a327d3e1"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5234799"
            }
        ]
    },
    {
        episode : 39,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239054&hash=04b37fdaf0378698"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5234800"
            }
        ]
    },
    {
        episode : 40,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239055&hash=ad9d51b1e1e3a21e"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5234803"
            }
        ]
    },
    {
        episode : 41,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239056&hash=dfde9a0823ee08a7"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5234808"
            }
        ]
    },
    {
        episode : 42,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239057&hash=ab6ce2266c077c02"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5234809"
            }
        ]
    },
    {
        episode : 43,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239058&hash=7e058f4b4d4603cb"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5234813"
            }
        ]
    },
    {
        episode : 44,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239059&hash=053b5d304c7e2c6f"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5234818"
            }
        ]
    },
    {
        episode : 45,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239060&hash=68a94c7e0f4fc488"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5234825"
            }
        ]
    },
    {
        episode : 46,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239061&hash=cd018ceeadcec59e"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5234830"
            }
        ]
    },
    {
        episode : 47,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239062&hash=11eeea491f6694c1"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5234832"
            }
        ]
    },
    {
        episode : 48,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239063&hash=4aa37dbe1551d44c"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5234835"
            }
        ]
    },
    {
        episode : 49,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239064&hash=dc3233abcef0c583"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5234838"
            }
        ]
    },
    {
        episode : 50,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239065&hash=b004df7a657d1b4c"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5234842"
            }
        ]
    },
    {
        episode : 51,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239066&hash=55bf1c3f4328a5c6"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5234844"
            }
        ]
    },
    {
        episode : 52,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239067&hash=8d5abe2c680d604a"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5234848"
            }
        ]
    }
]

export default listeEpisodes;