const listePages = [
    {
      page: 1,
      link: "https://i.ibb.co/16PLBwJ/0103-001.webp"
    },
    {
      page: 2,
      link: "https://i.ibb.co/hfPKmKz/0103-002.webp"
    },
    {
      page: 3,
      link: "https://i.ibb.co/M6PMqVt/0103-003.webp"
    },
    {
      page: 4,
      link: "https://i.ibb.co/3cvmNqF/0103-004.webp"
    },
    {
      page: 5,
      link: "https://i.ibb.co/zrcBfV6/0103-005.webp"
    },
    {
      page: 6,
      link: "https://i.ibb.co/7jPZGGW/0103-006.webp"
    },
    {
      page: 7,
      link: "https://i.ibb.co/z8k6F2r/0103-007.webp"
    },
    {
      page: 8,
      link: "https://i.ibb.co/LrHgJSH/0103-008.webp"
    },
    {
      page: 9,
      link: "https://i.ibb.co/YQmXRsJ/0103-009.webp"
    },
    {
      page: 10,
      link: "https://i.ibb.co/tpxcFg0/0103-010.webp"
    },
    {
      page: 11,
      link: "https://i.ibb.co/R0ZkHLR/0103-011.webp"
    },
    {
      page: 12,
      link: "https://i.ibb.co/cktpThg/0103-012.webp"
    },
    {
      page: 13,
      link: "https://i.ibb.co/Qd9CPpy/0103-013.webp"
    },
    {
      page: 14,
      link: "https://i.ibb.co/wsGtkfK/0103-014.webp"
    },
    {
      page: 15,
      link: "https://i.ibb.co/7NxHc0F/0103-015.webp"
    },
    {
      page: 16,
      link: "https://i.ibb.co/zFX14rn/0103-016.webp"
    },
    {
      page: 17,
      link: "https://i.ibb.co/Hxcwd6T/0103-017.webp"
    },
    {
      page: 18,
      link: "https://i.ibb.co/THqCS6t/0103-018.webp"
    },
    {
      page: 19,
      link: "https://i.ibb.co/NWqLMb2/0103-019.webp"
    },
    {
      page: 20,
      link: "https://i.ibb.co/zm5mRMB/0103-020.webp"
    },
    {
      page: 21,
      link: "https://i.ibb.co/JsD368g/0103-021.webp"
    },
    {
      page: 22,
      link: "https://i.ibb.co/YfT79J1/0103-022.webp"
    },
    {
      page: 23,
      link: "https://i.ibb.co/fxt76Mm/0103-023.webp"
    },
    {
      page: 24,
      link: "https://i.ibb.co/6n8hfHT/0103-024.webp"
    },
    {
      page: 25,
      link: "https://i.ibb.co/VmQThty/0103-025.webp"
    },
    {
      page: 26,
      link: "https://i.ibb.co/vmwJDXy/0103-026.webp"
    },
    {
      page: 27,
      link: "https://i.ibb.co/mFn7GVp/0103-027.webp"
    },
    {
      page: 28,
      link: "https://i.ibb.co/YTYgVPY/0103-028.webp"
    },
    {
      page: 29,
      link: "https://i.ibb.co/bLYNBZK/0103-029.webp"
    }
  ]
  

export default listePages;