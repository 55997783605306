const listeEpisodes = [
	{
        episode: 1,
        link: "https://www.dailymotion.com/embed/video/x7szwsx"
    },
    {
        episode: 2,
        link: "https://www.dailymotion.com/embed/video/x7t0dcv"
    },
    {
        episode: 3,
        link: "https://www.dailymotion.com/embed/video/x7t0qmp"
    },
    {
        episode: 4,
        link: "https://www.dailymotion.com/embed/video/x7t144r"
    },
    {
        episode: 5,
        link: "https://www.dailymotion.com/embed/video/x7t28hc"
    },
    {
        episode: 6,
        link: "https://www.dailymotion.com/embed/video/x7t328h"
    },
    {
        episode: 7,
        link: "https://www.dailymotion.com/embed/video/x7t32m5"
    },
    {
        episode: 8,
        link: "https://www.dailymotion.com/embed/video/x7t47ye"
    },
    {
        episode: 9,
        link: "https://www.dailymotion.com/embed/video/x7t4od0"
    },
    {
        episode: 10,
        link: "https://www.dailymotion.com/embed/video/x7t534k"
    },
    {
        episode: 11,
        link: "https://www.dailymotion.com/embed/video/x7t5vfr"
    },
    {
        episode: 12,
        link: "https://www.dailymotion.com/embed/video/x7t6nuq"
    },
    {
        episode: 13,
        link: "https://www.dailymotion.com/embed/video/x7t6o1t"
    },
    {
        episode: 14,
        link: "https://www.dailymotion.com/embed/video/x7t6zd0"
    },
    {
        episode: 15,
        link: "https://www.dailymotion.com/embed/video/x7t7b6g"
    },
    {
        episode: 16,
        link: "https://www.dailymotion.com/embed/video/x7t7dh0"
    },
    {
        episode: 17,
        link: "https://www.dailymotion.com/embed/video/x7t9dm1"
    },
    {
        episode: 18,
        link: "https://www.dailymotion.com/embed/video/x7t9dog"
    },
    {
        episode: 19,
        link: "https://www.dailymotion.com/embed/video/x7t9qhi"
    },
    {
        episode: 20,
        link: "https://www.dailymotion.com/embed/video/x7ta3tb"
    },
    {
        episode: 21,
        link: "https://www.dailymotion.com/embed/video/x7taxu8"
    },
    {
        episode: 22,
        link: "https://www.dailymotion.com/embed/video/x7tbhpv"
    },
    {
        episode: 23,
        link: "https://www.dailymotion.com/embed/video/x7tbidt"
    },
    {
        episode: 24,
        link: "https://www.dailymotion.com/embed/video/x7tbqxj"
    },
    {
        episode: 25,
        link: "https://www.dailymotion.com/embed/video/x7tc9ue"
    },
    {
        episode: 26,
        link: "https://www.dailymotion.com/embed/video/x7te4tq"
    },
    {
        episode: 27,
        link: "https://www.dailymotion.com/embed/video/x7teobg"
    },
    {
        episode: 28,
        link: "https://www.dailymotion.com/embed/video/x7tf405"
    },
    {
        episode: 29,
        link: "https://www.dailymotion.com/embed/video/x7tflzv"
    },
    {
        episode: 30,
        link: "https://www.dailymotion.com/embed/video/x7tgbrh"
    },
    {
        episode: 31,
        link: "https://www.dailymotion.com/embed/video/x7tggl2"
    },
    {
        episode: 32,
        link: "https://www.dailymotion.com/embed/video/x7thfg2"
    },
    {
        episode: 33,
        link: "https://www.dailymotion.com/embed/video/x7tipmi"
    },
    {
        episode: 34,
        link: "https://www.dailymotion.com/embed/video/x7tj3fx"
    },
    {
        episode: 35,
        link: "https://www.dailymotion.com/embed/video/x7tjg2o"
    },
    {
        episode: 36,
        link: "https://www.dailymotion.com/embed/video/x7tjmi9"
    },
    {
        episode: 37,
        link: "https://www.dailymotion.com/embed/video/x7tk9dt"
    },
    {
        episode: 38,
        link: "https://www.dailymotion.com/embed/video/x7tksvd"
    },
    {
        episode: 39,
        link: "https://www.dailymotion.com/embed/video/x7tl032"
    },
    {
        episode: 40,
        link: "https://www.dailymotion.com/embed/video/x7tobp3"
    },
    {
        episode: 41,
        link: "https://www.dailymotion.com/embed/video/x7tls7l"
    },
    {
        episode: 42,
        link: "https://www.dailymotion.com/embed/video/x7tmt2r"
    }
]

export default listeEpisodes;