const listePages = [
    {
        page: 1,
        link: "https://i.ibb.co/Sn862W6/0102.webp"
      },
      {
        page: 2,
        link: "https://i.ibb.co/9HWCS07/0103.webp"
      },
      {
        page: 3,
        link: "https://i.ibb.co/z8KJv8w/0104.webp"
      },
      {
        page: 4,
        link: "https://i.ibb.co/k2sXXxV/0105.webp"
      },
      {
        page: 5,
        link: "https://i.ibb.co/wdzvffB/0106.webp"
      },
      {
        page: 6,
        link: "https://i.ibb.co/G5FGwMq/0107.webp"
      },
      {
        page: 7,
        link: "https://i.ibb.co/x7QC90f/0108.webp"
      },
      {
        page: 8,
        link: "https://i.ibb.co/k2FWdFG/0109.webp"
      },
      {
        page: 9,
        link: "https://i.ibb.co/ggMGpBD/0110.webp"
      },
      {
        page: 10,
        link: "https://i.ibb.co/3yLSsH5/0111.webp"
      },
      {
        page: 11,
        link: "https://i.ibb.co/9vbZrd2/0112.webp"
      },
      {
        page: 12,
        link: "https://i.ibb.co/pLB9FPr/0113.webp"
      },
      {
        page: 13,
        link: "https://i.ibb.co/M5pTjWh/0114.webp"
      },
      {
        page: 14,
        link: "https://i.ibb.co/ZNHpxY2/0115.webp"
      },
      {
        page: 15,
        link: "https://i.ibb.co/F3VXWSp/0116.webp"
      },
      {
        page: 16,
        link: "https://i.ibb.co/tsTWBC1/0117.webp"
      },
      {
        page: 17,
        link: "https://i.ibb.co/xzq5JSt/0118.webp"
      },
      {
        page: 18,
        link: "https://i.ibb.co/HqpKXPD/0119.webp"
      },
      {
        page: 19,
        link: "https://i.ibb.co/t8VQgJT/0120.webp"
      },
      {
        page: 20,
        link: "https://i.ibb.co/SQ5fMX9/0121.webp"
      },
      {
        page: 21,
        link: "https://i.ibb.co/TM3zWpn/0122.webp"
      },
      {
        page: 22,
        link: "https://i.ibb.co/nMVsjyw/0123.webp"
      },
      {
        page: 23,
        link: "https://i.ibb.co/0hBW1N4/0124.webp"
      },
      {
        page: 24,
        link: "https://i.ibb.co/wdxBCTf/0125.webp"
      },
      {
        page: 25,
        link: "https://i.ibb.co/Dt8bQsH/0126.webp"
      },
      {
        page: 26,
        link: "https://i.ibb.co/C9F7X9r/0127.webp"
      },
      {
        page: 27,
        link: "https://i.ibb.co/ZW5BbWh/0128.webp"
      },
      {
        page: 28,
        link: "https://i.ibb.co/SRdr2nR/0129.webp"
      },
      {
        page: 29,
        link: "https://i.ibb.co/VCnmXzF/0130.webp"
      },
      {
        page: 30,
        link: "https://i.ibb.co/dp219tH/0131.webp"
      },
      {
        page: 31,
        link: "https://i.ibb.co/wsHckkf/0132.webp"
      },
      {
        page: 32,
        link: "https://i.ibb.co/tPHCNPk/0133.webp"
      },
      {
        page: 33,
        link: "https://i.ibb.co/12Wt8ty/0134.webp"
      },
      {
        page: 34,
        link: "https://i.ibb.co/WvphLPC/0135.webp"
      },
      {
        page: 35,
        link: "https://i.ibb.co/px0wB9y/0136.webp"
      },
      {
        page: 36,
        link: "https://i.ibb.co/D1gLv17/0137.webp"
      },
      {
        page: 37,
        link: "https://i.ibb.co/nsTPRLF/0138.webp"
      }
]


export default listePages;