const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/Ky6hgxF/0005-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/K2wCNTY/0005-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/Y8qSwQp/0005-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/SnvT3Ft/0005-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/yYVcSZd/0005-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/bWnmp1f/0005-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/ZN809P1/0005-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/1qgX4Q9/0005-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/1XWW4MM/0005-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/Fw4KVrQ/0005-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/M7mrd34/0005-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/QrNFYbT/0005-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/0fpKxx0/0005-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/phF7J7h/0005-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/b2gnL9y/0005-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/ZXwzLyF/0005-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/KDC4XJK/0005-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/72qF5Mx/0005-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/ryDQPT9/0005-019.webp"
  },
  {
    page: 20,
    link: "https://i.ibb.co/qNXdKHC/0005-020.webp"
  },
  {
    page: 21,
    link: "https://i.ibb.co/Y20K0Fb/0005-021.webp"
  },
  {
    page: 22,
    link: "https://i.ibb.co/pfRNkXm/0005-022.webp"
  },
  {
    page: 23,
    link: "https://i.ibb.co/s50TCPZ/0005-023.webp"
  },
  {
    page: 24,
    link: "https://i.ibb.co/0YPjKqJ/0005-024.webp"
  },
  {
    page: 25,
    link: "https://i.ibb.co/3Fn1P9s/0005-025.webp"
  },
  {
    page: 26,
    link: "https://i.ibb.co/GPpfjxW/0005-026.webp"
  },
  {
    page: 27,
    link: "https://i.ibb.co/4dnhfgN/0005-027.webp"
  },
  {
    page: 28,
    link: "https://i.ibb.co/XX0RjHt/0005-028.webp"
  },
  {
    page: 29,
    link: "https://i.ibb.co/GVPYTHm/0005-029.webp"
  },
  {
    page: 30,
    link: "https://i.ibb.co/8g3G8MZ/0005-030.webp"
  },
  {
    page: 31,
    link: "https://i.ibb.co/VV8XWqw/0005-031.webp"
  },
  {
    page: 32,
    link: "https://i.ibb.co/MkLq6Y6/0005-032.webp"
  },
  {
    page: 33,
    link: "https://i.ibb.co/9qkHN9Z/0005-033.webp"
  },
  {
    page: 34,
    link: "https://i.ibb.co/P53fkyX/0005-034.webp"
  },
  {
    page: 35,
    link: "https://i.ibb.co/3C6tsZk/0005-035.webp"
  },
  {
    page: 36,
    link: "https://i.ibb.co/JpP9Nh9/0005-036.webp"
  },
  {
    page: 37,
    link: "https://i.ibb.co/DKwhFHj/0005-037.webp"
  },
  {
    page: 38,
    link: "https://i.ibb.co/z8YGvT6/0005-038.webp"
  },
  {
    page: 39,
    link: "https://i.ibb.co/mbzJsk1/0005-039.webp"
  },
  {
    page: 40,
    link: "https://i.ibb.co/CtVGLWM/0005-040.webp"
  },
  {
    page: 41,
    link: "https://i.ibb.co/vs4wJSm/0005-041.webp"
  },
  {
    page: 42,
    link: "https://i.ibb.co/hyV2z8H/0005-042.webp"
  },
  {
    page: 43,
    link: "https://i.ibb.co/t2jfCGS/0005-043.webp"
  },
  {
    page: 44,
    link: "https://i.ibb.co/HDTCMLC/0005-044.webp"
  }
]


export default listePages;