const listePages = [
    {
        page: 1,
        link: "https://i.ibb.co/mydVzpL/125.webp"
      },
      {
        page: 2,
        link: "https://i.ibb.co/tKS6FvK/126.webp"
      },
      {
        page: 3,
        link: "https://i.ibb.co/c6RjVwP/127.webp"
      },
      {
        page: 4,
        link: "https://i.ibb.co/w07QBTW/128.webp"
      },
      {
        page: 5,
        link: "https://i.ibb.co/Nysk98q/129.webp"
      },
      {
        page: 6,
        link: "https://i.ibb.co/54g0Bqb/130.webp"
      },
      {
        page: 7,
        link: "https://i.ibb.co/7K0QjJw/131.webp"
      },
      {
        page: 8,
        link: "https://i.ibb.co/z6jTm21/132.webp"
      },
      {
        page: 9,
        link: "https://i.ibb.co/rG65nNj/133.webp"
      },
      {
        page: 10,
        link: "https://i.ibb.co/f9kLTCQ/134.webp"
      },
      {
        page: 11,
        link: "https://i.ibb.co/bzBN5nr/135.webp"
      },
      {
        page: 12,
        link: "https://i.ibb.co/JchnZk2/136.webp"
      },
      {
        page: 13,
        link: "https://i.ibb.co/s194HGX/137.webp"
      },
      {
        page: 14,
        link: "https://i.ibb.co/7CkqGDR/138.webp"
      },
      {
        page: 15,
        link: "https://i.ibb.co/NxW7zZQ/139.webp"
      },
      {
        page: 16,
        link: "https://i.ibb.co/tsnbSRT/140.webp"
      },
      {
        page: 17,
        link: "https://i.ibb.co/h1jsT0t/141.webp"
      },
      {
        page: 18,
        link: "https://i.ibb.co/n0SRC17/142.webp"
      },
      {
        page: 19,
        link: "https://i.ibb.co/nLBFzjD/143.webp"
      },
      {
        page: 20,
        link: "https://i.ibb.co/v1y50mv/144.webp"
      },
      {
        page: 21,
        link: "https://i.ibb.co/47tV1zZ/145.webp"
      },
      {
        page: 22,
        link: "https://i.ibb.co/KX2yWtH/146.webp"
      },
      {
        page: 23,
        link: "https://i.ibb.co/THrLbRY/147.webp"
      },
      {
        page: 24,
        link: "https://i.ibb.co/KwkR7Jn/148.webp"
      },
      {
        page: 25,
        link: "https://i.ibb.co/k5Fpcp3/149.webp"
      },
      {
        page: 26,
        link: "https://i.ibb.co/V3k42q3/150.webp"
      },
      {
        page: 27,
        link: "https://i.ibb.co/tptgfcp/151.webp"
      },
      {
        page: 28,
        link: "https://i.ibb.co/MNbRL04/152.webp"
      },
      {
        page: 29,
        link: "https://i.ibb.co/bXMgG2c/153.webp"
      },
      {
        page: 30,
        link: "https://i.ibb.co/mXy3GZz/154.webp"
      },
      {
        page: 31,
        link: "https://i.ibb.co/Lpm8w6c/155.webp"
      },
      {
        page: 32,
        link: "https://i.ibb.co/j36h046/156.webp"
      },
      {
        page: 33,
        link: "https://i.ibb.co/GTVmFR6/157.webp"
      },
      {
        page: 34,
        link: "https://i.ibb.co/4YrTdBF/158.webp"
      },
      {
        page: 35,
        link: "https://i.ibb.co/x61f6JV/159.webp"
      },
      {
        page: 36,
        link: "https://i.ibb.co/nCHBKnW/160.webp"
      },
      {
        page: 37,
        link: "https://i.ibb.co/P4DV4vg/161.webp"
      },
      {
        page: 38,
        link: "https://i.ibb.co/pjJgdN1/162.webp"
      },
      {
        page: 39,
        link: "https://i.ibb.co/Kr5zZ7c/163.webp"
      },
      {
        page: 40,
        link: "https://i.ibb.co/w6FF943/164.webp"
      },
      {
        page: 41,
        link: "https://i.ibb.co/vzVkXQz/165.webp"
      },
      {
        page: 42,
        link: "https://i.ibb.co/2qqWHHp/166.webp"
      },
      {
        page: 43,
        link: "https://i.ibb.co/mhC0Bh1/167.webp"
      }
]


export default listePages;