const listePages = [
    {
        "page": 1,
        "link": "https://i.ibb.co/CVytbLQ/0094.webp"
      },
      {
        "page": 2,
        "link": "https://i.ibb.co/1GmWm4f/0095.webp"
      },
      {
        "page": 3,
        "link": "https://i.ibb.co/pRDjQgg/0096.webp"
      },
      {
        "page": 4,
        "link": "https://i.ibb.co/jVf353b/0097.webp"
      },
      {
        "page": 5,
        "link": "https://i.ibb.co/wNWjHF7/0098.webp"
      },
      {
        "page": 6,
        "link": "https://i.ibb.co/WtLBW7S/0099.webp"
      },
      {
        "page": 7,
        "link": "https://i.ibb.co/QN2QhGP/0100.webp"
      },
      {
        "page": 8,
        "link": "https://i.ibb.co/TthfvPZ/0101.webp"
      },
      {
        "page": 9,
        "link": "https://i.ibb.co/BL9sLQX/0102.webp"
      },
      {
        "page": 10,
        "link": "https://i.ibb.co/Jn3Z7tF/0103.webp"
      },
      {
        "page": 11,
        "link": "https://i.ibb.co/Lgbdynv/0104.webp"
      },
      {
        "page": 12,
        "link": "https://i.ibb.co/JsWRxdH/0105.webp"
      },
      {
        "page": 13,
        "link": "https://i.ibb.co/Y0YM2pL/0106.webp"
      },
      {
        "page": 14,
        "link": "https://i.ibb.co/b66vp7X/0107.webp"
      },
      {
        "page": 15,
        "link": "https://i.ibb.co/1Jdm7Cv/0108.webp"
      },
      {
        "page": 16,
        "link": "https://i.ibb.co/TvpzVVM/0109.webp"
      },
      {
        "page": 17,
        "link": "https://i.ibb.co/zbYGRsX/0110.webp"
      },
      {
        "page": 18,
        "link": "https://i.ibb.co/0cXkVVf/0111.webp"
      },
      {
        "page": 19,
        "link": "https://i.ibb.co/nbGhnGZ/0112.webp"
      },
      {
        "page": 20,
        "link": "https://i.ibb.co/kQyNBYv/0113.webp"
      },
      {
        "page": 21,
        "link": "https://i.ibb.co/swnhS6N/0114.webp"
      },
      {
        "page": 22,
        "link": "https://i.ibb.co/47Lfw28/0115.webp"
      },
      {
        "page": 23,
        "link": "https://i.ibb.co/4pH7n8d/0116.webp"
      },
      {
        "page": 24,
        "link": "https://i.ibb.co/vvKsK50/0117.webp"
      },
      {
        "page": 25,
        "link": "https://i.ibb.co/db1B3MP/0118.webp"
      },
      {
        "page": 26,
        "link": "https://i.ibb.co/310hfrw/0119.webp"
      },
      {
        "page": 27,
        "link": "https://i.ibb.co/YpqDcwq/0120.webp"
      },
      {
        "page": 28,
        "link": "https://i.ibb.co/L0mzkqP/0121.webp"
      },
      {
        "page": 29,
        "link": "https://i.ibb.co/DDKV9Dr/0122.webp"
      },
      {
        "page": 30,
        "link": "https://i.ibb.co/NT78pQh/0123.webp"
      },
      {
        "page": 31,
        "link": "https://i.ibb.co/YhWYBN8/0124.webp"
      },
      {
        "page": 32,
        "link": "https://i.ibb.co/QvxkMbG/0125.webp"
      },
      {
        "page": 33,
        "link": "https://i.ibb.co/x3q3CRq/0126.webp"
      },
      {
        "page": 34,
        "link": "https://i.ibb.co/WPPpZg5/0127.webp"
      },
      {
        "page": 35,
        "link": "https://i.ibb.co/CQ3ynPt/0128.webp"
      },
      {
        "page": 36,
        "link": "https://i.ibb.co/2dFZ9WS/0129.webp"
      },
      {
        "page": 37,
        "link": "https://i.ibb.co/sF75kQs/0130.webp"
      },
      {
        "page": 38,
        "link": "https://i.ibb.co/pyhB2Pn/0131.webp"
      },
      {
        "page": 39,
        "link": "https://i.ibb.co/PF21Jf6/0132.webp"
      },
      {
        "page": 40,
        "link": "https://i.ibb.co/PFs3K32/0133.webp"
      },
      {
        "page": 41,
        "link": "https://i.ibb.co/7rrq6PY/0134.webp"
      },
      {
        "page": 42,
        "link": "https://i.ibb.co/fScc2ys/0135.webp"
      },
      {
        "page": 43,
        "link": "https://i.ibb.co/JBmKxrS/0136.webp"
      },
      {
        "page": 44,
        "link": "https://i.ibb.co/kyx0HWV/0137.webp"
      },
      {
        "page": 45,
        "link": "https://i.ibb.co/KqRBB35/0138.webp"
      },
      {
        "page": 46,
        "link": "https://i.ibb.co/PMY3Bwv/0139.webp"
      },
      {
        "page": 47,
        "link": "https://i.ibb.co/fvVTDyy/0140.webp"
      }
]


export default listePages;