const listePages = [
    {
      page: 1,
      link: "https://i.ibb.co/Fq0C5hZ/0102-001.webp"
    },
    {
      page: 2,
      link: "https://i.ibb.co/8MLR7PV/0102-002.webp"
    },
    {
      page: 3,
      link: "https://i.ibb.co/NWknkVr/0102-003.webp"
    },
    {
      page: 4,
      link: "https://i.ibb.co/tpNZxsN/0102-004.webp"
    },
    {
      page: 5,
      link: "https://i.ibb.co/1dRpRkQ/0102-005.webp"
    },
    {
      page: 6,
      link: "https://i.ibb.co/6F4g6Kb/0102-006.webp"
    },
    {
      page: 7,
      link: "https://i.ibb.co/3Tkss8S/0102-007.webp"
    },
    {
      page: 8,
      link: "https://i.ibb.co/Gn0Wm1y/0102-008.webp"
    },
    {
      page: 9,
      link: "https://i.ibb.co/RymNFNH/0102-009.webp"
    },
    {
      page: 10,
      link: "https://i.ibb.co/LkxFWKz/0102-010.webp"
    },
    {
      page: 11,
      link: "https://i.ibb.co/bv03hVT/0102-011.webp"
    },
    {
      page: 12,
      link: "https://i.ibb.co/3fKPnTM/0102-012.webp"
    },
    {
      page: 13,
      link: "https://i.ibb.co/XVpYR7j/0102-013.webp"
    },
    {
      page: 14,
      link: "https://i.ibb.co/smXXb6J/0102-014.webp"
    },
    {
      page: 15,
      link: "https://i.ibb.co/k0BLpS3/0102-015.webp"
    },
    {
      page: 16,
      link: "https://i.ibb.co/9Vk3Rqq/0102-016.webp"
    },
    {
      page: 17,
      link: "https://i.ibb.co/7GW1r9s/0102-017.webp"
    },
    {
      page: 18,
      link: "https://i.ibb.co/kGfw35z/0102-018.webp"
    },
    {
      page: 19,
      link: "https://i.ibb.co/fMYSsd5/0102-019.webp"
    },
    {
      page: 20,
      link: "https://i.ibb.co/5G6CfgM/0102-020.webp"
    },
    {
      page: 21,
      link: "https://i.ibb.co/qdt8J3S/0102-021.webp"
    },
    {
      page: 22,
      link: "https://i.ibb.co/jfvHRLq/0102-022.webp"
    },
    {
      page: 23,
      link: "https://i.ibb.co/S0Ncm8R/0102-023.webp"
    },
    {
      page: 24,
      link: "https://i.ibb.co/NxDqr0N/0102-024.webp"
    },
    {
      page: 25,
      link: "https://i.ibb.co/ZKnfcR2/0102-025.webp"
    },
    {
      page: 26,
      link: "https://i.ibb.co/0Gv0HQf/0102-026.webp"
    },
    {
      page: 27,
      link: "https://i.ibb.co/jrP2TDz/0102-027.webp"
    },
    {
      page: 28,
      link: "https://i.ibb.co/9vbrTnL/0102-028.webp"
    },
    {
      page: 29,
      link: "https://i.ibb.co/nDZQnnv/0102-029.webp"
    },
    {
      page: 30,
      link: "https://i.ibb.co/S7nsx77/0102-030.webp"
    },
    {
      page: 31,
      link: "https://i.ibb.co/vh0BMmM/0102-031.webp"
    },
    {
      page: 32,
      link: "https://i.ibb.co/Jy7xsNv/0102-032.webp"
    }
  ]
  

export default listePages;