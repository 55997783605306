const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/x6fqG7R/0036-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/dDcQHb5/0036-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/qr1tGMX/0036-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/zN7KdTY/0036-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/nngPQCk/0036-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/z7xcZ8K/0036-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/9YDzCTw/0036-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/Fn3h6bn/0036-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/CJktHMm/0036-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/6X7NvNf/0036-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/sbw1HJ0/0036-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/kGvZHt9/0036-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/f9LJTDB/0036-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/k4fFfym/0036-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/0Xh7DnG/0036-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/vhgxN6d/0036-016.webp"
  }
]


export default listePages;