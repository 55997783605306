import React, { useState, useEffect } from 'react'

export default function HelpSection() {
    const [isOpen, setIsOpen] = useState(false);
    const [isShown, setIsShown] = useState(true);
    const [largeurEcran, setLargeurEcran] = useState(window.innerWidth);
    const [messages, setMessages] = useState([
        {
            id: 1,
            author: "banji",
            content: "En quoi puis-je t'aider ?"
        }
    ])

    useEffect(() => {
        function handleResize() {
            setLargeurEcran(window.innerWidth);
        }
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);

    useEffect(() => {
        if (isOpen) {
            let messagesDiv = document.querySelector('.Messages');
            function scrollToBottom() {
                messagesDiv.scrollTop = messagesDiv.scrollHeight;
            }
            scrollToBottom()
        }
        
    }, [messages, isOpen])

    function interact(nb) {
        switch (nb) {
            case 1:
                setMessages(prevMessages => [...prevMessages, { id: prevMessages.length + 1, author: "you", content: "J'ai un problème de lecteur." }])
                setTimeout(function() {
                    setMessages(prevMessages => [...prevMessages, { id: prevMessages.length + 1, author: "banji", content: "Pour corriger le souci, si tu en as la possibilité, change de lecteur et nettoie le cache de ton navigateur. Si le problème persiste, contacte-nous sur le Discord !" }])
                }, 700)
                setTimeout(function() {
                    setMessages(prevMessages => [...prevMessages, { id: prevMessages.length + 1, author: "banji", content: "Besoin d'autre chose ?", same: true }])
                }, 1400)
                break
            case 2:
                setMessages(prevMessages => [...prevMessages, { id: prevMessages.length + 1, author: "you", content: "Quand sortent les épisodes de Beyblade X ?" }])
                setTimeout(function() {
                    setMessages(prevMessages => [...prevMessages,  { id: prevMessages.length + 1, author: "banji", content: "La VOSTFR de Beyblade X sort chaque samedi à 22h00. Il peut arriver qu'elle ait du retard, mais vous en serez informés à l'avance sur le serveur Discord." }])
                }, 700)
                setTimeout(function() {
                    setMessages(prevMessages => [...prevMessages, { id: prevMessages.length + 1, author: "banji", content: "Besoin d'autre chose ?", same: true }])
                }, 1400)
                break
            case 3:
                setMessages(prevMessages => [...prevMessages, { id: prevMessages.length + 1, author: "you", content: "Comment je télécharge l'application BEtv ?" }])
                setTimeout(function() {
                    setMessages(prevMessages => [...prevMessages, { id: prevMessages.length + 1, author: "banji", content: "Pour iOS, il faut être sur Safari, ouvrir le menu au milieu en bas et faire \"Sur l'écran d'accueil\"." }])
                }, 700)
                setTimeout(function() {
                    setMessages(prevMessages => [...prevMessages, { id: prevMessages.length + 1, author: "banji", content: "Pour Android, il faut être sur Chrome, ouvrir le menu en haut à droite puis faire \"Installer l'application\".", same: true }])
                }, 1400)
                setTimeout(function() {
                    setMessages(prevMessages => [...prevMessages, { id: prevMessages.length + 1, author: "banji", content: "Besoin d'autre chose ?", same: true }])
                }, 2100)
                break
            case 4:
                setMessages(prevMessages => [...prevMessages, { id: prevMessages.length + 1, author: "you", content: "Vous avez un serveur Discord ?" }])
                setTimeout(function() {
                    setMessages(prevMessages => [...prevMessages, { id: prevMessages.length + 1, author: "banji", content: "Bien sur, nous avons d'ailleurs le premier serveur communautaire Beyblade de France !" }])
                }, 700)
                setTimeout(function() {
                    setMessages(prevMessages => [...prevMessages, { id: prevMessages.length + 1, author: "banji", content: "Tu peux nous rejoindre à l'aide de ce lien : ", link : "https://discord.gg/HZEpzZ3", same: true }])
                }, 1400)
                setTimeout(function() {
                    setMessages(prevMessages => [...prevMessages, { id: prevMessages.length + 1, author: "banji", content: "Besoin d'autre chose ?", same: true }])
                }, 2100)
                break
            default:
                break
        }
    }

    return (
        <>
            {isShown ? (
                <section className={window.matchMedia('(display-mode: fullscreen)').matches && largeurEcran <= 850 ? 'HelpSection pwa-mode' : 'HelpSection'}>
                    {isOpen ? (
                        <article className='HelpWindow'>
                            <svg className='closeBtn' onClick={() => setIsOpen(!isOpen)} width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.05493 12.324L13.1676 6.21134" stroke="white" strokeWidth="2" strokeLinecap="round" />
                                <path d="M13.1677 12.324L7.05509 6.21134" stroke="white" strokeWidth="2" strokeLinecap="round" />
                            </svg>
                            <div className='Messages'>
                                {messages.map((message) => (
                                    <div key={message.id} className={message.author === "banji" ? ("banji") : ("yours")}>
                                        {!message.same ? (<img src={message.author === "banji" ? ('https://i.ibb.co/QnL6K1w/banji-chibi.webp') : ("https://i.ibb.co/gyq9t9S/1144760.webp")} alt='Logo Application Mobile BEtv' />) : ('')}
                                        <p style={message.same ? { marginLeft: 37 + "px", marginTop: -5 + "px" } : { marginLeft: 7 + "px" }}>{message.content}{message.link ? (<a href={message.link} target='_blank' rel='noreferrer'>{message.link}</a>) : ("")}</p>
                                    </div>
                                ))}
                            </div>
                            <div className='Separator'></div>
                            <div className='AskSection'>
                                <button onClick={() => interact(1)}>Problème de Lecteur</button>
                                <button onClick={() => interact(2)}>Sortie VOSTFR de X</button>
                                <button onClick={() => interact(3)}>Installer l'app BEtv</button>
                                <button onClick={() => interact(4)}>Discord</button>
                            </div>
                        </article>
                    ) : (
                        <article className='needhelp'>
                            <p onClick={() => setIsOpen(!isOpen)}>Besoin d'aide ?</p>
                            <button className='closeHelp' onClick={() => setIsShown(!isShown)}>
                                <svg width="25" height="25" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.05493 12.324L13.1676 6.21134" stroke="black" strokeWidth="2" strokeLinecap="round" />
                                    <path d="M13.1677 12.324L7.05509 6.21134" stroke="black" strokeWidth="2" strokeLinecap="round" />
                                </svg>
                            </button>
                        </article>
                    )}
                    <img className="BanjiTete" src='https://i.ibb.co/QnL6K1w/banji-chibi.webp' alt='Logo Application Mobile BEtv' onClick={() => setIsOpen(!isOpen)} />
                </section>
            ) : (<></>)}
            
        </>
    )
}