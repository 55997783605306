const listePages = [
  { page: 1, link: "https://i.ibb.co/MNVbxhB/0090-001.webp" },
  { page: 2, link: "https://i.ibb.co/kgPzzpq/0090-002.webp" },
  { page: 3, link: "https://i.ibb.co/sgC6P2F/0090-003.webp" },
  { page: 4, link: "https://i.ibb.co/3MP8v9F/0090-004.webp" },
  { page: 5, link: "https://i.ibb.co/ZWHFCW1/0090-005.webp" },
  { page: 6, link: "https://i.ibb.co/BCX1Hpg/0090-006.webp" },
  { page: 7, link: "https://i.ibb.co/XSbDBpm/0090-007.webp" },
  { page: 8, link: "https://i.ibb.co/ZS74Fxm/0090-008.webp" },
  { page: 9, link: "https://i.ibb.co/wd22WBN/0090-009.webp" },
  { page: 10, link: "https://i.ibb.co/dt372Vq/0090-010.webp" },
  { page: 11, link: "https://i.ibb.co/XyKjvSX/0090-011.webp" },
  { page: 12, link: "https://i.ibb.co/9Vq5ytf/0090-012.webp" },
  { page: 13, link: "https://i.ibb.co/7K4R768/0090-013.webp" },
  { page: 14, link: "https://i.ibb.co/7kgmW99/0090-014.webp" },
  { page: 15, link: "https://i.ibb.co/m6wPzxR/0090-015.webp" },
  { page: 16, link: "https://i.ibb.co/SnmbLzt/0090-016.webp" },
  { page: 17, link: "https://i.ibb.co/qCB0ncn/0090-017.webp" },
  { page: 18, link: "https://i.ibb.co/pyNqNSt/0090-018.webp" },
  { page: 19, link: "https://i.ibb.co/qJ1238G/0090-019.webp" },
  { page: 20, link: "https://i.ibb.co/DtFPtx6/0090-020.webp" },
  { page: 21, link: "https://i.ibb.co/BG4qq6z/0090-021.webp" },
  { page: 22, link: "https://i.ibb.co/CsLKK1R/0090-022.webp" },
  { page: 23, link: "https://i.ibb.co/Yp20QXB/0090-023.webp" },
  { page: 24, link: "https://i.ibb.co/bg3mn7J/0090-024.webp" },
  { page: 25, link: "https://i.ibb.co/LCtFZBR/0090-025.webp" },
  { page: 26, link: "https://i.ibb.co/272PXdy/0090-026.webp" }
]


export default listePages;