const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/0cq2Dpb/0039-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/SXSK1fT/0039-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/PrnptXF/0039-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/GTzKjyh/0039-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/sV2dCGq/0039-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/qM090Gp/0039-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/YP3D500/0039-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/PhnYS7z/0039-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/80r4G3h/0039-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/4pg89kn/0039-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/kc5gg71/0039-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/m94CWYv/0039-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/vZnD5h2/0039-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/Fwck6y4/0039-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/9r5LP81/0039-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/pv6LZwm/0039-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/C97F3Q2/0039-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/rQDb83K/0039-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/qpQkY0h/0039-019.webp"
  },
  {
    page: 20,
    link: "https://i.ibb.co/PtbxVwV/0039-020.webp"
  },
  {
    page: 21,
    link: "https://i.ibb.co/m52Fbxr/0039-021.webp"
  },
  {
    page: 22,
    link: "https://i.ibb.co/rMXydTY/0039-022.webp"
  },
  {
    page: 23,
    link: "https://i.ibb.co/BgNhq1t/0039-023.webp"
  },
  {
    page: 24,
    link: "https://i.ibb.co/vdqgRCD/0039-024.webp"
  },
  {
    page: 25,
    link: "https://i.ibb.co/LxYSSrZ/0039-025.webp"
  },
  {
    page: 26,
    link: "https://i.ibb.co/4dD87vh/0039-026.webp"
  },
  {
    page: 27,
    link: "https://i.ibb.co/Dzrk6Fx/0039-027.webp"
  },
  {
    page: 28,
    link: "https://i.ibb.co/zSnycb4/0039-028.webp"
  },
  {
    page: 29,
    link: "https://i.ibb.co/RpRSb3g/0039-029.webp"
  },
  {
    page: 30,
    link: "https://i.ibb.co/T4JyBKW/0039-030.webp"
  },
  {
    page: 31,
    link: "https://i.ibb.co/FbC4kg0/0039-031.webp"
  },
  {
    page: 32,
    link: "https://i.ibb.co/44swpJW/0039-032.webp"
  },
  {
    page: 33,
    link: "https://i.ibb.co/8gjwfDH/0039-033.webp"
  },
  {
    page: 34,
    link: "https://i.ibb.co/C2nRDH4/0039-034.webp"
  },
  {
    page: 35,
    link: "https://i.ibb.co/N660Wf2/0039-035.webp"
  },
  {
    page: 36,
    link: "https://i.ibb.co/tb0qm5n/0039-036.webp"
  },
  {
    page: 37,
    link: "https://i.ibb.co/ft52j0y/0039-037.webp"
  },
  {
    page: 38,
    link: "https://i.ibb.co/4MVY7vQ/0039-038.webp"
  },
  {
    page: 39,
    link: "https://i.ibb.co/SNXftfy/0039-039.webp"
  },
  {
    page: 40,
    link: "https://i.ibb.co/nfM5nw1/0039-040.webp"
  },
  {
    page: 41,
    link: "https://i.ibb.co/vz0YLyj/0039-041.webp"
  },
  {
    page: 42,
    link: "https://i.ibb.co/BC3k5Z2/0039-042.webp"
  }
]


export default listePages;