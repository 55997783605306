const listePages = [
    {
        "page": 1,
        "link": "https://i.ibb.co/brDsZ8D/0155.webp"
      },
      {
        "page": 2,
        "link": "https://i.ibb.co/hX6JCnW/0156.webp"
      },
      {
        "page": 3,
        "link": "https://i.ibb.co/RB39MKJ/0157.webp"
      },
      {
        "page": 4,
        "link": "https://i.ibb.co/Bjqm0GX/0158.webp"
      },
      {
        "page": 5,
        "link": "https://i.ibb.co/Z2c1Vmk/0159.webp"
      },
      {
        "page": 6,
        "link": "https://i.ibb.co/WzMPLC1/0160.webp"
      },
      {
        "page": 7,
        "link": "https://i.ibb.co/1QdGBxH/0161.webp"
      },
      {
        "page": 8,
        "link": "https://i.ibb.co/HC9tYfM/0162.webp"
      },
      {
        "page": 9,
        "link": "https://i.ibb.co/WKm5jm9/0163.webp"
      },
      {
        "page": 10,
        "link": "https://i.ibb.co/sCgBhyd/0164.webp"
      },
      {
        "page": 11,
        "link": "https://i.ibb.co/FDK0Hw8/0165.webp"
      },
      {
        "page": 12,
        "link": "https://i.ibb.co/JdYsFfh/0166.webp"
      },
      {
        "page": 13,
        "link": "https://i.ibb.co/wCNcyTx/0167.webp"
      },
      {
        "page": 14,
        "link": "https://i.ibb.co/jTBpVq3/0168.webp"
      },
      {
        "page": 15,
        "link": "https://i.ibb.co/3sQNkZZ/0169.webp"
      },
      {
        "page": 16,
        "link": "https://i.ibb.co/zZfMNt9/0170.webp"
      },
      {
        "page": 17,
        "link": "https://i.ibb.co/HXgtNRg/0171.webp"
      },
      {
        "page": 18,
        "link": "https://i.ibb.co/kxSg0vG/0172.webp"
      },
      {
        "page": 19,
        "link": "https://i.ibb.co/711shjL/0173.webp"
      },
      {
        "page": 20,
        "link": "https://i.ibb.co/pZWzpRM/0174.webp"
      },
      {
        "page": 21,
        "link": "https://i.ibb.co/LZcHm4V/0175.webp"
      },
      {
        "page": 22,
        "link": "https://i.ibb.co/StjJ3HM/0176.webp"
      },
      {
        "page": 23,
        "link": "https://i.ibb.co/7zPqNjx/0177.webp"
      },
      {
        "page": 24,
        "link": "https://i.ibb.co/jR2FxMy/0178.webp"
      },
      {
        "page": 25,
        "link": "https://i.ibb.co/QYVPqzC/0179.webp"
      },
      {
        "page": 26,
        "link": "https://i.ibb.co/wct0Zqk/0180.webp"
      },
      {
        "page": 27,
        "link": "https://i.ibb.co/7kzBKRS/0181.webp"
      },
      {
        "page": 28,
        "link": "https://i.ibb.co/qB3HmkP/0182.webp"
      },
      {
        "page": 29,
        "link": "https://i.ibb.co/QPPmQB5/0183.webp"
      },
      {
        "page": 30,
        "link": "https://i.ibb.co/Lt3ZV3v/0184.webp"
      },
      {
        "page": 31,
        "link": "https://i.ibb.co/kMT5knG/0185.webp"
      },
      {
        "page": 32,
        "link": "https://i.ibb.co/Y81Wssw/0186.webp"
      },
      {
        "page": 33,
        "link": "https://i.ibb.co/RN3Hzdd/0187.webp"
      },
      {
        "page": 34,
        "link": "https://i.ibb.co/BC2xBrc/0188.webp"
      },
      {
        "page": 35,
        "link": "https://i.ibb.co/rGjKQSf/0189.webp"
      },
      {
        "page": 36,
        "link": "https://i.ibb.co/Yp53Cm7/0190.webp"
      },
      {
        "page": 37,
        "link": "https://i.ibb.co/GHkPBVS/0191.webp"
      },
      {
        "page": 38,
        "link": "https://i.ibb.co/Z1fsqSZ/0192.webp"
      },
      {
        "page": 39,
        "link": "https://i.ibb.co/d4dgJSb/0193.webp"
      },
      {
        "page": 40,
        "link": "https://i.ibb.co/Gnb8ndg/0194.webp"
      }
]


export default listePages;