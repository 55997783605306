const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/4gwDRk7/18-5-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/WpRsTBn/18-5-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/TBGyDNJ/18-5-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/jzVtTqm/18-5-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/JqvQJp5/18-5-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/NpbPN7X/18-5-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/w4x3MdB/18-5-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/8cK6p7m/18-5-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/RjFtBkp/18-5-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/Ny19V9Z/18-5-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/hKK7RrZ/18-5-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/jG5fBRF/18-5-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/m4q8srF/18-5-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/MGL9qgX/18-5-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/n6PhkTZ/18-5-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/jJ3Hd0Q/18-5-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/n6f5Rvx/18-5-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/r7WhRpB/18-5-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/jM50ms4/18-5-019.webp"
  },
  {
    page: 20,
    link: "https://i.ibb.co/nkGNN8d/18-5-020.webp"
  },
  {
    page: 21,
    link: "https://i.ibb.co/hsSfkGB/18-5-021.webp"
  },
  {
    page: 22,
    link: "https://i.ibb.co/LYXwxLz/18-5-022.webp"
  },
  {
    page: 23,
    link: "https://i.ibb.co/Xxb6WdS/18-5-023.webp"
  },
  {
    page: 24,
    link: "https://i.ibb.co/XxqzV5z/18-5-024.webp"
  },
  {
    page: 25,
    link: "https://i.ibb.co/Rj6N3nY/18-5-025.webp"
  },
  {
    page: 26,
    link: "https://i.ibb.co/8xP6bLD/18-5-026.webp"
  },
  {
    page: 27,
    link: "https://i.ibb.co/41Ct4Yn/18-6-001.webp"
  },
  {
    page: 28,
    link: "https://i.ibb.co/Fqncsgb/18-6-002.webp"
  },
  {
    page: 29,
    link: "https://i.ibb.co/2c1zkp4/18-6-003.webp"
  },
  {
    page: 30,
    link: "https://i.ibb.co/hFmS5HH/18-6-004.webp"
  },
  {
    page: 31,
    link: "https://i.ibb.co/XZYWF0M/18-6-005.webp"
  },
  {
    page: 32,
    link: "https://i.ibb.co/pWbcx4n/18-6-006.webp"
  },
  {
    page: 33,
    link: "https://i.ibb.co/wpkq5Zr/18-6-007.webp"
  }
]

export default listePages;