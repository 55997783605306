const listePages = [
    {
        page: 1,
        link: "https://i.ibb.co/FYSRmFh/001.webp"
      },
      {
        page: 2,
        link: "https://i.ibb.co/yVLDw74/003.webp"
      },
      {
        page: 3,
        link: "https://i.ibb.co/L5vkGCN/004.webp"
      },
      {
        page: 4,
        link: "https://i.ibb.co/NjfPZdT/005.webp"
      },
      {
        page: 5,
        link: "https://i.ibb.co/TkdJtcq/006.webp"
      },
      {
        page: 6,
        link: "https://i.ibb.co/N64bknZ/007.webp"
      },
      {
        page: 7,
        link: "https://i.ibb.co/mSLwvwh/008.webp"
      },
      {
        page: 8,
        link: "https://i.ibb.co/rt8Z518/009.webp"
      },
      {
        page: 9,
        link: "https://i.ibb.co/XY17RWH/010.webp"
      },
      {
        page: 10,
        link: "https://i.ibb.co/3kLwczj/011.webp"
      },
      {
        page: 11,
        link: "https://i.ibb.co/WpPsXsF/012.webp"
      },
      {
        page: 12,
        link: "https://i.ibb.co/VwP86zd/013.webp"
      },
      {
        page: 13,
        link: "https://i.ibb.co/h7643JL/014.webp"
      },
      {
        page: 14,
        link: "https://i.ibb.co/F48MJJt/015.webp"
      },
      {
        page: 15,
        link: "https://i.ibb.co/jkJk0pR/016.webp"
      },
      {
        page: 16,
        link: "https://i.ibb.co/G9czDtK/017.webp"
      },
      {
        page: 17,
        link: "https://i.ibb.co/yYpt98P/018.webp"
      },
      {
        page: 18,
        link: "https://i.ibb.co/bH0x5Np/019.webp"
      },
      {
        page: 19,
        link: "https://i.ibb.co/vPNJy6b/020.webp"
      },
      {
        page: 20,
        link: "https://i.ibb.co/vQHmT29/021.webp"
      },
      {
        page: 21,
        link: "https://i.ibb.co/Cz0YSCc/022.webp"
      },
      {
        page: 22,
        link: "https://i.ibb.co/w6Cp2ty/023.webp"
      },
      {
        page: 23,
        link: "https://i.ibb.co/6RXjL62/024.webp"
      },
      {
        page: 24,
        link: "https://i.ibb.co/HCMqgQj/025.webp"
      },
      {
        page: 25,
        link: "https://i.ibb.co/Tc0CMyQ/026.webp"
      },
      {
        page: 26,
        link: "https://i.ibb.co/SJWGG34/027.webp"
      },
      {
        page: 27,
        link: "https://i.ibb.co/dbWGjh1/028.webp"
      },
      {
        page: 28,
        link: "https://i.ibb.co/k8crF6C/029.webp"
      },
      {
        page: 29,
        link: "https://i.ibb.co/4Pjkx0p/030.webp"
      },
      {
        page: 30,
        link: "https://i.ibb.co/zx0b5XW/031.webp"
      },
      {
        page: 31,
        link: "https://i.ibb.co/WFM9GHD/032.webp"
      },
      {
        page: 32,
        link: "https://i.ibb.co/7ywkRy5/033.webp"
      },
      {
        page: 33,
        link: "https://i.ibb.co/dPRPWP3/034.webp"
      },
      {
        page: 34,
        link: "https://i.ibb.co/s2PstLf/035.webp"
      },
      {
        page: 35,
        link: "https://i.ibb.co/84QFj4X/036.webp"
      },
      {
        page: 36,
        link: "https://i.ibb.co/1M9Cpn4/037.webp"
      },
      {
        page: 37,
        link: "https://i.ibb.co/XCtjw7t/038.webp"
      },
      {
        page: 38,
        link: "https://i.ibb.co/bBBsfSS/039.webp"
      },
      {
        page: 39,
        link: "https://i.ibb.co/qR9pfrw/040.webp"
      },
      {
        page: 40,
        link: "https://i.ibb.co/pRSWdJy/041.webp"
      },
      {
        page: 41,
        link: "https://i.ibb.co/3f9nLmP/042.webp"
      },
      {
        page: 42,
        link: "https://i.ibb.co/fd3S8wc/043.webp"
      },
      {
        page: 43,
        link: "https://i.ibb.co/kqkBBnQ/044.webp"
      },
      {
        page: 44,
        link: "https://i.ibb.co/Phrd3KQ/045.webp"
      },
      {
        page: 45,
        link: "https://i.ibb.co/TrpLxzK/046.webp"
      },
      {
        page: 46,
        link: "https://i.ibb.co/zXKTPHt/047.webp"
      },
      {
        page: 47,
        link: "https://i.ibb.co/74nkL9J/048.webp"
      }
]


export default listePages;