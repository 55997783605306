const filmsList = [
    {
        id : 1,
        generation : "Bakuten Shoot Beyblade",
        gen : "bakuten",
        titre : "Takao VS Daichi",
        link : "/bakuten/film",
        img : "https://i.ibb.co/ydL3gbv/kv-BEYBLADE-The-Movie-Fierce-Battle.webp",
        synopsis : "Alors que Takao vient de remporter le championnat du monde, un jeune garçon du nom de Daichi vient le défier. Pendant ce temps sur une île, un groupe d'enfants trouve d'étranges inscriptions dans une grotte et réveillent les esprits qui y étaient endormis, ces derniers prennent possession des enfants.",
        vostfr : true,
        vostfrlink : "/bakuten/film/vostfr",
        logo : "https://i.ibb.co/BLbtzCz/bakuten-movie.webp",
        character: "https://i.ibb.co/TB23CDs/tyson-movie.webp"
    },
    {
        id : 2,
        generation : "Metal Fight Beyblade",
        gen : "metal",
        titre : "Sol Blaze",
        link : "/metal/film",
        img : "https://i.ibb.co/B3b20jS/2-ACz-L2h-S.webp",
        synopsis : "On retrouve nos héros en vacances à la mer. Les garçons ne comptent pas profiter de la plage mais plutôt de leur toupie dans un tournoi qui se déroule pas loin. Tout se passe bien jusqu'à ce qu'arrive un inconnu avec sa toupie Sol Blaze.",
        vostfr : true,
        vostfrlink : "/metal/film/vostfr",
        vf : true,
        vflink : "/metal/film/vf",
        logo : "https://i.ibb.co/gtQ0PKq/beyblade-masters.webp",
        character: "https://i.ibb.co/Sv8v8JR/gingka-movie.webp"
    }
]

export default filmsList