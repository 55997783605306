const listePages = [
    {
      page: 1,
      link: "https://i.ibb.co/ww6NCr2/0105-001.webp"
    },
    {
      page: 2,
      link: "https://i.ibb.co/Vqcgjfb/0105-002.webp"
    },
    {
      page: 3,
      link: "https://i.ibb.co/wMNpZSL/0105-003.webp"
    },
    {
      page: 4,
      link: "https://i.ibb.co/RNzwGn8/0105-004.webp"
    },
    {
      page: 5,
      link: "https://i.ibb.co/dfTH6DK/0105-005.webp"
    },
    {
      page: 6,
      link: "https://i.ibb.co/Kr2LLxC/0105-006.webp"
    },
    {
      page: 7,
      link: "https://i.ibb.co/5RbzXNV/0105-007.webp"
    },
    {
      page: 8,
      link: "https://i.ibb.co/YLjHD9Z/0105-008.webp"
    },
    {
      page: 9,
      link: "https://i.ibb.co/6DPP3KF/0105-009.webp"
    },
    {
      page: 10,
      link: "https://i.ibb.co/N31KFmD/0105-010.webp"
    },
    {
      page: 11,
      link: "https://i.ibb.co/BgdLz8k/0105-011.webp"
    },
    {
      page: 12,
      link: "https://i.ibb.co/G7L9LmH/0105-012.webp"
    },
    {
      page: 13,
      link: "https://i.ibb.co/94jrmJ9/0105-013.webp"
    },
    {
      page: 14,
      link: "https://i.ibb.co/89wcpz2/0105-014.webp"
    },
    {
      page: 15,
      link: "https://i.ibb.co/Zg2B5jP/0105-015.webp"
    },
    {
      page: 16,
      link: "https://i.ibb.co/tZd58rK/0105-016.webp"
    },
    {
      page: 17,
      link: "https://i.ibb.co/619kW9Z/0105-017.webp"
    },
    {
      page: 18,
      link: "https://i.ibb.co/Cs0mBy9/0105-018.webp"
    },
    {
      page: 19,
      link: "https://i.ibb.co/NFxYc3Z/0105-019.webp"
    },
    {
      page: 20,
      link: "https://i.ibb.co/DzyVM1Y/0105-020.webp"
    },
    {
      page: 21,
      link: "https://i.ibb.co/nngC7xR/0105-021.webp"
    },
    {
      page: 22,
      link: "https://i.ibb.co/x5S5yL0/0105-022.webp"
    },
    {
      page: 23,
      link: "https://i.ibb.co/3RhXq6X/0105-023.webp"
    },
    {
      page: 24,
      link: "https://i.ibb.co/cC7xzBQ/0105-024.webp"
    },
    {
      page: 25,
      link: "https://i.ibb.co/SvHG8Yj/0105-025.webp"
    },
    {
      page: 26,
      link: "https://i.ibb.co/VpmnmQg/0105-026.webp"
    },
    {
      page: 27,
      link: "https://i.ibb.co/Zf6mXKm/0105-027.webp"
    },
    {
      page: 28,
      link: "https://i.ibb.co/S3t0k0Y/0105-028.webp"
    },
    {
      page: 29,
      link: "https://i.ibb.co/0MGqbC9/0105-029.webp"
    },
    {
      page: 30,
      link: "https://i.ibb.co/SKPh3VL/0105-030.webp"
    },
    {
      page: 31,
      link: "https://i.ibb.co/rfG5cXL/0105-031.webp"
    },
    {
      page: 32,
      link: "https://i.ibb.co/51P82Fr/0105-032.webp"
    },
    {
      page: 33,
      link: "https://i.ibb.co/XXjpdBh/0105-033.webp"
    },
    {
      page: 34,
      link: "https://i.ibb.co/kHkFxBP/0105-034.webp"
    },
    {
      page: 35,
      link: "https://i.ibb.co/f8snXZg/0105-035.webp"
    }
  ]
  

export default listePages;