const listePages = [
    { page: 1, link: "https://i.ibb.co/ThBjXXx/0083-001.webp" },
    { page: 2, link: "https://i.ibb.co/pwxJb4z/0083-002.webp" },
    { page: 3, link: "https://i.ibb.co/qp1sMfF/0083-003.webp" },
    { page: 4, link: "https://i.ibb.co/gm2WWFm/0083-004.webp" },
    { page: 5, link: "https://i.ibb.co/zZnLXHz/0083-005.webp" },
    { page: 6, link: "https://i.ibb.co/mTtFrf0/0083-006.webp" },
    { page: 7, link: "https://i.ibb.co/3h2pSgd/0083-007.webp" },
    { page: 8, link: "https://i.ibb.co/GQhCyCK/0083-008.webp" },
    { page: 9, link: "https://i.ibb.co/C57pr7T/0083-009.webp" },
    { page: 10, link: "https://i.ibb.co/61R3gH3/0083-010.webp" },
    { page: 11, link: "https://i.ibb.co/dLLQS5v/0083-011.webp" },
    { page: 12, link: "https://i.ibb.co/c12FPzy/0083-012.webp" },
    { page: 13, link: "https://i.ibb.co/Tbrw96S/0083-013.webp" },
    { page: 14, link: "https://i.ibb.co/JpGx9nX/0083-014.webp" },
    { page: 15, link: "https://i.ibb.co/JsqKSbZ/0083-015.webp" },
    { page: 16, link: "https://i.ibb.co/LY4zLpV/0083-016.webp" },
    { page: 17, link: "https://i.ibb.co/SNQ7xHq/0083-017.webp" },
    { page: 18, link: "https://i.ibb.co/n31dWXw/0083-018.webp" },
    { page: 19, link: "https://i.ibb.co/S62xwRZ/0083-019.webp" },
    { page: 20, link: "https://i.ibb.co/6bdsGK5/0083-020.webp" },
    { page: 21, link: "https://i.ibb.co/jHz4HbY/0083-021.webp" },
    { page: 22, link: "https://i.ibb.co/xMnF05X/0083-022.webp" },
    { page: 23, link: "https://i.ibb.co/dL1g2mm/0083-023.webp" },
    { page: 24, link: "https://i.ibb.co/d0BYqR6/0083-024.webp" },
    { page: 25, link: "https://i.ibb.co/j434NXY/0083-025.webp" },
    { page: 26, link: "https://i.ibb.co/rtfpsV7/0083-026.webp" },
    { page: 27, link: "https://i.ibb.co/4KjBzc8/0083-027.webp" },
    { page: 28, link: "https://i.ibb.co/tKnHnbn/0083-028.webp" },
    { page: 29, link: "https://i.ibb.co/Y2yB18p/0083-029.webp" },
    { page: 30, link: "https://i.ibb.co/C0DBS7p/0083-030.webp" },
    { page: 31, link: "https://i.ibb.co/c2J6GK0/0083-031.webp" },
    { page: 32, link: "https://i.ibb.co/PQG8Kdj/0083-032.webp" }
  ]
  

export default listePages;