import { Helmet } from 'react-helmet';
import MenuGeneration from '../components/MenuGenerations';
import { useParams } from 'react-router-dom';

export default function Generation() {
    const param = useParams()
    const generationURL = param.generation

    let actualGeneration;

    // eslint-disable-next-line default-case
    switch (generationURL) {
        case "bakuten":
            actualGeneration = "Bakuten"
            break
        case "metal":
            actualGeneration = "Metal"
            break
        case "burst":
            actualGeneration = "Burst"
            break
        case "x":
            actualGeneration = "Beyblade X"
            break
        case "spinoff":
            actualGeneration = "Spin-Off"
            break
    }

    return (
        <>
            {actualGeneration !== undefined ? (
                <Helmet>
                    <title>{actualGeneration} | Streaming Espace</title>
                    <link rel="canonical" href={`https://www.streaming-espace.fr/${generationURL}`}/>
                    <meta name="description" content={"Découvre ou redécouvre le contenu de la génération " + actualGeneration + " que ce soit en scan, en anime ou en film lorsque c'est disponible."} />
                </Helmet>
            ) : (<></>)}
            <main className="content container Generationpage">
                <MenuGeneration />
            </main>                
        </>
    )       
}