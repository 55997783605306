const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/DWTsxsL/0051-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/nfNtNcH/0051-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/5vH3ftC/0051-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/yfWsMSq/0051-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/DrC37J7/0051-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/GVsLc7P/0051-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/0rFT9k4/0051-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/m0qFNHW/0051-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/ZM74bnx/0051-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/0V6LT9C/0051-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/8r47WMh/0051-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/w0XR7zy/0051-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/VwXcx01/0051-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/tLQGGq7/0051-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/q7MFMGZ/0051-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/b2y9xbB/0051-016.webp"
  }
];


export default listePages;