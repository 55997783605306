const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/6mVTRP9/0033-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/0y2FHDC/0033-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/zhCbtjp/0033-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/R6Wjvxk/0033-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/t85fTyC/0033-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/7pn6SLQ/0033-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/RBzwNWR/0033-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/z609mfd/0033-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/PxqCwRH/0033-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/7Y3RVnD/0033-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/SPd4rvn/0033-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/gWSVGFP/0033-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/Rj4PWpJ/0033-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/SRLzch0/0033-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/SPPK90Y/0033-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/5Mkhb68/0033-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/rkcPFV8/0033-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/G2YxQZ6/0033-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/80LykrQ/0033-019.webp"
  },
  {
    page: 20,
    link: "https://i.ibb.co/4jVx1Ts/0033-020.webp"
  }  
]

export default listePages;