const listeEpisodes = [
    {
        episode: 1,
        link: "https://video.sibnet.ru/shell.php?videoid=5228796"
    },
    {
        episode: 2,
        link: "https://video.sibnet.ru/shell.php?videoid=5228797"
    },
    {
        episode: 3,
        link: "https://video.sibnet.ru/shell.php?videoid=5228798"
    },
    {
        episode: 4,
        link: "https://video.sibnet.ru/shell.php?videoid=5228800"
    },
    {
        episode: 5,
        link: "https://video.sibnet.ru/shell.php?videoid=5228802"
    },
    {
        episode: 6,
        link: "https://video.sibnet.ru/shell.php?videoid=5228805"
    },
    {
        episode: 7,
        link: "https://video.sibnet.ru/shell.php?videoid=5228806"
    },
    {
        episode: 8,
        link: "https://video.sibnet.ru/shell.php?videoid=5228807"
    },
    {
        episode: 9,
        link: "https://video.sibnet.ru/shell.php?videoid=5228809"
    },
    {
        episode: 10,
        link: "https://video.sibnet.ru/shell.php?videoid=5228810"
    },
    {
        episode: 11,
        link: "https://video.sibnet.ru/shell.php?videoid=5228814"
    },
    {
        episode: 12,
        link: "https://video.sibnet.ru/shell.php?videoid=5228820"
    },
    {
        episode: 13,
        link: "https://video.sibnet.ru/shell.php?videoid=5228828"
    },
    {
        episode: 14,
        link: "https://video.sibnet.ru/shell.php?videoid=5228829"
    },
    {
        episode: 15,
        link: "https://video.sibnet.ru/shell.php?videoid=5228831"
    },
    {
        episode: 16,
        link: "https://video.sibnet.ru/shell.php?videoid=5228835"
    },
    {
        episode: 17,
        link: "https://video.sibnet.ru/shell.php?videoid=5228838"
    },
    {
        episode: 18,
        link: "https://video.sibnet.ru/shell.php?videoid=5228841"
    },
    {
        episode: 19,
        link: "https://video.sibnet.ru/shell.php?videoid=5228844"
    },
    {
        episode: 20,
        link: "https://video.sibnet.ru/shell.php?videoid=5228847"
    },
    {
        episode: 21,
        link: "https://video.sibnet.ru/shell.php?videoid=5228851"
    },
    {
        episode: 22,
        link: "https://video.sibnet.ru/shell.php?videoid=5228616"
    },
    {
        episode: 23,
        link: "https://video.sibnet.ru/shell.php?videoid=5228620"
    },
    {
        episode: 24,
        link: "https://video.sibnet.ru/shell.php?videoid=5228623"
    },
    {
        episode: 25,
        link: "https://video.sibnet.ru/shell.php?videoid=5228642"
    },
    {
        episode: 26,
        link: "https://video.sibnet.ru/shell.php?videoid=5228676"
    },
    {
        episode: 27,
        link: "https://video.sibnet.ru/shell.php?videoid=5228686"
    },
    {
        episode: 28,
        link: "https://video.sibnet.ru/shell.php?videoid=5228694"
    },
    {
        episode: 29,
        link: "https://video.sibnet.ru/shell.php?videoid=5228697"
    },
    {
        episode: 30,
        link: "https://video.sibnet.ru/shell.php?videoid=5228701"
    },
    {
        episode: 31,
        link: "https://video.sibnet.ru/shell.php?videoid=5228712"
    },
    {
        episode: 32,
        link: "https://video.sibnet.ru/shell.php?videoid=5228727"
    },
    {
        episode: 33,
        link: "https://video.sibnet.ru/shell.php?videoid=5228778"
    },
    {
        episode: 34,
        link: "https://video.sibnet.ru/shell.php?videoid=5228786"
    },
    {
        episode: 35,
        link: "https://video.sibnet.ru/shell.php?videoid=5228788"
    },
    {
        episode: 36,
        link: "https://video.sibnet.ru/shell.php?videoid=5228790"
    },
    {
        episode: 37,
        link: "https://video.sibnet.ru/shell.php?videoid=5228791"
    },
    {
        episode: 38,
        link: "https://video.sibnet.ru/shell.php?videoid=5228793"
    },
    {
        episode: 39,
        link: "https://video.sibnet.ru/shell.php?videoid=5228794"
    }
    
]

export default listeEpisodes;