const listeChapitres = [
    {
        id : 0,
        titre : "Beyblade X",
        chapitre : "Chapitre 0",
        url : "chapitre-0",
        img : "https://i.ibb.co/wr51s9s/1.webp",
        vf : true,
        vflink : "/x/scans/chapitre-0"
    },
    {
        id : 1,
        titre : "Beyblade X",
        chapitre : "Chapitre 1",
        url : "chapitre-1",
        img : "https://i.ibb.co/fNk71Hp/Beyblade-X-p1.webp",
        vf : true,
        vflink : "/x/scans/chapitre-1"
    },
    {
        id : 2,
        titre : "Beyblade X",
        chapitre : "Chapitre 2",
        url : "chapitre-2",
        img : "https://i.ibb.co/ThGXTFS/p1.webp",
        vf : true,
        vflink : "/x/scans/chapitre-2"
    },
    {
        id : 3,
        titre : "Beyblade X",
        chapitre : "Chapitre 3",
        url : "chapitre-3",
        img : "https://i.ibb.co/Jz2j3My/1-1.webp",
        vf : true,
        vflink : "/x/scans/chapitre-3"
    },
    {
        id : 4,
        titre : "Beyblade X",
        chapitre : "Chapitre 4",
        url : "chapitre-4",
        img : "https://i.ibb.co/1b852np/cover-ch-4.webp",
        vf : true,
        vflink : "/x/scans/chapitre-4"
    },
    {
        id : 5,
        titre : "Beyblade X",
        chapitre : "Chapitre 5",
        url : "chapitre-5",
        img : "https://i.ibb.co/QMfQcr7/01.webp",
        vf : true,
        vflink : "/x/scans/chapitre-5"
    },
    {
        id : 6,
        titre : "Beyblade X",
        chapitre : "Chapitre 6",
        url : "chapitre-6",
        img : "https://i.ibb.co/tXGXj0y/001.webp",
        vf : true,
        vflink : "/x/scans/chapitre-6"
    },
    {
        id : 7,
        titre : "Beyblade X",
        chapitre : "Chapitre 7",
        url : "chapitre-7",
        img : "https://i.ibb.co/qppty9j/cover-ch-7-bx-1.webp",
        vf : true,
        vflink : "/x/scans/chapitre-7"
    },
    {
        id : 7.5,
        titre : "Beyblade X",
        chapitre : "Chapitre Bonus",
        url : "chapitre-7.5",
        img : "https://i.ibb.co/WkyrMx2/cover-bonus.webp",
        vf : true,
        vflink : "/x/scans/chapitre-7.5"
    },
    {
        id : 8,
        titre : "Beyblade X",
        chapitre : "Chapitre 8",
        url : "chapitre-8",
        img : "https://i.ibb.co/z2WtBr0/cover8-v2.webp",
        vf : true,
        vflink : "/x/scans/chapitre-8"
    },
    {
        id : 9,
        titre : "Beyblade X",
        chapitre : "Chapitre 9",
        url : "chapitre-9",
        img : "https://i.ibb.co/dGKBbN2/cover-chap-9.webp",
        vf : true,
        vflink : "/x/scans/chapitre-9"
    },
    {
        id : 10,
        titre : "Beyblade X",
        chapitre : "Chapitre 10",
        url : "chapitre-10",
        img : "https://i.ibb.co/x7PNyWM/cover-chap-10.webp",
        vf : true,
        vflink : "/x/scans/chapitre-10"
    },
    {
        id : 11,
        titre : "Beyblade X",
        chapitre : "Chapitre 11",
        url : "chapitre-11",
        img : "https://i.ibb.co/cy5ts7c/001.webp",
        vf : true,
        vflink : "/x/scans/chapitre-11"
    },
    {
        id : 12,
        titre : "Beyblade X",
        chapitre : "Chapitre 12",
        url : "chapitre-12",
        img : "https://i.ibb.co/tm4cCLg/cover-chap-12.webp",
        vf : true,
        vflink : "/x/scans/chapitre-12"
    },
    {
        id : 13,
        titre : "Beyblade X",
        chapitre : "Chapitre 13",
        url : "chapitre-13",
        img : "https://i.ibb.co/1KPSgdd/cover-chap-13.webp",
        vf : true,
        vflink : "/x/scans/chapitre-13"
    },
    {
        id : 13.5,
        titre : "Beyblade X",
        chapitre : "Chapitre Bonus",
        url : "chapitre-13.5",
        img : "https://i.ibb.co/rcpMhh9/cover-chap-13-5.webp",
        vf : true,
        vflink : "/x/scans/chapitre-13.5"
    },
    {
        id : 14,
        titre : "Beyblade X",
        chapitre : "Chapitre 14",
        url : "chapitre-14",
        img : "https://i.ibb.co/0mMLnFz/cover-chap-14.webp",
        vf : true,
        vflink : "/x/scans/chapitre-14"
    },  
    {
        id : 15,
        titre : "Beyblade X",
        chapitre : "Chapitre 15",
        url : "chapitre-15",
        img : "https://i.ibb.co/zbTtLy5/cover-chap-15.webp",
        vf : true,
        vflink : "/x/scans/chapitre-15"
    },    
]

export default listeChapitres