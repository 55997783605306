const listePages = [
      {
        page: 1,
        link: "https://i.ibb.co/Hggdjmn/0179.webp"
      },
      {
        page: 2,
        link: "https://i.ibb.co/m9tfZBY/0180.webp"
      },
      {
        page: 3,
        link: "https://i.ibb.co/bJDdQ0d/0181.webp"
      },
      {
        page: 4,
        link: "https://i.ibb.co/C7r9FFb/0182.webp"
      },
      {
        page: 5,
        link: "https://i.ibb.co/F6L3yV5/0183.webp"
      },
      {
        page: 6,
        link: "https://i.ibb.co/V2330d0/0184.webp"
      },
      {
        page: 7,
        link: "https://i.ibb.co/kDMYkGZ/0185.webp"
      },
      {
        page: 8,
        link: "https://i.ibb.co/PNzxBJZ/0186.webp"
      },
      {
        page: 9,
        link: "https://i.ibb.co/yQGTb4s/0187.webp"
      },
      {
        page: 10,
        link: "https://i.ibb.co/tDCD3bY/0188.webp"
      },
      {
        page: 11,
        link: "https://i.ibb.co/gVzW3nM/0189.webp"
      },
      {
        page: 12,
        link: "https://i.ibb.co/mtNRJK9/0190.webp"
      },
      {
        page: 13,
        link: "https://i.ibb.co/frfWjwC/0191.webp"
      },
      {
        page: 14,
        link: "https://i.ibb.co/ZJHkWQ6/0192.webp"
      },
      {
        page: 15,
        link: "https://i.ibb.co/YBT5jMG/0193.webp"
      },
      {
        page: 16,
        link: "https://i.ibb.co/5LF3SFY/0194.webp"
      },
      {
        page: 17,
        link: "https://i.ibb.co/TrLWxPx/0195.webp"
      },
      {
        page: 18,
        link: "https://i.ibb.co/Q81K6Yp/0196.webp"
      },
      {
        page: 19,
        link: "https://i.ibb.co/hfmBqph/0197.webp"
      },
      {
        page: 20,
        link: "https://i.ibb.co/YPQK4RK/0198.webp"
      }
]


export default listePages;