const listePages = [
    { page: 1, link: "https://i.ibb.co/fMJRpfG/0058-001.webp" },
    { page: 2, link: "https://i.ibb.co/gP4Ckv8/0058-002.webp" },
    { page: 3, link: "https://i.ibb.co/4V32gJr/0058-003.webp" },
    { page: 4, link: "https://i.ibb.co/rbpdKG5/0058-004.webp" },
    { page: 5, link: "https://i.ibb.co/fC57xXP/0058-005.webp" },
    { page: 6, link: "https://i.ibb.co/DrYvXX3/0058-006.webp" },
    { page: 7, link: "https://i.ibb.co/pLGVQhW/0058-007.webp" },
    { page: 8, link: "https://i.ibb.co/9cyv6Gp/0058-008.webp" },
    { page: 9, link: "https://i.ibb.co/KKd27q2/0058-009.webp" },
    { page: 10, link: "https://i.ibb.co/Ltg26Bm/0058-010.webp" },
    { page: 11, link: "https://i.ibb.co/qR90GHx/0058-011.webp" },
    { page: 12, link: "https://i.ibb.co/wW8DvrK/0058-012.webp" },
    { page: 13, link: "https://i.ibb.co/gwSvFcX/0058-013.webp" },
    { page: 14, link: "https://i.ibb.co/93xxQsr/0058-014.webp" },
    { page: 15, link: "https://i.ibb.co/Cw8FBc4/0058-015.webp" },
    { page: 16, link: "https://i.ibb.co/vjQR4GC/0058-016.webp" },
    { page: 17, link: "https://i.ibb.co/2nYkLSw/0058-017.webp" },
    { page: 18, link: "https://i.ibb.co/yPGkdhQ/0058-018.webp" },
    { page: 19, link: "https://i.ibb.co/Vq9zj4T/0058-019.webp" },
    { page: 20, link: "https://i.ibb.co/mSX76sf/0058-020.webp" },
    { page: 21, link: "https://i.ibb.co/Tcb5hbB/0058-021.webp" },
    { page: 22, link: "https://i.ibb.co/dDbG1YJ/0058-022.webp" },
    { page: 23, link: "https://i.ibb.co/Thfhs0m/0058-023.webp" },
    { page: 24, link: "https://i.ibb.co/j3V2PzT/0058-024.webp" },
    { page: 25, link: "https://i.ibb.co/vkmdfgp/0058-025.webp" },
    { page: 26, link: "https://i.ibb.co/CWm9bX4/0058-026.webp" },
    { page: 27, link: "https://i.ibb.co/K7fntGw/0058-027.webp" },
    { page: 28, link: "https://i.ibb.co/8Yy9qWx/0058-028.webp" },
    { page: 29, link: "https://i.ibb.co/1rHbBhD/0058-029.webp" },
    { page: 30, link: "https://i.ibb.co/D4F2f2C/0058-030.webp" },
    { page: 31, link: "https://i.ibb.co/Gdvx0b1/0058-031.webp" },
    { page: 32, link: "https://i.ibb.co/M7GnZLV/0058-032.webp" }
  ]
  

export default listePages;