const listePages = [
    {
        "page": 1,
        "link": "https://i.ibb.co/TK62ry5/0074.webp"
      },
      {
        "page": 2,
        "link": "https://i.ibb.co/Sf0xm0c/0075.webp"
      },
      {
        "page": 3,
        "link": "https://i.ibb.co/BTRvZ6Y/0076.webp"
      },
      {
        "page": 4,
        "link": "https://i.ibb.co/7yx45MP/0077.webp"
      },
      {
        "page": 5,
        "link": "https://i.ibb.co/mvymzwg/0078.webp"
      },
      {
        "page": 6,
        "link": "https://i.ibb.co/kq87pJh/0079.webp"
      },
      {
        "page": 7,
        "link": "https://i.ibb.co/HF48krk/0080.webp"
      },
      {
        "page": 8,
        "link": "https://i.ibb.co/4PKpc2t/0081.webp"
      },
      {
        "page": 9,
        "link": "https://i.ibb.co/6whmJF6/0082.webp"
      },
      {
        "page": 10,
        "link": "https://i.ibb.co/31sdJcB/0083.webp"
      },
      {
        "page": 11,
        "link": "https://i.ibb.co/8NHzvMK/0084.webp"
      },
      {
        "page": 12,
        "link": "https://i.ibb.co/HgtX1Br/0085.webp"
      },
      {
        "page": 13,
        "link": "https://i.ibb.co/z7swtrY/0086.webp"
      },
      {
        "page": 14,
        "link": "https://i.ibb.co/CnSWGzN/0087.webp"
      },
      {
        "page": 15,
        "link": "https://i.ibb.co/mTDNY0h/0088.webp"
      },
      {
        "page": 16,
        "link": "https://i.ibb.co/QbRZZ3q/0089.webp"
      },
      {
        "page": 17,
        "link": "https://i.ibb.co/Dfwg2VW/0090.webp"
      },
      {
        "page": 18,
        "link": "https://i.ibb.co/LnFc3H4/0091.webp"
      },
      {
        "page": 19,
        "link": "https://i.ibb.co/myjkqJm/0092.webp"
      },
      {
        "page": 20,
        "link": "https://i.ibb.co/h9vqHZ7/0093.webp"
      },
      {
        "page": 21,
        "link": "https://i.ibb.co/cQPVqJ3/0094.webp"
      },
      {
        "page": 22,
        "link": "https://i.ibb.co/gtqp6tX/0095.webp"
      },
      {
        "page": 23,
        "link": "https://i.ibb.co/qk30TXm/0096.webp"
      },
      {
        "page": 24,
        "link": "https://i.ibb.co/7XsCk94/0097.webp"
      },
      {
        "page": 25,
        "link": "https://i.ibb.co/VgrnDbt/0098.webp"
      },
      {
        "page": 26,
        "link": "https://i.ibb.co/xh9kR1p/0099.webp"
      },
      {
        "page": 27,
        "link": "https://i.ibb.co/sbBBDCR/0100.webp"
      },
      {
        "page": 28,
        "link": "https://i.ibb.co/S5zBt9w/0101.webp"
      },
      {
        "page": 29,
        "link": "https://i.ibb.co/MsbV0TY/0102.webp"
      },
      {
        "page": 30,
        "link": "https://i.ibb.co/pf0Csps/0103.webp"
      },
      {
        "page": 31,
        "link": "https://i.ibb.co/fd5B7kf/0104.webp"
      },
      {
        "page": 32,
        "link": "https://i.ibb.co/P6QQJfq/0105.webp"
      },
      {
        "page": 33,
        "link": "https://i.ibb.co/9sGtb27/0106.webp"
      },
      {
        "page": 34,
        "link": "https://i.ibb.co/wydh3dD/0107.webp"
      },
      {
        "page": 35,
        "link": "https://i.ibb.co/0q6nxZJ/0108.webp"
      },
      {
        "page": 36,
        "link": "https://i.ibb.co/C0MNGVH/0109.webp"
      },
      {
        "page": 37,
        "link": "https://i.ibb.co/FbQYwf1/0110.webp"
      },
      {
        "page": 38,
        "link": "https://i.ibb.co/Js8qNGm/0111.webp"
      },
      {
        "page": 39,
        "link": "https://i.ibb.co/tQMmwmb/0112.webp"
      },
      {
        "page": 40,
        "link": "https://i.ibb.co/0nx7B3r/0113.webp"
      }
]


export default listePages;