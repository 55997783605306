const listePages = [
    {
        "page": 1,
        "link": "https://i.ibb.co/Y2QhhPm/0001.webp"
      },
      {
        "page": 2,
        "link": "https://i.ibb.co/bWZjczH/0003.webp"
      },
      {
        "page": 3,
        "link": "https://i.ibb.co/WGYbCqW/0004.webp"
      },
      {
        "page": 4,
        "link": "https://i.ibb.co/0yT4FF5/0005.webp"
      },
      {
        "page": 5,
        "link": "https://i.ibb.co/8zDdNvG/0006.webp"
      },
      {
        "page": 6,
        "link": "https://i.ibb.co/1LHMhw6/0007.webp"
      },
      {
        "page": 7,
        "link": "https://i.ibb.co/VYSmHzg/0008.webp"
      },
      {
        "page": 8,
        "link": "https://i.ibb.co/s54bsb5/0009.webp"
      },
      {
        "page": 9,
        "link": "https://i.ibb.co/CKj8zRM/0010.webp"
      },
      {
        "page": 10,
        "link": "https://i.ibb.co/BG64P73/0011.webp"
      },
      {
        "page": 11,
        "link": "https://i.ibb.co/HLSLV3t/0012.webp"
      },
      {
        "page": 12,
        "link": "https://i.ibb.co/d2zF82p/0013.webp"
      },
      {
        "page": 13,
        "link": "https://i.ibb.co/yy9dC47/0014.webp"
      },
      {
        "page": 14,
        "link": "https://i.ibb.co/SBNWm2X/0015.webp"
      },
      {
        "page": 15,
        "link": "https://i.ibb.co/ZLCh1Zj/0016.webp"
      },
      {
        "page": 16,
        "link": "https://i.ibb.co/9TS2c3T/0017.webp"
      },
      {
        "page": 17,
        "link": "https://i.ibb.co/tDR2zx0/0018.webp"
      },
      {
        "page": 18,
        "link": "https://i.ibb.co/DLf1hr3/0019.webp"
      },
      {
        "page": 19,
        "link": "https://i.ibb.co/xmCHJ18/0020.webp"
      },
      {
        "page": 20,
        "link": "https://i.ibb.co/mCsWcVV/0021.webp"
      },
      {
        "page": 21,
        "link": "https://i.ibb.co/sJ6VKJt/0022.webp"
      },
      {
        "page": 22,
        "link": "https://i.ibb.co/WzcBTXG/0023.webp"
      },
      {
        "page": 23,
        "link": "https://i.ibb.co/5W9qKbZ/0024.webp"
      },
      {
        "page": 24,
        "link": "https://i.ibb.co/hMjNb5Z/0025.webp"
      },
      {
        "page": 25,
        "link": "https://i.ibb.co/T8m4WBw/0026.webp"
      },
      {
        "page": 26,
        "link": "https://i.ibb.co/L9MmFdZ/0027.webp"
      },
      {
        "page": 27,
        "link": "https://i.ibb.co/FK1Yp0g/0028.webp"
      },
      {
        "page": 28,
        "link": "https://i.ibb.co/3S00VGQ/0029.webp"
      },
      {
        "page": 29,
        "link": "https://i.ibb.co/sg03QH0/0030.webp"
      },
      {
        "page": 30,
        "link": "https://i.ibb.co/XyNXJ6S/0031.webp"
      },
      {
        "page": 31,
        "link": "https://i.ibb.co/xzvSc46/0032.webp"
      },
      {
        "page": 32,
        "link": "https://i.ibb.co/X3b0CqM/0033.webp"
      },
      {
        "page": 33,
        "link": "https://i.ibb.co/HTcRLKC/0034.webp"
      },
      {
        "page": 34,
        "link": "https://i.ibb.co/1RhSnnw/0035.webp"
      },
      {
        "page": 35,
        "link": "https://i.ibb.co/KhqXhvV/0036.webp"
      },
      {
        "page": 36,
        "link": "https://i.ibb.co/6JjTpbX/0037.webp"
      },
      {
        "page": 37,
        "link": "https://i.ibb.co/7tKrW8p/0038.webp"
      },
      {
        "page": 38,
        "link": "https://i.ibb.co/PxW2hy4/0039.webp"
      },
      {
        "page": 39,
        "link": "https://i.ibb.co/rd8brmD/0040.webp"
      },
      {
        "page": 40,
        "link": "https://i.ibb.co/d0dgfkf/0041.webp"
      },
      {
        "page": 41,
        "link": "https://i.ibb.co/983f7SP/0042.webp"
      },
      {
        "page": 42,
        "link": "https://i.ibb.co/4dQZPzP/0043.webp"
      },
      {
        "page": 43,
        "link": "https://i.ibb.co/LSWzKP7/0044.webp"
      },
      {
        "page": 44,
        "link": "https://i.ibb.co/xLXHHWb/0045.webp"
      },
      {
        "page": 45,
        "link": "https://i.ibb.co/xfcM6tM/0046.webp"
      },
      {
        "page": 46,
        "link": "https://i.ibb.co/vkmqjqx/0047.webp"
      },
      {
        "page": 47,
        "link": "https://i.ibb.co/hDFNxSV/0048.webp"
      }
]


export default listePages;