const listePages = [
    {
        "page": 1,
        "link": "https://i.ibb.co/z8tJYsh/0170.webp"
      },
      {
        "page": 2,
        "link": "https://i.ibb.co/3d2mmwB/0171.webp"
      },
      {
        "page": 3,
        "link": "https://i.ibb.co/ckTwcYq/0172.webp"
      },
      {
        "page": 4,
        "link": "https://i.ibb.co/brPCFcF/0173.webp"
      },
      {
        "page": 5,
        "link": "https://i.ibb.co/WfzC2Xy/0174.webp"
      },
      {
        "page": 6,
        "link": "https://i.ibb.co/rcY8dbf/0175.webp"
      },
      {
        "page": 7,
        "link": "https://i.ibb.co/gPP8JPx/0176.webp"
      },
      {
        "page": 8,
        "link": "https://i.ibb.co/TbjWCkJ/0177.webp"
      },
      {
        "page": 9,
        "link": "https://i.ibb.co/wJvY2Gv/0178.webp"
      },
      {
        "page": 10,
        "link": "https://i.ibb.co/KmgWBYw/0179.webp"
      },
      {
        "page": 11,
        "link": "https://i.ibb.co/jb5m1wt/0180.webp"
      },
      {
        "page": 12,
        "link": "https://i.ibb.co/Vmm1Gy5/0181.webp"
      },
      {
        "page": 13,
        "link": "https://i.ibb.co/zsXNYMB/0182.webp"
      },
      {
        "page": 14,
        "link": "https://i.ibb.co/vYpwPxJ/0183.webp"
      },
      {
        "page": 15,
        "link": "https://i.ibb.co/vqsry43/0184.webp"
      },
      {
        "page": 16,
        "link": "https://i.ibb.co/dK3sz7p/0185.webp"
      },
      {
        "page": 17,
        "link": "https://i.ibb.co/SBrtQQk/0186.webp"
      }
]


export default listePages;