const listePages = [
    {
      page: 1,
      link: "https://i.ibb.co/th5cvwF/0100-001.webp"
    },
    {
      page: 2,
      link: "https://i.ibb.co/cwfp8WC/0100-002.webp"
    },
    {
      page: 3,
      link: "https://i.ibb.co/gt6ScQz/0100-003.webp"
    },
    {
      page: 4,
      link: "https://i.ibb.co/dQ5pQ4H/0100-004.webp"
    },
    {
      page: 5,
      link: "https://i.ibb.co/5BhfngW/0100-005.webp"
    },
    {
      page: 6,
      link: "https://i.ibb.co/6FjhsmJ/0100-006.webp"
    },
    {
      page: 7,
      link: "https://i.ibb.co/HNZtRjr/0100-007.webp"
    },
    {
      page: 8,
      link: "https://i.ibb.co/tb3dfm1/0100-008.webp"
    },
    {
      page: 9,
      link: "https://i.ibb.co/V3N6g1k/0100-009.webp"
    },
    {
      page: 10,
      link: "https://i.ibb.co/VxWY4Y1/0100-010.webp"
    },
    {
      page: 11,
      link: "https://i.ibb.co/ScB8hqs/0100-011.webp"
    },
    {
      page: 12,
      link: "https://i.ibb.co/QmtzXM7/0100-012.webp"
    },
    {
      page: 13,
      link: "https://i.ibb.co/X36DKZR/0100-013.webp"
    },
    {
      page: 14,
      link: "https://i.ibb.co/KWry61N/0100-014.webp"
    },
    {
      page: 15,
      link: "https://i.ibb.co/0s4RxsM/0100-015.webp"
    },
    {
      page: 16,
      link: "https://i.ibb.co/DG3LS62/0100-016.webp"
    },
    {
      page: 17,
      link: "https://i.ibb.co/WDHkT0K/0100-017.webp"
    },
    {
      page: 18,
      link: "https://i.ibb.co/8MqGPz6/0100-018.webp"
    },
    {
      page: 19,
      link: "https://i.ibb.co/4K32nPw/0100-019.webp"
    },
    {
      page: 20,
      link: "https://i.ibb.co/s1XWdkH/0100-020.webp"
    },
    {
      page: 21,
      link: "https://i.ibb.co/ypWK7bn/0100-021.webp"
    },
    {
      page: 22,
      link: "https://i.ibb.co/k8jqJtk/0100-022.webp"
    },
    {
      page: 23,
      link: "https://i.ibb.co/23wy3ct/0100-023.webp"
    },
    {
      page: 24,
      link: "https://i.ibb.co/Mn24LHV/0100-024.webp"
    },
    {
      page: 25,
      link: "https://i.ibb.co/nD4gVZZ/0100-025.webp"
    },
    {
      page: 26,
      link: "https://i.ibb.co/6ybtfpx/0100-026.webp"
    },
    {
      page: 27,
      link: "https://i.ibb.co/WVyp39x/0100-027.webp"
    },
    {
      page: 28,
      link: "https://i.ibb.co/MZ9fNLC/0100-028.webp"
    },
    {
      page: 29,
      link: "https://i.ibb.co/5MNbJCY/0100-029.webp"
    },
    {
      page: 30,
      link: "https://i.ibb.co/VD6Pb78/0100-030.webp"
    },
    {
      page: 31,
      link: "https://i.ibb.co/7GC7MjX/0100-031.webp"
    },
    {
      page: 32,
      link: "https://i.ibb.co/2cR2qWB/0100-032.webp"
    },
    {
      page: 33,
      link: "https://i.ibb.co/vzWCN3M/0100-033.webp"
    },
    {
      page: 34,
      link: "https://i.ibb.co/SczDySb/0100-034.webp"
    },
    {
      page: 35,
      link: "https://i.ibb.co/mhz8187/0100-035.webp"
    },
    {
      page: 36,
      link: "https://i.ibb.co/kMWhqKx/0100-036.webp"
    },
    {
      page: 37,
      link: "https://i.ibb.co/zZ450ZZ/0100-037.webp"
    }
  ]

export default listePages;