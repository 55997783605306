const listeSaisons = [
    {
        id : 1,
        titre : "Metal Fight Beyblade",
        saison : "Saison 1",
        url : "saison1",
        img : "https://i.ibb.co/THdHrp7/12-quvnwfn.webp",
        synopsis : "Ginga Hagane, un jeune Blader venant de Koma, voyage à travers le Japon à la recherche de puissants adversaires. Alors qu'il est de passage dans une ville, il fait la rencontre de Kenta Yumiya et de Madoka Amano.",
        vostfr : true,
        vostfrlink : "/metal/saison1/vostfr",
        vf : true,
        vflink : "/metal/saison1/vf"
    },
    {
        id : 2,
        titre : "Metal Fight Beyblade Baku",
        saison : "Saison 2",
        url : "saison2",
        img : "https://i.ibb.co/rk5HMwm/13-aneaxh0.webp",
        synopsis : "Après son dernier combat contre Ryuga, Ginga perd Storm Pegasus. Son père lui parle de l'existence d'une autre Bey, le prototype de son défunt compagnon : Galaxy Pegasus. Ginga réussira-t-il à la récupérer et la maîtriser ?",
        vostfr : true,
        vostfrlink : "/metal/saison2/vostfr",
        vf : true,
        vflink : "/metal/saison2/vf"
    },
    {
        id : 3,
        titre : "Metal Fight Beyblade 4D",
        saison : "Saison 3",
        url : "saison3",
        img : "https://i.ibb.co/KycT9RY/Any-Conv-com-14-n-Reo-JSj.webp",
        synopsis : "Une météorite s'approche de la Terre et se divise en plusieurs fragments qui s'incrustent dans plusieurs Beys du monde. Pegasus, qui vient d'être réparée, est touchée par ce fragment d'étoile.",
        vostfr : true,
        vostfrlink : "/metal/saison3/vostfr",
        vf : true,
        vflink : "/metal/saison3/vf"
    },
    {
        id : 4,
        titre : "Metal Fight Beyblade ZeroG",
        saison : "Saison 4",
        url : "saison4",
        img : "https://i.ibb.co/kyBBMKr/Any-Conv-com-15-Y6z-Gii-W.webp",
        synopsis : "Cette histoire se déroule 7 ans après la précédente, un nouveau Blader, Zyro Kurogane, aspire à devenir le meilleur du pays. Il part en voyage à la recherche de nouveaux adversaires.",
        vostfr : true,
        vostfrlink : "/metal/saison4/vostfr",
        vf : true,
        vflink : "/metal/saison4/vf"
    },
    {
        id : 5,
        titre : "Metal Fight Beyblade Sol Blaze",
        saison : "Film",
        url : "film",
        img : "https://i.ibb.co/B3b20jS/2-ACz-L2h-S.webp",
        synopsis : "On retrouve nos héros en vacances à la mer. Les garçons ne comptent pas profiter de la plage mais plutôt de leurs Beys dans un tournoi qui se déroule pas loin. Tout se passe bien jusqu'à ce qu'arrive un inconnu avec sa Bey Sol Blaze.",
        vostfr : true,
        vostfrlink : "/metal/film/vostfr",
        vf : true,
        vflink : "/metal/film/vf"
    },
    {
        id : 6,
        titre : "Metal Fight Beyblade",
        saison : "Scans 1",
        url : "scans",
        img : "https://i.ibb.co/hVWgr1Q/0001.webp",
        synopsis : "Ginga Hagane, un jeune Blader venant de Koma, voyage à travers le Japon à la recherche de puissants adversaires. Alors qu'il est de passage dans une ville, il fait la rencontre de Kenta Yumiya et de Madoka Amano.",
        vf : true,
        vflink : "/metal/scans/",
        scan : true
    },
    {
        id : 7,
        titre : "Metal Fight Beyblade ZeroG",
        saison : "Scans 2",
        url : "scans",
        img : "https://i.ibb.co/YPtMbtP/001.webp",
        synopsis : "Cette histoire se déroule 7 ans après la précédente, un nouveau Blader, Zyro Kurogane, aspire à devenir le meilleur du pays. Il part en voyage à la recherche de nouveaux adversaires.",
        vf : true,
        vflink : "/metal/scans/",
        scan : true
    }
]

export default listeSaisons