const listePages = [
    { page: 1, link: "https://i.ibb.co/GWxb4sv/0070-001.webp" },
    { page: 2, link: "https://i.ibb.co/9sJZFGp/0070-002.webp" },
    { page: 3, link: "https://i.ibb.co/0r1bs21/0070-003.webp" },
    { page: 4, link: "https://i.ibb.co/RbXwZPf/0070-004.webp" },
    { page: 5, link: "https://i.ibb.co/gzhfw8y/0070-005.webp" },
    { page: 6, link: "https://i.ibb.co/ZStY7v5/0070-006.webp" },
    { page: 7, link: "https://i.ibb.co/7pmhW5q/0070-007.webp" },
    { page: 8, link: "https://i.ibb.co/1zL02Gy/0070-008.webp" },
    { page: 9, link: "https://i.ibb.co/DR2dZ1H/0070-009.webp" },
    { page: 10, link: "https://i.ibb.co/m4v71r4/0070-010.webp" },
    { page: 11, link: "https://i.ibb.co/qp96XZH/0070-011.webp" },
    { page: 12, link: "https://i.ibb.co/vcwpStY/0070-012.webp" },
    { page: 13, link: "https://i.ibb.co/J3S6zzX/0070-013.webp" },
    { page: 14, link: "https://i.ibb.co/M7gK8LD/0070-014.webp" },
    { page: 15, link: "https://i.ibb.co/yVxvHjF/0070-015.webp" },
    { page: 16, link: "https://i.ibb.co/TRTgRNz/0070-016.webp" },
    { page: 17, link: "https://i.ibb.co/NK5CxQ0/0070-017.webp" },
    { page: 18, link: "https://i.ibb.co/nnQ46CN/0070-018.webp" },
    { page: 19, link: "https://i.ibb.co/Gtq6pgX/0070-019.webp" },
    { page: 20, link: "https://i.ibb.co/cNkrYkP/0070-020.webp" }
  ]
  

export default listePages;