const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/sF7vYHs/28-5-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/gDZ5Rvg/28-5-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/4jyMKgL/28-5-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/XXGSy1L/28-5-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/bPnTf6s/28-5-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/GktgzLj/28-5-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/djP6h0c/28-5-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/vvTq1BX/28-5-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/q5q0RJp/28-5-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/VMw09Ld/28-5-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/wcB640V/28-5-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/sbYnKvy/28-5-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/GQVNR8g/28-5-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/w7TNY9z/28-5-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/Z19m8dG/28-5-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/CWmC6Bf/28-5-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/qjVsqBG/28-5-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/31LxqKw/28-5-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/rpmzKgX/28-5-019.webp"
  },
  {
    page: 20,
    link: "https://i.ibb.co/vX5D1WW/28-5-020.webp"
  },
  {
    page: 21,
    link: "https://i.ibb.co/4T3Yhfv/28-5-021.webp"
  },
  {
    page: 22,
    link: "https://i.ibb.co/tJdznNN/28-5-022.webp"
  },
  {
    page: 23,
    link: "https://i.ibb.co/dgY89f8/28-5-023.webp"
  },
  {
    page: 24,
    link: "https://i.ibb.co/R0wL6xp/28-5-024.webp"
  }  
]

export default listePages;