const listePages = [
  { page: 1, link: "https://i.ibb.co/PcWD9zv/59-6-001.webp" },
  { page: 2, link: "https://i.ibb.co/jWzcDKG/59-6-002.webp" },
  { page: 3, link: "https://i.ibb.co/K5Hvcd3/59-6-003.webp" },
  { page: 4, link: "https://i.ibb.co/NWW47vS/59-6-004.webp" },
  { page: 5, link: "https://i.ibb.co/rwB5S6F/59-6-005.webp" },
  { page: 6, link: "https://i.ibb.co/QNY5gGV/59-6-006.webp" },
  { page: 7, link: "https://i.ibb.co/xfkp1Lz/59-6-007.webp" },
  { page: 8, link: "https://i.ibb.co/D5w4hj9/59-6-008.webp" },
  { page: 9, link: "https://i.ibb.co/c6gkHFy/59-6-009.webp" },
  { page: 10, link: "https://i.ibb.co/sWJX8vs/59-6-010.webp" },
  { page: 11, link: "https://i.ibb.co/c3MRXtj/59-6-011.webp" },
  { page: 12, link: "https://i.ibb.co/YprMhzk/59-6-012.webp" }
]

export default listePages;