const listePages = [
  { page: 1, link: 'https://i.ibb.co/FxQjHcX/000.webp' },
  { page: 2, link: 'https://i.ibb.co/fGrd4zM/001.webp' },
  { page: 3, link: 'https://i.ibb.co/dKLpVkG/002.webp' },
  { page: 4, link: 'https://i.ibb.co/zFKDSxF/003.webp' },
  { page: 5, link: 'https://i.ibb.co/s9RrhMW/004.webp' },
  { page: 6, link: 'https://i.ibb.co/2Zq0v4j/005.webp' },
  { page: 7, link: 'https://i.ibb.co/XZxKrWF/006.webp' },
  { page: 8, link: 'https://i.ibb.co/WGF6fng/007.webp' },
  { page: 9, link: 'https://i.ibb.co/4T5gWXz/008.webp' },
  { page: 10, link: 'https://i.ibb.co/LhGkgjm/009.webp' },
  { page: 11, link: 'https://i.ibb.co/5WqgKNW/010-011.webp' },
  { page: 12, link: 'https://i.ibb.co/zsMv7Tf/012.webp' },
  { page: 13, link: 'https://i.ibb.co/7VwF68T/013.webp' },
  { page: 14, link: 'https://i.ibb.co/DMDBLmh/014.webp' },
  { page: 15, link: 'https://i.ibb.co/NV6RFvm/015.webp' },
  { page: 16, link: 'https://i.ibb.co/mqgNWN1/016.webp' },
  { page: 17, link: 'https://i.ibb.co/g6VfGxC/017.webp' },
  { page: 18, link: 'https://i.ibb.co/xmXKYXT/018.webp' },
  { page: 19, link: 'https://i.ibb.co/yqHBn8W/019.webp' },
  { page: 20, link: 'https://i.ibb.co/txM9dCH/020.webp' },
  { page: 21, link: 'https://i.ibb.co/jy7Fh7h/021.webp' },
  { page: 22, link: 'https://i.ibb.co/PtMHyCt/022-023.webp' },
  { page: 23, link: 'https://i.ibb.co/RhBm5pd/024.webp' },
  { page: 24, link: 'https://i.ibb.co/kgXrSFN/025.webp' },
  { page: 25, link: 'https://i.ibb.co/TkgWYyp/026.webp' },
  { page: 26, link: 'https://i.ibb.co/8NDGRLN/027.webp' },
  { page: 27, link: 'https://i.ibb.co/Gc0nNxH/028.webp' },
  { page: 28, link: 'https://i.ibb.co/HtsG5Nq/029.webp' },
  { page: 29, link: 'https://i.ibb.co/zRq5DFP/030.webp' },
  { page: 30, link: 'https://i.ibb.co/RQZZkLN/031.webp' },
  { page: 31, link: 'https://i.ibb.co/f8P4J1s/032.webp' },
  { page: 32, link: 'https://i.ibb.co/YknPmH1/033.webp' },
  { page: 33, link: 'https://i.ibb.co/h1SgfgY/034-v2.webp' },
  { page: 34, link: 'https://i.ibb.co/jvS0Zgg/035.webp' },
  { page: 35, link: 'https://i.ibb.co/yyMVyhh/036.webp' },
  { page: 36, link: 'https://i.ibb.co/SwQDWx9/037.webp' },
  { page: 37, link: 'https://i.ibb.co/ZMJfZSy/038.webp' },
  { page: 38, link: 'https://i.ibb.co/t3zx9mM/039.webp' },
  { page: 39, link: 'https://i.ibb.co/Cn8bfmN/040.webp' },
  { page: 40, link: 'https://i.ibb.co/5sJybzs/041.webp' },
  { page: 41, link: 'https://i.ibb.co/3yvBGgK/042.webp' },
  { page: 42, link: 'https://i.ibb.co/5WRxFYb/043.webp' },
  { page: 43, link: 'https://i.ibb.co/WWy8n7C/044.webp' },
  { page: 44, link: 'https://i.ibb.co/HF6XZzd/045.webp' },
  { page: 45, link: 'https://i.ibb.co/zFxpm12/046.webp' },
  { page: 46, link: 'https://i.ibb.co/JvDZ1bH/047.webp' },
  { page: 47, link: 'https://i.ibb.co/HP78N3j/048.webp' }
]

export default listePages;