const listeChapitres = [
    {
        id : 0,
        titre : "Metal Fight Beyblade",
        chapitre : "Metal Fight Beyblade Soul Burst",
        url : "soulburst",
        img : "https://i.ibb.co/XJRVBB1/cover.webp",
        vf : true,
        vflink : "/spinoff/scans/soulburst"
    }
]

export default listeChapitres