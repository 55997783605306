const listePages = [
  {
    "page": 1,
    "link": "https://i.ibb.co/0h3PVhG/0094-001.webp"
  },
  {
    "page": 2,
    "link": "https://i.ibb.co/DtpW8d7/0094-002.webp"
  },
  {
    "page": 3,
    "link": "https://i.ibb.co/MC8T13q/0094-003.webp"
  },
  {
    "page": 4,
    "link": "https://i.ibb.co/xhLXL8y/0094-004.webp"
  },
  {
    "page": 5,
    "link": "https://i.ibb.co/Y3VM2nf/0094-005.webp"
  },
  {
    "page": 6,
    "link": "https://i.ibb.co/vPZT0Qq/0094-006.webp"
  },
  {
    "page": 7,
    "link": "https://i.ibb.co/pZbP02t/0094-007.webp"
  },
  {
    "page": 8,
    "link": "https://i.ibb.co/dDtXHxh/0094-008.webp"
  },
  {
    "page": 9,
    "link": "https://i.ibb.co/3WLPSWS/0094-009.webp"
  },
  {
    "page": 10,
    "link": "https://i.ibb.co/TkCVL5B/0094-010.webp"
  },
  {
    "page": 11,
    "link": "https://i.ibb.co/RSxDB8Q/0094-011.webp"
  },
  {
    "page": 12,
    "link": "https://i.ibb.co/34tX8Wj/0094-012.webp"
  },
  {
    "page": 13,
    "link": "https://i.ibb.co/zZPTZmq/0094-013.webp"
  },
  {
    "page": 14,
    "link": "https://i.ibb.co/xfGHpDP/0094-014.webp"
  },
  {
    "page": 15,
    "link": "https://i.ibb.co/NxXbh1Q/0094-015.webp"
  },
  {
    "page": 16,
    "link": "https://i.ibb.co/0mnLmTw/0094-016.webp"
  }
]


export default listePages;