const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/dr8NH9c/0050-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/PWgz7x3/0050-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/PNFn6tT/0050-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/vYf0KPd/0050-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/VVgjv1T/0050-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/h7tfVK7/0050-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/gtSvbvS/0050-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/FKNFXyq/0050-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/tPktM3b/0050-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/HPW4L1b/0050-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/cYrzxz9/0050-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/yyr8S7x/0050-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/TvJdbhz/0050-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/YDrRcmp/0050-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/KmRzJ4v/0050-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/71XMK8q/0050-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/MG9XTFk/0050-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/rsh04w9/0050-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/TbkMvv9/0050-019.webp"
  },
  {
    page: 20,
    link: "https://i.ibb.co/CWWBqC7/0050-020.webp"
  },
  {
    page: 21,
    link: "https://i.ibb.co/0V66gVX/0050-021.webp"
  },
  {
    page: 22,
    link: "https://i.ibb.co/GVnKF3N/0050-022.webp"
  },
  {
    page: 23,
    link: "https://i.ibb.co/5chjpvh/0050-023.webp"
  },
  {
    page: 24,
    link: "https://i.ibb.co/JqCXtKw/0050-024.webp"
  },
  {
    page: 25,
    link: "https://i.ibb.co/5nJs2zD/0050-025.webp"
  },
  {
    page: 26,
    link: "https://i.ibb.co/Y8s1KkH/0050-026.webp"
  },
  {
    page: 27,
    link: "https://i.ibb.co/23CBLgx/0050-027.webp"
  },
  {
    page: 28,
    link: "https://i.ibb.co/3hwgxHz/0050-028.webp"
  },
  {
    page: 29,
    link: "https://i.ibb.co/028BLFW/0050-029.webp"
  },
  {
    page: 30,
    link: "https://i.ibb.co/ky6fWCh/0050-030.webp"
  },
  {
    page: 31,
    link: "https://i.ibb.co/74mdF8y/0050-031.webp"
  },
  {
    page: 32,
    link: "https://i.ibb.co/F8YJpJW/0050-032.webp"
  },
  {
    page: 33,
    link: "https://i.ibb.co/0c24MZz/0050-033.webp"
  }
];


export default listePages;