const listePages = [
	{ page: 1, link: 'https://i.ibb.co/PZFbsd2/001.webp' },
	{ page: 2, link: 'https://i.ibb.co/XzjJ2Gv/002.webp' },
	{ page: 3, link: 'https://i.ibb.co/HHsYyV3/003.webp' },
	{ page: 4, link: 'https://i.ibb.co/BGKZRXt/004.webp' },
	{ page: 5, link: 'https://i.ibb.co/bWDkfDS/005.webp' },
	{ page: 6, link: 'https://i.ibb.co/7tgvfq0/006.webp' },
	{ page: 7, link: 'https://i.ibb.co/gt4g9rN/007.webp' },
	{ page: 8, link: 'https://i.ibb.co/31dqfSQ/008.webp' },
	{ page: 9, link: 'https://i.ibb.co/BL8LrJC/009.webp' },
	{ page: 10, link: 'https://i.ibb.co/LNXmWvM/010.webp' },
	{ page: 11, link: 'https://i.ibb.co/xFk5x8J/011.webp' },
	{ page: 12, link: 'https://i.ibb.co/ZxFQqPn/012.webp' },
	{ page: 13, link: 'https://i.ibb.co/yVKfwSX/013.webp' },
	{ page: 14, link: 'https://i.ibb.co/vVHHtrC/014.webp' },
	{ page: 15, link: 'https://i.ibb.co/BKxQ8rt/015.webp' },
	{ page: 16, link: 'https://i.ibb.co/n1Nxvp4/016.webp' },
	{ page: 17, link: 'https://i.ibb.co/wrZ2Zgw/017.webp' },
	{ page: 18, link: 'https://i.ibb.co/17tH4S8/018.webp' },
	{ page: 19, link: 'https://i.ibb.co/WczTwVN/019.webp' },
	{ page: 20, link: 'https://i.ibb.co/ftm8bsL/020.webp' },
	{ page: 21, link: 'https://i.ibb.co/h8K7cMj/021.webp' },
	{ page: 22, link: 'https://i.ibb.co/b3jD38x/022.webp' },
	{ page: 23, link: 'https://i.ibb.co/jRGRvbt/023.webp' },
	{ page: 24, link: 'https://i.ibb.co/zQG18rY/024.webp' },
	{ page: 25, link: 'https://i.ibb.co/qJWYWWN/025.webp' },
	{ page: 26, link: 'https://i.ibb.co/Y8Wjq9J/026.webp' },
	{ page: 27, link: 'https://i.ibb.co/5hthYcr/027.webp' },
	{ page: 28, link: 'https://i.ibb.co/sP6ffLK/028.webp' },
	{ page: 29, link: 'https://i.ibb.co/6vCqT3y/029.webp' },
	{ page: 30, link: 'https://i.ibb.co/LdPMmC1/030.webp' },
	{ page: 31, link: 'https://i.ibb.co/vx0yBMZ/031.webp' },
	{ page: 32, link: 'https://i.ibb.co/gtqYzN5/032.webp' }
]


export default listePages;