const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/XsJDQ7n/43-6-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/37Rv93N/43-6-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/NYcqfcs/43-6-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/9tTks0T/43-6-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/J2Wqd2t/43-6-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/sHpHvbv/43-6-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/TLBGLQk/43-6-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/PWydhW1/43-6-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/W6X43Wt/43-6-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/Q8ddb1C/43-6-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/TWGCfxb/43-6-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/dLN9vWS/43-6-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/K5QbLq5/43-6-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/68y7bZk/43-6-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/FnN3RY9/43-6-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/3v9DmTb/43-6-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/qFZZ9hp/43-6-017.webp"
  }
];


export default listePages;