const listePages = [
    { page: 1, link: "https://i.ibb.co/6sz3cvJ/0065-001.webp" },
    { page: 2, link: "https://i.ibb.co/3ysyXyH/0065-002.webp" },
    { page: 3, link: "https://i.ibb.co/XJVG1tP/0065-003.webp" },
    { page: 4, link: "https://i.ibb.co/dG7FMMt/0065-004.webp" },
    { page: 5, link: "https://i.ibb.co/bvb9PSy/0065-005.webp" },
    { page: 6, link: "https://i.ibb.co/qWsmHcM/0065-006.webp" },
    { page: 7, link: "https://i.ibb.co/N6LCZng/0065-007.webp" },
    { page: 8, link: "https://i.ibb.co/FXLhVTw/0065-008.webp" },
    { page: 9, link: "https://i.ibb.co/x3wddc9/0065-009.webp" },
    { page: 10, link: "https://i.ibb.co/TYk9psv/0065-010.webp" },
    { page: 11, link: "https://i.ibb.co/tx5hPFp/0065-011.webp" },
    { page: 12, link: "https://i.ibb.co/sy8LfRd/0065-012.webp" },
    { page: 13, link: "https://i.ibb.co/XkcgWQD/0065-013.webp" },
    { page: 14, link: "https://i.ibb.co/wJbFKG4/0065-014.webp" },
    { page: 15, link: "https://i.ibb.co/ypRhM8Y/0065-015.webp" },
    { page: 16, link: "https://i.ibb.co/YNZp1k4/0065-016.webp" },
    { page: 17, link: "https://i.ibb.co/jfdk7wm/0065-017.webp" },
    { page: 18, link: "https://i.ibb.co/2NqfqdV/0065-018.webp" },
    { page: 19, link: "https://i.ibb.co/zh4Fw8y/0065-019.webp" },
    { page: 20, link: "https://i.ibb.co/frrxSzT/0065-020.webp" },
    { page: 21, link: "https://i.ibb.co/DRbFxmz/0065-021.webp" },
    { page: 22, link: "https://i.ibb.co/6RGTZrS/0065-022.webp" },
    { page: 23, link: "https://i.ibb.co/CBTwQVV/0065-023.webp" },
    { page: 24, link: "https://i.ibb.co/r4ZXhCc/0065-024.webp" },
    { page: 25, link: "https://i.ibb.co/4Yy1skN/0065-025.webp" },
    { page: 26, link: "https://i.ibb.co/vJ8d7Z2/0065-026.webp" },
    { page: 27, link: "https://i.ibb.co/nrc837w/0065-027.webp" },
    { page: 28, link: "https://i.ibb.co/v4x2jXS/0065-028.webp" },
    { page: 29, link: "https://i.ibb.co/7N7Z7WN/0065-029.webp" },
    { page: 30, link: "https://i.ibb.co/vxq85BK/0065-030.webp" },
    { page: 31, link: "https://i.ibb.co/Z1ShdDc/0065-031.webp" },
    { page: 32, link: "https://i.ibb.co/kKM52dY/0065-032.webp" },
    { page: 33, link: "https://i.ibb.co/hRpJBqV/0065-033.webp" }
  ]
  

export default listePages;