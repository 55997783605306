const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/9pMvQmD/0016-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/hRTHSyv/0016-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/bL8W4k7/0016-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/Xy9QMGY/0016-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/g6W3QwM/0016-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/MS162mz/0016-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/dgYsNRb/0016-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/CQ7RDNz/0016-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/J5XWq45/0016-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/zJgCYMS/0016-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/mB7tf48/0016-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/gMwm60r/0016-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/L1Qc8rD/0016-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/YQf3LgQ/0016-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/89YPpCP/0016-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/mHWjsWJ/0016-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/t4Gxv8J/0016-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/FxfrrXD/0016-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/prPz444/0016-019.webp"
  },
  {
    page: 20,
    link: "https://i.ibb.co/fxfNxC4/0016-020.webp"
  },
  {
    page: 21,
    link: "https://i.ibb.co/Pmz6pz4/0016-021.webp"
  },
  {
    page: 22,
    link: "https://i.ibb.co/JqXwnbQ/0016-022.webp"
  },
  {
    page: 23,
    link: "https://i.ibb.co/6vw2V7N/0016-023.webp"
  },
  {
    page: 24,
    link: "https://i.ibb.co/Mf4sSZ4/0016-024.webp"
  },
  {
    page: 25,
    link: "https://i.ibb.co/v1xG7f0/0016-025.webp"
  },
  {
    page: 26,
    link: "https://i.ibb.co/1RTJkPw/0016-026.webp"
  },
  {
    page: 27,
    link: "https://i.ibb.co/T2qbzp5/0016-027.webp"
  },
  {
    page: 28,
    link: "https://i.ibb.co/VLN9pZ2/0016-028.webp"
  },
  {
    page: 29,
    link: "https://i.ibb.co/rvJzg3p/0016-029.webp"
  },
  {
    page: 30,
    link: "https://i.ibb.co/WP6y52s/0016-030.webp"
  },
  {
    page: 31,
    link: "https://i.ibb.co/ZcdrwCY/0016-031.webp"
  },
  {
    page: 32,
    link: "https://i.ibb.co/WpHpSh4/0016-032.webp"
  },
  {
    page: 33,
    link: "https://i.ibb.co/RYwSPS5/0016-033.webp"
  },
  {
    page: 34,
    link: "https://i.ibb.co/h7wsQMg/0016-034.webp"
  },
  {
    page: 35,
    link: "https://i.ibb.co/bH2CGkQ/0016-035.webp"
  },
  {
    page: 36,
    link: "https://i.ibb.co/JKR3Zpv/0016-036.webp"
  },
  {
    page: 37,
    link: "https://i.ibb.co/550v0g8/0016-037.webp"
  },
  {
    page: 38,
    link: "https://i.ibb.co/rG2gyL7/0016-038.webp"
  },
  {
    page: 39,
    link: "https://i.ibb.co/qM6BFf0/0016-039.webp"
  },
  {
    page: 40,
    link: "https://i.ibb.co/2thgtMy/0016-040.webp"
  }    
]

export default listePages;