const listePages = [
  { page: 1, link: "https://i.ibb.co/64Ctg2G/52-5-001.webp" },
  { page: 2, link: "https://i.ibb.co/4mDSRPG/52-5-002.webp" },
  { page: 3, link: "https://i.ibb.co/M1Y2P30/52-5-003.webp" },
  { page: 4, link: "https://i.ibb.co/xHC832q/52-5-004.webp" },
  { page: 5, link: "https://i.ibb.co/fFzh6hP/52-5-005.webp" },
  { page: 6, link: "https://i.ibb.co/mR1kJT2/52-5-006.webp" },
  { page: 7, link: "https://i.ibb.co/gPtnQ4N/52-5-007.webp" },
  { page: 8, link: "https://i.ibb.co/7kqz1ty/52-5-008.webp" },
  { page: 9, link: "https://i.ibb.co/0cRhgvr/52-5-009.webp" },
  { page: 10, link: "https://i.ibb.co/RCJj37J/52-5-010.webp" },
  { page: 11, link: "https://i.ibb.co/ZKySt4G/52-5-011.webp" },
  { page: 12, link: "https://i.ibb.co/pvVfSNC/52-5-012.webp" }
]

export default listePages;