import HomeMoviesList from "../components/Home/MoviesList"
import HomeAnimesList from "../components/Home/AnimesList"
import HomeScansList from "../components/Home/ScansList"
import { Helmet } from "react-helmet"

export default function Home() {
        return (
                <>
                        <Helmet>
                                <title>Streaming Espace</title>
                                <link rel="canonical" href="https://www.streaming-espace.fr" />
                                <meta name="description" content="Créé par Beyblade Espace, Streaming Espace est LE site à visiter si tu souhaites regarder l'anime Beyblade en streaming facilement ! Découvrez vos personnages favoris dans toutes leurs aventures en version originale sous-titrée ou en version française ! Site gratuit, sans inscription et sans pubs !" />
                        </Helmet>
                        <main className="content container Homepage">
                                <HomeAnimesList />
                                <HomeScansList />
                                <HomeMoviesList />
                        </main>                
                </>
        )       
}