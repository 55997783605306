const listePages = [
  {
    page: 31,
    link: "https://i.ibb.co/C7V62KN/109-5-001.webp"
  },
  {
    page: 32,
    link: "https://i.ibb.co/8jTFpx4/109-5-002.webp"
  },
  {
    page: 33,
    link: "https://i.ibb.co/SxB1xpF/109-5-003.webp"
  },
  {
    page: 34,
    link: "https://i.ibb.co/pzXfhyw/109-5-004.webp"
  },
  {
    page: 35,
    link: "https://i.ibb.co/qg1NVbw/109-5-005.webp"
  },
  {
    page: 36,
    link: "https://i.ibb.co/SRtpN0y/109-5-006.webp"
  },
  {
    page: 37,
    link: "https://i.ibb.co/zXDScqX/109-5-007.webp"
  },
  {
    page: 38,
    link: "https://i.ibb.co/LC8ndMg/109-5-008.webp"
  },
  {
    page: 39,
    link: "https://i.ibb.co/7GCTr4q/109-5-009.webp"
  },
  {
    page: 40,
    link: "https://i.ibb.co/Prz2f3X/109-5-010.webp"
  },
  {
    page: 41,
    link: "https://i.ibb.co/YByQRjQ/109-5-011.webp"
  },
  {
    page: 42,
    link: "https://i.ibb.co/8gqm4N1/109-5-012.webp"
  },
  {
    page: 43,
    link: "https://i.ibb.co/6bHRQdG/109-5-013.webp"
  },
  {
    page: 44,
    link: "https://i.ibb.co/JcPrjtx/109-5-014.webp"
  },
  {
    page: 45,
    link: "https://i.ibb.co/3zCQ5FQ/109-5-015.webp"
  },
  {
    page: 46,
    link: "https://i.ibb.co/42ppjZB/109-5-016.webp"
  },
  {
    page: 47,
    link: "https://i.ibb.co/ykNBYPM/109-5-017.webp"
  },
  {
    page: 48,
    link: "https://i.ibb.co/d4czWQ6/109-5-018.webp"
  },
  {
    page: 49,
    link: "https://i.ibb.co/prCqTFZ/109-5-019.webp"
  },
  {
    page: 50,
    link: "https://i.ibb.co/30gzYSj/109-5-020.webp"
  },
  {
    page: 51,
    link: "https://i.ibb.co/xJTHkJQ/109-5-021.webp"
  },
  {
    page: 52,
    link: "https://i.ibb.co/KmN2Ygj/109-5-022.webp"
  },
  {
    page: 53,
    link: "https://i.ibb.co/BLnbsDX/109-5-023.webp"
  },
  {
    page: 54,
    link: "https://i.ibb.co/KqZS55M/109-5-024.webp"
  },
  {
    page: 55,
    link: "https://i.ibb.co/3NDvgpt/109-5-025.webp"
  },
  {
    page: 56,
    link: "https://i.ibb.co/VCVjpMc/109-5-026.webp"
  },
  {
    page: 57,
    link: "https://i.ibb.co/68wX2YB/109-5-027.webp"
  },
  {
    page: 58,
    link: "https://i.ibb.co/NpF6CSS/109-5-028.webp"
  },
  {
    page: 59,
    link: "https://i.ibb.co/5GngNX7/109-5-029.webp"
  },
  {
    page: 60,
    link: "https://i.ibb.co/zxgsBTy/109-5-030.webp"
  },
  {
    page: 61,
    link: "https://i.ibb.co/LJJfd5N/109-5-031.webp"
  },
  {
    page: 62,
    link: "https://i.ibb.co/GprhpZg/109-5-032.webp"
  },
  {
    page: 63,
    link: "https://i.ibb.co/3mG5vj4/109-5-033.webp"
  }
]


export default listePages;