import React from 'react'
import ScansList from '../../datas/ScansList'

export default function HomeScansList() {
  return (
    <section className='scans-section'>
      <div className='title-container'>
        <div className='title-decoration'></div>
        <h1>BEYBLADE SCANS</h1>
      </div>
      <div className='articles-container'>
      {
          ScansList.map((scan) =>
          <article key={scan.id}>
            <a href={scan.link}>
              <div className='card'>
                <div className='wrapper'>
                  <img width="220px" height="310px" src={scan.img} alt={"Affiche du scan de " + scan.generation + " Beyblade Streaming"}/>
                </div>
                <img src={scan.logo} className='logotitle' alt={"Logo du scan de " + scan.generation + " Beyblade Streaming"} />
                <img src={scan.character} className='character' alt={"Personnage principal du scan de " + scan.generation + " Beyblade Streaming"} />
              </div>
              
              <h2>{scan.generation}</h2>
            </a>
          </article>
          )
        }
      </div>
    </section>
  )
}