const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/vsdRQZM/0041-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/qN64B8R/0041-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/L5w0LCP/0041-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/kBpLJCC/0041-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/Gs7j5js/0041-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/nsVyNQ7/0041-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/YTYWFzy/0041-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/C921WnN/0041-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/gm7rz0d/0041-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/smPg312/0041-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/q7FGhfn/0041-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/K0ZW8y7/0041-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/gF6jtdZ/0041-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/qBMM50R/0041-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/P6D951Q/0041-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/JdZgbBt/0041-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/1v9WrH4/0041-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/P6Sbzxg/0041-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/n1vfbqp/0041-019.webp"
  },
  {
    page: 20,
    link: "https://i.ibb.co/ws2V25Z/0041-020.webp"
  },
  {
    page: 21,
    link: "https://i.ibb.co/zbSq8xw/0041-021.webp"
  },
  {
    page: 22,
    link: "https://i.ibb.co/jMCKzqP/0041-022.webp"
  },
  {
    page: 23,
    link: "https://i.ibb.co/3z6VMkb/0041-023.webp"
  },
  {
    page: 24,
    link: "https://i.ibb.co/8rqjYmd/0041-024.webp"
  },
  {
    page: 25,
    link: "https://i.ibb.co/8N7PCDK/0041-025.webp"
  },
  {
    page: 26,
    link: "https://i.ibb.co/gF0rP0h/0041-026.webp"
  },
  {
    page: 27,
    link: "https://i.ibb.co/GdpQKK6/0041-027.webp"
  },
  {
    page: 28,
    link: "https://i.ibb.co/mc51HDt/0041-028.webp"
  },
  {
    page: 29,
    link: "https://i.ibb.co/RYc8gxt/0041-029.webp"
  },
  {
    page: 30,
    link: "https://i.ibb.co/NN87pf4/0041-030.webp"
  },
  {
    page: 31,
    link: "https://i.ibb.co/0tcGTtb/0041-031.webp"
  },
  {
    page: 32,
    link: "https://i.ibb.co/r0F97r6/0041-032.webp"
  }
]


export default listePages;