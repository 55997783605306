const listePages = [
      {
        page: 44,
        link: "https://i.ibb.co/DWbWVtL/168.webp"
      },
      {
        page: 45,
        link: "https://i.ibb.co/4gshv7y/169.webp"
      },
      {
        page: 46,
        link: "https://i.ibb.co/BcRYQfB/170.webp"
      }
]


export default listePages;