const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/V3yzgQ9/0027-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/JtjFDPw/0027-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/sbBCgMv/0027-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/8M5SdHk/0027-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/DWXfQ5V/0027-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/k8Xvnch/0027-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/Khwffrc/0027-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/xFDbKNV/0027-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/FXqr9Jc/0027-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/D8kfPHr/0027-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/x7K1pQC/0027-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/pych4B3/0027-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/yhBzsyW/0027-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/TWYBy88/0027-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/t3ChdrK/0027-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/DrvNtRW/0027-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/TqT1Zwk/0027-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/0spDSN0/0027-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/TKWTjbL/0027-019.webp"
  },
  {
    page: 20,
    link: "https://i.ibb.co/WBFTsxW/0027-020.webp"
  },
  {
    page: 21,
    link: "https://i.ibb.co/64JCxDm/0027-021.webp"
  },
  {
    page: 22,
    link: "https://i.ibb.co/QY1pmVf/0027-022.webp"
  },
  {
    page: 23,
    link: "https://i.ibb.co/f95NcGw/0027-023.webp"
  },
  {
    page: 24,
    link: "https://i.ibb.co/FbB2htL/0027-024.webp"
  },
  {
    page: 25,
    link: "https://i.ibb.co/j6RNYPF/0027-025.webp"
  },
  {
    page: 26,
    link: "https://i.ibb.co/jyYQZqM/0027-026.webp"
  },
  {
    page: 27,
    link: "https://i.ibb.co/GdQbP6g/0027-027.webp"
  },
  {
    page: 28,
    link: "https://i.ibb.co/sgjvfDb/0027-028.webp"
  },
  {
    page: 29,
    link: "https://i.ibb.co/C0D4ryq/0027-029.webp"
  },
  {
    page: 30,
    link: "https://i.ibb.co/S0K4nJb/0027-030.webp"
  },
  {
    page: 31,
    link: "https://i.ibb.co/QfyBhNw/0027-031.webp"
  },
  {
    page: 32,
    link: "https://i.ibb.co/LpX28dH/0027-032.webp"
  },
  {
    page: 33,
    link: "https://i.ibb.co/6XH83wZ/0027-033.webp"
  },
  {
    page: 34,
    link: "https://i.ibb.co/HXj55dF/0027-034.webp"
  },
  {
    page: 35,
    link: "https://i.ibb.co/JB2LL3s/0027-035.webp"
  },
  {
    page: 36,
    link: "https://i.ibb.co/n6yH7JB/0027-036.webp"
  },
  {
    page: 37,
    link: "https://i.ibb.co/DwwWKm6/0027-037.webp"
  },
  {
    page: 38,
    link: "https://i.ibb.co/0rPRsjH/0027-038.webp"
  },
  {
    page: 39,
    link: "https://i.ibb.co/1MzQRYS/0027-039.webp"
  },
  {
    page: 40,
    link: "https://i.ibb.co/84gQjLL/0027-040.webp"
  },
  {
    page: 41,
    link: "https://i.ibb.co/Yt5SqQv/0027-041.webp"
  },
  {
    page: 42,
    link: "https://i.ibb.co/p1bH0G8/0027-042.webp"
  },
  {
    page: 43,
    link: "https://i.ibb.co/v42tdrR/0027-043.webp"
  },
  {
    page: 44,
    link: "https://i.ibb.co/dtV7S5R/0027-044.webp"
  },
  {
    page: 45,
    link: "https://i.ibb.co/S6ZH82D/0027-045.webp"
  },
  {
    page: 46,
    link: "https://i.ibb.co/rZdXfs5/0027-046.webp"
  },
  {
    page: 47,
    link: "https://i.ibb.co/z2Ds57S/0027-047.webp"
  },
  {
    page: 48,
    link: "https://i.ibb.co/Dz0p6Rq/0027-048.webp"
  },
  {
    page: 49,
    link: "https://i.ibb.co/DtkMM48/0027-049.webp"
  },
  {
    page: 50,
    link: "https://i.ibb.co/9ynDqmc/0027-050.webp"
  },
  {
    page: 51,
    link: "https://i.ibb.co/Xpygzp6/0027-051.webp"
  },
  {
    page: 52,
    link: "https://i.ibb.co/2FjJY8F/0027-052.webp"
  },
  {
    page: 53,
    link: "https://i.ibb.co/MBFRh9Z/0027-053.webp"
  },
  {
    page: 54,
    link: "https://i.ibb.co/Fs6xfDR/0027-054.webp"
  },
  {
    page: 55,
    link: "https://i.ibb.co/ykvc90h/0027-055.webp"
  },
  {
    page: 56,
    link: "https://i.ibb.co/qdYK0rj/0027-056.webp"
  },
  {
    page: 57,
    link: "https://i.ibb.co/HYBKM3Z/0027-057.webp"
  },
  {
    page: 58,
    link: "https://i.ibb.co/nPqkXGK/0027-058.webp"
  },
  {
    page: 59,
    link: "https://i.ibb.co/7nkBzfm/0027-059.webp"
  },
  {
    page: 60,
    link: "https://i.ibb.co/Dp08fsy/0027-060.webp"
  },
  {
    page: 61,
    link: "https://i.ibb.co/dfwR5s8/0027-061.webp"
  },
  {
    page: 62,
    link: "https://i.ibb.co/FwC6yy5/0027-062.webp"
  },
  {
    page: 63,
    link: "https://i.ibb.co/Xtxh0Bz/0027-063.webp"
  },
  {
    page: 64,
    link: "https://i.ibb.co/QkxBMj0/0027-064.webp"
  },
  {
    page: 65,
    link: "https://i.ibb.co/d2TV7xW/0027-065.webp"
  },
  {
    page: 66,
    link: "https://i.ibb.co/zSbxQBr/0027-066.webp"
  },
  {
    page: 67,
    link: "https://i.ibb.co/7g8Zt34/0027-067.webp"
  },
  {
    page: 68,
    link: "https://i.ibb.co/kBmsy1F/0027-068.webp"
  },
  {
    page: 69,
    link: "https://i.ibb.co/gdzsGcc/0027-069.webp"
  },
  {
    page: 70,
    link: "https://i.ibb.co/wC612yG/0027-070.webp"
  },
  {
    page: 71,
    link: "https://i.ibb.co/5sr4Bcn/0027-071.webp"
  },
  {
    page: 72,
    link: "https://i.ibb.co/WsJjWyk/0027-072.webp"
  },
  {
    page: 73,
    link: "https://i.ibb.co/4Yq6C1p/0027-073.webp"
  },
  {
    page: 74,
    link: "https://i.ibb.co/wCZ2N3Q/0027-074.webp"
  },
  {
    page: 75,
    link: "https://i.ibb.co/Cz2TQG0/0027-075.webp"
  },
  {
    page: 76,
    link: "https://i.ibb.co/FXjLJ9q/0027-076.webp"
  }  
]

export default listePages;