const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/r77T47V/0020-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/74pCH5K/0020-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/8XPP3Pq/0020-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/yf5CRKs/0020-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/tBqZrVM/0020-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/TM4WYvw/0020-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/X58H0hk/0020-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/jwdyCg7/0020-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/yPM3XBJ/0020-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/YDHg209/0020-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/Hq2BxQn/0020-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/mRB84P9/0020-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/d5K6SHk/0020-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/xfD7QqC/0020-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/sHTmd8B/0020-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/s9j4kcL/0020-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/ZWr6Zmj/0020-017.webp"
  }  
]

export default listePages;