const listePages = [
    { page: 1, link: "https://i.ibb.co/nP1Kcwn/Beyblade-X-01.webp" },
    { page: 2, link: "https://i.ibb.co/8MRyQYJ/Beyblade-X-02.webp" },
    { page: 3, link: "https://i.ibb.co/2yBKnTc/Beyblade-X-03.webp" },
    { page: 4, link: "https://i.ibb.co/RbXzpQ7/Beyblade-X-04.webp" },
    { page: 5, link: "https://i.ibb.co/Dr8SLsz/Beyblade-X-05.webp" },
    { page: 6, link: "https://i.ibb.co/XLk335b/Beyblade-X-06.webp" },
    { page: 7, link: "https://i.ibb.co/Vt9XnXZ/Beyblade-X-07.webp" },
    { page: 8, link: "https://i.ibb.co/jvfNXRL/Beyblade-X-08.webp" },
    { page: 9, link: "https://i.ibb.co/jGcNfSF/Beyblade-X-09.webp" },
    { page: 10, link: "https://i.ibb.co/Jry6W3r/Beyblade-X-p10.webp" },
    { page: 11, link: "https://i.ibb.co/ydbbpqf/Beyblade-X-p11.webp" },
    { page: 12, link: "https://i.ibb.co/s6CQSDF/Beyblade-X-p12.webp" },
    { page: 13, link: "https://i.ibb.co/MSS5XXN/Beyblade-X-p13.webp" },
    { page: 14, link: "https://i.ibb.co/n3dFsgj/Beyblade-X-p14.webp" },
    { page: 15, link: "https://i.ibb.co/7131Cdg/Beyblade-X-p15.webp" },
    { page: 16, link: "https://i.ibb.co/x3Vb0Px/Beyblade-X-p16.webp" },
    { page: 17, link: "https://i.ibb.co/K7bbWZC/Beyblade-X-p17.webp" },
    { page: 18, link: "https://i.ibb.co/JCg5GTt/Beyblade-X-p18.webp" },
    { page: 19, link: "https://i.ibb.co/h9zm3tD/Beyblade-X-p19.webp" },
    { page: 20, link: "https://i.ibb.co/Rbg12Tb/Beyblade-X-p20.webp" },
    { page: 21, link: "https://i.ibb.co/WnNKcs9/Beyblade-X-p21.webp" },
    { page: 22, link: "https://i.ibb.co/xSHDpf3/Beyblade-X-p22.webp" },
    { page: 23, link: "https://i.ibb.co/2gTvfRQ/Beyblade-X-p23.webp" },
    { page: 24, link: "https://i.ibb.co/MsR0vSt/Beyblade-X-p24.webp" },
    { page: 25, link: "https://i.ibb.co/7YGkPz1/Beyblade-X-p25.webp" },
    { page: 26, link: "https://i.ibb.co/P4z9v2M/Beyblade-X-p26.webp" },
    { page: 27, link: "https://i.ibb.co/JW1WsfB/Beyblade-X-p27.webp" },
    { page: 28, link: "https://i.ibb.co/BGVZ65y/Beyblade-X-p28.webp" },
    { page: 29, link: "https://i.ibb.co/NYP8sJQ/Beyblade-X-p29.webp" },
    { page: 30, link: "https://i.ibb.co/C8FK24R/Beyblade-X-p30.webp" },
    { page: 31, link: "https://i.ibb.co/ykx4YGm/Beyblade-X-p31.webp" },
    { page: 32, link: "https://i.ibb.co/CWP50TF/Beyblade-X-p32.webp" },
    { page: 33, link: "https://i.ibb.co/zH4yZ1W/Beyblade-X-p33.webp" },
    { page: 34, link: "https://i.ibb.co/3pDNLKs/Beyblade-X-p34.webp" },
    { page: 35, link: "https://i.ibb.co/W6RXL4t/Beyblade-X-p35.webp" },
    { page: 36, link: "https://i.ibb.co/VWCgpXR/Beyblade-X-p36.webp" },
    { page: 37, link: "https://i.ibb.co/h9wBsT7/Beyblade-X-p37.webp" },
    { page: 38, link: "https://i.ibb.co/8xj94Sj/Beyblade-X-p38.webp" },
    { page: 39, link: "https://i.ibb.co/KGsmjhT/Beyblade-X-p39.webp" },
    { page: 40, link: "https://i.ibb.co/tCL7NY7/Beyblade-X-p40.webp" },
    { page: 41, link: "https://i.ibb.co/fQGTSLC/Beyblade-X-p41.webp" },
    { page: 42, link: "https://i.ibb.co/cFnMKPT/Beyblade-X-p42.webp" },
    { page: 43, link: "https://i.ibb.co/BLNHsMc/Beyblade-X-p43.webp" },
    { page: 44, link: "https://i.ibb.co/pPgMMyc/Beyblade-X-p44.webp" },
    { page: 45, link: "https://i.ibb.co/2tthyFZ/Beyblade-X-p45.webp" },
    { page: 46, link: "https://i.ibb.co/XjR6Yr2/Beyblade-X-p46.webp" },
    { page: 47, link: "https://i.ibb.co/52bNv0h/Beyblade-X-p47.webp" },
    { page: 48, link: "https://i.ibb.co/z7mZSXD/Beyblade-X-p48.webp" },
    { page: 49, link: "https://i.ibb.co/7tHqVWM/Beyblade-X-p49.webp" },
    { page: 50, link: "https://i.ibb.co/R90ZT3C/Beyblade-X-p50.webp" },
    { page: 51, link: "https://i.ibb.co/Sf1z3gK/Beyblade-X-p51.webp" },
    { page: 52, link: "https://i.ibb.co/LdYz4Dw/Beyblade-X-p52.webp" },
    { page: 53, link: "https://i.ibb.co/7yFz1DW/Beyblade-X-p53.webp" },
    { page: 54, link: "https://i.ibb.co/LJj5jGk/Beyblade-X-p54.webp" },
    { page: 55, link: "https://i.ibb.co/CHJT70m/Beyblade-X-p55.webp" },
    { page: 56, link: "https://i.ibb.co/zmZy631/Beyblade-X-p56.webp" },
    { page: 57, link: "https://i.ibb.co/1MtRTCM/Beyblade-X-p57.webp" },
    { page: 58, link: "https://i.ibb.co/LxHHzQj/Beyblade-X-p58.webp" },
    { page: 59, link: "https://i.ibb.co/qRdyxBT/Beyblade-X-p59.webp" },
    { page: 60, link: "https://i.ibb.co/hXJTh7w/Beyblade-X-p60.webp" },
    { page: 61, link: "https://i.ibb.co/T1Qfv7G/Beyblade-X-p61.webp" },
    { page: 62, link: "https://i.ibb.co/Bq7nRwN/Beyblade-X-p62.webp" },
    { page: 63, link: "https://i.ibb.co/h8Tnmr9/Beyblade-X-p63.webp" },
    { page: 64, link: "https://i.ibb.co/5ny5vRC/Beyblade-X-p64.webp" },
    { page: 65, link: "https://i.ibb.co/WsK5vQx/Beyblade-X-p65.webp" },
    { page: 66, link: "https://i.ibb.co/fSyMNrJ/Beyblade-X-p66.webp" },
    { page: 67, link: "https://i.ibb.co/DCwWHFd/Beyblade-X-p67.webp" },
    { page: 68, link: "https://i.ibb.co/7XWqTMS/Beyblade-X-p68.webp" }
  ]
  

export default listePages;