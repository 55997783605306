const listePages = [
      {
        page: 32,
        link: "https://i.ibb.co/yNMRqHH/0035.webp"
      },
      {
        page: 33,
        link: "https://i.ibb.co/6wqRKZ5/0036.webp"
      },
      {
        page: 34,
        link: "https://i.ibb.co/Z2zRtDm/0037.webp"
      },
      {
        page: 35,
        link: "https://i.ibb.co/qNJqKNC/0038.webp"
      },
      {
        page: 36,
        link: "https://i.ibb.co/vXWW2JN/0039.webp"
      },
      {
        page: 37,
        link: "https://i.ibb.co/vjPVbxL/0040.webp"
      },
      {
        page: 38,
        link: "https://i.ibb.co/rZXDdnw/0041.webp"
      },
      {
        page: 39,
        link: "https://i.ibb.co/ZHdSjw2/0042.webp"
      },
      {
        page: 40,
        link: "https://i.ibb.co/qr2FbRS/0043.webp"
      },
      {
        page: 41,
        link: "https://i.ibb.co/PgN59Kn/0044.webp"
      },
      {
        page: 42,
        link: "https://i.ibb.co/0KFpRJp/0045.webp"
      },
      {
        page: 43,
        link: "https://i.ibb.co/RBq3bJf/0046.webp"
      },
      {
        page: 44,
        link: "https://i.ibb.co/gP3VHKT/0047.webp"
      },
      {
        page: 45,
        link: "https://i.ibb.co/jhmsYsM/0048.webp"
      },
      {
        page: 46,
        link: "https://i.ibb.co/4jQvtrL/0049.webp"
      },
      {
        page: 47,
        link: "https://i.ibb.co/2KSfNnn/0050.webp"
      },
      {
        page: 48,
        link: "https://i.ibb.co/cN1Lk2x/0051.webp"
      }
]


export default listePages;