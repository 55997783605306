const listePages = [
    { page: 1, link: "https://i.ibb.co/8gPb5XC/0067-001.webp" },
    { page: 2, link: "https://i.ibb.co/k4jtNLp/0067-002.webp" },
    { page: 3, link: "https://i.ibb.co/MC5tk0H/0067-003.webp" },
    { page: 4, link: "https://i.ibb.co/bBnhb8v/0067-004.webp" },
    { page: 5, link: "https://i.ibb.co/wgddm61/0067-005.webp" },
    { page: 6, link: "https://i.ibb.co/hdXZjy0/0067-006.webp" },
    { page: 7, link: "https://i.ibb.co/920WfpS/0067-007.webp" },
    { page: 8, link: "https://i.ibb.co/6yCVPNC/0067-008.webp" },
    { page: 9, link: "https://i.ibb.co/0BJ7LGF/0067-009.webp" },
    { page: 10, link: "https://i.ibb.co/x8F1KKW/0067-010.webp" },
    { page: 11, link: "https://i.ibb.co/mh1N8z2/0067-011.webp" },
    { page: 12, link: "https://i.ibb.co/R0Mx5rG/0067-012.webp" },
    { page: 13, link: "https://i.ibb.co/y5wMHtH/0067-013.webp" },
    { page: 14, link: "https://i.ibb.co/x1nyMxS/0067-014.webp" },
    { page: 15, link: "https://i.ibb.co/R2hMrQ3/0067-015.webp" },
    { page: 16, link: "https://i.ibb.co/NrZ3tsz/0067-016.webp" },
    { page: 17, link: "https://i.ibb.co/XknqdZ9/0067-017.webp" },
    { page: 18, link: "https://i.ibb.co/rbsvh2y/0067-018.webp" },
    { page: 19, link: "https://i.ibb.co/HDTrxFD/0067-019.webp" },
    { page: 20, link: "https://i.ibb.co/gdn76LQ/0067-020.webp" },
    { page: 21, link: "https://i.ibb.co/CwD0CPd/0067-021.webp" },
    { page: 22, link: "https://i.ibb.co/TTNPjVT/0067-022.webp" },
    { page: 23, link: "https://i.ibb.co/5KZ4Ly8/0067-023.webp" },
    { page: 24, link: "https://i.ibb.co/sqTrVz1/0067-024.webp" },
    { page: 25, link: "https://i.ibb.co/W5CYSMz/0067-025.webp" },
    { page: 26, link: "https://i.ibb.co/hm37qbM/0067-026.webp" },
    { page: 27, link: "https://i.ibb.co/wLYHNWz/0067-027.webp" },
    { page: 28, link: "https://i.ibb.co/hL7Q2n5/0067-028.webp" },
    { page: 29, link: "https://i.ibb.co/zSYJb16/0067-029.webp" },
    { page: 30, link: "https://i.ibb.co/F7dj27m/0067-030.webp" },
    { page: 31, link: "https://i.ibb.co/2j4rzNp/0067-031.webp" },
    { page: 32, link: "https://i.ibb.co/ZY7vzmd/0067-032.webp" },
    { page: 33, link: "https://i.ibb.co/TqtGD0R/0067-033.webp" },
    { page: 34, link: "https://i.ibb.co/Kq9pcz5/0067-034.webp" },
    { page: 35, link: "https://i.ibb.co/8dR6P42/0067-035.webp" },
    { page: 36, link: "https://i.ibb.co/F6G9qBv/0067-036.webp" },
    { page: 37, link: "https://i.ibb.co/f99vNZx/0067-037.webp" },
    { page: 38, link: "https://i.ibb.co/cQrvvb0/0067-038.webp" },
    { page: 39, link: "https://i.ibb.co/LQ6xwrh/0067-039.webp" }
  ]
  

export default listePages;