const listePages = [
  { page: 1, link: "https://i.ibb.co/CVvfSgB/0052-001.webp" },
  { page: 2, link: "https://i.ibb.co/TryRMkD/0052-002.webp" },
  { page: 3, link: "https://i.ibb.co/s5VHCvv/0052-003.webp" },
  { page: 4, link: "https://i.ibb.co/pW74K02/0052-004.webp" },
  { page: 5, link: "https://i.ibb.co/JF1KnRM/0052-005.webp" },
  { page: 6, link: "https://i.ibb.co/J3XN6D1/0052-006.webp" },
  { page: 7, link: "https://i.ibb.co/5jNbJsk/0052-007.webp" },
  { page: 8, link: "https://i.ibb.co/w7ZSf43/0052-008.webp" },
  { page: 9, link: "https://i.ibb.co/jDqcfwq/0052-009.webp" },
  { page: 10, link: "https://i.ibb.co/R4HSmQF/0052-010.webp" },
  { page: 11, link: "https://i.ibb.co/dj64nQ4/0052-011.webp" },
  { page: 12, link: "https://i.ibb.co/zrbzSBB/0052-012.webp" },
  { page: 13, link: "https://i.ibb.co/Jtzb71d/0052-013.webp" },
  { page: 14, link: "https://i.ibb.co/BsVd3KN/0052-014.webp" },
  { page: 15, link: "https://i.ibb.co/Ryz6Fc4/0052-015.webp" },
  { page: 16, link: "https://i.ibb.co/KWyWSZh/0052-016.webp" },
  { page: 17, link: "https://i.ibb.co/5hdQb0m/0052-017.webp" },
  { page: 18, link: "https://i.ibb.co/wzrq8qL/0052-018.webp" },
  { page: 19, link: "https://i.ibb.co/Qdfjndp/0052-019.webp" },
  { page: 20, link: "https://i.ibb.co/9WfJj1M/0052-020.webp" },
  { page: 21, link: "https://i.ibb.co/j8Xk1TN/0052-021.webp" },
  { page: 22, link: "https://i.ibb.co/jz84zW4/0052-022.webp" },
  { page: 23, link: "https://i.ibb.co/kqXYt6R/0052-023.webp" },
  { page: 24, link: "https://i.ibb.co/YB5gntp/0052-024.webp" },
  { page: 25, link: "https://i.ibb.co/pL5czW2/0052-025.webp" },
  { page: 26, link: "https://i.ibb.co/7yTmzWr/0052-026.webp" },
  { page: 27, link: "https://i.ibb.co/9hy23DP/0052-027.webp" },
  { page: 28, link: "https://i.ibb.co/kQj8XWK/0052-028.webp" },
  { page: 29, link: "https://i.ibb.co/qj3PxCw/0052-029.webp" },
  { page: 30, link: "https://i.ibb.co/zff0wKT/0052-030.webp" },
  { page: 31, link: "https://i.ibb.co/F4p651v/0052-031.webp" },
  { page: 32, link: "https://i.ibb.co/Fwpbsjq/0052-032.webp" }
]

export default listePages;