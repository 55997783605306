const listeEpisodes = [
    {
        episode : 1,
        link : "https://video.sibnet.ru/shell.php?videoid=5229385"
    },
    {
        episode : 2,
        link : "https://video.sibnet.ru/shell.php?videoid=5229386"
    },
    {
        episode : 3,
        link : "https://video.sibnet.ru/shell.php?videoid=5229387"
    },
    {
        episode : 4,
        link : "https://video.sibnet.ru/shell.php?videoid=5229388"
    },
    {
        episode : 5,
        link : "https://video.sibnet.ru/shell.php?videoid=5229389"
    },
    {
        episode : 6,
        link : "https://video.sibnet.ru/shell.php?videoid=5229400"
    },
    {
        episode : 7,
        link : "https://video.sibnet.ru/shell.php?videoid=5229391"
    },
    {
        episode : 8,
        link : "https://video.sibnet.ru/shell.php?videoid=5229392"
    },
    {
        episode : 9,
        link : "https://video.sibnet.ru/shell.php?videoid=5229393"
    },
    {
        episode : 10,
        link : "https://video.sibnet.ru/shell.php?videoid=5229394"
    },
    {
        episode : 11,
        link : "https://video.sibnet.ru/shell.php?videoid=5229395"
    },
    {
        episode : 12,
        link : "https://video.sibnet.ru/shell.php?videoid=5229396"
    },
    {
        episode : 13,
        link : "https://video.sibnet.ru/shell.php?videoid=5229397"
    },
    {
        episode : 14,
        link : "https://video.sibnet.ru/shell.php?videoid=5229401"
    },
    {
        episode : 15,
        link : "https://video.sibnet.ru/shell.php?videoid=5229403"
    },
    {
        episode : 16,
        link : "https://video.sibnet.ru/shell.php?videoid=5229404"
    },
    {
        episode : 17,
        link : "https://video.sibnet.ru/shell.php?videoid=5229405"
    },
    {
        episode : 18,
        link : "https://video.sibnet.ru/shell.php?videoid=5229406"
    },
    {
        episode : 19,
        link : "https://video.sibnet.ru/shell.php?videoid=5229407"
    },
    {
        episode : 20,
        link : "https://video.sibnet.ru/shell.php?videoid=5229408"
    },
    {
        episode : 21,
        link : "https://video.sibnet.ru/shell.php?videoid=5229409"
    },
    {
        episode : 22,
        link : "https://video.sibnet.ru/shell.php?videoid=5229410"
    },
    {
        episode : 23,
        link : "https://video.sibnet.ru/shell.php?videoid=5229411"
    },
    {
        episode : 24,
        link : "https://video.sibnet.ru/shell.php?videoid=5229412"
    },
    {
        episode : 25,
        link : "https://video.sibnet.ru/shell.php?videoid=5229413"
    },
    {
        episode : 26,
        link : "https://video.sibnet.ru/shell.php?videoid=5229414"
    },
    {
        episode : 27,
        link : "https://video.sibnet.ru/shell.php?videoid=5229415"
    },
    {
        episode : 28,
        link : "https://video.sibnet.ru/shell.php?videoid=5229416"
    },
    {
        episode : 29,
        link : "https://video.sibnet.ru/shell.php?videoid=5229417"
    },
    {
        episode : 30,
        link : "https://video.sibnet.ru/shell.php?videoid=5229418"
    },
    {
        episode : 31,
        link : "https://video.sibnet.ru/shell.php?videoid=5229419"
    },
    {
        episode : 32,
        link : "https://video.sibnet.ru/shell.php?videoid=5229420"
    },
    {
        episode : 33,
        link : "https://video.sibnet.ru/shell.php?videoid=5229422"
    },
    {
        episode : 34,
        link : "https://video.sibnet.ru/shell.php?videoid=5229423"
    },
    {
        episode : 35,
        link : "https://video.sibnet.ru/shell.php?videoid=5229424"
    },
    {
        episode : 36,
        link : "https://video.sibnet.ru/shell.php?videoid=5229426"
    },
    {
        episode : 37,
        link : "https://video.sibnet.ru/shell.php?videoid=5229370"
    },
    {
        episode : 38,
        link : "https://video.sibnet.ru/shell.php?videoid=5229371"
    },
    {
        episode : 39,
        link : "https://video.sibnet.ru/shell.php?videoid=5229372"
    },
    {
        episode : 40,
        link : "https://video.sibnet.ru/shell.php?videoid=5229373"
    },
    {
        episode : 41,
        link : "https://video.sibnet.ru/shell.php?videoid=5229374"
    },
    {
        episode : 42,
        link : "https://video.sibnet.ru/shell.php?videoid=5229375"
    },
    {
        episode : 43,
        link : "https://video.sibnet.ru/shell.php?videoid=5229376"
    },
    {
        episode : 44,
        link : "https://video.sibnet.ru/shell.php?videoid=5229377"
    },
    {
        episode : 45,
        link : "https://video.sibnet.ru/shell.php?videoid=5229378"
    },
    {
        episode : 46,
        link : "https://video.sibnet.ru/shell.php?videoid=5229379"
    },
    {
        episode : 47,
        link : "https://video.sibnet.ru/shell.php?videoid=5229380"
    },
    {
        episode : 48,
        link : "https://video.sibnet.ru/shell.php?videoid=5229381"
    },
    {
        episode : 49,
        link : "https://video.sibnet.ru/shell.php?videoid=5229382"
    },
    {
        episode : 50,
        link : "https://video.sibnet.ru/shell.php?videoid=5229383"
    },
    {
        episode : 51,
        link : "https://video.sibnet.ru/shell.php?videoid=5229384"
    }
]

export default listeEpisodes;