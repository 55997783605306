const listePages = [
    { page: 1, link: "https://i.ibb.co/m03Mn3W/0062-001.webp" },
    { page: 2, link: "https://i.ibb.co/ssBgQqB/0062-002.webp" },
    { page: 3, link: "https://i.ibb.co/nQ6dVPq/0062-003.webp" },
    { page: 4, link: "https://i.ibb.co/wMJjHX6/0062-004.webp" },
    { page: 5, link: "https://i.ibb.co/Bs9jcMz/0062-005.webp" },
    { page: 6, link: "https://i.ibb.co/hCh0rg8/0062-006.webp" },
    { page: 7, link: "https://i.ibb.co/vD7x51w/0062-007.webp" },
    { page: 8, link: "https://i.ibb.co/d4cpJnK/0062-008.webp" },
    { page: 9, link: "https://i.ibb.co/tPCYCy9/0062-009.webp" },
    { page: 10, link: "https://i.ibb.co/GM4khzy/0062-010.webp" },
    { page: 11, link: "https://i.ibb.co/7W37qdj/0062-011.webp" },
    { page: 12, link: "https://i.ibb.co/xgksyyq/0062-012.webp" },
    { page: 13, link: "https://i.ibb.co/ZKBPwcK/0062-013.webp" },
    { page: 14, link: "https://i.ibb.co/y8Z3VzC/0062-014.webp" },
    { page: 15, link: "https://i.ibb.co/B4bBKVJ/0062-015.webp" },
    { page: 16, link: "https://i.ibb.co/Bj0Jbd7/0062-016.webp" },
    { page: 17, link: "https://i.ibb.co/mCFc66t/0062-017.webp" },
    { page: 18, link: "https://i.ibb.co/tcTK65C/0062-018.webp" },
    { page: 19, link: "https://i.ibb.co/f9q4tGJ/0062-019.webp" },
    { page: 20, link: "https://i.ibb.co/x66qGrg/0062-020.webp" },
    { page: 21, link: "https://i.ibb.co/mBqVnts/0062-021.webp" },
    { page: 22, link: "https://i.ibb.co/0Mn6t5s/0062-022.webp" },
    { page: 23, link: "https://i.ibb.co/VJKkBCb/0062-023.webp" },
    { page: 24, link: "https://i.ibb.co/3mm2nQy/0062-024.webp" },
    { page: 25, link: "https://i.ibb.co/TYzqfDq/0062-025.webp" },
    { page: 26, link: "https://i.ibb.co/VYSycyK/0062-026.webp" },
    { page: 27, link: "https://i.ibb.co/J78vzfh/0062-027.webp" },
    { page: 28, link: "https://i.ibb.co/pnxzyWk/0062-028.webp" },
    { page: 29, link: "https://i.ibb.co/WKz7j8c/0062-029.webp" },
    { page: 30, link: "https://i.ibb.co/pr6xwC1/0062-030.webp" }
  ]
  

export default listePages;