const listePages = [
    { page: 1, link: "https://i.ibb.co/MnTQrfJ/0077-001.webp" },
    { page: 2, link: "https://i.ibb.co/T8ghr9B/0077-002.webp" },
    { page: 3, link: "https://i.ibb.co/Dtz15Y0/0077-003.webp" },
    { page: 4, link: "https://i.ibb.co/MZQqmMW/0077-004.webp" },
    { page: 5, link: "https://i.ibb.co/gZV3CsQ/0077-005.webp" },
    { page: 6, link: "https://i.ibb.co/hLnVC3F/0077-006.webp" },
    { page: 7, link: "https://i.ibb.co/q7SPWyT/0077-007.webp" },
    { page: 8, link: "https://i.ibb.co/2tgmXwc/0077-008.webp" },
    { page: 9, link: "https://i.ibb.co/FKp9w8s/0077-009.webp" },
    { page: 10, link: "https://i.ibb.co/6W3nFJL/0077-010.webp" },
    { page: 11, link: "https://i.ibb.co/3F7Nq4Z/0077-011.webp" },
    { page: 12, link: "https://i.ibb.co/3BS2vDK/0077-012.webp" },
    { page: 13, link: "https://i.ibb.co/7CJkVXr/0077-013.webp" },
    { page: 14, link: "https://i.ibb.co/9p40FRs/0077-014.webp" },
    { page: 15, link: "https://i.ibb.co/ss98GpS/0077-015.webp" },
    { page: 16, link: "https://i.ibb.co/6rn89wW/0077-016.webp" },
    { page: 17, link: "https://i.ibb.co/FkvtyJQ/0077-017.webp" },
    { page: 18, link: "https://i.ibb.co/p4tKdT4/0077-018.webp" },
    { page: 19, link: "https://i.ibb.co/X54GgZK/0077-019.webp" },
    { page: 20, link: "https://i.ibb.co/ZShgGGv/0077-020.webp" },
    { page: 21, link: "https://i.ibb.co/LhgTsFZ/0077-021.webp" },
    { page: 22, link: "https://i.ibb.co/886nxyN/0077-022.webp" },
    { page: 23, link: "https://i.ibb.co/yyX5cqX/0077-023.webp" },
    { page: 24, link: "https://i.ibb.co/RPDZhM8/0077-024.webp" },
    { page: 25, link: "https://i.ibb.co/mC6JjnF/0077-025.webp" },
    { page: 26, link: "https://i.ibb.co/kXTnbJ6/0077-026.webp" },
    { page: 27, link: "https://i.ibb.co/YtGTc5r/0077-027.webp" },
    { page: 28, link: "https://i.ibb.co/HGyt1ZD/0077-028.webp" }
  ]
  

export default listePages;