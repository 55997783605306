const listeEpisodes = [
    {
        episode: 1,
        link: "https://video.sibnet.ru/shell.php?videoid=5343879"
    },
    {
        episode: 2,
        link: "https://video.sibnet.ru/shell.php?videoid=5343961"
    },
    {
        episode: 3,
        link: "https://video.sibnet.ru/shell.php?videoid=5344038"
    },
    {
        episode: 4,
        link: "https://video.sibnet.ru/shell.php?videoid=5344082"
    },
    {
        episode: 5,
        link: "https://video.sibnet.ru/shell.php?videoid=5344174"
    },
    {
        episode: 6,
        link: "https://video.sibnet.ru/shell.php?videoid=5344224"
    },
    {
        episode: 7,
        link: "https://video.sibnet.ru/shell.php?videoid=5344253"
    },
    {
        episode: 8,
        link: "https://video.sibnet.ru/shell.php?videoid=5344279"
    },
    {
        episode: 9,
        link: "https://video.sibnet.ru/shell.php?videoid=5344307"
    },
    {
        episode: 10,
        link: "https://video.sibnet.ru/shell.php?videoid=5344346"
    },
    {
        episode: 11,
        link: "https://video.sibnet.ru/shell.php?videoid=5344420"
    },
    {
        episode: 12,
        link: "https://video.sibnet.ru/shell.php?videoid=5344485"
    },
    {
        episode: 13,
        link: "https://video.sibnet.ru/shell.php?videoid=5344539"
    },
    {
        episode: 14,
        link: "https://video.sibnet.ru/shell.php?videoid=5344579"
    },
    {
        episode: 15,
        link: "https://video.sibnet.ru/shell.php?videoid=5344600"
    },
    {
        episode: 16,
        link: "https://video.sibnet.ru/shell.php?videoid=5344615"
    },
    {
        episode: 17,
        link: "https://video.sibnet.ru/shell.php?videoid=5344625"
    },
    {
        episode: 18,
        link: "https://video.sibnet.ru/shell.php?videoid=5344634"
    },
    {
        episode: 19,
        link: "https://video.sibnet.ru/shell.php?videoid=5344645"
    },
    {
        episode: 20,
        link: "https://video.sibnet.ru/shell.php?videoid=5389457"
    },
    {
        episode: 21,
        link: "https://video.sibnet.ru/shell.php?videoid=5344665"
    },
    {
        episode: 22,
        link: "https://video.sibnet.ru/shell.php?videoid=5344674"
    },
    {
        episode: 23,
        link: "https://video.sibnet.ru/shell.php?videoid=5344690"
    },
    {
        episode: 24,
        link: "https://video.sibnet.ru/shell.php?videoid=5344719"
    },
    {
        episode: 25,
        link: "https://video.sibnet.ru/shell.php?videoid=5344739"
    },
    {
        episode: 26,
        link: "https://video.sibnet.ru/shell.php?videoid=5344755"
    },
    {
        special: 1,
        episode: 27,
        link: "https://video.sibnet.ru/shell.php?videoid=5344780"
    },
    {
        special: 2,
        episode: 28,
        link: "https://video.sibnet.ru/shell.php?videoid=5344803"
    }
]

export default listeEpisodes;