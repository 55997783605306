const listePages = [
    {
        "page": 1,
        "link": "https://i.ibb.co/2jkdh1m/0030.webp"
      },
      {
        "page": 2,
        "link": "https://i.ibb.co/thdYVDb/0031.webp"
      },
      {
        "page": 3,
        "link": "https://i.ibb.co/dK0wjBf/0032.webp"
      },
      {
        "page": 4,
        "link": "https://i.ibb.co/7JZbJHQ/0033.webp"
      },
      {
        "page": 5,
        "link": "https://i.ibb.co/yY4SqJV/0034.webp"
      },
      {
        "page": 6,
        "link": "https://i.ibb.co/j80193J/0035.webp"
      },
      {
        "page": 7,
        "link": "https://i.ibb.co/yB3sXpB/0036.webp"
      },
      {
        "page": 8,
        "link": "https://i.ibb.co/yqFycYn/0037.webp"
      },
      {
        "page": 9,
        "link": "https://i.ibb.co/vVYGW9t/0038.webp"
      },
      {
        "page": 10,
        "link": "https://i.ibb.co/HVXj10x/0039.webp"
      },
      {
        "page": 11,
        "link": "https://i.ibb.co/yXWQdFT/0040.webp"
      },
      {
        "page": 12,
        "link": "https://i.ibb.co/n0KDpxN/0041.webp"
      },
      {
        "page": 13,
        "link": "https://i.ibb.co/bNT8R3j/0042.webp"
      },
      {
        "page": 14,
        "link": "https://i.ibb.co/XsfvB6v/0043.webp"
      },
      {
        "page": 15,
        "link": "https://i.ibb.co/BnT1vMz/0044.webp"
      },
      {
        "page": 16,
        "link": "https://i.ibb.co/SstPFnN/0045.webp"
      },
      {
        "page": 17,
        "link": "https://i.ibb.co/RzPbfrt/0046.webp"
      },
      {
        "page": 18,
        "link": "https://i.ibb.co/Qp5FXYQ/0047.webp"
      },
      {
        "page": 19,
        "link": "https://i.ibb.co/60FytrK/0048.webp"
      },
      {
        "page": 20,
        "link": "https://i.ibb.co/b3tM6Hr/0049.webp"
      },
      {
        "page": 21,
        "link": "https://i.ibb.co/TMwCyn2/0050.webp"
      },
      {
        "page": 22,
        "link": "https://i.ibb.co/wCbH3Zs/0051.webp"
      },
      {
        "page": 23,
        "link": "https://i.ibb.co/RTYcdXT/0052.webp"
      },
      {
        "page": 24,
        "link": "https://i.ibb.co/thTg0Fk/0053.webp"
      },
      {
        "page": 25,
        "link": "https://i.ibb.co/bLDMZwD/0054.webp"
      },
      {
        "page": 26,
        "link": "https://i.ibb.co/tYqJvxt/0055.webp"
      },
      {
        "page": 27,
        "link": "https://i.ibb.co/FhKWT5L/0056.webp"
      },
      {
        "page": 28,
        "link": "https://i.ibb.co/1616Cm6/0057.webp"
      },
      {
        "page": 29,
        "link": "https://i.ibb.co/MS8F27B/0058.webp"
      },
      {
        "page": 30,
        "link": "https://i.ibb.co/Cw22sZW/0059.webp"
      },
      {
        "page": 31,
        "link": "https://i.ibb.co/zGDKbgF/0060.webp"
      },
      {
        "page": 32,
        "link": "https://i.ibb.co/BTKZgXq/0061.webp"
      },
      {
        "page": 33,
        "link": "https://i.ibb.co/pfBp409/0062.webp"
      },
      {
        "page": 34,
        "link": "https://i.ibb.co/YytsdPs/0063.webp"
      },
      {
        "page": 35,
        "link": "https://i.ibb.co/3WN99fB/0064.webp"
      },
      {
        "page": 36,
        "link": "https://i.ibb.co/H7cNg3S/0065.webp"
      },
      {
        "page": 37,
        "link": "https://i.ibb.co/HNJ4Wx9/0066.webp"
      },
      {
        "page": 38,
        "link": "https://i.ibb.co/7nscwBs/0067.webp"
      },
      {
        "page": 39,
        "link": "https://i.ibb.co/wLmqHQ1/0068.webp"
      },
      {
        "page": 40,
        "link": "https://i.ibb.co/cL6xzvw/0069.webp"
      },
      {
        "page": 41,
        "link": "https://i.ibb.co/kK53zgw/0070.webp"
      },
      {
        "page": 42,
        "link": "https://i.ibb.co/V3D62SY/0071.webp"
      },
      {
        "page": 43,
        "link": "https://i.ibb.co/ZKLbz0s/0072.webp"
      },
      {
        "page": 44,
        "link": "https://i.ibb.co/3Tn9R77/0073.webp"
      }
]

export default listePages;