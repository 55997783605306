const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/cTKxyDD/0047-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/qDxBBp7/0047-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/F6P0y1k/0047-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/9N4V0Xn/0047-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/WF4PPvT/0047-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/t2LJ1qF/0047-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/dMTWygL/0047-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/h8yqKGx/0047-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/d62SZDt/0047-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/XXsgSPm/0047-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/vZTWh4W/0047-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/XtFLLwG/0047-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/G2QkxNV/0047-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/RpwNVhD/0047-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/fQ9CmMC/0047-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/ysYPLRb/0047-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/Dz4PNtS/0047-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/kDxJs1R/0047-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/m9WBMs9/0047-019.webp"
  },
  {
    page: 20,
    link: "https://i.ibb.co/23pX5Z6/0047-020.webp"
  },
  {
    page: 21,
    link: "https://i.ibb.co/HV52X66/0047-021.webp"
  },
  {
    page: 22,
    link: "https://i.ibb.co/sF3MxPX/0047-022.webp"
  },
  {
    page: 23,
    link: "https://i.ibb.co/6w5GwWw/0047-023.webp"
  },
  {
    page: 24,
    link: "https://i.ibb.co/09zxcmg/0047-024.webp"
  },
  {
    page: 25,
    link: "https://i.ibb.co/DD284sL/0047-025.webp"
  },
  {
    page: 26,
    link: "https://i.ibb.co/qyf2KSQ/0047-026.webp"
  },
  {
    page: 27,
    link: "https://i.ibb.co/QXRzYZH/0047-027.webp"
  },
  {
    page: 28,
    link: "https://i.ibb.co/Xxw1F2H/0047-028.webp"
  },
  {
    page: 29,
    link: "https://i.ibb.co/3hSPkRT/0047-029.webp"
  },
  {
    page: 30,
    link: "https://i.ibb.co/6J5Td62/0047-030.webp"
  },
  {
    page: 31,
    link: "https://i.ibb.co/YkwgM9f/0047-031.webp"
  },
  {
    page: 32,
    link: "https://i.ibb.co/m60VX8r/0047-032.webp"
  },
  {
    page: 33,
    link: "https://i.ibb.co/KV4kRCM/0047-033.webp"
  },
  {
    page: 34,
    link: "https://i.ibb.co/TmFZDh4/0047-034.webp"
  }
];


export default listePages;