const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/Y86rnHK/0012-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/F4BNLwZ/0012-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/6P4LhRd/0012-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/pRscRjv/0012-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/61MQVmd/0012-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/Q9KbLqJ/0012-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/Lxkp0Ps/0012-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/kMLFNV0/0012-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/NydrHdn/0012-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/3dHfCJ2/0012-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/pb9YN7q/0012-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/fXr898W/0012-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/znTQZKD/0012-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/RTjhhd4/0012-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/VNPY0W3/0012-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/vkK4QLP/0012-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/X341nPP/0012-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/nBfVFKC/0012-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/RzqQkYV/0012-019.webp"
  },
  {
    page: 20,
    link: "https://i.ibb.co/4T3pJXh/0012-020.webp"
  },
  {
    page: 21,
    link: "https://i.ibb.co/pjGqFYC/0012-021.webp"
  },
  {
    page: 22,
    link: "https://i.ibb.co/7nfcwSv/0012-022.webp"
  },
  {
    page: 23,
    link: "https://i.ibb.co/HtRwCtF/0012-023.webp"
  },
  {
    page: 24,
    link: "https://i.ibb.co/KG2f7Hq/0012-024.webp"
  },
  {
    page: 25,
    link: "https://i.ibb.co/KFR28Gb/0012-025.webp"
  },
  {
    page: 26,
    link: "https://i.ibb.co/M8Tz0Dc/0012-026.webp"
  },
  {
    page: 27,
    link: "https://i.ibb.co/1R3S4nr/0012-027.webp"
  },
  {
    page: 28,
    link: "https://i.ibb.co/3FzYBdH/0012-028.webp"
  },
  {
    page: 29,
    link: "https://i.ibb.co/873zFJz/0012-029.webp"
  },
  {
    page: 30,
    link: "https://i.ibb.co/wdD6vsQ/0012-030.webp"
  },
  {
    page: 31,
    link: "https://i.ibb.co/zr7ZsXp/0012-031.webp"
  },
  {
    page: 32,
    link: "https://i.ibb.co/fNDqYMX/0012-032.webp"
  },
  {
    page: 33,
    link: "https://i.ibb.co/hHxpBh0/0012-033.webp"
  },
  {
    page: 34,
    link: "https://i.ibb.co/7pnF4qw/0012-034.webp"
  },
  {
    page: 35,
    link: "https://i.ibb.co/C9rHWw9/0012-035.webp"
  },
  {
    page: 36,
    link: "https://i.ibb.co/bzLf6Gp/0012-036.webp"
  },
  {
    page: 37,
    link: "https://i.ibb.co/Yy6Thtc/0012-037.webp"
  },
  {
    page: 38,
    link: "https://i.ibb.co/kxJZsQ9/0012-038.webp"
  },
  {
    page: 39,
    link: "https://i.ibb.co/Dg2DJw0/0012-039.webp"
  }  
]

export default listePages;