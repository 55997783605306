const listePages = [
    { page: 1, link: "https://i.ibb.co/TTx4mR7/01.webp" },
    { page: 2, link: "https://i.ibb.co/Jmj12N8/02.webp" },
    { page: 3, link: "https://i.ibb.co/FYszQpK/03.webp" },
    { page: 4, link: "https://i.ibb.co/m00CBNS/04.webp" },
    { page: 5, link: "https://i.ibb.co/pnsZGWJ/05.webp" },
    { page: 6, link: "https://i.ibb.co/wJgLbrg/06.webp" },
    { page: 7, link: "https://i.ibb.co/CW96Js6/07.webp" },
    { page: 8, link: "https://i.ibb.co/Kh8nQz9/08.webp" },
    { page: 9, link: "https://i.ibb.co/fHd8XxG/09.webp" },
    { page: 10, link: "https://i.ibb.co/8nwszTf/10.webp" },
    { page: 11, link: "https://i.ibb.co/xMLzzPW/11.webp" },
    { page: 12, link: "https://i.ibb.co/Bn4s5Kc/12.webp" },
    { page: 13, link: "https://i.ibb.co/p6q3HD8/13.webp" },
    { page: 14, link: "https://i.ibb.co/64j3304/14.webp" },
    { page: 15, link: "https://i.ibb.co/bR9n0HJ/15.webp" },
    { page: 16, link: "https://i.ibb.co/F4nb9cJ/16.webp" },
    { page: 17, link: "https://i.ibb.co/mGMK00m/17.webp" },
    { page: 18, link: "https://i.ibb.co/zQgSgG0/18.webp" },
    { page: 19, link: "https://i.ibb.co/kckYkm8/19.webp" },
    { page: 20, link: "https://i.ibb.co/whRvWT9/20.webp" },
    { page: 21, link: "https://i.ibb.co/jL9n87p/21.webp" },
    { page: 22, link: "https://i.ibb.co/hchvkhY/22.webp" },
    { page: 23, link: "https://i.ibb.co/YjM4rtw/23.webp" },
    { page: 24, link: "https://i.ibb.co/xHswT0K/24.webp" },
    { page: 25, link: "https://i.ibb.co/s2zbqj2/25.webp" },
    { page: 26, link: "https://i.ibb.co/Wghv3CL/26.webp" },
    { page: 27, link: "https://i.ibb.co/nfbtwLs/27.webp" },
    { page: 28, link: "https://i.ibb.co/wRkPtS8/28.webp" },
    { page: 29, link: "https://i.ibb.co/wK4K2MB/29.webp" },
    { page: 30, link: "https://i.ibb.co/vZvyKxp/30.webp" },
    { page: 31, link: "https://i.ibb.co/g3ZzGH6/31.webp" },
    { page: 32, link: "https://i.ibb.co/6Jhcs3C/32.webp" }
  ]
  

export default listePages;