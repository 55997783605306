const listePages = [
    { page: 1, link: "https://i.ibb.co/7bjT3K3/0075-001.webp" },
    { page: 2, link: "https://i.ibb.co/f0Rbqvq/0075-002.webp" },
    { page: 3, link: "https://i.ibb.co/82LKG4q/0075-003.webp" },
    { page: 4, link: "https://i.ibb.co/pbrtx6G/0075-004.webp" },
    { page: 5, link: "https://i.ibb.co/phHsy3k/0075-005.webp" },
    { page: 6, link: "https://i.ibb.co/DQRq1XK/0075-006.webp" },
    { page: 7, link: "https://i.ibb.co/YhgLxvF/0075-007.webp" },
    { page: 8, link: "https://i.ibb.co/chKmpPq/0075-008.webp" },
    { page: 9, link: "https://i.ibb.co/7KBPYZK/0075-009.webp" },
    { page: 10, link: "https://i.ibb.co/3ScVK4W/0075-010.webp" },
    { page: 11, link: "https://i.ibb.co/17zmgVj/0075-011.webp" },
    { page: 12, link: "https://i.ibb.co/cX4DLH9/0075-012.webp" },
    { page: 13, link: "https://i.ibb.co/PGJvPn3/0075-013.webp" },
    { page: 14, link: "https://i.ibb.co/SrjcXfd/0075-014.webp" },
    { page: 15, link: "https://i.ibb.co/cwXxkQb/0075-015.webp" },
    { page: 16, link: "https://i.ibb.co/nLvLC8B/0075-016.webp" },
    { page: 17, link: "https://i.ibb.co/8B0188s/0075-017.webp" },
    { page: 18, link: "https://i.ibb.co/ydMcqSM/0075-018.webp" },
    { page: 19, link: "https://i.ibb.co/gRJNt03/0075-019.webp" },
    { page: 20, link: "https://i.ibb.co/yRHFD2p/0075-020.webp" },
    { page: 21, link: "https://i.ibb.co/P628fx4/0075-021.webp" },
    { page: 22, link: "https://i.ibb.co/J2D9P1y/0075-022.webp" },
    { page: 23, link: "https://i.ibb.co/Vqp4SD0/0075-023.webp" },
    { page: 24, link: "https://i.ibb.co/YPnt3tS/0075-024.webp" },
    { page: 25, link: "https://i.ibb.co/vQg34HY/0075-025.webp" },
    { page: 26, link: "https://i.ibb.co/j5xFk66/0075-026.webp" },
    { page: 27, link: "https://i.ibb.co/MGhHjGp/0075-027.webp" },
    { page: 28, link: "https://i.ibb.co/3cCKLbk/0075-028.webp" },
    { page: 29, link: "https://i.ibb.co/YWgfmhy/0075-029.webp" },
    { page: 30, link: "https://i.ibb.co/N1t1BD2/0075-030.webp" }
  ]
  

export default listePages;