const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/wN2tMCk/0013-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/ggydcPJ/0013-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/pbqTDX9/0013-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/jk4SZT8/0013-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/ck0p3N0/0013-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/56FckkR/0013-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/THcY8vC/0013-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/N799dFj/0013-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/ZSmGhqR/0013-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/ZYFzNpb/0013-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/kg717y0/0013-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/mcqrxZr/0013-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/vJN2HnM/0013-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/grwvcFg/0013-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/mDFbkmN/0013-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/X4ZfWqh/0013-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/VvmjD7Z/0013-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/sy2CP6D/0013-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/Yh5MYph/0013-019.webp"
  },
  {
    page: 20,
    link: "https://i.ibb.co/z4rd251/0013-020.webp"
  },
  {
    page: 21,
    link: "https://i.ibb.co/TBsWhPv/0013-021.webp"
  },
  {
    page: 22,
    link: "https://i.ibb.co/gdM419m/0013-022.webp"
  },
  {
    page: 23,
    link: "https://i.ibb.co/5GcftQG/0013-023.webp"
  },
  {
    page: 24,
    link: "https://i.ibb.co/VCL8KRC/0013-024.webp"
  },
  {
    page: 25,
    link: "https://i.ibb.co/YRRHDK0/0013-025.webp"
  },
  {
    page: 26,
    link: "https://i.ibb.co/V3MbcXV/0013-026.webp"
  },
  {
    page: 27,
    link: "https://i.ibb.co/6BzB4WM/0013-027.webp"
  },
  {
    page: 28,
    link: "https://i.ibb.co/F3FGG3t/0013-028.webp"
  },
  {
    page: 29,
    link: "https://i.ibb.co/yRZfnCt/0013-029.webp"
  },
  {
    page: 30,
    link: "https://i.ibb.co/5YYB1Xs/0013-030.webp"
  },
  {
    page: 31,
    link: "https://i.ibb.co/89LqFfB/0013-031.webp"
  },
  {
    page: 32,
    link: "https://i.ibb.co/7NGfD0Q/0013-032.webp"
  },
  {
    page: 33,
    link: "https://i.ibb.co/y6QKRgf/0013-033.webp"
  },
  {
    page: 34,
    link: "https://i.ibb.co/qr75pSc/0013-034.webp"
  },
  {
    page: 35,
    link: "https://i.ibb.co/jT7yBmw/0013-035.webp"
  },
  {
    page: 36,
    link: "https://i.ibb.co/yQc01L0/0013-036.webp"
  },
  {
    page: 37,
    link: "https://i.ibb.co/jL8gm8M/0013-037.webp"
  },
  {
    page: 38,
    link: "https://i.ibb.co/VtcfXKJ/0013-038.webp"
  },
  {
    page: 39,
    link: "https://i.ibb.co/DtFGpD9/0013-039.webp"
  },
  {
    page: 40,
    link: "https://i.ibb.co/tmWg2Zq/0013-040.webp"
  }  
]

export default listePages;