const listePages = [
    { page: 1, link: "https://i.ibb.co/Pc5qVrB/0060-001.webp" },
    { page: 2, link: "https://i.ibb.co/Kxy7LtH/0060-002.webp" },
    { page: 3, link: "https://i.ibb.co/dtMRwWk/0060-003.webp" },
    { page: 4, link: "https://i.ibb.co/b16Ttzg/0060-004.webp" },
    { page: 5, link: "https://i.ibb.co/BzvJ0dk/0060-005.webp" },
    { page: 6, link: "https://i.ibb.co/5v3CyNc/0060-006.webp" },
    { page: 7, link: "https://i.ibb.co/rb0VVGB/0060-007.webp" },
    { page: 8, link: "https://i.ibb.co/gRvXYKR/0060-008.webp" },
    { page: 9, link: "https://i.ibb.co/F8ySgnF/0060-009.webp" },
    { page: 10, link: "https://i.ibb.co/6wP0WTT/0060-010.webp" },
    { page: 11, link: "https://i.ibb.co/4P27vYF/0060-011.webp" },
    { page: 12, link: "https://i.ibb.co/bvj3bLx/0060-012.webp" },
    { page: 13, link: "https://i.ibb.co/tCN3K2B/0060-013.webp" },
    { page: 14, link: "https://i.ibb.co/8gJBsxY/0060-014.webp" },
    { page: 15, link: "https://i.ibb.co/1LT5dyR/0060-015.webp" },
    { page: 16, link: "https://i.ibb.co/rmtHWph/0060-016.webp" }
  ]
  

export default listePages;