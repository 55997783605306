const listePages = [
    {
        page: 1,
        link: "https://i.ibb.co/44pMK9X/0001.webp"
      },
      {
        page: 2,
        link: "https://i.ibb.co/2vXcCR3/0005.webp"
      },
      {
        page: 3,
        link: "https://i.ibb.co/Y0WBhRB/0006.webp"
      },
      {
        page: 4,
        link: "https://i.ibb.co/d5FR846/0007.webp"
      },
      {
        page: 5,
        link: "https://i.ibb.co/hYtNZT8/0008.webp"
      },
      {
        page: 6,
        link: "https://i.ibb.co/tZBK5LC/0009.webp"
      },
      {
        page: 7,
        link: "https://i.ibb.co/ZB1ps1P/0010.webp"
      },
      {
        page: 8,
        link: "https://i.ibb.co/f8WvQv8/0011.webp"
      },
      {
        page: 9,
        link: "https://i.ibb.co/fnsrH1V/0012.webp"
      },
      {
        page: 10,
        link: "https://i.ibb.co/Bc1WXPZ/0013.webp"
      },
      {
        page: 11,
        link: "https://i.ibb.co/JQsk0Bn/0014.webp"
      },
      {
        page: 12,
        link: "https://i.ibb.co/wLynct8/0015.webp"
      },
      {
        page: 13,
        link: "https://i.ibb.co/9GKMrDv/0016.webp"
      },
      {
        page: 14,
        link: "https://i.ibb.co/djbwF00/0017.webp"
      },
      {
        page: 15,
        link: "https://i.ibb.co/qnFr7Ts/0018.webp"
      },
      {
        page: 16,
        link: "https://i.ibb.co/LzL7VcM/0019.webp"
      },
      {
        page: 17,
        link: "https://i.ibb.co/7CTbkMv/0020.webp"
      },
      {
        page: 18,
        link: "https://i.ibb.co/cgFHxHd/0021.webp"
      },
      {
        page: 19,
        link: "https://i.ibb.co/gjQ6c0c/0022.webp"
      },
      {
        page: 20,
        link: "https://i.ibb.co/4YZht66/0023.webp"
      },
      {
        page: 21,
        link: "https://i.ibb.co/kc90wWm/0024.webp"
      },
      {
        page: 22,
        link: "https://i.ibb.co/XWJbcqt/0025.webp"
      },
      {
        page: 23,
        link: "https://i.ibb.co/7Sq9S5n/0026.webp"
      },
      {
        page: 24,
        link: "https://i.ibb.co/xmWj2qr/0027.webp"
      },
      {
        page: 25,
        link: "https://i.ibb.co/XpL162v/0028.webp"
      },
      {
        page: 26,
        link: "https://i.ibb.co/MBqy7GR/0029.webp"
      },
      {
        page: 27,
        link: "https://i.ibb.co/ccZWbJ5/0030.webp"
      },
      {
        page: 28,
        link: "https://i.ibb.co/bP6Gp6T/0031.webp"
      },
      {
        page: 29,
        link: "https://i.ibb.co/KWmbw3X/0032.webp"
      },
      {
        page: 30,
        link: "https://i.ibb.co/tXnB7tb/0033.webp"
      },
      {
        page: 31,
        link: "https://i.ibb.co/v17ZmmX/0034.webp"
      },
      {
        page: 32,
        link: "https://i.ibb.co/gjS1sk6/0035.webp"
      },
      {
        page: 33,
        link: "https://i.ibb.co/LnYx7Pt/0036.webp"
      },
      {
        page: 34,
        link: "https://i.ibb.co/LRj2gmJ/0037.webp"
      },
      {
        page: 35,
        link: "https://i.ibb.co/RPrdngh/0038.webp"
      },
      {
        page: 36,
        link: "https://i.ibb.co/41P3R8g/0039.webp"
      },
      {
        page: 37,
        link: "https://i.ibb.co/Gn0nZCh/0040.webp"
      },
      {
        page: 38,
        link: "https://i.ibb.co/gvjBhnK/0041.webp"
      },
      {
        page: 39,
        link: "https://i.ibb.co/zrcdDWX/0042.webp"
      },
      {
        page: 40,
        link: "https://i.ibb.co/v1TCBXR/0043.webp"
      },
      {
        page: 41,
        link: "https://i.ibb.co/jrM02sR/0044.webp"
      },
      {
        page: 42,
        link: "https://i.ibb.co/SPWtjkD/0045.webp"
      },
      {
        page: 43,
        link: "https://i.ibb.co/f1xqrSK/0046.webp"
      },
      {
        page: 44,
        link: "https://i.ibb.co/0Q2z70V/0047.webp"
      },
      {
        page: 45,
        link: "https://i.ibb.co/Mns9vKH/0048.webp"
      },
      {
        page: 46,
        link: "https://i.ibb.co/ckpKW0f/0049.webp"
      },
      {
        page: 47,
        link: "https://i.ibb.co/LgsRH6b/0050.webp"
      },
      {
        page: 48,
        link: "https://i.ibb.co/M17YrX7/0051.webp"
      },
      {
        page: 49,
        link: "https://i.ibb.co/xqjQ3SV/0052.webp"
      },
      {
        page: 50,
        link: "https://i.ibb.co/0rg4Wzm/0053.webp"
      },
      {
        page: 51,
        link: "https://i.ibb.co/NCh9fhB/0054.webp"
      },
      {
        page: 52,
        link: "https://i.ibb.co/GC6ndv9/0055.webp"
      },
      {
        page: 53,
        link: "https://i.ibb.co/FW0XZMc/0056.webp"
      }
]


export default listePages;