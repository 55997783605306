const listePages = [
	{ page: 1, link: 'https://i.ibb.co/hFmqVcV/001.webp' },
	{ page: 2, link: 'https://i.ibb.co/kqSRc2n/002.webp' },
	{ page: 3, link: 'https://i.ibb.co/V9Nfvtv/003.webp' },
	{ page: 4, link: 'https://i.ibb.co/h1kbT4N/004.webp' },
	{ page: 5, link: 'https://i.ibb.co/ZfCR00h/005.webp' },
	{ page: 6, link: 'https://i.ibb.co/9rkQzYJ/006.webp' },
	{ page: 7, link: 'https://i.ibb.co/6yvQ4Q8/007.webp' },
	{ page: 8, link: 'https://i.ibb.co/8sYGDsz/008.webp' },
	{ page: 9, link: 'https://i.ibb.co/m5hrLS1/009.webp' },
	{ page: 10, link: 'https://i.ibb.co/VqCY469/010.webp' },
	{ page: 11, link: 'https://i.ibb.co/vd1hYZ0/011.webp' },
	{ page: 12, link: 'https://i.ibb.co/kmwy9KV/012.webp' },
	{ page: 13, link: 'https://i.ibb.co/y5LhVzS/013.webp' },
	{ page: 14, link: 'https://i.ibb.co/Gp91zLf/014.webp' },
	{ page: 15, link: 'https://i.ibb.co/56x68qP/015.webp' },
	{ page: 16, link: 'https://i.ibb.co/7k108yq/016.webp' },
	{ page: 17, link: 'https://i.ibb.co/Nj1Tc2L/017.webp' },
	{ page: 18, link: 'https://i.ibb.co/F8Gsr91/018.webp' },
	{ page: 19, link: 'https://i.ibb.co/p0qJpD6/019.webp' },
	{ page: 20, link: 'https://i.ibb.co/dL2fwgx/020.webp' },
	{ page: 21, link: 'https://i.ibb.co/6J509xq/021.webp' },
	{ page: 22, link: 'https://i.ibb.co/8xsy1Sp/022.webp' },
	{ page: 23, link: 'https://i.ibb.co/S6wfKhx/023.webp' },
	{ page: 24, link: 'https://i.ibb.co/vXYy7pd/024.webp' },
	{ page: 25, link: 'https://i.ibb.co/nsWPc8t/025.webp' },
	{ page: 26, link: 'https://i.ibb.co/hHDpKY6/026.webp' },
	{ page: 27, link: 'https://i.ibb.co/Mp6Jgd2/027.webp' },
	{ page: 28, link: 'https://i.ibb.co/RTBv9YJ/028.webp' },
	{ page: 29, link: 'https://i.ibb.co/8mqqytp/029.webp' },
	{ page: 30, link: 'https://i.ibb.co/6F2GFwm/030.webp' },
	{ page: 31, link: 'https://i.ibb.co/kgvyGjK/031.webp' },
	{ page: 32, link: 'https://i.ibb.co/jZPb1L0/032.webp' },
	{ page: 33, link: 'https://i.ibb.co/TqgBH47/033.webp' },
	{ page: 34, link: 'https://i.ibb.co/M9sW0PR/034.webp' },
	{ page: 35, link: 'https://i.ibb.co/b7f01Vb/035.webp' },
	{ page: 36, link: 'https://i.ibb.co/Zf2dFm1/036.webp' },
	{ page: 37, link: 'https://i.ibb.co/RBdgm8s/037.webp' },
	{ page: 38, link: 'https://i.ibb.co/ckS5VCz/038.webp' },
	{ page: 39, link: 'https://i.ibb.co/CMbsm1G/039.webp' },
	{ page: 40, link: 'https://i.ibb.co/7Qkvz7K/040.webp' },
	{ page: 41, link: 'https://i.ibb.co/chJxSfh/041.webp' },
	{ page: 42, link: 'https://i.ibb.co/JsLP1mX/042.webp' },
	{ page: 43, link: 'https://i.ibb.co/yWtJgf3/043.webp' },
	{ page: 44, link: 'https://i.ibb.co/ckvGYTS/044-045.webp' },
	{ page: 45, link: 'https://i.ibb.co/T8knFXD/046.webp' },
	{ page: 46, link: 'https://i.ibb.co/vqGtLHN/047.webp' },
	{ page: 47, link: 'https://i.ibb.co/mvmZwv8/048.webp' },
	{ page: 48, link: 'https://i.ibb.co/rtXFRCf/049.webp' },
	{ page: 49, link: 'https://i.ibb.co/rMLmXzD/050.webp' },
	{ page: 50, link: 'https://i.ibb.co/C5hP8DN/051.webp' },
	{ page: 51, link: 'https://i.ibb.co/WyTwSyL/052.webp' },
	{ page: 52, link: 'https://i.ibb.co/r2QwBNh/053.webp' },
	{ page: 53, link: 'https://i.ibb.co/cYqyRHJ/054.webp' },
	{ page: 54, link: 'https://i.ibb.co/2vS3KLN/055.webp' },
	{ page: 55, link: 'https://i.ibb.co/DkJnypT/056.webp' }
]

export default listePages;