const listePages = [
    {
      page: 1,
      link: "https://i.ibb.co/1QK3Yqt/0108-001.webp"
    },
    {
      page: 2,
      link: "https://i.ibb.co/GMF3Fb6/0108-002.webp"
    },
    {
      page: 3,
      link: "https://i.ibb.co/T4kcLg6/0108-003.webp"
    },
    {
      page: 4,
      link: "https://i.ibb.co/tPPGc9d/0108-004.webp"
    },
    {
      page: 5,
      link: "https://i.ibb.co/4tL7C71/0108-005.webp"
    },
    {
      page: 6,
      link: "https://i.ibb.co/dWFmHX8/0108-006.webp"
    },
    {
      page: 7,
      link: "https://i.ibb.co/GkJSR6H/0108-007.webp"
    },
    {
      page: 8,
      link: "https://i.ibb.co/BPDyTqK/0108-008.webp"
    },
    {
      page: 9,
      link: "https://i.ibb.co/LpVT664/0108-009.webp"
    },
    {
      page: 10,
      link: "https://i.ibb.co/s91kvnH/0108-010.webp"
    },
    {
      page: 11,
      link: "https://i.ibb.co/HC2PLRk/0108-011.webp"
    },
    {
      page: 12,
      link: "https://i.ibb.co/fdJ6Qw2/0108-012.webp"
    },
    {
      page: 13,
      link: "https://i.ibb.co/zZNc0sH/0108-013.webp"
    },
    {
      page: 14,
      link: "https://i.ibb.co/R00pBGn/0108-014.webp"
    },
    {
      page: 15,
      link: "https://i.ibb.co/6tPRkDH/0108-015.webp"
    },
    {
      page: 16,
      link: "https://i.ibb.co/bvYpqsd/0108-016.webp"
    },
    {
      page: 17,
      link: "https://i.ibb.co/yhwBSMf/0108-017.webp"
    },
    {
      page: 18,
      link: "https://i.ibb.co/nzrs9hn/0108-018.webp"
    },
    {
      page: 19,
      link: "https://i.ibb.co/Fbntsqb/0108-019.webp"
    },
    {
      page: 20,
      link: "https://i.ibb.co/K0wH8Fz/0108-020.webp"
    },
    {
      page: 21,
      link: "https://i.ibb.co/drkTPNP/0108-021.webp"
    },
    {
      page: 22,
      link: "https://i.ibb.co/2YyC0YH/0108-022.webp"
    },
    {
      page: 23,
      link: "https://i.ibb.co/dp6RxsZ/0108-023.webp"
    },
    {
      page: 24,
      link: "https://i.ibb.co/gSLtd9n/0108-024.webp"
    },
    {
      page: 25,
      link: "https://i.ibb.co/gtFydfW/0108-025.webp"
    },
    {
      page: 26,
      link: "https://i.ibb.co/3TXrpKQ/0108-026.webp"
    },
    {
      page: 27,
      link: "https://i.ibb.co/h25GY6P/0108-027.webp"
    },
    {
      page: 28,
      link: "https://i.ibb.co/NSfDKv9/0108-028.webp"
    },
    {
      page: 29,
      link: "https://i.ibb.co/pvsvxVP/0108-029.webp"
    },
    {
      page: 30,
      link: "https://i.ibb.co/GPJcgd3/0108-030.webp"
    }
  ]
  

export default listePages;