const listePages = [
    {
      "page": 1,
      "link": "https://i.ibb.co/ZH7XvVy/0096.webp"
    },
    {
      "page": 2,
      "link": "https://i.ibb.co/10hgy76/0097.webp"
    },
    {
      "page": 3,
      "link": "https://i.ibb.co/vHXvxFq/0098.webp"
    },
    {
      "page": 4,
      "link": "https://i.ibb.co/pXm2SVd/0099.webp"
    },
    {
      "page": 5,
      "link": "https://i.ibb.co/nbXp9JB/0100.webp"
    },
    {
      "page": 6,
      "link": "https://i.ibb.co/hmr0cpB/0101.webp"
    },
    {
      "page": 7,
      "link": "https://i.ibb.co/3F2z4YM/0102.webp"
    },
    {
      "page": 8,
      "link": "https://i.ibb.co/hK6ybTR/0103.webp"
    },
    {
      "page": 9,
      "link": "https://i.ibb.co/VTj0PbQ/0104.webp"
    },
    {
      "page": 10,
      "link": "https://i.ibb.co/L6ppDKb/0105.webp"
    },
    {
      "page": 11,
      "link": "https://i.ibb.co/SnbdCYQ/0106.webp"
    },
    {
      "page": 12,
      "link": "https://i.ibb.co/XkCJfYX/0107.webp"
    },
    {
      "page": 13,
      "link": "https://i.ibb.co/svz8Yxm/0108.webp"
    },
    {
      "page": 14,
      "link": "https://i.ibb.co/THYX4Zd/0109.webp"
    },
    {
      "page": 15,
      "link": "https://i.ibb.co/ySBRYFj/0110.webp"
    },
    {
      "page": 16,
      "link": "https://i.ibb.co/Tmg2pZ3/0111.webp"
    },
    {
      "page": 17,
      "link": "https://i.ibb.co/8jFD2SJ/0112.webp"
    },
    {
      "page": 18,
      "link": "https://i.ibb.co/yB1MRcQ/0113.webp"
    },
    {
      "page": 19,
      "link": "https://i.ibb.co/3SNBZMK/0114.webp"
    },
    {
      "page": 20,
      "link": "https://i.ibb.co/9wfps3P/0115.webp"
    },
    {
      "page": 21,
      "link": "https://i.ibb.co/vX7NsYS/0116.webp"
    },
    {
      "page": 22,
      "link": "https://i.ibb.co/pJZdpWd/0117.webp"
    },
    {
      "page": 23,
      "link": "https://i.ibb.co/MhVLTFv/0118.webp"
    },
    {
      "page": 24,
      "link": "https://i.ibb.co/HGyMKp8/0119.webp"
    },
    {
      "page": 25,
      "link": "https://i.ibb.co/6BbCc1K/0120.webp"
    },
    {
      "page": 26,
      "link": "https://i.ibb.co/t436SZS/0121.webp"
    },
    {
      "page": 27,
      "link": "https://i.ibb.co/4T8PHxX/0122.webp"
    },
    {
      "page": 28,
      "link": "https://i.ibb.co/3zgfd7g/0123.webp"
    },
    {
      "page": 29,
      "link": "https://i.ibb.co/c8Pg15L/0124.webp"
    },
    {
      "page": 30,
      "link": "https://i.ibb.co/5js5tMT/0125.webp"
    },
    {
      "page": 31,
      "link": "https://i.ibb.co/R67j05S/0126.webp"
    },
    {
      "page": 32,
      "link": "https://i.ibb.co/rfPtt3d/0127.webp"
    },
    {
      "page": 33,
      "link": "https://i.ibb.co/mDK1gYR/0128.webp"
    },
    {
      "page": 34,
      "link": "https://i.ibb.co/X2txgk6/0129.webp"
    },
    {
      "page": 35,
      "link": "https://i.ibb.co/7VY68FX/0130.webp"
    },
    {
      "page": 36,
      "link": "https://i.ibb.co/2sHL9cW/0131.webp"
    },
    {
      "page": 37,
      "link": "https://i.ibb.co/YTLW3zP/0132.webp"
    },
    {
      "page": 38,
      "link": "https://i.ibb.co/jgD7TK3/0133.webp"
    },
    {
      "page": 39,
      "link": "https://i.ibb.co/H7NWDvt/0134.webp"
    },
    {
      "page": 40,
      "link": "https://i.ibb.co/wMmk5v8/0135.webp"
    },
    {
      "page": 41,
      "link": "https://i.ibb.co/kGQq5WB/0136.webp"
    },
    {
      "page": 42,
      "link": "https://i.ibb.co/2gQjjgn/0137.webp"
    }
  ]


export default listePages;