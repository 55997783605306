const listePages = [
      {
        page: 49,
        link: "https://i.ibb.co/60Sk4nd/0052.webp"
      },
      {
        page: 50,
        link: "https://i.ibb.co/5KZNhLZ/0053.webp"
      },
      {
        page: 51,
        link: "https://i.ibb.co/XSbtqtY/0054.webp"
      },
      {
        page: 52,
        link: "https://i.ibb.co/Nm33b5c/0055.webp"
      },
      {
        page: 53,
        link: "https://i.ibb.co/SJ9yrT9/0056.webp"
      },
      {
        page: 54,
        link: "https://i.ibb.co/wRLQvmQ/0057.webp"
      },
      {
        page: 55,
        link: "https://i.ibb.co/09qHXV2/0058.webp"
      },
      {
        page: 56,
        link: "https://i.ibb.co/Zc3M55f/0059.webp"
      },
      {
        page: 57,
        link: "https://i.ibb.co/3FN1tfN/0060.webp"
      },
      {
        page: 58,
        link: "https://i.ibb.co/r3spSCX/0061.webp"
      },
      {
        page: 59,
        link: "https://i.ibb.co/cTsM9tZ/0062.webp"
      },
      {
        page: 60,
        link: "https://i.ibb.co/WGjMhWc/0063.webp"
      },
      {
        page: 61,
        link: "https://i.ibb.co/TWPF2SN/0064.webp"
      },
      {
        page: 62,
        link: "https://i.ibb.co/W0fhZZf/0065.webp"
      },
      {
        page: 63,
        link: "https://i.ibb.co/xfQRRDH/0066.webp"
      },
      {
        page: 64,
        link: "https://i.ibb.co/WnzFVKK/0067.webp"
      },
      {
        page: 65,
        link: "https://i.ibb.co/mG78RNJ/0068.webp"
      },
      {
        page: 66,
        link: "https://i.ibb.co/mBjc7GX/0069.webp"
      },
      {
        page: 67,
        link: "https://i.ibb.co/yppkc64/0070.webp"
      },
      {
        page: 68,
        link: "https://i.ibb.co/wwkzLhJ/0071.webp"
      },
      {
        page: 69,
        link: "https://i.ibb.co/nwBjKFp/0072.webp"
      },
      {
        page: 70,
        link: "https://i.ibb.co/mNQd2vq/0073.webp"
      },
      {
        page: 71,
        link: "https://i.ibb.co/HPphdZF/0074.webp"
      },
      {
        page: 72,
        link: "https://i.ibb.co/TT4zkwB/0075.webp"
      },
      {
        page: 73,
        link: "https://i.ibb.co/WHsXBQ7/0076.webp"
      },
      {
        page: 74,
        link: "https://i.ibb.co/C107tQM/0077.webp"
      },
      {
        page: 75,
        link: "https://i.ibb.co/VmrJzF6/0078.webp"
      },
      {
        page: 76,
        link: "https://i.ibb.co/c87QZLV/0079.webp"
      },
      {
        page: 77,
        link: "https://i.ibb.co/LYpPwFQ/0080.webp"
      },
      {
        page: 78,
        link: "https://i.ibb.co/3Bvsk0b/0081.webp"
      },
      {
        page: 79,
        link: "https://i.ibb.co/Xt0LfLY/0084.webp"
      },
      {
        page: 80,
        link: "https://i.ibb.co/R7tZHTj/0085.webp"
      },
      {
        page: 81,
        link: "https://i.ibb.co/Vqt8nCb/0086.webp"
      },
      {
        page: 82,
        link: "https://i.ibb.co/55d0WGP/0082.webp"
      }
]


export default listePages;