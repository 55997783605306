import { Helmet } from 'react-helmet';
import FaqContainer from '../components/FaqContainer';

export default function Faq() {
    return (
        <>
            <Helmet>
                <title>FAQ | Streaming Espace</title>
                <link rel="canonical" href={`https://www.streaming-espace.fr/faq`}/>
                <meta name="description" content={"Foire aux questions de Streaming Espace."} />
            </Helmet>
            <main className="content container">
                <FaqContainer />
            </main>                
        </>
    )       
}