const listeEpisodes = [
    {
        episode: 1,
        link: "https://www.youtube.com/embed/ort_4yQ-LOE"
    },
    {
        episode: 2,
        link: "https://www.youtube.com/embed/-5tCxM0MUG8"
    },
    {
        episode: 3,
        link: "https://www.youtube.com/embed/KYkuela6QbY"
    },
    {
        episode: 4,
        link: "https://www.youtube.com/embed/eKnJFO4ErZw"
    },
    {
        episode: 5,
        link: "https://www.youtube.com/embed/lypktUXf9OE"
    },
    {
        episode: 6,
        link: "https://www.youtube.com/embed/fPYMq5g7bJQ"
    },
    {
        episode: 7,
        link: "https://www.youtube.com/embed/gWcGzW6s7Gs"
    },
    {
        episode: 8,
        link: "https://www.youtube.com/embed/hxn0WWFh5e0"
    },
    {
        episode: 9,
        link: "https://www.youtube.com/embed/V5siALc4ZO8"
    },
    {
        episode: 10,
        link: "https://www.youtube.com/embed/sg_CGvlk_N0"
    },
    {
        episode: 11,
        link: "https://www.youtube.com/embed/tnfCP3l0i3I"
    },
    {
        episode: 12,
        link: "https://www.youtube.com/embed/oBR4FbpMrZc"
    },
    {
        episode: 13,
        link: "https://www.youtube.com/embed/uXzXrmkkPI0"
    },
    {
        episode: 14,
        link: "https://www.youtube.com/embed/t3d6aRnqRY0"
    },
    {
        episode: 15,
        link: "https://www.youtube.com/embed/v81js-zQryQ"
    },
    {
        episode: 16,
        link: "https://www.youtube.com/embed/iFMedDU5mV0"
    },
    {
        episode: 17,
        link: "https://www.youtube.com/embed/ItV7nHAMPQs"
    },
    {
        episode: 18,
        link: "https://www.youtube.com/embed/3-ZvquI4fCo"
    },
    {
        episode: 19,
        link: "https://www.youtube.com/embed/pCzodxHw6kk"
    },
    {
        episode: 20,
        link: "https://www.youtube.com/embed/maYwz4VDXIo"
    },
    {
        episode: 21,
        link: "https://www.youtube.com/embed/z1cLrMzkFak"
    },
    {
        episode: 22,
        link: "https://www.youtube.com/embed/k6tWQmzjJk0"
    },
    {
        episode: 23,
        link: "https://www.youtube.com/embed/WefeMzwTApo"
    },
    {
        episode: 24,
        link: "https://www.youtube.com/embed/kiDS-eaoo6U"
    },
    {
        episode: 25,
        link: "https://www.youtube.com/embed/DQrwkyud6E4"
    },
    {
        episode: 26,
        link: "https://www.youtube.com/embed/vZCkEv3hdn8"
    },
    {
        episode: 27,
        link: "https://www.youtube.com/embed/1zMMa_ez9Eg"
    },
    {
        episode: 28,
        link: "https://www.youtube.com/embed/iatkXxDPyi8"
    },
    {
        episode: 29,
        link: "https://www.youtube.com/embed/__YkZKLbNPY"
    },
    {
        episode: 30,
        link: "https://www.youtube.com/embed/_8swBo5CB0s"
    },
    {
        episode: 31,
        link: "https://www.youtube.com/embed/oAddC7CxJIQ"
    },
    {
        episode: 32,
        link: "https://www.youtube.com/embed/u7bjRtxlzWY"
    },
    {
        episode: 33,
        link: "https://www.youtube.com/embed/GJLJJDzqdtM"
    },
    {
        episode: 34,
        link: "https://www.youtube.com/embed/vnjeCzMwfrc"
    },
    {
        episode: 35,
        link: "https://www.youtube.com/embed/KXCTUca7SRY"
    },
    {
        episode: 36,
        link: "https://www.youtube.com/embed/ziL3ijEVFdQ"
    },
    {
        episode: 37,
        link: "https://www.youtube.com/embed/dpxJZJaeglk"
    },
    {
        episode: 38,
        link: "https://www.youtube.com/embed/BRT8dx6zGyY"
    },
    {
        episode: 39,
        link: "https://www.youtube.com/embed/Wjw0YprM2jw"
    },
    {
        episode: 40,
        link: "https://www.youtube.com/embed/cO_xIw889BA"
    },
    {
        episode: 41,
        link: "https://www.youtube.com/embed/LZkLxjz8Zhk"
    },
    {
        episode: 42,
        link: "https://www.youtube.com/embed/UBQdxFOv-O4"
    },
    {
        episode: 43,
        link: "https://www.youtube.com/embed/u6MKrfIWaLM"
    },
    {
        episode: 44,
        link: "https://www.youtube.com/embed/79jpXc5za5E"
    },
    {
        episode: 45,
        link: "https://www.youtube.com/embed/9MM52aeNZJY"
    },
    {
        episode: 46,
        link: "https://www.youtube.com/embed/MTM5Ac21snQ"
    },
    {
        episode: 47,
        link: "https://www.youtube.com/embed/ZzLHXnztCEY"
    },
    {
        episode: 48,
        link: "https://www.youtube.com/embed/9a_uYhc65-I"
    },
    {
        episode: 49,
        link: "https://www.youtube.com/embed/L4WhIshe6Tw"
    },
    {
        episode: 50,
        link: "https://www.youtube.com/embed/vrMeyxhPad8"
    },
    {
        episode: 51,
        link: "https://www.youtube.com/embed/WuhDpY3DOAg"
    }
]

export default listeEpisodes;