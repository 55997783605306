const listePages = [
    { page: 1, link: "https://i.ibb.co/StvVJD8/0064-001.webp" },
    { page: 2, link: "https://i.ibb.co/pwdm161/0064-002.webp" },
    { page: 3, link: "https://i.ibb.co/rfbJqd6/0064-003.webp" },
    { page: 4, link: "https://i.ibb.co/dsvBdD7/0064-004.webp" },
    { page: 5, link: "https://i.ibb.co/0Fg7cQZ/0064-005.webp" },
    { page: 6, link: "https://i.ibb.co/N6C7cC9/0064-006.webp" },
    { page: 7, link: "https://i.ibb.co/QCQjNZj/0064-007.webp" },
    { page: 8, link: "https://i.ibb.co/fYm7g4t/0064-008.webp" },
    { page: 9, link: "https://i.ibb.co/QHF7WWg/0064-009.webp" },
    { page: 10, link: "https://i.ibb.co/CVWj97j/0064-010.webp" },
    { page: 11, link: "https://i.ibb.co/tYb7q3z/0064-011.webp" },
    { page: 12, link: "https://i.ibb.co/YP77mxk/0064-012.webp" },
    { page: 13, link: "https://i.ibb.co/LtCLKwL/0064-013.webp" },
    { page: 14, link: "https://i.ibb.co/gW6Y3HH/0064-014.webp" },
    { page: 15, link: "https://i.ibb.co/Xy9Wdgp/0064-015.webp" },
    { page: 16, link: "https://i.ibb.co/5MTgyv6/0064-016.webp" },
    { page: 17, link: "https://i.ibb.co/p0zmt1m/0064-017.webp" }
  ]
  

export default listePages;