import { useParams } from 'react-router-dom';
import MenuScans from '../components/MenuScans';
import { Helmet } from 'react-helmet';

export default function ScansMenu() {
    const param = useParams()
    const generationURL = param.generation

    let actualGeneration;

    // eslint-disable-next-line default-case
    switch (generationURL) {
        case "bakuten":
            actualGeneration = "Bakuten Shoot Beyblade"
            break
        case "metal":
            actualGeneration = "Metal Fight Beyblade"
            break
        case "burst":
            actualGeneration = "Beyblade Burst"
            break
        case "x":
            actualGeneration = "Beyblade X"
            break
        case "spinoff":
            actualGeneration = "Spin-Off"
            break
    }

    return (
        <>
            <Helmet>
                <title>Scans {actualGeneration} | Streaming Espace</title>
                <link rel="canonical" href={`https://www.streaming-espace.fr/${generationURL}/scans`}/>
                <meta name="description" content={"Découvre la liste des scans de " + actualGeneration + "."} />
            </Helmet>
            <main className="content container Scanmenupage">
                <MenuScans />
            </main>                
        </>
    )       
}