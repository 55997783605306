const listePages = [
    {
        page: 1,
        link: "https://i.ibb.co/h8ypKf9/085.webp"
      },
      {
        page: 2,
        link: "https://i.ibb.co/4F5Vrk8/086.webp"
      },
      {
        page: 3,
        link: "https://i.ibb.co/LzJ8WJD/087.webp"
      },
      {
        page: 4,
        link: "https://i.ibb.co/hgHp9LS/088.webp"
      },
      {
        page: 5,
        link: "https://i.ibb.co/GpNk3zy/089.webp"
      },
      {
        page: 6,
        link: "https://i.ibb.co/d5JTnJ9/090.webp"
      },
      {
        page: 7,
        link: "https://i.ibb.co/2NZdX4H/091.webp"
      },
      {
        page: 8,
        link: "https://i.ibb.co/bR2kL0P/092.webp"
      },
      {
        page: 9,
        link: "https://i.ibb.co/1z45P98/093.webp"
      },
      {
        page: 10,
        link: "https://i.ibb.co/dgRm15Y/094.webp"
      },
      {
        page: 11,
        link: "https://i.ibb.co/pLVSkZ1/095.webp"
      },
      {
        page: 12,
        link: "https://i.ibb.co/JqNK8pf/096.webp"
      },
      {
        page: 13,
        link: "https://i.ibb.co/PQTt4wq/097.webp"
      },
      {
        page: 14,
        link: "https://i.ibb.co/JRcJ70G/098.webp"
      },
      {
        page: 15,
        link: "https://i.ibb.co/c3jbn1C/099.webp"
      },
      {
        page: 16,
        link: "https://i.ibb.co/rpG3gFN/100.webp"
      },
      {
        page: 17,
        link: "https://i.ibb.co/hMk28sJ/101.webp"
      },
      {
        page: 18,
        link: "https://i.ibb.co/nkhJRQ0/102.webp"
      },
      {
        page: 19,
        link: "https://i.ibb.co/Js27Vx5/103.webp"
      },
      {
        page: 20,
        link: "https://i.ibb.co/ygnfxc1/104.webp"
      },
      {
        page: 21,
        link: "https://i.ibb.co/JRrPV6M/105.webp"
      },
      {
        page: 22,
        link: "https://i.ibb.co/ysXTTCk/106.webp"
      },
      {
        page: 23,
        link: "https://i.ibb.co/K5QtTzX/107.webp"
      },
      {
        page: 24,
        link: "https://i.ibb.co/Q6LH66s/108.webp"
      },
      {
        page: 25,
        link: "https://i.ibb.co/3c8xz6v/109.webp"
      },
      {
        page: 26,
        link: "https://i.ibb.co/bRB7Gks/110.webp"
      },
      {
        page: 27,
        link: "https://i.ibb.co/TqqTsxG/111.webp"
      },
      {
        page: 28,
        link: "https://i.ibb.co/rbYcktm/112.webp"
      },
      {
        page: 29,
        link: "https://i.ibb.co/6RqTJfS/113.webp"
      },
      {
        page: 30,
        link: "https://i.ibb.co/x3FGSXS/114.webp"
      },
      {
        page: 31,
        link: "https://i.ibb.co/1M1qXKt/115.webp"
      },
      {
        page: 32,
        link: "https://i.ibb.co/4sZr0NX/116.webp"
      },
      {
        page: 33,
        link: "https://i.ibb.co/d2cc48W/117.webp"
      },
      {
        page: 34,
        link: "https://i.ibb.co/zhfb3DL/118.webp"
      },
      {
        page: 35,
        link: "https://i.ibb.co/LCp8nJr/119.webp"
      },
      {
        page: 36,
        link: "https://i.ibb.co/rkkkFqz/120.webp"
      },
      {
        page: 37,
        link: "https://i.ibb.co/pfsq57q/121.webp"
      },
      {
        page: 38,
        link: "https://i.ibb.co/T8Skpwd/122.webp"
      },
      {
        page: 39,
        link: "https://i.ibb.co/wgZnY8n/123.webp"
      },
      {
        page: 40,
        link: "https://i.ibb.co/xqTT5VK/124.webp"
      }
]


export default listePages;