const listePages = [
    { page: 1, link: "https://i.ibb.co/8gZGnwv/73-2-001.webp" },
    { page: 2, link: "https://i.ibb.co/Gkw8rZF/73-2-002.webp" },
    { page: 3, link: "https://i.ibb.co/SNxt4c3/73-2-003.webp" },
    { page: 4, link: "https://i.ibb.co/HhXmYw5/73-2-004.webp" },
    { page: 5, link: "https://i.ibb.co/db3SPqH/73-2-005.webp" },
    { page: 6, link: "https://i.ibb.co/db1W360/73-2-006.webp" },
    { page: 7, link: "https://i.ibb.co/hLn4w53/73-2-007.webp" },
    { page: 8, link: "https://i.ibb.co/kK4N2CM/73-2-008.webp" },
    { page: 9, link: "https://i.ibb.co/z7mS93P/73-2-009.webp" },
    { page: 10, link: "https://i.ibb.co/YbMh0Ly/73-2-010.webp" },
    { page: 11, link: "https://i.ibb.co/HdhB1sm/73-2-011.webp" },
    { page: 12, link: "https://i.ibb.co/bX6Nrx4/73-2-012.webp" }
  ]
  

export default listePages;