const listePages = [
  { page: 1, link: "https://i.ibb.co/s6R366N/0092-001.webp" },
  { page: 2, link: "https://i.ibb.co/p4c2CN3/0092-002.webp" },
  { page: 3, link: "https://i.ibb.co/bH3HxdF/0092-003.webp" },
  { page: 4, link: "https://i.ibb.co/qkpKz7M/0092-004.webp" },
  { page: 5, link: "https://i.ibb.co/RcbPhvQ/0092-005.webp" },
  { page: 6, link: "https://i.ibb.co/474r6d2/0092-006.webp" },
  { page: 7, link: "https://i.ibb.co/d0rBQkC/0092-007.webp" },
  { page: 8, link: "https://i.ibb.co/dk03phZ/0092-008.webp" },
  { page: 9, link: "https://i.ibb.co/F8q4tKw/0092-009.webp" },
  { page: 10, link: "https://i.ibb.co/YZ7KGR0/0092-010.webp" },
  { page: 11, link: "https://i.ibb.co/pb0N2R5/0092-011.webp" },
  { page: 12, link: "https://i.ibb.co/HrYnZnL/0092-012.webp" },
  { page: 13, link: "https://i.ibb.co/ZHJxMF1/0092-013.webp" },
  { page: 14, link: "https://i.ibb.co/B4vLwRr/0092-014.webp" },
  { page: 15, link: "https://i.ibb.co/ZXjZpHq/0092-015.webp" },
  { page: 16, link: "https://i.ibb.co/LzC8p1J/0092-016.webp" },
  { page: 17, link: "https://i.ibb.co/MsB54Kz/0092-017.webp" },
  { page: 18, link: "https://i.ibb.co/yyLssjF/0092-018.webp" },
  { page: 19, link: "https://i.ibb.co/CwQC1XB/0092-019.webp" },
  { page: 20, link: "https://i.ibb.co/GFGyp5D/0092-020.webp" },
  { page: 21, link: "https://i.ibb.co/PTPxrcN/0092-021.webp" },
  { page: 22, link: "https://i.ibb.co/mGxHr3Z/0092-022.webp" },
  { page: 23, link: "https://i.ibb.co/VVVspNs/0092-023.webp" },
  { page: 24, link: "https://i.ibb.co/XZNB9Jj/0092-024.webp" },
  { page: 25, link: "https://i.ibb.co/WFYVFg0/0092-025.webp" },
  { page: 26, link: "https://i.ibb.co/ynvgV4t/0092-026.webp" },
  { page: 27, link: "https://i.ibb.co/5B5J1ZP/0092-027.webp" },
  { page: 28, link: "https://i.ibb.co/k59yTwS/0092-028.webp" },
  { page: 29, link: "https://i.ibb.co/LkSqJBk/92-5-001.webp" },
  { page: 30, link: "https://i.ibb.co/QrzBTS5/92-5-002.webp" },
  { page: 31, link: "https://i.ibb.co/p1Fg5yL/92-5-003.webp" },
  { page: 32, link: "https://i.ibb.co/hMjLZ6Z/92-5-004.webp" }
]


export default listePages;