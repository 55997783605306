const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/SNFsJXC/0042-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/bLcztP6/0042-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/276dnDP/0042-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/MGDtQpw/0042-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/FJy9nj5/0042-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/tX9j7MF/0042-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/SRpvPHY/0042-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/m99K3ZH/0042-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/MNs9c6R/0042-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/tLnVbtz/0042-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/LQrt63S/0042-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/JKKCMX6/0042-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/FmsZgJB/0042-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/2ncL98X/0042-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/5nVJ9rH/0042-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/p3wSTRh/0042-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/YfkpfJp/0042-017.webp"
  }
]


export default listePages;