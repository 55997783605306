const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/yWWnMR2/43-5-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/x8937rj/43-5-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/ZHBV8Fp/43-5-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/h1CHM2T/43-5-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/4MWTj9W/43-5-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/nBXtWm3/43-5-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/PWKPGcL/43-5-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/brnbbwh/43-5-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/zSxxj15/43-5-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/n3GBHzs/43-5-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/8jtPcMK/43-5-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/qn9cZ77/43-5-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/wwkSs23/43-5-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/vqG5Q4Q/43-5-014.webp"
  }
];


export default listePages;