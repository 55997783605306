const listeEpisodes = [
    {
        episode : 1,
        link : "https://video.sibnet.ru/shell.php?videoid=4926363"
    },
    {
        episode : 2,
        link : "https://video.sibnet.ru/shell.php?videoid=4926366"
    },
    {
        episode : 3,
        link : "https://video.sibnet.ru/shell.php?videoid=4926368"
    },
    {
        episode : 4,
        link : "https://video.sibnet.ru/shell.php?videoid=4926369"
    },
    {
        episode : 5,
        link : "https://video.sibnet.ru/shell.php?videoid=4926371"
    },
    {
        episode : 6,
        link : "https://video.sibnet.ru/shell.php?videoid=4926373"
    },
    {
        episode : 7,
        link : "https://video.sibnet.ru/shell.php?videoid=4926377"
    },
    {
        episode : 8,
        link : "https://video.sibnet.ru/shell.php?videoid=4926382"
    },
    {
        episode : 9,
        link : "https://video.sibnet.ru/shell.php?videoid=4926387"
    },
    {
        episode : 10,
        link : "https://video.sibnet.ru/shell.php?videoid=4926389"
    },
    {
        episode : 11,
        link : "https://video.sibnet.ru/shell.php?videoid=4926393"
    },
    {
        episode : 12,
        link : "https://video.sibnet.ru/shell.php?videoid=4926362"
    },
    {
        episode : 13,
        link : "https://video.sibnet.ru/shell.php?videoid=4926397"
    },
    {
        episode : 14,
        link : "https://video.sibnet.ru/shell.php?videoid=4926402"
    },
    {
        episode : 15,
        link : "https://video.sibnet.ru/shell.php?videoid=4926421"
    },
    {
        episode : 16,
        link : "https://video.sibnet.ru/shell.php?videoid=4926429"
    }
]

export default listeEpisodes;