const listePages = [
    { page: 1, link: "https://i.ibb.co/n3bmWjX/1.webp" },
    { page: 2, link: "https://i.ibb.co/tKW1xnb/2.webp" },
    { page: 3, link: "https://i.ibb.co/PxHNGfx/3.webp" },
    { page: 4, link: "https://i.ibb.co/5hwMrwz/4.webp" },
    { page: 5, link: "https://i.ibb.co/0tHYKLX/5.webp" },
    { page: 6, link: "https://i.ibb.co/17xf4Kt/6.webp" },
    { page: 7, link: "https://i.ibb.co/hc4MyxD/7.webp" },
    { page: 8, link: "https://i.ibb.co/M2Jxjmt/cplugrandlamdr.webp" }
  ]
  

export default listePages;