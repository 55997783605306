const listePages = [
    {
        page: 1,
        link: "https://i.ibb.co/9H5c2BM/0057.webp"
      },
      {
        page: 2,
        link: "https://i.ibb.co/q1v3p3P/0058.webp"
      },
      {
        page: 3,
        link: "https://i.ibb.co/3WbVsFM/0059.webp"
      },
      {
        page: 4,
        link: "https://i.ibb.co/2NnVm3Z/0060.webp"
      },
      {
        page: 5,
        link: "https://i.ibb.co/Gn2737d/0061.webp"
      },
      {
        page: 6,
        link: "https://i.ibb.co/k5jyX0V/0062.webp"
      },
      {
        page: 7,
        link: "https://i.ibb.co/1ssTD2T/0063.webp"
      },
      {
        page: 8,
        link: "https://i.ibb.co/2PQCRzn/0064.webp"
      },
      {
        page: 9,
        link: "https://i.ibb.co/DbyZfsW/0065.webp"
      },
      {
        page: 10,
        link: "https://i.ibb.co/gFZ2JJ9/0066.webp"
      },
      {
        page: 11,
        link: "https://i.ibb.co/n3CZPV7/0067.webp"
      },
      {
        page: 12,
        link: "https://i.ibb.co/wYXhRrx/0068.webp"
      },
      {
        page: 13,
        link: "https://i.ibb.co/bbD6W4j/0069.webp"
      },
      {
        page: 14,
        link: "https://i.ibb.co/jwMMWyf/0070.webp"
      },
      {
        page: 15,
        link: "https://i.ibb.co/4gnkMy9/0071.webp"
      },
      {
        page: 16,
        link: "https://i.ibb.co/tLCdtzN/0072.webp"
      },
      {
        page: 17,
        link: "https://i.ibb.co/rQsXGp0/0073.webp"
      },
      {
        page: 18,
        link: "https://i.ibb.co/ZgRt0dx/0074.webp"
      },
      {
        page: 19,
        link: "https://i.ibb.co/D51Yss1/0075.webp"
      },
      {
        page: 20,
        link: "https://i.ibb.co/nnbfdD4/0076.webp"
      },
      {
        page: 21,
        link: "https://i.ibb.co/Sn53kZb/0077.webp"
      },
      {
        page: 22,
        link: "https://i.ibb.co/hySX7Nr/0078.webp"
      },
      {
        page: 23,
        link: "https://i.ibb.co/hXqbrN5/0079.webp"
      },
      {
        page: 24,
        link: "https://i.ibb.co/v32syss/0080.webp"
      },
      {
        page: 25,
        link: "https://i.ibb.co/K5kRrpF/0081.webp"
      },
      {
        page: 26,
        link: "https://i.ibb.co/Y2v5h49/0082.webp"
      },
      {
        page: 27,
        link: "https://i.ibb.co/fx6VGgk/0083.webp"
      },
      {
        page: 28,
        link: "https://i.ibb.co/wNk3QXw/0084.webp"
      },
      {
        page: 29,
        link: "https://i.ibb.co/MpN3HyW/0085.webp"
      },
      {
        page: 30,
        link: "https://i.ibb.co/FHmYG2T/0086.webp"
      },
      {
        page: 31,
        link: "https://i.ibb.co/S71xp4r/0087.webp"
      },
      {
        page: 32,
        link: "https://i.ibb.co/X5Nptcp/0088.webp"
      },
      {
        page: 33,
        link: "https://i.ibb.co/4gFW75v/0089.webp"
      },
      {
        page: 34,
        link: "https://i.ibb.co/JWZ4SMx/0090.webp"
      },
      {
        page: 35,
        link: "https://i.ibb.co/hdzMf74/0091.webp"
      },
      {
        page: 36,
        link: "https://i.ibb.co/4gB1hYr/0092.webp"
      },
      {
        page: 37,
        link: "https://i.ibb.co/6g8q3w7/0093.webp"
      },
      {
        page: 38,
        link: "https://i.ibb.co/KsS6N1D/0094.webp"
      },
      {
        page: 39,
        link: "https://i.ibb.co/rdcL7Xd/0095.webp"
      },
      {
        page: 40,
        link: "https://i.ibb.co/DDnjWQX/0096.webp"
      }
]


export default listePages;