const listeChapitres = [
    {
        id : 0,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 0",
        url : "chapitre-0",
        img : "https://i.ibb.co/7rTj4Rt/f44hb2.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-0"
    },
    {
        id : 1,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 1",
        url : "chapitre-1",
        img : "https://i.ibb.co/FsC5dBx/Beyblade-Burst-T01.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-1"
    },
    {
        id : 2,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 2",
        url : "chapitre-2",
        img : "https://i.ibb.co/FsC5dBx/Beyblade-Burst-T01.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-2"
    },
    {
        id : 3,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 3",
        url : "chapitre-3",
        img : "https://i.ibb.co/FsC5dBx/Beyblade-Burst-T01.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-3"
    },
    {
        id : 4,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 4",
        url : "chapitre-4",
        img : "https://i.ibb.co/FsC5dBx/Beyblade-Burst-T01.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-4"
    },
    {
        id : 5,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 5",
        url : "chapitre-5",
        img : "https://i.ibb.co/98wBJyx/Beyblade-Burst-T02.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-5"
    },
    {
        id : 6,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 6",
        url : "chapitre-6",
        img : "https://i.ibb.co/98wBJyx/Beyblade-Burst-T02.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-6"
    },
    {
        id : 7,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 7",
        url : "chapitre-7",
        img : "https://i.ibb.co/98wBJyx/Beyblade-Burst-T02.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-7"
    },
    {
        id : 8,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 8",
        url : "chapitre-8",
        img : "https://i.ibb.co/98wBJyx/Beyblade-Burst-T02.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-8"
    },
    {
        id : 9,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 9",
        url : "chapitre-9",
        img : "https://i.ibb.co/98wBJyx/Beyblade-Burst-T02.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-9"
    },
    {
        id : 10,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 10",
        url : "chapitre-10",
        img : "https://i.ibb.co/k04fyTD/Beyblade-Burst-T03.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-10"
    },
    {
        id : 11,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 11",
        url : "chapitre-11",
        img : "https://i.ibb.co/k04fyTD/Beyblade-Burst-T03.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-11"
    },
    {
        id : 12,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 12",
        url : "chapitre-12",
        img : "https://i.ibb.co/k04fyTD/Beyblade-Burst-T03.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-12"
    },
    {
        id : 13,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 13",
        url : "chapitre-13",
        img : "https://i.ibb.co/k04fyTD/Beyblade-Burst-T03.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-13"
    },
    {
        id : 14,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 14",
        url : "chapitre-14",
        img : "https://i.ibb.co/k04fyTD/Beyblade-Burst-T03.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-14"
    },
    {
        id : 14.5,
        titre : "Beyblade Burst",
        chapitre : "Épisode Spécial",
        url : "chapitre-14.5",
        img : "https://i.ibb.co/MNRfyjV/Beyblade-Burst-T04.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-14.5"
    },
    {
        id : 15,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 15",
        url : "chapitre-15",
        img : "https://i.ibb.co/MNRfyjV/Beyblade-Burst-T04.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-15"
    },
    {
        id : 16,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 16",
        url : "chapitre-16",
        img : "https://i.ibb.co/MNRfyjV/Beyblade-Burst-T04.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-16"
    },
    {
        id : 17,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 17",
        url : "chapitre-17",
        img : "https://i.ibb.co/MNRfyjV/Beyblade-Burst-T04.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-17"
    },
    {
        id : 18,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 18",
        url : "chapitre-18",
        img : "https://i.ibb.co/MNRfyjV/Beyblade-Burst-T04.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-18"
    },
    {
        id : 18.5,
        titre : "Beyblade Burst",
        chapitre : "Épisode Spécial - Part 1",
        url : "chapitre-18.5-part1",
        img : "https://i.ibb.co/MNRfyjV/Beyblade-Burst-T04.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-18.5-part1"
    },
    {
        id : 18.6,
        titre : "Beyblade Burst",
        chapitre : "Épisode Spécial - Part 2",
        url : "chapitre-18.5-part2",
        img : "https://i.ibb.co/D9BHKFs/Beyblade-Burst-T05.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-18.5-part2"
    },
    {
        id : 19,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 19",
        url : "chapitre-19",
        img : "https://i.ibb.co/D9BHKFs/Beyblade-Burst-T05.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-19"
    },
    {
        id : 20,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 20",
        url : "chapitre-20",
        img : "https://i.ibb.co/D9BHKFs/Beyblade-Burst-T05.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-20"
    },
    {
        id : 21,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 21",
        url : "chapitre-21",
        img : "https://i.ibb.co/D9BHKFs/Beyblade-Burst-T05.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-21"
    },
    {
        id : 22,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 22",
        url : "chapitre-22",
        img : "https://i.ibb.co/D9BHKFs/Beyblade-Burst-T05.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-22"
    },
    {
        id : 23,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 23",
        url : "chapitre-23",
        img : "https://i.ibb.co/D9BHKFs/Beyblade-Burst-T05.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-23"
    },
    {
        id : 23.5,
        titre : "Beyblade Burst",
        chapitre : "Épisode Spécial",
        url : "chapitre-23.5",
        img : "https://i.ibb.co/M959vRs/Beyblade-Burst-T06.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-23.5"
    },
    {
        id : 24,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 24",
        url : "chapitre-24",
        img : "https://i.ibb.co/M959vRs/Beyblade-Burst-T06.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-24"
    },
    {
        id : 25,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 25",
        url : "chapitre-25",
        img : "https://i.ibb.co/M959vRs/Beyblade-Burst-T06.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-25"
    },
    {
        id : 26,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 26",
        url : "chapitre-26",
        img : "https://i.ibb.co/M959vRs/Beyblade-Burst-T06.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-26"
    },
    {
        id : 27,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 27",
        url : "chapitre-27",
        img : "https://i.ibb.co/M959vRs/Beyblade-Burst-T06.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-27"
    },
    {
        id : 28,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 28",
        url : "chapitre-28",
        img : "https://i.ibb.co/SwZY1YG/Beyblade-Burst-T07.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-28"
    },
    {
        id : 28.5,
        titre : "Beyblade Burst",
        chapitre : "Épisode Spécial",
        url : "chapitre-28.5",
        img : "https://i.ibb.co/SwZY1YG/Beyblade-Burst-T07.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-28.5"
    },
    {
        id : 29,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 29",
        url : "chapitre-29",
        img : "https://i.ibb.co/SwZY1YG/Beyblade-Burst-T07.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-29"
    },
    {
        id : 30,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 30",
        url : "chapitre-30",
        img : "https://i.ibb.co/SwZY1YG/Beyblade-Burst-T07.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-30"
    },
    {
        id : 31,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 31",
        url : "chapitre-31",
        img : "https://i.ibb.co/SwZY1YG/Beyblade-Burst-T07.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-31"
    },
    {
        id : 32,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 32",
        url : "chapitre-32",
        img : "https://i.ibb.co/SwZY1YG/Beyblade-Burst-T07.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-32"
    },
    {
        id : 33,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 33",
        url : "chapitre-33",
        img : "https://i.ibb.co/SwZY1YG/Beyblade-Burst-T07.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-33"
    },
    {
        id : 34,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 34",
        url : "chapitre-34",
        img : "https://i.ibb.co/RTN5hW3/Beyblade-Burst-T08.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-34"
    },
    {
        id : 34.5,
        titre : "Beyblade Burst",
        chapitre : "Épisode Spécial - Part 1",
        url : "chapitre-34.5-part1",
        img : "https://i.ibb.co/RTN5hW3/Beyblade-Burst-T08.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-34.5-part1"
    },
    {
        id : 34.6,
        titre : "Beyblade Burst",
        chapitre : "Épisode Spécial - Part 2",
        url : "chapitre-34.5-part2",
        img : "https://i.ibb.co/RTN5hW3/Beyblade-Burst-T08.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-34.5-part2"
    },
    {
        id : 35,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 35",
        url : "chapitre-35",
        img : "https://i.ibb.co/RTN5hW3/Beyblade-Burst-T08.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-35"
    },
    {
        id : 36,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 36",
        url : "chapitre-36",
        img : "https://i.ibb.co/RTN5hW3/Beyblade-Burst-T08.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-36"
    },
    {
        id : 37,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 37",
        url : "chapitre-37",
        img : "https://i.ibb.co/RTN5hW3/Beyblade-Burst-T08.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-37"
    },
    {
        id : 38,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 38",
        url : "chapitre-38",
        img : "https://i.ibb.co/RTN5hW3/Beyblade-Burst-T08.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-38"
    },
    {
        id : 39,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 39",
        url : "chapitre-39",
        img : "https://i.ibb.co/P9pypxB/Beyblade-Burst-T09.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-39"
    },
    {
        id : 40,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 40",
        url : "chapitre-40",
        img : "https://i.ibb.co/P9pypxB/Beyblade-Burst-T09.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-40"
    },
    {
        id : 41,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 41",
        url : "chapitre-41",
        img : "https://i.ibb.co/P9pypxB/Beyblade-Burst-T09.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-41"
    },
    {
        id : 42,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 42",
        url : "chapitre-42",
        img : "https://i.ibb.co/P9pypxB/Beyblade-Burst-T09.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-42"
    },
    {
        id : 43,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 43",
        url : "chapitre-43",
        img : "https://i.ibb.co/P9pypxB/Beyblade-Burst-T09.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-43"
    },
    {
        id : 43.5,
        titre : "Beyblade Burst",
        chapitre : "Épisode Spécial - Part 1",
        url : "chapitre-43.5-1-part1",
        img : "https://i.ibb.co/P9pypxB/Beyblade-Burst-T09.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-43.5-1-part1"
    },
    {
        id : 43.6,
        titre : "Beyblade Burst",
        chapitre : "Épisode Spécial - Part 2",
        url : "chapitre-43.5-1-part2",
        img : "https://i.ibb.co/P9pypxB/Beyblade-Burst-T09.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-43.5-1-part2"
    },
    {
        id : 43.7,
        titre : "Beyblade Burst",
        chapitre : "Épisode Spécial",
        url : "chapitre-43.5-2",
        img : "https://i.ibb.co/WsPFGHY/Beyblade-Burst-T10.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-43.5-2"
    },
    {
        id : 44,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 44",
        url : "chapitre-44",
        img : "https://i.ibb.co/WsPFGHY/Beyblade-Burst-T10.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-44"
    },
    {
        id : 45,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 45",
        url : "chapitre-45",
        img : "https://i.ibb.co/WsPFGHY/Beyblade-Burst-T10.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-45"
    },
    {
        id : 46,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 46",
        url : "chapitre-46",
        img : "https://i.ibb.co/WsPFGHY/Beyblade-Burst-T10.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-46"
    },
    {
        id : 47,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 47",
        url : "chapitre-47",
        img : "https://i.ibb.co/WsPFGHY/Beyblade-Burst-T10.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-47"
    },
    {
        id : 48,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 48",
        url : "chapitre-48",
        img : "https://i.ibb.co/WsPFGHY/Beyblade-Burst-T10.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-48"
    },
    {
        id : 48.5,
        titre : "Beyblade Burst",
        chapitre : "Épisode Spécial",
        url : "chapitre-48.5-1",
        img : "https://i.ibb.co/WsPFGHY/Beyblade-Burst-T10.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-48.5-1"
    },
    {
        id : 48.6,
        titre : "Beyblade Burst",
        chapitre : "Épisode Spécial",
        url : "chapitre-48.5-2",
        img : "https://i.ibb.co/WsPFGHY/Beyblade-Burst-T10.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-48.5-2"
    },
    {
        id : 49,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 49",
        url : "chapitre-49",
        img : "https://i.ibb.co/80P6WGJ/Beyblade-Burst-T11.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-49"
    },
    {
        id : 50,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 50",
        url : "chapitre-50",
        img : "https://i.ibb.co/80P6WGJ/Beyblade-Burst-T11.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-50"
    },
    {
        id : 51,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 51",
        url : "chapitre-51",
        img : "https://i.ibb.co/80P6WGJ/Beyblade-Burst-T11.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-51"
    },
    {
        id : 52,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 52",
        url : "chapitre-52",
        img : "https://i.ibb.co/80P6WGJ/Beyblade-Burst-T11.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-52"
    },
    {
        id : 52.5,
        titre : "Beyblade Burst",
        chapitre : "Épisode Spécial - Part 1",
        url : "chapitre-52.5-part1",
        img : "https://i.ibb.co/80P6WGJ/Beyblade-Burst-T11.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-52.5-part1"
    },
    {
        id : 52.6,
        titre : "Beyblade Burst",
        chapitre : "Épisode Spécial - Part 2",
        url : "chapitre-52.5-part2",
        img : "https://i.ibb.co/80P6WGJ/Beyblade-Burst-T11.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-52.5-part2"
    },
    {
        id : 53,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 53",
        url : "chapitre-53",
        img : "https://i.ibb.co/80P6WGJ/Beyblade-Burst-T11.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-53"
    },
    {
        id : 53.5,
        titre : "Beyblade Burst",
        chapitre : "Épisode Spécial",
        url : "chapitre-53.5",
        img : "https://i.ibb.co/80P6WGJ/Beyblade-Burst-T11.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-53.5"
    },
    {
        id : 54,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 54",
        url : "chapitre-54",
        img : "https://i.ibb.co/fq44TN7/Beyblade-Burst-T12.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-54"
    },
    {
        id : 55,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 55",
        url : "chapitre-55",
        img : "https://i.ibb.co/fq44TN7/Beyblade-Burst-T12.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-55"
    },
    {
        id : 56,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 56",
        url : "chapitre-56",
        img : "https://i.ibb.co/fq44TN7/Beyblade-Burst-T12.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-56"
    },
    {
        id : 57,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 57",
        url : "chapitre-57",
        img : "https://i.ibb.co/fq44TN7/Beyblade-Burst-T12.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-57"
    },
    {
        id : 58,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 58",
        url : "chapitre-58",
        img : "https://i.ibb.co/fq44TN7/Beyblade-Burst-T12.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-58"
    },
    {
        id : 59,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 59",
        url : "chapitre-59",
        img : "https://i.ibb.co/fq44TN7/Beyblade-Burst-T12.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-59"
    },
    {
        id : 59.5,
        titre : "Beyblade Burst",
        chapitre : "Épisode Spécial - Part 1",
        url : "chapitre-59.5-1-part1",
        img : "https://i.ibb.co/fq44TN7/Beyblade-Burst-T12.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-59.5-1-part1"
    },
    {
        id : 59.6,
        titre : "Beyblade Burst",
        chapitre : "Épisode Spécial - Part 2",
        url : "chapitre-59.5-1-part2",
        img : "https://i.ibb.co/fq44TN7/Beyblade-Burst-T12.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-59.5-1-part2"
    },
    {
        id : 59.7,
        titre : "Beyblade Burst",
        chapitre : "Épisode Spécial",
        url : "chapitre-59.5-2",
        img : "https://i.ibb.co/fMZ1rzb/Beyblade-Burst-T13.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-59.5-2"
    },
    {
        id : 60,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 60",
        url : "chapitre-60",
        img : "https://i.ibb.co/fMZ1rzb/Beyblade-Burst-T13.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-60"
    },
    {
        id : 61,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 61",
        url : "chapitre-61",
        img : "https://i.ibb.co/fMZ1rzb/Beyblade-Burst-T13.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-61"
    },
    {
        id : 62,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 62",
        url : "chapitre-62",
        img : "https://i.ibb.co/fMZ1rzb/Beyblade-Burst-T13.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-62"
    },
    {
        id : 63,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 63",
        url : "chapitre-63",
        img : "https://i.ibb.co/fMZ1rzb/Beyblade-Burst-T13.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-63"
    },
    {
        id : 64,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 64",
        url : "chapitre-64",
        img : "https://i.ibb.co/fMZ1rzb/Beyblade-Burst-T13.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-64"
    },
    {
        id : 65,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 65",
        url : "chapitre-65",
        img : "https://i.ibb.co/fMZ1rzb/Beyblade-Burst-T13.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-65"
    },
    {
        id : 66,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 66",
        url : "chapitre-66",
        img : "https://i.ibb.co/fMZ1rzb/Beyblade-Burst-T13.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-66"
    },
    {
        id : 66.5,
        titre : "Beyblade Burst",
        chapitre : "Épisode Spécial",
        url : "chapitre-66.5",
        img : "https://i.ibb.co/fMZ1rzb/Beyblade-Burst-T13.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-66.5"
    },
    {
        id : 67,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 67",
        url : "chapitre-67",
        img : "https://i.ibb.co/k2wbWSr/Beyblade-Burst-T14.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-67"
    },
    {
        id : 68,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 68",
        url : "chapitre-68",
        img : "https://i.ibb.co/k2wbWSr/Beyblade-Burst-T14.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-68"
    },
    {
        id : 69,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 69",
        url : "chapitre-69",
        img : "https://i.ibb.co/k2wbWSr/Beyblade-Burst-T14.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-69"
    },
    {
        id : 70,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 70",
        url : "chapitre-70",
        img : "https://i.ibb.co/k2wbWSr/Beyblade-Burst-T14.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-70"
    },
    {
        id : 71,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 71",
        url : "chapitre-71",
        img : "https://i.ibb.co/k2wbWSr/Beyblade-Burst-T14.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-71"
    },
    {
        id : 72,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 72",
        url : "chapitre-72",
        img : "https://i.ibb.co/k2wbWSr/Beyblade-Burst-T14.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-72"
    },
    {
        id : 73,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 73",
        url : "chapitre-73",
        img : "https://i.ibb.co/YZtTFVY/Beyblade-Burst-T15.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-73"
    },
    {
        id : 73.5,
        titre : "Beyblade Burst",
        chapitre : "Épisode Spécial",
        url : "chapitre-73.5-part1",
        img : "https://i.ibb.co/YZtTFVY/Beyblade-Burst-T15.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-73.5-part1"
    },
    {
        id : 73.6,
        titre : "Beyblade Burst",
        chapitre : "Épisode Spécial",
        url : "chapitre-73.5-part2",
        img : "https://i.ibb.co/YZtTFVY/Beyblade-Burst-T15.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-73.5-part2"
    },
    {
        id : 74,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 74",
        url : "chapitre-74",
        img : "https://i.ibb.co/YZtTFVY/Beyblade-Burst-T15.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-74"
    },
    {
        id : 75,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 75",
        url : "chapitre-75",
        img : "https://i.ibb.co/YZtTFVY/Beyblade-Burst-T15.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-75"
    },
    {
        id : 76,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 76",
        url : "chapitre-76",
        img : "https://i.ibb.co/YZtTFVY/Beyblade-Burst-T15.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-76"
    },
    {
        id : 77,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 77",
        url : "chapitre-77",
        img : "https://i.ibb.co/YZtTFVY/Beyblade-Burst-T15.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-77"
    },
    {
        id : 78,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 78",
        url : "chapitre-78",
        img : "https://i.ibb.co/YZtTFVY/Beyblade-Burst-T15.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-78"
    },
    {
        id : 79,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 79",
        url : "chapitre-79",
        img : "https://i.ibb.co/883qHsW/Beyblade-Burst-T16.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-79"
    },
    {
        id : 80,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 80",
        url : "chapitre-80",
        img : "https://i.ibb.co/883qHsW/Beyblade-Burst-T16.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-80"
    },
    {
        id : 81,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 81",
        url : "chapitre-81",
        img : "https://i.ibb.co/883qHsW/Beyblade-Burst-T16.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-81"
    },
    {
        id : 82,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 82",
        url : "chapitre-82",
        img : "https://i.ibb.co/883qHsW/Beyblade-Burst-T16.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-82"
    },
    {
        id : 83,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 83",
        url : "chapitre-83",
        img : "https://i.ibb.co/883qHsW/Beyblade-Burst-T16.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-83"
    },
    {
        id : 84,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 84",
        url : "chapitre-84",
        img : "https://i.ibb.co/883qHsW/Beyblade-Burst-T16.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-84"
    },
    {
        id : 85,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 85",
        url : "chapitre-85",
        img : "https://i.ibb.co/883qHsW/Beyblade-Burst-T16.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-85"
    },
    {
        id : 86,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 86",
        url : "chapitre-86",
        img : "https://i.ibb.co/kyvsCTB/Beyblade-Burst-T17.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-86"
    },
    {
        id : 87,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 87",
        url : "chapitre-87",
        img : "https://i.ibb.co/kyvsCTB/Beyblade-Burst-T17.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-87"
    },
    {
        id : 88,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 88",
        url : "chapitre-88",
        img : "https://i.ibb.co/kyvsCTB/Beyblade-Burst-T17.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-88"
    },
    {
        id : 89,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 89",
        url : "chapitre-89",
        img : "https://i.ibb.co/kyvsCTB/Beyblade-Burst-T17.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-89"
    },
    {
        id : 90,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 90",
        url : "chapitre-90",
        img : "https://i.ibb.co/kyvsCTB/Beyblade-Burst-T17.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-90"
    },
    {
        id : 91,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 91",
        url : "chapitre-91",
        img : "https://i.ibb.co/kyvsCTB/Beyblade-Burst-T17.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-91"
    },
    {
        id : 92,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 92",
        url : "chapitre-92",
        img : "https://i.ibb.co/kyvsCTB/Beyblade-Burst-T17.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-92"
    },
    {
        id : 93,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 93",
        url : "chapitre-93",
        img : "https://i.ibb.co/QdjcrBk/Beyblade-Burst-T18.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-93"
    },
    {
        id : 94,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 94",
        url : "chapitre-94",
        img : "https://i.ibb.co/QdjcrBk/Beyblade-Burst-T18.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-94"
    },
    {
        id : 95,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 95",
        url : "chapitre-95",
        img : "https://i.ibb.co/QdjcrBk/Beyblade-Burst-T18.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-95"
    },
    {
        id : 96,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 96",
        url : "chapitre-96",
        img : "https://i.ibb.co/QdjcrBk/Beyblade-Burst-T18.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-96"
    },
    {
        id : 97,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 97",
        url : "chapitre-97",
        img : "https://i.ibb.co/QdjcrBk/Beyblade-Burst-T18.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-97"
    },
    {
        id : 98,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 98",
        url : "chapitre-98",
        img : "https://i.ibb.co/QdjcrBk/Beyblade-Burst-T18.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-98"
    },
    {
        id : 99,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 99",
        url : "chapitre-99",
        img : "https://i.ibb.co/QdjcrBk/Beyblade-Burst-T18.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-99"
    },
    {
        id : 100,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 100",
        url : "chapitre-100",
        img : "https://i.ibb.co/k9Wg5p3/Beyblade-Burst-T19.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-100"
    },
    {
        id : 101,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 101",
        url : "chapitre-101",
        img : "https://i.ibb.co/k9Wg5p3/Beyblade-Burst-T19.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-101"
    },
    {
        id : 102,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 102",
        url : "chapitre-102",
        img : "https://i.ibb.co/k9Wg5p3/Beyblade-Burst-T19.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-102"
    },
    {
        id : 103,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 103",
        url : "chapitre-103",
        img : "https://i.ibb.co/k9Wg5p3/Beyblade-Burst-T19.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-103"
    },
    {
        id : 104,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 104",
        url : "chapitre-104",
        img : "https://i.ibb.co/k9Wg5p3/Beyblade-Burst-T19.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-104"
    },
    {
        id : 104.5,
        titre : "Beyblade Burst",
        chapitre : "Épisode Spécial",
        url : "chapitre-104.5-1",
        img : "https://i.ibb.co/k9Wg5p3/Beyblade-Burst-T19.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-104.5-1"
    },
    {
        id : 104.6,
        titre : "Beyblade Burst",
        chapitre : "Épisode Spécial",
        url : "chapitre-104.5-2",
        img : "https://i.ibb.co/k9Wg5p3/Beyblade-Burst-T19.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-104.5-2"
    },
    {
        id : 105,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 105",
        url : "chapitre-105",
        img : "https://i.ibb.co/w4Rv74N/Beyblade-Burst-T20-End.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-105"
    },
    {
        id : 106,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 106",
        url : "chapitre-106",
        img : "https://i.ibb.co/w4Rv74N/Beyblade-Burst-T20-End.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-106"
    },
    {
        id : 107,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 107",
        url : "chapitre-107",
        img : "https://i.ibb.co/w4Rv74N/Beyblade-Burst-T20-End.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-107"
    },
    {
        id : 108,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 108",
        url : "chapitre-108",
        img : "https://i.ibb.co/w4Rv74N/Beyblade-Burst-T20-End.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-108"
    },
    {
        id : 109,
        titre : "Beyblade Burst",
        chapitre : "Chapitre 109",
        url : "chapitre-109",
        img : "https://i.ibb.co/w4Rv74N/Beyblade-Burst-T20-End.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-109"
    },
    {
        id : 109.5,
        titre : "Beyblade Burst",
        chapitre : "Épisode Spécial",
        url : "chapitre-109.5",
        img : "https://i.ibb.co/w4Rv74N/Beyblade-Burst-T20-End.webp",
        vf : true,
        vflink : "/burst/scans/chapitre-109.5"
    }
]

export default listeChapitres