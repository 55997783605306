const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/vcSHxpF/0048-024.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/HgvjGcg/0048-025.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/nR6ZzvP/0048-026.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/D4R4FBy/0048-027.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/Qdmw03d/0048-028.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/SBHGR9S/0048-029.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/mN8VZfJ/0048-030.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/r3hNFJ0/0048-031.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/bj0vg9M/0048-032.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/TgStNn2/0048-033.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/F6BZgxN/0048-034.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/0Gz2ZCj/0048-035.webp"
  }
];


export default listePages;