const listePages = [
    { page: 1, link: "https://i.ibb.co/FVrpGwZ/0056-001.webp" },
    { page: 2, link: "https://i.ibb.co/6Z5Z1gn/0056-002.webp" },
    { page: 3, link: "https://i.ibb.co/Dk9vDzr/0056-003.webp" },
    { page: 4, link: "https://i.ibb.co/L636MMV/0056-004.webp" },
    { page: 5, link: "https://i.ibb.co/FJRknBX/0056-005.webp" },
    { page: 6, link: "https://i.ibb.co/bWjHkLP/0056-006.webp" },
    { page: 7, link: "https://i.ibb.co/89NWRVt/0056-007.webp" },
    { page: 8, link: "https://i.ibb.co/P5pT7Kc/0056-008.webp" },
    { page: 9, link: "https://i.ibb.co/WD5YkTC/0056-009.webp" },
    { page: 10, link: "https://i.ibb.co/ChzBbvq/0056-010.webp" },
    { page: 11, link: "https://i.ibb.co/t2VpMCX/0056-011.webp" },
    { page: 12, link: "https://i.ibb.co/FK9RNJp/0056-012.webp" },
    { page: 13, link: "https://i.ibb.co/VBD27gV/0056-013.webp" },
    { page: 14, link: "https://i.ibb.co/MC2KfH0/0056-014.webp" },
    { page: 15, link: "https://i.ibb.co/bF5LsKZ/0056-015.webp" },
    { page: 16, link: "https://i.ibb.co/t4VGRhn/0056-016.webp" },
    { page: 17, link: "https://i.ibb.co/HNR7w1Z/0056-017.webp" },
    { page: 18, link: "https://i.ibb.co/CKkxsGS/0056-018.webp" },
    { page: 19, link: "https://i.ibb.co/NtQ3gM7/0056-019.webp" },
    { page: 20, link: "https://i.ibb.co/R6BHjPv/0056-020.webp" },
    { page: 21, link: "https://i.ibb.co/5K3pGp4/0056-021.webp" },
    { page: 22, link: "https://i.ibb.co/Wk60MVg/0056-022.webp" },
    { page: 23, link: "https://i.ibb.co/1Zpgs3B/0056-023.webp" },
    { page: 24, link: "https://i.ibb.co/kQtVrp7/0056-024.webp" },
    { page: 25, link: "https://i.ibb.co/6gw5gzc/0056-025.webp" },
    { page: 26, link: "https://i.ibb.co/vmfSbzN/0056-026.webp" },
    { page: 27, link: "https://i.ibb.co/XZ2XvDf/0056-027.webp" },
    { page: 28, link: "https://i.ibb.co/tZNJbS0/0056-028.webp" },
    { page: 29, link: "https://i.ibb.co/DY4G5w5/0056-029.webp" },
    { page: 30, link: "https://i.ibb.co/d5fSxdz/0056-030.webp" },
    { page: 31, link: "https://i.ibb.co/FHjCCSf/0056-031.webp" },
    { page: 32, link: "https://i.ibb.co/dDxh6gx/0056-032.webp" }
  ]
  

export default listePages;