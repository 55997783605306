const listePages = [
  { page: 1, link: "https://i.ibb.co/HXRWc2z/0076-001.webp" },
  { page: 2, link: "https://i.ibb.co/68CdZSc/0076-002.webp" },
  { page: 3, link: "https://i.ibb.co/dbYZCmp/0076-003.webp" },
  { page: 4, link: "https://i.ibb.co/7rhTSQB/0076-004.webp" },
  { page: 5, link: "https://i.ibb.co/72mcJT6/0076-005.webp" },
  { page: 6, link: "https://i.ibb.co/vBS6wQJ/0076-006.webp" },
  { page: 7, link: "https://i.ibb.co/Kh2mv52/0076-007.webp" },
  { page: 8, link: "https://i.ibb.co/qM4J6qw/0076-008.webp" },
  { page: 9, link: "https://i.ibb.co/nP5CXc9/0076-009.webp" },
  { page: 10, link: "https://i.ibb.co/XVpHnSL/0076-010.webp" },
  { page: 11, link: "https://i.ibb.co/0Mc5bnD/0076-011.webp" },
  { page: 12, link: "https://i.ibb.co/QDW8tfw/0076-012.webp" },
  { page: 13, link: "https://i.ibb.co/Fx0CxrT/0076-013.webp" },
  { page: 14, link: "https://i.ibb.co/vcnNzNg/0076-014.webp" },
  { page: 15, link: "https://i.ibb.co/9qjYH02/0076-015.webp" },
  { page: 16, link: "https://i.ibb.co/4mjw0DV/0076-016.webp" },
  { page: 17, link: "https://i.ibb.co/cbyhJ1D/0076-017.webp" },
  { page: 18, link: "https://i.ibb.co/y0vmywQ/0076-018.webp" },
  { page: 19, link: "https://i.ibb.co/r0YsP76/0076-019.webp" },
  { page: 20, link: "https://i.ibb.co/ZBjkBj8/0076-020.webp" },
  { page: 21, link: "https://i.ibb.co/tPcs2s4/0076-021.webp" },
  { page: 22, link: "https://i.ibb.co/x80PRxC/0076-022.webp" }
]


export default listePages;