/* eslint-disable default-case */
import { Helmet } from 'react-helmet';
import MenuFilms from '../components/MenuFilms';
import { useParams } from 'react-router-dom';

export default function FilmMenu() {
    const param = useParams()
    const generationURL = param.generation

    let filmName;

    switch (generationURL) {
        case "bakuten":
            filmName = "Bakuten Shoot Beyblade - Takao VS Daichi"
            break
        case "metal":
            filmName = "Metal Fight Beyblade - Sol Blaze"
            break
    }

    return (
        <>
            {filmName !== undefined ? (
                <Helmet>
                    <title>{filmName} | Streaming Espace</title>
                    <link rel="canonical" href={`https://www.streaming-espace.fr/${generationURL}/film`}/>
                    <meta name="description" content={"Découvre le film " + filmName + '.'} />
                </Helmet>
            ) : (<></>)}
            <main className="content container Menufilm">
                <MenuFilms />
            </main>                
        </>
    )       
}