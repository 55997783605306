const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/TcyT1z6/0037-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/SNH9d7n/0037-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/DbrLDnn/0037-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/0qBfPzj/0037-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/28tKRPz/0037-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/vcb7m42/0037-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/zngR2FG/0037-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/d5X6MwP/0037-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/WVddwhW/0037-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/R9rNQnS/0037-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/5RFNnzd/0037-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/1vdMk3S/0037-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/KmjyPhz/0037-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/L9Dc883/0037-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/ZH7KTzJ/0037-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/YZ4byWM/0037-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/3FTSGTp/0037-017.webp"
  }
]


export default listePages;