const listeEpisodes = [
    {
        episode : 1,
        link : "https://www.youtube.com/embed/SAejkfMQKe4"
    },
    {
        episode : 2,
        link : "https://www.youtube.com/embed/BB0ImQ7va44"
    },
    {
        episode : 3,
        link : "https://www.youtube.com/embed/B9V-0jwsD1Q"
    },
    {
        episode : 4,
        link : "https://www.youtube.com/embed/v4d7FPlwinI"
    },
    {
        episode : 5,
        link : "https://www.youtube.com/embed/0eB_fUpAvhU"
    },
    {
        episode : 6,
        link : "https://www.youtube.com/embed/D5c_Oiss9iA"
    },
    {
        episode : 7,
        link : "https://www.youtube.com/embed/oZCDUZWuMok"
    },
    {
        episode : 8,
        link : "https://www.youtube.com/embed/_1ktCrdtH2g"
    },
    {
        episode : 9,
        link : "https://www.youtube.com/embed/9neALO0LLpQ"
    },
    {
        episode : 10,
        link : "https://www.youtube.com/embed/Tzu14LVTIW8"
    },
    {
        episode : 11,
        link : "https://www.youtube.com/embed/sEJgZeNiSCo"
    },
    {
        episode : 12,
        link : "https://www.youtube.com/embed/q84rtTcCnyc"
    },
    {
        episode : 13,
        link : "https://www.youtube.com/embed/TEJlSzv70qU"
    }
]

export default listeEpisodes;