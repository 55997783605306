const listePages = [
    {
        "page": 1,
        "link": "https://i.ibb.co/SPwzmY8/0001.webp"
      },
      {
        "page": 2,
        "link": "https://i.ibb.co/ZdLskPc/0003.webp"
      },
      {
        "page": 3,
        "link": "https://i.ibb.co/yQxVt5C/0004.webp"
      },
      {
        "page": 4,
        "link": "https://i.ibb.co/pQy5b13/0005.webp"
      },
      {
        "page": 5,
        "link": "https://i.ibb.co/Kw4zWBj/0006.webp"
      },
      {
        "page": 6,
        "link": "https://i.ibb.co/d6bNHNw/0007.webp"
      },
      {
        "page": 7,
        "link": "https://i.ibb.co/qgD8YKS/0008.webp"
      },
      {
        "page": 8,
        "link": "https://i.ibb.co/xzj78DL/0009.webp"
      },
      {
        "page": 9,
        "link": "https://i.ibb.co/fVgy1k5/0010.webp"
      },
      {
        "page": 10,
        "link": "https://i.ibb.co/Trj7PqS/0011.webp"
      },
      {
        "page": 11,
        "link": "https://i.ibb.co/FH8QB1h/0012.webp"
      },
      {
        "page": 12,
        "link": "https://i.ibb.co/jw1n3Ck/0013.webp"
      },
      {
        "page": 13,
        "link": "https://i.ibb.co/7Kv49Ct/0014.webp"
      },
      {
        "page": 14,
        "link": "https://i.ibb.co/L6nnJzc/0015.webp"
      },
      {
        "page": 15,
        "link": "https://i.ibb.co/gJ1x7GL/0016.webp"
      },
      {
        "page": 16,
        "link": "https://i.ibb.co/cXmq8Yz/0017.webp"
      },
      {
        "page": 17,
        "link": "https://i.ibb.co/XxG7KZC/0018.webp"
      },
      {
        "page": 18,
        "link": "https://i.ibb.co/Z872HJ7/0019.webp"
      },
      {
        "page": 19,
        "link": "https://i.ibb.co/jwf6SRC/0020.webp"
      },
      {
        "page": 20,
        "link": "https://i.ibb.co/M8227VH/0021.webp"
      },
      {
        "page": 21,
        "link": "https://i.ibb.co/7jFt0B5/0022.webp"
      },
      {
        "page": 22,
        "link": "https://i.ibb.co/5xB7Qrh/0023.webp"
      },
      {
        "page": 23,
        "link": "https://i.ibb.co/wMJBnpB/0024.webp"
      },
      {
        "page": 24,
        "link": "https://i.ibb.co/nwYc8SS/0025.webp"
      },
      {
        "page": 25,
        "link": "https://i.ibb.co/3RbK2W3/0026.webp"
      },
      {
        "page": 26,
        "link": "https://i.ibb.co/qY1zbL3/0027.webp"
      },
      {
        "page": 27,
        "link": "https://i.ibb.co/zhCJFzV/0028.webp"
      },
      {
        "page": 28,
        "link": "https://i.ibb.co/MZ14jHf/0029.webp"
      }
]


export default listePages;