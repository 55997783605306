const listePages = [
    { page: 1, link: "https://i.ibb.co/52NycQX/p01.webp" },
    { page: 2, link: "https://i.ibb.co/ChYJ72W/p02.webp" },
    { page: 3, link: "https://i.ibb.co/bF40hrw/p03.webp" },
    { page: 4, link: "https://i.ibb.co/kSWh2CL/p04.webp" },
    { page: 5, link: "https://i.ibb.co/jDTb9nr/p05.webp" },
    { page: 6, link: "https://i.ibb.co/t8GY5TR/p06.webp" },
    { page: 7, link: "https://i.ibb.co/DWtXHZw/p07.webp" },
    { page: 8, link: "https://i.ibb.co/gWyxgQn/p08.webp" },
    { page: 9, link: "https://i.ibb.co/YkfQxdG/p09.webp" },
    { page: 10, link: "https://i.ibb.co/DY9XQTF/p10.webp" },
    { page: 11, link: "https://i.ibb.co/wBWrTxY/p11.webp" },
    { page: 12, link: "https://i.ibb.co/Qcgd6mn/p12.webp" },
    { page: 13, link: "https://i.ibb.co/Ltp96mq/p13.webp" },
    { page: 14, link: "https://i.ibb.co/vvJ0Ngc/p14.webp" },
    { page: 15, link: "https://i.ibb.co/9n4z0SB/p15.webp" },
    { page: 16, link: "https://i.ibb.co/2vC5pfV/p16.webp" },
    { page: 17, link: "https://i.ibb.co/PT0HMph/p17.webp" },
    { page: 18, link: "https://i.ibb.co/hZvbg8w/p18.webp" },
    { page: 19, link: "https://i.ibb.co/T8NB1W5/p19.webp" },
    { page: 20, link: "https://i.ibb.co/KXD7Z2Y/p20.webp" },
    { page: 21, link: "https://i.ibb.co/pxMQwJF/p21.webp" },
    { page: 22, link: "https://i.ibb.co/9T9PDv2/p22.webp" },
    { page: 23, link: "https://i.ibb.co/4mKSPct/p23.webp" },
    { page: 24, link: "https://i.ibb.co/8dbtVDK/p24.webp" },
    { page: 25, link: "https://i.ibb.co/Fn50gX6/p25.webp" },
    { page: 26, link: "https://i.ibb.co/NCMqSJG/p26.webp" },
    { page: 27, link: "https://i.ibb.co/N3pNh9M/p27.webp" },
    { page: 28, link: "https://i.ibb.co/wS11cb1/p28.webp" },
    { page: 29, link: "https://i.ibb.co/zNQ2g0c/p29.webp" },
    { page: 30, link: "https://i.ibb.co/kg2pYFp/p30.webp" },
    { page: 31, link: "https://i.ibb.co/S3dh33f/p31.webp" },
    { page: 32, link: "https://i.ibb.co/G5ypsvp/p32.webp" },
    { page: 33, link: "https://i.ibb.co/s5ZK7n6/p33.webp" },
    { page: 34, link: "https://i.ibb.co/CM4mh8m/p34.webp" },
    { page: 35, link: "https://i.ibb.co/CwHvNNr/p35.webp" },
    { page: 36, link: "https://i.ibb.co/mHL9z8K/p36.webp" },
    { page: 37, link: "https://i.ibb.co/k8HYH1Q/p37.webp" },
    { page: 38, link: "https://i.ibb.co/3pqGHCS/p38.webp" },
    { page: 39, link: "https://i.ibb.co/yqGLdz6/p39.webp" },
    { page: 40, link: "https://i.ibb.co/NpvwNbd/p40.webp" },
    { page: 41, link: "https://i.ibb.co/s94F3Y6/p41.webp" },
    { page: 42, link: "https://i.ibb.co/sJ2dCwp/p42.webp" },
    { page: 43, link: "https://i.ibb.co/d7LQSsQ/p43.webp" },
    { page: 44, link: "https://i.ibb.co/QNVn73c/p44.webp" },
    { page: 45, link: "https://i.ibb.co/0cZpkpM/p45.webp" },
    { page: 46, link: "https://i.ibb.co/tB33nhd/p46.webp" },
    { page: 47, link: "https://i.ibb.co/C24gDPC/p47.webp" },
    { page: 48, link: "https://i.ibb.co/ck7yBzQ/p48.webp" },
    { page: 49, link: "https://i.ibb.co/gJ9B8L8/p49.webp" },
    { page: 50, link: "https://i.ibb.co/H4jMPDc/p50.webp" },
    { page: 51, link: "https://i.ibb.co/Tt9SmHp/p51.webp" },
    { page: 52, link: "https://i.ibb.co/qW3RVtF/p52.webp" },
    { page: 53, link: "https://i.ibb.co/Pc5JML8/p53.webp" },
    { page: 54, link: "https://i.ibb.co/r0MV3qm/p54.webp" },
    { page: 55, link: "https://i.ibb.co/8NBkKM7/p55.webp" },
    { page: 56, link: "https://i.ibb.co/Br2r6Vk/p56.webp" },
    { page: 57, link: "https://i.ibb.co/ZKMQ0yZ/p57.webp" },
    { page: 58, link: "https://i.ibb.co/xhKYh9q/p58.webp" },
    { page: 59, link: "https://i.ibb.co/sCbZzFf/p59.webp" },
    { page: 60, link: "https://i.ibb.co/wW0CYRK/p60.webp" },
    { page: 61, link: "https://i.ibb.co/R6R2NBD/p61.webp" },
    { page: 62, link: "https://i.ibb.co/WFpnZcz/p62.webp" },
    { page: 63, link: "https://i.ibb.co/3YwxG8R/p63.webp" },
    { page: 64, link: "https://i.ibb.co/yk52Q3d/p64.webp" }
]

export default listePages;