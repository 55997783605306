const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/5WGgpKW/0043-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/5hDM5g4/0043-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/3FXfnYq/0043-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/xjJ71hh/0043-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/S77RThs/0043-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/6wr9TnL/0043-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/fMKzVjZ/0043-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/sVrP9jk/0043-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/9gBm2Nk/0043-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/jrp35Cw/0043-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/QQBJ4VZ/0043-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/f2j8NWF/0043-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/r28LkJK/0043-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/QFTRG13/0043-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/BgQ9hrv/0043-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/5TbDCpZ/0043-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/2hQtSv0/0043-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/gy1gzgY/0043-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/HqXPY42/0043-019.webp"
  },
  {
    page: 20,
    link: "https://i.ibb.co/TgBK3zX/0043-020.webp"
  },
  {
    page: 21,
    link: "https://i.ibb.co/84xV8Wh/0043-021.webp"
  },
  {
    page: 22,
    link: "https://i.ibb.co/gdSJZqN/0043-022.webp"
  },
  {
    page: 23,
    link: "https://i.ibb.co/88WZxC1/0043-023.webp"
  },
  {
    page: 24,
    link: "https://i.ibb.co/HYMtcN0/0043-024.webp"
  },
  {
    page: 25,
    link: "https://i.ibb.co/Ct9CZRW/0043-025.webp"
  },
  {
    page: 26,
    link: "https://i.ibb.co/cN2dYxw/0043-026.webp"
  },
  {
    page: 27,
    link: "https://i.ibb.co/Pzq4MP4/0043-027.webp"
  },
  {
    page: 28,
    link: "https://i.ibb.co/sqydngZ/0043-028.webp"
  },
  {
    page: 29,
    link: "https://i.ibb.co/s9jq25J/0043-029.webp"
  },
  {
    page: 30,
    link: "https://i.ibb.co/ZRs4myG/0043-030.webp"
  },
  {
    page: 31,
    link: "https://i.ibb.co/1nStzdR/0043-031.webp"
  },
  {
    page: 32,
    link: "https://i.ibb.co/gtCx6pj/0043-032.webp"
  },
  {
    page: 33,
    link: "https://i.ibb.co/VvGGyJ7/0043-033.webp"
  }
];


export default listePages;