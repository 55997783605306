const listePages = [
  { page: 1, link: "https://i.ibb.co/h7Fqg2W/0071-001.webp" },
  { page: 2, link: "https://i.ibb.co/qRs7wz2/0071-002.webp" },
  { page: 3, link: "https://i.ibb.co/87c8XV4/0071-003.webp" },
  { page: 4, link: "https://i.ibb.co/mqxLXZD/0071-004.webp" },
  { page: 5, link: "https://i.ibb.co/gWxdPVb/0071-005.webp" },
  { page: 6, link: "https://i.ibb.co/1vDmkmW/0071-006.webp" },
  { page: 7, link: "https://i.ibb.co/tpNXSHP/0071-007.webp" },
  { page: 8, link: "https://i.ibb.co/2cMv1hx/0071-008.webp" },
  { page: 9, link: "https://i.ibb.co/r3XQFCf/0071-009.webp" },
  { page: 10, link: "https://i.ibb.co/CH6r7W9/0071-010.webp" },
  { page: 11, link: "https://i.ibb.co/wB04LqY/0071-011.webp" },
  { page: 12, link: "https://i.ibb.co/hVCTdFP/0071-012.webp" },
  { page: 13, link: "https://i.ibb.co/9qSpfp9/0071-013.webp" },
  { page: 14, link: "https://i.ibb.co/gZVN1NW/0071-014.webp" },
  { page: 15, link: "https://i.ibb.co/P4DSg6G/0071-015.webp" },
  { page: 16, link: "https://i.ibb.co/Yts8vmr/0071-016.webp" },
  { page: 17, link: "https://i.ibb.co/h8FCL5c/0071-017.webp" },
  { page: 18, link: "https://i.ibb.co/pdYkfsG/0071-018.webp" },
  { page: 19, link: "https://i.ibb.co/kQb5mSz/0071-019.webp" },
  { page: 20, link: "https://i.ibb.co/mhDRhxs/0071-020.webp" },
  { page: 21, link: "https://i.ibb.co/5krfHGW/0071-021.webp" },
  { page: 22, link: "https://i.ibb.co/mDdTt8w/0071-022.webp" },
  { page: 23, link: "https://i.ibb.co/wcNp5GZ/0071-023.webp" },
  { page: 24, link: "https://i.ibb.co/tYWwPG3/0071-024.webp" },
  { page: 25, link: "https://i.ibb.co/HX1LSky/0071-025.webp" },
  { page: 26, link: "https://i.ibb.co/4dVrM6S/0071-026.webp" },
  { page: 27, link: "https://i.ibb.co/LYVDsWs/0071-027.webp" },
  { page: 28, link: "https://i.ibb.co/QcGMPx0/0071-028.webp" },
  { page: 29, link: "https://i.ibb.co/YPRfCW1/0071-029.webp" },
  { page: 30, link: "https://i.ibb.co/QHqS8Kr/0071-030.webp" },
  { page: 31, link: "https://i.ibb.co/nnW6Vt5/0071-031.webp" },
  { page: 32, link: "https://i.ibb.co/ZXHcPXn/0071-032.webp" }
]


export default listePages;