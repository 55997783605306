const listePages = [
  { page: 1, link: "https://i.ibb.co/0Q7kWS7/52-6-001.webp" },
  { page: 2, link: "https://i.ibb.co/jf5dMdL/52-6-002.webp" },
  { page: 3, link: "https://i.ibb.co/Hgg8506/52-6-003.webp" },
  { page: 4, link: "https://i.ibb.co/6Jj5CJp/52-6-004.webp" },
  { page: 5, link: "https://i.ibb.co/cXHgwjK/52-6-005.webp" },
  { page: 6, link: "https://i.ibb.co/pKvXVMG/52-6-006.webp" },
  { page: 7, link: "https://i.ibb.co/jZNJ9hY/52-6-007.webp" },
  { page: 8, link: "https://i.ibb.co/dfPXqnc/52-6-008.webp" },
  { page: 9, link: "https://i.ibb.co/b201hfM/52-6-009.webp" },
  { page: 10, link: "https://i.ibb.co/qsJSN6V/52-6-010.webp" },
  { page: 11, link: "https://i.ibb.co/ZXRp3vG/52-6-011.webp" },
  { page: 12, link: "https://i.ibb.co/ygnKF6r/52-6-012.webp" },
  { page: 13, link: "https://i.ibb.co/w6jg1qH/52-6-013.webp" },
  { page: 14, link: "https://i.ibb.co/qy9TSJ1/52-6-014.webp" },
  { page: 15, link: "https://i.ibb.co/B397BWB/52-6-015.webp" },
  { page: 16, link: "https://i.ibb.co/wJYmfSN/52-6-016.webp" }
]

export default listePages;