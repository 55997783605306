const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/7jVdhhX/0002-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/tsGqy4s/0002-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/XCQ2dCg/0002-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/B2nBzht/0002-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/ctn0WxS/0002-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/Tc3CcVn/0002-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/zs3gJb4/0002-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/WVSSKK2/0002-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/yNrW0NG/0002-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/F7VYH2b/0002-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/7GWmL5N/0002-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/dPqzs7L/0002-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/tK0qkq7/0002-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/f9NM9Y9/0002-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/M5qZ7BN/0002-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/MMHcYM9/0002-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/5rhb28z/0002-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/ws1Z0vF/0002-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/FzJBsQP/0002-019.webp"
  },
  {
    page: 20,
    link: "https://i.ibb.co/kK5hKXw/0002-020.webp"
  },
  {
    page: 21,
    link: "https://i.ibb.co/sm1GyJM/0002-021.webp"
  },
  {
    page: 22,
    link: "https://i.ibb.co/q01crHr/0002-022.webp"
  },
  {
    page: 23,
    link: "https://i.ibb.co/yYYmXJh/0002-023.webp"
  },
  {
    page: 24,
    link: "https://i.ibb.co/sK412h3/0002-024.webp"
  },
  {
    page: 25,
    link: "https://i.ibb.co/H4wtCTg/0002-025.webp"
  },
  {
    page: 26,
    link: "https://i.ibb.co/hWTLn5M/0002-026.webp"
  },
  {
    page: 27,
    link: "https://i.ibb.co/CnrG51R/0002-027.webp"
  },
  {
    page: 28,
    link: "https://i.ibb.co/wQkNXMk/0002-028.webp"
  },
  {
    page: 29,
    link: "https://i.ibb.co/VgY6nZv/0002-029.webp"
  },
  {
    page: 30,
    link: "https://i.ibb.co/0J1YXps/0002-030.webp"
  },
  {
    page: 31,
    link: "https://i.ibb.co/wRNs3BL/0002-031.webp"
  },
  {
    page: 32,
    link: "https://i.ibb.co/2qdPNgx/0002-032.webp"
  },
  {
    page: 33,
    link: "https://i.ibb.co/Sxxh4kF/0002-033.webp"
  },
  {
    page: 34,
    link: "https://i.ibb.co/f1L2WbX/0002-034.webp"
  },
  {
    page: 35,
    link: "https://i.ibb.co/4NkTc2S/0002-035.webp"
  },
  {
    page: 36,
    link: "https://i.ibb.co/hMmNqRq/0002-036.webp"
  },
  {
    page: 37,
    link: "https://i.ibb.co/Jj4scx2/0002-037.webp"
  },
  {
    page: 38,
    link: "https://i.ibb.co/59CtF2D/0002-038.webp"
  },
  {
    page: 39,
    link: "https://i.ibb.co/qC8dXTk/0002-039.webp"
  },
  {
    page: 40,
    link: "https://i.ibb.co/72sNrKR/0002-040.webp"
  }
]

export default listePages;