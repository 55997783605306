const listeSaisons = [
    {
        id : 1,
        titre : "BeyWheels",
        saison : "BeyWheels",
        url : "beywheels",
        img : "https://i.ibb.co/2FZSRKd/Any-Conv-com-16-QFh-D9-Et.webp",
        synopsis : "Il y a très longtemps, une météorite s'est écrasée sur Terre. Les fragments de cette météorite ce sont séparés, certains fragments devinrent les Beyblade tandis que d'autre donnèrent naissance aux BeyWheelz.",
        vostfr : true,
        vostfrlink : "/spinoff/beywheels/vostfr",
        vf : true,
        vflink : "/spinoff/beywheels/vf"
    },
    {
        id : 2,
        titre : "BeyWarriors: BeyRaiderz",
        saison : "BeyWarriors: BeyRaiderz",
        url : "beywarriors-beyraiderz",
        img : "https://i.ibb.co/nkMwhjP/Any-Conv-com-17-TADkkt-F.webp",
        synopsis : "Six bêtes gardiennes assurent la protection et aident les villes à prospérer grâce à des batailles appelées BeyRaiderz. Un mal inconnu survient pour affronter les six personnages gardiens, forçant les six à s'unir.",
        vostfr : false,
        vostfrlink : "nope",
        vf : true,
        vflink : "/spinoff/beywarriors-beyraiderz/vf",
        needvo : true
    },
    {
        id : 3,
        titre : "BeyWarriors: Cyborg",
        saison : "BeyWarriors: Cyborg",
        url : "beywarriors-cyborg",
        img : "https://i.ibb.co/vzgGm00/Any-Conv-com-18-FLx-Iyk0.webp",
        synopsis : '"Teslandia" est une planète abandonnée, ignorée par le reste des planètes voisines en raison de son environnement difficile. Les Beywarriors doivent obtenir le plus de jetons possible. Ils trouvent de mystérieux lieux nommés "Sanctuaires"',
        vostfr : true,
        vostfrlink : "/spinoff/beywarriors-cyborg/vostfr"
    },
    {
        id : 4,
        titre : "Scans",
        saison : "Scans",
        url : "scans",
        img : "https://i.ibb.co/XJRVBB1/cover.webp",
        synopsis : "Tous les spin-offs de Beyblade comme les remakes Burst des anciennes générations !",
        vf : true,
        vflink : "/spinoff/scans/",
        scan : true
    }
]

export default listeSaisons