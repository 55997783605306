const listePages = [
    {
  "page": 1,
  "link": "https://i.ibb.co/SVjR5PS/0131.webp"
},
{
  "page": 2,
  "link": "https://i.ibb.co/QXzwsG0/0132.webp"
},
{
  "page": 3,
  "link": "https://i.ibb.co/W2VksHn/0133.webp"
},
{
  "page": 4,
  "link": "https://i.ibb.co/YBMXLLt/0134.webp"
},
{
  "page": 5,
  "link": "https://i.ibb.co/YjzQn9r/0135.webp"
},
{
  "page": 6,
  "link": "https://i.ibb.co/NTyZwVp/0136.webp"
},
{
  "page": 7,
  "link": "https://i.ibb.co/X5SDKJr/0137.webp"
},
{
  "page": 8,
  "link": "https://i.ibb.co/m6BQWrs/0138.webp"
},
{
  "page": 9,
  "link": "https://i.ibb.co/P9rYZxs/0139.webp"
},
{
  "page": 10,
  "link": "https://i.ibb.co/HhgZS1b/0140.webp"
},
{
  "page": 11,
  "link": "https://i.ibb.co/b1bFg36/0141.webp"
},
{
  "page": 12,
  "link": "https://i.ibb.co/JsMT2PC/0142.webp"
},
{
  "page": 13,
  "link": "https://i.ibb.co/z7n7Q18/0143.webp"
},
{
  "page": 14,
  "link": "https://i.ibb.co/ThwtGSt/0144.webp"
},
{
  "page": 15,
  "link": "https://i.ibb.co/hgs9zmC/0145.webp"
},
{
  "page": 16,
  "link": "https://i.ibb.co/GskNnW0/0146.webp"
},
{
  "page": 17,
  "link": "https://i.ibb.co/hKvjmJj/0147.webp"
},
{
  "page": 18,
  "link": "https://i.ibb.co/tzvYZcV/0148.webp"
},
{
  "page": 19,
  "link": "https://i.ibb.co/bvmKNck/0149.webp"
},
{
  "page": 20,
  "link": "https://i.ibb.co/Ny6Z6FS/0150.webp"
},
{
  "page": 21,
  "link": "https://i.ibb.co/0BVXYKL/0151.webp"
},
{
  "page": 22,
  "link": "https://i.ibb.co/FsQxNG0/0152.webp"
},
{
  "page": 23,
  "link": "https://i.ibb.co/QMYn0ct/0153.webp"
},
{
  "page": 24,
  "link": "https://i.ibb.co/chJDJD0/0154.webp"
},
{
  "page": 25,
  "link": "https://i.ibb.co/N7ZH8hf/0155.webp"
},
{
  "page": 26,
  "link": "https://i.ibb.co/mh5gjRj/0156.webp"
},
{
  "page": 27,
  "link": "https://i.ibb.co/DWC0ZRp/0157.webp"
},
{
  "page": 28,
  "link": "https://i.ibb.co/N7jq96p/0158.webp"
},
{
  "page": 29,
  "link": "https://i.ibb.co/3S30YZz/0159.webp"
},
{
  "page": 30,
  "link": "https://i.ibb.co/vs2tH01/0160.webp"
},
{
  "page": 31,
  "link": "https://i.ibb.co/QPnR282/0161.webp"
},
{
  "page": 32,
  "link": "https://i.ibb.co/nf0PQSG/0162.webp"
},
{
  "page": 33,
  "link": "https://i.ibb.co/VgXr5F0/0163.webp"
},
{
  "page": 34,
  "link": "https://i.ibb.co/GJBnMkQ/0164.webp"
},
{
  "page": 35,
  "link": "https://i.ibb.co/8z0C9b4/0165.webp"
},
{
  "page": 36,
  "link": "https://i.ibb.co/2yxZ2mn/0166.webp"
},
{
  "page": 37,
  "link": "https://i.ibb.co/mJgPBCz/0167.webp"
},
{
  "page": 38,
  "link": "https://i.ibb.co/wCX4Z07/0168.webp"
},
{
  "page": 39,
  "link": "https://i.ibb.co/YP5rMD0/0169.webp"
},
{
  "page": 40,
  "link": "https://i.ibb.co/w6tghdm/0170.webp"
},
{
  "page": 41,
  "link": "https://i.ibb.co/8cqkb4h/0171.webp"
},
{
  "page": 42,
  "link": "https://i.ibb.co/Z2KWBKb/0172.webp"
},
{
  "page": 43,
  "link": "https://i.ibb.co/dDrYhz7/0173.webp"
},
{
  "page": 44,
  "link": "https://i.ibb.co/g4nVqc9/0174.webp"
},
{
  "page": 45,
  "link": "https://i.ibb.co/5BHBNFQ/0175.webp"
},
{
  "page": 46,
  "link": "https://i.ibb.co/h8LJ318/0176.webp"
},
{
  "page": 47,
  "link": "https://i.ibb.co/sHY8ztw/0177.webp"
},
{
  "page": 48,
  "link": "https://i.ibb.co/tsQ9ysF/0178.webp"
}
]
  

export default listePages;