const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/ByZSQwp/0106-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/QmzyCVg/0106-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/MkxTPcS/0106-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/cLZLMjq/0106-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/89cnKST/0106-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/TbWW80V/0106-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/XJvgNMZ/0106-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/CPf0GdZ/0106-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/NZ21hn4/0106-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/GTczbs7/0106-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/qn7dspW/0106-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/C86ktpt/0106-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/BTj37np/0106-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/tmj0Vp4/0106-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/6Z0g9pP/0106-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/NrN0Dkm/0106-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/7171c0g/0106-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/nn7kgtL/0106-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/syMZfCz/0106-019.webp"
  },
  {
    page: 20,
    link: "https://i.ibb.co/VL83q6s/0106-020.webp"
  },
  {
    page: 21,
    link: "https://i.ibb.co/HgyvP4Z/0106-021.webp"
  },
  {
    page: 22,
    link: "https://i.ibb.co/kmgG338/0106-022.webp"
  },
  {
    page: 23,
    link: "https://i.ibb.co/qsZqDX5/0106-023.webp"
  },
  {
    page: 24,
    link: "https://i.ibb.co/prp6V92/0106-024.webp"
  },
  {
    page: 25,
    link: "https://i.ibb.co/1Qgj1J9/0106-025.webp"
  },
  {
    page: 26,
    link: "https://i.ibb.co/S5QQSDW/0106-026.webp"
  },
  {
    page: 27,
    link: "https://i.ibb.co/Tb5mKVM/0106-027.webp"
  },
  {
    page: 28,
    link: "https://i.ibb.co/t4KHD43/0106-028.webp"
  },
  {
    page: 29,
    link: "https://i.ibb.co/p3HLq5B/0106-029.webp"
  },
  {
    page: 30,
    link: "https://i.ibb.co/yP5kvQL/0106-030.webp"
  }
]


export default listePages;