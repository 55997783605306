const listePages = [
    {
      page: 1,
      link: "https://i.ibb.co/QKFvJ0t/0101-001.webp"
    },
    {
      page: 2,
      link: "https://i.ibb.co/MkL3qK7/0101-002.webp"
    },
    {
      page: 3,
      link: "https://i.ibb.co/mhCr3RP/0101-003.webp"
    },
    {
      page: 4,
      link: "https://i.ibb.co/mcTLhmh/0101-004.webp"
    },
    {
      page: 5,
      link: "https://i.ibb.co/GQGFYBf/0101-005.webp"
    },
    {
      page: 6,
      link: "https://i.ibb.co/gJDLk7m/0101-006.webp"
    },
    {
      page: 7,
      link: "https://i.ibb.co/VSWWD2p/0101-007.webp"
    },
    {
      page: 8,
      link: "https://i.ibb.co/nmRXX5r/0101-008.webp"
    },
    {
      page: 9,
      link: "https://i.ibb.co/yfQpZYF/0101-009.webp"
    },
    {
      page: 10,
      link: "https://i.ibb.co/xsVd2nC/0101-010.webp"
    },
    {
      page: 11,
      link: "https://i.ibb.co/QDwyjKW/0101-011.webp"
    },
    {
      page: 12,
      link: "https://i.ibb.co/3rGXPdv/0101-012.webp"
    },
    {
      page: 13,
      link: "https://i.ibb.co/r456NqK/0101-013.webp"
    },
    {
      page: 14,
      link: "https://i.ibb.co/mC8jnf5/0101-014.webp"
    },
    {
      page: 15,
      link: "https://i.ibb.co/fkKb7Dh/0101-015.webp"
    },
    {
      page: 16,
      link: "https://i.ibb.co/9vtL0NZ/0101-016.webp"
    },
    {
      page: 17,
      link: "https://i.ibb.co/HnbQzsn/0101-017.webp"
    },
    {
      page: 18,
      link: "https://i.ibb.co/b7rqwyT/0101-018.webp"
    },
    {
      page: 19,
      link: "https://i.ibb.co/QYLV9HL/0101-019.webp"
    },
    {
      page: 20,
      link: "https://i.ibb.co/nMRJ5dv/0101-020.webp"
    },
    {
      page: 21,
      link: "https://i.ibb.co/3BsJ1D1/0101-021.webp"
    },
    {
      page: 22,
      link: "https://i.ibb.co/4NWYB4r/0101-022.webp"
    },
    {
      page: 23,
      link: "https://i.ibb.co/QJkRFH6/0101-023.webp"
    },
    {
      page: 24,
      link: "https://i.ibb.co/2Pdh0XC/0101-024.webp"
    },
    {
      page: 25,
      link: "https://i.ibb.co/7QZVhM9/0101-025.webp"
    },
    {
      page: 26,
      link: "https://i.ibb.co/L1B6dtr/0101-026.webp"
    },
    {
      page: 27,
      link: "https://i.ibb.co/K5jBR7j/0101-027.webp"
    },
    {
      page: 28,
      link: "https://i.ibb.co/4JxK2jT/0101-028.webp"
    },
    {
      page: 29,
      link: "https://i.ibb.co/JmD6mvL/0101-029.webp"
    },
    {
      page: 30,
      link: "https://i.ibb.co/CJ9H9Sn/0101-030.webp"
    },
    {
      page: 31,
      link: "https://i.ibb.co/B6pKvr1/0101-031.webp"
    },
    {
      page: 32,
      link: "https://i.ibb.co/djwfk5v/0101-032.webp"
    }
  ]
  

export default listePages;