const listePages = [
    {
        page: 1,
        link: "https://i.ibb.co/gRLZ35H/0087.webp"
      },
      {
        page: 2,
        link: "https://i.ibb.co/SxQf03H/0088.webp"
      },
      {
        page: 3,
        link: "https://i.ibb.co/zVRZkBC/0089.webp"
      },
      {
        page: 4,
        link: "https://i.ibb.co/wSz2PQT/0090.webp"
      },
      {
        page: 5,
        link: "https://i.ibb.co/vDZ6Rvj/0091.webp"
      },
      {
        page: 6,
        link: "https://i.ibb.co/Q8TcrHk/0092.webp"
      },
      {
        page: 7,
        link: "https://i.ibb.co/rZdqbvX/0093.webp"
      },
      {
        page: 8,
        link: "https://i.ibb.co/rsQ8qgc/0094.webp"
      },
      {
        page: 9,
        link: "https://i.ibb.co/2ZsmyJP/0095.webp"
      },
      {
        page: 10,
        link: "https://i.ibb.co/CHq9dR3/0096.webp"
      },
      {
        page: 11,
        link: "https://i.ibb.co/ftBnQKw/0097.webp"
      },
      {
        page: 12,
        link: "https://i.ibb.co/3SMZbXL/0098.webp"
      },
      {
        page: 13,
        link: "https://i.ibb.co/3N56V50/0099.webp"
      },
      {
        page: 14,
        link: "https://i.ibb.co/BNdXP1c/0100.webp"
      },
      {
        page: 15,
        link: "https://i.ibb.co/Y2yghzm/0101.webp"
      },
      {
        page: 16,
        link: "https://i.ibb.co/n8kz0Wj/0102.webp"
      },
      {
        page: 17,
        link: "https://i.ibb.co/2FRysYk/0103.webp"
      },
      {
        page: 18,
        link: "https://i.ibb.co/Rcr8B6b/0104.webp"
      },
      {
        page: 19,
        link: "https://i.ibb.co/HXwp8S4/0105.webp"
      },
      {
        page: 20,
        link: "https://i.ibb.co/3RMJ5zN/0106.webp"
      },
      {
        page: 21,
        link: "https://i.ibb.co/xgCbFW0/0107.webp"
      },
      {
        page: 22,
        link: "https://i.ibb.co/0qyyBQ4/0108.webp"
      },
      {
        page: 23,
        link: "https://i.ibb.co/SmyfHzp/0109.webp"
      },
      {
        page: 24,
        link: "https://i.ibb.co/fQ3nSyp/0110.webp"
      },
      {
        page: 25,
        link: "https://i.ibb.co/NLDM8GR/0111.webp"
      },
      {
        page: 26,
        link: "https://i.ibb.co/t3Wn76q/0112.webp"
      },
      {
        page: 27,
        link: "https://i.ibb.co/f13pKQg/0113.webp"
      },
      {
        page: 28,
        link: "https://i.ibb.co/H7Bxs86/0114.webp"
      },
      {
        page: 29,
        link: "https://i.ibb.co/Hg7tCKs/0115.webp"
      },
      {
        page: 30,
        link: "https://i.ibb.co/ynxCD88/0116.webp"
      },
      {
        page: 31,
        link: "https://i.ibb.co/X78JpZb/0117.webp"
      },
      {
        page: 32,
        link: "https://i.ibb.co/9hy12rK/0118.webp"
      },
      {
        page: 33,
        link: "https://i.ibb.co/Y7ScRjh/0119.webp"
      },
      {
        page: 34,
        link: "https://i.ibb.co/gSymrcC/0120.webp"
      },
      {
        page: 35,
        link: "https://i.ibb.co/dBS6gcs/0121.webp"
      },
      {
        page: 36,
        link: "https://i.ibb.co/pyzbPQZ/0122.webp"
      }
]


export default listePages;