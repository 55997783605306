const listeEpisodes = [
    {
        episode: 1,
        link: "https://www.youtube.com/embed/nKwzbVpTnVc"
    },
    {
        episode: 2,
        link: "https://www.youtube.com/embed/NFbhw_bxjfE"
    },
    {
        episode: 3,
        link: "https://www.youtube.com/embed/g16WtvKrR3s"
    },
    {
        episode: 4,
        link: "https://www.youtube.com/embed/WuImnt9RD5U"
    },
    {
        episode: 5,
        link: "https://www.youtube.com/embed/O3kp1pJctkI"
    },
    {
        episode: 6,
        link: "https://www.youtube.com/embed/areqFzRoSPc"
    },
    {
        episode: 7,
        link: "https://www.youtube.com/embed/yn4AlPnGFSQ"
    },
    {
        episode: 8,
        link: "https://www.youtube.com/embed/49Y1B3CH5hI"
    },
    {
        episode: 9,
        link: "https://www.youtube.com/embed/tpsh9kB70_0"
    },
    {
        episode: 10,
        link: "https://www.youtube.com/embed/sKTp1Tm-1YA"
    },
	{
        episode : 11,
        multi: true,
        links : [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239099&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/38y5nxla"
            },
            {
                name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=5487044"
            }
        ]
    },
    {
        episode: 12,
        link: "https://www.youtube.com/embed/MVKBmqegIYk"
    },
    {
        episode: 13,
        link: "https://www.youtube.com/embed/99PY60eQemM"
    },
    {
        episode : 14,
        multi: true,
        links : [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239104&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/gfhfglbf"
            },
            {
                name: "YouTube",
                link : "https://www.youtube.com/embed/FC7WSGRAAVM"
            }            
        ]
    },
    {
        episode : 15,
        multi: true,
        links : [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239105&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/709i1285"
            },
            {
                name: "YouTube",
                link : "https://www.youtube.com/embed/VmtAH4cjJB8"
            }            
        ]
    },    
    {
        episode: 16,
        link: "https://www.youtube.com/embed/w2lBKUyT53Y"
    },
    {
        episode: 17,
        link: "https://www.youtube.com/embed/LL1UMhqJmko"
    },
    {
        episode: 18,
        link: "https://www.youtube.com/embed/3tQ675qAZDU"
    },
    {
        episode: 19,
        link: "https://www.youtube.com/embed/RLyM_O0rpkM"
    },
    {
        episode: 20,
        link: "https://www.youtube.com/embed/Ll0xPGaioZw"
    },
    {
        episode: 21,
        link: "https://www.youtube.com/embed/VDNNuBKypLU"
    },
    {
        episode: 22,
        link: "https://www.youtube.com/embed/SPm36GqxF4g"
    },
    {
        episode: 23,
        link: "https://www.youtube.com/embed/kBJQA-nvpI8"
    },
    {
        episode: 24,
        link: "https://www.youtube.com/embed/Bjl2-eYtQmU"
    },
    {
        episode: 25,
        link: "https://www.youtube.com/embed/KHFajI-ObCQ"
    },
    {
        episode: 26,
        link: "https://www.youtube.com/embed/i_J0jKoNp0M"
    }
]

export default listeEpisodes;