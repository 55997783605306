const listePages = [
  { 
    page: 1, 
    link: "https://i.ibb.co/DMSvYJL/0001.webp"
  },
  { 
    page: 2, 
    link: "https://i.ibb.co/zXjyhDh/0002.webp"
  },
  { 
    page: 3, 
    link: "https://i.ibb.co/Yb3K194/0003.webp"
  },
  { 
    page: 4, 
    link: "https://i.ibb.co/x7gmGkn/0004.webp"
  },
  { 
    page: 5, 
    link: "https://i.ibb.co/WvSzyX8/0005.webp"
  },
  { 
    page: 6, 
    link: "https://i.ibb.co/7t6xfs9/0006.webp"
  },
  { 
    page: 7, 
    link: "https://i.ibb.co/Lh9W7MQ/0007.webp"
  },
  { 
    page: 8, 
    link: "https://i.ibb.co/ZBb8Bgc/0008.webp"
  },
  { 
    page: 9, 
    link: "https://i.ibb.co/VqZF0DD/0009.webp"
  },
  { 
    page: 10, 
    link: "https://i.ibb.co/GsBLwVc/0010.webp"
  },
  { 
    page: 11, 
    link: "https://i.ibb.co/bNbVqvw/0011.webp"
  },
  { 
    page: 12, 
    link: "https://i.ibb.co/LhPh7p0/0012.webp"
  },
  { 
    page: 13, 
    link: "https://i.ibb.co/RD2bksc/0013.webp"
  },
  { 
    page: 14, 
    link: "https://i.ibb.co/xMjLVMc/0014.webp"
  },
  { 
    page: 15, 
    link: "https://i.ibb.co/yy32PR6/0015.webp"
  },
  { 
    page: 16, 
    link: "https://i.ibb.co/j43wkSv/0016.webp"
  },
  { 
    page: 17, 
    link: "https://i.ibb.co/Khr2RY1/0017.webp"
  },
  { 
    page: 18, 
    link: "https://i.ibb.co/zPZKdZ4/0018.webp"
  },
  { 
    page: 19, 
    link: "https://i.ibb.co/s3LnkdR/0019.webp"
  },
  { 
    page: 20, 
    link: "https://i.ibb.co/whJNKKh/0020.webp"
  },
  { 
    page: 21, 
    link: "https://i.ibb.co/rFBj91M/0021.webp"
  },
  { 
    page: 22, 
    link: "https://i.ibb.co/1R59NLG/0022.webp"
  },
  { 
    page: 23, 
    link: "https://i.ibb.co/sybPbt9/0023.webp"
  },
  { 
    page: 24, 
    link: "https://i.ibb.co/VVsjRb8/0024.webp"
  },
  { 
    page: 25, 
    link: "https://i.ibb.co/HGjNBS9/0025.webp"
  },
  { 
    page: 26, 
    link: "https://i.ibb.co/tHCkqFT/0026.webp"
  },
  { 
    page: 27, 
    link: "https://i.ibb.co/GkLbY7V/0027.webp"
  },
  { 
    page: 28, 
    link: "https://i.ibb.co/9pkmcVh/0028.webp"
  },
  { 
    page: 29, 
    link: "https://i.ibb.co/pWwWDJ1/0029.webp"
  },
  { 
    page: 30, 
    link: "https://i.ibb.co/k63Lx0s/0030.webp"
  },
  { 
    page: 31, 
    link: "https://i.ibb.co/yS1MHRV/0031.webp"
  },
  { 
    page: 32, 
    link: "https://i.ibb.co/crTKH5T/0032.webp"
  }
]

export default listePages;