const listePages = [
    {
        page: 1,
        link: "https://i.ibb.co/DYTV8wQ/0060.webp"
      },
      {
        page: 2,
        link: "https://i.ibb.co/Jnw1FYx/0061.webp"
      },
      {
        page: 3,
        link: "https://i.ibb.co/FJCrXsF/0062.webp"
      },
      {
        page: 4,
        link: "https://i.ibb.co/xJWPD8F/0063.webp"
      },
      {
        page: 5,
        link: "https://i.ibb.co/jMgdKcz/0064.webp"
      },
      {
        page: 6,
        link: "https://i.ibb.co/S5HdwNh/0065.webp"
      },
      {
        page: 7,
        link: "https://i.ibb.co/NrTdjD8/0065-1.webp"
      },
      {
        page: 8,
        link: "https://i.ibb.co/5M3McQn/0066.webp"
      },
      {
        page: 9,
        link: "https://i.ibb.co/NngQhhC/0067.webp"
      },
      {
        page: 10,
        link: "https://i.ibb.co/HnPysbf/0068.webp"
      },
      {
        page: 11,
        link: "https://i.ibb.co/M9BmyQN/0069.webp"
      },
      {
        page: 12,
        link: "https://i.ibb.co/NykSvNG/0070.webp"
      },
      {
        page: 13,
        link: "https://i.ibb.co/f0wvRRv/0071.webp"
      },
      {
        page: 14,
        link: "https://i.ibb.co/z8t63jC/0072.webp"
      },
      {
        page: 15,
        link: "https://i.ibb.co/wgGDJd1/0073.webp"
      },
      {
        page: 16,
        link: "https://i.ibb.co/sgw10NR/0074.webp"
      },
      {
        page: 17,
        link: "https://i.ibb.co/NNk8kB2/0075.webp"
      },
      {
        page: 18,
        link: "https://i.ibb.co/q13sdh0/0076.webp"
      },
      {
        page: 19,
        link: "https://i.ibb.co/ZJCJv2r/0077.webp"
      },
      {
        page: 20,
        link: "https://i.ibb.co/dGqDrLs/0078.webp"
      },
      {
        page: 21,
        link: "https://i.ibb.co/jVPLNW8/0079.webp"
      },
      {
        page: 22,
        link: "https://i.ibb.co/q71rNqC/0080.webp"
      },
      {
        page: 23,
        link: "https://i.ibb.co/NVS8qkN/0081.webp"
      },
      {
        page: 24,
        link: "https://i.ibb.co/yqG3kWZ/0082.webp"
      },
      {
        page: 25,
        link: "https://i.ibb.co/2PLP8d7/0083.webp"
      },
      {
        page: 26,
        link: "https://i.ibb.co/yFL8Hwq/0084.webp"
      },
      {
        page: 27,
        link: "https://i.ibb.co/DfqX7PF/0085.webp"
      },
      {
        page: 28,
        link: "https://i.ibb.co/B2snSfS/0086.webp"
      },
      {
        page: 29,
        link: "https://i.ibb.co/fH9G3ZJ/0087.webp"
      },
      {
        page: 30,
        link: "https://i.ibb.co/kQ8ctQG/0088.webp"
      },
      {
        page: 31,
        link: "https://i.ibb.co/19TQc9K/0088-1.webp"
      },
      {
        page: 32,
        link: "https://i.ibb.co/VYdcbR0/0089.webp"
      },
      {
        page: 33,
        link: "https://i.ibb.co/GMQwc9C/0090.webp"
      },
      {
        page: 34,
        link: "https://i.ibb.co/4YNrw5Q/0091.webp"
      },
      {
        page: 35,
        link: "https://i.ibb.co/FxJNBD4/0092.webp"
      },
      {
        page: 36,
        link: "https://i.ibb.co/8b3gx5r/0093.webp"
      },
      {
        page: 37,
        link: "https://i.ibb.co/0nTkcLV/0094.webp"
      },
      {
        page: 38,
        link: "https://i.ibb.co/gJz4T1j/0095.webp"
      },
      {
        page: 39,
        link: "https://i.ibb.co/XyvRkzx/0096.webp"
      },
      {
        page: 40,
        link: "https://i.ibb.co/XL3Ygrj/0097.webp"
      },
      {
        page: 41,
        link: "https://i.ibb.co/r5TKwD9/0098.webp"
      },
      {
        page: 42,
        link: "https://i.ibb.co/YDPKMFh/0099.webp"
      },
      {
        page: 43,
        link: "https://i.ibb.co/fSJWZZZ/0100.webp"
      },
      {
        page: 44,
        link: "https://i.ibb.co/7ynGvGC/0101.webp"
      }
]


export default listePages;