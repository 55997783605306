const listeEpisodes = [
    {
        episode: 1,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/OKqdQDkFOTk"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239178&hash=feb22664b77515f9"
            }
        ]
    },
    {
        episode: 2,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/STzXo5MexRg"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239179&hash=684c3f79aaa44457"
            }
        ]
    },
    {
        episode: 3,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/r8uQOUZDc10"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239180&hash=7b835634bccd9041"
            }
        ]
    },
    {
        episode: 4,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/szEPQWDARpg"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239181&hash=9e9d41d126fabd74"
            }
        ]
    },
    {
        episode: 5,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/Cyuy7iieBLI"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239182&hash=1b9fea24ea33ba5c"
            }
        ]
    },
    {
        episode: 6,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/K57USORhfPA"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239183&hash=a8474288aa581865"
            }
        ]
    },
    {
        episode: 7,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/Mp8WME1zlM4"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239184&hash=9dc0afcc0c523550"
            }
        ]
    },
    {
        episode: 8,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/CYUsMlUBqW8"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239185&hash=79569bd84fd80693"
            }
        ]
    },
    {
        episode: 9,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/ZR6EwbF0d3U"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239186&hash=a4f4da0a4795c365"
            }
        ]
    },
    {
        episode: 10,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/c6z6y0F07vc"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239187&hash=0bd4397d5117c9e1"
            }
        ]
    },
    {
        episode: 11,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/gr3cY3GfY7E"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239188&hash=d06cc21bb5c8a543"
            }
        ]
    },
    {
        episode: 12,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/FMvmD9STbxU"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239189&hash=e7453df2a00b916f"
            }
        ]
    },
    {
        episode: 13,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/YXWYFOxkT0E"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239190&hash=0cff370908d7e8ee"
            }
        ]
    },
    {
        episode: 14,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/-fmCGggv8wQ"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239191&hash=f53c701589478e3c"
            }
        ]
    },
    {
        episode: 15,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/hvRnH9jogfQ"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239192&hash=1d9a6d8b69ad3250"
            }
        ]
    },
    {
        episode: 16,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/9-KSJYC--dg"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239193&hash=58f8013c45460baf"
            }
        ]
    },
    {
        episode: 17,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/D2DFa0LkuKQ"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239194&hash=48c6248c99ad67f2"
            }
        ]
    },
    {
        episode: 18,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/pGS4whjkrxE"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239195&hash=565a5e7057ca4ca2"
            }
        ]
    },
    {
        episode: 19,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/UglzGf5qYqk"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239196&hash=b6c141cad917b3c1"
            }
        ]
    },
    {
        episode: 20,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/v1I8u4kT66E"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239197&hash=caffd634e2491f13"
            }
        ]
    },
    {
        episode: 21,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/_wiV41q4VRI"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239198&hash=90f868821d70f88a"
            }
        ]
    },
    {
        episode: 22,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/gnHpVeIfFNc"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239199&hash=fdd0df2c3b99ac85"
            }
        ]
    },
    {
        episode: 23,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/kNH8BgknjYI"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239200&hash=8019f3524c0e4d64"
            }
        ]
    },
    {
        episode: 24,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/QjO0hgxZLu8"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239201&hash=e36261a3671d61a5"
            }
        ]
    },
    {
        episode: 25,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/kICxzZC91Tk"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239202&hash=a730c1dbecb77b46"
            }
        ]
    },
    {
        episode: 26,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/ajOAgaJosrI"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239203&hash=4ee4e45972fc2421"
            }
        ]
    },
    {
        episode: 27,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/X5zsALvptBc"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239204&hash=528ba88de50f4c24"
            }
        ]
    },
    {
        episode: 28,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/OOzk6gEtF7s"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239205&hash=fc1da1976582c071"
            }
        ]
    },
    {
        episode: 29,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/HzOET3eZFuQ"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239206&hash=ac056d649654a46e"
            }
        ]
    },
    {
        episode: 30,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/RFwGnnVKUVw"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239207&hash=170aa9cd39af168d"
            }
        ]
    },
    {
        episode: 31,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/dyA5Ly4gZIY"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239208&hash=5a584c9eacacaea3"
            }
        ]
    },
    {
        episode: 32,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/WXe4YaR-Z5Q"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239209&hash=37adc60e65bff63c"
            }
        ]
    },
    {
        episode: 33,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/el5EfN4cdIk"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239210&hash=c2031986c53bc2f6"
            }
        ]
    },
    {
        episode: 34,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/JbgOcD0ivGw"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239211&hash=0bac1f73fa8fb9bc"
            }
        ]
    },
    {
        episode: 35,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/bkZWzxNDOK8"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239212&hash=6f3147faadeae3c9"
            }
        ]
    },
    {
        episode: 36,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/OX39CLjofjA"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239213&hash=f54f802e75de74b0"
            }
        ]
    },
    {
        episode: 37,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/BaAvudFEEM8"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239214&hash=ebb3b2807c708c2b"
            }
        ]
    },
    {
        episode: 38,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/GBQwPilWwLE"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239215&hash=f86b9474c4c40be7"
            }
        ]
    },
    {
        episode: 39,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/gODsUyE6jxk"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239216&hash=b81982f458fa90ff"
            }
        ]
    },
    {
        episode: 40,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/X-_gJ6GGa3c"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239217&hash=d2e1a7325cff58e1"
            }
        ]
    },
    {
        episode: 41,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/8fg6K67fsUE"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239218&hash=2f1bebaf768e8ed2"
            }
        ]
    },
    {
        episode: 42,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/1bZIhUiDa7w"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239219&hash=6fa5cd7fbe89c272"
            }
        ]
    },
    {
        episode: 43,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/cnvd1qqWyAg"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239220&hash=146e5cdec8d3f61a"
            }
        ]
    },
    {
        episode: 44,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/oEtI1uOA1wU"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239221&hash=7acec59bef26ef5d"
            }
        ]
    },
    {
        episode: 45,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/mJFzR9JN2bc"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239222&hash=2f4eb9fcf7058a70"
            }
        ]
    },
    {
        episode: 46,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/SrsrRdNqB-E"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239223&hash=60281285cfff211a"
            }
        ]
    },
    {
        episode: 47,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/Tv4QurpBTQY"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239224&hash=2729f9adf2d18d84"
            }
        ]
    },
    {
        episode: 48,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/DgL5iho3mys"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239225&hash=daa2c8fcbc4b2088"
            }
        ]
    },
    {
        episode: 49,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/mQm1wmi0XPw"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239226&hash=b84b8789482c3dd8"
            }
        ]
    },
    {
        episode: 50,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/Zzc6KgEukJ0"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239227&hash=ccf91a4fdb8e018e"
            }
        ]
    },
    {
        episode: 51,
        multi: true,
        links: [
            {
                name: "YouTube",
                link: "https://www.youtube.com/embed/nYxmQHxMGaY"
            },
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616393&id=456239228&hash=246614074b14b369"
            }
        ]
    }
]

export default listeEpisodes;