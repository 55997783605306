const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/LtmCBDX/0024-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/bR08VSM/0024-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/p4wrh4X/0024-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/nPwWR4R/0024-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/0sLPKPC/0024-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/80vqLXr/0024-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/jW0mdK0/0024-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/yFbMVKh/0024-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/7zvb4DS/0024-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/vhrjZMk/0024-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/N6sHgWG/0024-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/9nPPWNT/0024-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/jzk7cCW/0024-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/NYP5srm/0024-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/w415D2X/0024-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/ZLT3YdB/0024-016.webp"
  }  
]

export default listePages;