const listeEpisodes = [
    {
        episode : 1,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929007"
        //     }
        // ]
    },
    {
        episode : 2,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929008"
        //     }
        // ]
    },
    {
        episode : 3,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929009"
        //     }
        // ]
    },
    {
        episode : 4,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929010"
        //     }
        // ]
    },
    {
        episode : 5,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929012"
        //     }
        // ]
    },
    {
        episode : 6,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929013"
        //     }
        // ]
    },
    {
        episode : 7,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929014"
        //     }
        // ]
    },
    {
        episode : 8,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4932344"
        //     }
        // ]
    },
    {
        episode : 9,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929019"
        //     }
        // ]
    },
    {
        episode : 10,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929025"
        //     }
        // ]
    },
    {
        episode : 11,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929028"
        //     }
        // ]
    },
    {
        episode : 12,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929031"
        //     }
        // ]
    },
    {
        episode : 13,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4932347"
        //     }
        // ]
    },
    {
        episode : 14,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929152"
        //     }
        // ]
    },
    {
        episode : 15,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929154"
        //     }
        // ]
    },
    {
        episode : 16,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929157"
        //     }
        // ]
    },
    {
        episode : 17,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929159"
        //     }
        // ]
    },
    {
        episode : 18,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929161"
        //     }
        // ]
    },
    {
        episode : 19,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929163"
        //     }
        // ]
    },
    {
        episode : 20,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929164"
        //     }
        // ]
    },
    {
        episode : 21,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929166"
        //     }
        // ]
    },
    {
        episode : 22,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929167"
        //     }
        // ]
    },
    {
        episode : 23,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929168"
        //     }
        // ]
    },
    {
        episode : 24,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929169"
        //     }
        // ]
    },
    {
        episode : 25,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4932348"
        //     }
        // ]
    },
    {
        episode : 26,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929170"
        //     }
        // ]
    },
    {
        episode : 27,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929172"
        //     }
        // ]
    },
    {
        episode : 28,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929173"
        //     }
        // ]
    },
    {
        episode : 29,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929177"
        //     }
        // ]
    },
    {
        episode : 30,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929180"
        //     }
        // ]
    },
    {
        episode : 31,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4932349"
        //     }
        // ]
    },
    {
        episode : 32,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929182"
        //     }
        // ]
    },
    {
        episode : 33,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929190"
        //     }
        // ]
    },
    {
        episode : 34,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929191"
        //     }
        // ]
    },
    {
        episode : 35,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929193"
        //     }
        // ]
    },
    {
        episode : 36,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4932351"
        //     }
        // ]
    },
    {
        episode : 37,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4932353"
        //     }
        // ]
    },
    {
        episode : 38,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929194"
        //     }
        // ]
    },
    {
        episode : 39,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929196"
        //     }
        // ]
    },
    {
        episode : 40,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929198"
        //     }
        // ]
    },
    {
        episode : 41,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4932354"
        //     }
        // ]
    },
    {
        episode : 42,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929199"
        //     }
        // ]
    },
    {
        episode : 43,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929201"
        //     }
        // ]
    },
    {
        episode : 44,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4932356"
        //     }
        // ]
    },
    {
        episode : 45,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929202"
        //     }
        // ]
    },
    {
        episode : 46,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929204"
        //     }
        // ]
    },
    {
        episode : 47,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929205"
        //     }
        // ]
    },
    {
        episode : 48,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929207"
        //     }
        // ]
    },
    {
        episode : 49,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929208"
        //     }
        // ]
    },
    {
        episode : 50,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929209"
        //     }
        // ]
    },
    {
        episode : 51,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929211"
        //     }
        // ]
    },
    {
        episode : 52,
        // multi: true,
        // links: [
        //     {
        //         name: "VK Video",
        //         link: ""
        //     },
        //     {
        //         name: "Sibnet",
                link : "https://video.sibnet.ru/shell.php?videoid=4929212"
        //     }
        // ]
    }
]

export default listeEpisodes;