const listePages = [
    { page: 1, link: "https://i.ibb.co/tBVsVL6/0073-001.webp" },
    { page: 2, link: "https://i.ibb.co/kgPdyLh/0073-002.webp" },
    { page: 3, link: "https://i.ibb.co/sgQVT6r/0073-003.webp" },
    { page: 4, link: "https://i.ibb.co/hF7pys9/0073-004.webp" },
    { page: 5, link: "https://i.ibb.co/LnCFZzt/0073-005.webp" },
    { page: 6, link: "https://i.ibb.co/N3GzKkb/0073-006.webp" },
    { page: 7, link: "https://i.ibb.co/RTkk0xp/0073-007.webp" },
    { page: 8, link: "https://i.ibb.co/VLGW591/0073-008.webp" },
    { page: 9, link: "https://i.ibb.co/pQs1rWC/0073-009.webp" },
    { page: 10, link: "https://i.ibb.co/mqRW7Y4/0073-010.webp" },
    { page: 11, link: "https://i.ibb.co/2vkR5kL/0073-011.webp" },
    { page: 12, link: "https://i.ibb.co/cTyJGry/0073-012.webp" },
    { page: 13, link: "https://i.ibb.co/PYD7G22/0073-013.webp" },
    { page: 14, link: "https://i.ibb.co/m9sdVQz/0073-014.webp" },
    { page: 15, link: "https://i.ibb.co/JnvtqK6/0073-015.webp" },
    { page: 16, link: "https://i.ibb.co/fS9Z5Xc/0073-016.webp" },
    { page: 17, link: "https://i.ibb.co/LzsTFJ0/0073-017.webp" },
    { page: 18, link: "https://i.ibb.co/MBgvWwS/0073-018.webp" },
    { page: 19, link: "https://i.ibb.co/hZRmSCH/0073-019.webp" },
    { page: 20, link: "https://i.ibb.co/rtwXXTq/0073-020.webp" },
    { page: 21, link: "https://i.ibb.co/Qpr7t56/0073-021.webp" },
    { page: 22, link: "https://i.ibb.co/vsz6wJG/0073-022.webp" },
    { page: 23, link: "https://i.ibb.co/c3hK3qb/0073-023.webp" },
    { page: 24, link: "https://i.ibb.co/GsLDt4S/0073-024.webp" },
    { page: 25, link: "https://i.ibb.co/h7BThb3/0073-025.webp" }
  ]
  

export default listePages;