const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/gZqrnK9/0022-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/GnLcWg6/0022-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/nRPRbt9/0022-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/z23btWw/0022-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/QvZ20V5/0022-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/P620P5p/0022-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/ggPvmvN/0022-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/PCNtSS1/0022-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/tzVRPVH/0022-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/6rv93MD/0022-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/jg89LmB/0022-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/x3M3TWb/0022-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/c6vNz92/0022-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/WnBqqTj/0022-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/r2v6VWG/0022-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/6wk6JRQ/0022-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/TPKCTgm/0022-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/34x4Txh/0022-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/f1WcX6H/0022-019.webp"
  },
  {
    page: 20,
    link: "https://i.ibb.co/gW6WyKP/0022-020.webp"
  },
  {
    page: 21,
    link: "https://i.ibb.co/2cKPCsk/0022-021.webp"
  },
  {
    page: 22,
    link: "https://i.ibb.co/KmWDspN/0022-022.webp"
  },
  {
    page: 23,
    link: "https://i.ibb.co/LznB0vK/0022-023.webp"
  },
  {
    page: 24,
    link: "https://i.ibb.co/kMjrTJP/0022-024.webp"
  },
  {
    page: 25,
    link: "https://i.ibb.co/BZMNp11/0022-025.webp"
  },
  {
    page: 26,
    link: "https://i.ibb.co/K6LCLP7/0022-026.webp"
  },
  {
    page: 27,
    link: "https://i.ibb.co/v4vKmFC/0022-027.webp"
  },
  {
    page: 28,
    link: "https://i.ibb.co/FhSzXNL/0022-028.webp"
  },
  {
    page: 29,
    link: "https://i.ibb.co/vPPjBkL/0022-029.webp"
  },
  {
    page: 30,
    link: "https://i.ibb.co/dQ53DKs/0022-030.webp"
  },
  {
    page: 31,
    link: "https://i.ibb.co/ThJtWs5/0022-031.webp"
  },
  {
    page: 32,
    link: "https://i.ibb.co/smpDC4m/0022-032.webp"
  },
  {
    page: 33,
    link: "https://i.ibb.co/CsKx4Zg/0022-033.webp"
  },
  {
    page: 34,
    link: "https://i.ibb.co/ccvsBKw/0022-034.webp"
  },
  {
    page: 35,
    link: "https://i.ibb.co/NtjKCH1/0022-035.webp"
  },
  {
    page: 36,
    link: "https://i.ibb.co/rfCtvCd/0022-036.webp"
  },
  {
    page: 37,
    link: "https://i.ibb.co/B4jt28q/0022-037.webp"
  },
  {
    page: 38,
    link: "https://i.ibb.co/SKfqB6J/0022-038.webp"
  },
  {
    page: 39,
    link: "https://i.ibb.co/5FJGzPc/0022-039.webp"
  }  
]

export default listePages;