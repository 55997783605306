const listePages = [
    {
      "page": 1,
      "link": "https://i.ibb.co/G3JD4zv/0097-001.webp"
    },
    {
      "page": 2,
      "link": "https://i.ibb.co/ZKP2Xvc/0097-002.webp"
    },
    {
      "page": 3,
      "link": "https://i.ibb.co/5x36ZZb/0097-003.webp"
    },
    {
      "page": 4,
      "link": "https://i.ibb.co/KqZF3cF/0097-004.webp"
    },
    {
      "page": 5,
      "link": "https://i.ibb.co/6X8H6ns/0097-005.webp"
    },
    {
      "page": 6,
      "link": "https://i.ibb.co/0QHx0QM/0097-006.webp"
    },
    {
      "page": 7,
      "link": "https://i.ibb.co/HxkNwth/0097-007.webp"
    },
    {
      "page": 8,
      "link": "https://i.ibb.co/qxf7rTT/0097-008.webp"
    },
    {
      "page": 9,
      "link": "https://i.ibb.co/rQy3GkK/0097-009.webp"
    },
    {
      "page": 10,
      "link": "https://i.ibb.co/hsFBvHw/0097-010.webp"
    },
    {
      "page": 11,
      "link": "https://i.ibb.co/2Pzygv9/0097-011.webp"
    },
    {
      "page": 12,
      "link": "https://i.ibb.co/hKS8495/0097-012.webp"
    },
    {
      "page": 13,
      "link": "https://i.ibb.co/SwxJvQS/0097-013.webp"
    },
    {
      "page": 14,
      "link": "https://i.ibb.co/m5ZXmYk/0097-014.webp"
    },
    {
      "page": 15,
      "link": "https://i.ibb.co/m81wpNS/0097-015.webp"
    },
    {
      "page": 16,
      "link": "https://i.ibb.co/X4bTPdS/0097-016.webp"
    }
  ]  

export default listePages;