import React from 'react'
import FilmsList from '../../datas/FilmsList'

export default function HomeMoviesList() {
  return (
    <section className='movies-section'>
      <div className='title-container'>
        <div className='title-decoration'></div>
        <h1>BEYBLADE FILMS</h1>
      </div>
      <div className='articles-container'>
      {
          FilmsList.map((film) =>
          <article key={film.id}>
            <a href={film.link}>
              <div className='card'>
                <div className='wrapper'>
                  <img width="220px" height="310px" src={film.img} alt={"Affiche de " + film.generation + " " + film.titre + " Beyblade Streaming"}/>
                </div>
                <img src={film.logo} className='logotitle' alt={"Logo de " + film.generation + " " + film.titre + " Beyblade Streaming"} />
                <img src={film.character} className='character' alt={"Personnage principal de " + film.generation + " " + film.titre + " Beyblade Streaming"} />
              </div>
              
              <h2>{film.generation}<br/>{film.titre}</h2>

              {/* <img width="220px" height="310px" src={film.img} alt={"Affiche de " + film.generation + " " + film.titre + " Beyblade Streaming"}/>
              <h2>{film.generation}<br/>{film.titre}</h2> */}
            </a>
          </article>
          )
        }
      </div>

    </section>
  )
}