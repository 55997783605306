const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/WFDHTjw/0029-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/K6VmCgY/0029-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/gJ4ZBN7/0029-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/9wTqQ3W/0029-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/hcvXQMx/0029-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/tZg7MCp/0029-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/58BqQw9/0029-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/jHvr4sB/0029-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/N3NMhPB/0029-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/dk56Z51/0029-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/Ctsz8rY/0029-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/nwXkY4x/0029-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/gVRNK45/0029-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/bzZ13KN/0029-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/717KhTk/0029-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/4RWdD8h/0029-016.webp"
  }  
]

export default listePages;