const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/mTVsrwQ/0007-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/HTMMcZD/0007-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/rFmDKTz/0007-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/GdZKR2N/0007-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/CnrwG9V/0007-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/R4NYnY0/0007-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/QcDpnTw/0007-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/RQyRSj8/0007-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/bzX2Y4x/0007-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/r54Js09/0007-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/Lg565Rw/0007-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/X2Q1SDr/0007-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/TM7Lggr/0007-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/v4c3sxL/0007-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/H2Z88Km/0007-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/WHvxk7L/0007-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/4dS2ZPZ/0007-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/zWd2dPP/0007-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/G2GpGwf/0007-019.webp"
  },
  {
    page: 20,
    link: "https://i.ibb.co/ScRfxrC/0007-020.webp"
  },
  {
    page: 21,
    link: "https://i.ibb.co/3Cy7cP0/0007-021.webp"
  },
  {
    page: 22,
    link: "https://i.ibb.co/8DnhM0J/0007-022.webp"
  },
  {
    page: 23,
    link: "https://i.ibb.co/3B6Ttdn/0007-023.webp"
  },
  {
    page: 24,
    link: "https://i.ibb.co/60svkM2/0007-024.webp"
  },
  {
    page: 25,
    link: "https://i.ibb.co/Ns554Ln/0007-025.webp"
  },
  {
    page: 26,
    link: "https://i.ibb.co/M8S69yd/0007-026.webp"
  }
]


export default listePages;