const listePages = [
    { page: 1, link: "https://i.ibb.co/GnDmvSs/0088-001.webp" },
    { page: 2, link: "https://i.ibb.co/jkyfmdQ/0088-002.webp" },
    { page: 3, link: "https://i.ibb.co/FDZ6fbK/0088-003.webp" },
    { page: 4, link: "https://i.ibb.co/nRtg5N9/0088-004.webp" },
    { page: 5, link: "https://i.ibb.co/DMLWztt/0088-005.webp" },
    { page: 6, link: "https://i.ibb.co/TRFprhs/0088-006.webp" },
    { page: 7, link: "https://i.ibb.co/7KDk9RT/0088-007.webp" },
    { page: 8, link: "https://i.ibb.co/xCzNw3n/0088-008.webp" },
    { page: 9, link: "https://i.ibb.co/b1R8kqj/0088-009.webp" },
    { page: 10, link: "https://i.ibb.co/GTrcYV6/0088-010.webp" },
    { page: 11, link: "https://i.ibb.co/7N7S1Lb/0088-011.webp" },
    { page: 12, link: "https://i.ibb.co/QPHrqs9/0088-012.webp" },
    { page: 13, link: "https://i.ibb.co/NLrf1ym/0088-013.webp" },
    { page: 14, link: "https://i.ibb.co/SJLXHrJ/0088-014.webp" },
    { page: 15, link: "https://i.ibb.co/RYwQrjW/0088-015.webp" },
    { page: 16, link: "https://i.ibb.co/HdxjvWy/0088-016.webp" }
  ]
  

export default listePages;