const listePages = [
    {
        page: 1,
        link: "https://i.ibb.co/PmBgdYX/0097.webp"
      },
      {
        page: 2,
        link: "https://i.ibb.co/KjyCtB2/0098.webp"
      },
      {
        page: 3,
        link: "https://i.ibb.co/0JRQt2z/0099.webp"
      },
      {
        page: 4,
        link: "https://i.ibb.co/6vWT37R/0100.webp"
      },
      {
        page: 5,
        link: "https://i.ibb.co/2dxRfb5/0101.webp"
      },
      {
        page: 6,
        link: "https://i.ibb.co/xqk4zSk/0102.webp"
      },
      {
        page: 7,
        link: "https://i.ibb.co/6B7M0PB/0103.webp"
      },
      {
        page: 8,
        link: "https://i.ibb.co/y52mJdv/0104.webp"
      },
      {
        page: 9,
        link: "https://i.ibb.co/7zhs2ch/0105.webp"
      },
      {
        page: 10,
        link: "https://i.ibb.co/L6s384T/0106.webp"
      },
      {
        page: 11,
        link: "https://i.ibb.co/xGkdgcY/0107.webp"
      },
      {
        page: 12,
        link: "https://i.ibb.co/vmpsnrk/0108.webp"
      },
      {
        page: 13,
        link: "https://i.ibb.co/Mpd7Fch/0109.webp"
      },
      {
        page: 14,
        link: "https://i.ibb.co/F02PWV7/0110.webp"
      },
      {
        page: 15,
        link: "https://i.ibb.co/D1HMYw2/0111.webp"
      },
      {
        page: 16,
        link: "https://i.ibb.co/T195LCW/0112.webp"
      },
      {
        page: 17,
        link: "https://i.ibb.co/KbjSyKR/0113.webp"
      },
      {
        page: 18,
        link: "https://i.ibb.co/G908hy4/0114.webp"
      },
      {
        page: 19,
        link: "https://i.ibb.co/g3BKM8J/0115.webp"
      },
      {
        page: 20,
        link: "https://i.ibb.co/FH7xBGL/0116.webp"
      },
      {
        page: 21,
        link: "https://i.ibb.co/wK5V69F/0117.webp"
      },
      {
        page: 22,
        link: "https://i.ibb.co/RbyWNQL/0118.webp"
      },
      {
        page: 23,
        link: "https://i.ibb.co/GQGBPVS/0119.webp"
      },
      {
        page: 24,
        link: "https://i.ibb.co/0sBcHgP/0120.webp"
      },
      {
        page: 25,
        link: "https://i.ibb.co/yVwbsTd/0121.webp"
      },
      {
        page: 26,
        link: "https://i.ibb.co/f2ckbTN/0122.webp"
      },
      {
        page: 27,
        link: "https://i.ibb.co/C96yVsP/0123.webp"
      },
      {
        page: 28,
        link: "https://i.ibb.co/sg9Vd8r/0124.webp"
      },
      {
        page: 29,
        link: "https://i.ibb.co/nBw2p35/0125.webp"
      },
      {
        page: 30,
        link: "https://i.ibb.co/2gbgmrp/0125-1.webp"
      },
      {
        page: 31,
        link: "https://i.ibb.co/jJkNg6m/0126.webp"
      },
      {
        page: 32,
        link: "https://i.ibb.co/LdcRS9S/0127.webp"
      },
      {
        page: 33,
        link: "https://i.ibb.co/fr19pfk/0128.webp"
      },
      {
        page: 34,
        link: "https://i.ibb.co/7G3ry3D/0129.webp"
      },
      {
        page: 35,
        link: "https://i.ibb.co/8mhM6Kv/0130.webp"
      },
      {
        page: 36,
        link: "https://i.ibb.co/q1qxrL9/0131.webp"
      },
      {
        page: 37,
        link: "https://i.ibb.co/kJQKkQw/0132.webp"
      }
]


export default listePages;