const listePages = [
  {
    page: 1,
    link: "https://i.ibb.co/JRMPJX5/0023-001.webp"
  },
  {
    page: 2,
    link: "https://i.ibb.co/wQ9QB7z/0023-002.webp"
  },
  {
    page: 3,
    link: "https://i.ibb.co/NpJN8FN/0023-003.webp"
  },
  {
    page: 4,
    link: "https://i.ibb.co/xgFQ68G/0023-004.webp"
  },
  {
    page: 5,
    link: "https://i.ibb.co/pRV2k2L/0023-005.webp"
  },
  {
    page: 6,
    link: "https://i.ibb.co/8cfBp2D/0023-006.webp"
  },
  {
    page: 7,
    link: "https://i.ibb.co/zb5Dwhd/0023-007.webp"
  },
  {
    page: 8,
    link: "https://i.ibb.co/tDzypZW/0023-008.webp"
  },
  {
    page: 9,
    link: "https://i.ibb.co/KFZT8F7/0023-009.webp"
  },
  {
    page: 10,
    link: "https://i.ibb.co/jvWbq3M/0023-010.webp"
  },
  {
    page: 11,
    link: "https://i.ibb.co/RhqQPsg/0023-011.webp"
  },
  {
    page: 12,
    link: "https://i.ibb.co/4P1q1LL/0023-012.webp"
  },
  {
    page: 13,
    link: "https://i.ibb.co/1MwZG6S/0023-013.webp"
  },
  {
    page: 14,
    link: "https://i.ibb.co/tDnjScz/0023-014.webp"
  },
  {
    page: 15,
    link: "https://i.ibb.co/CnqsfHx/0023-015.webp"
  },
  {
    page: 16,
    link: "https://i.ibb.co/V3Snq9g/0023-016.webp"
  },
  {
    page: 17,
    link: "https://i.ibb.co/VLp2mvZ/0023-017.webp"
  },
  {
    page: 18,
    link: "https://i.ibb.co/PhV1x9M/0023-018.webp"
  },
  {
    page: 19,
    link: "https://i.ibb.co/wdVJ62k/0023-019.webp"
  },
  {
    page: 20,
    link: "https://i.ibb.co/Cz6Lx2m/0023-020.webp"
  },
  {
    page: 21,
    link: "https://i.ibb.co/jR0WfDX/0023-021.webp"
  },
  {
    page: 22,
    link: "https://i.ibb.co/8NQ0bTk/0023-022.webp"
  },
  {
    page: 23,
    link: "https://i.ibb.co/DG4jdCv/0023-023.webp"
  },
  {
    page: 24,
    link: "https://i.ibb.co/CnpWhn5/0023-024.webp"
  },
  {
    page: 25,
    link: "https://i.ibb.co/RpXGKHq/0023-025.webp"
  },
  {
    page: 26,
    link: "https://i.ibb.co/9W5mycc/0023-026.webp"
  },
  {
    page: 27,
    link: "https://i.ibb.co/z6ZyNws/0023-027.webp"
  },
  {
    page: 28,
    link: "https://i.ibb.co/7YcYjgz/0023-028.webp"
  },
  {
    page: 29,
    link: "https://i.ibb.co/MRn3gP1/0023-029.webp"
  },
  {
    page: 30,
    link: "https://i.ibb.co/hsrDb2X/0023-030.webp"
  },
  {
    page: 31,
    link: "https://i.ibb.co/PjhRQc2/0023-031.webp"
  },
  {
    page: 32,
    link: "https://i.ibb.co/sjHVXv0/0023-032.webp"
  },
  {
    page: 33,
    link: "https://i.ibb.co/KVZWyF1/0023-033.webp"
  },
  {
    page: 34,
    link: "https://i.ibb.co/J23ypj6/0023-034.webp"
  },
  {
    page: 35,
    link: "https://i.ibb.co/dDRynF8/0023-035.webp"
  },
  {
    page: 36,
    link: "https://i.ibb.co/6v1LtpN/0023-036.webp"
  },
  {
    page: 37,
    link: "https://i.ibb.co/XFbYH6F/0023-037.webp"
  },
  {
    page: 38,
    link: "https://i.ibb.co/ZLjmRfg/0023-038.webp"
  },
  {
    page: 39,
    link: "https://i.ibb.co/rmf3Gny/0023-039.webp"
  },
  {
    page: 40,
    link: "https://i.ibb.co/p0HTRqS/0023-040.webp"
  },
  {
    page: 41,
    link: "https://i.ibb.co/ZxFymVH/0023-041.webp"
  },
  {
    page: 42,
    link: "https://i.ibb.co/2NP8qNH/0023-042.webp"
  },
  {
    page: 43,
    link: "https://i.ibb.co/fGCYFMY/0023-043.webp"
  },
  {
    page: 44,
    link: "https://i.ibb.co/stcyqpT/0023-044.webp"
  } 
]

export default listePages;