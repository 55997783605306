const scansList = [
    {
        id : 1,
        generation : "Metal Fight Beyblade",
        gen : "metal",
        link : "/metal/scans",
        img : "https://i.ibb.co/L9XxPcs/Beyblade-metal-fusion.webp",
        logo : "https://i.ibb.co/FxkP8pC/beyblade-metal.webp",
        character: "https://i.ibb.co/WfFPbv9/gingka-manga.webp"
    },
    {
        id : 2,
        generation : "Metal Fight Beyblade Zero-G",
        gen : "metal",
        link : "/metal/scans",
        img : "https://i.ibb.co/1sZRhDK/Beyblade-Shogun-Steel.webp",
        logo : "https://i.ibb.co/VYKcy0H/beyblade-shogun-steel.webp",
        character: "https://i.ibb.co/XxQQ5ZT/zyro-manga.webp"
    },
    {
        id : 3,
        generation : "Beyblade Burst",
        gen : "burst",
        link : "/burst/scans",
        img : "https://i.ibb.co/xjTGXKG/Couv-313677.webp",
        logo : "https://i.ibb.co/DLy9Ph4/beyblade-burst.webp",
        character: "https://i.ibb.co/Hgzv891/valt-manga.webp"
    },
    {
        id : 4,
        generation : "Beyblade X",
        gen : "x",
        link : "/x/scans",
        img : "https://i.ibb.co/xSfsk0S/Beyblade-demizu-pozuka-visual.webp",
        logo : "https://i.ibb.co/KjRFQNV/beyblade-x.webp",
        character: "https://i.ibb.co/gDck5ph/bird-manga.webp"
    },
    {
        id : 5,
        generation : "Spin-off",
        gen : "spinoff",
        link : "/spinoff/scans",
        img : "https://i.ibb.co/J7D35St/0001.webp",
        logo : "https://i.ibb.co/b2MfMRn/spin-off.webp",
        character: "https://i.ibb.co/7tdj7wb/rien.webp"
    }
]

export default scansList